import React from "react";

const ErrorForm = (props:any) => {
  return (
    <div className="online-form-generator m-2 p-2">
      <div className="alert alert-danger" role="alert">
        Error!
        <br/>
        <br/>
      <p>{props?.errorMessage}</p>
      </div>
      {/* <h2>{props?.location?.state?.Title || props?.Title}</h2> */}
    </div>
  );
};

export default ErrorForm;
