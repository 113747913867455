import moment from "moment";
import { getTimeFormat } from "utils";

const History = (props: any) => {
  let item = props.dataItem;
  return (
    <div
      style={{ backgroundColor: "#fff" }}
      className="py-2 bz-package-audit-history-list"
    >
      {" "}
      <span className="text-muted d-block mb-0 audit-flex">
        <span>
          <i className="far fa-calendar-alt"></i>
        </span>
        <div>
          <span className="bz-activity-time-date text-capitalize">
            {" "}
            {moment(item?.AuditDate).format("L")}{" "}
            {getTimeFormat(item?.AuditDate)}{" "}
          </span>
          <span className="bz-activity-dis-div">{item?.ActionDetail} </span>
          <label className="bz-requestedBy font-italic bz-fw-600">
            {item?.RequestedBy}
          </label>{" "}
        </div>
      </span>
    </div>
  );
};

export default History;
