import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";

const SchedulesList = (props: any) => {
  const schedules = props?.schedules || [];
  if(props?.loading){
    return <BuzopsLoader type="list"/>
  }
  const DateCell=(propData:any)=>{
    const dateVal=moment(propData?.dataItem?.FromTime).format('L')
    return <td>{`${dateVal} ${propData?.dataItem?.ScheduleName}`}</td>
  }

 
  return (
    <>
      <div>
        <Grid data={schedules} className="bzc-grid bz-pkg-utilization-grid" style={{ maxHeight: schedules?.length > 0 ? "250px":"400px",overflow:'auto' }}>
          <Column
            field="FromTime"
            title="Date & Time"
            cell={DateCell}
          />
          <Column
            field="ServiceName"
            title={`${props?.typeName || 'Appointments'}`}
          />
            <Column
            field="TrainerName"
            title="Provider Name"
          />
        </Grid>
      </div>
    </>
  );
};

export default SchedulesList;
