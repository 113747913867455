import moment from "moment";

export const NonPaidLabelValidator = (value: string) => value.length <=12 ? "Provide enrollment label for customers (e.g., Free, Non-Paid, etc.)" : "";
export const classNameValidator = (value: string) =>
  value ? "" : "Please enter a name.";
export const classDescriptionValidator = (value: string) =>
  value ? "" : "Please enter a description.";
export const classSessionValidator = (value: string) =>
  value ? "" : "Please select a appointment.";
export const requiredValidator = (value: any) => (value ? "" : "required");
const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const mobileRegex = new RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
);
const strongPassword = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
);
const mediumPassword = new RegExp(
  "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,}))"
);

const shortCodeRegex = new RegExp(/^[a-zA-Z0-9]{1,25}$/);
const affiliateCodeRegex = new RegExp(/^[a-zA-Z0-9]{6,20}$/);
const zipCodeRegex = new RegExp(/^(\d{5}|[a-zA-Z0-9]{1,6})$/);
export const AffiliateZipCodeRegex = new RegExp(/^(\d{5}|[a-zA-Z0-9]{1,6}|\d{5}-\d{4})$/);
const onlyNumericRegex = new RegExp(/^[0-9]{1,20}$/);
const AccessTagIdRegex = new RegExp(/^[0-9]{1,15}$/);
const cardNameRegex = new RegExp(/^[A-Za-z][A-Za-z\s]*$/);
const surchargeNameRegex = new RegExp(/^(?!\d+$)(?:[a-zA-Z][a-zA-Z0-9 @&$#*%^.!()_-]*)?$/);
export const NameRegex = new RegExp(/^[A-Za-z][A-Za-z'-.`\s]*$/);
export const FormNameRegex = new RegExp(/.*/);
export const CustomNameRegex = new RegExp(
  /^[^-\s][A-Za-z0-9_@.,$'%*^)(!/#&+-\s]*$/
);
export const URLRegex = new RegExp(
  "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
);
export const emailValidator = (value: any,name="Email") => {
  let message = "";
  if (value) {
    if (!emailRegex.test(value)) {
      message = `Please enter a valid ${name}`;
    }else if(value.length > 128){
      message=`${name} exceeds the limit`
    }
  }else{
    message = `Please enter ${name}`;
  } 
  return message;
};
export const childEmailValidator = (value: any) => {
  let message = "";
  if (value) {
    if (!emailRegex.test(value)) {
      message = `Please enter a valid Email Id`;
    }else if(value.length > 128){
      message=`Email Id exceeds the limit`
    }
  } 
  return message;
};
export const cardNameValidator = (value: any) =>
  cardNameRegex.test(value) ? "" : "Please enter a valid Name.";


export const FormNameValidator = (value: any, label: string): string => {
  let message = "";
  if (value) {
    if (!FormNameRegex.test(value)) {
      message = `Please enter a valid ${label}`;
    }else if(value?.length > 128){
      message=`${label} exceeds the limit`
    }
  } else {
    message = `Please enter ${label}`;
  }
  return message;
  return message;
};
export const NameValidator = (value: any, label: string) => {
  let message = "";
  if (value) {
    if (!NameRegex.test(value)) {
      message = `Please enter a valid ${label}`;
    }else if(value?.length > 128){
      message=`${label} exceeds the limit`
    }
  } else {
    message = `Please enter ${label}`;
  }
  return message;
};
export const mobileValidator = (value: any) => {
  let message = "";
  if (value) {
    if (!mobileRegex.test(value)) {
      message = "Please enter a valid Phone Number";
    }
  } else {
    message = "Please enter Phone Number";
  }
  return message;
};

export const urlValidator = (value: any) => {
  let message = "";
  if (value) {
    if (!URLRegex.test(value)) {
      message = "Please enter a valid URL";
    }
  } else {
    message = "Please enter URL";
  }
  return message;
};
export const shortCodeValidator = (value: any) => {
  let message = "";
  if (value) {
    if (!shortCodeRegex.test(value)) {
      message = "Please enter a valid code.";
    }
  } else {
    message = "Please enter Code";
  }
  return message;
};
export const AffiliateCodeValidator = (value: any) => {
  let message = "";
  if (value) {
    if (!affiliateCodeRegex.test(value)) {
      message = "Affiliate Code must have atleast 6 alphanumeric characters";
    }
  } else {
    message = "Please enter Affiliate Code";
  }
  return message;
};

export const trailDaysValidator = (value: any) => {
  let message = "";
  if (value) {
    if (value < 0) {
      message = "Please enter a valid Trial Days.";
    }
  } else {
    message = "Please enter Trial Days";
  }
  return message;
};
export const zipCodeValidator = (value: any) => {
  let message = "";
  if (value) {
    if (!zipCodeRegex.test(value)) {
      message = "Please enter a valid Zip Code.";
    }
  } else {
    message = "Please enter Zip Code";
  }
  return message;
};
export const AffiliateZipCodeValidator = (value: any) => {
  let message = "";
  if (value) {
    if (!AffiliateZipCodeRegex.test(value)) {
      message = "Please enter a valid Zip Code.";
    }
  } else {
    message = "Please enter Zip Code";
  }
  return message;
};
export const routingNumberValidator = (value: any) => {
  let message = "";
  if (value) {
    if (!onlyNumericRegex.test(value)) {
      message = "Please enter valid Routing Number";
    } else if (value.length > 9) {
      message = "Routing Number should not exceed 9 digits";
    }
  } else {
    message = "Please enter Routing Number";
  }
  return message;
};
export const AccessTagValidator = (value: any) => {
  let message = "";
  if (value) {
    if (value.length > 15) {
      message = "Access Tag Id should not exceed 15 digits";
    } else if (!AccessTagIdRegex.test(value)) {
      message = "Please enter valid Access Tag Id";
    }
  }
  return message;
};

export const passwordValidator = (value: any) => {
  if (value) {
    if (value.length < 8) {
      return "Password must be at least 8 characters";
    }
    if (value.length > 20) {
      return "Password must be at less than 20 characters";
    }

    if (value.search(/[a-z]/i) < 0) {
      return "Password must contain at least one letter.";
    }
    if (value.search(/[0-9]/) < 0) {
      return "Password must contain at least one digit.";
    }
    return "";
  } else {
    return "Please enter Password";
  }
};
export const BankAccountValidator = (value: any) => {
  let message = "";
  if (value) {
    if (!onlyNumericRegex.test(value)) {
      message = "Please enter valid Account Number";
    } else if (value.length > 16) {
      message = "Account Number should not exceed 16 digits";
    }
  } else {
    message = "Please enter Account Number";
  }

  return message;
};

export const otpValidator = (value: any) => {
  let message = "";
  if (value) {
    if (!onlyNumericRegex.test(value)) {
      message = "Please enter valid OTP";
    } else if (value.length > 6) {
      message = "OTP should not exceed 6 digits";
    }
  } else {
    message = "Please enter OTP";
  }

  return message;
};


  
  
    export const appointmentFormValidator = (values: any) => {
      let formValidations = {};
    
      if (values?.Name === "") {
        formValidations = {
          ...formValidations,
          Name: "Please enter an Appointment Name",
        };
      }

      if(values.duration <= 0 || values.duration > 120){
        formValidations = {
          ...formValidations,
          duration:"Please enter duration upto 120 minutes"
        }
      }
    
      return formValidations; 
    };
    


export const classFormValidator = (values: any) => {
  let formValidations = {};
  const StartDate = moment(values.StartDate).format("YYYY-MM-DD");
  const EndDate = moment(values.EndDate).format("YYYY-MM-DD");
  const fromTime = moment(values.fromTime).format("HH:mm:ss");
  const currentDate = moment().format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm:ss");
  if (values?.Type?.id === 0) {
    formValidations = {
      ...formValidations,
      Type: "Please select Type",
    };
  }
  if (values?.PaymentOption?.id === 0) {
    formValidations = {
      ...formValidations,
      PaymentOption: "Please select Payment Option",
    };
  }
  if (!values.provider) {
    formValidations = {
      ...formValidations,
      provider: "Please select provider",
    };
  }
  if (!values.Slot || values.Slot <= 0 || values.Slot > 150) {
    formValidations = {
      ...formValidations,
      Slot: "Please enter Maximum Attendees upto 150 ",
    };
  }
  if (values.screen === "addClass") {
    if (
      values.CancellationFee === 0 &&
      !Object.is(values.CancellationFee, +0)
    ) {
      formValidations = {
        ...formValidations,
        CancellationFee: "Please enter valid Cancellation Fee ",
      };
    }
    if (
      (!values.RemainderDuration || values.RemainderDuration <= 0) &&
      values.emailNotification
    ) {
      formValidations = {
        ...formValidations,
        RemainderDuration: "Please enter valid Reminder Duration ",
      };
    }
    if (
      (!values.MinimumBookingDuration || values.MinimumBookingDuration <= 0) &&
      values.appointmentBookNotification
    ) {
      formValidations = {
        ...formValidations,
        MinimumBookingDuration: "Please enter valid Minimum Booking Duration ",
      };
    }
    if (
      (!values.MinimumCancellationDuration ||
        values.MinimumCancellationDuration <= 0) &&
      values.appointmentCancelNotification
    ) {
      formValidations = {
        ...formValidations,
        MinimumCancellationDuration:
          "Please enter valid Minimum Cancellation Duration ",
      };
    }
  }
  if (values.EventType === 1) {
    switch (values.sessions.text) {
      case "Weekly":
        if (
          StartDate === "Invalid date" ||
          EndDate === "Invalid date" ||
          fromTime === "Invalid date"
        ) {
          if (StartDate === "Invalid date") {
            formValidations = {
              ...formValidations,
              StartDate: "Please enter valid Start Date.",
            };
          }
          if (EndDate === "Invalid date") {
            formValidations = {
              ...formValidations,
              EndDate: "Please enter valid End Date.",
            };
          }
          if (fromTime === "Invalid date") {
            formValidations = {
              ...formValidations,
              fromTime: "Please enter valid From Time.",
            };
          }
        }
        if (
          StartDate !== "Invalid date" &&
          EndDate !== "Invalid date" &&
          StartDate > EndDate
        ) {
          formValidations = {
            ...formValidations,
            EndDate: "Please select End Date greater than Start Date.",
          };
        }
        if (values.weekDays.length === 0) {
          formValidations = {
            ...formValidations,
            weekDays: "Please select atleast one week day.",
          };
        }

        if (fromTime !== "Invalid date" && StartDate === currentDate) {
          if (fromTime < currentTime) {
            formValidations = {
              ...formValidations,
              fromTime: "Please select Start Time Greater than Current Time.",
            };
          }
        }
        if (values?.duration <= 0 || values?.duration > 240) {
          formValidations = {
            ...formValidations,
            duration: "Please enter duration upto 240 minutes.",
          };
        }
        break;
      case "Specific Days":
        if (values.specificDays.length === 0) {
          formValidations = {
            ...formValidations,
            specificDays: "Please add at least one day appointment.",
          };
        }
        break;
      default:

        if (values.weekDay === "") {
          formValidations = {
            ...formValidations,
            weekDay: "Please select week day.",
          };
        }
        if (values.onEvery === "") {
          formValidations = {
            ...formValidations,
            onEvery: "Please select on Every.",
          };
        }
        if (
          StartDate === "Invalid date" ||
          EndDate === "Invalid date" ||
          fromTime === "Invalid date"
        ) {
          if (StartDate === "Invalid date") {
            formValidations = {
              ...formValidations,
              StartDate: "Please enter valid Start Date.",
            };
          }
          if (EndDate === "Invalid date") {
            formValidations = {
              ...formValidations,
              EndDate: "Please enter valid End Date.",
            };
          }
          if (fromTime === "Invalid date") {
            formValidations = {
              ...formValidations,
              fromTime: "Please enter valid From Time.",
            };
          }
        }
        if (
          StartDate !== "Invalid date" &&
          EndDate !== "Invalid date" &&
          StartDate > EndDate
        ) {
          formValidations = {
            ...formValidations,
            EndDate: "Please select End Date greater than Start Date.",
          };
        }
        if (fromTime !== "Invalid date" && StartDate === currentDate) {
          if (fromTime < currentTime) {
            formValidations = {
              ...formValidations,
              fromTime: "Please select Start Time Greater than Current Time.",
            };
          }
        }
        if (values?.duration <= 0 || values?.duration > 240) {
          formValidations = {
            ...formValidations,
            duration: "Please enter duration upto 240 minutes.",
          };
        }
        break;
    }

    if (
      (!values.RemainderDuration || values.RemainderDuration <= 0) &&
      values.emailNotification
    ) {
      formValidations = {
        ...formValidations,
        RemainderDuration: "Please enter valid Reminder Duration ",
      };
    }
    if (
      (!values.MinimumBookingDuration || values.MinimumBookingDuration <= 0) &&
      values.appointmentBookNotification
    ) {
      formValidations = {
        ...formValidations,
        MinimumBookingDuration: "Please enter valid Minimum Booking Duration ",
      };
    }
    if (
      (!values.MinimumCancellationDuration ||
        values.MinimumCancellationDuration <= 0) &&
      values.appointmentCancelNotification
    ) {
      formValidations = {
        ...formValidations,
        MinimumCancellationDuration:
          "Please enter valid Minimum Cancellation Duration ",
      };
    }
  } else {
    if (StartDate === "Invalid date" || fromTime === "Invalid date") {
      if (StartDate === "Invalid date") {
        formValidations = {
          ...formValidations,
          StartDate: "Please enter valid Start Date.",
        };
      }
      if (fromTime === "Invalid date") {
        formValidations = {
          ...formValidations,
          fromTime: "Please enter valid From Time.",
        };
      }
    }

    if (fromTime !== "Invalid date" && StartDate === currentDate) {
      if (fromTime < currentTime) {
        formValidations = {
          ...formValidations,
          fromTime: "Please select Start Time Greater than Current Time.",
        };
      }
    }
    if (values?.duration <= 0 || values?.duration > 240) {
      formValidations = {
        ...formValidations,
        duration: "Please enter duration upto 240 minutes.",
      };
    }
  }

  return formValidations;
};


export const packageFormValidator = (values: any) => {
  let formValidations = {};
  if (values?.SessionType?.id === 0) {
    formValidations = {
      ...formValidations,
      SessionType: "Please select Appointment Type.",
    };
  }
  if (values?.SessionType?.id === 1 && values?.ExpireAfterDaysOnPurchase <= 0) {
    formValidations = {
      ...formValidations,
      ExpireAfterDaysOnPurchase: "Please enter Expire After.",
    };
  }
  if (values?.SessionType?.id === 1 && values?.Schedules <= 0) {
    formValidations = {
      ...formValidations,
      Schedules: "Please enter Schedules.",
    };
  }
  return formValidations;
}

export const ValidatePassword = (value: any) => {
  if (value && !passwordValidator(value)) {
    if (strongPassword.test(value)) {
      return "Strong Password";
    } else if (mediumPassword.test(value)) {
      return "Medium Password";
    } else {
      return "Weak Password";
    }
  }
}
export const taxPercentageValidator = (value: any) => {
  let message = "";
  if (value) {
    if(value < 0){
      message = "Please enter valid Surcharge Percentage";
    }else if (value > 100) {
      message = "Surcharge Percentage should not exceed 100";
    } 
  } else {
    message = "Please enter Surcharge Percentage";
  }
  return message;
};
export const SurchargeNameValidator = (value: any) => {
  let message = "";
  if (value) {
    if (!surchargeNameRegex.test(value)) {
      message = "Surcharge Name allows alphanumeric characters with special characters";
    }else if(value?.length >50){
      message = "Surcharge Name should not exceed 50 characters";
    }
  } else {
    message = "Please enter Surcharge Name";
  }
  return message;
};
