import React, { useEffect, useState } from "react";
import BuzopsButton from "generic-components/BuzopsButton";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { CurrencyFormat, CustomToFixed } from "utils";
import { Notification as Notify } from "@progress/kendo-react-notification";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Error } from "@progress/kendo-react-labels";
import { TenantService } from "services/tenant/index.service";
import BuzopsNotification from "generic-components/BuzopsNotification";
import { TooltipContentRefundTax } from "./Refund";
import { Tooltip } from "@progress/kendo-react-tooltip";

const Void = (props: any) => {
  const [viewDetails, setviewDetails] = useState<any>(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });

  const [loading, setLoading] = useState(true);
  const service = new TenantService();

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

  useEffect(() => {
    if(props?.data){
      getData(props?.data);
    }
  }, [props?.data]);

  const handleCalculateSurcharges = (
    refundAmt: any,
    MetaDataItem:any
  ) => {
    if(MetaDataItem){
    const TaxItems=MetaDataItem?.split(",")
    const TaxesArray = TaxItems?.map((i: any) => {
      const dataItem=i?.split(":")
      const percentage = parseFloat(dataItem[1]);
      const description = `${dataItem[0]} - (${percentage}%)`
      const amount = (refundAmt * percentage) / 100;
      const calcAmt=CustomToFixed(amount,2)
      return {
        Description: description,
        Percentage: percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount
    }, 0);
    return {
      TaxesArray:TaxesArray,
      TaxAmountToRefund:taxAmt
    }
  }else{
    return {
      TaxesArray:[],taxAmt:0
    }
  }
  };


  const getData=(result: any)=> {
    setLoading(true);
    console.log(result)
    const resultItems=result?.Result?.map((i:any)=> {
        if(i?.Taxes > 0){
          let amt=i?.AmountPaid-i?.Taxes
          const obj=handleCalculateSurcharges(amt,i?.MetaData)
          return {...i,TaxesArray:obj?.TaxesArray,TaxAmountToRefund:obj?.TaxAmountToRefund}
        }else{
          return {...i}
        }
    })
    const data={...result,Result:resultItems}
    setviewDetails(data);
    setLoading(false);

  }


  if (loading) {
    return <BuzopsLoader type={"list"} />;
  }

  const handleValidation = () => {
    if (!viewDetails?.Comments || viewDetails?.Comments === "") {
      return false;
    }

    return true;
  };
  const handleComplete = async () => {
    const check = handleValidation();
    if (!check) {
      return true;
    }
    setBtnLoading(true);
    viewDetails.UserMemberId = props?.UserMemberId;
    const result = await service.VoidTransactions(viewDetails);
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setMessage(result?.SuccessMessage);
      setNotification({ ...notification, success: true });
      props?.handleCloseViewDetail();
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0 ? result?.ErrorMessages[0] : "Error ";
      setMessage(errorMsg);
      setNotification({ ...notification, error: true });
    }
  };

  const handleChange = async (value: any, name: string) => {
    const data = JSON.parse(JSON.stringify(viewDetails));
    let datatrans = data;

    datatrans = {
      ...data,
      [name]: value,
    };
    //paymentsetteled -> item
    setviewDetails(datatrans);
  };

  const showErrorMessage = (key: any, mainKey: any) => {
    if (key === "Comments") {
      if (!viewDetails?.Comments || viewDetails?.Comments.length <= 0) {
        return <Error>{`${mainKey} is required`}</Error>;
      }
    }
    return <></>;
  };

  const amountCell = (props: any) => {
    let amount:any=props?.dataItem?.AmountPaid-props?.dataItem?.Taxes
    return (
      <td className={"capitalize"}>
        {CurrencyFormat(amount?.toFixed(2))}
      </td>
    );
  };
  const taxCell = (props: any) => {
    if(props.dataItem.Taxes <=0){
      return (
        <td >{CurrencyFormat(0)}</td>)
    }
    return (
      <td >
        <Tooltip
          content={(tooltipProps: any) => {
            return (
              <TooltipContentRefundTax
                title={tooltipProps.title}
                data={props?.dataItem?.TaxesArray}
                TotalTaxAmount={props?.dataItem?.TaxAmountToRefund}
              />
            );
          }}
          tooltipClassName="bz-tooltip-table"
        >
          <a
            title="Surcharges"
            style={{
              borderBottom: "1px dotted #0d6efd",
              cursor: "pointer",
            }}
          >
            {CurrencyFormat(props.dataItem.Taxes.toFixed(2))}
          </a>
          </Tooltip>
      </td>
    );
  };

  const checkSurchargesAvailable=()=>{
      const check=viewDetails?.Result?.filter((i:any)=>i?.MetaData)
      if(check?.length > 0){
        return true
      }else{
        return false
      }
  }
  const { TransactionId=0, Result=[], TotalAmount=0 } = viewDetails;
  return (
    <>
        <h5 className="mb-3 bz_fs_1_5">
          <Button
            className="btn btn-primary bz-back-btn btn-sm back-arrow-btn ml-0"
            onClick={() => props?.handleCloseViewDetail()}
          >
            <i className="fa fa-arrow-left"></i>
          </Button>
          &nbsp;Void Transaction
        </h5>
      <hr />
      <h6 className="mb-2 bz_fs_1_5">General Info</h6>
      <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation">
      <div className="left-section" style={{maxWidth:'750px'}}>
          <Notify key="info" type={{ style: "info" }} className="mb-2">
            {`Below transactions are not settled yet, you can only void all the transactions specified below, partial or full refunds are not allowed at this time. Please click complete to void the transactions.`}
          </Notify>
          <div className="bz-mt-1rem bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Client Name</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="d-flex align-items-center">{Result[0]?.MemberName}</div>
              </div>
            </div>
          </div>
          <div className="bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Service Type</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="d-flex align-items-center">{Result[0]?.PaymentMadeForDescription}</div>
              </div>
            </div>
          </div>
          <div className="bz-mb-2rem bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Transaction ID</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="d-flex align-items-center">{TransactionId}</div>
              </div>
            </div>
          </div>
          {Result?.length > 0 ? (
            <>
              <Grid
                data={Result}
                className="bzc-grid bzc-void-grid"
              >
                {/* <Column field="MemberName" title="Client Name"/> */}
                <Column field="ServiceName" title="Service Name"/>
                {/* <Column
                  field="PaymentMadeForDescription"
                  title="Service Type"
                /> */}
                <Column field="TransactionDate" title="Transaction Date"   footerCell={(props) => (
                    <td colSpan={props.colSpan} style={props.style}>{`Sub Total`}</td>
                  )}/>
                <Column
                  field="AmountPaid"
                  title="Amount Paid"
                  cell={amountCell}
                  footerCell={(props) => {
                    console.log(props)
                    const sum=Result?.reduce((acc: number, currentValue: any) => {
                      return acc + currentValue?.AmountPaid-currentValue?.Taxes
                    }, 0)
                    return <td colSpan={props.colSpan} style={props.style}>{CurrencyFormat(sum)}</td>
                  }
                  }
                />
                {checkSurchargesAvailable() && (<Column
                  field="Taxes"
                  title="Surcharges Collected"
                  cell={taxCell}
                  footerCell={(props) => {
                    const sum=Result?.reduce((acc: number, currentValue: any) => {
                      return acc + currentValue?.Taxes
                    }, 0)
                    return <td colSpan={props.colSpan} style={props.style}>{CurrencyFormat(sum)}</td>
                  }}
                />)}
              </Grid>
              <div className="text-right">

      </div>
            </>
          ) : (
            <></>
          )}
          
          <div className="bz-mt-2rem  bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Total Amount</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="d-flex align-items-center">
                  {CurrencyFormat(TotalAmount)}
                </div>
              </div>
            </div>
          </div>
          <div className="bz-content-text-row align-items-start">
            <div className="bz-content-title text-right">Comments</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <textarea
                className="form-control"
                onChange={(e) => handleChange(e.target.value, "Comments")}
              ></textarea>
              {showErrorMessage("Comments", "Comments")}
            </div>
          </div>
          {Result[0]?.PaymentMadeForDescription==="Group" ? (<>
            <div className="bz-content-text-row">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <Notify key="info" type={{ style: "info" }} className="mb-2">
              {`To initiate a refund for this transaction, please cancel the group appointments and issue the refund.`}
              </Notify>
            </div>
          </div>
          </>):(<>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <Button
                className="pt-2"
                onClick={() => props?.handleCloseViewDetail()}
              >
                Cancel
              </Button>
              <BuzopsButton
                loading={btnLoading}
                disabled={btnLoading}
                label={"Complete"}
                onClick={() => handleComplete()}
              />
            </div>
          </div>
          </>)}
          
        
        </div>
      </div>
      <BuzopsNotification
        notification={notification}
        message={message}
        handleNotification={(type: any) => handleNotification(type)}
      />
    </>
  );
};

export default Void;
