import { Text, Group, exportImage } from '@progress/kendo-drawing';
import { useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
const TypeSign = (props: any) => {
    const [signatureText, setSignatureText] = useState("");
    const [showStyles, setShowStyles] = useState(false);
    const [selectedStyle, setSelectedStyle] = useState<any>({'fontFamily' : 'alexbrush-regular'  });
    const  fontsArr = [
       [ {'fontFamily' : 'alexbrush-regular'  },
       {'fontFamily' : 'allura-regular'  }],
       [
        {'fontFamily' : 'blackjack'  },
        {'fontFamily' : 'dancingscript-regular'  }
       ],
       [
        {'fontFamily' : 'greatvibes-regular'  },
        {'fontFamily' : 'learning_curve_regular_ot_tt'  }
       ],
       [
        {'fontFamily' : 'blunt-webfont'  },
        {'fontFamily' : 'eager-webfont'}
       ],
        [
            {'fontFamily' : 'hoffm-webfont'},
        {'fontFamily' : 'jacoba_bold-webfont'}
        ],
       [
        {'fontFamily' : 'mrdehaviland-webfont'},
        {'fontFamily' : 'carolina-webfont' }
       ],
       [
        {'fontFamily' : 'champignon-webfont'}
       ]
    ];
        
    const renderDifferentFontText = () => {
        return fontsArr.map((val: any) => {
          return (
            <tr>
              {" "}
              {val.map((font:any) => {
                return (
                  <td
                  style={{width:'50%'}}
                    onClick={() => onFontSelect(font)}
                  >
                    <div className='bz-type-signature'  style={{ ...font, fontSize: "40px" }}>
                      {signatureText || "Type your signature"}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        });
    }
    const onFontExport = () => {
        const text = new Text(signatureText, [10, 10 ], {font: `400 40px ${selectedStyle?.fontFamily}`} );
        const group = new Group();
        group.append(text);
        const width = text.measure().width + 10 + 10;
        const height = text.measure().height + 10 + 10;
        exportImage(group, {
          width: width,
          height: height,
        }).then((data) => {
            props.getImage(data, signatureText, selectedStyle?.fontFamily,'type');
        });
    }
    const onFontSelect = (fontStyle: any) => {
        setSelectedStyle(fontStyle);
        setShowStyles(false);
        return true;
       
    }
    const onSignatureEnter = (e: any) => {
        setSignatureText(e.target.value)
    }

    const onClear = () => {
        setShowStyles(false);
        setSignatureText("");
    }
    return (
        <>
        <div className={"row"}>
           <div className="col-12">
           <input type="text" placeholder="Type your signature" className="form-control buzops-type-signature font-size-40" value={signatureText} style={{...selectedStyle, fontSize: "40px", padding: '10px'}} onChange={(e) => onSignatureEnter(e)} />
           </div>
        </div>
        <div className={"row typed-signature-styles mt-3 mb-4"} style={{maxHeight: "300px"}}>
        <div className="col-12 pb-2">
            <div style={{float: "right"}}>
                <span  className={` btn-link`}  onClick={() => setShowStyles((prev) => !prev)}>Change style {showStyles ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</span>
            </div>
            </div>
            <div className="col-12 pb-3">
            <table style={{border:0,width:'100%'}}>
            {showStyles && renderDifferentFontText()}
            </table>
            </div>
            {
                !showStyles && signatureText && (<div className="col-md-12 text-center d-flex justify-content-center">
                    <button  className="k-button" onClick={() => onClear()}>Clear</button>
                    <button  className="k-button k-primary" onClick={() => onFontExport()}>Apply</button>
                </div>)
            }
        </div>
        </>
    )
}
export default TypeSign;