import React, { useReducer } from 'react'
import Reducer from './Reducer'

const ContextApi = React.createContext()

const initialState = {
    error: null,
    email: '',
    isLoading: false
}

function AppContext(props){
    const [state, dispatch ] = useReducer(Reducer, initialState)
    return(
        <ContextApi.Provider value={{state, dispatch}}>
            {props.children}
        </ContextApi.Provider>
    )
}

export { ContextApi }

export default AppContext