import React, { useEffect, useState } from "react";
import MemberSelection from "components/submemberseletion/MemberSelection";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TenantService } from "services/tenant/index.service";
import { PackageService } from "services/package/index.service";
import { Upload } from "@progress/kendo-react-upload";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import {
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import BuzopsButton from "generic-components/BuzopsButton";
import { useApiClient } from "services/axios-service-utils";
import withNotification from "components/Hoc/withNotification";
import { Error } from "@progress/kendo-react-labels";
import { AgreementType } from "utils";

interface IEntityType {
  text: string;
  id: number;
}

interface IDocumentType {
  text: string;
  id: number;
}

interface IEntity {
  EntityName: string;
  EntityId: number;
}

const defaultEntityType: IEntityType = { text: "Please Select Entity Type", id: 0 };
const defaultDocumentType: IDocumentType = { text: "Please Select Document Type", id: 0 };

const EntityTypes = [
  defaultEntityType,
  { text: "Memberships", id: 1 },
  { text: "Packages", id: 6 },
  { text: "Waiver", id: 2 },
];

const DocumentTypes = [
  defaultDocumentType,
  { text: "Agreements", id: 1 },
  { text: "Others", id: 2 },
];

enum EntityTypeEnum {
  "NONE" = 0,
  "MEMBERSHIP" = 1,
  "PACKAGE" = 6,
  "WAIVER" = 2,
}

const FileAllowedExtensions= [
  ".pdf",
  ".docx",
  ".doc",
  ".jpg",
  ".jfif",
  ".webp",
  ".jpeg",
  ".png",
  ".rtf",
  ".txt",
  ".odt",
]
const MaxFileSize=5000000
const UploadAgreement = (props: any) => {
  const { axiosRequest } = useApiClient();
  const service = new TenantService();
  const packageservice = new PackageService();

  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<IEntity | null>(null);
  const [selectedUsermemberId, setSelectedUserMemberId] = useState(props?.UserMemberId);
  const [entityData, setEntityData] = useState([]);
  const [selectedEntityType, setSelectedEntityType] = useState<IEntityType>(defaultEntityType);
  const [selectedDocumentType, setSelectedDocumentType] = useState<IDocumentType>(defaultDocumentType);
  const [selectedDocumetName, setSelectedDocumetName] = useState("");
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);

  // Convert files to base64 and update state
  const convertFilesToBase64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const fileInfo = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          fileData.push({ ...fileInfo, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };

  useEffect(() => {
    convertFilesToBase64(originalFiles);
  }, [originalFiles]);

  useEffect(() => {
    if (props?.selectedItemId) {
      const entityTypeValue = EntityTypes?.find(
        (i: any) => i?.id === AgreementType[props?.type]
      );
      setSelectedEntityType(entityTypeValue || defaultEntityType);
      fetchPlanSummary(entityTypeValue?.id, props?.selectedItemId);
    }
  }, [props?.selectedItemId]);

  const handleFileAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
  };

  const handleFileRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  const fetchPlanSummary = async (id: any, entityId = 0) => {
    let data = [];
    if (id === EntityTypeEnum.MEMBERSHIP) {
      const req = {
        PageNumber: 0,
        PageSize: 32767,
        UserMemberId: selectedUsermemberId,
      };
      const res = await service?.getAllMemberPlanSummary(req);
      if (res?.length > 0) {
        data = res?.filter((i:any)=> i?.UserMemberId==selectedUsermemberId)?.map((item: any) => ({
          EntityId: item?.MemberPlanId,
          EntityName: item?.PlanName,
        }));
      }
    } else if (id === EntityTypeEnum.PACKAGE) {
      const req = {
        PageNumber: 0,
        PageSize: 32767,
        UserMemberId: selectedUsermemberId,
        SortBy: "PackageInstanceId",
        SortDirection: "ascending",
      };
      const res = await packageservice?.packageInstanceFilter(req);
      if (res?.Items?.length > 0) {
        data = res?.Items?.filter((i:any)=> i?.UserMemberId==selectedUsermemberId)?.map((item: any) => ({
          EntityId: item?.PackageInstanceId,
          EntityName: item?.Name,
        }));
      }
    }
    if (entityId) {
      const selectedItem = data?.find((i: any) => i?.EntityId == entityId);
      setSelectedEntity(selectedItem);
    }
    return data;
  };

  const handleEntityTypeChange = async (selectedOption: any) => {
    setSelectedEntityType(selectedOption);
    setSelectedEntity(null);
    let response: any = [];
    if (selectedOption?.id !== EntityTypeEnum.WAIVER) {
      response = await fetchPlanSummary(selectedOption?.id, props?.selectedItemId);
    }
    setEntityData(response || []);
  };

  const handleEntityChange = async (selectedOption: any) => {
    setSelectedEntity(selectedOption);
  };

  const updateSelectedClientData = (dataItem: any) => {
    setSelectedUserMemberId(dataItem?.UserMemberId);
    handleClear(true);
  };

  const loadClientSection = () => {
    const UserMemberId = selectedUsermemberId;
    if (UserMemberId) {
      return (
        <MemberSelection
          allClientsList={props?.AllClientsList}
          disabled={props?.IsChild}
          selectedUsermemberId={UserMemberId}
          handleUpdateClient={(val: any) => updateSelectedClientData(val)}
          width={"100%"}
        />
      );
    }
  };

  useEffect(() => {
    if (success) {
      let timer = setTimeout(() => {
        props.toggleUploadAgreement(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  const handleClear = (ignoreUsermeberId = false) => {
    if (!ignoreUsermeberId) {
      setSelectedUserMemberId(props?.UserMemberId);
    }
    setSelectedEntity(null);
    setEntityData([]);
    setSelectedEntityType(defaultEntityType);
    setOriginalFiles([]);
    setFiles([]);
    setSubmit(false);
  };

  const handleInputChange = (event: any) => {
    setSelectedDocumetName(event.target.value);
  };

  const handleDocumentTypeChange = (selectedOption: IDocumentType) => {
    setSelectedDocumentType(selectedOption);
  };

  const handleUploadAgreement = async () => {
    setSubmit(true);
    if (
      !selectedEntityType ||
      (!selectedEntity && selectedEntityType?.id !== EntityTypeEnum.WAIVER) ||
      !files.length ||
      !selectedDocumentType ||
      !selectedDocumetName
    ) {
      return;
    }
    if(files[0]?.Size > MaxFileSize){
      return
    }
    const isAllowed=FileAllowedExtensions?.find((i:any)=> i ===files[0]?.Extension)
    if(!isAllowed){
      return
    }

    let req: any = {
      UserMemberId: selectedUsermemberId,
      EntityType: selectedEntityType?.id,
      DocumentType: selectedDocumentType.text,
      DocumentName: selectedDocumetName,
      Files: files?.map((file) => file.ImageAsBase64),
    };

    if (selectedEntityType?.id !== EntityTypeEnum.WAIVER) {
      req.EntityId = selectedEntity?.EntityId;
    }

    setBtnLoading(true);
    await axiosRequest.post("Agreement/UploadSignedAgreement", req, {
      successCallBack: (response: any) => {
        setBtnLoading(false);
        props?.handleNotificationMessage("Document Uploaded successfully", "success");
        setSuccess(true);
      },
      errorCallBack: (response: any) => {
        setBtnLoading(false);
        props?.handleNotificationMessage(
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "Internal server error",
          "error"
        );
      },
    });
  };

  return (
    <>
      <h5 className="mb-3 bz_fs_1_5 affiliatemainheading">
        <button
          className="btn btn-primary bz-back-btn btn-sm mr-3"
          onClick={() => props.toggleUploadAgreement(false)}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;{"Upload Document"}
      </h5>
      <hr />
      <div className="bz-subsection-row one-time-charge">
        <div className="left-section uploaddocument">
          <div className="mt-1">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Client*</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="k-form-field-wrap">{loadClientSection()}</div>
              </div>
            </div>
          </div>
          <div className="mt-1">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Entity Type*</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="k-form-field-wrap">
                  <DropDownList
                    data={EntityTypes}
                    textField="text"
                    dataItemKey="id"
                    name="EntityId"
                    id="EntityId"
                    disabled={props?.IsChild}
                    value={selectedEntityType}
                    onChange={(event) => handleEntityTypeChange(event.target.value)}
                    style={{ width: "100%" }}
                  />
                  {submit && selectedEntityType?.id === 0 && (
                    <Error>{"Please select an Entity Type"}</Error>
                  )}
                </div>
              </div>
            </div>
          </div>
          {selectedEntityType?.id !== EntityTypeEnum.WAIVER && (
            <div className="mt-1">
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Entity*</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <div className="k-form-field-wrap">
                    <DropDownList
                      data={entityData || []}
                      textField="EntityName"
                      dataItemKey="EntityId"
                      name="Entity"
                      disabled={props?.IsChild}
                      id="Entity"
                      value={selectedEntity}
                      onChange={(event) => handleEntityChange(event.target.value)}
                      style={{ width: "100%" }}
                    />
                    {submit && !selectedEntity && selectedEntityType?.id !== EntityTypeEnum.WAIVER && (
                      <Error>{"Please select an Entity"}</Error>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mt-1">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Document Type*</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="k-form-field-wrap">
                  <DropDownList
                    data={DocumentTypes}
                    textField="text"
                    dataItemKey="id"
                    name="DocumenTypeId"
                    id="DocumenTypeId"
                    value={selectedDocumentType}
                    onChange={(event) => handleDocumentTypeChange(event.target.value)}
                    style={{ width: "100%" }}
                  />
                  {submit && selectedDocumentType?.id === 0 && (
                    <Error>{"Please select a Document Type"}</Error>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Document Name*</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="k-form-field-wrap">
                  <Input
                    type={"text"}
                    style={{ width: "100%" }}
                    id={"DocumentName"}
                    value={selectedDocumetName}
                    onChange={(e: any) => handleInputChange(e)}
                  />
                  {submit && selectedDocumetName === "" && (
                    <Error>{"Please enter Document Name"}</Error>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Upload Document*</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div className="k-form-field-wrap">
                  <Upload
                    autoUpload={false}
                    batch={false}
                    restrictions={{
                      allowedExtensions: FileAllowedExtensions,
                      maxFileSize: MaxFileSize,
                    }}
                    showActionButtons={false}
                    multiple={false}
                    onAdd={handleFileAdd}
                    withCredentials={false}
                    onRemove={handleFileRemove}
                    files={originalFiles}
                  />
                  {submit && originalFiles.length === 0 && (
                    <Error>{"Please upload a file"}</Error>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bz-content-text-row mt-4 pt-2">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <Button className="ml-0" disabled={btnLoading} onClick={() => handleClear()}>
                Reset
              </Button>
              <BuzopsButton
                type={"submit"}
                label={"Save"}
                loading={btnLoading}
                disabled={btnLoading || success}
                onClick={() => handleUploadAgreement()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withNotification(UploadAgreement);
