import { createSlice } from "@reduxjs/toolkit";
export const userSlice = createSlice({
  name: "userConfiguration",
  initialState: {
    userDetails: null, //must be /app or /external or /:providerId or /,
    loggedIn: false,
    affiliateDetails:null,
    IsEnforced:false,
    IsCardEnforced:false,
    IsFormsEnforced:false,
    UserMemberStatus:0
  },
  reducers: {
    setUserConfiguration: (state, action) => {
      const {
        userDetails= null,
        affiliateDetails= null,
        loggedIn= false,
      } = action.payload;
      state.userDetails = userDetails;
      state.affiliateDetails = affiliateDetails;
      state.loggedIn = loggedIn;
    },
    setEnforceStatus:(state,action)=> {
      state.IsEnforced=action.payload.IsEnforced;
      state.IsCardEnforced=action.payload.IsCardEnforced;
      state.IsFormsEnforced=action.payload.IsFormsEnforced;
      state.UserMemberStatus=action.payload.UserMemberStatus;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setUserConfiguration,setEnforceStatus } = userSlice.actions;

export default userSlice.reducer;
