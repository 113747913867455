import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { CurrencyFormat, CustomToFixed, checkHasTaxEnable } from "utils";
import {
  DropDownList,
  ListItemProps,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import React, { useEffect } from "react";
import useState from "react-usestateref";
import moment from "moment";
import {
  Checkbox,
  Input,
  NumericTextBox,
  RadioGroup,
  Switch,
} from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { TenantService } from "services/tenant/index.service";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { filterBy } from "@progress/kendo-data-query";
import BuzopsWindow from "generic-components/BuzopsWindow";
import SignaturePad from "components/signaturePad";
import PaymentCheckout from "components/PaymentCheckout/PaymentCheckout";
import images from "utils/images";
import { GetLocalStore } from "utils/storage";
import { Hint } from "@progress/kendo-react-labels";
import CreateClient from "../clientCreation/CreateClient";
import { Tooltip } from "@progress/kendo-react-tooltip";
import withNotification from "components/Hoc/withNotification";
import ClientNameWithMemberType from "../ClientNameWithMemberType";
import useCustomFieldsUpdate from "components/ManageCustomFields/CustomFieldsUpdate.hook";
import { CouponDurationType, CreditsErrorMessage } from "utils/form-utils";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { BiInfoCircle } from "react-icons/bi";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import TermsAndConditions from "components/checkout/TermsAndConditions";
import { PermissionEnum } from "utils/PermissionEnum";
import CheckPermissions from "utils/permissions";
import MemberSelection from "components/submemberseletion/MemberSelection";
import bzImages from "./../../../Images";
const AssignPlan = (props: any) => {
  const service = new TenantService();
  const [paymentProfile, setPaymentProfiles] = useState<any>([]);
  //To client details to submit
  const [Taxes, setTaxes] = useState<any>([]);
  const [PredictedTaxes, setPredictedTaxes] = useState<any>([]);
  const [IsTaxSkipped, setIsTaxSkipped] = useState<boolean>(true);
  const [TotalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const [PredictedTotalTaxAmount, setPredictedTotalTaxAmount] = useState<number>(0);
  const [clientSubmitDetails, setClientSubmitDetails] = useState<any>(null);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState<any>(0);
  const [billingDays, setBillingDays] = useState<any>([]);
  const [clientsList, setClientsList] = useState<any>([]);
  const [salesData, setSalesData] = useState<any>([]);
  const [disable, setDisable] = useState(false);
  const [startDate, setStartDate] = useState<Date | null | string>(moment().format("L"))
  const [filterplandata, setfilterPlanData] = useState<any>([]);
  const [filterAddondata, setfilterAddonData] = useState<any>([]);
  const [confirmClientDialog, setConfirmClientDialog] = useState(false);
  const initialData = {
    HasAutoRenewal: false,
    CanAutoRenewalPayInFull: false,
    CanPayInFull: false,
    PayLater: false,
    DisableAutoRenewal: true,
    DisableAutoRenewalPayInFull: true,
    DisablePayInFull: true,
    DisablePayLater: true,
    StartDate: moment().format("L"),
    PurchaseDate: moment().format("L"),
    BillingType: 1,
    CardDescription: "",
    PaymentGatewayPaymentProfileId: 0,
    CustomerProfileId: "",
    PaymentProfileId: "",
    MaskedCCNumber: "",
    UserMemberId: props?.UserMemberId,
    MemberNumber: "",
    MemberName: "",
    Email: "",
    MemberType: 0,
    MemberTypeDescription: "",
    Photo: null,
    AgreementContent: null,
    AdditionalFee: 0,
    CanFeesWaived: false,
    EndDate: null,
    RecurrenceEndDate: "",
    IsEndDateOverridden: false,
    RecurringAmount: 0,
    PlanStatus: 0,
    DiscountAmount: 0,
    DownPayment: 0,
    MembershipDownPayment: 0,
    RefundAmount: 0,
    PreviousPlanRemainingAmount: 0,
    TransactionStatus: null,
    IsTransactionSucced: false,
    PaymentGatewayTransactionHistoryId: 0,
    ShowPayInFull: true,
    SelectedAddons: [],
    BillingDay: 0,
    SalesPersonId: 0,
    MemberPlanId: 0,
    Coupons: [],
    DisableCoupon: false,
    Coupon: [],
    MembershipPlanDetail: null,
    ProratedRate: 0,
  };

  const [submitPlanData, setSubmitPlanData,submitPlanDataRef] = useState<any>(initialData);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const customFields = useCustomFieldsUpdate({ ...props, CustomFields: submitPlanData?.MembershipPlanDetail?.CustomFields || [] })

  const [finalData, setFinalData] = useState<any>(null);
  const [filterCoupondata, setfilterCouponData] = useState<any>([]);
  const [plandata, setPlanData] = useState<any>([]);
  const [addondata, setAddonData] = useState<any>([]);
  const [paymentMethod, setPaymentMethod] = useState({
    card: false,
    bank: false,
  });
  //checkbox data
  const [selected, setSelected] = useState<any>("5");
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [paymentGatewayType, setPaymentGatewayType] =
    useState<any>(paymentGatewaytype);
  const [visibleAgreement, setVisibleAgreement] = useState(false);
  const [addClient, setAddClient] = useState(false);
  const [agreementTemplate, setAgreementTemplate] = useState("");
  const [visibleSignPad, setVisibleSignPad] = useState(false);
  const [termsandConditions, setTermsandConditions] = useState<boolean>(true);
  const [termsandConditionsDialog, setTermsandConditionsDialog] =
    useState<boolean>(false);
  //base64 encode signature
  const [signature, setSignature] = useState("");

  //for buzops button loading for card submit
  const [cardSubmitBtnLoading, setCardSubmitBtnLoading] = useState(false);

  //for buzops button loading for bank submit
  const [bankSubmitBtnLoading, setBankSubmitBtnLoading] = useState(false);

  const [signatureText, setSignatureText] = useState<any>("");
  const [signatureFont, setSignatureFont] = useState<any>("");
  const [signatureType, setSignatureType] = useState<any>("");

  //  payment profile card initial form values
  const cardIntialValues = {
    MaskedCCNumber: undefined,
    CardTypeId: undefined,
    ExpiryMonthYear: undefined,
    ExpiryMonth: undefined,
    ExpiryYear: undefined,
    ZipCode: undefined,
    PaymentType: "Card",
    UserMemberId: props?.UserMemberId,
  };
  const [cardFormValues, setCardFormValues] = useState<any>(cardIntialValues);

  // credit cards image placeholder
  const [cardImageIcon, setcardImageIcon] = useState(images.placeholder);
  //  payment profile bank initial form values
  const bankIntialValues = {
    AccountName: undefined,
    BankName: undefined,
    BankAccountType: undefined,
    RoutingNumber: undefined,
    AccountNumber: undefined,
    UserMemberId: props?.UserMemberId,
    PaymentType: "Bank",
  };
  const [bankFormValues, setBankFormValues] = useState<any>(bankIntialValues);
  //selected payment profile
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState<any>({
    selectedPayment: undefined,
  });

  //payment profiles to submit
  const [paymentSubmitProfile, setPaymentSubmitProfile] = useState<any>(null);

  const [loading, setLoading] = useState(true);

  const [coupondata, setCouponData] = useState<any>([]);

  const [btnLoading, setBtnLoading] = useState(false);

  //to auto populate latest submitted payment profile
  const [populateProfile, setPopulateProfile] = useState<any>(null);
  // after submission of card or bank need to update payment profile with latest one

  useEffect(() => {
    const { selectedPayment } = selectedPaymentProfile;
    const { bank, card } = paymentMethod;
    if (
      populateProfile &&
      !selectedPayment &&
      paymentProfile &&
      !bank &&
      !card && paymentProfile?.length > 0
    ) {
      const index = paymentProfile.findIndex((i: any) => i?.PaymentGatewayPaymentProfileID === populateProfile?.PaymentGatewayPaymentProfileID)
      if (index !== -1) {
        const selectPayment: any = paymentProfile[index];
        setSelectedPaymentProfile({
          ...selectedPaymentProfile,
          selectedPayment: selectPayment,
        });
        generatePaymentProfileObj(selectPayment);
      }
    }
  }, [paymentProfile]);

  const generatePaymentProfileObj = (val: any) => {
    const paymentDetails = {
      CustomerProfileID: val?.CustomerProfileID,
      PaymentProfileID: val?.PaymentProfileID,
      PaymentGatewayPaymentProfileID: val?.PaymentGatewayPaymentProfileID,
      MaskedCCNumber: val?.MaskedCCNumber,
      CardTypeId: val?.CardTypeId,
      CardDescription: val?.CardDescription,
      BankAccountType: val?.BankAccountType,
      Credit: val?.Credit,
    };

    setPaymentSubmitProfile(paymentDetails);
    const data = {
      ...submitPlanDataRef?.current,
      CardDescription: val?.CardDescription,
      PaymentGatewayPaymentProfileId: val?.PaymentGatewayPaymentProfileID,
      CustomerProfileId: val?.CustomerProfileID,
      PaymentProfileId: val?.PaymentProfileID,
      MaskedCCNumber: val?.MaskedCCNumber,
      CardTypeId: val?.CardTypeId,
      Credit: val?.Credit,
    };
    setSubmitPlanData(data);
    setPopulateProfile(null);

    if(paymentDetails?.CardDescription?.trim().toUpperCase() === "CASH" ){
      if(submitPlanDataRef?.current?.HasAutoRenewal==true ){
        let errormsg = "Auto Renew cannot be selected for cash payments";
        props?.handleNotificationMessage(errormsg);
      }
            submitPlanDataRef.current.DisableAutoRenewal= true;
            submitPlanDataRef.current.DisableAutoRenewalPayInFull= true;
            submitPlanDataRef.current.HasAutoRenewal= false;
            submitPlanDataRef.current.CanAutoRenewalPayInFull= false;
    }else{
      submitPlanDataRef.current.DisableAutoRenewal= false;
    }

    if(paymentDetails?.CardDescription?.trim().toUpperCase() === "CASH" || !(moment(submitPlanDataRef.current.StartDate).diff(moment(submitPlanDataRef.current.PurchaseDate), 'months') < 18 && moment(submitPlanDataRef.current.StartDate).diff(moment(submitPlanDataRef.current.PurchaseDate), 'days') > 0 )){
      if(submitPlanDataRef?.current?.PayLater==true){
        let errormsg = "The 'Pay Later' option cannot be selected for cash payments";
        props?.handleNotificationMessage(errormsg);
      }
      submitPlanDataRef.current.PayLater= false;
      submitPlanDataRef.current.DisablePayLater= true;
    }else{
      submitPlanDataRef.current.DisablePayLater= false;
    }
  };
  const fetchTaxes = async () => {
    const req = {};
    const taxService = new ManageTaxesService()
    const res = await taxService.getTaxes(req);
    setTaxes(res);
    return res
  };
  // to select the payment profile
  const handleChangeSavedCard = (val: any) => {
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      selectedPayment: val,
    });
    setCardFormValues(cardIntialValues);
    setBankFormValues(bankIntialValues);
    generatePaymentProfileObj(val);
    setPaymentMethod({
      ...paymentMethod,
      card: false,
      bank: false,
    });
    setSelected("0");
  };

  console.log('submitPlanData', submitPlanData);

  // handle bank form submit
  const handleBankSubmit = async (dataItem: any) => {
    setBankSubmitBtnLoading(true);
    const saveBankDetails = new TenantService();
    const req = { ...dataItem, BankAccountType: dataItem?.BankAccountType?.id }
    const result = await saveBankDetails.createPaymentProfile(req);
    setBankSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPopulateProfile(result?.Item);
      setSelected("0");
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      setPaymentMethod({
        ...paymentMethod,
        card: false,
        bank: false,
      });
      fetchPaymentProfiles(props?.UserMemberId);
      handleSubmit("text");
    } else {
      setBankFormValues(dataItem);
      setCardFormValues(cardIntialValues);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  // handle payment profile submit
  const handleSubmit = (dataItem: any = null) => {
    if (selected === "0") {
      setSelected(undefined);
    } else {
      setSelected("3");
    }
  };
  // to handle panel bar expand and collpase
  const onSelect = (e: any) => {
    if (!e?.target?.props?.expanded) {
      setSelected(e?.target?.props?.id);
    } else {
      setSelected("5");
    }
  };

  // to handle credit card image icon
  const handleCardImageIcon = (imageUrl: any) => {
    setcardImageIcon(imageUrl);
  };

  // handle card form submit
  const handleCardSubmit = async (dataItem: any) => {
    setCardSubmitBtnLoading(true);
    const saveCardDetails = new TenantService();
    if (paymentGatewayType === 1) {
      dataItem.MaskedCCNumber = dataItem.MaskedCCNumber.split(" ").join("");
    }
    const result = await saveCardDetails.createPaymentProfile(dataItem);
    setCardSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPopulateProfile(result?.Item);
      setSelected("0");
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      setPaymentMethod({
        ...paymentMethod,
        card: false,
        bank: false,
      });
      setcardImageIcon(images.placeholder);
      fetchPaymentProfiles(props?.UserMemberId);
      handleSubmit("text");
    } else {
      setCardFormValues(dataItem);
      setBankFormValues(bankIntialValues);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  // handle switching between saved payment  & card & bank forms
  const handleForm = (form: string) => {
    setcardImageIcon(images.placeholder);
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      selectedPayment: undefined,
    });
    setPaymentSubmitProfile(null);
    if (form === "bank") {
      setPaymentMethod({ ...paymentMethod, bank: true, card: false });
    } else {
      setPaymentMethod({ ...paymentMethod, bank: false, card: true });
    }
  };

  const endafter_addon = [
    {
      label: "Never",
      value: 0,
    },
    {
      label: "No of occurrence",
      value: 1,
    },
  ];
  const Frequencytype_addon = [
    { text: "ONE TIME", id: 1 },
    { text: "DAILY", id: 2 },
    { text: "MONTHLY", id: 4 },
    { text: "YEARLY", id: 5 },
  ];

  //to saving signature
  const generatedImage = (
    imageString: string,
    text?: string,
    font?: string,
    Type?: string
  ) => {
    setSignature(imageString);
    setSignatureText(text);
    setSignatureFont(font);
    setSignatureType(Type || "");
    setTermsandConditions(true);
    setTermsandConditionsDialog(false);
  };

  useEffect(() => {
    fetchAllApis();
    generateBillingDays()
  }, []);
  const generateBillingDays = () => {
    let days = []
    for (let i = 1; i <= 28; i++) {
      const obj = {
        text: i,
        value: i
      }
      days.push(obj)
    }
    setBillingDays(days)
  }
  //apis
  const fetchAllApis = async () => {
    setLoading(true);
    let memberId = props?.UserMemberId;
    await fetchClientData(memberId);
    await getSignUpData();
    await fetchSalesPersons();
    await getPlanData();
    await getAddonData();
    if (props?.location?.state?.planItem) {
      await handlePlanChange(props?.location?.state?.planItem);
    }
    await fetchPaymentProfiles(memberId);
    setLoading(false);
  };

  async function fetchPaymentProfiles(userMemberId: any) {
    const result = await service.paymentProfile(userMemberId);
    if (result) {
      setPaymentProfiles(result);

      let selectedCard;
      const cardType1 = result.find((card: any) => card.CardTypeId >= 1 && card.CardTypeId < 8);
      const cardType2 = result.find((card: any) => card.CardTypeId === 0);
      const cardType3 = result.find((card: any) => card.CardTypeId === 8);

      if (cardType1) {
        selectedCard = cardType1;
      } else if (cardType2) {
        selectedCard = cardType2;
      } else if (cardType3) {
        selectedCard = cardType3;
      } else if(submitPlanDataRef?.current?.TotalBeforeSurcharges !== 0){
        selectedCard = result.find((card: any) => card.CardTypeId === -1);
      } else {
        selectedCard = [];
      }

      if (selectedCard) {
        handleChangeSavedCard(selectedCard);
      }
    }
  }

  const fetchSalesPersons = async () => {
    const includeAffiliates = false
    const userDetails = GetLocalStore("userDetails");
    let personId = userDetails?.UserSession?.UserId;
    const res = await service.SalesPerson(includeAffiliates);
    setSalesData(res);
    if (personId) {
      const salesPersonRecord = res?.find(
        (item: any) => item?.UserId === personId
      );
      if (salesPersonRecord) {
        personId = salesPersonRecord?.UserId;
      } else {
        personId = res[0]?.UserId;
      }
    }
    setSelectedSalesPerson(personId);
  };

  const handleTermsConditions = (val: boolean) => {
    setTermsandConditions(val);
    setTermsandConditionsDialog(false);
  };


  const handleTermsConditionDialog = (val: any) => {
    let errorvalidatin = handleValidation("submit");
    if (errorvalidatin !== "") {
      props?.handleNotificationMessage(errorvalidatin, "error");
      return;
    }
    if (val === true || val === false) {
      setTermsandConditions(val);

    } else {
      setTermsandConditionsDialog(!termsandConditionsDialog);
    }

  };

  const handleIsTaxSkipped = () => {
    setIsTaxSkipped(!IsTaxSkipped)
  }

  const fetchClientData = async (usermemberVal: any, success = false) => {
    let clientSubmitDetails = null;
    const res = await service.GetSubMembersWithParent(props?.UserMemberId);
    let result = res?.find(
      (item: any) => item?.UserMemberId === props?.UserMemberId
    );
    if (success) {
      result = res[res?.length - 1]
    }
    if (result) {
      clientSubmitDetails = {
        Email: result?.Email,
        FirstName: result?.FirstName,
        Photo: result?.Photo,
        LastName: result?.LastName,
        FullName: result?.FullName,
        PrimaryMemberId: result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        MemberType: result?.MemberType,
        Status: result?.UserMemberStatus || result?.Status
      };
    }

    setClientsList(res);
    setClientSubmitDetails(clientSubmitDetails);
  };

  const getPlanData = async () => {
    const req = {};
    const res = await service.AvailablePlansToSubscribe(req);
    setPlanData(res);
    setfilterPlanData(res);
  };

  const getCouponData = async (planManagementId: any) => {
    let memberId = props?.UserMemberId;
    const req = {
      UserMemberId: memberId,
      EntityType: 1,
      EntityId: planManagementId,
      CouponType: 1
    };
    const res = await service.AvailableCouponsToApply(req);
    setCouponData(res);
    setfilterCouponData(res);
  };
  const getCouponDataAddon = async (selectedAddons: any) => {
    let memberId = props?.UserMemberId;
    let initialValue = { ...submitPlanData };
    if (selectedAddons?.length > 0) {
      const req = {
        UserMemberId: memberId,
        EntityType: selectedAddons[selectedAddons.length - 1]?.EndType,
        EntityId: selectedAddons[selectedAddons.length - 1]?.AddonTemplateId,
      };
      const res = await service.AvailableCouponsToApply(req);
      const addonsData = selectedAddons.map((i: any) => {
        if (i?.AddonTemplateId === req?.EntityId) {
          return {
            ...i,
            StartDate: moment(submitPlanData?.StartDate).format("L"),
            AllCoupons: res,
          };
        } else {
          return { ...i };
        }
      });
      initialValue = {
        ...submitPlanData,
        SelectedAddons: addonsData,
      };
    } else {
      initialValue = {
        ...submitPlanData,
        SelectedAddons: [],
      };
    }
    updateTotalAmount(initialValue);
  };

  const getSignUpData = async () => {
    const req = {
      PrimaryUserMemberId: props?.UserMemberId,
      UserMemberId: props?.UserMemberId,
    };
    const res = await service.SignUpDetails(req);
    setFinalData(res);
  };
  const toggleClientConfirmDialog = () => {
    setConfirmClientDialog(!confirmClientDialog);
  };
  const handleClientConfirmation = () => {
    let errorvalidatin = handleValidation('submit');
    if (errorvalidatin !== "") {
      props?.handleNotificationMessage(errorvalidatin, "error");
      return;
    }
    if (!termsandConditions) {
      props?.handleNotificationMessage(
        `Please Accept Terms and Conditions`,
        "error"
      );
      return;
    }
    if (clientsList?.length > 1) {
      // const selectedClientInfo = clientsList?.filter(
      //   (res: any) => res?.UserMemberId === clientSubmitDetails?.UserMemberId
      // )[0]
      // if (selectedClientInfo?.MemberType === 0) {
        toggleClientConfirmDialog()
        return
      // } else {
      //   handleFinalSubmit("submit")
      // }
    } else {
      handleFinalSubmit("submit")
    }
  }

  const renderClientConfirmationDialog = () => {
    const selectedClientInfo = clientsList?.filter(
      (res: any) => res?.UserMemberId === clientSubmitDetails?.UserMemberId
    )[0]
    return (
      <Dialog
        className="bz-book-Appoinment-dialog"
        title={"Please Confirm"}
        onClose={() => toggleClientConfirmDialog()}
        width={"35%"}
      >
        <div>
          <p className={"dialogContainer"}>
            {`Looks like you have additional family members on account, please confirm you are making purchases for the below client.`}
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <ClientNameWithMemberType
              clientName={selectedClientInfo?.FullName}
              memberType={selectedClientInfo?.MemberType}
            />
          </div>
          <div className="column-button-section book-appt-btns">
            <Button primary={false} onClick={() => toggleClientConfirmDialog()}>
              No
            </Button>
            <BuzopsButton
              label={"Yes"}
              onClick={() =>
                handleFinalSubmit("submit")
              }
            />
          </div>
        </div>
      </Dialog>
    );
  };
  const getAddonData = async () => {
    const req = {};
    const res = await service.AvailableAddonsToSubscribe(req);
    const updatedResult = res?.map((i: any) => {
      if (i?.Name === "Custom") {
        return { ...i, CanCollectTaxes: checkHasTaxEnable() }
      } else {
        return { ...i }
      }
    })
    setAddonData(updatedResult);
    setfilterAddonData(updatedResult);
  };

  const handleChangeAddonCoupon = (e: any, index: any) => {
    let initialVal = { ...submitPlanData };
    let planData = { ...submitPlanData };
    if (planData?.SelectedAddons?.length !== 0) {
      if (e?.length !== 0) {
        let coup = 0;
        e.forEach((res: any) => {
          if (res?.TypeDescription === "Percentage") {
            let percentageAmt = 0;
            if (
              planData?.SelectedAddons[index]?.IsProrated &&
              planData?.SelectedAddons[index].ProratedAmount !== 0
            ) {
              let calcultePercentageCoupon =
                res?.Amount *
                (planData?.SelectedAddons[index]?.ProratedAmount / 100);
              if (res?.DurationType === 3) {
                calcultePercentageCoupon = 0;
              }
              coup = coup + calcultePercentageCoupon;
              percentageAmt = calcultePercentageCoupon;
            } else {
              coup =
                coup +
                (planData?.SelectedAddons[index]?.Charge / 100) * res?.Amount;
              percentageAmt =
                (planData?.SelectedAddons[index]?.Charge / 100) * res?.Amount;
            }
            initialVal.SelectedAddons[index].PercentageCoupon = percentageAmt;
          } else if (res?.TypeDescription === "Fixed") {
            let calculteFixedCoupon = res?.Amount;
            if (
              res?.DurationType === 3 &&
              planData?.SelectedAddons[index]?.IsProrated
            ) {
              calculteFixedCoupon = 0;
            }
            coup = coup + calculteFixedCoupon;
            initialVal.SelectedAddons[index].FixedCoupon = calculteFixedCoupon;
          }
        });

        if (coup > planData?.SelectedAddons[index]?.Charge) {
          coup = planData?.SelectedAddons[index]?.Charge;
        }

        initialVal.SelectedAddons[index].coupamt = coup;

        let final_coup_data: any[] = [];
        const couponsList = e.sort(function (a: any, b: any) {
          return b.Amount - a.Amount;
        });
        const couponPercentage = couponsList.filter(
          (res: any) => res?.TypeDescription === "Percentage"
        );
        if (couponPercentage.length > 0) {
          final_coup_data.push(couponPercentage[0]);
        }
        const couponFixed = couponsList.filter(
          (res: any) => res?.TypeDescription === "Fixed"
        );

        if (couponFixed.length > 0) {
          final_coup_data.push(couponFixed[0]);
        }

        //adding coupon data
        initialVal.SelectedAddons[index].Coupon = final_coup_data;

        initialVal = {
          ...initialVal,
          SelectedAddons: initialVal?.SelectedAddons,
        };
        updateTotalAmount(initialVal);
      } else {
        initialVal.SelectedAddons[index].coupamt = 0;
        initialVal.SelectedAddons[index].Coupon = [];

        initialVal = {
          ...initialVal,
          SelectedAddons: initialVal?.SelectedAddons,
        };
        updateTotalAmount(initialVal);
      }
    }
  };
  const handleChangeAddonElement = (e: any, from: any, index: any) => {
    let initialVal = submitPlanData;

    if (from === "EndType") {
      initialVal.SelectedAddons[index].EndType = e;
      if (e === 1) {
        initialVal.SelectedAddons[index].Occurrence = 2;
      }
    }

    if (from === "Description")
      initialVal.SelectedAddons[index].Description = e;

    if (from === "Charge") initialVal.SelectedAddons[index].Charge = e;

    if (from === "Occurrence") initialVal.SelectedAddons[index].Occurrence = e;

    if (from === "StartDate")
      initialVal.SelectedAddons[index].StartDate = moment(e).format("L");

    if (from === "Frequency") {
      initialVal.SelectedAddons[index].Frequency = e?.id;
      if (e.id === 1) {
        initialVal.SelectedAddons[index].EndType = 0;
        initialVal.SelectedAddons[index].Occurrence = 0;
        initialVal.SelectedAddons[index].RepeatFrequency = 0;
      } else {
        initialVal.SelectedAddons[index].EndType = 0;
        initialVal.SelectedAddons[index].RepeatFrequency = 1;
      }
    }

    if (from === "RepeatFrequency")
      initialVal.SelectedAddons[index].RepeatFrequency = e;

    if (from === "IsTaxSkipped")
      initialVal.SelectedAddons[index].IsTaxSkipped = e;

    if (
      from === "IsProrated" ||
      initialVal.SelectedAddons[index].IsProrated ||
      from === "StartDate"
    ) {
      if (
        initialVal.SelectedAddons[index].IsProrated ||
        from === "IsProrated"
      ) {
        if (from === "IsProrated") {
          initialVal.SelectedAddons[index].IsProrated = e;
        }

        const currentDate = new Date(initialVal.SelectedAddons[index].StartDate);
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;

        const currentMonthLastDate = new Date(
          currentYear,
          currentMonth,
          0
        ).getDate();

        const daysLeftInMonth = currentMonthLastDate - currentDate.getDate() + 1;

        let plan_per_day_cost =
          initialVal.SelectedAddons[index]?.Charge / currentMonthLastDate;
        let pros = daysLeftInMonth * plan_per_day_cost;

        initialVal.SelectedAddons[index].ProratedAmount = pros;
      } else {
        if (from === "IsProrated") {
          initialVal.SelectedAddons[index].IsProrated = e;
          initialVal.SelectedAddons[index].ProratedAmount = 0;
        }
      }
    }

    initialVal = {
      ...submitPlanData,
      SelectedAddons: initialVal?.SelectedAddons,
    };
    updateTotalAmount(initialVal);
    if (initialVal?.SelectedAddons[index]?.Coupon) {
      handleChangeAddonCoupon(initialVal?.SelectedAddons[index]?.Coupon, index);
    }
  };

  const updateTotalAmount = async (dataItem: any, taxItems = Taxes) => {
    let total = 0;
    let coup = 0;
    let pros = 0;
    let eftAmount = 0;
    let acceptLimitedCoupon = false
    if (dataItem?.MembershipPlanDetail?.MonthlyRate !== undefined || dataItem?.MembershipPlanDetail?.MonthlyRate !== null) {
      //prosperty
      if (dataItem?.BillingType === 1 || dataItem?.BillingType === 3) {
        const currentDate = new Date(dataItem?.StartDate);
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentMonthLastDate = new Date(
          currentYear,
          currentMonth,
          0
        ).getDate();
        let plan_per_day_cost = 0
        let daysLeftInMonth = 0
        if (dataItem?.BillingType === 3) {
          if (dataItem.BillingDay > currentDate.getDate()) {
            const totalDaysInMonth = new Date(
              currentYear,
              currentMonth - 1,
              0
            ).getDate();
            daysLeftInMonth = dataItem.BillingDay - currentDate.getDate()
            plan_per_day_cost = dataItem?.MembershipPlanDetail?.MonthlyRate / totalDaysInMonth;
          } else {
            const totalDaysInMonth = new Date(
              currentYear,
              currentMonth,
              0
            ).getDate();
            daysLeftInMonth = (currentMonthLastDate - currentDate.getDate() + 1) + dataItem.BillingDay - 1
            plan_per_day_cost = dataItem?.MembershipPlanDetail?.MonthlyRate / totalDaysInMonth;
          }
        } else {
          daysLeftInMonth = currentMonthLastDate - currentDate.getDate() + 1;
          plan_per_day_cost = dataItem?.MembershipPlanDetail?.MonthlyRate / currentMonthLastDate;
        }
        pros = daysLeftInMonth * plan_per_day_cost;
        if (currentDate.getDate() === 1 && dataItem?.BillingType === 1) {
          pros = 0;
          acceptLimitedCoupon = true
        }
        if ((dataItem?.BillingType === 3 && currentDate.getDate() === dataItem.BillingDay) || !dataItem.BillingDay) {
          pros = 0;
          acceptLimitedCoupon = true
        }
      } else {
        acceptLimitedCoupon = true
      }
      //auto renewal
      if (
        dataItem?.CanPayInFull &&
        dataItem?.MembershipPlanDetail?.Duration > 0 && dataItem?.MembershipPlanDetail?.DurationType !== 3
      ) {
        total =
          total +
          dataItem?.MembershipPlanDetail?.MonthlyRate *
          dataItem?.MembershipPlanDetail?.Duration;
        if (pros !== 0) {
          total = total + pros;
        }
      } else {
        if (pros !== 0) {
          total = total + pros;
        } else {
          total = total + dataItem?.MembershipPlanDetail?.MonthlyRate;
        }
      }

      //fee
      if (dataItem?.MembershipPlanDetail?.PlanManagementFee?.length > 0 && !dataItem?.CanFeesWaived) {
        const cost = dataItem?.MembershipPlanDetail?.PlanManagementFee?.reduce(
          (acc: Number, currentValue: any) => {
            if (currentValue?.CanFeesRemoved !== undefined && currentValue?.CanFeesRemoved) {
              return acc;
            } else
              if (currentValue?.editedAmount !== undefined && currentValue?.editedAmount !== null) {
                return acc + currentValue?.editedAmount;
              } else {
                return acc + currentValue?.Amount;
              }
          },
          0
        );
        console.log("cost = ", cost)

        total = total + cost;
      }
      //PreviousPlanRemainingAmount
      if (dataItem?.PreviousPlanRemainingAmount > 0) {
        total = total + dataItem?.PreviousPlanRemainingAmount;
      }

      if (dataItem?.Coupon?.length !== 0) {
        dataItem?.Coupon.forEach((res: any) => {
          if (res?.TypeDescription === "Percentage") {
            let calcultePercentageCoupon = res?.Amount * (total / 100);
            if (res?.DurationType === 3 && !acceptLimitedCoupon) {
              calcultePercentageCoupon = 0;
            }
            coup = coup + calcultePercentageCoupon;
            const percentageValue = calcultePercentageCoupon;
            dataItem.MembershipPlanDetail.PercentageCoupon = percentageValue;
            total = total - percentageValue;
          } else if (res?.TypeDescription === "Fixed") {
            let calculteFixedCoupon = res?.Amount;
            if (res?.DurationType === 3 && !acceptLimitedCoupon) {
              calculteFixedCoupon = 0;
            }
            coup = coup + calculteFixedCoupon;
            dataItem.MembershipPlanDetail.FixedCoupon = calculteFixedCoupon;
            total = total - calculteFixedCoupon;
          }
        });
        dataItem.MembershipPlanDetail.coupamt = coup;
      }
    }
    if (dataItem?.PayLater) {
      eftAmount = total;
    }
    //prorated for pif
    if (total === undefined || total == null || total < 0) {
      total = 0;
    }
    //addon
    let coup_addon = 0,
      coup_addon_temp = 0,
      addon_total = 0,
      addon_total_with_payLater = 0;
    if (dataItem?.SelectedAddons?.length > 0) {
      let selectedAddons = dataItem?.SelectedAddons
      if (dataItem?.PayLater) {
        selectedAddons = selectedAddons?.map((i: any) => {
          if (moment(dataItem?.StartDate).format('L') > moment(i?.StartDate).format('L')) {
            return { ...i, StartDate: moment(dataItem?.StartDate).format('L') }
          }
          return { ...i }
        })
        dataItem.SelectedAddons = selectedAddons
      }
      const updatedAddons = selectedAddons.map((item: any) => {
        const x = new Date();
        const y = new Date(item?.StartDate);
        let taxableAmt = 0
        let predictedTaxableAmt = 0
        if (x < y) {
          if (item?.IsProrated) {
            predictedTaxableAmt = item?.ProratedAmount
            addon_total_with_payLater = addon_total_with_payLater + item?.ProratedAmount
          } else {
            predictedTaxableAmt = item?.Charge
            addon_total_with_payLater = addon_total_with_payLater + item?.Charge

          }
          if (item?.coupamt) {
            coup_addon = coup_addon + item?.coupamt;
            taxableAmt = 0
            predictedTaxableAmt = predictedTaxableAmt - item?.coupamt
          }
        } else {
          //addon ProratedAmount
          if (item?.IsProrated) {
            addon_total = addon_total + item?.ProratedAmount;
            addon_total_with_payLater = addon_total_with_payLater + item?.ProratedAmount;
            taxableAmt = item?.ProratedAmount;
          } else {
            addon_total = addon_total + item?.Charge;
            addon_total_with_payLater = addon_total_with_payLater + item?.Charge;
            taxableAmt = item?.Charge;
          }
          predictedTaxableAmt = taxableAmt
          if (item?.coupamt) {
            coup_addon_temp = coup_addon_temp + item?.coupamt;
            coup_addon = coup_addon + item?.coupamt;
            taxableAmt = taxableAmt - item?.coupamt
            predictedTaxableAmt = predictedTaxableAmt - item?.coupamt
          }
        }
        if (checkHasTaxEnable() && item?.CanCollectTaxes && !item?.IsTaxSkipped) {
          if (taxableAmt <= 0) {
            taxableAmt = 0
          }
          if (predictedTaxableAmt <= 0) {
            predictedTaxableAmt = 0
          }
          const taxCal = handleTaxCalculation(taxableAmt, Taxes, true)
          const predictedTaxCal = handleTaxCalculation(predictedTaxableAmt, Taxes, true)
          return { ...item, Taxes: taxCal?.TaxesArray, TotalTaxAmount: taxCal?.taxAmt, IsTaxSkipped: item?.IsTaxSkipped || false, PredictedTaxes: predictedTaxCal?.TaxesArray, PredictedTotalTaxAmount: predictedTaxCal?.taxAmt }
        }
        return { ...item, Taxes: [], TotalTaxAmount: 0, IsTaxSkipped: true, PredictedTaxes: [], PredictedTotalTaxAmount: 0 }

      });
      dataItem.SelectedAddons = updatedAddons
      addon_total = addon_total - coup_addon_temp;
      addon_total_with_payLater = addon_total_with_payLater - coup_addon;
      if (addon_total < 0) {
        addon_total = 0;
      }
      if (addon_total_with_payLater < 0) {
        addon_total_with_payLater = 0;
      }
    }
    let totalPlanAmount = total
    const PlanAmount = total
    const total_with_paylater = total + addon_total_with_payLater
    total = total + addon_total;
    //if only pros
    //pay later
    if (dataItem?.PayLater || total <= 0) {
      total = 0;
      totalPlanAmount = 0;
    }

    let initialVal = {
      ...dataItem,
      TotalPlanAmount: totalPlanAmount,
      PlanCummulativeAmount: PlanAmount,
      AddonTotalCouponAmount: coup_addon,
      TotalCouponAmount: coup_addon + coup,
      DownPayment: total,
      TotalBeforeSurcharges: total_with_paylater,
      ProratedAmount: pros,
      PlanTotalCouponAmount: coup,
      RecurringAmount: eftAmount,
    };
    setSubmitPlanData(initialVal);
    handleTaxCalculation(totalPlanAmount, taxItems, false)
    handleTaxCalculation(PlanAmount, taxItems, false, true)
    if(submitPlanDataRef?.current?.TotalBeforeSurcharges === 0){
      await fetchPaymentProfiles(props?.UserMemberId);
    } 
  };

  const handleTaxCalculation = (purchaseAmt: number, TaxItems = Taxes, Addon = false, Prediction = false) => {
    const TaxesArray = TaxItems.map((i: any) => {
      const amount = purchaseAmt * i?.Percentage / 100;
      const calcAmt = CustomToFixed(amount, 2)
      return {
        "TaxId": i?.TaxId,
        "TaxName": i?.TaxName,
        "Percentage": i?.Percentage,
        "Amount": calcAmt
      }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount
    }, 0);
    if (!Addon) {
      if (!Prediction) {
        setTaxes(TaxesArray)
        setTotalTaxAmount(taxAmt)
      } else {
        setPredictedTaxes(TaxesArray)
        setPredictedTotalTaxAmount(taxAmt)
      }
    } else {
      return { taxAmt, TaxesArray }
    }
  }

  //value renders
  const valueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any
  ) => {
    let children: any = [];
    if (selectedPlan) {
      children = [
        <div
          className={"row align-items-center w-100 value-render-dropdown p-2"}
        >
          <span key={1} className={""}>
            <h6 className="mb-0">{selectedPlan?.Name}</h6>
            <div className="plan-summary-card-row">
              <span>{`Duration - ${(selectedPlan?.Duration > 1 || selectedPlan?.DurationType === 2) ? selectedPlan?.DurationDescription : selectedPlan?.DurationDescription?.slice(0, -1)}`}</span>
              <span>{`Rate - ${CurrencyFormat(
                selectedPlan?.MonthlyRate
              )}`}</span>
            </div>
          </span>
        </div>,
      ];
    } else {
      children = [
        <span key={1} className={""}>
          Please Select
        </span>,
      ];
    }

    return React.cloneElement(element, { ...element.props }, children);
  };
  const PaidLaterAddonsLength = () => {
    const data = submitPlanData?.SelectedAddons
    const records = data?.filter((i: any) => {
      if (moment(new Date()).format("L") <
        moment(i?.StartDate).format("L")) {
        return true
      }
      return false
    })
    return records?.length || 0
  }

  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {

    const itemChildren = (
      <span style={{ width: "100%" }}>
        <span style={{ fontSize: "1rem", fontWeight: 400 }}>
          {itemProps.dataItem.Name}
        </span>
        <br />
        <div className="row">
          <span className="col-8">
            {(itemProps.dataItem.Duration > 1 || itemProps.dataItem.DurationType === 2) ? itemProps.dataItem.DurationDescription : itemProps.dataItem.DurationDescription?.slice(0, -1)}
          </span>
          <span className="col-4" style={{ alignItems: "end" }}>
            {"$ "} {itemProps.dataItem.MonthlyRate}
          </span>
        </div>
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const filterPlanChange = (e: any) => {
    const filteredData = filterData(e.filter, plandata);
    setfilterPlanData(filteredData);
  };
  const filterAddonChange = (e: any) => {
    const filteredData = filterData(e.filter, addondata);
    setfilterAddonData(filteredData);
  };
  const filterCouponChange = (e: any) => {
    const filteredData = filterData(e.filter, coupondata);
    setfilterCouponData(filteredData);
  };

  const filterData = (filter: any, itemData: any) => {
    const data = itemData.slice();
    return filterBy(data, filter);
  };

  const TooltipContentTemplate = (propData: any) => {
    return (
      <>
        <ul className="list-group list-group-flush taxes-list">
          {checkHasTaxEnable() && !IsTaxSkipped && PredictedTaxes?.length > 0 && (
            <li className="list-group-item" style={{ fontSize: '0.875rem', fontWeight: 600 }}>{submitPlanData?.MembershipPlanDetail?.Name}</li>
          )}
          {checkHasTaxEnable() &&
            !IsTaxSkipped &&
            PredictedTaxes?.length > 0 &&
            PredictedTaxes.map((i: any) => {
              return (
                <>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span>
                      {i?.TaxName}&nbsp;({i?.Percentage}%)
                    </span>
                    <span className="price">{CurrencyFormat(i?.Amount)}</span>
                  </li>
                </>
              );
            })}

          {submitPlanData?.SelectedAddons?.length > 0 &&
            submitPlanData?.SelectedAddons?.map((val: any, indx: any) => {
              if (
                checkHasTaxEnable() &&
                !val?.IsTaxSkipped &&
                val?.PredictedTaxes?.length > 0
              ) {
                return (
                  <>
                    <li className="list-group-item text-align-center" style={{ fontSize: '0.875rem', fontWeight: 600 }}>{val?.Name}</li>
                    {val?.PredictedTaxes.map((i: any) => {
                      return (
                        <>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span>
                              {i?.TaxName}&nbsp;({i?.Percentage}%)
                            </span>
                            <span className="price">
                              {CurrencyFormat(i?.Amount)}
                            </span>
                          </li>
                        </>
                      );
                    })}
                  </>
                );
              }
            })}
          {propData?.TotalTaxAmount > 0 && (<li className="list-group-item d-flex justify-content-between align-items-center">
            <span>
              {"Total Surcharges"}
            </span>
            <span className="price">{CurrencyFormat(propData?.TotalTaxAmount)}</span>
          </li>)}
        </ul>
      </>
    );
  };

  const itemRenderCoupon = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    let itemChildren;
    const index = itemProps?.dataItem?.Amount;
    if (itemProps?.dataItem?.TypeDescription === "Percentage") {
      itemChildren = (
        <span style={{ fontSize: "18px" }}>
          {itemProps?.dataItem.Description} <br />
          {index} {"%"}
        </span>
      );
    } else if (itemProps?.dataItem?.TypeDescription === "Fixed") {
      itemChildren = (
        <span style={{ fontSize: "18px" }}>
          {itemProps?.dataItem.Description} <br />
          {CurrencyFormat(index)}
        </span>
      );
    }

    return React.cloneElement(li, li.props, itemChildren);
  };

  //handel sign

  //handle signature pad popup
  const handleSignaturePad = () => {
    let errorvalidatin = handleValidation("signature");
    if (errorvalidatin !== "") {
      props?.handleNotificationMessage(errorvalidatin, "error");
      return;
    }
    setVisibleSignPad(true);
  };
  const handleValidation = (type: string) => {
    console.log(paymentSubmitProfile, 'paymentSubmitProfile')
    console.log(submitPlanData?.DownPayment, 'submitPlanData?.DownPayment')
    setSelected("0");
    let errormsg = "";
    if (submitPlanData?.BillingType === 3 && !submitPlanData?.BillingDay) {
      errormsg = "Please Select Billing Day";
    } else if (selectedSalesPerson === 0) {
      errormsg = "Please Select SalesPerson";
    } else if (!clientSubmitDetails) {
      errormsg = "Please Select Client";
    } else if (!submitPlanData?.MembershipPlanDetail) {
      errormsg = "Select Membership ";
    } else if (submitPlanData?.BillingType === 0) {
      errormsg = "Select BillingType";
    } else if ((!paymentSubmitProfile && submitPlanData?.DownPayment > 0) || (!paymentSubmitProfile && submitPlanData?.MembershipPlanDetail?.MonthlyRate > 0) || (!paymentSubmitProfile && submitPlanData?.SelectedAddons?.length > 0)) {
      errormsg = "Select Payment Profile ";
      setSelected("1");
    } else if (paymentSubmitProfile?.CardTypeId === 8 && submitPlanData?.DownPayment > paymentSubmitProfile?.Credit) {
      errormsg = CreditsErrorMessage
      setSelected("1");
    } else if (!customFields?.handleFieldValidation(false)) {
      setSelected("3");
      errormsg = "Please fill out the required additional information fields";
    } else if (
      paymentSubmitProfile?.CardDescription?.trim().toUpperCase() === "CASH" &&
      (submitPlanData?.HasAutoRenewal ||
        submitPlanData?.PayLater ||
        submitPlanData?.MembershipPlanDetail?.DurationType === 2 ||
        !submitPlanData?.CanPayInFull)
    ) {
      if (submitPlanData?.MembershipPlanDetail?.DurationType === 3 && !submitPlanData?.HasAutoRenewal) {
        errormsg = ""
      } else if (submitPlanData?.MembershipPlanDetail?.DurationType === 3 && submitPlanData?.HasAutoRenewal) {
        errormsg = "Can't use cash payments with Day membership";
      }
      else if (submitPlanData?.PayLater) {
        errormsg = "The 'Pay Later' option cannot be selected for cash payments";
      }
      else if (submitPlanData?.HasAutoRenewal) {
        errormsg = "Auto Renew cannot be selected for cash payments";
      }
      else if (submitPlanData?.MembershipPlanDetail?.DurationType === 1 && submitPlanData?.MembershipPlanDetail?.Duration === 1) {
        errormsg = ""
      }
      else {
        errormsg = "Can't use cash payments with M2M membership/ Recurring addons";
      }
    } else if (submitPlanData?.SelectedAddons?.length > 0) {
      submitPlanData?.SelectedAddons.forEach((item: any) => {
        //date condition
        const x = moment(item?.StartDate); //adon
        const y = moment(submitPlanData?.StartDate); //plan

        if (x.diff(y, "days") < 0) {
          errormsg =
            "Select Addon date greater than plan start date for addon " +
            item?.Name;
        }

        if (item?.Charge === 0) {
          errormsg = "Select Price for addon " + item?.Name;
        } else if (item?.EndType === 1 && item?.Occurrence === 0) {
          errormsg = "Select No of Occurrence for addon " + item?.Name;
        } else if (item?.Frequency === 0) {
          errormsg = "Select Frequency for addon " + item?.Name;
        } else if (item?.Frequency > 1 && item?.RepeatFrequency === 0) {
          errormsg = "Select Repeat Frequency for addon  " + item?.Name;
        }

        if (
          paymentSubmitProfile?.CardDescription?.trim().toUpperCase() ===
          "CASH" &&
          (submitPlanData?.PayLater || item?.Frequency !== 1)
        ) {
          errormsg = "Can't use cash payments for Pay Later/ Recurring addons";
        }
      });
    }


    if (errormsg === "" && type === "submit") {
      if (signature === null || !signature) {
        errormsg = "Please provide Signature";
      }
    }
    return errormsg;
  };

  const checkZeroDollarMembership = (dataItem = submitPlanData) => {
    if (dataItem?.MembershipPlanDetail?.MonthlyRate <= 0) {
      let total = 0
      if (dataItem?.MembershipPlanDetail?.PlanManagementFee?.length > 0 && !dataItem?.CanFeesWaived) {
        const cost = dataItem?.MembershipPlanDetail?.PlanManagementFee?.reduce(
          (acc: Number, currentValue: any) => {
            return acc + currentValue?.Amount;
          },
          0
        );
        total = total + cost;
      }
      //PreviousPlanRemainingAmount
      if (dataItem?.PreviousPlanRemainingAmount > 0) {
        total = total + dataItem?.PreviousPlanRemainingAmount;
      }
      if (total <= 0) {
        return true
      }
    }
    return false
  }

  const handleRedirection = () => {
    props?.handleAddPlan();
  };

  const handlePlanDataChange = async (value: any, name: string) => {
    if (name === "salesperson") {
      setSelectedSalesPerson(value);
    } else if (name === "client") {
      const clientDetails = {
        Email: value?.Email,
        FirstName: value?.FirstName,
        Photo: value?.Photo,
        LastName: value?.LastName,
        FullName: value?.FullName,
        PrimaryMemberId: value?.UserMemberId,
        UserId: value?.UserId,
        UserMemberId: value?.UserMemberId,
        MemberNumber: value?.MemberNumber,
        MemberType: value?.MemberType,
      };
      setClientSubmitDetails(clientDetails);
    } else if (name === "Coupon") {
      let final_coup_data: any[] = [];
      const couponsList = value.sort(function (a: any, b: any) {
        return b.Amount - a.Amount;
      });
      const couponFixed = couponsList.filter(
        (res: any) => res?.TypeDescription === "Fixed"
      );
      if (couponFixed.length > 0) {
        final_coup_data.push(couponFixed[0]);
      }
      const couponPercentage = couponsList.filter(
        (res: any) => res?.TypeDescription === "Percentage"
      );
      if (couponPercentage.length > 0) {
        final_coup_data.push(couponPercentage[0]);
      }
      const couponsUpdatedList = final_coup_data.map((i: any) => {
        if (i?.DurationType === 3 && !submitPlanData?.HasAutoRenewal) {
          return { ...i, RenewType: 0 }
        }
        return { ...i }
      })
      const dataItem = {
        ...submitPlanData,
        Coupon: couponsUpdatedList,
      };
      updateTotalAmount(dataItem);
    } else if (name === "CouponRenewal") {
      const couponsList = submitPlanData.Coupon.map((i: any) => {
        if (i?.CouponId === value?.CouponId) {
          if (value?.selected) {
            return { ...i, RenewType: 1 }
          } else {
            return { ...i, RenewType: 0 }
          }
        } else {
          return { ...i }
        }
      })
      const dataItem = {
        ...submitPlanData,
        Coupon: couponsList,
      };
      updateTotalAmount(dataItem);
    } else if (name === "addon") {
      let addonValues = value;
      if (submitPlanData?.SelectedAddons?.length === 0) {
        addonValues = value;
      } else if (value?.length > submitPlanData?.SelectedAddons?.length) {
        //add
        const originalArray = submitPlanData?.SelectedAddons;
        originalArray.push(value[value.length - 1]);
        addonValues = originalArray;
      } else if (value?.length < submitPlanData?.SelectedAddons?.length) {
        //removed
        const updateArray = submitPlanData?.SelectedAddons?.filter((i: any) => {
          if (
            value?.find((j: any) => j?.AddonTemplateId === i?.AddonTemplateId)
          ) {
            return true;
          }
          return false;
        });
        addonValues = updateArray;
      }
      getCouponDataAddon(addonValues);
    } else if (name === "HasAutoRenewal") {
      if (value === true) {
        if (paymentSubmitProfile?.CardDescription?.trim().toUpperCase() === "CASH" && value==true) {
          let errormsg = "Auto Renew cannot be selected for cash payments";
          props?.handleNotificationMessage(errormsg, "error");
        } 
        if (
          (submitPlanData?.MembershipPlanDetail?.DurationType === 1 &&
            submitPlanData?.MembershipPlanDetail?.AllowRecurring === false) || submitPlanData?.MembershipPlanDetail?.DurationType === 3
        ) {
          const dataItem = {
            ...submitPlanData,
            DisableAutoRenewal: false,
            DisableAutoRenewalPayInFull: true,
            HasAutoRenewal: true,
            CanAutoRenewalPayInFull: true,
          };
          updateTotalAmount(dataItem);
        } else {
          const dataItem = {
            ...submitPlanData,
            DisableAutoRenewal: false,
            DisableAutoRenewalPayInFull: false,

            HasAutoRenewal: true,
            CanAutoRenewalPayInFull: false,
          };
          updateTotalAmount(dataItem);
        }
      } else {
        const couponsList = submitPlanData.Coupon.map((i: any) => {
          if (i?.DurationType === 3) {
            return { ...i, RenewType: 0 }
          }
          return { ...i }
        })
        const dataItem = {
          ...submitPlanData,
          Coupon: couponsList,
          DisableAutoRenewal: false,
          DisableAutoRenewalPayInFull: true,
          HasAutoRenewal: false,
          CanAutoRenewalPayInFull: false,
        };
        updateTotalAmount(dataItem);
      }
    } else if (name === "StartDate") {
      let dataItem = {
        ...submitPlanData,
        [name]: moment(value).format("L"),
      };
      const startDateVal = moment(value).format('L')
      const currentDateVal = moment().format('L')
      // if start date is less than or equal to 6 months and greater than current date paylater will be enabled, in other cases paylater will be disabled.
      if (moment(startDateVal).diff(moment(currentDateVal), 'months') < 18 && moment(startDateVal).diff(moment(currentDateVal), 'days') > 0  && !(paymentSubmitProfile?.CardDescription?.trim().toUpperCase() === "CASH")) {
        dataItem = {
          ...submitPlanData,
          [name]: moment(value).format("L"),
          PayLater: submitPlanData?.PayLater ? true : false,
          DisablePayLater: false,
        };
      } else {
        dataItem = {
          ...submitPlanData,
          [name]: moment(value).format("L"),
          PayLater: false,
          DisablePayLater: true,
        };
      }
      updateTotalAmount(dataItem);
    } else if (name === "MonthlyRate") {
      let val = value
      if (value <= 0) {
        val = 0
      }
      const obj = { ...submitPlanData?.MembershipPlanDetail, [name]: val }
      const updatedSubmitPlan = {
        ...submitPlanData,
        MembershipPlanDetail: obj
      };
      updateTotalAmount(updatedSubmitPlan);
    }
    else if (name === "PayLater") {
      let dataItem = {
        ...submitPlanData,
      };
      if (paymentSubmitProfile?.CardDescription?.trim().toUpperCase() === "CASH" && value==true) {
        let errormsg = "The 'Pay Later' option cannot be selected for cash payments";
        props?.handleNotificationMessage(errormsg, "error");
      } 
        dataItem = {
          ...submitPlanData,
          [name]: value,
      }
      updateTotalAmount(dataItem);
    }
    else {
      const dataItem = {
        ...submitPlanData,
        [name]: value,
      };
      updateTotalAmount(dataItem);
    }
  };
  const unloadFunc = () => {
    //use the history and the location from the props instead of window
    props.history.replace({
      ...props.location,
      state: undefined,
    });
  };

  const handlePlanChange = async (val: any) => {
    const res = await service.getPlanDetails(val?.PlanManagementId);
    let taxItems = await fetchTaxes()
    if (checkHasTaxEnable() && res?.CanCollectTaxes) {
      setIsTaxSkipped(false)
    }
    let dataItem = {
      ...submitPlanData,
      CanCollectTaxes: res?.CanCollectTaxes
    };

    if (res?.CanProrate) {
      dataItem = {
        ...submitPlanData,
        BillingType: 3,
        BillingDay: res?.BillingDay
      };
    } else {
      dataItem = {
        ...submitPlanData,
        BillingType: 2,
        BillingDay: null
      };
    }
    if (val?.DurationType === 1) {
      //m
      if (val?.AllowRecurring === false) {
        //pif
        dataItem = {
          ...dataItem,
          MembershipPlanDetail: res,
          DisableAutoRenewal: false,
          DisableAutoRenewalPayInFull: true,
          DisablePayInFull: true,
          HasAutoRenewal: res?.CanAutoRenew,
          CanAutoRenewalPayInFull: true,
          CanPayInFull: true,
        };
      } else {
        dataItem = {
          ...dataItem,
          MembershipPlanDetail: res,
          DisableAutoRenewal: false,
          DisableAutoRenewalPayInFull: false,
          DisablePayInFull: false,
          HasAutoRenewal: res?.CanAutoRenew,
          CanAutoRenewalPayInFull: false,
          CanPayInFull: false,
        };
      }
      if (val?.Duration === 1) {
        dataItem = {
          ...dataItem,
          BillingType: 2
        };
      }
    } else if (val?.DurationType === 2) {
      //m2m
      dataItem = {
        ...dataItem,
        MembershipPlanDetail: res,
        HasAutoRenewal: false,
        CanAutoRenewalPayInFull: false,
        CanPayInFull: false,

        DisableAutoRenewal: true,
        DisableAutoRenewalPayInFull: true,
        DisablePayInFull: true,
      };
    } else if (val?.DurationType === 3) {
      dataItem = {
        ...dataItem,
        MembershipPlanDetail: res,
        HasAutoRenewal: res?.CanAutoRenew,
        CanAutoRenewalPayInFull: false,
        CanPayInFull: false,
        DisableAutoRenewal: false,
        DisableAutoRenewalPayInFull: true,
        DisablePayInFull: true,
        BillingType: 2
      };
    }

    updateTotalAmount(dataItem, taxItems);
    setSelectedPlan(val);
    getCouponData(val?.PlanManagementId);
    if (props?.location?.state?.planItem) {
      unloadFunc();
    }
  };

  //handel submit
  const handleFinalSubmit = async (type: any) => {
    let BillingDayValue = submitPlanData?.BillingDay
    if (submitPlanData?.BillingType === 1) {
      BillingDayValue = 1
    } else if (submitPlanData?.BillingType === 2) {
      BillingDayValue = moment(submitPlanData?.StartDate).date()
    }
    const selectedAddons = submitPlanData?.SelectedAddons.map((i: any) => {
      if (i?.IsTaxSkipped) {
        return { ...i, CanCollectTaxes: false, Taxes: [], TotalTaxAmount: 0 }
      }
      return { ...i, CanCollectTaxes: checkHasTaxEnable() ? !i?.IsTaxSkipped : false }
    })
    //
    const planManagementFeeUpdatedData = submitPlanData?.MembershipPlanDetail?.PlanManagementFee?.map((item: any) => {
      if (item?.CanFeesRemoved !== undefined && item?.CanFeesRemoved) {
        return item;
      } else
        if (item?.editedAmount) {
          return { ...item, Amount: item.editedAmount };
        }
      return item;
    });
    let membershipPlanDetailData = submitPlanData?.MembershipPlanDetail;
    membershipPlanDetailData.PlanManagementFee = planManagementFeeUpdatedData;
    //
    const resultRecord = {
      ...submitPlanData,
      MembershipPlanDetail: membershipPlanDetailData,
      UserMemberId: clientSubmitDetails?.UserMemberId,
      MemberNumber: clientSubmitDetails?.MemberNumber,
      MemberName: clientSubmitDetails?.FullName,
      Email: clientSubmitDetails?.Email,
      MemberType: clientSubmitDetails?.MemberType,
      Photo: clientSubmitDetails?.Photo,
      SalesPersonId: selectedSalesPerson,
      MemberPlanId: submitPlanData?.MembershipPlanDetail?.PlanManagementId,
      BillingType: submitPlanData?.BillingType === 3 ? 2 : submitPlanData?.BillingType,
      BillingDay: BillingDayValue,
      SelectedAddons: selectedAddons,
      Taxes: checkHasTaxEnable() && !IsTaxSkipped ? Taxes : [],
      CanCollectTaxes: checkHasTaxEnable() ? !IsTaxSkipped : false,
      TotalTaxAmount: checkHasTaxEnable() && !IsTaxSkipped ? TotalTaxAmount : 0,
      TotalDownPayment: calculatePaidToday(),
      UserInternalDataId: finalData?.Result[0]?.UserInternalDataId,
      UserId: finalData?.Result[0]?.UserId,
      CardDescription: paymentSubmitProfile?.CardDescription,
      PaymentGatewayPaymentProfileId: paymentSubmitProfile?.PaymentGatewayPaymentProfileID,
      CustomerProfileId: paymentSubmitProfile?.CustomerProfileID,
      PaymentProfileId: paymentSubmitProfile?.PaymentProfileID,
      MaskedCCNumber: paymentSubmitProfile?.MaskedCCNumber,
      CardTypeId: paymentSubmitProfile?.CardTypeId,
      Credit: paymentSubmitProfile?.Credit,
    };
    let buildagreement = {
      ...finalData,
      Result: [resultRecord],
      UserMemberId: props?.UserMemberId,
      PrimaryUserMemberId: props?.UserMemberId,
      IsSignUp: true,
      CustomFields: customFields?.fieldsData?.length > 0 ? customFields?.fieldsData : [],
      Taxes: checkHasTaxEnable() && !IsTaxSkipped ? Taxes : [],
      CanCollectTaxes: checkHasTaxEnable() ? !IsTaxSkipped : false,
      TotalTaxAmount: checkHasTaxEnable() && !IsTaxSkipped ? TotalTaxAmount : 0,
      TotalDownPayment: calculatePaidToday(),

    };
    if (type === "agreement") {
      const req = {
        ...buildagreement,
        OverridePlanDetail: true,
        Signature: signature ? signature : "",
      };
      setDisable(true);
      const res = await service.BuildAgreement(req);
      setDisable(false);
      if (res?.ResponseCode === 100) {
        let template = await res?.Item?.AgreementContent;
        setAgreementTemplate(template);
        setVisibleAgreement(true);
      } else {
        const errorMsg =
          res?.ErrorMessages.length > 0 ? res?.ErrorMessages[0] : "Error ";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } else if (type === "submit") {
      setConfirmClientDialog(false);
      setBtnLoading(true);
      const req = {
        ...buildagreement,
        Signature: signature,
        AgreementContent: agreementTemplate,
        TermsAndConditionAccepted: termsandConditions,
        OverridePlanDetail: true
      };
      setDisable(true);
      const res = await service.CompleteSignUp(req);
      setDisable(false);
      setBtnLoading(false);
      if (res?.ResponseCode === 100) {
        props?.handleNotificationMessage(
          `Membership assigned successfully`,
          "success"
        );
        handleRedirection();
      } else {
        const errorMsg =
          res?.ErrorMessages.length > 0 ? res?.ErrorMessages[0] : "Error ";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
  };
  const handleClose = async (success = false) => {
    setAddClient(false);
    let memberId = props?.UserMemberId;
    await fetchClientData(memberId, success);
  };

  const widthOfDevice = window.innerWidth;
  if (addClient) {
    return (
      <CreateClient
        handleClose={(success = false) => handleClose(success)}
        UserMemberId={props?.UserMemberId}
        parentUser={clientsList?.find((i: any) => i?.MemberType === 0)}
        staffPage={true}
      />
    );
  }

  if (loading) {
    return <BuzopsLoader type={"list"} />;
  }
  const dropdownBillingData = [
    {
      text: `${moment(submitPlanData?.StartDate).format("Do")} of Month`,
      value: 2,
    },
    {
      text: "Custom",
      value: 3
    }
  ];

  const grandTotalCalculate = (DownPayment: any, addon: any) => {
    const initialValue = 0;
    const additiondata = submitPlanData?.MembershipPlanDetail?.PlanManagementFee?.map((itm: any) => {
      return itm?.Amount
    })
    const sumWithInitial = additiondata.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue,
      initialValue
    );

    const addonValue = addon.map((itm: any) => {
      const addonCoupons = itm?.Coupon?.map((i: any) => {
        return calculateAddon(i, itm)
      })
      const intialValue = 0

      const sumWithaddoncoupon = addonCoupons?.reduce(
        (accumulator: any, currentValue: any) => accumulator + currentValue,
        intialValue
      );
      if (itm?.IsProrated && itm?.ProratedAmount !== 0) {
        return itm?.ProratedAmount - (sumWithaddoncoupon || 0)
      }
      else {
        return itm?.Charge - (sumWithaddoncoupon || 0)
      }
    })
    const addOnSum = addonValue.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue,
      initialValue
    );
    if (!submitPlanData?.PayLater) {
      return (submitPlanData?.DownPayment)?.toFixed(2)
    } else {
      return submitPlanData?.RecurringAmount + addOnSum
    }
  }

  const currencyPaid = () => {
    const initialValue = 0;
    const additiondata = submitPlanData?.MembershipPlanDetail?.PlanManagementFee?.map((itm: any) => {
      return itm?.Amount
    })
    const sumWithInitial = additiondata.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue,
      initialValue
    );
    if (!submitPlanData?.PayLater) {
      return (submitPlanData?.DownPayment)?.toFixed(2)
    } else {
      return submitPlanData?.RecurringAmount
    }
  }

  const calculatePaidToday = () => {
    if (submitPlanData?.PayLater) {
      return 0
    } else {
      return submitPlanData?.DownPayment.toFixed(2) || 0
    }
  }
  const calculateTotalTaxAmount = (prediction = false) => {
    let total = 0
    if (IsTaxSkipped) {
      total = 0
    } else {
      if (!prediction) {
        total += TotalTaxAmount
      } else {
        total += PredictedTotalTaxAmount
      }
    }
    const addonTax = submitPlanData?.SelectedAddons?.reduce(
      (accumulator: number, currentValue: any) => {
        if (!currentValue?.IsTaxSkipped) {
          if (!prediction) {
            return accumulator + CustomToFixed(currentValue?.TotalTaxAmount, 2)
          } else {
            return accumulator + CustomToFixed(currentValue?.PredictedTotalTaxAmount, 2);
          }
        } else {
          return accumulator + 0
        }
      },
      0
    );
    return total + addonTax
  }

  const PayLaterTooltipContentTemplate = (propData: any) => {
    return (
      <div>
        <span className="">
          {`The pay later option is available
   only for purchases with a  start date
    within the next six months.`}
        </span>
      </div>
    );
  };

  const FrequencyDescription = (addonVal: any) => {
    let Description = ""
    const freq = addonVal?.Frequency
    if (freq === 1) {
      return `One Time`
    }
    if (addonVal?.RepeatFrequency > 0) {
      switch (freq) {
        case 2:
          if (addonVal?.RepeatFrequency > 1) {
            Description = `For Every ${addonVal?.RepeatFrequency} Days`
          } else {
            Description = `For Every ${addonVal?.RepeatFrequency} Day`
          }
          break;
        case 4:
          if (addonVal?.RepeatFrequency > 1) {
            Description = `For Every ${addonVal?.RepeatFrequency} Months`
          } else {
            Description = `For Every ${addonVal?.RepeatFrequency} Month`
          }
          break;
        case 5:
          if (addonVal?.RepeatFrequency > 1) {
            Description = `For Every ${addonVal?.RepeatFrequency} Years`
          } else {
            Description = `For Every ${addonVal?.RepeatFrequency} Year`
          }
          break;

        default:
          break;
      }
    }
    return Description
  }

  const handleUpdatePlanManagementFee = (obj: any, index: number, value: any) => {
    const updatedData = { ...submitPlanData };
    updatedData.MembershipPlanDetail.PlanManagementFee[index].editedAmount = value;
    if (value == 0) {
      updatedData.MembershipPlanDetail.PlanManagementFee[index].CanFeesRemoved = true;
    }
    setSubmitPlanData(updatedData);
    updateTotalAmount(updatedData);
  }

  const handleUpdatePlanManagementFeeStatus = (obj: any, index: number, value: any) => {
    const updatedData = { ...submitPlanData };
    updatedData.MembershipPlanDetail.PlanManagementFee[index].CanFeesRemoved = !value;
    updatedData.MembershipPlanDetail.PlanManagementFee[index].editedAmount = 0;
    setSubmitPlanData(updatedData);
    updateTotalAmount(updatedData);
  }

  const checkTaxApplicable = () => {
    if (Taxes?.length === 0) {
      return false
    }
    if (!checkHasTaxEnable()) {
      return false
    }
    let check = false
    if (submitPlanData?.MembershipPlanDetail?.CanCollectTaxes && !IsTaxSkipped) {
      check = true
    }
    const addonCheck = submitPlanData?.SelectedAddons?.some((i: any) => {
      if (i?.CanCollectTaxes && !i?.IsTaxSkipped) {
        return true
      } else {
        return false
      }
    })
    if (!check) {
      check = addonCheck
    }
    return check
  }

  const calculateAddon = (e: any, addon: any) => {
    if (e?.Description === "limited") {
      if (submitPlanData?.BillingType === 1 && moment?.(addon?.StartDate)?.format("DD") === "01") {
        return (e?.Type === 2
          ? addon?.PercentageCoupon
          : addon?.FixedCoupon)
      }
      else if (submitPlanData?.BillingType === 2 && moment?.(submitPlanData?.StartDate)?.format("DD") === moment?.(addon?.StartDate)?.format("DD")) {
        return (e?.Type === 2
          ? addon?.PercentageCoupon
          : addon?.FixedCoupon)
      }
      else if (!addon?.IsProrated) {
        return (e?.Type === 2
          ? addon?.PercentageCoupon
          : addon?.FixedCoupon)
      }
      else {
        return 0

      }
    }
    else {
      return (e?.Type === 2
        ? addon?.PercentageCoupon
        : addon?.FixedCoupon)
    }
  }
  const proratedHintDay = moment([moment(submitPlanData?.StartDate).year(), moment(submitPlanData?.StartDate).month(), submitPlanData?.BillingDay]).format("Do")
  const notProratedHintDay = moment(submitPlanData?.StartDate).format("Do")
  let autoRenewEveryText = ""
  if (submitPlanData?.MembershipPlanDetail?.DurationType === 1) {
    autoRenewEveryText = `Renews every ${submitPlanData?.MembershipPlanDetail?.Duration} ${submitPlanData?.MembershipPlanDetail?.Duration === 1 ? "month" : "months"}`
  } else if (submitPlanData?.MembershipPlanDetail?.DurationType === 2) {
    autoRenewEveryText = `Renews every 1 month`
  } else {
    autoRenewEveryText = `Renews every ${submitPlanData?.MembershipPlanDetail?.Duration} ${submitPlanData?.MembershipPlanDetail?.Duration === 1 ? "day" : "days"}`
  }
  const maximum = new Date(moment().add(18, "month").format("L"));
  return (
    <>
      <div className="dFlexBtn bz-addNewMemberBtn">
        <h6 className="mb-2 bz_fs_1_5">
          <button
            className="btn btn-primary bz-back-btn btn-sm"
            onClick={() => props?.handleAddPlan()}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          &nbsp;Membership Details
        </h6>
        <Button
          className={"bz-schedule-btn float-right"}
          primary={true}
          onClick={() => setAddClient(true)}
        >
          Add Family Member
        </Button>
      </div>
      <div className="plan-details bz-plan-puchase-page">
        <div className="row">
          <div
            className={
              submitPlanData?.MembershipPlanDetail
                ? `col-md-8 col-lg-8 bg-white`
                : `col-md-12 col-lg-8 bg-white`
            }
          >
            <div className="card" style={{ padding: "12px" }}>
              <div className="row">
                <div className="col-md-6">
                  <label>Sales Person*</label>
                  <DropDownList
                    className="form-control"
                    data={salesData}
                    textField="UserName"
                    dataItemKey="UserId"
                    onChange={(e) => {
                      handlePlanDataChange(e.value?.UserId, "salesperson");
                    }}
                    value={
                      salesData.filter(
                        (res: any) => res?.UserId === selectedSalesPerson
                      )[0] || {
                        UserId: 0,
                        UserName: "Please Select Sales Person",
                      }
                    }
                  />
                </div>

                <div className="col-md-6">
                  <label>Purchase/Enrollment For*</label>
                  <MemberSelection
                    allClientsList={clientsList}
                    selectedUsermemberId={clientSubmitDetails?.UserMemberId}
                    handleUpdateClient={(val: any) => handlePlanDataChange(val, "client")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>Sign up Date</label>
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <DatePicker
                      min={new Date()}
                      className="form-control"
                      value={new Date(submitPlanData?.PurchaseDate)}
                      id={"PurchaseDate"}
                      name={"PurchaseDate"}
                      disabled={true}
                      onChange={(e) => {
                        handlePlanDataChange(e.value, "PurchaseDate");
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Start Date*</label>
                  <div
                    style={{
                      width: "100%",
                      // height: "50px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >

                    <DatePicker
                      min={new Date()}
                      max={maximum}
                      className="form-control"
                      value={new Date(submitPlanData?.StartDate)}
                      id={"StartDate"}
                      name={"StartDate"}
                      onChange={(e) => {
                        setStartDate(moment(e?.value).format("L"))
                        handlePlanDataChange(e.value, "StartDate");
                      }}
                    />
                  </div>
                  <Hint>
                    <span className="form-text text-muted">
                      Choose a date from which you want to start your membership.
                    </span>
                  </Hint>
                </div>
                <div className="col-md-6 plan-name-field">
                  <label>Membership Name*</label>
                  <DropDownList
                    data={filterplandata}
                    textField="Name"
                    dataItemKey="PlanCode"
                    onChange={(e) => handlePlanChange(e.value)}
                    valueRender={(
                      el: React.ReactElement<HTMLSpanElement>,
                      value: any
                    ) => valueRender(el, value)}
                    filterable={true}
                    onFilterChange={(e) => filterPlanChange(e)}
                    className={"customDropdown customDropdown2 padd-0"}
                    itemRender={itemRender}
                  />
                  <Hint>
                    <span className="form-text text-muted">
                      Select the membership to assign to this client.
                    </span>
                  </Hint>
                </div>
                <div className="col-md-6">
                  <label>Monthly Rate*</label>
                  <div>
                    <NumericTextBox
                      id={`MonthlyRate`}
                      name={`MonthlyRate`}
                      width={"100%"}
                      min={0}
                      value={submitPlanData?.MembershipPlanDetail?.MonthlyRate}
                      format={"c2"}
                      onChange={(e) => {
                        handlePlanDataChange(e.value, "MonthlyRate");
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Billed On*</label>
                  <DropDownList
                    style={{
                      width: "100%",
                    }}
                    className="form-control"
                    onChange={(e) => {
                      handlePlanDataChange(e.value?.value, "BillingType");
                    }}
                    data={dropdownBillingData}
                    textField="text"
                    dataItemKey="value"
                    disabled={(submitPlanData?.MembershipPlanDetail?.DurationType === 1 && submitPlanData?.MembershipPlanDetail?.Duration === 1) || submitPlanData?.MembershipPlanDetail?.DurationType === 3}
                    value={
                      dropdownBillingData.filter(
                        (res: any) => res.value === submitPlanData?.BillingType
                      )[0]
                    }
                  />
                  {submitPlanData?.BillingType === 1 && submitPlanData?.MembershipPlanDetail?.DurationType !== 3 && (
                    <>
                      <Hint>
                        <span className="form-text text-muted">
                          Membership billing amount will be prorated to the rest of
                          the month
                        </span>
                      </Hint>
                    </>
                  )}
                  {(submitPlanData?.BillingType === 2 || (moment(submitPlanData?.StartDate).date() === submitPlanData.BillingDay)) && submitPlanData?.MembershipPlanDetail?.DurationType !== 3 && (
                    <>
                      <Hint>
                        <span className="form-text text-muted">
                          {`Membership billing amount will be charged on the ${notProratedHintDay} of every month for any recurring fees or charges associated`}
                        </span>
                      </Hint>
                    </>
                  )}
                  {submitPlanData?.BillingType === 3 && moment(submitPlanData?.StartDate).date() !== submitPlanData.BillingDay && submitPlanData?.MembershipPlanDetail?.DurationType !== 3 && (
                    <>
                      <Hint>
                        <span className="form-text text-muted">
                          {submitPlanData?.BillingDay && `Membership billing amount will be prorated for any current charges until the ${proratedHintDay} of this month. From then on, Membership billing amount will be charged on the ${proratedHintDay} of every month`}
                        </span>
                      </Hint>
                    </>
                  )}
                </div>
                {submitPlanData?.BillingType === 3 && <div className="col-md-6">
                  <label>Billing Day*</label>
                  <DropDownList
                    style={{
                      width: "100%",
                    }}
                    className="form-control"
                    onChange={(e) => {
                      handlePlanDataChange(e.value?.value, "BillingDay");
                    }}
                    data={billingDays}
                    textField="text"
                    dataItemKey="value"
                    value={
                      billingDays.filter(
                        (res: any) => res.value === submitPlanData?.BillingDay
                      )[0] || { text: "Select Billing Day", value: 0 }
                    }
                  />
                  <Hint> <span className="form-text text-muted">{submitPlanData?.BillingDay && `Billing occurs on the ${proratedHintDay} of each month`}</span></Hint>
                </div>}
              </div>
              <div className="row">
                <label>Payment Types*</label>
              </div>
              <div className="row d-flex">
                <div className="col-md-4 mb-3 d-flex">
                  <Checkbox
                    label={"Pay Later"}
                    style={{
                      height: "25px",
                      width: "25px",
                    }}
                    disabled={submitPlanData?.DisablePayLater}
                    value={submitPlanData?.PayLater}
                    onChange={(e) => {
                      handlePlanDataChange(e.value, "PayLater");
                    }}
                  />
                  <BuzopsTooltip
                    toolTipClass={'Cancellation-fee-tooltip'}
                    position={'right'}
                    content={(propdata: any) => (
                      <PayLaterTooltipContentTemplate title={propdata.title} />
                    )}
                    html={
                      <button
                        className="btn btn-icon  d-flex align-items-end"
                        title={"Pay Later"}
                      >
                        <BiInfoCircle color="#008db9" />
                      </button>
                    }
                  />
                </div>
                {submitPlanData?.MembershipPlanDetail?.DurationType === 3 && (<>
                  <div className="col-md-4 mb-3 mb-sm-0">
                    <Checkbox
                      label={"Auto Renews"}
                      style={{
                        height: "25px",
                        width: "25px",
                      }}
                      disabled={submitPlanData?.DisableAutoRenewal}
                      value={submitPlanData?.HasAutoRenewal}
                      onChange={(e) => {
                        handlePlanDataChange(e.value, "HasAutoRenewal");
                      }}
                    />
                    {submitPlanData?.HasAutoRenewal && <Hint>
                      <span className="form-text text-muted">
                        {autoRenewEveryText}
                      </span>
                    </Hint>}
                  </div>
                </>)}
                {submitPlanData?.MembershipPlanDetail?.DurationType === 1 && (<>
                  <div className="col-md-4 mb-3">
                    <Checkbox
                      label={"Pay In Full"}
                      style={{
                        height: "25px",
                        width: "25px",
                      }}
                      disabled={submitPlanData?.DisablePayInFull}
                      value={submitPlanData?.CanPayInFull}
                      onChange={(e) => {
                        handlePlanDataChange(e.value, "CanPayInFull");
                      }}
                    />
                  </div>
                  <div className="col-md-4 mb-3 mb-sm-0">
                    <Checkbox
                      label={"Auto Renews"}
                      style={{
                        height: "25px",
                        width: "25px",
                      }}
                      disabled={submitPlanData?.DisableAutoRenewal}
                      value={submitPlanData?.HasAutoRenewal}
                      onChange={(e) => {
                        handlePlanDataChange(e.value, "HasAutoRenewal");
                      }}
                    />
                    {submitPlanData?.HasAutoRenewal && <Hint>
                      <span className="form-text text-muted">
                        {autoRenewEveryText}
                      </span>
                    </Hint>}
                  </div>
                  <div className="col-md-4">
                    <Checkbox
                      label={"Pay In Full"}
                      style={{
                        height: "25px",
                        width: "25px",
                      }}
                      disabled={submitPlanData?.DisableAutoRenewalPayInFull}
                      value={submitPlanData?.CanAutoRenewalPayInFull}
                      onChange={(e) => {
                        handlePlanDataChange(e.value, "CanAutoRenewalPayInFull");
                      }}
                    />
                  </div>
                </>)}
              </div>
              {!checkZeroDollarMembership(submitPlanData) && (<div className="row">
                <div className="col-md-6">
                  <label>Coupons</label>
                  <div>
                    <MultiSelect
                      data={filterCoupondata}
                      textField="Description"
                      dataItemKey="CouponId"
                      placeholder="Please select coupon"
                      onChange={(e) => {
                        handlePlanDataChange(e.value, "Coupon");
                      }}
                      filterable={true}
                      onFilterChange={(e) => filterCouponChange(e)}
                      itemRender={itemRenderCoupon}
                      value={submitPlanData?.Coupon}
                      className="bz-multiselect-arrow"
                    />
                  </div>
                  <Hint>
                    <span className="form-text text-muted">
                      Select a coupon from the list applicable for the membership
                      selected above.
                    </span>
                  </Hint>
                </div>
              </div>)}
              {submitPlanData?.Coupon?.length > 0 && <div className="row">
                <label>Selected Coupons</label>
                <div className="coupon-list-card">
                  {submitPlanData?.Coupon?.map((i: any) => {
                    return (<>
                      <div className="coupon-card-item">
                        <div className="buz-font-600">{`${i?.Description} - ${i?.Type === 1 ? CurrencyFormat(i?.Amount) : `${i?.Amount}%`}`} </div>
                        <div>Duration Type : {CouponDurationType[i?.DurationType]}</div>
                        {i?.DurationType === 3 && <div>Billing Cycles : {i?.MinimumCycle}</div>}
                        {i?.DurationType === 3 && submitPlanData?.MembershipPlanDetail?.DurationType === 1 && <div>Auto Renews:<Checkbox name={`Coupon${i?.CouponId}`} id={`Coupon${i?.CouponId}`} value={i?.RenewType === 1 ? true : false}
                          disabled={!submitPlanData?.HasAutoRenewal}
                          onChange={(e) => {
                            handlePlanDataChange({ selected: e.value, CouponId: i?.CouponId }, "CouponRenewal");
                          }} className="pl-2" /></div>}
                      </div>
                    </>)
                  })}
                </div>
              </div>}
              <div className="row">
                <label>Add on</label>
                <div className="addon-dropdown">
                  <MultiSelect
                    data={filterAddondata}
                    textField="Name"
                    dataItemKey="AddonTemplateId"
                    placeholder="Please select addon"
                    onChange={(e) => {
                      handlePlanDataChange(e.value, "addon");
                    }}
                    filterable={true}
                    onFilterChange={(e) => filterAddonChange(e)}
                    allowCustom={false}
                    autoClose={true}
                  />
                  <i className="arrow"></i>
                </div>
                <Hint>
                  <span className="form-text text-muted">
                    You may add mutiple Add-Ons to this client account.
                  </span>
                </Hint>
              </div>
            </div>
            {submitPlanData?.SelectedAddons?.map((val: any, indx: any) => {
              return (
                <div className="card" style={{ marginTop: "12px" }}>
                  <div
                    style={{
                      height: "40px",
                      background: "#008db9",
                      position: "relative",
                      top: "50%",
                    }}
                  >
                    <h5 style={{ color: "white", margin: "8px" }}>
                      {val?.Name}
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {val?.Name === "Custom" && (<>
                        <div className="col-md-6 pb-1">
                          <label>Description*</label>
                          <div>
                            <Input
                              id={`Description`}
                              name={`Description`}
                              style={{ width: "100%" }}
                              value={val?.Description}
                              onChange={(e) => {
                                handleChangeAddonElement(
                                  e.target.value,
                                  "Description",
                                  indx
                                );
                              }}
                              disabled={val?.Name === "Custom" ? false : true}
                            />
                          </div>
                          <Hint>
                            <span className="form-text text-muted">
                              Additional information about the Add-On selected.
                            </span>
                          </Hint>
                        </div>
                      </>)}
                      <div className="col-md-6 pb-1">
                        <label>Price*</label>
                        <div>
                          <NumericTextBox
                            id={`price`}
                            name={`price`}
                            width={"100%"}
                            min={0}
                            value={val?.Charge}
                            format={"c2"}
                            onChange={(e) => {
                              handleChangeAddonElement(e.value, "Charge", indx);
                            }}
                            disabled={val?.Name == "Custom" ? false : true}
                          />
                        </div>
                        <Hint>
                          <span className="form-text text-muted">
                            Amount to be charged on every billing cycle.
                          </span>
                        </Hint>
                      </div>
                      <div className="col-md-6 pb-1">
                        <label>Frequency *</label>
                        <div>
                          <DropDownList
                            data={Frequencytype_addon}
                            textField="text"
                            dataItemKey="id"
                            value={
                              Frequencytype_addon.filter(
                                (res: any) => res.id == val?.Frequency
                              )[0] || { text: "Please Select Frequency", id: 0 }
                            }
                            onChange={(e) => {
                              handleChangeAddonElement(
                                e.value,
                                "Frequency",
                                indx
                              );
                            }}
                            disabled={val?.Name == "Custom" ? false : true}
                          />
                        </div>
                        <Hint>
                          <span className="form-text text-muted">
                            The billing frequency for the Add-On.
                          </span>
                        </Hint>
                      </div>
                      <div className="col-md-6 pb-1">
                        <label>Start Date*</label>
                        <div>
                          <DatePicker
                            min={new Date()}
                            max={maximum}
                            value={new Date(val?.StartDate)}
                            className="form-control"
                            onChange={(e) => {
                              handleChangeAddonElement(
                                e.value,
                                "StartDate",
                                indx
                              );
                            }}
                          // disabled={(val?.Name == "Custom") ? false : true}
                          />
                        </div>
                        <Hint>
                          <span className="form-text text-muted">
                            Choose a date from which you want to start this
                            Add-On.
                          </span>
                        </Hint>
                      </div>
                      {val?.Frequency === 1 ? (
                        <></>
                      ) : (
                        <>
                          <div className="col-md-6 pb-1">
                            <label>End After*</label>
                            <div style={{ paddingBottom: "8px" }}>
                              <RadioGroup
                                data={endafter_addon}
                                layout={"horizontal"}
                                disabled={val?.Name === "Custom" ? false : true}
                                onChange={(e) => {
                                  handleChangeAddonElement(
                                    e.value,
                                    "EndType",
                                    indx
                                  );
                                }}
                                value={val?.EndType}
                              />
                            </div>
                            <Hint>
                              <span className="form-text text-muted">
                                Maximum no.of recurring Add-Ons for a billing
                                cycle.
                              </span>
                            </Hint>
                          </div>
                          {val?.EndType === 1 && (
                            <div className="col-md-6 pb-1">
                              <label>No of occurrence*</label>
                              <div>
                                <NumericTextBox
                                  id={`Occurrence`}
                                  name={`Occurrence`}
                                  min={1}
                                  width={"100%"}
                                  value={val?.Occurrence}
                                  onChange={(e) => {
                                    handleChangeAddonElement(
                                      e.value,
                                      "Occurrence",
                                      indx
                                    );
                                  }}
                                  disabled={
                                    val?.Name === "Custom" ? false : true
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div className="col-md-6 pb-1">
                            <label>Repeat Every*</label>
                            <div>
                              <NumericTextBox
                                id={`repeat`}
                                name={`repeat`}
                                min={1}
                                width={"100%"}
                                value={val?.RepeatFrequency}
                                onChange={(e) => {
                                  handleChangeAddonElement(
                                    e.value,
                                    "RepeatFrequency",
                                    indx
                                  );
                                }}
                                disabled={val?.Name === "Custom" ? false : true}
                              />
                            </div>
                            <Hint>
                              <span className="form-text text-muted">
                                Select the number of times the client would want
                                to repeat the Add-Ons based on frequency.
                              </span>
                            </Hint>
                          </div>
                        </>
                      )}
                      <div className="col-md-6 pb-1">
                        <label>Coupons</label>
                        <div>
                          <MultiSelect
                            data={val?.AllCoupons}
                            value={val?.Coupon}
                            textField="Description"
                            dataItemKey="CouponId"
                            placeholder="Please select Coupon"
                            onChange={(e) => {
                              handleChangeAddonCoupon(e.value, indx);
                            }}
                            itemRender={itemRenderCoupon}
                            filterable={true}
                            allowCustom={false}
                            autoClose={false}
                            className="bz-multiselect-arrow"
                          />
                        </div>
                        <Hint>
                          <span className="form-text text-muted">
                            Select a coupon to apply (if applicable).
                          </span>
                        </Hint>
                      </div>
                      {val?.Frequency === 4 && val?.RepeatFrequency === 1 && (
                        <div className="col-md-6 pb-1">
                          <label>Is Prorated</label>
                          <div>
                            <Checkbox
                              checked={val?.IsProrated}
                              onChange={(e) => {
                                handleChangeAddonElement(
                                  e.value,
                                  "IsProrated",
                                  indx
                                );
                              }}
                              value={val?.IsProrated}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <PaymentCheckout
              UserMemberId={props?.UserMemberId}
              handleSubmit={handleSubmit}
              // handlePackageSelection={handlePackageSelection}
              handleForm={(val: string) => handleForm(val)}
              paymentMethod={paymentMethod}
              selected={selected}
              staffPage={true}
              paymentGatewayType={paymentGatewayType}
              bankFormValues={bankFormValues}
              cardImageIcon={cardImageIcon}
              selectedPaymentProfile={selectedPaymentProfile}
              handleBankSubmit={handleBankSubmit}
              cardFormValues={cardFormValues}
              paymentProfile={paymentProfile}
              handleCardSubmit={handleCardSubmit}
              cardSubmitBtnLoading={cardSubmitBtnLoading}
              bankSubmitBtnLoading={bankSubmitBtnLoading}
              handleChangeSavedCard={(val: any) => handleChangeSavedCard(val)}
              handleCardImageIcon={(url: any) => handleCardImageIcon(url)}
              onSelect={(e: any) => onSelect(e)}
              customFields={customFields}
              submitPlanData={submitPlanData}
            />
            <br />
          </div>
          {submitPlanData?.MembershipPlanDetail && (
            <>
              <div className="col-md-4">
                <div className="bz-orderSummary-card">
                  <div className="card" style={{ borderRadius: "5px" }}>
                    <div
                      className="card-header order-summary-header"
                      style={{
                        height: "auto",
                        position: "relative",
                        top: "50%",
                      }}
                    >
                      <h5
                        className="capitaltxt"
                        style={{ color: "#000", margin: "8px" }}
                      >
                        Order Summary
                      </h5>
                    </div>

                    <div style={{ padding: "0 8px" }}>
                      <h4 className="client-name-txt">
                        {submitPlanData?.clientname}
                      </h4>

                      {submitPlanData?.MembershipPlanDetail && (
                        <>
                          <h6 className="buz-font-600 bz-summary-block">
                            Membership
                          </h6>
                          {submitPlanData.CanPayInFull && submitPlanData?.MembershipPlanDetail?.DurationType !== 3 ? (
                            <>
                              <div className="plan-summary-card-row pb-2">
                                <div className="planleftCol">{submitPlanData?.MembershipPlanDetail?.Name}
                                  <small>{` (PIF for ${submitPlanData?.MembershipPlanDetail?.Duration} months)`}</small>
                                </div>
                                <div className="planrightCol">
                                  {CurrencyFormat(
                                    submitPlanData?.MembershipPlanDetail
                                      ?.MonthlyRate *
                                    submitPlanData?.MembershipPlanDetail?.Duration
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (<>
                            <div className="plan-summary-card-row pb-2">
                              <div className="buz-font-600 planleftCol">
                                {submitPlanData?.MembershipPlanDetail?.DurationType === 3 ? (<>
                                  <div className="d-flex flex-column">
                                    <div>{submitPlanData?.MembershipPlanDetail?.Name}</div>
                                    {submitPlanData?.HasAutoRenewal ? (<small>{`(For Every ${submitPlanData?.MembershipPlanDetail.Duration} ${submitPlanData?.MembershipPlanDetail.Duration > 1 ? "Days" : "Day"})`}</small>) : (<small>{`(Expires on ${moment(submitPlanData?.StartDate).add(submitPlanData?.MembershipPlanDetail.Duration, 'days').format('L')})`}</small>)}

                                  </div>
                                </>) : (<>
                                  {submitPlanData?.MembershipPlanDetail?.Name}<small>{` (Monthly)`}</small>
                                </>)}

                              </div>
                              <div
                                className="planrightCol"
                                style={{ fontWeight: 600 }}
                              >
                                {CurrencyFormat(
                                  submitPlanData?.MembershipPlanDetail
                                    ?.MonthlyRate
                                )}
                              </div>
                            </div>


                          </>)}
                        </>
                      )}

                      {submitPlanData?.ProratedAmount > 0 && (
                        <>
                          <div className="plan-summary-card-row pb-2">
                            <div className="planleftCol">Prorated Rate</div>
                            <div className="planrightCol">
                              {CurrencyFormat(
                                // calCulateProratedAmount()
                                submitPlanData?.ProratedAmount?.toFixed(2)
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {submitPlanData?.MembershipPlanDetail?.PlanManagementFee
                        ?.length > 0 && (
                          <>
                            {submitPlanData?.MembershipPlanDetail?.PlanManagementFee.map(
                              (i: any, index: numebr) => (
                                <>
                                  <p
                                    className={`mb-1 buz-font-500 service-para ${CheckPermissions(PermissionEnum["AdministratorAccess"]) ? "additionalfees" : ""}`}

                                  >
                                    <div className="planleftCol">
                                      <div className={"mb-1 plan-summary-card-row  w-100"}>
                                        <span className=" text-dark">
                                          {CheckPermissions(PermissionEnum["AdministratorAccess"]) && <Switch
                                            checked={!i?.CanFeesRemoved ?? true}
                                            defaultValue={true}
                                            className="mr-1"
                                            onChange={(e: any) => handleUpdatePlanManagementFeeStatus(i, index, e.value)}
                                          />}
                                          {i?.Fee?.FeeType} {submitPlanData?.CanFeesWaived ? <span className="badge badge-status-Frozen">Waived</span> : null}
                                        </span>
                                      </div>
                                    </div>

                                    <span className="planrightCol valuememebership">
                                      {CheckPermissions(PermissionEnum["AdministratorAccess"]) ? <NumericTextBox
                                        value={i?.editedAmount ?? i?.Amount}
                                        format="c"
                                        disabled={i?.CanFeesRemoved}
                                        // max={i?.Amount}
                                        min={0}
                                        onChange={(e: any) => handleUpdatePlanManagementFee(i, index, e.target?.value)}
                                        placeholder="$0.00"
                                      /> : CurrencyFormat(i?.Amount)}
                                    </span>
                                  </p>
                                  {/* <div
                                  className={"text-left mb-2 buz-text-small buz-font-500 d-flex align-items-center"}>
                                 {``}</div> */}

                                </>
                              )
                            )}
                          </>
                        )}

                      {submitPlanData?.PreviousPlanRemainingAmount > 0 && (
                        <>
                          <div className="plan-summary-card-row pb-2">
                            <div>Previous Membership Remaining Amount</div>
                            <div>
                              {CurrencyFormat(
                                submitPlanData?.PreviousPlanRemainingAmount.toFixed(
                                  2
                                )
                              )}
                            </div>
                          </div>
                        </>
                      )}


                      {submitPlanData?.Coupon?.length > 0 &&
                        submitPlanData?.Coupon?.map((i: any) => {

                          return (
                            <>
                              <div className="plan-summary-card-row pb-2">
                                <div className="planleftCol">
                                  {i?.CouponInformation}
                                </div>
                                <div className="planrightCol">{`(-${CurrencyFormat(
                                  i?.Type === 2
                                    ? submitPlanData?.MembershipPlanDetail
                                      ?.PercentageCoupon
                                    : submitPlanData?.MembershipPlanDetail
                                      ?.FixedCoupon
                                )})`}</div>
                              </div>
                            </>
                          );
                        })}
                      {submitPlanData?.RecurringAmount > 0 && (
                        <>
                          <div className="plan-summary-card-row pb-2">
                            <div>{"Next EFT"}</div>
                            <div>
                              {CurrencyFormat(
                                submitPlanData?.RecurringAmount?.toFixed(2)
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {checkHasTaxEnable() && submitPlanData?.MembershipPlanDetail?.CanCollectTaxes && Taxes?.length > 0 && <div
                        className={"text-left mb-2 buz-text-small buz-font-500 d-flex align-items-center"}>
                        <Switch
                          checked={!IsTaxSkipped}
                          defaultChecked={false}
                          className="mr-1"
                          onChange={() => handleIsTaxSkipped()}
                        />{`Surcharges`}</div>}

                      {submitPlanData?.SelectedAddons?.length > 0 && (
                        <h6 className="buz-font-600 bz-summary-block">Addon</h6>
                      )}

                      {submitPlanData?.SelectedAddons?.length > 0 &&
                        submitPlanData?.SelectedAddons?.map(
                          (val: any, indx: any) => {
                            return (
                              <>
                                <div className="plan-summary-card-row">
                                  <div className="planleftCol">
                                    <span className="buz-font-600">
                                      {val?.Name}{val?.Frequency > 0 && FrequencyDescription(val) && <small className="text-capitalize">{` (${FrequencyDescription(val)})`}</small>}
                                    </span>
                                  </div>
                                  <div
                                    className="planrightCol"
                                    style={{ fontWeight: 600 }}
                                  >
                                    {CurrencyFormat(val?.Charge)}
                                  </div>
                                </div>
                                <div className="pb-2">
                                  {submitPlanData?.MembershipPlanDetail?.DurationType === 3 && !submitPlanData?.HasAutoRenewal && <small >
                                    {`(Expires on ${moment(submitPlanData?.StartDate).add(submitPlanData?.MembershipPlanDetail.Duration, 'days').format('L')})`}
                                  </small>}
                                </div>
                                {val?.IsProrated && val?.ProratedAmount !== 0 && (
                                  <div className="plan-summary-card-row pb-2">
                                    <div className="planleftCol">
                                      Prorated Amount
                                    </div>
                                    <div className="planrightCol">
                                      {CurrencyFormat(
                                        val?.ProratedAmount?.toFixed(2)
                                      )}
                                    </div>
                                  </div>
                                )}
                                {moment(new Date()).format("L") <
                                  moment(val?.StartDate).format("L") && (
                                    <div className="plan-summary-card-row pb-2">
                                      <div className="planleftCol">
                                        Next EFT
                                      </div>
                                      <div className="planrightCol">
                                        {val?.IsProrated && val?.ProratedAmount !== 0 ? CurrencyFormat(
                                          val?.ProratedAmount?.toFixed(2)) : CurrencyFormat(val?.Charge)}
                                      </div>
                                    </div>
                                  )}
                                <>
                                  {val?.Coupon?.length > 0 &&
                                    val?.Coupon?.map((i: any) => {
                                      return (
                                        <>
                                          <div className="plan-summary-card-row pb-2">
                                            <div className="planleftCol">
                                              {i?.CouponInformation}
                                            </div>
                                            <div className="planrightCol">{`(-${CurrencyFormat(
                                              calculateAddon(i, val)
                                            )
                                              })
                                            
                                            `}</div>
                                          </div>
                                        </>
                                      );
                                    })}
                                </>
                                {checkHasTaxEnable() && val?.CanCollectTaxes && <div
                                  className={"text-left mb-2 buz-text-small buz-font-500"}>
                                  <Switch
                                    checked={!val?.IsTaxSkipped}
                                    defaultChecked={false}
                                    className="mr-1"
                                    onChange={(e) => {
                                      handleChangeAddonElement(
                                        !val?.IsTaxSkipped,
                                        "IsTaxSkipped",
                                        indx
                                      );
                                    }}
                                  />{`Surcharges`}</div>}
                              </>
                            );
                          }
                        )}
                      <hr />
                      {checkTaxApplicable() && (<>
                        {submitPlanData?.TotalBeforeSurcharges >= 0 && (
                          <>
                            <div className="plan-summary-card-row pb-2">
                              <div className="buz-font-600 planleftCol">
                                Total before Surcharges
                              </div>
                              <div
                                className="planrightCol"
                                style={{ fontWeight: 600 }}
                              >
                                {CurrencyFormat(submitPlanData?.TotalBeforeSurcharges)}
                              </div>
                            </div>
                          </>
                        )}
                        {calculateTotalTaxAmount(true) > 0 && (<>
                          <div className={"plan-summary-card-row pb-2"}>
                            <div className="buz-font-600 planleftCol">
                              <Tooltip
                                content={(tooltipProps: any) => {
                                  return (
                                    <TooltipContentTemplate
                                      title={tooltipProps.title}
                                      data={submitPlanData}
                                      TotalTaxAmount={calculateTotalTaxAmount(true)}
                                    />
                                  );
                                }}
                                tooltipClassName="bz-tooltip-table"
                              >
                                <a
                                  title="Surcharges"
                                  style={{
                                    borderBottom: "1px dotted #0d6efd",
                                    cursor: "pointer",
                                  }}
                                >
                                  {"Surcharges to be collected"}
                                </a>
                              </Tooltip>
                            </div>
                            <div
                              className="planrightCol"
                              style={{ fontWeight: 600 }}
                            >
                              {CurrencyFormat(calculateTotalTaxAmount(true))}
                            </div>
                          </div>
                        </>)
                        }
                        <hr />
                      </>)}

                      <div className={"plan-summary-card-row pb-2"}>
                        <div className="buz-font-600 planleftCol">{'Order Total'}</div>
                        <div
                          className="planrightCol"
                          style={{ fontWeight: 600 }}
                        >
                          {CurrencyFormat(submitPlanData?.TotalBeforeSurcharges + calculateTotalTaxAmount(true))}
                        </div>
                      </div>

                      <div className="plan-summary-card-row pb-2">
                        <div className="buz-font-600 planleftCol">
                          Down Payment
                        </div>
                        <div
                          className="planrightCol"
                          style={{ fontWeight: 600 }}
                        >
                          {CurrencyFormat(parseFloat(calculatePaidToday()) + calculateTotalTaxAmount(false))}
                        </div>
                      </div>
                      {(submitPlanData?.PayLater || PaidLaterAddonsLength() > 0) &&
                        <h6 className="buz-font-400 bz-summary-block">
                          Pay On
                        </h6>
                      }

                      {(submitPlanData?.PayLater) && (

                        <div className="plan-summary-card-row pb-2">
                          <div className="buz-font-600 planleftCol">
                            {!submitPlanData?.PayLater ? "Pay" : submitPlanData?.MembershipPlanDetail?.Name} {submitPlanData?.PayLater ? "-" : ""} {(!submitPlanData?.PayLater) ? "Today" : `${startDate}`}

                          </div>
                          <div
                            className="planrightCol"
                            style={{ fontWeight: 600 }}
                          >
                            {submitPlanData?.MembershipPlanDetail?.CanCollectTaxes && !IsTaxSkipped && checkHasTaxEnable() ? CurrencyFormat(currencyPaid() + PredictedTotalTaxAmount
                            ) : CurrencyFormat(currencyPaid())}
                          </div>
                        </div>

                      )}
                      {PaidLaterAddonsLength() > 0 && (
                        submitPlanData?.SelectedAddons?.map((itm: any) => {
                          if (moment(new Date()).format("L") <
                            moment(itm?.StartDate).format("L")) {
                            const addonCoupons = itm?.Coupon?.map((i: any) => {
                              return calculateAddon(i, itm)
                            })
                            const intialValue = 0
                            const sumWithInitial = addonCoupons?.reduce(
                              (accumulator: any, currentValue: any) => accumulator + currentValue,
                              intialValue
                            );
                            return (
                              <div className="plan-summary-card-row pb-3">
                                <div className="buz-font-600 planleftCol">
                                  {`${itm?.Name} - ${itm?.StartDate} `}
                                </div>
                                <div
                                  className="planrightCol"
                                  style={{ fontWeight: 600 }}
                                >{itm?.IsProrated && itm?.ProratedAmount !== 0 ?
                                  CurrencyFormat(itm?.ProratedAmount?.toFixed(2) - (sumWithInitial || 0) + itm?.PredictedTotalTaxAmount) :
                                  CurrencyFormat(
                                    itm?.Charge - (sumWithInitial || 0) + itm?.PredictedTotalTaxAmount
                                  )}
                                </div>
                              </div>
                            )
                          } else {
                            return (<></>)
                          }
                        })

                      )}
                      {visibleAgreement && <div className="k-overlay"></div>}
                      <BuzopsWindow
                        key={visibleAgreement.toString()}
                        reduceWidth={widthOfDevice < 800 ? 10 : 430}
                        onWindowClose={() => setVisibleAgreement(false)}
                        title={"Agreement"}
                        className={"bzo-window bzo-agreement-window"}
                        visibility={visibleAgreement}
                      >
                        <div
                          className="bzo-agreement"
                          dangerouslySetInnerHTML={{
                            __html: agreementTemplate,
                          }}
                        />
                      </BuzopsWindow>
                      {visibleSignPad && (
                        <SignaturePad
                          staffPage={props?.staffPage}
                          generatedImage={(imageString, text, font, typeVal) =>
                            generatedImage(imageString, text, font, typeVal)
                          }
                          key={visibleSignPad.toString()}
                          onClose={() => setVisibleSignPad(false)}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    {true && (
                      <div
                        className={signatureType === 'type' ? `buz-signature-type mt-3` : `buz-signature mt-3`}
                        onClick={() => {
                          handleSignaturePad();
                        }}
                        style={{ display: "flex", justifyContent: "left" }}
                      >
                        {signature !== "" ? (
                          signatureText && signatureFont ? (
                            <span
                              style={{
                                fontFamily: signatureFont,
                                alignSelf: "center",
                                fontSize: signatureType === 'type' ? `25px` : `36px`,
                                overflow: "visible",
                                padding: signatureType === 'type' ? `2px` : `0px`,
                              }}
                            >
                              {signatureText}
                            </span>
                          ) : (
                            <img
                              src={signature}
                              placeholder={"Please Sign"}
                              width={"100%"}
                              height={"100%"}
                              onClick={() => {
                                handleSignaturePad();
                              }}
                            ></img>
                          )
                        ) : (
                          <div className="buz-signature-pad">
                            <img src={bzImages.SignHereImg} alt="Sign-here"/>
                          </div>
                        )}
                      </div>
                    )}

                    <p
                      className={
                        "text-left mb-3 buz-text-small buz-font-500 agree-copy"
                      }
                    >
                      {disable ? (
                        <>
                          <a className={"disable_link"}>
                            View Your Agreement Copy
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            className={"buzops_link "}
                            onClick={() => handleFinalSubmit("agreement")}
                          >
                            View Your Agreement Copy
                          </a>
                        </>
                      )}
                    </p>
                    <div
                      className={"text-left mb-4 buz-text-small buz-font-500"}
                    >
                      <Checkbox
                        checked={termsandConditions}
                        defaultChecked={false}
                        disabled={disable}
                        onChange={() => handleTermsConditionDialog(!termsandConditions)}
                      />{" "}
                      I agree to the{" "}
                      <span
                        className={
                          "mb-1 buz-text-small buz-font-600 agree-copy"
                        }
                      >
                        {disable ? (
                          <>
                            <a className={"disable_link"}>
                              {"Terms & Conditions"}
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              className={"buzops_link "}
                              onClick={() => handleTermsConditionDialog(null)}
                            >
                              {"Terms & Conditions"}
                            </a>
                          </>
                        )}
                      </span>
                    </div>
                    <BuzopsButton
                      loading={btnLoading}
                      disabled={btnLoading || disable}
                      label={"Purchase"}
                      // onClick={() => handleFinalSubmit("submit")}
                      onClick={() => handleClientConfirmation()}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {confirmClientDialog && renderClientConfirmationDialog()}
      {termsandConditionsDialog && <TermsAndConditions handleTermsConditionDialog={handleTermsConditionDialog} handleTermsConditions={handleTermsConditions} />}
    </>
  );
};

export default withNotification(AssignPlan);
