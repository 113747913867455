import React, { useState } from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartAxisDefaults,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import BuzopsLoader from "generic-components/BuzopsLoader";

const OneonOneChart = ({ chartOriginalData, chartLoading }: any) => {
  const [chartView, setChartView] = useState<any>("Bar chart");
  const [dataType, setDataType] = React.useState("Bar");

  const handleLineChartClick = () => {
    setChartView("Line Chart");
    setDataType("Line");
  };
  const handleBarChartClick = () => {
    setChartView("Bar chart");
    setDataType("Bar");
  };
  const chartCategories = chartOriginalData?.Items?.map(
    (item: any) => item.Year
  );
  let ChartSeriesItemData: any[] = [];
  ChartSeriesItemData = chartOriginalData?.Items?.map(
    (item: any) => item.PersonalTraining
  );
  let max = 0;
  if (ChartSeriesItemData) {
    max = Math.max(...ChartSeriesItemData.map((data: any) => data));
  }

  const chartSeriesName = chartOriginalData?.Items?.map(
    (el: any) => el.OverallPersonalTraining
  );
  const chartNameData = chartSeriesName && chartSeriesName[0];
  return (
    <>
      <div className="d-flex mb-1 sales-sub-header">
        <div className="me-auto sales-header">Sales by One-on-One</div>
        <div
          className={
            dataType === "Line"
              ? "p-2 sales-line-header clicked"
              : "p-2 sales-line-header"
          }
        >
          <span className="sales-line-button" onClick={handleLineChartClick}>
            <i className="k-icon k-i-chart-line mr-1"></i>
            Line
          </span>
        </div>
        <div
          className={
            dataType === "Bar"
              ? "p-2 sales-bar-header clicked"
              : "p-2 sales-bar-header"
          }
        >
          <span className="sales-bar-button" onClick={handleBarChartClick}>
            Bar
          </span>
        </div>
      </div>
      <div className="sales-graphs-header">
        {chartLoading ? (
          <>
            <BuzopsLoader type="list" />
          </>
        ) : (
          <>
            {chartView === "Line Chart" ? (
              <div className="mt-6 mb-5">
                <div className="k-card">
                  <Chart className="slaes-line-graphs">
                    <ChartValueAxis>
                      <ChartValueAxisItem
                        min={0}
                        max={max + 980}
                        color={"black"}
                      />
                    </ChartValueAxis>

                    <ChartAxisDefaults
                      labels={{
                        format: "c0",
                      }}
                      majorGridLines={{ visible: false }}
                    />
                    <ChartLegend position="top" orientation="horizontal" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={chartCategories}
                        color={"black"}
                      />
                    </ChartCategoryAxis>
                    <ChartSeries>
                      <ChartSeriesItem
                        type="line"
                        labels={{
                          visible: true,
                          format: "c",
                        }}
                        tooltip={{
                          visible: true,
                          format:
                            "One-on-One" +
                            " " +
                            "($" +
                            chartNameData +
                            ")" +
                            ":" +
                            " " +
                            " ${0}",
                        }}
                        data={ChartSeriesItemData}
                      />
                    </ChartSeries>
                  </Chart>
                </div>
              </div>
            ) : (
              <div className="mt-6 mb-5">
                <div className="k-card">
                  <Chart className="slaes-bar-graphs">
                    <ChartAxisDefaults
                      labels={{
                        format: "c0",
                      }}
                      majorGridLines={{ visible: false }}
                    />
                    <ChartLegend position="top" orientation="horizontal" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={chartCategories}
                        startAngle={45}
                        color={"black"}
                      />
                    </ChartCategoryAxis>
                    <ChartSeries>
                      <ChartSeriesItem
                        type="column"
                        labels={{
                          visible: true,
                          format: "c",
                        }}
                        tooltip={{
                          visible: true,
                          format:
                            "One-on-One" +
                            " " +
                            "($" +
                            chartNameData +
                            ")" +
                            ":" +
                            " " +
                            " ${0}",
                        }}
                        data={ChartSeriesItemData}
                      />
                    </ChartSeries>
                  </Chart>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(OneonOneChart);
