import { Dialog } from "@progress/kendo-react-dialogs";
import AffiliateWelcome from "components/affiliate-welcome";
import AffiliateLoginPage from "components/login/AffiliateLoginPage";
import EmailOTP from "components/login/EmailOTP";
import LoginPage from "components/login/loginPage";
import ResetPassword from "components/resetPassword";
import Signup from "components/signup";
import Welcome from "components/welcome";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const Auth = (props: any) => {
  const [authroute, setauthroute] = useState(props?.loginPage ==="affiliate" ? "affiliatelogin":'login');
  const redirectionObj = useSelector((state: any) => state?.redirection);
  const providerId = props.match?.params?.providerId;
  const [guestLayout, setGuestLayout] = useState<boolean>(
    providerId || props.providerName
      ? true
      : redirectionObj?.firstPathValue === "" ||
        redirectionObj?.firstPathValue === "member" ||
        redirectionObj?.firstPathValue === "external"
      ? false
      : true
  );
  const [locationData, setLocationData] = useState(null);
  const setAuthRoute = (currentRoute: any, locationData: any = null) => {
    setauthroute(currentRoute);
    setLocationData(locationData);
  };
  const renderComponent = (props: any) => {
    // const authroute=props.match.params.authroute
    switch (authroute) {
      case "login":
        return (
          <LoginPage
            {...props}
            setAuthRoute={(page: any, locationData: any) =>
              setAuthRoute(page, locationData)
            }
            locationData={locationData}
            guestLayout={guestLayout}
            handleCloseLogin={() => props.handleCloseLogin()}
          />
        );
      case "affiliatelogin":
        return (
          <AffiliateLoginPage
            {...props}
            setAuthRoute={(page: any, locationData: any) =>
              setAuthRoute(page, locationData)
            }
            locationData={locationData}
            guestLayout={guestLayout}
            handleCloseLogin={() => props.handleCloseLogin()}
          />
        );
      case "welcome":
        return (
          <Welcome
            {...props}
            setAuthRoute={(page: any, locationData: any) =>
              setAuthRoute(page, locationData)
            }
            locationData={locationData}
            guestLayout={guestLayout}
          />
        );
      case "affiliatewelcome":
        return (
          <AffiliateWelcome
            {...props}
            setAuthRoute={(page: any, locationData: any) =>
              setAuthRoute(page, locationData)
            }
          />
        );
      case "signup":
        return (
          <Signup
            {...props}
            setAuthRoute={(page: any, locationData: any) =>
              setAuthRoute(page, locationData)
            }
            locationData={locationData}
            guestLayout={guestLayout}
          />
        );
      case "emailOTP":
        return (
          <EmailOTP
            {...props}
            setAuthRoute={(page: any, locationData: any) =>
              setAuthRoute(page, locationData)
            }
            locationData={locationData}
            guestLayout={guestLayout}
          />
        );
      case "resetPassword":
        return (
          <ResetPassword
            {...props}
            setAuthRoute={(page: any, locationData: any) =>
              setAuthRoute(page, locationData)
            }
            locationData={locationData}
            guestLayout={guestLayout}
            affiliate={false}
          />
        );
      case "affiliateResetPassword":
        return (
          <ResetPassword
            {...props}
            setAuthRoute={(page: any, locationData: any) =>
              setAuthRoute(page, locationData)
            }
            locationData={locationData}
            guestLayout={guestLayout}
            affiliate={true}
          />
        );
      default:
        return (
          <LoginPage
            {...props}
            setAuthRoute={(page: any, locationData: any) =>
              setAuthRoute(page, locationData)
            }
            locationData={locationData}
            guestLayout={guestLayout}
          />
        );
    }
  };

  return (
    <>
      {/* <About/> */}
      {props.showComponent && (
        <Dialog className="bzo-login-dialog">{renderComponent(props)}</Dialog>
      )}
    </>
  );
};

export default Auth;
