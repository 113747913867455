import { createSlice } from "@reduxjs/toolkit";
export const redirectionSlice = createSlice({
  name: "redirection",
  initialState: {
    firstPathValue: "", //must be /app or /external or /:providerId or /,
    staffuuid: "",
    clientMemberId: "",
    staffPage: false,
    secondRoute:""
  },
  reducers: {
    setRedirectionConfig: (state, action) => {
      const {
        firstPathValue="",
        staffuuid = "",
        clientMemberId = "",
        staffPage = false,
        secondRoute = "",
      } = action.payload;
      state.firstPathValue = firstPathValue;
      state.staffuuid = staffuuid;
      state.clientMemberId = clientMemberId;
      state.staffPage = staffPage;
      state.secondRoute = secondRoute;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRedirectionConfig } = redirectionSlice.actions;

export default redirectionSlice.reducer;
