import { useState, useEffect } from "react";
import { ReportsService } from "services/reports/index.service";
import {
  DetailedMRR1Columns,
  DetailedMRR2Columns,
  MRRDataColumns,
} from "../Utilities/constants";

const useDetailedMRR = (props: any) => {
  const { hierarchyType, selectedRow } = props;
  const [clientData, setClientData] = useState<any>({});
  var lastday = function (y: any, m: any) {
    return new Date(y, m, 0).getDate();
  };

  const lastDate = lastday(
    selectedRow.Month.split("/")[1],
    selectedRow.Month.split("/")[0]
  );

  const gridColumns =
    hierarchyType === 1 ? DetailedMRR1Columns : DetailedMRR2Columns;
  const [show, setShow] = useState(false);
  const [updaterFullscreen, setUpdaterFullscreen] = useState(false);
  const [stateGridColumns, setStateGridColumns] = useState(gridColumns);
  const [stateGrid1Columns, setStateGrid1Columns] = useState(MRRDataColumns);
  const [pageInfo, setPageInfo] = useState<any>({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });

  const [page1Info, setPage1Info] = useState({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });
  const [excelData, setExcelData] = useState([]);
  const [excel1Data, setExcel1Data] = useState([]);
  const service = new ReportsService();
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async (pageData?: any, forExcel = false) => {
    const req = {
      FilterFields: [],
      PageNumber: forExcel
        ? 0
        : pageData?.dataState?.skip
        ? pageData?.dataState.skip / pageData?.dataState.take
        : 0,
      PageSize: forExcel ? pageInfo.total : pageData?.dataState?.take || 10,
      showAddittionFilter: false,
      IsExportRequest: false,
      IncludeDateRanges: true,
      FromDate: pageData?.fromDate
        ? pageData?.fromDate
        : `2022-${selectedRow.Month.split("/")[0]}-01T00:00:00`,
      ToDate: pageData?.fromDate
        ? pageData?.toDate
        : `2022-${selectedRow.Month.split("/")[0]}-${lastDate}T23:59:59`,
      GridHirecharyLevel: hierarchyType,
      IncludeNextEFT: false,
      IncludePaymentType: 0,
      SortBy:
        pageData?.sort?.length > 0
          ? pageData.sort[0].field
          : "RecurrenceDescription",
      SortDirection: pageData?.sort?.length > 0 ? pageData.sort[0].dir : "desc",
    };
    const getData = await service.getMRRData(req);

    if (forExcel) {
      setExcelData(getData.Item.Result);
    } else {
      setPageInfo((prev: any) => {
        prev.data = getData.Item.Result;
        prev.pageNumber = req.PageNumber;
        prev.total = getData.Item.TotalItems;
        if (pageData?.take) {
          prev.skip = pageData.skip;
          prev.take = pageData.take;
        }
        return { ...prev };
      });
    }
  };
  const fetchData1 = async (pageData?: any, forExcel = false) => {
    const req = {
      Filters: pageData?.filters || [],
      PageNumber: forExcel
        ? 0
        : pageData?.dataState?.skip
        ? pageData?.dataState.skip / pageData?.dataState.take
        : 0,
      PageSize: forExcel ? pageInfo.total : pageData?.dataState?.take || 10,
      showAddittionFilter: false,
      IsExportRequest: false,
      IncludeDateRanges: true,
      FromDate: `2022-${selectedRow.Month.split("/")[0]}-01T00:00:00`,
      ToDate: `2022-${selectedRow.Month.split("/")[0]}-${lastDate}T23:59:59`,
      GridHirecharyLevel: 2,
      IncludeNextEFT: false,
      IncludePaymentType: 0,
      SortBy:
        pageData?.sort?.length > 0
          ? pageData.sort[0].field
          : "RecurrenceDescription",
      SortDirection: pageData?.sort?.length > 0 ? pageData.sort[0].dir : "desc",
    };
    const getData = await service.getMRRData(req);

    if (forExcel) {
      setExcel1Data(getData.Item.Result);
    } else {
      setPage1Info((prev) => {
        prev.data = getData.Item.Result;
        prev.pageNumber = req.PageNumber;
        prev.total = getData.Item.TotalItems;
        if (pageData?.take) {
          prev.skip = pageData.skip;
          prev.take = pageData.take;
        }
        return { ...prev };
      });
      setShow(!show);
    }
  };

  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };
  const onColumns1Submit = (columnsState: any) => {
    setStateGrid1Columns(columnsState);
  };

  const onCellClick = async (cellData: any) => {
    const filters = [
      {
        column: "StatusDescription",
        condition: "and",
        dataType: "string",
        displayName: "Recurring Type",
        dropDownValue: "",
        field: "StatusDescription",
        filter: "eq",
        logic: "and",
        numberValue: 0,
        operator: "eq",
        type: "string",
        value: cellData?.RecurrenceDescription,
      },
    ];
    console.log(cellData);
    await fetchData1({ filters: filters });
  };
  const onCell1Click = (cellData: any) => {
    console.log(cellData);
    setClientData(cellData);
  };
  return {
    pageInfo,
    fetchData,
    setUpdaterFullscreen,
    updaterFullscreen,
    stateGridColumns,
    setStateGridColumns,
    onColumnsSubmit,
    onCellClick,
    onCell1Click,
    excel1Data,
    excelData,
    setExcelData,
    setShow,
    show,
    page1Info,
    fetchData1,
    MRRDataColumns,
    setStateGrid1Columns,
    onColumns1Submit,
    setExcel1Data,
    clientData,
  };
};

export default useDetailedMRR;
