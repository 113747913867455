import { Html5QrcodeScanner } from "html5-qrcode";
import React, { useEffect } from 'react';

const qrcodeRegionId = "html5qr-code-full-region";

const Html5QrcodePlugin = ({
    fps = 50,
    qrbox = { height: 200, width: 300 },
    disableFlip = false,
    aspectRatio = 200,
    SuccessCallback,
    FailureCallback
}: any) => {
    useEffect(() => {
        let html5QrcodeScanner = new Html5QrcodeScanner(
            qrcodeRegionId,
            { fps: fps, qrbox: qrbox, disableFlip: disableFlip, /*aspectRatio: aspectRatio */ },
            /* verbose= */ false
        );
        html5QrcodeScanner.render(SuccessCallback, FailureCallback);
        return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        }
    }, [])


    return <div id={qrcodeRegionId} className="qrcodescanner" />;
};

export default Html5QrcodePlugin;