import React, { useEffect, useState } from "react";
import xss from "xss";

const myxss = new xss.FilterXSS({
  whiteList: {
    u: [],
    br: [],
    b: [],
    i: [],
    ol: ["style"],
    ul: ["style"],
    li: [],
    p: ["style"],
    sub: [],
    sup: [],
    div: ["style"],
    em: [],
    strong: [],
    span: ["style"],
  },
});

const BuzopsFormValidator = (props: any) => {
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    const listener = props?.emitterRef?.addListener(
      "formValidation",
      (errors: any) => {
        setErrors(errors);
      }
    );
    return () => {
      listener.remove();
    };
  }, [props?.emitterRef]);

  const dismissModal = (e: any) => {
    e.preventDefault();
    setErrors([]);
  };

  const errorItems = errors.map((error: any, index: number) => (
    <li
      key={`error_${index}`}
      dangerouslySetInnerHTML={{ __html: myxss.process(error) }}
    />
  ));
  return (
    <div>
      {errors.length > 0 && (
        <div className="alert alert-danger validation-error">
          <div className="clearfix">
            <i className="fas fa-exclamation-triangle float-left"></i>
            <ul className="float-left">{errorItems}</ul>
          </div>
          <div className="clearfix">
            <a
              className="float-right btn btn-default btn-sm btn-danger"
              onClick={(e: any) => dismissModal(e)}
            >
              Dismiss
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuzopsFormValidator;
