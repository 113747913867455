import "./../index.scss";
const Error404 = (props = null) => {
  let displayText = "";
  if (props?.displayText) {
    displayText = props?.displayText;
  }
  return (
    <div className="">
      <div id="bz-notfound">
        <div className="bz-notfound">
          <div className="bz-notfound-404"></div>
          <h1>404</h1>
          {displayText ? (
            <h6>{displayText}</h6>
          ) : (
            <>
              <h2>Requested URL was not found</h2>
              <h6>Please check the URL address</h6>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Error404;
