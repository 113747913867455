import { Calendar, DatePicker } from "@progress/kendo-react-dateinputs";
import ReportsCommonFilters from "components/reports/reportsCommonFilters";
import BuzopsReportGrid from "generic-components/BuzopsReportGrid";
import useAccountUpdater from "../Container/AccountUpdater.hook";

const AccountUpdater = (props: any) => {
  const uuid = props?.match?.params?.uuid;
  const accountUpdater = useAccountUpdater();

  return (
    <>
      <ReportsCommonFilters
        {...props}
        showDate={false}
        history={props.history}
        location={props.location}
      />
      <div className="mt-4 subscribers-main-container">
        <div className="d-flex justify-content-end subscribers-close-icon">
          <li
            className="k-icon k-i-close"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
        <div className="subscribers-main-header">
          <h2 className="subscriber-header">Account Updater</h2>
          <div className="subscribers-labels">
            <div className="subscribers-grid-parent">
              <div className="subscribers-card-content">
                <div className={"subscribers-box"}>
                  <DatePicker
                    format={"MM/yyyy"}
                    calendar={() => (
                      <Calendar bottomView="year" topView="year" />
                    )}
                  />
                </div>
                <div
                  className={
                    accountUpdater.dataType === "NAN"
                      ? "subscribers-box clicked"
                      : "subscribers-box"
                  }
                  onClick={() => {
                    accountUpdater.handleChange("NAN");
                  }}
                >
                  <div className="subsctibers-box-content">
                    <p>New Account Number</p>
                    <h2>{"0"}</h2>
                  </div>
                  <span>NAN</span>
                </div>
                <div
                  className={
                    accountUpdater.dataType === "NED"
                      ? "subscribers-box clicked"
                      : "subscribers-box"
                  }
                  onClick={() => {
                    accountUpdater.handleChange("NED");
                  }}
                >
                  <div className="subsctibers-box-content">
                    <p>New Expiration Date</p>
                    <h2>{"0"}</h2>
                  </div>
                  <span>NED</span>
                </div>
                <div
                  className={
                    accountUpdater.dataType === "N/A"
                      ? "subscribers-box clicked"
                      : "subscribers-box"
                  }
                  onClick={() => {
                    accountUpdater.handleChange("N/A");
                  }}
                >
                  <div className="subsctibers-box-content">
                    <p>Not Available</p>
                    <h2>{"0"}</h2>
                  </div>
                  <span>N/A</span>
                </div>
              </div>
            </div>
          </div>
          <BuzopsReportGrid
            fileName="AccountUpdated.xlsx"
            //   handleSearch={handleSearch}
            result={accountUpdater.tableData}
            dataState={accountUpdater.dataState}
            dataStateChange={accountUpdater.dataStateChange}
            stateGridColumns={accountUpdater.stateGridColumns}
            pageSize={5}
            setStateGridColumns={(data: any) =>
              accountUpdater.setStateGridColumns(data)
            }
            onColumnsSubmit={accountUpdater.onColumnsSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default AccountUpdater;
