import { PaymentMadeFor, PrivateServiceType, ScheduleStatus } from "utils";
import { PermissionEnum } from "./PermissionEnum";
import CheckPermissions from "./permissions";
import { GetLocalStore } from "./storage";

const userSession = JSON.parse(
  JSON.stringify(GetLocalStore("tenantUserSession"))
);
const UserAssociateId = userSession.UserAssociateId;

export const NotesPermission = (TrainerId: any) => {
  return (
    CheckPermissions(PermissionEnum["Level3"]) ||
    ((CheckPermissions(PermissionEnum["Level1"]) ||
      CheckPermissions(PermissionEnum["Level2"]) ||
      CheckPermissions(PermissionEnum["ComplementaryCheckout"]) ||
      CheckPermissions(PermissionEnum["QuickCheckout"])) &&
      TrainerId === UserAssociateId)
  );
};

export const ScheduleReSchedulePermission = function (dataItem: any) {
  return (
    (CheckPermissions(PermissionEnum["Level3"]) ||
    CheckPermissions(PermissionEnum["Level2"]) ||
    CheckPermissions(PermissionEnum["Level1"]) ||
    CheckPermissions(PermissionEnum["ComplementaryCheckout"]) ||
    CheckPermissions(PermissionEnum["QuickCheckout"])) && (dataItem?.Status === ScheduleStatus["Active"] ||
      dataItem?.Status === ScheduleStatus["None"])
  );
};

export const DeletePermission = (dataItem: any) => {
  return (
    (CheckPermissions(PermissionEnum["Level3"]) ||
      ((CheckPermissions(PermissionEnum["Level2"]) ||
        CheckPermissions(PermissionEnum["Level1"]) ||
        CheckPermissions(PermissionEnum["ComplementaryCheckout"]) ||
        CheckPermissions(PermissionEnum["QuickCheckout"])) &&
        dataItem?.TrainerId === UserAssociateId)) &&
    (dataItem?.Status === ScheduleStatus["Active"] ||
      dataItem?.Status === ScheduleStatus["None"])
  );
};

export const ViewOtherProvidersAppointmentsPermission = () => {
  return (
    CheckPermissions(PermissionEnum["Level3"]) ||
    CheckPermissions(PermissionEnum["Level2"]) ||
    CheckPermissions(PermissionEnum["Level1"]) ||
    CheckPermissions(PermissionEnum["ComplementaryCheckout"]) ||
    CheckPermissions(PermissionEnum["QuickCheckout"])
  );
};

export const ShowNoShowPermission = (dataItem: any) => {
  return (
    CheckPermissions(PermissionEnum["Level3"]) ||
    ((
      CheckPermissions(PermissionEnum["Level2"]) ||
      CheckPermissions(PermissionEnum["ComplementaryCheckout"]) ||
      CheckPermissions(PermissionEnum["QuickCheckout"])) &&
      dataItem?.TrainerId === UserAssociateId)
  );
};

export const DisputePermission = (dataItem: any) => {
  return (
    CheckPermissions(PermissionEnum["Level3"]) ||
    ((
      CheckPermissions(PermissionEnum["ComplementaryCheckout"]) ||
      CheckPermissions(PermissionEnum["QuickCheckout"])) &&
      dataItem?.TrainerId === UserAssociateId)
  );
};
export const CancelPackagePermission = () => {
  return CheckPermissions(PermissionEnum["ServiceCancel"])
  
};

export const GroupServicePermission = (dataItem: any) => {
  return (
    CheckPermissions(PermissionEnum["GroupServiceLevel3"]) ||
    (CheckPermissions(PermissionEnum["GroupServiceCancellation"]) &&
      dataItem?.TrainerId === UserAssociateId)
  );
};


export const WaivePackagePaymentPermission=()=>{
  return CheckPermissions(PermissionEnum["ServiceWaive"])

}
export const RefundPackagePaymentPermission=()=>{
  return CheckPermissions(PermissionEnum["ServiecRefunds"])

}
export const ChargeBackPermission=()=>{
  return CheckPermissions(PermissionEnum["Chargeback_Return_Manage_User"])

}
export const SessionSignOffPermission=()=>{
  return CheckPermissions(PermissionEnum["QuickCheckout"])

}
export const signOffComplimentaryPermission=()=>{
  return CheckPermissions(PermissionEnum["ComplementaryCheckout"])

}

export const showWaived = (dataItem: any) => {
  if (
    dataItem.PaymentMadeFor === PaymentMadeFor["Membership"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["MembershipCancellation"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["MembershipFreeze"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["MembershipUnFreeze"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["PurchaseSubscription"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["SubscriptionCancellation"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["SubscriptionFreeze"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["SubscriptionUnFreeze"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["SubscriptionWithdraw"]
  ) {
    return CheckPermissions(PermissionEnum["ManageWaivePayment"]);
  } else if (
    dataItem.PaymentMadeFor === PaymentMadeFor["PrivateService"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["PrivateServiceCancellation"]
  ) {
    return CheckPermissions(PermissionEnum["ServiceWaive"]);
  } else if (
    dataItem.PaymentMadeFor === PaymentMadeFor["Package"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["PackageCancellation"]
  ) {
    return WaivePackagePaymentPermission()
  }else{
    return true;
  }
};


export const showRefund = (dataItem: any) => {
  if (
    dataItem.PaymentMadeFor === PaymentMadeFor["Membership"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["MembershipCancellation"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["MembershipFreeze"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["MembershipUnFreeze"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["PurchaseSubscription"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["SubscriptionCancellation"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["SubscriptionFreeze"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["SubscriptionUnFreeze"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["SubscriptionWithdraw"]
  ) {
    return CheckPermissions(PermissionEnum["ManageRefund"]);
  } else if (
    dataItem.PaymentMadeFor === PaymentMadeFor["PrivateService"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["PrivateServiceCancellation"] || 
    dataItem.PaymentMadeFor === PaymentMadeFor["Package"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["PackageCancellation"]
  ) {
    return RefundPackagePaymentPermission()
  }  else if (
    dataItem.PaymentMadeFor === PaymentMadeFor["Class"] ||
    dataItem.PaymentMadeFor === PaymentMadeFor["ClassCancellation"]
  ) {
    return CheckPermissions(PermissionEnum["GroupServiceLevel3"]);
  }else if (dataItem.PaymentMadeFor === PaymentMadeFor["PosPurchase"]) {
    return false;
  } else {
    return true;
  }
};

export const isAdmin=()=> {
  return  GetLocalStore("CurrentApplicationType") === 1;
}


export const CanRevertSchedule = (dataItem: any) => {
  return (
    CheckPermissions(PermissionEnum["Level3"]) &&
    dataItem?.Status !== ScheduleStatus["Active"]
  );
};
