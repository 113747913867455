import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import Transactions from "components/package/Transactions";
import AuditHistory from "components/summary/AuditHistory";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import {
  checkSubscriptionStatus,
  getDayFormat, getMonthFormat,
  getTimeFormat,
  getWeekdayFormat,
  getYearFormat
} from "utils";
import virtualMeeting from "./../../virtual-meeting.jpg";
import {
  Tooltip,
} from "@progress/kendo-react-tooltip";
import BuzopsLoader from "generic-components/BuzopsLoader";
const PackagesSummary = (props: any) => {
  const [selected, setSelected] = React.useState(0);
 const [searchAppointments, setSearchAppointments] = useState<any>({
    pageNumber: 0,
    maxPageNumber: 0,
    appointmentsData: [],
  });
  const [loading, setLoading] = useState(false);
  let tabs = [
    { title: "Upcoming" },
    { title: "Past" },
    { title: "Transactions" },
    { title: "Audit" },
  ];
  if (props?.from === "online") {
    tabs = [{ title: "Upcoming" }, { title: "Past" }];
  }

  const handleSelect = (e: any) => {
    const val = e.selected;
    if (val === 0) {
      getUpcomingAppointments(0, 0);
    } else if (val === 1) {
      getUpcomingAppointments(0, 1);
    }
    setSelected(val);
  };
  useEffect(() => {
    getUpcomingAppointments(0, 0);
  }, []);

  const handlePage = (indx: number, searchAppts: any) => {
    switch (indx) {
      case 0:
        return renderAppointments(0);
      case 1:
        return renderAppointments(1);
      case 2:
        return (
          <Transactions
            EntityId={props?.PackageInstanceId}
            UserMemberId={props?.UserMemberId}
          />
        );
      case 3:
        return (
          <AuditHistory
            EntityId={props?.PackageInstanceId}
            UserMemberId={props?.UserMemberId}
            Type={512}
          />
        );
      default:
        return renderAppointments(0);
    }
  };
  const getUpcomingAppointments = async (
    pageNumber: number = 0,
    selectedTab: number
  ) => {
    if (pageNumber === 0) {
      setLoading(true);
    }
    const pageSize = 10;

    let req: any;
    const fetchAppointments = new TenantService();
    if (selectedTab === 0) {
      req = {
        PageNumber: pageNumber,
        PageSize: pageSize,
        FromDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        ShowFutureDate: true,
        ToDate: moment().add(80, "days").format("YYYY-MM-DD") + "T00:00:00",
        UserMemberId: props?.UserMemberId,
        ServiceInstanceId: props?.PackageInstanceId,
        IsPackageInstance: true,
        Status: 1,
      };
    } else if (selectedTab === 1) {
      req = {
        FromDate: moment().add(-1, "month").format("YYYY-MM-DD") + "T00:00:00",
        ShowFutureDate: false,
        ToDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        UserMemberId: props?.UserMemberId,
        ServiceInstanceId: props?.PackageInstanceId,
        IsPackageInstance: true,
      };
    }
    const result = await fetchAppointments.getUpcomingAppointments(req);
    if (pageNumber === 0) {
      setLoading(false);
    }
    if (result) {
      const maxPageNumber = result.TotalItems / pageSize;
      setSearchAppointments({
        ...searchAppointments,
        pageNumber: searchAppointments.pageNumber,
        maxPageNumber: maxPageNumber,
        appointmentsData: result?.Result || [],
      });
    }
    return result?.Result || [];
  };

  const GroupServicesRender = (props: any,indx:number) => {
    let item = props.dataItem;
    return (
      <div className="">
        <div className="card bzc-sessions-list-card mb-3">
          <div className="card-body">
            <div className="bzc-sessions-list-row">
              <div className="bzc-col-1">
                <div>
                  <div className="bzc-date-sec">
                    <i className="far fa-calendar-alt"></i>
                    {getWeekdayFormat(item?.FromTime)},&nbsp;
                    {getDayFormat(item?.FromTime)}&nbsp;
                    {getMonthFormat(item?.FromTime)}&nbsp;
                    {getYearFormat(item?.FromTime)}
                    {checkSubscriptionStatus(1110) && item?.IsVirtualMeeting && indx===0 && (<a style={{ color: "#008db9" }}
                            className={"justify-content"}
                            rel="noreferrer"
                            href={item?.VirtualMeetingLink}
                    target="_blank"><img src={virtualMeeting} alt="Virtual Meeting Appointment" style={{maxWidth:'1.5rem'}} /></a>)}
                     {!checkSubscriptionStatus(1110) && item?.IsVirtualMeeting && indx===0 && (<Tooltip anchorElement="target" position="top" ><img title="Virtual Meeting is disabled at club level.Please contact administrator." src={virtualMeeting} alt="Virtual Meeting Appointment" style={{maxWidth:'1.5rem'}} />
                          </Tooltip>)}

                  </div>
                  <div className="bzc-time-sec">
                    <span className={""}>
                      <i className="far fa-clock"></i>
                      {getTimeFormat(item?.FromTime)} -{" "}
                      {getTimeFormat(item?.ToTime)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="bzc-col-1">
                <div>
                  <div className="bzc-session-price">
                    <small>Group Name</small>
                    <h5 className={""}>{item?.ServiceName}</h5>
                  </div>
                </div>
              </div>
              <div className="bzc-col-1">
                <div>
                  <div className="bzc-provider-name">
                    <small>Provider</small>
                    <h5 className="mb-0">{item?.TrainerName}</h5>
                  </div>
                </div>
              </div>
              <div className="bzc-col-1">
                <div>
                  <div className="bzc-session-price">
                    <small>Duration(minutes)</small>
                    <h5 className={""}>{item?.Duration}</h5>
                  </div>
                </div>
              </div>
              <div className={"bzc-close-icon bz-past-statuses"}>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const requestIfNeeded = () => {
    const nextPage = searchAppointments.pageNumber + 1;
    const maxPageNum = searchAppointments.maxPageNumber;
    if (nextPage < maxPageNum) {
      return {
        status: true,
        page: nextPage,
      };
    } else {
      return {
        status: false,
        page: nextPage,
      };
    }
  };
  const scrollHandler = async (event: any) => {
    const e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      const check = await requestIfNeeded();
      if (check.status) {
        const moreData = await getUpcomingAppointments(check.page, selected);
        if (moreData.length > 0) {
          const test = searchAppointments.appointmentsData.concat(moreData);
          setSearchAppointments({
            ...searchAppointments,
            appointmentsData: test,
            pageNumber: check.page,
          });
        }
      }
    }
  };

  const renderAppointments = (index: number) => {
    const scrollPadding = searchAppointments.appointmentsData?.length > 2?"scroll-padding":"";
    if (loading) {
      return (
        <>
          <div
            className={
              "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
            }
          >
            <BuzopsLoader type="list" />
          </div>
        </>
      );
    }
    return searchAppointments.appointmentsData?.length === 0 ? (
      <div className="bz-norecords-available">
        {index === 0
          ? "No Upcoming Appointments Available"
          : "No Past Appointments Available"}
      </div>
    ) : (
      <ListView
        className={`bz-group-services-list bz-past-GroupServices ${scrollPadding}`}
        data={searchAppointments.appointmentsData || []}
        item={(props:any)=>GroupServicesRender(props,index)}
        onScroll={(e) => scrollHandler(e)}
        style={{
          width: "100%",
        }}
      />
    );
  };

  return (
    <>
      <TabStrip selected={selected} onSelect={handleSelect}>
        {tabs.map((val, indx) => {
          return (
            <TabStripTab
              title={val.title}
              key={indx}
              contentClassName={"bz-group-services-list"}
            >
              {handlePage(indx, searchAppointments)}
            </TabStripTab>
          );
        })}
      </TabStrip>
    </>
  );
};

export default PackagesSummary;
