import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
const PaymentDropDown = ({
  paymentProfiles,
  selectedValue,
  changePaymentProfile,
  merge,
}: any) => {
  console.log(paymentProfiles, selectedValue)
  const cashData = {
    CardDescription: "Cash",
    PaymentGatewayPaymentProfileID: -1,
  };
  return (
    // <DropDownList
    // textField="CardDescription"
    //     style={{
    //       width: "100%"
    //     }}
    //     disabled={merge?false:value?.CardTypeId ==-1?true:false}
    //     data={merge?paymentProfiles:value?.CardTypeId ==-1?[...paymentProfiles,cashData]:paymentProfiles}
    //     onChange={(e)=>{
    //       changePaymentProfile(e?.value,value)
    //    }}

    //     dataItemKey="PaymentGatewayPaymentProfileID"
    //     value={paymentProfiles?.find((itn:any)=>itn?.PaymentGatewayPaymentProfileID === value?.PaymentProfileId)?paymentProfiles?.find((itn:any)=>itn?.PaymentGatewayPaymentProfileID === value?.PaymentProfileId):cashData}
    //   />

    <DropDownList
      id={`PaymentGatewayPaymentProfileId`}
      style={{ minHeight: '38px', borderColor: '#ced4da' }}
      name={`PaymentGatewayPaymentProfileId`}
      data={merge ? paymentProfiles : selectedValue?.CardTypeId == -1 ? [...paymentProfiles, cashData] : paymentProfiles}
      textField="MaskedCCNumber"
      valueRender={(el: React.ReactElement<HTMLSpanElement>, value: any) =>
        PaymentValueRender(
          el,
          value,
          paymentProfiles?.find((itn: any) => itn?.PaymentGatewayPaymentProfileID === selectedValue?.PaymentProfileId) ? paymentProfiles?.find((itn: any) => itn?.PaymentGatewayPaymentProfileID === selectedValue?.PaymentProfileId) : cashData
        )
      }
      itemRender={PaymentItemRender}
      onChange={(e) => {
        changePaymentProfile(e.value, selectedValue);
      }}
    />
  );
};

export default PaymentDropDown;
