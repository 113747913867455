export const PermissionEnum = {
  None: 0,
  BusinessDashboard:1,

  //Member Management
  AddMemberships: 101,

  EditMemberships: 102,
  ViewMemberManagement: 103,

  //Plan Management
  ApproveCancelRequest: 202,

  ApproveFreezeRequest: 204,
  ManaageMemberCredit: 207,
  ManageRefund: 208,
  ManageWaivePayment: 209,
  ActivateAccountSuspension: 210,
  ModifyCancellationFeeForCancelRequest: 211,
  ModifyRefundAmountForCancelRequest: 212,
  ModifyAmountForFreezeRequest: 213,

  //Club Configuration
  PlanManagement: 301,

  AdditionalServicesManagemenet: 302,
  StaffManagement: 303,
  MemberFollowup: 304,
  ReassignFollowup: 305,
  SubscriptionManagement: 306,

  //Site Configurations
  AdministratorAccess: 351,

  //Service Management
  RaisePersonalTrainingCancelRequest: 401,

  ApprovePersonalTrainingCancelRequest: 402,
  RaisePersonalTrainingFreezeRequest: 403,
  ApprovePersonalTrainingFreezeRequest: 404,
  AdjustPersonalTrainingRecurrence: 405,
  SchedulePersonalTrainingSessions: 406,
  PurchasePersonalTrainingSessions: 407,
  SignOffPersonalTrainingExpiredSession: 408,
  ModifyPersonalTrainingCancellationFeeForCancelRequest: 409,
  ComplementartyPackage: 410,
  CustomPackage: 411,
  BodyComposition: 412,

  //Group Service Management
  RaiseGroupServiceCancelRequest: 501,

  ApproveGroupServiceCancelRequest: 502,
  RaiseGroupServiceFreezeRequest: 503,
  ApproveGroupServiceFreezeRequest: 504,
  AdjustGroupServiceRecurrence: 505,
  ScheduleGroupServiceSessions: 506,
  PurchaseGroupServiceSessions: 507,
  SignOffGroupServiceExpiredSession: 508,
  ModifyGroupServiceCancellationFeeForCancelRequest: 509,

  //Reports
  ManageDeclinePayments: 601,

  Revenue: 602,
  Sales: 603,
  OnDemand: 604,
  Transaction: 605,
  Plans: 606,
  AccountUpdaterReport: 607,
  RecurringRevenue: 608,
  OnsiteAccess: 609,
  SubscriptionStatistics: 610,
  AddOns: 611,
  ProvidePayouts: 612,

  //Email Functionality
  CreditCardAboutToExpire: 701,

  MembershipAboutToExpire: 702,
  AddonAboutToExpire: 703,
  RecurringService: 704,
  OverDueFollowup: 705,
  NoFollowup: 706,
  MembershipCancellation: 707,
  MembershipFreeze: 708,
  AddonCancellation: 709,
  AddonFreeze: 710,
  AccountUpdater: 711,

  //Coupons
  AddOrUpdateCoupon: 801,

  ApplyCoupon: 802,

  //Service Management
  ServiecRefunds: 891,

  ServiceWaive: 892,
  ServiceCancel: 893,
  ServicePause: 894,

  //OnSite Access
  ManageOnSiteAccess: 901,

  RemoveOnsiteAccess: 902,

  //General  Settings
  BasicSettings: 1001,

  //Service Configuration
  ManageService: 903,

  AccessAsProvider: 904,
  Trainer: 905,
  Schedule: 906,
  Availability: 907,

  //Provider Configuration
  Level1: 911,

  Level2: 912,
  QuickCheckout: 913,
  ComplementaryCheckout: 914,
  Level3: 915,

  //Go Live Site
  UnlimitedClient: 1100,

  CouponWithLimitCount: 1101,
  AccessControl: 1102,
  Reporting: 1103,
  Agreements: 1104,
  ECommerce: 1105,
  ClientLogin: 1106,
  Scheduler: 1107,

  GroupServiceLevel3: 1108,
  GroupServiceCancellation: 1109,

  VirtualMeetings: 1110,
  VirtualMeetingScreenShareAndChat: 1111,
  Chargeback_Return_Manage_User : 1112,


  //Added From UI for payment portal Configuration
  PaymentPortalConfiguration:5555,

  //Checkins Feature

  checkinFeatureId:1113,

  ComplimentaryCheckout:510

};