import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
const BuzopsButton = (props: any) => {
    let disable=props?.disable
    if(props.loading){
        disable=true
    }
    let primary=true
    if(props?.nonPrimary){
        primary=false
    }
        return (
            <Button primary={primary} style={props?.style}  disabled={disable} {...props} className={props.className}>
                {   
                    <span className='labelname'>{props.label}</span>
                }
                {
                    props.loading ? (
                        <i
                            className="btnloading fa fa-spinner fa-spin"
                        />
                    ):(<i
                        className="btnNotloading"
                    />)
                }
            </Button>
        )
}
export default BuzopsButton;