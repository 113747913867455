import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
} from "@progress/kendo-react-form";
import { useEffect, useState } from "react";
import { emailValidator, otpValidator } from "validators/validator";
import { Error } from "@progress/kendo-react-labels";
import { FormInput } from "utils/form-components";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import { ClassService } from "services/class/index.service";
import { TenantService } from "services/tenant/index.service";

const UpdateEmailDialog = (props: any) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(true);
  const classService = new ClassService();
  const [disabledContinue, setDisabledContinue] = useState(false);
  const [timer, setTimer] = useState<any>();
  const tenantService = new TenantService();
  const [seconds, setSeconds] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");


  const validateEmail = async (value: string) => {
    if (!value || value?.trim()==="") return;
    setLoading(true);
    const resp = await classService.validateEmail(value);
    if (resp.Item.UserId !== 0) {
      setErrorMessage("The email address provided is already taken. Please provide an alternate email address.")
      setDisabledContinue(true);
    }else{
      setErrorMessage("")
    }
    setLoading(false);
  };

  const onChangeEmail = (event: any) => {
    const { value } = event;
    const val=value?.trim()
    setEmail(val);
    setDisabledContinue(false);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      validateEmail(val);
    }, 500);
    setTimer(newTimer);
  };

  const handleEmailSubmit = async (values: any) => {
    setLoading(true);
    if (props.isAdminOrStaff) {
      await updateUser();
    } else {
      const response = await tenantService.sendOTP({
        EmailId: values.email,
      });
      if (response.ResponseCode === 100) {
        props.setMessage("OTP sent");
        props.setNotification({
          ...props.notification,
          success: true,
          error: false,
        });
        setShowEmailInput(false);
        setSeconds(300);
      } else {
        props.setMessage("Error occurred while performing the operation");
        props.setNotification({
          ...props.notification,
          success: false,
          error: true,
        });
      }
    }

    setLoading(false);
  };

  const resendOTP = async () => {
    setResendLoading(true);
    const response = await tenantService.sendOTP({
      EmailId: email,
    });

    if (response.ResponseCode === 100) {
      props.setMessage("OTP resent");
      props.setNotification({
        ...props.notification,
        success: true,
        error: false,
      });
      setSeconds(300);
    } else {
      props.setMessage("Error occurred while performing the operation");

      props.setNotification({
        ...props.notification,
        success: false,
        error: true,
      });
    }
    setResendLoading(false);
  };

  const updateUser = async () => {
    const res = await tenantService?.saveUserInfo({
      ...props.userDetails,
      Email: email,
    });
    if (res.ResponseCode !== 100) {
      props.setMessage("Unable to update email");
      props.setNotification({
        ...props.notification,
        success: false,
        error: true,
      });
    } else {
      props.setMessage("Email updated successfully");
      props.setNotification({
        ...props.notification,
        success: true,
        error: false,
      });
      props.setUserDetails({ ...props.userDetails, Email: email });
    }
    props.setShowUpdateEmailDialog(false);
  };

  const handleSubmitOTP = async (dataItem: any) => {
    setLoading(true);
    const response = await tenantService.validateOTP({
      EmailId: email,
      Code: dataItem.code,
    });

    if (response.ResponseCode === 100) {
      props.setMessage("OTP Successful");
      props.setNotification({
        ...props.notification,
        success: true,
        error: false,
      });
      await updateUser();
    } else {
      props.setMessage("Invalid OTP");
      props.setNotification({
        ...props.notification,
        success: false,
        error: true,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [seconds]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return (
    <>
      <Dialog
        className="bz-edit-email-dialog"
        title={showEmailInput ? "Enter your email address" : "Enter OTP"}
        onClose={(e: any) => {
          props?.setShowUpdateEmailDialog(false)
        }}
      >
        {showEmailInput && (
          <Form
            onSubmit={handleEmailSubmit}
            render={(formRenderProps) => (
              <FormElement>
                <div className="">
                  <div className="bzo-login-card bz-emailLogin-card border-0 bz-border-radius-1">
                    <div className="bzo-login-body">
                      <div className="form-group mb-4">
                        <Field
                          id={"email"}
                          name={"email"}
                          component={FormInput}
                          validator={(val:any) => emailValidator(val,"Email")}
                          autoFocus={true}
                          hidelabel={true}
                          onChange={onChangeEmail}
                          value={email}
                        />
                         {errorMessage && <Error>{errorMessage}</Error>}
                      </div>
                      <div className="d-flex justify-content-end">
                        <ButtonGroup>
                          <Button
                            type={"reset"}
                            onClick={() =>
                              props.setShowUpdateEmailDialog(false)
                            }
                          >
                            Cancel
                          </Button>

                          <BuzopsButton
                            type={"submit"}
                            label={"Next"}
                            loading={loading}
                            disable={loading || disabledContinue}
                            className="mr-0"
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </FormElement>
            )}
          />
        )}

        {!showEmailInput && (
          <Form
            onSubmit={handleSubmitOTP}
            render={(formRenderProps) => (
              <FormElement>
                <div className="">
                  <div className="bzo-login-card bz-emailLogin-card border-0 bz-border-radius-1">
                    <div className="bzo-login-body">
                      <p className="text-left mt-0 my-3">
                        An email has been sent to you with a one time passcode
                        (OTP). Please check your email and enter this code
                        within 5 minutes to verify your identity and complete
                        the process.
                      </p>
                      <p>
                        OTP Expires in: {minutes.toString().padStart(2, "0")}:
                        {remainingSeconds.toString().padStart(2, "0")}
                      </p>

                      <div className="form-group mb-4">
                        <Field
                          id={"code"}
                          name={"code"}
                          component={FormInput}
                          validator={otpValidator}
                          autoFocus={true}
                          hidelabel={true}
                        />
                      </div>
                      <div className="d-flex justify-content-end">
                        <ButtonGroup>
                          <Button
                            type={"reset"}
                            onClick={() =>
                              props.setShowUpdateEmailDialog(false)
                            }
                          >
                            Cancel
                          </Button>

                          <BuzopsButton
                            type={"submit"}
                            label={"Submit"}
                            loading={loading}
                            disable={loading}
                          />
                          <BuzopsButton
                            label={"resend"}
                            loading={resendLoading}
                            disable={resendLoading || seconds !== 0}
                            onClick={resendOTP}
                            className="mr-0"
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </FormElement>
            )}
          />
        )}
      </Dialog>
    </>
  );
};

export default UpdateEmailDialog;
