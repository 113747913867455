import React from "react";
import styled from "styled-components";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";

const Container = styled.div`
  display: inline-block;
  border: 1px solid grey;
  border-color: #ced4da;
  width: 100%;
  border-radius: 2px;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
`;

const FieldBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  overflow: hidden;
  border: 0;
`;
const CardImage = styled.img`
  height: 1em;
`;

const InputWrapper = styled.label`
align-items: center;
display: flex;
margin-left: 0.5em;
margin-top: 0.3em;
margin-bottom: 0.3em;
position: relative;
transition: transform 0.5s;
transform: translateX(0)});

&::after {
content: attr(data-max);
visibility: hidden;
height: 0;
}

& .credit-card-input {
border: 0px;
position: absolute;
width: 100%;
font-size: 1em;
&:focus {
  outline: 0px;
}
}

& .zip-input {
display: flex;
width: 80%;

}
`;

const formatString = (event: any) => {      
  var inputChar = String.fromCharCode(event.keyCode);
  var code = event.keyCode;
  var allowedKeys = [8];
  if (allowedKeys.indexOf(code) !== -1 || inputChar.length > 5) {
    return;
  }

  event.target.value = event.target.value.replace(
    /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
  ).replace(
    /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
  ).replace(
    /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
  ).replace(
    /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
  ).replace(
    /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
  ).replace(
    /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
  ).replace(
    /\/\//g, '/' // Prevent entering more than 1 `/`
  );

}

const PaymentCardInput = (props: any) => {
  const { MaskedCCNumber, ExpiryMonthYear, ZipCode } = props.cardFormValues;
  let edit=false
  if(props.type==='edit'){
    edit=true
  }
  return (
    <>
      <div className="payment-card-input-blk">
        <FieldWrapper>
          <Container
            className={"credit-card-input-form"}
            style={{ backgroundColor: "#f0f0f0",borderRadius: "0.25rem" }}
          >
            <FieldBoxWrapper id="field-wrapper" className={"fieldClassName"} style={{ borderRadius: "0.25rem" }}>
              <div className="bz-custom-card-input">
                <div className="icon-sec">
                  <CardImage
                    className={"cardImageClassName"}
                    src={props.cardImageIcon}
                  />
                </div>
                <div className="card-number-sec">
                  <InputWrapper data-max="9999 9999 9999 9999">
                    <input
                      id="card-number"
                      placeholder="Card Number"
                      maxLength="19"
                      type="tel"
                      onChange={(e) => props.handleCardNumberChange(e)}
                      onBlur={(e) => props.handleCardNumberBlur(e.target.value)}
                      onKeyPress={props.handleCardNumberKeyPress}
                      value={MaskedCCNumber}
                      autoComplete="cc-number"
                      className={"credit-card-input"}
                      disabled={edit}
                    />
                  </InputWrapper>
                </div>
                <div className="expiry-date-sec">
                  <InputWrapper data-max="MM / YY">
                    <input
                      id="card-expiry"
                      placeholder="MM/YY"
                      type="tel"
                      onChange={(e) => props.handleCardExpiryChange(e)}
                      onBlur={(e) => props.handleCardExpiryBlur(e.target.value)}
                      // onKeyPress={props.handleCardExpiryKeyPress}                      
                      onKeyPress={(e) => formatString(e)}
                      onKeyUp={(e) => formatString(e)}
                      value={ExpiryMonthYear}
                      autoComplete="cc-exp"
                      className={"credit-card-input"}
                      width={"70px"}
                      maxLength={5}
                    />
                  </InputWrapper>
                </div>
         
                <div className="zipcode-sec">
                  <InputWrapper data-max="99999">
                    <input
                      id="zip-code"
                      placeholder="Zip Code"
                      type="text"
                      maxLength={6}
                      // disabled={edit}
                      onChange={(e) => props.handlezipCodeChange(e)}
                      onBlur={(e) => props.handlezipCodeBlur(e.target.value)}
                      value={ZipCode}
                      className={"credit-card-input"}
                    />
                  </InputWrapper>
                </div>

               
              </div>
            </FieldBoxWrapper>
          </Container>
        </FieldWrapper>
        {!props.isValid && <Error>{props.error}</Error>}
      </div>
    </>
  );
};

export default PaymentCardInput;
