import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Loader } from "@progress/kendo-react-indicators";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import ClientNameWithMemberType from "./ClientNameWithMemberType";

const CheckIns = (props: any) => {
  const checkIns = new TenantService();
  const [loading, setloading] = useState(true);
  const showMoreRef = useRef<any>();
  const [dataList, setDataList] = useState<any>({
    pageNumber: 0,
    maxPageNumber: 0,
    checkInsData: [],
  });
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    fetchCheckIns(0);
  }, []);


  const fetchCheckIns = async (pageNumber: number) => {
    if (pageNumber === 0) {
      setloading(true);
    }
    const pageSize = 12;
    const req = {
      FilterFields: [],
      PageNumber: pageNumber,
      PageSize: pageSize,
      PrimaryUserMemberId: props?.userData?.MainUserId,
      FromDate: "1990-01-01T05:30:00",
      ToDate: moment().format(),
      SortBy: "Ocurred",
      SortDirection: "desc",
    };
    const result = await checkIns.getCheckIns(req);
    if (pageNumber === 0) {
      setloading(false);
    }
    if (result?.ResponseCode === 100) {
      const maxPageNumber = result.Item?.TotalItems / pageSize;
      if (pageNumber === 0) {
        setDataList({
          ...dataList,
          pageNumber: pageNumber,
          maxPageNumber: maxPageNumber,
          checkInsData: result?.Item?.Result || [],
        });
      }
      return result?.Item?.Result || [];
    }
    return [];
  };

  const handlePageChange = async (status = "more") => {
    if (status === "more") {
      const check = await requestIfNeeded();
      if (check.status) {
        setPageLoading(true);
        const moreData = await fetchCheckIns(check.page);
        console.log(moreData)
        const test = dataList?.checkInsData?.concat(moreData);
        setPageLoading(false);
        if (moreData.length > 0) {
          setDataList({
            ...dataList,
            checkInsData: test,
            pageNumber: check.page,
          });
          if (showMoreRef.current) {
            showMoreRef.current?.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    }
  };

  const requestIfNeeded = () => {
    const nextPage = dataList.pageNumber + 1;
    const maxPageNum = dataList.maxPageNumber;

    if (nextPage <= maxPageNum) {
      return {
        status: true,
        page: nextPage,
      };
    } else {
      return {
        status: false,
        page: nextPage,
      };
    }
  };

  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  return (
    <>
      <div>
        <h5 className="mb-3 bz_fs_1_5">Check-Ins</h5>
        {loading ? (
          <BuzopsLoader type={"list"} />
        ) : (
          <>
            <ExcelExport data={dataList?.checkInsData || []} ref={_export}>
              <Grid
                data={dataList?.checkInsData || []}
                className="bzc-grid bz-checkins-grid"
              >
                <GridToolbar>
                  <button
                    title="Export Excel"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    onClick={excelExport}
                  >
                    Download
                  </button>
                </GridToolbar>
                <Column field="FullName" title="Name" cell={(props) => {
                  return <td><ClientNameWithMemberType memberType={props?.dataItem?.MemberType} clientName={props?.dataItem?.FullName} /></td>
                }} />
                <Column field="ClubName" title="Home Club" />
                <Column field="SiteName" title="Site" />
                <Column field="AccessPoint" title="Access Point" />
                <Column field="Occured" title="Occurred" />
              </Grid>
            </ExcelExport>
            <div className="d-flex justify-content-center align-items-center bz-services-showMore">
              <div ref={showMoreRef}>
                {pageLoading ? (
                  <>
                    <Loader type="converging-spinner" />
                  </>
                ) : (
                  <>
                    {dataList?.pageNumber + 1 <
                      dataList?.maxPageNumber && (
                        <span
                          className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                          onClick={() => handlePageChange()}
                        >
                          View More <i className="fas fa-caret-down"></i>
                        </span>
                      )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CheckIns;
