export const MRRColumns = [
  {
    title: "Month & Year",
    field: "RecurrenceDateFormat",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Scheduled Payments",
    field: "ExepctedAmountToCollectDescription",
    show: true,
    clickable: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Collected Payments",
    field: "ActualAmountCollectedDescription",
    show: true,
    clickable: true,
    filter: "text",
    width: "250px",
  },
  {
    title: "Failed Payments",
    field: "FailedPaymentDescription",
    show: true,
    clickable: true,
    filter: "numeric",
    width: "150px",
  },

  {
    title: "Not Processed Payments",
    field: "NotProcessedDescription",
    show: true,
    clickable: true,
    filter: "text",
    width: "150px",
  },
];

export const DetailedMRR1Columns = [
  {
    title: "Recurring Type",
    field: "RecurrenceDescription",
    show: true,
    filter: "text",
    //   width: "200px",
  },
  {
    title: "Scheduled Payments",
    field: "ExepctedAmountToCollectDescription",
    show: true,
    filter: "text",
    clickable: true,
    //   width: "150px",
  },
  {
    title: "Collected Payments",
    field: "ActualAmountCollectedDescription",
    show: true,
    filter: "text",
    clickable: true,

    //   width: "250px",
  },
  {
    title: "Failed Payments",
    field: "FailedPaymentDescription",
    show: true,
    filter: "numeric",
    clickable: true,

    //   width: "150px",
  },

  {
    title: "Not Processed Payments",
    field: "NotProcessedDescription",
    show: true,
    clickable: true,
    filter: "text",
    //   width: "150px",
  },
];

export const DetailedMRR2Columns = [
  {
    title: "Client Name",
    clickable: true,
    field: "MemberName",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Email",
    field: "Email",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Recurring Type",
    field: "RecurrenceDescription",
    show: true,
    filter: "text",
    width: "250px",
  },
  {
    title: "Service Name",
    field: "ServiceName",
    show: true,
    filter: "numeric",
    width: "150px",
  },

  {
    title: "Status",
    field: "Status",
    show: true,
    clickable: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Next Recurrence",
    field: "NextRecurrenceFormat",
    show: true,
    clickable: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Recurring Amount",
    field: "RecurringAmount",
    show: true,
    clickable: true,
    filter: "text",
    width: "150px",
  },
];

export const MRRDataColumns = [
  {
    title: "Client Name",
    field: "MemberName",
    show: true,
    clickable: true,
    filter: "text",
    //   width: "200px",
  },
  {
    title: "Individual Services Name",
    field: "ServiceName",
    show: true,
    filter: "text",
    //   width: "200px",
  },
  {
    title: "Recurring Amount",
    field: "RecurringAmount",
    show: true,
    filter: "numeric",
    //   width: "200px",
  },
  {
    title: "Next Recurrence",
    field: "NextRecurrence",
    show: true,
    filter: "text",
    //   width: "200px",
  },
];

export const TaxReportColumns = [
  {
    title: "Transaction Id",
    field: "TransactionId",
    show: true,
    width: "130px",
    filter: "text",
    type: "text",
  },
  {
    title: "Client Name",
    field: "MemberName",
    show: true,
    width: "180px",
    filter: "text",
    type: "text",
  },
  {
    title: "Email",
    field: "Email",
    show: true,
    filter: "text",
    width: "200px",
    type: "text",
  },
  {
    title: "Transaction Date",
    field: "TransactionDate",
    show: true,
    filter: "text",
    width: "140px",
    type: "date",
  },
  {
    title: "Payment Type",
    field: "PaymentExecutionMode",
    show: true,
    filter: "text",
    width: "200px",
    type: "text",
    // format: "{0:d}",
  },
  {
    title: "Payment By",
    field: "CardDescription",
    show: true,
    filter: "text",
    width: "180px",
    type: "text",
  },
  {
    title: "Subscription Type",
    field: "PaymentMadeFor",
    show: true,
    filter: "text",
    width: "150px",
    type: "text",
  },
  {
    title: "Payment Mode",
    field: "PaymentMode",
    show: true,
    filter: "text",
    width: "150px",
    type: "text",
  },
  {
    title: "Payment Status",
    field: "PaymentTransactionStatus",
    show: true,
    filter: "numeric",
    width: "200px",
    type: "text",
  },
  {
    title: "Surcharges",
    field: "Taxes",
    show: true,
    filter: "numeric",
    width: "100px",
    type: "amount",
  },
  {
    title: "Surcharges Details",
    field: "Notes",
    show: true,
    filter: "text",
    width: "200px",
    type: "text",
  },
  {
    title: "Internal Processing Status",
    field: "InternalProcessingStatusDescription",
    show: true,
    filter: "text",
    width: "200px",
    type: "text",
  },
];
