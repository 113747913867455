import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { Hint } from "@progress/kendo-react-labels";
import { CheckUserMemberStatus, getTimeFormat } from "utils";
import moment from "moment";
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import Html5QrcodePlugin from "./Html5QrcodePlugin";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import { BarCodeType } from "components/integrations/Components/SimpleConfiguration";
import withNotification from "components/Hoc/withNotification";
import { Dialog } from "@progress/kendo-react-dialogs";
import SimpleAccessCheckIns from "components/summary/SimpleAccessCheckIns";
import { Barcode, QRCode } from "@progress/kendo-react-barcodes";
import { Loader } from "@progress/kendo-react-indicators";
import { GetLocalStore } from "utils/storage";
import bzImages from "../../../Images";
import { SecuritySystemType } from "utils/constants";
import { Button } from "@progress/kendo-react-buttons";

function getBarcodeValue(value: number) {
  return Object.keys(BarCodeType).find((key) => BarCodeType[key] === value);
}
const SimpleCheckinAccess = (props: any) => {
  const isAdminOrStaff = [1, 8].includes(
    GetLocalStore("CurrentApplicationType")
  );
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [listItems, setListItems] = useState<any>([]);
  const [cameraScan, setCameraScan] = useState<any>({
    show: false,
    selectedData: null,
  });
  const barcodeStyles = {
    width: "100%",
    "min-width": "100px",
  };
  useEffect(() => {
    fetchAllApis();
  }, []);

  const simpleAccessService = new SimpleAccessService();
  const barcodeRef = React.createRef<Barcode>();
  const qrcodeRef = React.createRef<QRCode>();
  const tenantUserSession = GetLocalStore("tenantUserSession");
  const ClubId = tenantUserSession?.ClubId;

  const fetchAllApis = async () => {
    setLoading(true);
    // add network calls
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      PrimaryUserMemberId: props?.userData?.MainUserId,
      IncludeSubMember: true,
    };
    const response = await simpleAccessService.getMembersWithAccess(req);
    setListItems(
      response.map((item: any) => {
        return { ...item, selectedArrow: false, history: [] };
      })
    );
    setLoading(false);
  };

  const handleSelectedArrow = async (dataItem: any) => {
    if (dataItem?.selectedArrow) {
      const records = listItems?.map((i: any) => {
        if (i?.UserMemberId === dataItem?.UserMemberId) {
          return { ...i, selectedArrow: false, history: [] };
        } else {
          return { ...i };
        }
      });
      setListItems(records);
      return true;
    }
    const req = {
      UserMemberId: dataItem?.UserMemberId,
      PageSize: 20,
      PageNumber: 0,
    };
    setProcessing(true);
    const auditResponse = await simpleAccessService.getSimpleAccessAuditInfo(
      req
    );
    setProcessing(false);

    const records = listItems?.map((i: any) => {
      if (i?.UserMemberId === dataItem?.UserMemberId) {
        return { ...i, selectedArrow: true, history: auditResponse };
      } else {
        return { ...i };
      }
    });
    setListItems(records);
  };
  const HistoryItemRender = (props: any) => {
    let item = props.dataItem;
    return (
      <div
        style={{ backgroundColor: "#fff" }}
        className="py-2 bz-package-audit-history-list"
      >
        {" "}
        <span className="text-muted d-block mb-0 audit-flex">
          <span>
            <i className="far fa-calendar-alt"></i>
          </span>
          <div>
            <span className="bz-activity-time-date text-capitalize">
              {" "}
              {moment(item?.AuditDate).format("L")}{" "}
              {getTimeFormat(item?.AuditDate)}{" "}
            </span>
            <span className="bz-activity-dis-div">{item?.ActionDetail} </span>
            <label className="bz-requestedBy font-italic bz-fw-600">
              {item?.RequestedBy}
            </label>{" "}
          </div>
        </span>
      </div>
    );
  };

  const saveBarcodeInfo = async (req: any, remove = false) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    setProcessing(true);
    const saveResponse = await simpleAccessService.saveSimpleAccessCard(req);
    setProcessing(false);
    if (saveResponse.success) {
      // update list items object
      setCameraScan({
        show: false,
        selectedData: {},
      });
      if (remove) {
        props?.handleNotificationMessage(
          "Access card has been removed.",
          "success"
        );
      } else {
        props?.handleNotificationMessage(
          "Access card has been added.",
          "success"
        );
      }
      fetchAllApis();
    } else {
      props?.handleNotificationMessage(
        saveResponse?.msg || "Unable to assign/remove Access card to user",
        "error"
      );
    }
  };
  const onCameraScan = (dataItem: any) => {
    setCameraScan({
      show: true,
      selectedData: dataItem,
    });
  };

  const generateBarcode = (dataItem: any) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    const userId = dataItem?.UserMemberId;
    const barcodeVal = `${String(userId).padStart(8, "0")}-${Math.random()
      .toString(36)
      .slice(2, 5)}`;

    const req = {
      CardNumber: barcodeVal.toLocaleUpperCase(),
      IsSuspended: false,
      EntityId: dataItem?.UserMemberId,
      UserId: dataItem?.UserId,
      AccessCardType: 2,
      BarcodeType:
        props?.securityConfig?.SimpleSecuritySystemPreferences
          ?.PreferredBarcodeType || 8,
      MemberNumber: dataItem?.MemberNumber,
    };
    setUserPayload(req);
  };

  const generateQuickID = async (dataItem: any) => {
    const response = await simpleAccessService.getSimpleAccessQuickID();
    const req = {
      CardNumber: response?.data.split("-")[1],
      IsSuspended: false,
      EntityId: dataItem?.UserMemberId,
      UserId: dataItem?.UserId,
      AccessCardType: 2,
      BarcodeType:
        props?.securityConfig?.SimpleSecuritySystemPreferences
          ?.PreferredBarcodeType || 8,
      MemberNumber: dataItem?.MemberNumber,
    };
    setUserPayload(req);
  };

  const setUserPayload = (payload: any) => {
    setListItems((prev: any) => {
      return prev.map((val: any) => {
        if (val.MemberNumber === payload?.MemberNumber) {
          return {
            ...val,
            payload: payload,
          };
        } else {
          return val;
        }
      });
    });
  };

  const suspendOrActivateUser = async (dataItem: any) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if (
      dataItem.CanDisableEditAndActivate ||
      !dataItem?.AccessCard?.CardNumber
    ) {
      return true;
    }
    const req = {
      UserId: dataItem?.UserId,
      IsSuspended: !dataItem?.AccessCard?.IsSuspended,
      EntityId: dataItem?.UserMemberId,
    };
    setProcessing(true);
    const res = await simpleAccessService.updateSimpleAccessCard(req);
    setProcessing(false);
    if (res.success) {
      // update list items object
      setCameraScan({
        show: false,
        selectedData: {},
      });
      const message = `Access card has been ${
        dataItem?.AccessCard?.IsSuspended ? "activated" : "suspended"
      }.`;
      props?.handleNotificationMessage(message, "success");
      fetchAllApis();
    } else {
      props?.handleNotificationMessage(
        res?.msg || "Unable to Suspend/Activate User",
        "error"
      );
      // throw notification error
    }
  };

  const removeUser = (dataItem: any) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if (dataItem.CanDisableRemoveAccess || !dataItem?.AccessCard?.CardNumber) {
      return true;
    }

    const req = {
      CardNumber: "",
      IsSuspended: false,
      EntityId: dataItem?.UserMemberId,
      UserId: dataItem?.UserId,
      AccessCardType: 2,
      BarcodeType: dataItem?.AccessCard?.BarcodeType,
    };

    saveBarcodeInfo(req, true);
  };

  const onSave = (dataItem: any) => {
    if (props.securityConfig.ManualAplhanumCodeCheckinsEnabled === true) {
      if (dataItem.payload.CardNumber.length < 4) {
        props?.handleNotificationMessage("Invalid code", "error");
        return;
      }
      dataItem.payload.CardNumber = `${ClubId}-${dataItem.payload.CardNumber}`;
    }
    if (dataItem?.payload) {
      saveBarcodeInfo(dataItem?.payload);
    } else {
      props?.handleNotificationMessage(
        "Please scan/generate Access card",
        "error"
      );
    }
  };

  const sendQrcodeEmail = (dataItem: any) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if (!qrcodeRef.current) {
      return;
    }
    qrcodeRef.current.exportImage().then(async (dataURI) => {
      const req = {
        UserId: dataItem?.UserId,
        AccessCardImageSrcData: dataURI,
      };
      const res = await simpleAccessService.sendEmail(req);

      if (res.success) {
        props.handleNotificationMessage("Email sent!", "success");
      }
    });
  };
  const sendEmail = (dataItem: any) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if (!barcodeRef.current) {
      return;
    }
    barcodeRef.current.exportImage().then(async (dataURI) => {
      const req = {
        UserId: dataItem?.UserId,
        AccessCardImageSrcData: dataURI,
      };
      setProcessing(true);

      const res = await simpleAccessService.sendEmail(req);
      setProcessing(false);

      if (res.success) {
        props.handleNotificationMessage("Email sent!", "success");
      }
    });
  };

  const getCardValue = (dataItem: any) => {
    const value: string =
      dataItem?.payload?.CardNumber === undefined
        ? dataItem?.AccessCard?.CardNumber
        : dataItem?.payload?.CardNumber;
    if (
      value?.startsWith(`${ClubId}-`) &&
      props.securityConfig.ManualAplhanumCodeCheckinsEnabled === true
    ) {
      return value.split("-")[1];
    } else {
      return value;
    }
  };

  const onChangeQuickID = (dataItem: any, quickID: string) => {
    if (
      props.securityConfig.ManualAplhanumCodeCheckinsEnabled === false ||
      dataItem?.AccessCard?.CardNumber?.length > 0
    ) {
      return;
    } else {
      if (quickID.length > 6) {
        return;
      }
      const req = {
        CardNumber: quickID.replace(/[^a-z0-9]/gi, "").toUpperCase(),
        IsSuspended: false,
        EntityId: dataItem?.UserMemberId,
        UserId: dataItem?.UserId,
        AccessCardType: 2,
        BarcodeType:
          props?.securityConfig?.SimpleSecuritySystemPreferences
            ?.PreferredBarcodeType || 8,
        MemberNumber: dataItem?.MemberNumber,
      };
      setUserPayload(req);
    }
  };

  const isValidCode = (dataItem: any) => {
    const value: string = dataItem?.AccessCard?.CardNumber;
    if (!value) {
      return true;
    }
    if (props.securityConfig.ManualAplhanumCodeCheckinsEnabled === true) {
      if (value?.startsWith(`${ClubId}-`)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const getAccessCardAssignmentText = (dataItem: any) => {
    if (
      dataItem?.AccessCard?.CardNumber !== null &&
      dataItem?.AccessCard?.IsSuspended === true
    ) {
      return "Suspended";
    } else if (
      dataItem?.AccessCard?.CardNumber !== null &&
      dataItem?.AccessCard?.IsSuspended === false
    ) {
      return "Assigned";
    } else {
      return "Not Assigned";
    }
  };

  const RenderItem = (dataItem: any) => {
    return (
      <>
        <div className={`bz-inhouse-group-events align-middle buz-online-list-view bz-groupServices-list-new group-serv-list bz-openPath-credintials bz-checkins-barcode ${!dataItem?.Email && 'cursor-not-allowed'}`} title={!dataItem.Email ? "User must have a valid email to assign access card" : ""}>
          <div className=" d-flex justify-content-between mobile-onsite-checkins">
            <div className="section-1" style={{ width: "20%" }}>
              <div className="bz-provider-name">
                <span className="bz-provider text-muted">Name</span>
                <h6>
                  <ClientNameWithMemberType
                    memberType={dataItem?.MemberType}
                    clientName={dataItem?.FullName}
                  />
                </h6>
              </div>
            </div>

            {(isAdminOrStaff || getCardValue(dataItem)) && (
              <div className="section-3">
                <div className="bz-provider-name">
                  <Hint>
                    <span
                      className={`btn btn-link ${
                        getCardValue(dataItem) ? "disabled pe-none" : ""
                      }`}
                      onClick={() =>
                        props.securityConfig?.ManualAplhanumCodeCheckinsEnabled
                          ? generateQuickID(dataItem)
                          : generateBarcode(dataItem)
                      }
                    >
                      <span className="bz-provider">
                        {getCardValue(dataItem)
                          ? "Card Number"
                          : "Generate Card"}
                      </span>
                      {!isValidCode(dataItem) && (
                        <span className="k-notification-error d-block mb-1 px-1 ft-12">
                          Invalid Code
                        </span>
                      )}
                    </span>
                  </Hint>
                  <div className="input-group">
                    {props.securityConfig
                      ?.ManualAplhanumCodeCheckinsEnabled && (
                      <div className="input-group-prepend">
                        <span className="input-group-text"> {ClubId}-</span>
                      </div>
                    )}

                    {props.securityConfig?.ManualAplhanumCodeCheckinsEnabled ? (
                      <input
                        type="text"
                        className="form-control k-textbox"
                        value={getCardValue(dataItem)}
                        onChange={(e: any) =>
                          onChangeQuickID(dataItem, e.target.value)
                        }
                      />
                    ) : (
                      <>
                        <input
                          type="text"
                          className="form-control k-textbox"
                          value={
                            dataItem?.payload?.CardNumber ||
                            dataItem?.AccessCard?.CardNumber ||
                            ""
                          }
                        />
                      </>
                    )}
                    {isAdminOrStaff && (
                      <div
                        className={`input-group-append  ${
                          dataItem?.AccessCard?.CardNumber
                            ? "disabled pe-none"
                            : ""
                        }`}
                        onClick={() => onCameraScan(dataItem)}
                      >
                        <span className="input-group-text">
                          <span className="fa fa-camera fa-lg bz-cursor-pointer bz-fs-1rem"></span>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {dataItem?.AccessCard?.CardNumber && (
                  <div className="barcode-container bz-barcode-container mt-3 px-2 px-md-0">
                    <div className="bz-barcode-img">
                      {dataItem?.AccessCard?.BarcodeType !== 16 && (
                        <Barcode
                          style={barcodeStyles}
                          height={70}
                          type={
                            getBarcodeValue(dataItem?.AccessCard?.BarcodeType)
                              ?.replace(/_/g, "")
                              ?.toLocaleUpperCase() || ""
                          }
                          value={dataItem?.AccessCard?.CardNumber}
                          ref={barcodeRef}
                        />
                      )}
                      {dataItem?.AccessCard?.BarcodeType === 16 && (
                        <QRCode
                          value={dataItem?.AccessCard?.CardNumber}
                          ref={qrcodeRef}
                          size={70}
                          className="qr-buzops"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="section-2">
              <div className="bz-provider-name">
                <span className="bz-provider text-muted d-block">
                  Access card Assignment
                </span>
                <span
                  className={`bz-access-credintials-status ${getAccessCardAssignmentText(
                    dataItem
                  )
                    ?.replace(/\s/g, "")
                    ?.toLowerCase()}`}
                >
                  <img
                    src={
                      getAccessCardAssignmentText(dataItem) === "Assigned"
                        ? bzImages.activeIcon
                        : getAccessCardAssignmentText(dataItem) === "Suspended"
                        ? bzImages.suspendedIcon
                        : bzImages.noAccessIcon
                    }
                    alt="status"
                  />
                  {getAccessCardAssignmentText(dataItem)}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr style={{ marginBottom: "0.75rem" }} />
            </div>

            <div
              className="col-12 bz-openPath-actions"
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              {
              !dataItem?.Email &&
              <div className="mr-auto text-danger mb-3 mb-sm-0">
                User must have a valid email to assign access card.
              </div>
            }
              {isAdminOrStaff && (
                <div className="bz-checkins-actions-container justify-content-start justify-content-md-center">
                  {!dataItem?.AccessCard?.CardNumber && (
                    <span
                      className={`k-button k-button-solid-primary k-button-sm ${
                        dataItem?.payload?.CardNumber ? "" : "disabled pe-none"
                      }`}
                      onClick={(e) => onSave(dataItem)}
                    >
                      Save
                    </span>
                  )}
                  {dataItem?.AccessCard?.CardNumber && (
                    <Button
                      primary={true}
                      iconClass="fa fa-envelope"
                      className="k-button-sm"
                      onClick={() =>
                        dataItem?.AccessCard?.BarcodeType === 16
                          ? sendQrcodeEmail(dataItem)
                          : sendEmail(dataItem)
                      }
                    >
                      Resend
                    </Button>
                  )}

                  {dataItem.AccessCard?.IsSuspended && (
                    <BuzopsTooltip
                      html={
                        <span
                          style={{
                            margin: "0rem 0.5rem",
                            color: "skyblue",
                            cursor: dataItem?.AccessCard?.CardNumber
                              ? "pointer"
                              : "not-allowed",
                          }}
                          title="Activate access"
                          className={`bz-cursor-pointer ${
                            dataItem?.AccessCard?.CardNumber ? "" : "disabled"
                          }`}
                          onClick={() => suspendOrActivateUser(dataItem)}
                        >
                          <i
                            style={{ fontSize: "16px" }}
                            className="fa fa-check"
                          ></i>
                        </span>
                      }
                    />
                  )}
                  {!dataItem.AccessCard?.IsSuspended && (
                    <BuzopsTooltip
                      html={
                        <span
                          style={{
                            margin: "0rem 0.5rem",
                            color: "green",
                            cursor:
                              dataItem.CanDisableEditAndActivate ||
                              !dataItem?.AccessCard?.CardNumber
                                ? "not-allowed"
                                : "pointer",
                          }}
                          title="Access will be Suspend temporarily"
                          className={`bz-cursor-pointer ${
                            dataItem?.AccessCard?.CardNumber ? "" : "disabled"
                          }`}
                          onClick={() => suspendOrActivateUser(dataItem)}
                        >
                          <i
                            style={{ fontSize: "16px" }}
                            className="fa fa-stop-circle"
                          ></i>
                        </span>
                      }
                    />
                  )}
                  <BuzopsTooltip
                    html={
                      <span
                        style={{
                          margin: "0rem 0.5rem",
                          color: "crimson",
                          cursor:
                            dataItem.CanDisableRemoveAccess ||
                            !dataItem?.AccessCard?.CardNumber
                              ? "not-allowed"
                              : "pointer",
                        }}
                        title="Access will be removed permanently"
                        className={`bz-cursor-pointer ${
                          dataItem?.AccessCard?.CardNumber ? "" : "disabled"
                        }`}
                        onClick={() => removeUser(dataItem)}
                      >
                        <i
                          style={{ fontSize: "16px" }}
                          className="fa fa-trash"
                        ></i>
                      </span>
                    }
                  />
                </div>
              )}

              <div className="ml-3 mr-3 section-3 d-flex align-items-center">
                <span
                  className="bz-cursor-pointer p-0 d-flex align-items-center"
                  onClick={() => handleSelectedArrow(dataItem)}
                >
                  Audit &nbsp;
                  <i
                    className={
                      dataItem?.selectedArrow
                        ? "fas fa-angle-up"
                        : "fas fa-angle-down"
                    }
                  ></i>
                </span>
              </div>
            </div>
          </div>

          {dataItem?.selectedArrow && (
            <>
              <div className="row mt-3">
                <div className="col-12">
                  {dataItem.history.length > 0 ? (
                    <ListView
                      item={HistoryItemRender}
                      data={dataItem.history || []}
                    />
                  ) : (
                    <div>
                      <span>No Audit History</span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  function isAlphanumeric(value: string) {
    return /^[a-zA-Z0-9]+$/.test(value);
  }

  const onScanSuccess = (decodedText: any, decodedResult: any) => {
    const barcodeType = BarCodeType[decodedResult?.result?.format?.formatName];

    if (!barcodeType) {
      // barcode format is not supported
      props?.handleNotificationMessage(
        "Barcode format is not supported",
        "error"
      );
      return true;
    }
    let cardNumber = decodedText;

    if (props.securityConfig.ManualAplhanumCodeCheckinsEnabled === true) {
      if (
        cardNumber.startsWith(`${ClubId}-`) &&
        cardNumber.split(`${ClubId}-`)?.[1]?.length <= 6 &&
        cardNumber.split(`${ClubId}-`)?.[1]?.length >= 4 &&
        isAlphanumeric(cardNumber.split(`${ClubId}-`)?.[1])
      ) {
        cardNumber = cardNumber.split(`${ClubId}-`)?.[1];
      } else if (
        cardNumber.length <= 6 &&
        cardNumber.length >= 4 &&
        isAlphanumeric(cardNumber)
      ) {
      } else {
        props?.handleNotificationMessage("Invalid Quick ID", "error");
        return true;
      }
    }
    const req = {
      CardNumber: cardNumber,
      IsSuspended: false,
      EntityId: cameraScan?.selectedData?.UserMemberId,
      UserId: cameraScan?.selectedData?.UserId,
      AccessCardType: 2,
      BarcodeType: barcodeType,
      MemberNumber: cameraScan?.selectedData?.MemberNumber,
    };
    setUserPayload(req);
    setCameraScan((prev: any) => {
      return {
        ...prev,
        show: false,
        selectedData: { ...prev.selectedData, scanResult: decodedResult },
      };
    });
  };
  const onScanFail = (error: any) => {
    console.log(error);
  };
  const toggleDialog = () => {
    setCameraScan((prev: any) => {
      return { ...prev, show: false };
    });
  };
  const renderScanWindow = () => {
    return (
      <Dialog title={"Scanning..."} onClose={() => toggleDialog()}>
        <Html5QrcodePlugin
          SuccessCallback={onScanSuccess}
          FailureCallback={onScanFail}
        />
      </Dialog>
    );
  };

  return (
    <>
      {loading ? (
        <>
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <div className="">
          <Card
            className=""
            style={{ width: "100%", overflow:'visible', opacity: processing ? 0.5 : 1 }}
          >
            <CardHeader className="p-0">
              <CardTitle className="bz-card-title">{"OnSite Access"}</CardTitle>
            </CardHeader>
            <CardBody className="p-0">
              <>
                {processing && (
                  <Loader
                    type="infinite-spinner"
                    className="laoder-full-screen"
                  />
                )}
                <div className="bz-class-list">
                  {listItems.length === 0 ? (
                    <>{"No Data Available"}</>
                  ) : (
                    <>
                      {listItems?.map((item: any) => RenderItem(item))}

                      {/* <ListView
                        item={(props) => RenderItem(props, quickID, setQuickID)}
                        data={listItems || []}
                        style={{
                          width: "100%",
                        }}
                      /> */}
                    </>
                  )}
                </div>
              </>
            </CardBody>
            <CardBody className="p-0">
              <div className="mb-3 pt-3">
                {/* <h5>Check Ins</h5> */}
                <SimpleAccessCheckIns
                  {...props}
                  userData={props?.userData}
                  members={listItems}
                  securitySystemType={SecuritySystemType.ScannerSystem}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      {cameraScan.show && renderScanWindow()}
    </>
  );
};

export default withNotification(SimpleCheckinAccess);
