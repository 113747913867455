import React from "react";
import { useParams } from "react-router-dom";
import { Barcode, QRCode } from "@progress/kendo-react-barcodes";
import { saveAs } from "@progress/kendo-file-saver";
import { Button } from "@progress/kendo-react-buttons";

const GenerateImage = () => {
  const { imageId } = useParams<any>();
  const dataObject = JSON.parse(atob(imageId));
  const barcode = React.createRef<Barcode>();
  const qrcode = React.createRef<QRCode>();

  const exportBarcode = () => {
    if (!barcode.current) {
      return;
    }
    barcode.current.exportImage().then((dataURI: any) => {
      saveAs(dataURI, "barcode.png");
    });
  };

  const exportQRCode = () => {
    if (!qrcode.current) {
      return;
    }
    qrcode.current.exportImage().then((dataURI: any) => {
      saveAs(dataURI, "qrcode.png");
    });
  };
  return (
    <div className="generate-image-card">
      {dataObject.type.replace(/_/g, "")?.toLocaleUpperCase() === "QRCODE" ? (
        <div className="code-container">
          <QRCode
            ref={qrcode}
            value={dataObject.data}
          />
          <Button primary onClick={exportQRCode}>Download</Button>
        </div>
      ) : (
        <div className="code-container"> 
          <Barcode
            ref={barcode}
            type={dataObject.type.replace(/_/g, "")?.toLocaleUpperCase() || ""}
            value={dataObject.data}
          />
          <Button primary onClick={exportBarcode}>Download</Button>
        </div>
      )}
    </div>
  );
};

export default GenerateImage;
