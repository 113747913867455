import PayNowSession from 'components/session/PayNowSession';
import BuzopsWindow from 'generic-components/BuzopsWindow';
import React, { useState } from 'react';
import { ClassService } from 'services/class/index.service';
import PayRemainingDue from './PayRemainingDue';
import { TenantService } from 'services/tenant/index.service';
import PayNowDeclinedPayments from './PayNowDeclinedPayments';


const RenderPayRemainingdue = (props: any) => {
    const { onWindowClose, transactionState, userDetails} = props;
    const [session, setSession] = useState<any>({});
    const [paymentStatus, setPaymentStatus] = useState<boolean>(false);
    const [declinedPayments, setDeclinedPayments] = useState<any>([]);

    React.useEffect(() => {
      fetchClassession();
    }, [transactionState?.data])
    const fetchClassession = async () => {
      if(transactionState.data.EntityId && transactionState.data.EntityType === 2){
        const req = {
          ClassSessionId: transactionState.data.EntityId,
          IncludeInActive: true,
        };
        const service = new ClassService();
        const result = await service.filterClassSchedule(req);
        setSession(result?.Items[0]);
      }
    };
    const onSuccessfulPayment = () => {
      setPaymentStatus(true);
    }
    const renderPayForm = () => {
      if(transactionState.data.EntityType === 3 && transactionState.data.HasPackage){
        return <PayRemainingDue
          staffPage={props?.staffPage}
          serviceInstance={{ ...transactionState.data }}
          userDetails={props?.userDetails}
          onSuccessfullPayment={() => onSuccessfulPayment()}
        />
      }else if(transactionState.data.EntityType === 3 && transactionState.data.HasPackage === false){
        return <PayNowDeclinedPayments
                staffPage={props?.staffPage}
                transactionData = {{ ...transactionState.data }}
                userDetails={props?.userDetails}
                onSuccessfullPayment={() => onSuccessfulPayment()}
                handleWindow={() => onWindowClose()}
                />
      }
      else {
        const value = transactionState.data;
        const obj = {
          ClassSessionId: session?.ClassSessionId,
          ClassId: session?.ClassId,
          ScheduleId: session.ScheduleId,
          UserId: userDetails?.UserId,
          UserMemberId: userDetails?.UserMemberId,
          DownPayment: session.Cost,
          Name: session.ClassName,
          ProfileImage: value?.photo,
          CanCollectTaxes:value?.CancollectTaxes
        };
          return <PayNowSession
            isInhouse={false}
            fromDashboard={false}
            isClient={!props?.staffPage}
            payNowItem={obj}
            classSession={session}
            handleWindow={() => onWindowClose()}
            onSuccessfullPayment={() => onSuccessfulPayment()}
          />
      }

    }
    const getMessageForService = () => {
      if(transactionState.data.EntityType === 3){
        return <span className='d-inline-block'>Payment for <b>One-on-One - "{transactionState.data.ServiceName}"</b>  has been successfully collected.</span>
      } else {
        return <span className='d-inline-block'>Payment for <b>Group Class - "{transactionState.data.ServiceName}"</b>  has been successfully collected.</span>
      }
    }
    const renderSuccessMessage = () => {
      return <div className="bz-text-center">
            <span className="k-notification k-notification-success">{getMessageForService()} <span className="btn-link d-inline-block" onClick={() => onWindowClose(true)}><u>Please Continue</u></span></span>
          </div>
    }
    return (
        <BuzopsWindow
          key={transactionState.visible.toString()}
          reduceWidth={10}
          onWindowClose={() => {
            onWindowClose(paymentStatus);
          }}
          title={"Pay remaining due"}
          className={"chargeback-global-window"}
          visibility={transactionState.visible}
        >
          <button
            type="button"
            className="btn btn-primary bz-back-btn btn-sm mr-2"
            onClick={() => onWindowClose(paymentStatus)}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          {
            paymentStatus ? renderSuccessMessage() : renderPayForm()
          }
        </BuzopsWindow>
      );
}

export default RenderPayRemainingdue;