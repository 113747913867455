import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Card,
  CardBody,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import React, { useState} from "react";
import DrawSign from "./DrawSign";
import TypeSign from "./TypeSign";
import UploadSign from "./UploadSign";
import {
  IsSigWebInstalled,
  listener,
} from "./SigWebTablet";
import TopazSignPad from "./topazSignPad";
import { isMobile } from "react-device-detect";
IsSigWebInstalled();
const SignaturePad = (props: {
  generatedImage: (
    dataString: string,
    text?: string,
    font?: string,
    TypeVal?: string
  ) => void;
  onClose: () => void;
  staffPage?: boolean;
  show?: boolean;
}) => {
  console.log(window?.SigWeb);
const isSigInstalled = localStorage.getItem('sigWebInstalled') === "true";

  const [selectedType, setSelectedType] = useState<string>("");
  const [imageString, setImageString] = useState<string>("");
  const [useLegacyOptions, setLegacyOptionsUsage] = useState<boolean>(
    isSigInstalled
  );

  listener();
  const showTabs = (type: string) => {
    if(type === "draw") {
      setLegacyOptionsUsage(isSigInstalled);
    }
    setSelectedType(type);
    return true;
  };
  const CloseWindow = () => {
    props.onClose();
  };
  const renderInitData = () => {
    return (
      <div className={"row bzo-signature-options"}>
        <div className={"col-md-12 mb-2"}>
          Choose an input method for your Signature
        </div>

        <div className={"col-lg-4 col-md-4 col-sm-12 mb-3 text-center"}>
          <div onClick={() => showTabs("draw")}>
            <Card className={"buzops-clickable"}>
              <CardBody>
                <img
                  src="/images/sign_write.svg"
                  className="card-img-top"
                  alt="Signature"
                />
                <span>Draw</span>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className={"col-lg-4 col-md-4 col-sm-12 mb-3 text-center"}>
          <div onClick={() => showTabs("type")}>
            <Card className={"buzops-clickable"}>
              <CardBody>
                <img
                  src="/images/sign_type.svg"
                  className="card-img-top"
                  alt="Signature"
                />
                <span>Type</span>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className={"col-lg-4 col-md-4 col-sm-12 mb-3 text-center"}>
          <div onClick={() => showTabs("upload")}>
            <Card className={"buzops-clickable"}>
              <CardBody>
                <img
                  src="/images/sign_upload.svg"
                  className="card-img-top"
                  alt="Signature"
                />
                <span>Upload</span>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  };
  const getTitle = () => {
    if (selectedType)
      return (
        <span onClick={() => setSelectedType("")}>
          <span className={"k-icon k-i-undo"}></span>
          {` Add Signature`}
        </span>
      );
    else return <span>Add Signature</span>;
  };
  const renderComponent = () => {
    const signatureDraw = selectedType === "draw" ? "bz-signature-dialog" : "";
    return (
      <Dialog
        className={`signature-blk ${signatureDraw}`}
        title={getTitle()}
        contentStyle={{ width: "600px", maxHeight: "400px" }}
        onClose={() => {
          CloseWindow();
        }}
      >
        <>
        {
          selectedType ? renderBasedOnSelectedType() : renderInitData()
        }
        </>
      </Dialog>
    );
  };
  const getImage = (
    imageString: string,
    signatureText?: string,
    fontStyle?: string,
    TypeVal?: string
  ) => {
    if (imageString) {
      setImageString(imageString);
      props.generatedImage(imageString, signatureText, fontStyle, TypeVal);
      props.onClose();
    }
  };
  const handleSelect = (e: any) => {
    setLegacyOptionsUsage(e.selected === 1 ? true : false);
  };
  const renderBasedOnSelectedType = () => {
    switch (selectedType) {
      case "draw":
        if (isSigInstalled && !isMobile && props?.staffPage) {
          return (
            <TabStrip
              selected={useLegacyOptions ? 1 : 0}
              onSelect={(e) => handleSelect(e)}
            >
              <TabStripTab title="Others" key={0}>
                <DrawSign getImage={getImage} />
              </TabStripTab>
              <TabStripTab title="Signature Pad" key={1}>
                <TopazSignPad
                  initialize={!useLegacyOptions}
                  getImage={getImage}
                />
              </TabStripTab>
            </TabStrip>
          );
        } else {
          return <DrawSign getImage={getImage} />;
        }
      case "type":
        return <TypeSign getImage={getImage} />;
      case "upload":
        return <UploadSign getImage={getImage} />;
      default:
        break;
    }
  };
    return <div className="sign_holder">{!imageString && renderComponent()}</div>;
};
export default SignaturePad;
