import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import ReportsCommonFilters from "components/reports/reportsCommonFilters";
import BuzopsButton from "generic-components/BuzopsButton";
import ManageDeclines from "./manageDeclines";

const Deliquency = (props: any) => {
  const products = {
    TransactionDate: null,
    SearchKeyword: null,
    EntityType: 0,
    DeclinedTransactions: [
      {
        ParentName: "Joel Felix",
        ParentMemberNumber: "GE-07282201504035",
        TotalAmount: 120.0,
        CustomerProfileID: "506620457",
        PaymentGatewayPaymentProfileId: 114146,
        PaymentProfileID: "510504920",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Joel Felix",
            UserMemberId: 151881,
            TransactionId: "40099259623",
            TransactionDate: "08/01/2022",
            MemberPlanStatus: 15,
            SubscriptionStatus: 0,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 149677,
            Amount: 120.0,
            StatusDescription: "In-Active",
            EntityId: 103730,
            ServiceName: "3 months(monthly payment)",
            PaymentMadeFor: 1,
            PaymentMadeForDescription: "Plan",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 114145,
            PaymentProfileId: "510504892",
            CustomerProfileId: "506620457",
            UserMemberId: 151881,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "02/26",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 114146,
            PaymentProfileId: "510504920",
            CustomerProfileId: "506620457",
            UserMemberId: 151881,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151881,
        ParentPhoto: null,
      },
      {
        ParentName: "zubedha Z",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 1340.0,
        CustomerProfileID: "505594234",
        PaymentGatewayPaymentProfileId: 113620,
        PaymentProfileID: "508799211",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Zubedha Sub",
            UserMemberId: 151637,
            TransactionId: "40097604401",
            TransactionDate: "06/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 149439,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100955,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Zubedha Sub",
            UserMemberId: 151637,
            TransactionId: "40097602624",
            TransactionDate: "05/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 149415,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100955,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Zubedha Sub",
            UserMemberId: 151637,
            TransactionId: "40097602300",
            TransactionDate: "04/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 149391,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100955,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Zubedha Sub",
            UserMemberId: 151637,
            TransactionId: "40097602129",
            TransactionDate: "03/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 149366,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100955,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Zubedha Sub",
            UserMemberId: 151637,
            TransactionId: "60193346550",
            TransactionDate: "02/01/2022",
            MemberPlanStatus: 15,
            SubscriptionStatus: 0,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148294,
            Amount: 1250.0,
            StatusDescription: "In-Active",
            EntityId: 103104,
            ServiceName: "Five Months",
            PaymentMadeFor: 1,
            PaymentMadeForDescription: "Membership",
          },
          {
            FullName: "Zubedha Sub",
            UserMemberId: 151637,
            TransactionId: "40097601694",
            TransactionDate: "02/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 149341,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100955,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Zubedha Sub",
            UserMemberId: 151637,
            TransactionId: "40097601263",
            TransactionDate: "01/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 149316,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100955,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Zubedha Sub",
            UserMemberId: 151637,
            TransactionId: "60193345499",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148257,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100955,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Zubedha Sub",
            UserMemberId: 151637,
            TransactionId: "60193345411",
            TransactionDate: "11/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148222,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100955,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Zubedha Sub",
            UserMemberId: 151637,
            TransactionId: "60193345294",
            TransactionDate: "10/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148200,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100955,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113619,
            PaymentProfileId: "508799144",
            CustomerProfileId: "505594234",
            UserMemberId: 151636,
            MaskedCCNumber: "xxxx0015",
            ExpiryDate: "09/2029",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113620,
            PaymentProfileId: "508799211",
            CustomerProfileId: "505594234",
            UserMemberId: 151636,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151636,
        ParentPhoto: null,
      },
      {
        ParentName: "Swathi S",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 1410.0,
        CustomerProfileID: "501746264",
        PaymentGatewayPaymentProfileId: 93444,
        PaymentProfileID: "507633300",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Swathi S",
            UserMemberId: 121551,
            TransactionId: "40095993507",
            TransactionDate: "06/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 149124,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80892,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Swathi S",
            UserMemberId: 121551,
            TransactionId: "40095993176",
            TransactionDate: "05/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 149036,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80892,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Swathi S",
            UserMemberId: 121551,
            TransactionId: "60193347219",
            TransactionDate: "04/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148462,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80892,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Swathi S",
            UserMemberId: 121551,
            TransactionId: "60193346786",
            TransactionDate: "03/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148398,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80892,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Swathi S",
            UserMemberId: 121551,
            TransactionId: "60193346558",
            TransactionDate: "02/01/2022",
            MemberPlanStatus: 15,
            SubscriptionStatus: 0,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148315,
            Amount: 1250.0,
            StatusDescription: "In-Active",
            EntityId: 82911,
            ServiceName: "Five Months",
            PaymentMadeFor: 1,
            PaymentMadeForDescription: "Membership",
          },
          {
            FullName: "Swathi S",
            UserMemberId: 121551,
            TransactionId: "40088417845",
            TransactionDate: "01/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 146524,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80892,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Swathi S",
            UserMemberId: 121551,
            TransactionId: "40087633218",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 136454,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80892,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Swathi S",
            UserMemberId: 121551,
            TransactionId: "40087275290",
            TransactionDate: "11/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 126503,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80892,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Swathi S",
            UserMemberId: 121551,
            TransactionId: "40087273874",
            TransactionDate: "10/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 126496,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80892,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 21107,
            PaymentProfileId: "502928961",
            CustomerProfileId: "501746264",
            UserMemberId: 121551,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "03/2027",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 93443,
            PaymentProfileId: "507633233",
            CustomerProfileId: "501746264",
            UserMemberId: 121551,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "09/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 93444,
            PaymentProfileId: "507633300",
            CustomerProfileId: "501746264",
            UserMemberId: 121551,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 121551,
        ParentPhoto:
          "/Storage//Stesting/User/6ac6c7cb-6308-4e47-8a1b-7a70365f5532.png",
      },
      {
        ParentName: "Leela L",
        ParentMemberNumber: "GE-01012207000000",
        TotalAmount: 20.0,
        CustomerProfileID: "505834231",
        PaymentGatewayPaymentProfileId: 113944,
        PaymentProfileID: "509396454",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Leela L",
            UserMemberId: 151707,
            TransactionId: "40095913487",
            TransactionDate: "03/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148927,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 101091,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Leela L",
            UserMemberId: 151707,
            TransactionId: "40095193920",
            TransactionDate: "02/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148848,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 101091,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113799,
            PaymentProfileId: "509196573",
            CustomerProfileId: "505834231",
            UserMemberId: 151707,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "12/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113944,
            PaymentProfileId: "509396454",
            CustomerProfileId: "505834231",
            UserMemberId: 151707,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113946,
            PaymentProfileId: "509405337",
            CustomerProfileId: "505834231",
            UserMemberId: 151707,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "08/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151707,
        ParentPhoto: null,
      },
      {
        ParentName: "Amitha A",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 125.0,
        CustomerProfileID: "1518638400",
        PaymentGatewayPaymentProfileId: 113913,
        PaymentProfileID: "509351861",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Amitha A",
            UserMemberId: 101544,
            TransactionId: "0",
            TransactionDate: "02/10/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 0,
            ServiceStatus: 5,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148760,
            Amount: 125.0,
            StatusDescription: "In-Active",
            EntityId: 92265,
            ServiceName: "Paid_Recurrence",
            PaymentMadeFor: 5,
            PaymentMadeForDescription: "One-on-One",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 20579,
            PaymentProfileId: "500422331",
            CustomerProfileId: "1518638400",
            UserMemberId: 101544,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "01/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 73432,
            PaymentProfileId: "507354056",
            CustomerProfileId: "1518638400",
            UserMemberId: 101544,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "02/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113913,
            PaymentProfileId: "509351861",
            CustomerProfileId: "1518638400",
            UserMemberId: 101544,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "12/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113914,
            PaymentProfileId: "509352234",
            CustomerProfileId: "1518638400",
            UserMemberId: 101544,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "12/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113963,
            PaymentProfileId: "509459671",
            CustomerProfileId: "1518638400",
            UserMemberId: 101544,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "07/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 114002,
            PaymentProfileId: "509586975",
            CustomerProfileId: "1518638400",
            UserMemberId: 101544,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "02/27",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 114013,
            PaymentProfileId: "509652690",
            CustomerProfileId: "1518638400",
            UserMemberId: 101544,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "12/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 114132,
            PaymentProfileId: "510393714",
            CustomerProfileId: "1518638400",
            UserMemberId: 101544,
            MaskedCCNumber: "xxxx0015",
            ExpiryDate: "11/26",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 101544,
        ParentPhoto: null,
      },
      {
        ParentName: "Aradhya AA",
        ParentMemberNumber: "GE-12012107000000",
        TotalAmount: 40.0,
        CustomerProfileID: "504604611",
        PaymentGatewayPaymentProfileId: 73450,
        PaymentProfileID: "507389791",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Aradhya AA",
            UserMemberId: 101550,
            TransactionId: "40086344159",
            TransactionDate: "02/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 106483,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 60888,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Aradhya AA",
            UserMemberId: 101550,
            TransactionId: "40086343111",
            TransactionDate: "01/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 106467,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 60888,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 73447,
            PaymentProfileId: "507389207",
            CustomerProfileId: "504604611",
            UserMemberId: 101550,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "12/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 73450,
            PaymentProfileId: "507389791",
            CustomerProfileId: "504604611",
            UserMemberId: 101550,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113916,
            PaymentProfileId: "905202800",
            CustomerProfileId: "504604611",
            UserMemberId: 101550,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "12/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 101550,
        ParentPhoto: null,
      },
      {
        ParentName: "Shradha SS",
        ParentMemberNumber: "GE-12012107000000",
        TotalAmount: 40.0,
        CustomerProfileID: "504604332",
        PaymentGatewayPaymentProfileId: 73449,
        PaymentProfileID: "507389422",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Shradha SS",
            UserMemberId: 101548,
            TransactionId: "40086344149",
            TransactionDate: "02/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 106480,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 60886,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Shradha SS",
            UserMemberId: 101548,
            TransactionId: "40086343109",
            TransactionDate: "01/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 106465,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 60886,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 73443,
            PaymentProfileId: "507389000",
            CustomerProfileId: "504604332",
            UserMemberId: 101548,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "04/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 73449,
            PaymentProfileId: "507389422",
            CustomerProfileId: "504604332",
            UserMemberId: 101548,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 101548,
        ParentPhoto: null,
      },
      {
        ParentName: "Priya PP",
        ParentMemberNumber: "GE-12012107000000",
        TotalAmount: 40.0,
        CustomerProfileID: "504604421",
        PaymentGatewayPaymentProfileId: 73448,
        PaymentProfileID: "507389278",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Priya PP",
            UserMemberId: 101549,
            TransactionId: "40086344146",
            TransactionDate: "02/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 106477,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 60887,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Priya PP",
            UserMemberId: 101549,
            TransactionId: "40086343106",
            TransactionDate: "01/01/2022",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 106463,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 60887,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 73445,
            PaymentProfileId: "507389074",
            CustomerProfileId: "504604421",
            UserMemberId: 101549,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "04/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 73448,
            PaymentProfileId: "507389278",
            CustomerProfileId: "504604421",
            UserMemberId: 101549,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 101549,
        ParentPhoto: null,
      },
      {
        ParentName: "Sridevi S",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 20.0,
        CustomerProfileID: "500657118",
        PaymentGatewayPaymentProfileId: 113464,
        PaymentProfileID: "507938215",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Sridevi S",
            UserMemberId: 151557,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148260,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 100898,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 20779,
            PaymentProfileId: "501088102",
            CustomerProfileId: "500657118",
            UserMemberId: 151557,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "07/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 20780,
            PaymentProfileId: "501088111",
            CustomerProfileId: "500657118",
            UserMemberId: 151557,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113464,
            PaymentProfileId: "507938215",
            CustomerProfileId: "500657118",
            UserMemberId: 151557,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "09/2027",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151557,
        ParentPhoto: null,
      },
      {
        ParentName: "Xavier X",
        ParentMemberNumber: "GE-10012108000000",
        TotalAmount: 8.0,
        CustomerProfileID: "505593487",
        PaymentGatewayPaymentProfileId: 113617,
        PaymentProfileID: "508798230",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Xavier X",
            UserMemberId: 151635,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148255,
            Amount: 8.0,
            StatusDescription: "In-Active",
            EntityId: 100952,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113617,
            PaymentProfileId: "508798230",
            CustomerProfileId: "505593487",
            UserMemberId: 151635,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "10/2027",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151635,
        ParentPhoto: null,
      },
      {
        ParentName: "Renu R",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 16.0,
        CustomerProfileID: "504631099",
        PaymentGatewayPaymentProfileId: 113515,
        PaymentProfileID: "508613558",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Renu R",
            UserMemberId: 151584,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148279,
            Amount: 16.0,
            StatusDescription: "In-Active",
            EntityId: 100912,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113515,
            PaymentProfileId: "508613558",
            CustomerProfileId: "504631099",
            UserMemberId: 151584,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "09/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113833,
            PaymentProfileId: "509222347",
            CustomerProfileId: "504631099",
            UserMemberId: 151584,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "12/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151584,
        ParentPhoto: null,
      },
      {
        ParentName: "Bharati B",
        ParentMemberNumber: "GE-10012108000000",
        TotalAmount: 10.0,
        CustomerProfileID: "505572653",
        PaymentGatewayPaymentProfileId: 113585,
        PaymentProfileID: "508756534",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Bharati B",
            UserMemberId: 151621,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148253,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100939,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113585,
            PaymentProfileId: "508756534",
            CustomerProfileId: "505572653",
            UserMemberId: 151621,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "04/2023",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151621,
        ParentPhoto: null,
      },
      {
        ParentName: "zubedha Z",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 230.0,
        CustomerProfileID: "505594234",
        PaymentGatewayPaymentProfileId: 113620,
        PaymentProfileID: "508799211",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "zubedha Z",
            UserMemberId: 151636,
            TransactionId: "60193345499",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148258,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100954,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "zubedha Z",
            UserMemberId: 151636,
            TransactionId: "60193345411",
            TransactionDate: "11/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148220,
            Amount: 100.0,
            StatusDescription: "In-Active",
            EntityId: 100953,
            ServiceName: "Monthly(until 5 months)",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "zubedha Z",
            UserMemberId: 151636,
            TransactionId: "60193345411",
            TransactionDate: "11/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148221,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100954,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "zubedha Z",
            UserMemberId: 151636,
            TransactionId: "60193345294",
            TransactionDate: "10/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148199,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100954,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "zubedha Z",
            UserMemberId: 151636,
            TransactionId: "60193345294",
            TransactionDate: "10/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148198,
            Amount: 100.0,
            StatusDescription: "In-Active",
            EntityId: 100953,
            ServiceName: "Monthly(until 5 months)",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113619,
            PaymentProfileId: "508799144",
            CustomerProfileId: "505594234",
            UserMemberId: 151636,
            MaskedCCNumber: "xxxx0015",
            ExpiryDate: "09/2029",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113620,
            PaymentProfileId: "508799211",
            CustomerProfileId: "505594234",
            UserMemberId: 151636,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151636,
        ParentPhoto: null,
      },
      {
        ParentName: "Vinaya V",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 20.0,
        CustomerProfileID: "501360291",
        PaymentGatewayPaymentProfileId: 113507,
        PaymentProfileID: "508579166",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Vinaya V",
            UserMemberId: 151580,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148273,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 100907,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 21020,
            PaymentProfileId: "502253006",
            CustomerProfileId: "501360291",
            UserMemberId: 151580,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "05/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 31392,
            PaymentProfileId: "504874233",
            CustomerProfileId: "501360291",
            UserMemberId: 151580,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "02/26",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 41521,
            PaymentProfileId: "505668318",
            CustomerProfileId: "501360291",
            UserMemberId: 151580,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "02/26",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 41522,
            PaymentProfileId: "505668319",
            CustomerProfileId: "501360291",
            UserMemberId: 151580,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "02/26",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113507,
            PaymentProfileId: "508579166",
            CustomerProfileId: "501360291",
            UserMemberId: 151580,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "09/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151580,
        ParentPhoto: null,
      },
      {
        ParentName: "Raman R",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 10.0,
        CustomerProfileID: "505362467",
        PaymentGatewayPaymentProfileId: 113525,
        PaymentProfileID: "508667566",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Raman R",
            UserMemberId: 151591,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148281,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100914,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113525,
            PaymentProfileId: "508667566",
            CustomerProfileId: "505362467",
            UserMemberId: 151591,
            MaskedCCNumber: "xxxx0015",
            ExpiryDate: "09/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151591,
        ParentPhoto: null,
      },
      {
        ParentName: "Madhu M",
        ParentMemberNumber: "GE-10012108000000",
        TotalAmount: 10.0,
        CustomerProfileID: "505567269",
        PaymentGatewayPaymentProfileId: 113576,
        PaymentProfileID: "508750791",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Madhu M",
            UserMemberId: 151617,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148288,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100936,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113576,
            PaymentProfileId: "508750791",
            CustomerProfileId: "505567269",
            UserMemberId: 151617,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "03/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113582,
            PaymentProfileId: "904897373",
            CustomerProfileId: "505567269",
            UserMemberId: 151617,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "04/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151617,
        ParentPhoto: null,
      },
      {
        ParentName: "sarayu S",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 20.0,
        CustomerProfileID: "1516893811",
        PaymentGatewayPaymentProfileId: 113501,
        PaymentProfileID: "508019712",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "sarayu S",
            UserMemberId: 151575,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148271,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 100906,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 10529,
            PaymentProfileId: "500183883",
            CustomerProfileId: "1516893811",
            UserMemberId: 151575,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "02/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 53232,
            PaymentProfileId: "506545580",
            CustomerProfileId: "1516893811",
            UserMemberId: 151575,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "03/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113498,
            PaymentProfileId: "508013802",
            CustomerProfileId: "1516893811",
            UserMemberId: 151575,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "09/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113500,
            PaymentProfileId: "508019230",
            CustomerProfileId: "1516893811",
            UserMemberId: 151575,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113501,
            PaymentProfileId: "508019712",
            CustomerProfileId: "1516893811",
            UserMemberId: 151575,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "12/23",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151575,
        ParentPhoto: null,
      },
      {
        ParentName: "Lokesh L",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 20.0,
        CustomerProfileID: "505019182",
        PaymentGatewayPaymentProfileId: 113462,
        PaymentProfileID: "507937940",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Lokesh L",
            UserMemberId: 151556,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148269,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 100897,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113462,
            PaymentProfileId: "507937940",
            CustomerProfileId: "505019182",
            UserMemberId: 151556,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "09/2027",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151556,
        ParentPhoto: null,
      },
      {
        ParentName: "Priya P",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 20.0,
        CustomerProfileID: "500992946",
        PaymentGatewayPaymentProfileId: 113495,
        PaymentProfileID: "507982602",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Priya P",
            UserMemberId: 151573,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148267,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 100904,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 31229,
            PaymentProfileId: "503753015",
            CustomerProfileId: "500992946",
            UserMemberId: 151573,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "03/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113495,
            PaymentProfileId: "507982602",
            CustomerProfileId: "500992946",
            UserMemberId: 151573,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "09/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151573,
        ParentPhoto: null,
      },
      {
        ParentName: "Nolan N",
        ParentMemberNumber: "GE-10012108000000",
        TotalAmount: 10.0,
        CustomerProfileID: "505572658",
        PaymentGatewayPaymentProfileId: 113587,
        PaymentProfileID: "508756540",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Nolan N",
            UserMemberId: 151622,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148289,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100940,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113587,
            PaymentProfileId: "508756540",
            CustomerProfileId: "505572658",
            UserMemberId: 151622,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "07/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151622,
        ParentPhoto: null,
      },
      {
        ParentName: "Simran SS",
        ParentMemberNumber: "GE-10012108000000",
        TotalAmount: 40.0,
        CustomerProfileID: "504742237",
        PaymentGatewayPaymentProfileId: 93433,
        PaymentProfileID: "507597420",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Simran SS",
            UserMemberId: 121545,
            TransactionId: "40087633206",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 136439,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80885,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Simran SS",
            UserMemberId: 121545,
            TransactionId: "40087130952",
            TransactionDate: "11/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 126458,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80885,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 93432,
            PaymentProfileId: "507596590",
            CustomerProfileId: "504742237",
            UserMemberId: 121545,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "03/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 93433,
            PaymentProfileId: "507597420",
            CustomerProfileId: "504742237",
            UserMemberId: 121545,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 121545,
        ParentPhoto: null,
      },
      {
        ParentName: "priya P",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 20.0,
        CustomerProfileID: "505462858",
        PaymentGatewayPaymentProfileId: 113511,
        PaymentProfileID: "508579250",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "priya P",
            UserMemberId: 151582,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148277,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 100911,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113511,
            PaymentProfileId: "508579250",
            CustomerProfileId: "505462858",
            UserMemberId: 151582,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "09/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 114005,
            PaymentProfileId: "509612272",
            CustomerProfileId: "505462858",
            UserMemberId: 151582,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "11/28",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151582,
        ParentPhoto: null,
      },
      {
        ParentName: "Suman S",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 20.0,
        CustomerProfileID: "504986615",
        PaymentGatewayPaymentProfileId: 113448,
        PaymentProfileID: "507896631",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Suman S",
            UserMemberId: 151549,
            TransactionId: "0",
            TransactionDate: "12/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148287,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 100888,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113448,
            PaymentProfileId: "507896631",
            CustomerProfileId: "504986615",
            UserMemberId: 151549,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "09/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151549,
        ParentPhoto: null,
      },
      {
        ParentName: "Nithin N",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 16.0,
        CustomerProfileID: "1516930951",
        PaymentGatewayPaymentProfileId: 113800,
        PaymentProfileID: "509196579",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Nithin N",
            UserMemberId: 151696,
            TransactionId: "40094456762",
            TransactionDate: "11/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148611,
            Amount: 8.0,
            StatusDescription: "In-Active",
            EntityId: 101030,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Nithin N",
            UserMemberId: 151696,
            TransactionId: "40094451154",
            TransactionDate: "10/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148575,
            Amount: 8.0,
            StatusDescription: "In-Active",
            EntityId: 101030,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113778,
            PaymentProfileId: "509176769",
            CustomerProfileId: "1516930951",
            UserMemberId: 151696,
            MaskedCCNumber: "xxxx0015",
            ExpiryDate: "09/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113800,
            PaymentProfileId: "509196579",
            CustomerProfileId: "1516930951",
            UserMemberId: 151696,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151696,
        ParentPhoto: null,
      },
      {
        ParentName: "Sandy S",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 20.0,
        CustomerProfileID: "501297681",
        PaymentGatewayPaymentProfileId: 113797,
        PaymentProfileID: "509196565",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Sandy S",
            UserMemberId: 151706,
            TransactionId: "40094456753",
            TransactionDate: "11/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148604,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 101037,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Sandy S",
            UserMemberId: 151706,
            TransactionId: "40094451149",
            TransactionDate: "10/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 148569,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 101037,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113796,
            PaymentProfileId: "509196356",
            CustomerProfileId: "501297681",
            UserMemberId: 151706,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "09/2027",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113797,
            PaymentProfileId: "509196565",
            CustomerProfileId: "501297681",
            UserMemberId: 151706,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 20980,
            PaymentProfileId: "502135610",
            CustomerProfileId: "501297681",
            UserMemberId: 151706,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "03/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 20981,
            PaymentProfileId: "502135653",
            CustomerProfileId: "501297681",
            UserMemberId: 151706,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151706,
        ParentPhoto: null,
      },
      {
        ParentName: "Chakri C",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 40.0,
        CustomerProfileID: "502051808",
        PaymentGatewayPaymentProfileId: 93441,
        PaymentProfileID: "507599125",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Chakri C",
            UserMemberId: 121549,
            TransactionId: "40087130981",
            TransactionDate: "11/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 126468,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80889,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Chakri C",
            UserMemberId: 121549,
            TransactionId: "40087128661",
            TransactionDate: "10/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 126451,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 80889,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 93440,
            PaymentProfileId: "507599008",
            CustomerProfileId: "502051808",
            UserMemberId: 121549,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "09/28",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 93441,
            PaymentProfileId: "507599125",
            CustomerProfileId: "502051808",
            UserMemberId: 121549,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113912,
            PaymentProfileId: "509351725",
            CustomerProfileId: "502051808",
            UserMemberId: 121549,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "12/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 121549,
        ParentPhoto: null,
      },
      {
        ParentName: "sarayu S",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 20.0,
        CustomerProfileID: "1516893811",
        PaymentGatewayPaymentProfileId: 113500,
        PaymentProfileID: "508019230",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "sarayu S",
            UserMemberId: 151575,
            TransactionId: "40088892235",
            TransactionDate: "10/01/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 146588,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 100906,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 10529,
            PaymentProfileId: "500183883",
            CustomerProfileId: "1516893811",
            UserMemberId: 151575,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "02/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 53232,
            PaymentProfileId: "506545580",
            CustomerProfileId: "1516893811",
            UserMemberId: 151575,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "03/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113498,
            PaymentProfileId: "508013802",
            CustomerProfileId: "1516893811",
            UserMemberId: 151575,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "09/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113500,
            PaymentProfileId: "508019230",
            CustomerProfileId: "1516893811",
            UserMemberId: 151575,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113501,
            PaymentProfileId: "508019712",
            CustomerProfileId: "1516893811",
            UserMemberId: 151575,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "12/23",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151575,
        ParentPhoto: null,
      },
      {
        ParentName: "Rohith R",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 1.0,
        CustomerProfileID: "504771167",
        PaymentGatewayPaymentProfileId: 93447,
        PaymentProfileID: "507633408",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Rohith R",
            UserMemberId: 121552,
            TransactionId: "40087271725",
            TransactionDate: "09/03/2021",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 126489,
            Amount: 1.0,
            StatusDescription: "In-Active",
            EntityId: 80893,
            ServiceName: "Daily",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 93446,
            PaymentProfileId: "507633343",
            CustomerProfileId: "504771167",
            UserMemberId: 121552,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "09/2028",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 93447,
            PaymentProfileId: "507633408",
            CustomerProfileId: "504771167",
            UserMemberId: 121552,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 121552,
        ParentPhoto: null,
      },
      {
        ParentName: "Sudha S",
        ParentMemberNumber: "GE-04011908000000",
        TotalAmount: 30.0,
        CustomerProfileID: "502486374",
        PaymentGatewayPaymentProfileId: 113609,
        PaymentProfileID: "508793163",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Sudha S",
            UserMemberId: 151631,
            TransactionId: "0",
            TransactionDate: "06/01/2020",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147569,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100950,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Sudha S",
            UserMemberId: 151631,
            TransactionId: "0",
            TransactionDate: "02/01/2020",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147242,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100950,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Sudha S",
            UserMemberId: 151631,
            TransactionId: "0",
            TransactionDate: "10/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147212,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100950,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113609,
            PaymentProfileId: "508793163",
            CustomerProfileId: "502486374",
            UserMemberId: 151631,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "10/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 31363,
            PaymentProfileId: "504726247",
            CustomerProfileId: "502486374",
            UserMemberId: 151631,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "12/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 41546,
            PaymentProfileId: "505776769",
            CustomerProfileId: "502486374",
            UserMemberId: 151631,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "02/2027",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151631,
        ParentPhoto: null,
      },
      {
        ParentName: "Ali A",
        ParentMemberNumber: "GE-04011908000000",
        TotalAmount: 20.0,
        CustomerProfileID: "1515446194",
        PaymentGatewayPaymentProfileId: 113552,
        PaymentProfileID: "508725236",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Ali A",
            UserMemberId: 151606,
            TransactionId: "0",
            TransactionDate: "06/01/2020",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147550,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100926,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Ali A",
            UserMemberId: 151606,
            TransactionId: "0",
            TransactionDate: "03/01/2020",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 146977,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100926,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113552,
            PaymentProfileId: "508725236",
            CustomerProfileId: "1515446194",
            UserMemberId: 151606,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "10/2023",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 31216,
            PaymentProfileId: "503735738",
            CustomerProfileId: "1515446194",
            UserMemberId: 151606,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "09/2027",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151606,
        ParentPhoto: null,
      },
      {
        ParentName: "Sunitha S",
        ParentMemberNumber: "GE-01011907000000",
        TotalAmount: 40.0,
        CustomerProfileID: "1515711481",
        PaymentGatewayPaymentProfileId: 113529,
        PaymentProfileID: "508689727",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Sunitha S",
            UserMemberId: 151593,
            TransactionId: "0",
            TransactionDate: "06/01/2020",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147554,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 100916,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Sunitha S",
            UserMemberId: 151593,
            TransactionId: "0",
            TransactionDate: "03/01/2020",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 146981,
            Amount: 20.0,
            StatusDescription: "In-Active",
            EntityId: 100916,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 20661,
            PaymentProfileId: "500758298",
            CustomerProfileId: "1515711481",
            UserMemberId: 151593,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "01/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 20662,
            PaymentProfileId: "500758358",
            CustomerProfileId: "1515711481",
            UserMemberId: 151593,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 21160,
            PaymentProfileId: "503279969",
            CustomerProfileId: "1515711481",
            UserMemberId: 151593,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "10/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 41594,
            PaymentProfileId: "506095889",
            CustomerProfileId: "1515711481",
            UserMemberId: 151593,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "08/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 41595,
            PaymentProfileId: "506096744",
            CustomerProfileId: "1515711481",
            UserMemberId: 151593,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 53224,
            PaymentProfileId: "506511102",
            CustomerProfileId: "1515711481",
            UserMemberId: 151593,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "02/2027",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113529,
            PaymentProfileId: "508689727",
            CustomerProfileId: "1515711481",
            UserMemberId: 151593,
            MaskedCCNumber: "xxxx0027",
            ExpiryDate: "10/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151593,
        ParentPhoto: null,
      },
      {
        ParentName: "Shreya S",
        ParentMemberNumber: "GE-01011907000000",
        TotalAmount: 40.0,
        CustomerProfileID: "504673875",
        PaymentGatewayPaymentProfileId: 113660,
        PaymentProfileID: "508849553",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Shreya S",
            UserMemberId: 151654,
            TransactionId: "0",
            TransactionDate: "05/01/2020",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147539,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100967,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Shreya S",
            UserMemberId: 151654,
            TransactionId: "0",
            TransactionDate: "12/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147463,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100967,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Shreya S",
            UserMemberId: 151654,
            TransactionId: "0",
            TransactionDate: "09/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147452,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100967,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Shreya S",
            UserMemberId: 151654,
            TransactionId: "0",
            TransactionDate: "06/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147441,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100967,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113660,
            PaymentProfileId: "508849553",
            CustomerProfileId: "504673875",
            UserMemberId: 151654,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "07/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151654,
        ParentPhoto: null,
      },
      {
        ParentName: "Smitha S",
        ParentMemberNumber: "GE-01011907000000",
        TotalAmount: 40.0,
        CustomerProfileID: "505626129",
        PaymentGatewayPaymentProfileId: 113652,
        PaymentProfileID: "508845416",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Smitha S",
            UserMemberId: 151651,
            TransactionId: "0",
            TransactionDate: "05/01/2020",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147534,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100964,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Smitha S",
            UserMemberId: 151651,
            TransactionId: "0",
            TransactionDate: "12/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147393,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100964,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Smitha S",
            UserMemberId: 151651,
            TransactionId: "0",
            TransactionDate: "07/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147350,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100964,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Smitha S",
            UserMemberId: 151651,
            TransactionId: "0",
            TransactionDate: "04/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147314,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100964,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113652,
            PaymentProfileId: "508845416",
            CustomerProfileId: "505626129",
            UserMemberId: 151651,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "06/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113653,
            PaymentProfileId: "508845446",
            CustomerProfileId: "505626129",
            UserMemberId: 151651,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151651,
        ParentPhoto: null,
      },
      {
        ParentName: "Smitha SS",
        ParentMemberNumber: "GE-01011907000000",
        TotalAmount: 30.0,
        CustomerProfileID: "505626260",
        PaymentGatewayPaymentProfileId: 113655,
        PaymentProfileID: "508845552",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Smitha SS",
            UserMemberId: 151652,
            TransactionId: "0",
            TransactionDate: "05/01/2020",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147531,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100965,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Smitha SS",
            UserMemberId: 151652,
            TransactionId: "0",
            TransactionDate: "12/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147390,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100965,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Smitha SS",
            UserMemberId: 151652,
            TransactionId: "0",
            TransactionDate: "07/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147348,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100965,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113655,
            PaymentProfileId: "508845552",
            CustomerProfileId: "505626260",
            UserMemberId: 151652,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "07/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113656,
            PaymentProfileId: "508845574",
            CustomerProfileId: "505626260",
            UserMemberId: 151652,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151652,
        ParentPhoto: null,
      },
      {
        ParentName: "Jai J",
        ParentMemberNumber: "GE-04011908000000",
        TotalAmount: 30.0,
        CustomerProfileID: "505553203",
        PaymentGatewayPaymentProfileId: 113562,
        PaymentProfileID: "508725920",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Jai J",
            UserMemberId: 151610,
            TransactionId: "0",
            TransactionDate: "07/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147139,
            Amount: 30.0,
            StatusDescription: "In-Active",
            EntityId: 100930,
            ServiceName: "Daily_15 days",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113562,
            PaymentProfileId: "508725920",
            CustomerProfileId: "505553203",
            UserMemberId: 151610,
            MaskedCCNumber: "xxxx0015",
            ExpiryDate: "11/2023",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151610,
        ParentPhoto: null,
      },
      {
        ParentName: "Ankitha A",
        ParentMemberNumber: "GE-03311908000000",
        TotalAmount: 10.0,
        CustomerProfileID: "505552688",
        PaymentGatewayPaymentProfileId: 113557,
        PaymentProfileID: "508725604",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Ankitha A",
            UserMemberId: 151608,
            TransactionId: "0",
            TransactionDate: "06/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147977,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100990,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113556,
            PaymentProfileId: "508725325",
            CustomerProfileId: "505552688",
            UserMemberId: 151608,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "11/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113557,
            PaymentProfileId: "508725604",
            CustomerProfileId: "505552688",
            UserMemberId: 151608,
            MaskedCCNumber: "xxxx0015",
            ExpiryDate: "12/26",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 114087,
            PaymentProfileId: "510169966",
            CustomerProfileId: "505552688",
            UserMemberId: 151608,
            MaskedCCNumber: "xxxx0027",
            ExpiryDate: "12/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151608,
        ParentPhoto: null,
      },
      {
        ParentName: "Anusha A",
        ParentMemberNumber: "GE-04011908000000",
        TotalAmount: 10.0,
        CustomerProfileID: "502551689",
        PaymentGatewayPaymentProfileId: 113564,
        PaymentProfileID: "508746567",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Anusha A",
            UserMemberId: 151611,
            TransactionId: "0",
            TransactionDate: "06/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147979,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100991,
            ServiceName: "Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113564,
            PaymentProfileId: "508746567",
            CustomerProfileId: "502551689",
            UserMemberId: 151611,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "06/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 31314,
            PaymentProfileId: "504290428",
            CustomerProfileId: "502551689",
            UserMemberId: 151611,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "11/2027",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151611,
        ParentPhoto: null,
      },
      {
        ParentName: "Anwar A",
        ParentMemberNumber: "GE-04011908000000",
        TotalAmount: 10.0,
        CustomerProfileID: "505552962",
        PaymentGatewayPaymentProfileId: 113560,
        PaymentProfileID: "508725696",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Anwar A",
            UserMemberId: 151609,
            TransactionId: "0",
            TransactionDate: "06/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147982,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100992,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113559,
            PaymentProfileId: "508725654",
            CustomerProfileId: "505552962",
            UserMemberId: 151609,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "07/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113560,
            PaymentProfileId: "508725696",
            CustomerProfileId: "505552962",
            UserMemberId: 151609,
            MaskedCCNumber: "xxxx0015",
            ExpiryDate: "11/26",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151609,
        ParentPhoto: null,
      },
      {
        ParentName: "Juhi J",
        ParentMemberNumber: "GE-09012108000000",
        TotalAmount: 10.0,
        CustomerProfileID: "504796651",
        PaymentGatewayPaymentProfileId: 93461,
        PaymentProfileID: "507665227",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Juhi J",
            UserMemberId: 121557,
            TransactionId: "0",
            TransactionDate: "06/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147984,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100995,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 93461,
            PaymentProfileId: "507665227",
            CustomerProfileId: "504796651",
            UserMemberId: 121557,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "09/2027",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 121557,
        ParentPhoto: null,
      },
      {
        ParentName: "Hari H",
        ParentMemberNumber: "GE-01011907000000",
        TotalAmount: 10.0,
        CustomerProfileID: "502861560",
        PaymentGatewayPaymentProfileId: 113675,
        PaymentProfileID: "508856003",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Hari H",
            UserMemberId: 151660,
            TransactionId: "0",
            TransactionDate: "06/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147981,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100994,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113675,
            PaymentProfileId: "508856003",
            CustomerProfileId: "502861560",
            UserMemberId: 151660,
            MaskedCCNumber: "xxxx8888",
            ExpiryDate: "07/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 31379,
            PaymentProfileId: "504840827",
            CustomerProfileId: "502861560",
            UserMemberId: 151660,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "02/26",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151660,
        ParentPhoto: null,
      },
      {
        ParentName: "Lahari L",
        ParentMemberNumber: "GE-01012007000000",
        TotalAmount: 10.0,
        CustomerProfileID: "500264809",
        PaymentGatewayPaymentProfileId: 113568,
        PaymentProfileID: "508746891",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Lahari L",
            UserMemberId: 151613,
            TransactionId: "0",
            TransactionDate: "06/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147983,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100993,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113568,
            PaymentProfileId: "508746891",
            CustomerProfileId: "500264809",
            UserMemberId: 151613,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "06/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 20597,
            PaymentProfileId: "500453475",
            CustomerProfileId: "500264809",
            UserMemberId: 151613,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "06/2026",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 20606,
            PaymentProfileId: "500477211",
            CustomerProfileId: "500264809",
            UserMemberId: 151613,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151613,
        ParentPhoto: null,
      },
      {
        ParentName: "sravanthi S",
        ParentMemberNumber: "GE-01011907000000",
        TotalAmount: 10.0,
        CustomerProfileID: "500138464",
        PaymentGatewayPaymentProfileId: 113697,
        PaymentProfileID: "508886882",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "sravanthi S",
            UserMemberId: 151670,
            TransactionId: "40093127627",
            TransactionDate: "06/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147850,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100979,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113696,
            PaymentProfileId: "508886730",
            CustomerProfileId: "500138464",
            UserMemberId: 151670,
            MaskedCCNumber: "xxxx0015",
            ExpiryDate: "07/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113697,
            PaymentProfileId: "508886882",
            CustomerProfileId: "500138464",
            UserMemberId: 151670,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151670,
        ParentPhoto: null,
      },
      {
        ParentName: "Vamsi V",
        ParentMemberNumber: "GE-03011907000000",
        TotalAmount: 10.0,
        CustomerProfileID: "500372634",
        PaymentGatewayPaymentProfileId: 113544,
        PaymentProfileID: "508721251",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Vamsi V",
            UserMemberId: 151602,
            TransactionId: "0",
            TransactionDate: "06/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147975,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100989,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113544,
            PaymentProfileId: "508721251",
            CustomerProfileId: "500372634",
            UserMemberId: 151602,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "10/2025",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 31327,
            PaymentProfileId: "504329248",
            CustomerProfileId: "500372634",
            UserMemberId: 151602,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "02/26",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151602,
        ParentPhoto: null,
      },
      {
        ParentName: "sravanthi S",
        ParentMemberNumber: "GE-01011907000000",
        TotalAmount: 20.0,
        CustomerProfileID: "500138464",
        PaymentGatewayPaymentProfileId: 113696,
        PaymentProfileID: "508886730",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "sravanthi S",
            UserMemberId: 151670,
            TransactionId: "0",
            TransactionDate: "05/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147758,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100979,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "sravanthi S",
            UserMemberId: 151670,
            TransactionId: "0",
            TransactionDate: "03/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147753,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100979,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113696,
            PaymentProfileId: "508886730",
            CustomerProfileId: "500138464",
            UserMemberId: 151670,
            MaskedCCNumber: "xxxx0015",
            ExpiryDate: "07/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113697,
            PaymentProfileId: "508886882",
            CustomerProfileId: "500138464",
            UserMemberId: 151670,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151670,
        ParentPhoto: null,
      },
      {
        ParentName: "Dheekshitha D",
        ParentMemberNumber: "GE-03011907000000",
        TotalAmount: 10.0,
        CustomerProfileID: "505547000",
        PaymentGatewayPaymentProfileId: 113540,
        PaymentProfileID: "508719234",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Dheekshitha D",
            UserMemberId: 151600,
            TransactionId: "0",
            TransactionDate: "05/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147811,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100983,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113540,
            PaymentProfileId: "508719234",
            CustomerProfileId: "505547000",
            UserMemberId: 151600,
            MaskedCCNumber: "xxxx0015",
            ExpiryDate: "10/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 113703,
            PaymentProfileId: "508906597",
            CustomerProfileId: "505547000",
            UserMemberId: 151600,
            MaskedCCNumber: "xxxx3455",
            ExpiryDate: "BANK A/C",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151600,
        ParentPhoto: null,
      },
      {
        ParentName: "Dharani D",
        ParentMemberNumber: "GE-03311908000000",
        TotalAmount: 6.0,
        CustomerProfileID: "505550168",
        PaymentGatewayPaymentProfileId: 113550,
        PaymentProfileID: "508722703",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Dharani D",
            UserMemberId: 151605,
            TransactionId: "0",
            TransactionDate: "04/30/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147180,
            Amount: 2.0,
            StatusDescription: "In-Active",
            EntityId: 100925,
            ServiceName: "Addon_daily",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Dharani D",
            UserMemberId: 151605,
            TransactionId: "0",
            TransactionDate: "04/22/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147172,
            Amount: 2.0,
            StatusDescription: "In-Active",
            EntityId: 100925,
            ServiceName: "Addon_daily",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Dharani D",
            UserMemberId: 151605,
            TransactionId: "0",
            TransactionDate: "04/08/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147158,
            Amount: 2.0,
            StatusDescription: "In-Active",
            EntityId: 100925,
            ServiceName: "Addon_daily",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113550,
            PaymentProfileId: "508722703",
            CustomerProfileId: "505550168",
            UserMemberId: 151605,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "10/2023",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151605,
        ParentPhoto: null,
      },
      {
        ParentName: "Varun V",
        ParentMemberNumber: "GE-03251908000000",
        TotalAmount: 3.0,
        CustomerProfileID: "1517669342",
        PaymentGatewayPaymentProfileId: 113593,
        PaymentProfileID: "508756782",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Varun V",
            UserMemberId: 151624,
            TransactionId: "0",
            TransactionDate: "04/30/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147181,
            Amount: 1.0,
            StatusDescription: "In-Active",
            EntityId: 100943,
            ServiceName: "Daily",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Varun V",
            UserMemberId: 151624,
            TransactionId: "0",
            TransactionDate: "04/22/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147173,
            Amount: 1.0,
            StatusDescription: "In-Active",
            EntityId: 100943,
            ServiceName: "Daily",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
          {
            FullName: "Varun V",
            UserMemberId: 151624,
            TransactionId: "0",
            TransactionDate: "04/08/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147159,
            Amount: 1.0,
            StatusDescription: "In-Active",
            EntityId: 100943,
            ServiceName: "Daily",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113593,
            PaymentProfileId: "508756782",
            CustomerProfileId: "1517669342",
            UserMemberId: 151624,
            MaskedCCNumber: "xxxx0012",
            ExpiryDate: "06/2023",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 47,
            PaymentProfileId: "1516565276",
            CustomerProfileId: "1517669342",
            UserMemberId: 151624,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "03/2027",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151624,
        ParentPhoto: null,
      },
      {
        ParentName: "Thanuja T",
        ParentMemberNumber: "GE-01011907000000",
        TotalAmount: 10.0,
        CustomerProfileID: "505655820",
        PaymentGatewayPaymentProfileId: 113699,
        PaymentProfileID: "508887018",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Thanuja T",
            UserMemberId: 151671,
            TransactionId: "0",
            TransactionDate: "03/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147784,
            Amount: 10.0,
            StatusDescription: "In-Active",
            EntityId: 100980,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113699,
            PaymentProfileId: "508887018",
            CustomerProfileId: "505655820",
            UserMemberId: 151671,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "08/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 151671,
        ParentPhoto: null,
      },
      {
        ParentName: "Baskar B",
        ParentMemberNumber: "GE-12012107000000",
        TotalAmount: 5.0,
        CustomerProfileID: "504581872",
        PaymentGatewayPaymentProfileId: 73441,
        PaymentProfileID: "507355746",
        ProfileStatus: "",
        Color: "",
        CurrentTransactionStatus: null,
        IsNeedToCharge: true,
        RelatedDeclinedTransactions: [
          {
            FullName: "Baskar B",
            UserMemberId: 101547,
            TransactionId: "0",
            TransactionDate: "03/01/2019",
            MemberPlanStatus: 0,
            SubscriptionStatus: 5,
            ServiceStatus: 0,
            PackageStatus: 0,
            PaymentGatewayTransactionHistoryId: 147788,
            Amount: 5.0,
            StatusDescription: "In-Active",
            EntityId: 100982,
            ServiceName: "Addon_Monthly",
            PaymentMadeFor: 16,
            PaymentMadeForDescription: "Add-On",
          },
        ],
        PaymentProfiles: [
          {
            PaymentGatewayPaymentProfileId: 113700,
            PaymentProfileId: "508904263",
            CustomerProfileId: "504581872",
            UserMemberId: 101547,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "06/2024",
            ProfileStatus: "",
            ShowAlert: false,
          },
          {
            PaymentGatewayPaymentProfileId: 73441,
            PaymentProfileId: "507355746",
            CustomerProfileId: "504581872",
            UserMemberId: 101547,
            MaskedCCNumber: "xxxx0002",
            ExpiryDate: "04/2023",
            ProfileStatus: "",
            ShowAlert: false,
          },
        ],
        ParentUserMemberId: 101547,
        ParentPhoto: null,
      },
    ],
    DeclinedTransactionsSummary: [
      {
        TotalAmount: 3980.0,
        MembersCount: 12,
        TransactionsCount: 25,
        PaymentMadeForDescription:
          "Membership (Suspended) System will not charge for these users",
      },
      {
        TotalAmount: 2620.0,
        MembersCount: 3,
        TransactionsCount: 3,
        PaymentMadeForDescription: "Membership (In-Active)",
      },
      {
        TotalAmount: 1325.0,
        MembersCount: 45,
        TransactionsCount: 91,
        PaymentMadeForDescription: "Add-Ons",
      },
      {
        TotalAmount: 600.0,
        MembersCount: 1,
        TransactionsCount: 1,
        PaymentMadeForDescription: null,
      },
      {
        TotalAmount: 125.0,
        MembersCount: 1,
        TransactionsCount: 1,
        PaymentMadeForDescription: "One-on-One",
      },
    ],
    TotalAmount: 4070.0,
  };

  const [result, setResult] = React.useState(
    products.DeclinedTransactionsSummary
  );
  const [dataState, setDataState] = React.useState(
    products.DeclinedTransactionsSummary
  );

  const calculateTotal = () => {
    return dataState.reduce((partialSum, a) => partialSum + a.TotalAmount, 0);
  };
  const uuid = props?.match?.params?.uuid;

  return (
    <>
      <ReportsCommonFilters
        {...props}
        showDate={false}
        history={props.history}
        location={props.location}
      />
      <div className="mt-4 customer-main-container">
        <div className="d-flex justify-content-end customer-close-icon">
          <li
            className="k-icon k-i-close"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
        <div className="d-flex customer-sub-header">
          <h2 className="customer-header">Manage Declines</h2>
        </div>
        <ManageDeclines declinedData={products.DeclinedTransactions} />
        <div className="customer-grid-border">
          <Grid
            data={result}
            {...dataState}
            sortable={true}
            pageable={false}
          >
            <Column field="PaymentMadeForDescription" title="Services" />
            <Column field="MembersCount" title="Clients" />
            <Column field="TransactionsCount" title="Transactions" />
            <Column
              field="TotalAmount"
              title="Amount"
              footerCell={(props) => (
                <BuzopsButton label={`$ ${calculateTotal()}`} />
              )}
            />
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Deliquency;
