import React from "react";
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { IMemberPassportAccessProps } from "../Utils/interfaces";
import { Button } from "@progress/kendo-react-buttons";
import {
  PassportCredentialStatusType,
  PassPortCrendentialType,
  PassportCrendentialOptions,
} from "../Utils/constants";
import { ListView } from "@progress/kendo-react-listview";
import History from "./History";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GetLocalStore } from "utils/storage";
import usePassportMemberCredentials from "../Container/PassportMemberCredentials.hook";

const PassportMemberCredential: React.FunctionComponent<
  IMemberPassportAccessProps
> = (props) => {
  const isAdminOrStaff = [1, 8].includes(
    GetLocalStore("CurrentApplicationType")
  );

  const passportMemberCredential = usePassportMemberCredentials({ ...props });

  return (
    <>
      <div
        className={`bz-inhouse-group-events align-middle buz-online-list-view bz-groupServices-list-new group-serv-list bz-openPath-credintials ${
          !props?.StaffPage && !props?.Email && "cursor-not-allowed"
        }`}
        title={
          !props?.StaffPage && !props?.Email 
            ? "User must have a valid email to assign access card"
            : ""
        }
      >
        <div className=" row justify-content-between mobile-onsite-checkins">
          <div className="col-md-4 col-sm-6">
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <div className="bz-provider-name">
                    <span className="k-label">Name</span>
                    <h6 className="mb-0 mb-sm-2">
                      <ClientNameWithMemberType
                        memberType={props.MemberType}
                        clientName={props.FullName}
                      />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-6">
            <div className="row mb-2 openpathflex">
              <div className="col-md-6">
                <div className="section-2 mt-sm-4-openpath">
                  <div className="bz-provider-name">
                    <span className="bz-provider text-muted">Status</span>
                    <div>
                      <span
                        className={`bz-access-credintials-status ${passportMemberCredential
                          .getAccessCredentialStatus()
                          .replace(/\s/g, "")
                          .toLowerCase()}`}
                      >
                        <img
                          src={passportMemberCredential.getStatusICon(
                            passportMemberCredential.getAccessCredentialStatus()
                          )}
                          alt="status"
                        />
                        {passportMemberCredential.getAccessCredentialStatus()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 credentialopenpath mt-1 mt-md-0">
                <div>
                  <DropDownList
                    defaultValue={
                      passportMemberCredential.selectedCredentialType
                    }
                    textField="text"
                    dataItemKey="id"
                    label="Credential Type"
                    data={[
                      {
                        id: -1,
                        text: "Select",
                      },
                      ...PassportCrendentialOptions,
                    ]}
                    value={passportMemberCredential.selectedCredentialType}
                    onChange={(e) => {
                      passportMemberCredential.setSelectedCredentialType(
                        e.target.value
                      );
                      passportMemberCredential.setError("");
                      passportMemberCredential.setCardId("");
                      passportMemberCredential.setCardNumber("");
                      passportMemberCredential.setFacilityCode(-1);
                    }}
                    disabled={(props.PassportTechCardType && props?.CardNumber) || !isAdminOrStaff}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" row justify-content-between mobile-onsite-checkins openpath-credintials">
          <div className="col-md-8 col-sm-6 order-1 order-sm-2">
            <div className="row">
              {passportMemberCredential.selectedCredentialType?.id ===
                PassPortCrendentialType.Card_LF_Weigand_H10301 && (
                <div className="col-md-6 bz-provider-name">
                  <div
                    className="d-flex align-items-center mb-2"
                    style={{ lineHeight: 1 }}
                  >
                    <span className="k-label bz-label-mb-0">Facility Code</span>
                    <BuzopsTooltip
                      position={"top"}
                      html={
                        <span
                          title={`A number between 0 to 255`}
                          className="bz-cursor-pointer"
                        >
                          <i
                            style={{ fontSize: "16px", marginLeft: "5px" }}
                            className="fa fa-info-circle"
                          ></i>
                        </span>
                      }
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      className="form-control k-textbox"
                      value={
                        passportMemberCredential.facilityCode === -1
                          ? ""
                          : passportMemberCredential.facilityCode
                      }
                      min={0}
                      max={255}
                      onChange={(e: any) => {
                        passportMemberCredential.setFacilityCodeError("");
                        passportMemberCredential.setFacilityCode(
                          Math.min(e.target.value || 0, 255)
                        );
                      }}
                      disabled={props.FacilityCode !== null || !isAdminOrStaff}
                      style={{ maxWidth: "250px", width: "100%" }}
                    />
                    {passportMemberCredential.facilityCodeError && (
                      <p className="k-form-error">
                        {passportMemberCredential.facilityCodeError}
                      </p>
                    )}
                  </div>
                </div>
              )}
              {passportMemberCredential.selectedCredentialType?.id !== -1 && (
                <div className="col-md-6 bz-provider-name mt-2 mt-md-0">
                    <div
                      className="d-flex align-items-center mb-2"
                      style={{ lineHeight: 1 }}
                    >
                      <span className="k-label bz-label-mb-0">Card Number</span>
                      <BuzopsTooltip className="hrll"
                        position={"right"}
                        html={
                          <span
                            title={passportMemberCredential.selectedCredentialType
                              ?.id ===
                              PassPortCrendentialType.Card_LF_Weigand_H10301 ? `A number between 0 to 65535`:`Scan or Enter the Passport Card Number (An 18 character hex string containing characters: 0-9 or A-F)`}
                            className="bz-cursor-pointer"
                          >
                         
                              <i
                                style={{
                                  fontSize: "16px",
                                  marginLeft: "5px",
                                }}
                                className="fa fa-info-circle"
                              ></i>
                       
                          </span>
                        }
                      />
                    </div>
                  <input
                    type={
                      passportMemberCredential.selectedCredentialType?.id ===
                      PassPortCrendentialType.Card_LF_Weigand_H10301
                        ? "number"
                        : "text"
                    }
                    className="form-control k-textbox"
                    value={
                      passportMemberCredential.selectedCredentialType?.id ===
                      PassPortCrendentialType.Card_LF_Weigand_H10301
                        ? passportMemberCredential.cardId
                        : passportMemberCredential.cardNumber
                    }
                    maxLength={
                      passportMemberCredential.selectedCredentialType?.id ===
                      PassPortCrendentialType.Card_HF_PassportTech
                        ? 18
                        : 30
                    }
                    onChange={(e: any) => {
                      passportMemberCredential.setError("");
                      passportMemberCredential.selectedCredentialType?.id ===
                      PassPortCrendentialType.Card_LF_Weigand_H10301
                        ? passportMemberCredential.setCardId(
                            Math.min(e.target.value || 0, 65535).toString()
                          )
                        : passportMemberCredential.setCardNumber(
                            e.target.value
                          );
                    }}
                    disabled={
                     (props.CardNumber !== null || props.CardId !== null) || !isAdminOrStaff
                    }
                    style={{ maxWidth: "250px", width: "100%" }}
                  />
                  {passportMemberCredential.error && (
                    <p className="k-form-error">
                      {passportMemberCredential.error}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          {isAdminOrStaff && (
            <div className="col-12">
              <hr style={{ marginBottom: "0.75rem" }} />
            </div>
          )}

          <div
            className="col-12 bz-openPath-actions"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {!props?.StaffPage && !props?.Email && (
              <div className="mr-auto text-danger mb-3 mb-sm-0">
                User must have a valid email to assign access card.
              </div>
            )}
            {isAdminOrStaff && (
              <div className="bz-checkins-actions-container">
                {passportMemberCredential.getAccessCredentialStatus() ===
                  PassportCredentialStatusType.NoAccess && (
                  <Button
                    primary={true}
                    iconClass="fa fa-plus"
                    className="k-button-sm"
                    onClick={() => {
                      passportMemberCredential.selectedCredentialType !== -1 &&
                        passportMemberCredential.createPassportCredential();
                    }}
                    disabled={
                      (!props?.CanDisableEditAndActivate || props?.StaffPage) &&                passportMemberCredential.selectedCredentialType?.id === -1
                    }
                  >
                    Assign
                  </Button>
                )}
                {props.IsSuspended && (
                  <BuzopsTooltip
                  position={"left"}
                    html={
                      <span
                        style={{
                          margin: "0rem 0.5rem",
                          color: "skyblue",
                          cursor:
                            passportMemberCredential.getAccessCredentialStatus() ===
                              PassportCredentialStatusType.Suspended &&
                            (!props?.CanDisableEditAndActivate || props?.StaffPage)
                              ? "pointer"
                              : "not-allowed",
                        }}
                        title="Activate Access"
                        className={`bz-cursor-pointer ${
                          passportMemberCredential.getAccessCredentialStatus() ===
                            PassportCredentialStatusType.Suspended &&
                          (!props?.CanDisableEditAndActivate || props?.StaffPage)
                            ? ""
                            : "disabled"
                        }`}
                        onClick={() =>
                          passportMemberCredential.getAccessCredentialStatus() ===
                            PassportCredentialStatusType.Suspended &&
                          (!props?.CanDisableEditAndActivate || props?.StaffPage) &&
                          passportMemberCredential.updateMemberHardwareAccess()
                        }
                      >
                        <i
                          style={{ fontSize: "16px" }}
                          className="fa fa-check"
                        ></i>
                      </span>
                    }
                  />
                )}

                {!props.IsSuspended && (
                  <BuzopsTooltip
                  position={"left"}
                    html={
                      <span
                        style={{
                          margin: "0rem 0.5rem",
                          color: "green",
                          cursor:
                            (passportMemberCredential.getAccessCredentialStatus() ===
                              PassportCredentialStatusType.Active) &&
                            (!props?.CanDisableEditAndActivate || props?.StaffPage)  && (props.PassportTechCardType && props?.CardNumber)
                              ? "pointer"
                              : "not-allowed",
                        }}
                        title="Access will be suspended temporarily"
                        className={`bz-cursor-pointer ${
                          (passportMemberCredential.getAccessCredentialStatus() ===
                            PassportCredentialStatusType.Active) &&
                          (!props?.CanDisableEditAndActivate || props?.StaffPage)  && (props.PassportTechCardType && props?.CardNumber)
                            ? ""
                            : "disabled"
                        }`}
                        onClick={() =>
                          (passportMemberCredential.getAccessCredentialStatus() ===
                            PassportCredentialStatusType.Active) &&
                          (!props?.CanDisableEditAndActivate || props?.StaffPage) &&
                          passportMemberCredential.updateMemberHardwareAccess()
                        }
                      >
                        <i
                          style={{ fontSize: "16px" }}
                          className="fa fa-stop-circle"
                        ></i>
                      </span>
                    }
                  />
                )}
                <BuzopsTooltip
                 position={"left"}
                  html={
                    <span
                      style={{
                        margin: "0rem 0.5rem",
                        color: "crimson",
                        cursor:
                          passportMemberCredential.getAccessCredentialStatus() !==
                            PassportCredentialStatusType.NoAccess &&
                          (!props.CanDisableRemoveAccess || props?.StaffPage)
                            ? "pointer"
                            : "not-allowed",
                      }}
                      title="Remove Access"
                      className={`bz-cursor-pointer ${
                        passportMemberCredential.getAccessCredentialStatus() !==
                          PassportCredentialStatusType.NoAccess &&
                        (!props.CanDisableRemoveAccess || props?.StaffPage)
                          ? ""
                          : "disabled"
                      }`}
                      onClick={() =>
                        passportMemberCredential.getAccessCredentialStatus() !==
                          PassportCredentialStatusType.NoAccess &&
                        (!props.CanDisableRemoveAccess || props?.StaffPage) &&
                        passportMemberCredential.removePassportAccessCredential()
                      }
                    >
                      <i className="fa fa-trash"></i>
                    </span>
                  }
                />
              </div>
            )}
            {!props?.StaffPage ? 
            <div className="ml-3 mr-3 section-3 d-flex align-items-center">
              <span
                className="bz-cursor-pointer p-0 d-flex align-items-center"
                onClick={() =>
                  passportMemberCredential.getAuditLogs(
                    !passportMemberCredential.showHistory
                  )
                }
              >
                Audit &nbsp;
                <i
                  className={
                    passportMemberCredential.showHistory
                      ? "fas fa-angle-up"
                      : "fas fa-angle-down"
                  }
                ></i>
              </span>
            </div> : null}
          </div>
        </div>

        {passportMemberCredential?.showHistory && !props?.StaffPage && (
          <>
            <div className="row">
              <div className="col-12">
                {passportMemberCredential.auditLogs.length > 0 ? (
                  <ListView
                    item={History}
                    data={passportMemberCredential.auditLogs || []}
                  />
                ) : (
                  <div>
                    <span>No Audit History</span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PassportMemberCredential;
