import { useEffect, useState } from "react";
import { reportFilters } from "../../reportsCommonFilters";
import { ReportsService } from "services/reports/index.service";
import moment from "moment";
import { ServiceLogColumns } from "../Utilities/constants";

const useServiceLog = () => {
  const [pageInfo, setPageInfo] = useState({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });
  const [excelData, setExcelData] = useState([]);
  const [stateGridColumns, setStateGridColumns] = useState(ServiceLogColumns);
  const service = new ReportsService();

  const [fromDateToDate, setFromDateToDate] = useState({
    from: moment().startOf("year").startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });

  const fetchAttendanceData = async (pageData?: any, forExcel = false) => {
    let req = {
      pageSize: forExcel ? pageInfo.total : pageData?.dataState?.take || 10,
      pageNumber: forExcel
        ? 0
        : pageData?.dataState?.skip
        ? pageData?.dataState.skip / pageData?.dataState.take
        : 0,
      SortBy: pageData?.sort?.length > 0 ? pageData.sort[0].field : "FullName",
      SortDirection: pageData?.sort?.length > 0 ? pageData.sort[0].dir : "asc",
      FromDate: pageData?.fromDate || fromDateToDate.from,
      ToDate: pageData?.toDate || fromDateToDate.to,
    };

    const getData = await service.getServiceLog(req);

    if (forExcel) {
      setExcelData(getData.Item.Result);
    } else {
      setPageInfo((prev) => {
        prev.data = getData.Item.Result;
        prev.pageNumber = req.pageNumber;
        prev.total = getData.Item.TotalItems;
        if (pageData?.dataState?.take) {
          prev.skip = pageData?.dataState.skip;
          prev.take = pageData?.dataState.take;
        }
        return { ...prev };
      });
    }
  };

  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };

  useEffect(() => {
    fetchAttendanceData({
      fromDate: fromDateToDate.from,
      toDate: fromDateToDate.to,
    });
  }, [fromDateToDate]);

  const filterText = reportFilters?.ThisMonth;
  return {
    onColumnsSubmit,
    filterText,
    pageInfo,
    stateGridColumns,
    excelData,
    setExcelData,
    setStateGridColumns,
    fetchAttendanceData,
    setFromDateToDate,
  };
};
export default useServiceLog;
