interface ICloseReport {
  history: any;
  uuid: string;
}

const CloseReport: React.FunctionComponent<ICloseReport> = ({
  history,
  uuid,
}) => {
  return (
    <div className="d-flex justify-content-end customer-close-icon">
      <li
        className="k-icon k-i-close"
        onClick={() => {
          history.push(`/app/reports/${uuid}`);
        }}
      ></li>
    </div>
  );
};

export default CloseReport;
