import React from "react";
import {
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";

export const CustomColumnMenu = (props: any) => {
  console.log("Data .......", props)
  const [columnsExpanded, setColumnsExpanded] = React.useState(false);

  const onFilterExpandChange = (value: any) => {
    setColumnsExpanded(value ? false : columnsExpanded);
  };

  return (
    <div>
      <GridColumnMenuFilter
        {...props}
        onExpandChange={onFilterExpandChange}
        expanded={true}
      />
    </div>
  );
};
