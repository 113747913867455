import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import BuzopsButton from "generic-components/BuzopsButton";
import React from "react";
import { UnFreezeChargeTypes } from "utils/form-utils";

const AddonUnfreeze = (props: any) => {
  const {
    MemberName,
    SubscribedTo,
    UnfreezeComment,
    ChargeType,
    ProratedRate,
  } = props?.unfreezeDetails;
  return (
    <>
      <div className="card card-fluid bz-sub-card mb-2 bz-card bz-cancel-package">
        <div className="card-header py-4 bz-no-background">
          <div className="bz-row no-gutters">
            <div className="col-12 col-md-6 p-0 mb-sm-0">
              <span className="h6 bz-border-Inactive-sm bz--left-border pl-2">
                <span className="bz-card-heading text-capitalize">{`${SubscribedTo}`}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="card-body p-0 position-relative">
          <div className="bz-row no-gutters">
            <div className="col">
              <div className="p-4">
                <div className="">
                  <div className="bz-row no-gutters mb-3">
                    <div className="col-12 col-lg-12">
                      <p className="row">
                        <span className="col bz-article">Addon Name</span>:
                        <span className="col">{SubscribedTo}</span>
                      </p>
                    </div>
                    <div className="col-12 col-lg-12">
                      <p className="row">
                        <span className="col bz-article">Client Name</span>:
                        <span className="col">{MemberName}</span>
                      </p>
                    </div>
                    <div className={"bz-row no-gutters mb-4 pb-5"}>
                      <div className="form-group col-12 pr-3 bz-payment-profile-options">
                        <div className="bzc-provider-name">
                          <label className="mb-2 d-inline-block k-label">
                            Payment Profile
                          </label>
                          <div className="">
                            <DropDownList
                              id={`PaymentGatewayProfileId`}
                              name={`PaymentGatewayProfileId`}
                              data={props?.paymentProfiles}
                              textField="MaskedCCNumber"
                              valueRender={(
                                el: React.ReactElement<HTMLSpanElement>,
                                value: any
                              ) =>
                                PaymentValueRender(
                                  el,
                                  value,
                                  props?.selectedPaymentProfile
                                )
                              }
                              itemRender={PaymentItemRender}
                              onChange={(e) => {
                                props.handleUnfreezeChange(
                                  e.value,
                                  "paymentProfile"
                                );
                              }}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 pr-3 bz-payment-profile-options">
                        <div className="bzc-provider-name">
                          <label className="mb-2 d-inline-block k-label">
                            Charge Type
                          </label>
                          <div className="">
                            <DropDownList
                              data={UnFreezeChargeTypes}
                              textField="text"
                              dataItemKey="id"
                              value={
                                UnFreezeChargeTypes?.filter(
                                  (i: any) => i?.id === ChargeType
                                )?.[0]
                              }
                              onChange={(e) =>
                                props?.handleUnfreezeChange(
                                  e?.value?.id,
                                  "ChargeType"
                                )
                              }
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                      {ChargeType === 0 && (
                        <div className="form-group col-12 pr-3 bz-payment-profile-options">
                          <div className="bzc-provider-name">
                            <label className="mb-2 d-inline-block k-label">
                              Down Payment
                            </label>
                            <div className="">
                              <NumericTextBox
                                id={`ProratedRate`}
                                name={`ProratedRate`}
                                min={0}
                                disabled={true}
                                format={"c2"}
                                className="k-dropdown-width-100"
                                value={ProratedRate}
                                onChange={(e) =>
                                  props?.handleUnfreezeChange(
                                    e.target.value,
                                    "ProratedRate"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="form-group col-12 pr-3 bz-payment-profile-options">
                        <div className="bzc-provider-name">
                          <label className="mb-2 d-inline-block k-label">
                            Comments
                          </label>
                          <div className="">
                            <textarea
                              className="form-control"
                              onChange={(e) =>
                                props?.handleUnfreezeChange(
                                  e.target.value,
                                  "UnfreezeComment"
                                )
                              }
                              value={UnfreezeComment}
                            ></textarea>
                          </div>
                        </div>
                        {props?.showErrorMessage("UnfreezeComment", "Comments")}
                      </div>
                      <div className="row no-gutters">
                        <div className="col-sm-12 mt-2">
                          <div className="float-right">
                            <BuzopsButton
                              loading={props?.btnLoading}
                              disabled={props?.btnLoading}
                              label={"UnFreeze"}
                              onClick={() => props?.handleUnfreeze()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddonUnfreeze;
