import ReportsCommonFilters from "../../reportsCommonFilters";
import BuzopsReportGrid from "generic-components/BuzopsReportGrid";
import useServiceLog from "../Container/ServiceLog.hook";

const ServiceLog = (props: any) => {
  const uuid = props?.match?.params?.uuid;
  const serviceLog = useServiceLog();

  return (
    <div>
      <ReportsCommonFilters
        {...props}
        history={props.history}
        location={props.location}
        setFromDateToDate={serviceLog.setFromDateToDate}
      />
      <div className="mt-4 customer-main-container">
        <div className="d-flex justify-content-end customer-close-icon">
          <li
            className="k-icon k-i-close"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
        <div className="d-flex customer-sub-header">
          <h2 className="customer-header">Attendance - Service Log</h2>
        </div>
        <div className="customer-grid-border">
          <BuzopsReportGrid
            fileName="ServiceLog.xlsx"
            result={serviceLog.pageInfo.data}
            dataState={{ ...serviceLog.pageInfo }}
            dataStateChange={(e: any, forExcel: boolean) =>
              serviceLog.fetchAttendanceData(e, forExcel)
            }
            stateGridColumns={serviceLog.stateGridColumns}
            pageSize={10}
            setStateGridColumns={(data: any) =>
              serviceLog.setStateGridColumns(data)
            }
            onColumnsSubmit={serviceLog.onColumnsSubmit}
            excelData={serviceLog.excelData}
            setExcelData={() => serviceLog.setExcelData([])}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceLog;
