/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { getFullImageUrl } from "utils";

export const scheduleValueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: any,
  selectedValue: any
) => {
  let children = [<></>];
  if (selectedValue) {
    children = [
      <div className={"row align-items-center w-100 test-1"}>
        <div className={"col-3 text-center "}>
          {selectedValue?.TrainerPhoto ? (
            <img
              style={{ borderRadius: "20px" }}
              width={38}
              src={getFullImageUrl(selectedValue?.TrainerPhoto)}
            />
          ) : (
            <img width={38} src="/images/user.png" />
          )}
        </div>
        <div className={"col-9 pl-2 pr-0"}>
          <span key={1} className={""}>
            <h6 className="mb-0">{`${selectedValue?.TrainerName}`}</h6>
            <span className="d-block">{selectedValue.ShortFromDate}</span>
            <span>{`${selectedValue.ScheduleName}`}</span>
          </span>
        </div>
      </div>,
    ];
  }

  return React.cloneElement(element, { ...element.props }, children);
};

export const scheduleItemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
  selectedValue: any
) => {
  const itemChildren = (
    <div className={"row align-items-center w-100 bz-pickSchedule-drop-item"}>
      <div className={"col-3 text-center "}>
        {itemProps.dataItem.TrainerPhoto ? (
          <img
            style={{ borderRadius: "20px" }}
            width={38}
            src={getFullImageUrl(itemProps.dataItem.TrainerPhoto)}
          />
        ) : (
          <img width={38} src="/images/user.png" />
        )}
      </div>
      <div className={"col-9 px-0 "}>
        <span
          style={
            selectedValue?.ScheduleId === itemProps.dataItem.ScheduleId
              ? { color: "#000" }
              : {}
          }
        >
          <h6 className="mb-0">{`${itemProps.dataItem?.TrainerName}`}</h6>
          <span className="d-block">{itemProps.dataItem.ShortFromDate}</span>
          <span>{` ${itemProps.dataItem.ScheduleName}`}</span>
        </span>
      </div>
    </div>
  );

  return React.cloneElement(li, li.props, itemChildren);
};
