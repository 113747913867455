import { createSlice } from '@reduxjs/toolkit'

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    data: [],
    lastItem: null,
    fieldTemplates:[]
  },
  reducers: {
    AddElementInForm: (state, action) => {
      if(action.payload){
        const elementVal:any= [...state.data, action.payload]
        state.data = elementVal
      }
    },
    ClearForm: (state) => {
      state.data = []
      state.lastItem = null
    },
    UpdateFormTemplate: (state,action) => {
      state.data = action.payload
      state.lastItem = null
    },
    UpdateOrder: (state, action) => {
      const newData = action.payload?.filter((x:any) => x && !x?.parentId);
      const checkData= action.payload.filter((x:any) => x && x.parentId)
      checkData?.forEach((x:any) => newData.push(x));
      state.data = newData
    },
    DeleteFormElement: (state, action) => {
      const newData =  [...state.data]
      const findIndex=newData.findIndex((i:any)=> i?.id === action?.payload?.id)
      newData.splice(findIndex, 1);
      state.data = newData
    },
    insertFormElement: (state, action) => {
      state.lastItem =  action.payload.isContainer ? null : action.payload
      return state;
    },
    DeleteLastFormElement: (state, action) => {
      const { lastItem } = state;
      if(lastItem){
        const newData =  [...state.data]
        const findIndex=newData.findIndex((i:any)=> i?.id === lastItem?.id)
        newData.splice(findIndex, 1);
        state.data = newData
        state.lastItem = null
      }
    },
    ResetLastFormElement: (state, action) => {
      const { lastItem } = state;
      if(lastItem){
        state.lastItem = null
      }
    },
    saveFieldTemplates:(state, action) => {
      state.fieldTemplates = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  AddElementInForm,
  UpdateOrder,
  DeleteFormElement,
  insertFormElement,
  DeleteLastFormElement,
  ResetLastFormElement,
  ClearForm,
  UpdateFormTemplate,
  saveFieldTemplates
} = formSlice.actions

export default formSlice.reducer