import { Button } from "@progress/kendo-react-buttons";
import { ListView } from "@progress/kendo-react-listview";
import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { useApiClient } from "services/axios-service-utils";
import { PageChangeEvent, Pager } from "@progress/kendo-react-data-tools";
import { CurrencyFormat, getTimeFormat } from "utils";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { PaymentMadeForItems } from "utils/form-utils";
import moment from "moment";
import CustomCalendar from "atoms/CustomCalendar";
import { BsCCircleFill } from "react-icons/bs";

const CreditActivity = (props: any) => {
  const { axiosRequest } = useApiClient();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({
    skip: 0,
    take: 5,
  });
  const { skip, take } = page;
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState<any>([]);
  const [fromDateToDate, setFromDateToDate] = useState({
    from: moment()
      .startOf("isoWeek")
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const [dateFilter, setDateFilter] = useState("This Week");
  useEffect(() => {
    fetchCreditActivity();
  }, [fromDateToDate]);

  const CreditActvityList = (propdata: any) => {
    console.log(propdata?.dataItem);
    const item = propdata.dataItem;
    const paymentMadeItem = PaymentMadeForItems.filter(
      (i: any) => i?.id === item?.PaymentMadeFor
    )?.[0];
    return (
      <div className="py-3 px-2 align-middle buz-online-list-view credit-activity-list pe-4 ">
      <div className="section-1">
        <div className="bz-main-provider">
          <div className="bz-provider-name">
            <h6 style={{ textAlign: "center" }}>
              <span className="">
                <BuzopsTooltip
                  html={
                    <BsCCircleFill
                      style={{ fontSize: "1rem", color: "#008db9" }}
                    />
                  }
                />
              </span>
            </h6>
            <span
              className="bz-provider "
              style={{
                textTransform: "capitalize",
                justifySelf: "center",
              }}
            >
              Credit
            </span>
          </div>
        </div>
      </div>
      <div className="section-2">
        <div className="bz-main-provider">
          <div className="bz-provider-name">
            <h6>{item?.Notes}</h6>
            <span
              className="bz-provider "
              style={{ textTransform: "capitalize" }}
            >
              {moment(item?.TransactionDate).format("DD MMMM YYYY")}
              ,&nbsp;
              {getTimeFormat(item?.TransactionDate)}
            </span>
          </div>
        </div>
      </div>
      <div className="section-3">
        <div className="bz-main-provider">
          <div className="bz-provider-name">
            <span
              style={{
                display: "block",
                color: item?.PaymentModeId === 15 ? "red" : "green",
              }}
            >
              {item?.PaymentModeId === 15 ? "-" : "+"}
              {` `}
              {CurrencyFormat(item?.AmountPaid)}
            </span>
            <span className="bz-provider">
              Balance: {CurrencyFormat(item?.UpdatedBalance)}
            </span>
          </div>
        </div>
      </div>
    </div>
    );
  };

  const fetchCreditActivity = async (Skip = 0, Take = 5) => {
    setLoading(true);
    const payload = {
      UserMemberId: props?.UserMemberId,
      PageNumber: Skip / Take,
      PageSize: Take,
      FromDate: fromDateToDate.from,
      ToDate: fromDateToDate.to,
      SortBy: "TransactionDate",
      SortDirection: "DESC",
    };
    const errorCallBack = (message: string) => {
      props?.handleNotificationMessage(message, "error");
    };

    await axiosRequest.post("payment/credittransactions", payload, {
      successCallBack: (response: any) => {
        console.log(response?.data?.Items);
        setData(response?.data?.Items || []);
        setTotalItems(response?.data?.TotalItems || 0);
        setLoading(false);
      },
      errorCallBack: (response: any) => {
        console.log(response?.response, "response");
        errorCallBack(
          response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] ||
            `Error occured while adding credits to wallet`
        );
      },
    });
    setLoading(false);
  };
  const handlePageChange = async (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
    fetchCreditActivity(e.skip, e.take);
  };
  return (
    <div>
      <div>
        <h5 className="mb-3 bz_fs_1_5">
          <Button
            className="btn btn-primary bz-back-btn btn-sm back-arrow-btn ml-0"
            onClick={() => props?.handleCloseDialog()}
          >
            <i className="fa fa-arrow-left"></i>
          </Button>
          &nbsp;Credits Activity
        </h5>
      </div>
      <hr />

      <div>
        <CustomCalendar
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          key={"no change"}
          setFromDateToDate={setFromDateToDate}
          selectedDateItem={dateFilter}
          // setDateFilter={setDateFilter}
          onlyPastDates
        />
        {loading ? (
          <BuzopsLoader type="list" />
        ) : (
          <>
            {data?.length > 0 ? (
              <ListView
                item={CreditActvityList}
                data={data || []}
                style={{
                  width: "100%",
                }}
                className="bz-credit-activity mt-md-2"
              />
            ) : (
              <div className="bz-norecords-available">
                No credit activity available
              </div>
            )}
            {totalItems > 5 ? (
              <Pager
                className="k-listview-pager-chargeback"
                skip={skip}
                responsive={true}
                // size={'small'}

                take={take}
                onPageChange={handlePageChange}
                total={totalItems}
                pageSizes={[5, 10, 20, 50]}
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default CreditActivity;
