import { Upload } from "@progress/kendo-react-upload";
import React, { useState } from "react";
import BuzopsNotification from "generic-components/BuzopsNotification";

const UploadSign = (props: any) => {
    const [uploadedFiles, setUploadedFiles] = useState<any>([]);
    const [message, setMessage] = useState("");
    const [notification, setNotification] = useState({
        error: false,
        success: false,
      });
    
    const getBase64 = (file: any, cb: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
        };
    }
    const onAdd = (event: any) => {
        const rawFile=event.affectedFiles[0].getRawFile();
        const size=100001 //kb
        if(rawFile.type ==='image/png' || rawFile.type ==='image/jpg' || rawFile.type ==='image/jpeg'){
            if(rawFile.size < size){
                getBase64(event.affectedFiles[0].getRawFile(), (result: any) => {
                    props.getImage(result);
                });
                setUploadedFiles([])
            }else{
                const errorMsg = `File size should be less than 100KB`;
                setMessage(errorMsg);
                setNotification({ ...notification, success: false, error: true });
            }
        }else{
            const errorMsg = `Only .jpg, .png,.jpeg extensions allowed`;
            setMessage(errorMsg);
            setNotification({ ...notification, success: false, error: true });
        }
    }
      // for notifications
  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

    const onRemove = (event: any) => {

        setUploadedFiles([])
    }

    const onProgress = (event: any) => {

        setUploadedFiles([])
    }

    const onStatusChange = (event: any) => {

        setUploadedFiles([])
    }
    return (
        <div className={"form-group d-flex align-items-center justify-content-center"}>
            <div className={"col-md-10 text-center"}>
                <div className={"buzops-logos-bg signature-upload"}>
                    <img id="imageHolder" src="https://app.oorwin.com/assets/images/spa_newui/upload_document.svg" alt="Upload File" className="img-responsive mb-2" />
                    <Upload
                        accept=".jpg, .png,.jpeg"
                        batch={false}
                        restrictions={{
                                allowedExtensions: [ '.jpg', '.png','.jpeg' ]
                            }}
                        multiple={false}
                        files={uploadedFiles}
                        autoUpload={false}
                        withCredentials={false}
                        onAdd={onAdd}
                        />
                </div>
                <small style={{color: "red"}}>Allowable:png, jpg, jpeg and Size should be less than 100KB</small>
            </div>
            <BuzopsNotification
                notification={notification}
                message={message}
                handleNotification={(type: any) => handleNotification(type)}
            />
        </div>
    )
}
export default UploadSign;