import {
  SubscriberStatusTypes,
  SubscriptionTypes,
} from "components/reports/Generic/Utilities/constants";
import SubsciberReport from "../../Generic/Components/SubscriberReport";

const Plan = (props: any) => {
  const availableStatus = [
    {
      statusType: SubscriberStatusTypes.active,
      description: "Number of clients with an active membership currently",
      statusName: "ACTIVE",
    },
    {
      statusType: SubscriberStatusTypes.inActive,
      description:
        "Number of clients whose memberships is/are left unpaid for consecutive months with no further notice of continuing the Service",
      statusName: "IN ACTIVE",
    },
    {
      statusType: SubscriberStatusTypes.frozen,
      description: "Number of clients whose memberships is on freeze",
      statusName: "FROZEN",
    },
    {
      statusType: SubscriberStatusTypes.expired,
      description:
        "Number of clients who have not renewed to the membership after its expiry",
      statusName: "EXPIRED",
    },
    {
      statusType: SubscriberStatusTypes.cancelled,
      description:
        "Number of clients who cancelled membership before its expiry and discontinued the Service",
      statusName: "CANCELLED",
    },
    {
      statusType: SubscriberStatusTypes.aboutToExpire,
      description: "Number of clients whose plan is about to expire",
      statusName: "ABOUT TO EXPIRE",
    },
    {
      statusType: SubscriberStatusTypes.suspended,
      description:
        "Number of clients whose account suspended due to two consecutive declined payments",
      statusName: "SUSPENDED",
    },

    {
      statusType: SubscriberStatusTypes.future,
      description:
        "Number of clients who have paid but yet to avail Add-On and customers who will pay in future but already subscribed to a One-on-One",
      statusName: "FUTURE",
    },
  ];

  const SubsriberReportColumns = [
    {
      title: "Name",
      field: "MemberName",
      show: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Email",
      field: "Email",
      show: true,
      filter: "text",
      width: "250px",
    },
    {
      title: "Phone",
      field: "phone",
      show: true,
      filter: "text",
      width: "150px",
    },

    {
      title: "Membership Name",
      field: "SubscriptionName",
      show: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Start Date",
      field: "StartDate",
      show: true,
      filter: "date",
      width: "150px",
    },
    {
      title: "Signed Up Date",
      field: "signedUpDate",
      show: true,
      filter: "date",
      width: "170px",
    },
    {
      title: "Signed Up By",
      field: "signedUpBy",
      show: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Amount",
      field: "amount",
      show: true,
      filter: "numeric",
      width: "150px",
    },
    {
      title: "Status",
      field: "FormattedStatus",
      show: true,
      enableHtml: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Last Payment Date",
      field: "LastTransactionDate",
      show: false,
      filter: "date",
      width: "200px",
    },
    {
      title: "Last Payment Amount",
      field: "LastTransactionAmount",
      show: false,
      filter: "numeric",
      width: "250px",
    },
    {
      title: "Next EFT Amount",
      field: "RecurringAmount",
      show: false,
      filter: "numeric",
      width: "200px",
    },
    {
      title: "Next EFT Date",
      field: "NextRecurrence",
      show: false,
      filter: "date",
      width: "200px",
    },
    {
      title: "Amount collected",
      field: "amountCollected",
      show: false,
      filter: "numeric",
      width: "200px",
    },
    {
      title: "No of Renewals",
      field: "RenewalCount",
      show: false,
      filter: "numeric",
      width: "200px",
    },
    {
      title: "Last Audit",
      field: "lastAudit",
      show: false,
      filter: "date",
      width: "150px",
    },
  ];
  return (
    <SubsciberReport
      reportName={"Memberships"}
      reportDescription={""}
      reportType={SubscriptionTypes.MembershipPlan}
      {...props}
      availableStatus={availableStatus}
      SubsriberReportColumns={SubsriberReportColumns}
    />
  );
};

export default Plan;
