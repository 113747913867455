import ReportsCommonFilters from "../../reportsCommonFilters";
import BuzopsReportGrid from "generic-components/BuzopsReportGrid";
import StatisticsDetail from "./StatisticsDetail";
import StatisticChart from "./StatisticChart";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import useStatistics from "../Container/Statistics.hook";

const SubscriberStatistics = (props: any) => {
  const uuid = props?.match?.params?.uuid;
  const statistic = useStatistics();
  return (
    <div>
      <ReportsCommonFilters
        {...props}
        history={props.history}
        location={props.location}
        setFromDateToDate={statistic.setFromDateToDate}
      />
      <div className="mt-4 customer-main-container">
        <div className="d-flex justify-content-end customer-close-icon">
          <li
            className="k-icon k-i-close"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
        <div className="d-flex customer-sub-header">
          <h2 className="customer-header">Subscription Statistic Report</h2>
        </div>
        <DropDownList
          data={[
            {
              label: "All",
              value: 0,
            },
            {
              label: "Membership",
              value: 2,
            },
            {
              label: "Add-On",
              value: 16,
            },
            {
              label: "One-on-One",
              value: 8,
            },
            {
              label: "Group",
              value: 256,
            },
            {
              label: "Package",
              value: 512,
            },
          ]}
          textField="label"
          dataItemKey="value"
          defaultValue={{
            label: "All",
            value: 0,
          }}
          value={statistic.serviceTypeValue}
          onChange={(e) => {
            statistic.setServiceTypeValue(e.target.value);
            statistic.fetchStatistics({ AuditType: e.target.value.value });
          }}
        />
        <StatisticChart
          chartOriginalData={
            statistic.pageInfo?.data?.length > 0
              ? {
                  Items: statistic.pageInfo?.data[0].OverAllByDate.map(
                    (d: any) => ({
                      ...d,
                      "In-Active": d.InActive,
                      "Re-Activated": d.ReActivated,
                      "Re-Joined": d.ReJoined,
                    })
                  ),
                }
              : {}
          }
          chartLoading={false}
          title={"Statistics"}
          removeColumns={(columnsToRemove: any) =>
            statistic.redesignGrid(columnsToRemove)
          }
          dropDownValues={[
            "Select All",
            "Joined",
            "In-Active",
            "Suspended",
            "Re-Activated",
            "Re-Joined",
            "Cancelled",
            "Freezed",
            "Expired",
            "Unfrozen",
          ]}
        />
        <div className="customer-grid-border">
          <BuzopsReportGrid
            fileName="Statistics.xlsx"
            result={
              statistic.pageInfo.data?.length > 0
                ? statistic.pageInfo.data[0].OverAllByAuditType
                : []
            }
            dataState={{
              ...statistic.pageInfo,
              data:
                statistic.pageInfo.data?.length > 0
                  ? statistic.pageInfo.data[0].OverAllByAuditType
                  : [],
            }}
            dataStateChange={(e: any, forExcel: boolean) =>
              statistic.fetchStatistics(e, forExcel)
            }
            stateGridColumns={statistic.stateGridColumns}
            pageSize={10}
            setStateGridColumns={(data: any) =>
              statistic.setStateGridColumns(data)
            }
            onColumnsSubmit={statistic.onColumnsSubmit}
            excelData={statistic.excelData}
            setExcelData={() => statistic.setExcelData([])}
            expandChange={statistic.expandChange}
            DetailComponent={(props: any) =>
              StatisticsDetail(props, {
                from: statistic.fromDateToDate.from,
                to: statistic.fromDateToDate.to,
              })
            }
            renderCustomCell={statistic.renderCustomCell}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriberStatistics;
