import BuzopsReportGrid from "generic-components/BuzopsReportGrid";
import ReportsCommonFilters from "../../reportsCommonFilters";
import useSubsciberReport from "../Container/SubscriberReport.hook";
import SubscriberStatusTypeCard from "./SubscriberStatusTypeCard";
import { SubscriberStatusTypes } from "../Utilities/constants";

interface IStatusObject {
  statusType: SubscriberStatusTypes;
  description: string;
  statusName: string;
}

const SubsciberReport = (props: any) => {
  const { reportName } = props;
  const subscriberReport = useSubsciberReport(props);
  const uuid = props?.match?.params?.uuid;

  return (
    <div>
      <ReportsCommonFilters
        {...props}
        history={props.history}
        showDate={false}
        location={props.location}
        dateValues={subscriberReport.dateValues}
        defaultValue={
          Object.keys(subscriberReport.isDateRange).length === 0
            ? subscriberReport.filterText
            : subscriberReport.isDateRange
        }
      />
      <div className="mt-4 subscribers-main-container">
        <div className="d-flex justify-content-end subscribers-close-icon">
          <li
            className="k-icon k-i-close"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
        <div className="subscribers-main-header">
          <h2 className="subscriber-header">{`Subscribers By ${reportName}`}</h2>
          <div className="subscribers-labels">
            <div
              className={
                subscriberReport.dataType === "total"
                  ? "subscribers-card clicked"
                  : "subscribers-card"
              }
              onClick={() => subscriberReport.handleChange("total")}
            >
              <h2>{subscriberReport.count.total}</h2>
              <span>
                TOTAL SUBSCRIBERS BY &nbsp; &nbsp;&nbsp;
                {reportName.toUpperCase()}
              </span>
              <p>{props.reportDescription}</p>
            </div>
            <div className="subscribers-grid-parent">
              <div className="subscribers-card-content">
                {props.availableStatus?.map((statusObj: IStatusObject) => (
                  <SubscriberStatusTypeCard
                    dataType={subscriberReport.dataType}
                    count={subscriberReport.count}
                    statusType={statusObj.statusType}
                    handleChange={subscriberReport.handleChange}
                    description={statusObj.description}
                    statusName={statusObj.statusName}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        {
          <BuzopsReportGrid
            fileName="subscribersReport.xlsx"
            result={subscriberReport.tableData.data}
            dataState={{ ...subscriberReport.tableData }}
            dataStateChange={subscriberReport.dataStateChange}
            stateGridColumns={subscriberReport.stateGridColumns}
            pageSize={subscriberReport.pageSize}
            setStateGridColumns={(data: any) =>
              subscriberReport.setStateGridColumns(data)
            }
            onColumnsSubmit={subscriberReport.onColumnsSubmit}
            loading={subscriberReport.loading.dataLoad}
            excelData={subscriberReport.excelData}
            setExcelData={() => subscriberReport.setExcelData([])}
          />
        }
      </div>
    </div>
  );
};

export default SubsciberReport;
