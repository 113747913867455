import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { Chip, ChipList } from '@progress/kendo-react-buttons';
import { Button } from '@progress/kendo-react-buttons';
import { TenantService } from 'services/tenant/index.service';

interface Tag {
  text: string;
  value: string;
}

const TaggingComponent = forwardRef((props: { tagData: string }, ref) => {
  const { tagData = "" } = props;
  const [tags, setTags] = useState<string[]>([]);
  const [allTags, setAllTags] = useState<string[]>([]);
  const [chips, setChips] = useState<Tag[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [autoCompleteVisible, setAutoCompleteVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const autoCompleteRef = useRef<any>(null);
  const service = new TenantService();

  useEffect(() => {
    console.log("Received tagData:", tagData); // Check the received tagData
    if (tagData) {
      const initialChips = tagData.split(",").map(tag => ({ text: tag, value: tag }));
      setChips(initialChips);
    }
  }, [tagData]);
  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    if (chips.length > 0) {
      const mappedChips = chips.map(c => c.value);
      setTags((tag) => tag.filter(t => !mappedChips.includes(t)))
    } else {
      setTags(allTags)
    }
  }, [chips]);

  useEffect(() => {
    if (autoCompleteVisible && autoCompleteRef.current) {
      autoCompleteRef.current.focus();
      console.log(autoCompleteRef.current)
    }
  }, [autoCompleteVisible]);
  useImperativeHandle(ref, () => ({
    getTags: () => chips?.map(tag => tag.value),
  }));


  const getTags = async () => {
    setLoading(true);
    try {
      const res = await service.getTags();
      const tagResponse = res?.map(tag => tag.Description) || [];
      const mappedChips = chips.map(c => c.value);
      setTags((tag) => tagResponse.filter(t => !mappedChips.includes(t)))
      setAllTags(tagResponse);
    } finally {
      setLoading(false);
    }
  };

  const handleTagChange = (event: AutoCompleteChangeEvent) => {
    const value = event.value as string;
    setInputValue(value);
    if (event.nativeEvent.type === "input") {
      setFilter(event.value);
    }
    if (event.nativeEvent.type === 'blur' || event.nativeEvent.type === 'click') {
      addTag(value);
    }
  };

  const addTag = async (value: string) => {
    if (value && !chips.some(chip => chip.text === value)) {
      const newChip = { text: value, value: value };
      setChips([...chips, newChip]);
      if (!tags.includes(value)) {
        setTags([...tags, value]);
        setLoading(true);
        try {
          await service.addTag({ Description: value }); // Add tag to the backend
        } finally {
          setLoading(false);
        }
      }
      setInputValue('');
      setAutoCompleteVisible(false);
    } else {
      setAutoCompleteVisible(false);
    }
  };

  const handleRemoveChip = (chipToRemove: Tag) => {
    setChips(chips.filter(chip => chip.value !== chipToRemove.value));
  };

  const handleBlur = () => {
    if (inputValue && !chips.some(chip => chip.text === inputValue)) {
      const newChip = { text: inputValue, value: inputValue };
      setChips([...chips, newChip]);
      if (!tags.includes(inputValue)) {
        setTags([...tags, inputValue]);
        setLoading(true);
        service.addTag({ Description: inputValue }).finally(() => setLoading(false)); // Add tag to the backend
      }
      setInputValue('');
      setAutoCompleteVisible(false);
    } else {
      setAutoCompleteVisible(false);
    }
  };

  const handleFilterChange = (event: AutoCompleteFilterChangeEvent) => {
    setFilter(event.filter.value);
  };

  // const handleFocus = () => {
  //   if (autoCompleteRef.current) {
  //     autoCompleteRef.current.togglePopup(true);
  //   }
  // };

  return (
    <div>
      {chips.length > 0 && (
        <div className='mb-3 myprofiletags'>
          <ChipList
            data={chips}
            selection="multiple"
            onDataChange={({ value }) => setChips(value as Tag[])}
            chip={(props) => (
              <Chip
                style={{ width: 'fit-content' }}
                removable={true}
                removeIcon="k-icon k-i-close"
                {...props}
              />
            )}
          />
        </div>
      )}

      {!autoCompleteVisible && (
        <div>
          <Button onClick={() => {
            setAutoCompleteVisible(true);
            autoCompleteRef?.current?.input?.focus();
          }} style={{ margin: '0px' }}>
            Add Tag
          </Button>
        </div>
      )}
      {autoCompleteVisible && (
        <AutoComplete
          ref={autoCompleteRef}
          id="tag-input"
          data={tags.filter(tag => tag.toLowerCase().includes(filter.toLowerCase()))}
          value={inputValue}
          onChange={handleTagChange}
          opened={true}
          onBlur={handleBlur}
          // onFocus={handleFocus}
          suggest={true}
          // onFilterChange={handleFilterChange}
          placeholder="Select or add a tag"
          loading={loading}
          style={{ marginTop: '10px' }}
        />
      )}
    </div>
  );
});

export default TaggingComponent;
