export enum CheckinMode {
  AccessControlBrivo = 0,
  ByStaff = 1,
  UserFromKiosk = 2,
  //   OnlineByUser = 3, // Futre
}

export enum CheckinFrom {
  LiveStream = "livestream",
  Appointment = "appointmnet",
  Kiosk = "kiosk",
}

export enum CheckinType {
  Location = 0,
  GroupClassAppointment = 2,
  OneOnOneAppointment = 3,
}
