import { configureStore } from '@reduxjs/toolkit'
import redirectionSlice from 'redux-slices/redirectionSlice'
import staffCheckinsSlice from 'redux-slices/staffCheckinsSlice'
import userSlice from 'redux-slices/userSlice'
import genericReducer from './redux-slices/genericSlice'
import formSlice from 'redux-slices/formSlice'

export default configureStore({
    reducer: {
        generic: genericReducer,
        redirection: redirectionSlice,
        staffCheckIn: staffCheckinsSlice,
        userConfiguration: userSlice,
        form: formSlice,
    },
})