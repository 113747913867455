import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { useEffect, useState } from "react";
import { ThirdPartyConfigService } from "services/thirdparty-configuration/index.service";
import { HardwareProviderType, SecuritySystemType } from "utils/constants";
import { ISecuritySystemConfiguration } from "utils/interfaces";
import OnsiteAccess from "./OnsiteAccess";
import OpenPathCredentials from "./OpenPathCredentials";
import SimpleCheckinAccess from "./SimpleCheckinAccess";
import PassportTechCredentials from "./PassportTechCredentials";

const AccessComponent = (props: any) => {
  const service = new ThirdPartyConfigService();
  const [loading, setLoading] = useState<boolean>(false);
  const [securityConfig, setSecuritySystemConfig] =
    useState<ISecuritySystemConfiguration>();
  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    const getResult = await service.getSecuritySystemConfigs();
    if (getResult.success) {
      setSecuritySystemConfig(getResult?.data);
    } else {
      if (getResult.msg) {
        props?.handleNotificationMessage(getResult.msg, "error");
      }
    }
    setLoading(false);
  };

  const showComponentBasedOnSelectedSecType = () => {
    switch (securityConfig?.SecuritySystemType) {
      case SecuritySystemType.ProximityReaderSystem:
        switch (securityConfig?.HardwareSecuritySystemPreferences?.ProviderType){
          case HardwareProviderType.Brivo:
            return <OnsiteAccess {...props} />;
          case HardwareProviderType.Openpath:
            return <OpenPathCredentials
              UserMemberId={props?.userData?.UserMemberId}
              PrimaryUserMemberId={props?.userData?.MainUserId}
              handleNotification={props.handleNotificationMessage}
              userData={props.userData}
              ConfiguredGroups={securityConfig.HardwareSecuritySystemPreferences.OpenPathSettings.SiteConfiguration.ConfiguredGroups.filter(
                (g: any) => g.ForStaffOnly === false
              )}
            />
          case HardwareProviderType.Passport:
            return <PassportTechCredentials
              UserMemberId={props?.userData?.UserMemberId}
              PrimaryUserMemberId={props?.userData?.MainUserId}
              handleNotification={props.handleNotificationMessage}
              userData={props.userData}
            />
          default:
            return (
              <div className="d-flex text-center">
                <h5>
                  Please configure security system under third party integration to
                  proceed further.
                </h5>
              </div>
            );
        }
      case SecuritySystemType.ScannerSystem:
        return (
          <SimpleCheckinAccess {...props} securityConfig={securityConfig} />
        );
      default:
        return (
          <div className="d-flex text-center">
            <h5>
              Please configure security system under third party integration to
              proceed further.
            </h5>
          </div>
        );
    }
  };

  if (loading) {
    return <BuzopsLoader type="list" />;
  } else {
    return showComponentBasedOnSelectedSecType();
  }
};

export default withNotification(AccessComponent);
