const reports = [
  {
    name: "Select Reports",
    url: "",
  },
];

const subscribers = [
  {
    name: "Subscription Statistics",
    url: `/subscribers/statistics`,
    image: "/images/subscribers-by-plan.svg",
  },
  {
    name: "Subscriber by Memberships",
    url: `/subscribers/plan`,
    image: "/images/subscribers-by-plan.svg",
  },
  {
    name: "Subscriber by Add-Ons",
    url: `/subscribers/addons`,
    image: "/images/subscribers-by-addons.svg",
  },

  {
    name: "Subscriber by Group Classes",
    url: `/subscribers/groupServices`,
    image: "/images/group-services.svg",
  },
  {
    name: "Subscriber by One-on-One",
    url: `/subscribers/individualServices`,
    image: "/images/individual-services.svg",
  },
  {
    name: "Subscriber by Packages",
    url: `/subscribers/packages`,
    image: "/images/subscribers-by-packages.svg",
  },
];

const sales = [
  {
    name: "Sales by Memberships",
    url: `/sales/plan`,
    image: "/images/sales-by-plan.svg",
  },
  {
    name: "Sales by Add-Ons",
    url: `/sales/addons`,
    image: "/images/sales-by-addons.svg",
  },
  {
    name: "Sales by One Time Charge",
    url: `/sales/oneTimeCharge`,
    image: "/images/sales-by-one-time-charge.svg",
  },
  {
    name: "Sales by Group Classes",
    url: `/sales/groupServices`,
    image: "/images/group-services.svg",
  },
  {
    name: "Sales by One-on-One",
    url: `/sales/individualServices`,
    image: "/images/individual-services.svg",
  },
  {
    name: "Sales by Packages",
    url: `/sales/packages`,
    image: "/images/sales-by-packages.svg",
  },
];

const customers = [
  {
    name: "All Customers",
    url: `/customers`,
  },
];

const attendance = [
  {
    name: "Onsite Log",
    url: `/onsitelog`,
  },
  {
    name: "Service Log",
    url: `/serviceLog`,
  },
];

const revenue = [
  {
    name: "MRR",
    url: `/mrr`,
  },
  {
    name: "Transactions",
    url: `/transactions`,
  },
  {
    name: "Revenue",
    url: `/revenue`,
  },
  {
    name: "Surcharges Report",
    url: `/TaxReport`,
  },
];

const payroll = [
  {
    name: "Staff Payouts",
    url: `/staffPayouts`,
  },
];

const payments = [
  {
    name: "Auto Updater",
    url: `/paymentMethodUpdater`,
  },
];

const ReportConstants = {
  reports,
  subscribers,
  sales,
  customers,
  attendance,
  revenue,
  payroll,
  payments,
};

export default ReportConstants;
