import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ContextApi } from "context-api/AppContext";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsNotification from "generic-components/BuzopsNotification";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserConfiguration } from "redux-slices/userSlice";
import { TenantService } from "services/tenant/index.service";
import { FormInput } from "utils/form-components";
import { GetLocalStore, RemoveLocalStore } from "utils/storage";
import { requiredValidator } from "validators/validator";

function Welcome(props: any) {
  const dispatch = useDispatch()
  const { state } = useContext(ContextApi);
  const redirectionObj = useSelector((state:any)=>state?.redirection)
  const history = useHistory();
  const [loading, setLoading] = useState<Boolean>(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const [passwordType, setPasswordType] = useState(true);

  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    let timer = 1000;
    if (notification.error) {
      timer = 8000;
    }
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
    }, timer);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification, notification.error, notification.success]);

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

  const handleResetPassword = async () => {
    const tenantService = new TenantService();
    const reqData = {
      EmailId: GetLocalStore("emailId"),
    };
    const forgotPassword = await tenantService.resetPassword(reqData);
    if (forgotPassword.ResponseCode === 100) {
      props.setAuthRoute('resetPassword')
    } else {
      const errorMsg = forgotPassword.ErrorMessages[0];
      setMessage(errorMsg);
      setNotification({ ...notification, error: true });
    }
  };
  const handleCheckout = (obj: any) => {
    const { firstPathValue, staffuuid, clientMemberId } = redirectionObj

    let basePath = '';
    if(obj.session){
      basePath="checkout"
    }else{
      basePath="purchasepackage"
    }
    
    if (firstPathValue === 'member') {
      history.push(`/${firstPathValue}/${staffuuid}/${clientMemberId}/${basePath}`, obj);
    } else if (firstPathValue === 'external') {
      history.push(`/${firstPathValue}/${basePath}`, obj);
    } else {
      history.push(`/${basePath}`, obj);
    }
  }

  useEffect(() => {
    const userDetails = GetLocalStore("userDetails");
    if (userDetails) {
      history.goBack();
    }
  }, []);

  const handleSubmit = async (dataItem: any) => {
    setLoading(true);
    const reqData = {
      EmailId: GetLocalStore("emailId"),
      UserId: GetLocalStore("userId"),
      Password: dataItem.password,
      IsActivated: true
    };
    const tenantService = new TenantService();
    const authUser = await tenantService.authenticateUser(reqData);
    if (authUser.response) {
      const userInfo = authUser?.data?.Item
      const userData= {
        UserMemberId:userInfo?.UserSession?.UserMemberId,
        FirstName:userInfo?.UserSession?.FirstName,
        LastName:userInfo?.UserSession?.LastName,
        FullName:userInfo?.UserSession?.FullName,
      }
      dispatch(setUserConfiguration({
        loggedIn:true,
        userDetails:userInfo
      }))
      if(userInfo?.MemberStatus===16 || userInfo?.MemberStatus===32){
        props.handleCloseLogin()
        history.push('/summary')
      }else if (props.guestLayout) {
        let trainerName = props.providerName;
        if (props.match?.params?.providerId) {
          trainerName = props.match?.params?.providerId
        }
        props.handleCloseLogin()
        const serviceItem = GetLocalStore("serviceItem");
        const providerItem = GetLocalStore("provider");
        const classItem = GetLocalStore("classItem");
        const packageItem = GetLocalStore("packageItem");
        const planItem = GetLocalStore("planItem");

        if (serviceItem) {
            const redirectUrl =`/scheduleappointment`
            const obj={ provider: providerItem, selectedService: serviceItem,
              userData:userData
             }
          if(redirectionObj?.firstPathValue === "external"){
            history.push(`/${redirectionObj?.firstPathValue}${redirectUrl}`,obj)
          }else{
            history.push(redirectUrl,obj)
          }
        } else if (classItem) {
          handleCheckout({ provider: providerItem, session: classItem })
        } else if (packageItem) {
          handleCheckout({ provider: providerItem, package: packageItem })
        } else if(planItem){
          if(redirectionObj?.firstPathValue === "external"){
            history.push(`/${redirectionObj?.firstPathValue}/plancheckout`,  { plan: planItem });
          }else{
            history.push(`/plancheckout`,  { plan: planItem });
          }
        }
        else {
          let redirectUrl=`/${redirectionObj?.firstPathValue}`
          if(trainerName){
            redirectUrl=`/${trainerName}`
          }else{
            redirectUrl=`/`
          }
          if(redirectionObj?.firstPathValue === "external"){
            history.push(`/${redirectionObj?.firstPathValue}${redirectUrl}`);
          }else{
            history.push(redirectUrl);
          }

        }
      } else {
        props.handleCloseLogin()
        const serviceItem = GetLocalStore("serviceItem");
        const classItem = GetLocalStore("classItem");
        const packageItem = GetLocalStore("packageItem");
        const planItem = GetLocalStore("planItem");
        if (!serviceItem && !classItem && !packageItem && !planItem) {
          history.push({
            pathname: redirectionObj?.firstPathValue !==""?`/${redirectionObj?.firstPathValue}/summary`: `/summary`,
            state: {
              userDetails: authUser.data
            }
          });
        } else {
          if (serviceItem) {
            if(redirectionObj?.firstPathValue === "external"){
              history.push(`/${redirectionObj?.firstPathValue}/scheduleappointment`,{selectedService: serviceItem,  userData:userData});
            }else{
              history.push(`/scheduleappointment`,{selectedService: serviceItem,  userData:userData});
            }
          }
          if (classItem) {
            handleCheckout({ session: classItem })
          }
          if (packageItem) {
            handleCheckout({ package: packageItem })
          }
          if (planItem) {
            if(redirectionObj?.firstPathValue === "external"){
              history.push(`/${redirectionObj?.firstPathValue}/plancheckout`,  { plan: planItem });
            }else{
              history.push(`/plancheckout`,  { plan: planItem });
            }
          }

        }
      }
      RemoveLocalStore("serviceItem");
      RemoveLocalStore("classItem");
      RemoveLocalStore("packageItem");
      RemoveLocalStore("planItem");
    } else {
      dispatch(setUserConfiguration({
        loggedIn:false,
        userDetails:null
      }))
      let errorMsg = `Password is not correct.
      Did you forget your password?`;
      if(authUser?.data?.ErrorMessages[0] === "Your primary account is inactive please activate to proceed to login with your account."){
        errorMsg="Your primary account is inactive please activate to proceed to login with your account."
      }
      setMessage(errorMsg);
      setNotification({ ...notification, error: true });
    }
    setLoading(false);
  };
  const openDialog = (title: string) => {
    setTitle(title);
    setVisible(true);
  }
  const closeDialog = () => {
    setVisible(false);
  }
  const handlePasswordDisplay = () => {
    setPasswordType(!passwordType);
  };
  const redirectPage = () => {
    if (props.guestLayout) {
      props.setAuthRoute('login')
    } else {
      props.setAuthRoute('login')
    }
  }
  return (
    // <Card className="bz-ol-login-card">
    //   <CardBody>
    <>
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (

          <FormElement>
            <div className="">
              <div className="bzo-login-card bz-emailLogin-card border-0 bz-border-radius-1">
                <div className="bzo-login-header">
                  <h4 className="h4 mb-0 text-left">Welcome back!</h4>
                </div>
                <div className="bzo-login-body pb-3">
                  <p className="text-left my-3"> Enter your Password</p>
                  <div className="form-group">
                    <Field
                      type={"password"}
                      id={"password"}
                      name={"password"}
                      passwordType={passwordType}
                      label={"Password"}
                      validator={requiredValidator}
                      customvalidation={true}
                      hidelabel={true}
                      component={FormInput}
                      autoFocus={true}
                    />
                    <span className="password_eye_icon"  onClick={()=>handlePasswordDisplay()} key={passwordType.toString()}><i className={passwordType ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
                  </div>

                  <div className="">
                    <ButtonGroup>
                      <Button
                        type={"reset"}
                        onClick={() => redirectPage()}
                      >
                        Back
                      </Button>
                      <BuzopsButton
                        type={"submit"}
                        label={"Sign In"}
                        loading={loading}
                        disable={loading}
                      />
                    </ButtonGroup>
                  </div>

                  <hr className="my-4" />
                  <div className="d-flex justify-content-center text-center buz-text-medium mb-2" style={{maxWidth:'25rem',margin:'auto'}}>
                    <div className="text-center buz-text-small bz-ol-muted buz-font-500">
                      By signing in you are agreeing to the{" "}
                      <div
                        onClick={() => openDialog("Terms of Service")}
                        className="btn btn-link align-baseline buz-text-small bz-cursor-pointer p-0"
                      >
                        Terms of Service
                      </div>{" "}
                      and{" "}
                      <div
                        onClick={() => openDialog("Privacy Policy")}
                        className="btn btn-link align-baseline buz-text-small bz-cursor-pointer p-0"
                      >
                        Privacy Policy.
                      </div>
                    </div>
                  </div>
                  <div className="text-center bz-other-login-option mt-3" style={{maxWidth:'25rem',margin:'auto'}}>
                    <div className="bzo-reset-pwd d-inline-block text-left">
                      <label className="mb-0 text-muted">
                        <span className="btn btn-link p-0" onClick={() => handleResetPassword()}>
                          {message ? (
                            <b className={"h5"}>Reset your password</b>
                          ) : (
                            <b className={"h6"}>Reset your password</b>
                          )}
                        </span>
                      </label>
                    </div>
                    <span className="align-middle">
                      Not{" "}
                      <span
                        className="btn btn-link align-baseline bz-cursor-pointer p-0"
                        onClick={() => redirectPage()}
                      >
                        {state.email}?
                      </span>
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </FormElement>
        )}
      />
      <BuzopsNotification
        notification={notification}
        message={message}
        handleNotification={(type: any) => handleNotification(type)}
      />
      {
        visible &&
        <Dialog
          className={title === "Terms of Service" ? "bz-mybookings-dialog-box bz-privacyPolicy-dialog bzo-auto-height" : "bz-mybookings-dialog-box bz-privacyPolicy-dialog"}
          title={title}
          onClose={closeDialog}
        >

          <div className="row">
            <div className={title === "Terms of Service" ? "col-12" : "col-12 d-none"}>
              <h4 className="mble-title">Terms of Service</h4>
              <article>
                <p>These Terms govern your access and use of the cloud-based subscription billing platform and its related products and services provided by Buzops. By using our Services, you’re agreeing to be bound by these Terms. If you’re using our Services for an Organization, you’re agreeing to these Terms on behalf of that Organization and in these Terms, “you”, “you’re”, or “your” refers to that Organization. </p>
                <p>
                  We may modify these Terms from time to time by posting the most current version on our website. New features or updates that we add to our products or services are subject to these Terms. If a modification materially reduces your rights, we will notify you. If you do not agree to a modification, you may terminate your use of our products and/or services or request us to terminate the provision of our product or services to you. By continuing to use our products and/or services after the modification comes into effect, you are agreeing to be bound by the modified Terms.
                </p>
                <p className="text-muted">Terms of Service Last Updated: 06/30/2021</p>
              </article>
            </div>

            <div className={title === "Privacy Policy" ? "col-12" : "col-12 d-none"}>
              <h4 className="mble-title">Privacy Policy</h4>
              <article>

                <p>This Privacy Policy governs the manner in which Buzops Inc. and its affiliates collects, uses, maintains, and discloses information collected from users (each, a "User") of the www.buzops.com website URL or associated and/or affiliated IP addresses, sub-domains, redirected links, but strictly limited to these specific associated or affiliated IP or URL addresses, which may also include native or non-native applications ("Site"). This privacy policy applies to the Site and all products and services offered by Buzops Inc. and its affiliates. </p>


              </article>
              <article>
                <h5 className="mble-title">Personal identification information </h5>
                <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, place an order, subscribe to the newsletter, respond to a survey, fill out a form, or any other digital method and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number, billing or payment information, date of birth, and social security number. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us or it is provided to us through third party analytics and authorized to be provided by third party analytic services or tools (of which we have no control). Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities. </p>
              </article>
              <article>
                <h5 className="mble-title">Non-personal identification information</h5>
                <p>We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service provider’s utilized and other similar information. </p>
              </article>
              <article>
                <h5 className="mble-title">Web browser cookies</h5>
                <p>Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>
              </article>
              <article>
                <h5 className="mble-title">How we use collected information </h5>
                <p>Buzops Inc. and its affiliates collects and uses User personal information for the following purposes: </p>
                <ul>
                  <li>To personalize user experience:
                    We may use information in the aggregate to understand how our Users, as a group, or personally, use the products, services, and resources provided on our Site. </li>
                  <li>To improve our Site:
                    We continually strive to improve our website offerings based on the information and feedback we receive from you and collected data. </li>
                  <li>To improve customer service:
                    Your information helps us to more effectively respond to your customer service requests and support needs. </li>
                  <li>To process transactions:
                    We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service. </li>
                  <li>To administer content, promotion, survey or other Site feature:
                    To send Users information they agreed to receive about topics we think will be of interest to them. </li>
                  <li>To send periodic emails
                    The email address Users provide for order processing, will only be used to send them information and updates pertaining to their order. It may also be used to respond to their inquiries, and/or other requests or questions. If a User decides to opt-in to our mailing list, they will receive emails that may include, but not limited to, company news, updates, related products, or service information. If at any time the User would like to unsubscribe from receiving future emails, unsubscribe instructions are included at the bottom of each email; or User may contact us through our Site. </li>
                </ul>
              </article>
              <article>
                <h5 className="mble-title">How we protect your information </h5>
                <p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site. </p>
                <p>Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures. </p>
              </article>
              <article>
                <h5 className="mble-title">Sharing your personal information </h5>
                <p>We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above. We may use third party service providers to help us operate our business and the Site or administer activities on our behalf, such as, but not limited to, sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us your permission. </p>
              </article>
              <article>
                <h5 className="mble-title">Changes to this privacy policy  </h5>
                <p>Buzops Inc. and its affiliates has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of any and all modifications. </p>
              </article>
              <article>
                <h5 className="mble-title">Your acceptance of these terms</h5>
                <p>By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.  </p>
              </article>
              <p className="text-muted">Privacy Policy Last Updated: 06/30/2021 </p>
            </div>

          </div>

        </Dialog>
      }
    </>
    //   </CardBody>
    // </Card>
  );
}

export default Welcome;
