import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsNotification from "generic-components/BuzopsNotification";
import React, {  useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserConfiguration } from "redux-slices/userSlice";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import { CheckInService } from "services/checkin/index.service";
import { TenantService } from "services/tenant/index.service";
import { FormInput } from "utils/form-components";
import { ApplicationTypeEnum, UserAffiliateStatusEnum } from "utils/form-utils";
import { GetLocalStore, LocalStore } from "utils/storage";
import { requiredValidator } from "validators/validator";

function AffiliateWelcome(props: any) {
  const dispatch = useDispatch()
  const history = useHistory();
  const [loading, setLoading] = useState<Boolean>(false);
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const [passwordType, setPasswordType] = useState(true);
  const [emailId,setEmailId]=useState(GetLocalStore("emailId"));
  const AffiliateService=new AffiliateProgramService();

  useEffect(() => {
    let timer = 1000;
    if (notification.error) {
      timer = 8000;
    }
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
    }, timer);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification, notification.error, notification.success]);

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };




  useEffect(() => {
    const AffiliateDetails = GetLocalStore("AffiliateDetails");
    setEmailId(GetLocalStore("emailId"));
    if (AffiliateDetails) {
      history.push("/affiliate/summary");
    }
  }, []);
  
  const handleResetPassword = async (e:any) => {
    e?.stopPropagation()
    const tenantService = new TenantService();
    const reqData = {
      EmailId: emailId,
    };
    const forgotPassword = await tenantService.resetPassword(reqData);
    if (forgotPassword.ResponseCode === 100) {
      props.setAuthRoute('affiliateResetPassword')
    } else {
      const errorMsg = forgotPassword.ErrorMessages[0];
      setMessage(errorMsg);
      setNotification({ ...notification, error: true });
    }
  };

  const handleSubmit = async (dataItem: any) => {
    setLoading(true);
    const reqData = {
      EmailId: emailId,
      Password: dataItem.password,
      ApplicationType:ApplicationTypeEnum.AFFILIATE
    };
    const service = new CheckInService();
    const authUser = await service.authenticateStaff(reqData);
    if (authUser.successResponse) {
      const affiliateInfo = authUser?.data?.Item;
      const AssociateInfo = await AffiliateService.getAffiliateById(affiliateInfo.UserSession.AssociateId,{});
      const status=AssociateInfo?.Status
      switch (status) {
        case UserAffiliateStatusEnum.Active:
          LocalStore('AffiliateDetails',affiliateInfo)
          LocalStore('CurrentApplicationType',ApplicationTypeEnum.AFFILIATE)
          dispatch(
            setUserConfiguration({
              loggedIn: true,
              affiliateDetails: affiliateInfo,
            })
          );
          history.push("/affiliate/summary");
          break;
        case UserAffiliateStatusEnum.Declined:
          LocalStore("emailId", emailId);
          setMessage(`Request Declined by the User`);
          setNotification({ ...notification, success: false, error: true });
          break;
        case UserAffiliateStatusEnum.InActive:
          LocalStore("emailId", emailId);
          setMessage(`Your affiliate account is inactive. Please reach out to admin for reactivation`);
          setNotification({ ...notification, success: false, error: true });
          break;
        default:
          break;
      }
    }else{
      const errorMsg = `Password is not correct.
      Did you forget your password?`;
      setMessage(errorMsg);
      setNotification({ ...notification, success: false, error: true });
    }
    setLoading(false);
  };

  const handlePasswordDisplay = () => {
    setPasswordType(!passwordType);
  };
  const redirectPage = () => {
      props.setAuthRoute('affiliatelogin')
  }
  return (

    <>
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (

          <FormElement>
            <div className="">
              <div className="bzo-login-card bz-emailLogin-card border-0 bz-border-radius-1">
                <div className="bzo-login-header">
                  <h4 className="h4 mb-0 text-left">Welcome back Affiliate!</h4>
                </div>
                <div className="bzo-login-body pb-3">
                  <p className="text-left my-3"> Enter your Password</p>
                  <div className="form-group">
                    <Field
                      type={"password"}
                      id={"password"}
                      name={"password"}
                      passwordType={passwordType}
                      label={"Password"}
                      validator={requiredValidator}
                      customvalidation={true}
                      hidelabel={true}
                      component={FormInput}
                      autoFocus={true}
                    />
                    <span className="password_eye_icon"  onClick={()=>handlePasswordDisplay()} key={passwordType.toString()}><i className={passwordType ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
                  </div>

                  <div className="">
                    <ButtonGroup>
                      <Button
                        type={"reset"}
                        onClick={() => redirectPage()}
                      >
                        Back
                      </Button>
                      <BuzopsButton
                        type={"submit"}
                        label={"Sign In"}
                        loading={loading}
                        disable={loading}
                      />
                    </ButtonGroup>
                  </div>
                  <hr className="my-4" />
                  <div className="text-center bz-other-login-option mt-3" style={{maxWidth:'25rem',margin:'auto'}}>
                    <div className="bzo-reset-pwd d-inline-block text-left">
                      <label className="mb-0 text-muted">
                        <span className="btn btn-link p-0" onClick={(e:any) => handleResetPassword()}>
                          {message ? (
                            <b className={"h5"}>Reset your password</b>
                          ) : (
                            <b className={"h6"}>Reset your password</b>
                          )}
                        </span>
                      </label>
                    </div>
                    <span className="align-middle">
                      Not{" "}
                      <span
                        className="btn btn-link align-baseline bz-cursor-pointer p-0"
                        onClick={() => redirectPage()}
                      >
                        {emailId}?
                      </span>
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </FormElement>
        )}
      />
      <BuzopsNotification
        notification={notification}
        message={message}
        handleNotification={(type: any) => handleNotification(type)}
      />

    </>
  );
}

export default AffiliateWelcome;
