import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router";
import { GetLocalStore } from "utils/storage";

const ResetPassword = (props: any) => {
  const history = useHistory();
  const email = GetLocalStore("emailId");
  return (
      <>
        <div className="container h-100">
          <div className="row justify-content-center align-items-center h-100">
            <div className="">

              <div className="bzo-login-card bz-emailLogin-card border-0 bz-border-radius-1">
                <div className="card-body">
                  <div className="row d-flex justify-content-center text-center">
                    <h5 className="h5 mb-2 mt-0 text-center w-100 mb-4"> Instructions sent!</h5>
                  </div>
                  <div className="row d-flex justify-content-center text-center">
                    <div className="">
                      <p className="h6 mb-3 mt-0 text-center text-muted"> Instructions have been sent to <strong>{email}</strong></p>

                    </div>
                    <div className="">
                      <p className="h6 mb-3 mt-0 text-center text-muted">  You'll receive this email within 5 minutes. Be sure to check your
                        spam folder, too.</p>

                    </div>


                  </div>



                  <div className="row d-flex justify-content-center text-center">
                    <div className="">
                      <Button
                        primary={true}
                        style={{textTransform:'none'}}
                        type={"submit"}
                        onClick={() =>
                          {props?.affiliate ?props.setAuthRoute('affiliatelogin') : props.setAuthRoute('login')}
                        }
                      >
                        Back to Login
            </Button>


                    </div>
                  </div>



                </div>
              </div>


            </div>
          </div>
        </div>
         {/* <div id="overlay"></div> */}
    </>
  );
};
export default ResetPassword;
