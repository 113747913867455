import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox, Switch, TextArea } from "@progress/kendo-react-inputs";
import BuzopsButton from "generic-components/BuzopsButton";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CheckPermissions from "utils/permissions";
import { Button } from "@progress/kendo-react-buttons";
import { Error, Hint } from "@progress/kendo-react-labels";
import { TenantService } from "services/tenant/index.service";
import { PackageService } from "services/package/index.service";
import {
  CheckUserMemberStatus,
  CurrencyFormat,
  CustomToFixed,
  PackageInstanceStatus,
  checkIfUserAddPaymentGateway,
  checkIfUserCanAddBankAccount,
} from "utils";
import { PermissionEnum } from "utils/PermissionEnum";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import { DropDownButton } from "@progress/kendo-react-buttons";
import BuzopsLoader from "generic-components/BuzopsLoader";
import withNotification from "components/Hoc/withNotification";
import { TooltipContentRefundTax } from "components/Transactions/Refund";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  CreditsErrorMessage,
  PackageCancellationOnOptions,
  PackageInstanceStatusEnum,
  SessionTypeEnum,
} from "utils/form-utils";
import { Notification as Notify } from "@progress/kendo-react-notification";
import CancelledPackage from "./CancelledPackage";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";

const CancelPackage = (props: any) => {
  const [cancelDetails, setCancelDetails] = useState<any>(null);
  const [formData, setFormData] = useState<any>({});
  const [paymentProfiles, setPaymentProfiles] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taxAmountToRefund, setTaxAmountToRefund] = useState<number>(0);
  const [taxes, setTaxes] = useState<any>([]);
  const [submit, setSubmit] = useState<any>(false);
  const [selectedPeriod, setSelectedPeriod] = useState<any>(null);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const PaymentMethodItems = [
    {
      iconClass: "fa fa-credit-card pr-1",
      text: "Add credit card",
      key: "addCard",
      visible: true,
    },
    {
      iconClass: "fa fa-university pr-1",
      text: "Add bank account",
      key: "addBank",
      visible: checkIfUserCanAddBankAccount(),
    },
  ];
  const packageService = new PackageService();
  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async () => {
    const successMsg = "Payment creation successfully done";
    props?.handleNotificationMessage(successMsg, "success");
    setShowPaymentDialog(!showPaymentDialog);
    await fetchPaymentProfiles(
      props?.userData?.ParentId || props?.userData?.UserMemberId
    );
  };

  const handleCalculateSurcharges = (
    refundAmt: any,
    TaxItems = cancelDetails?.ChargedTransaction
      ?.PaymentTransactionHistoryDetails
  ) => {
    const mainTaxItems = TaxItems?.filter((i: any) => i?.MetaData);
    const TaxesArray = mainTaxItems.map((i: any) => {
      const percentage = parseFloat(i?.MetaData.split(":")[1]);
      const amount = (refundAmt * percentage) / 100;
      const calcAmt = CustomToFixed(amount, 2);
      return {
        Description: i?.Description,
        Percentage: percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount;
    }, 0);
    setTaxes(TaxesArray);
    setTaxAmountToRefund(taxAmt);
  };

  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${
            checkIfUserCanAddBankAccount() ? "Add Bank or Card" : "Add Card"
          }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={props?.packageData?.UserMemberId}
          />
        </Dialog>
      </>
    );
  };
  const fetchAllApis = async () => {
    setLoading(true);
    await fetchPackageDetails();
    await fetchPaymentProfiles(
      props?.userData?.ParentId || props?.userData?.UserMemberId
    );
    await fetchBillingPeriodDetails();
    setLoading(false);
  };
  useEffect(() => {
    fetchAllApis();
  }, []);

  console.log('propsinCancel',props);

  const fetchBillingPeriodDetails = async () => {
    const packageData = props.packageData;
    if (packageData) {
      const payload = {
        UserMemberId: packageData.UserMemberId,
        PackageInstanceId: packageData.PackageInstanceId,
      };
      const result = await packageService.fetchPackageBillingPeriods(payload);
      if (result?.length > 0) {
        const defaultSelectedPeriod = result?.filter(
          (i: any) => i?.IsCurrentBillingPeriod === true
        )?.[0];
        setSelectedPeriod(defaultSelectedPeriod);
      }
    }
  };

  useEffect(() => {
    if (props?.submit) {
      if (props?.fromDashboard) {
        props.gotoPackageOverview();
      } else {
        props.handleWindow();
      }
    }
  }, [props?.submit]);

  const items = [
    {
      text: "Charge",
      id: 1,
      disabled: !CheckPermissions(PermissionEnum["GroupServiceLevel3"]),
    },
    {
      text: "Refund",
      id: 2,
      disabled: !CheckPermissions(PermissionEnum["GroupServiceLevel3"]),
    },
    {
      text: "No Charge & No Refund",
      id: 3,
      disabled: !CheckPermissions(PermissionEnum["GroupServiceLevel3"]),
    },
  ];

  async function fetchPaymentProfiles(userMemberId: any) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);

    setPaymentProfiles(result || []);
  }

  const changeFormData = (value: any, key: any) => {
    let maxRefund: any =
      cancelDetails?.AmountAvailableToRefund -
      cancelDetails?.Taxes -
      cancelDetails?.CancellationFee;
    maxRefund = parseFloat(maxRefund).toFixed(2);
    if (key === "refundAmount" && parseFloat(value) > parseFloat(maxRefund)) {
      props?.handleNotificationMessage(
        "Refund fee should be less than package price",
        "error"
      );
      return true;
    }
    let oldFormData = { ...formData };
    oldFormData[key] = value;
    setFormData(oldFormData);
    if (key === "refundAmount") {
      handleCalculateSurcharges(value);
    }
  };
  const showErrorMessage = (key: any, mainKey: any) => {
    if (
      key === "chargeAmount" &&
      formData[key] < cancelDetails?.CancellationFee
    ) {
      return (
        <Error>{`Charge Amount should be minimum cancellation fee`}</Error>
      );
    }
    if (!formData[key] || formData[key].length <= 0) {
      return <Error>{`${mainKey} is required`}</Error>;
    }
    return <></>;
  };
  const fetchPackageDetails = async () => {
    const packageData = props.packageData;
    if (packageData) {
      const req = {
        UserMemberId: packageData.UserMemberId,
        PackageInstanceId: packageData.PackageInstanceId,
      };
      const packageRes =
        await packageService.fetchPackageDetailsForCancellation(req);
      if (packageRes) {
        setCancelDetails(packageRes);
        if (
          packageRes?.Status === PackageInstanceStatusEnum.FUTURENOTPAID ||
          packageRes?.Status === PackageInstanceStatusEnum.FUTUREPAID
        ) {
          changeFormData(1, "CancellationOn");
        }
        if(packageRes?.SessionType ===SessionTypeEnum.PRESET){
          setFormData({...formData,CancellationOn:1,CanScheduleAppointment:true});
        }
      } else {
        setCancelDetails(null);
      }
    }
  };
  const handleCancelPackage = async (dataItem: any) => {
    const packageRes = await packageService.cancelPackage(dataItem);
    if (packageRes?.ResponseCode === 100) {
      props?.handleNotificationMessage(
        "Package Successfully Cancelled",
        "success"
      );
      setSubmit(true);
    } else {
      const errorMsg =
        packageRes?.ErrorMessages.length > 0
          ? packageRes?.ErrorMessages[0]
          : "Error in Cancelling Package";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const submitForm = () => {
    let oldFormData = { ...formData };

    if (oldFormData?.cancellationType?.id) {
      if (oldFormData?.cancellationType?.id === 1) {
        if (!oldFormData?.chargeAmount) {
          return true;
        }
        if (oldFormData?.chargeAmount < cancelDetails?.CancellationFee) {
          return true;
        }
      }

      if (oldFormData?.cancellationType?.id === 2) {
        if (!oldFormData?.refundAmount || oldFormData?.refundAmount <= 0) {
          return true;
        }
      }
    } else {
      return true;
    }
    if (!oldFormData?.CancellationOn) {
      return true;
    }
    oldFormData["step1"] = true;
    setFormData(oldFormData);
  };
  const submitStep2 = async () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    let oldFormData = { ...formData };

    if (oldFormData?.cancellationType?.id) {
      if (oldFormData?.cancellationType?.id === 1) {
        if (
          !oldFormData?.paymentProfile ||
          Object.keys(oldFormData?.paymentProfile).length <= 0
        ) {
          return true;
        }
        if (
          oldFormData?.paymentProfile?.CardTypeId === 8 &&
          oldFormData?.chargeAmount > oldFormData?.paymentProfile?.Credit
        ) {
          const errorMsg = CreditsErrorMessage;
          props?.handleNotificationMessage(errorMsg, "error");
          return true;
        }
      }
    } else {
      return true;
    }
    const packageData = props.packageData;
    const newObj: any = new Object();
    newObj.CanExcludedFromChurnRate = formData?.CanExcludedFromChurnRate;
    newObj.Notes = formData?.Notes;
    newObj.CanCollectTaxes = packageData?.CanCollectTaxes;
    newObj.Status = cancelDetails?.Status;
    newObj.FailedTransactions = cancelDetails?.FailedTransactions;
    newObj.NotifyCancelationEmailToClient =
      formData?.NotifyCancelationEmailToClient;
    newObj.CanUtilizeAfterCancellation =
      formData?.CancellationOn === 2 ? true : false;
    newObj.Type = formData?.cancellationType?.id;
    newObj.PackageInstanceId = packageData?.PackageInstanceId;
    newObj.UserMemberId = packageData?.UserMemberId;
    if (formData?.cancellationType?.id === 1) {
      newObj.PaymentGatewayPaymentProfileId =
        formData?.paymentProfile?.PaymentGatewayPaymentProfileID;
      newObj.Amount = formData?.chargeAmount;
      newObj.CancellationCharges = cancelDetails?.CancellationFee;
    } else if (formData?.cancellationType?.id === 2) {
      newObj.Amount = formData?.refundAmount;
      newObj.CancellationCharges = cancelDetails?.CancellationFee;
    }
    if (cancelDetails?.SessionType === SessionTypeEnum.PRESET) {
      newObj.CanScheduleAppointment = formData?.CanScheduleAppointment || false;
      newObj.UnusedPaidSchedulesCount = formData?.CanScheduleAppointment ? props?.packageData?.UnusedPaidSchedules : undefined;
      newObj.CanUtilizeAfterCancellation =
      formData?.CanScheduleAppointment || false;
    }else if(formData?.CancellationOn === 1){
      newObj.CanScheduleAppointment = false
    }
    setBtnLoading(true);
    await handleCancelPackage(newObj);
    setBtnLoading(false);
  };

  const CanScheduleAppointmentOptions = [
    { value: true, text: "Yes" },
    { value: false, text: "No" },
  ];

  console.log("CancelDetails", cancelDetails);

  const showStep1Form = () => {
    let itemsList = items;
    if (
      cancelDetails?.Status === PackageInstanceStatus["FutureNotPaid"] ||
      cancelDetails?.AmountAvailableToRefund <= 0
    ) {
      itemsList = items.filter((i: any) => i?.id !== 2);
    }
    let cancellationOnDropdownList = PackageCancellationOnOptions;
    if (
      cancelDetails?.Status === PackageInstanceStatusEnum.FUTURENOTPAID ||
      cancelDetails?.Status === PackageInstanceStatusEnum.FUTUREPAID
    ) {
      cancellationOnDropdownList = PackageCancellationOnOptions.filter(
        (i: any) => i?.id === 1
      );
    }
    return (
      <>
        {cancelDetails?.SessionType === SessionTypeEnum.PRESET ? (
          <div className="form-group col-12 bz-payment-profile-options">
            <div className="bzc-provider-name">
              <label className="mb-2 d-inline-block k-label">
                {`You have (${
                  props?.packageData?.UnusedPaidSchedules
                }) paid schedules without usage, do you want to allow to schedule after cancellation?`}
              </label>
              <div className="bzc-time-sec">
                <DropDownList
                  data={CanScheduleAppointmentOptions}
                  textField="text"
                  dataItemKey="value"
                  value={
                    CanScheduleAppointmentOptions?.filter(
                      (i: any) => i?.value === formData?.CanScheduleAppointment
                    )?.[0]
                  }
                  onChange={(e) =>
                    changeFormData(e?.value?.value, "CanScheduleAppointment")
                  }
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {submit &&
              showErrorMessage(
                "CanScheduleAppointment",
                "Allow to schedule after cancellation"
              )}
          </div>
        ) : null}
        <div className="form-group col-12 bz-payment-profile-options">
          <div className="bzc-provider-name">
            <label className="mb-2 d-inline-block k-label">
              Cancellation Type
            </label>
            <div className="">
              <DropDownList
                className="k-dropdown-width-100"
                id={`chargeType`}
                name={`chargeType`}
                data={itemsList}
                textField="text"
                dataItemKey="id"
                value={formData?.cancellationType}
                onChange={(e) => changeFormData(e.value, "cancellationType")}
              />
            </div>
          </div>
          {showErrorMessage("cancellationType", "Cancellation Type")}
        </div>
        {formData?.cancellationType?.id === 1 && (
          <div className="form-group col-12 bz-payment-profile-options">
            <div className="bzc-provider-name">
              <label className="mb-2 d-inline-block k-label">
                <span>
                  Charge Amount{" "}
                  {props?.packageData?.CanCollectTaxes && <span>*</span>}
                </span>
                {props?.packageData?.CanCollectTaxes && (
                  <small>(Inclusion of Surcharges)</small>
                )}
              </label>
              <div className="bzc-time-sec">
                <NumericTextBox
                  id={`charge`}
                  name={`charge`}
                  min={0}
                  format={"c2"}
                  className="k-dropdown-width-100"
                  value={formData?.chargeAmount}
                  onChange={(e) =>
                    changeFormData(e.target.value, "chargeAmount")
                  }
                />
              </div>
            </div>
            {showErrorMessage("chargeAmount", "Charge Amount")}
          </div>
        )}
        {formData?.cancellationType?.id === 2 && (
          <div className="form-group col-12 bz-payment-profile-options">
            <div className="bzc-provider-name">
              <label className="mb-2 d-inline-block k-label">
                Refund Amount
              </label>
              <div className="bzc-time-sec">
                <NumericTextBox
                  id={`refund`}
                  name={`refund`}
                  min={0}
                  format={"c2"}
                  className="k-dropdown-width-100"
                  value={formData?.refundAmount}
                  onChange={(e) =>
                    changeFormData(e.target.value, "refundAmount")
                  }
                />
                <Hint>
                  <span>{`Available to refund : ${CurrencyFormat(
                    cancelDetails.AmountAvailableToRefund -
                      cancelDetails?.Taxes -
                      cancelDetails?.CancellationFee
                  )}`}</span>
                </Hint>
              </div>
            </div>
            {showErrorMessage("refundAmount", "Refund Amount")}
          </div>
        )}
        <div className="form-group col-12 bz-payment-profile-options">
          <div className="bzc-provider-name">
            <label className="mb-2 d-inline-block k-label">Cancel On</label>
            <div className="bzc-time-sec">
              <DropDownList
                data={cancellationOnDropdownList}
                textField="text"
                dataItemKey="id"
                value={
                  cancellationOnDropdownList?.filter(
                    (i: any) => i?.id === formData?.CancellationOn
                  )?.[0]
                }
                disabled={cancelDetails?.SessionType === SessionTypeEnum.PRESET}
                onChange={(e) => changeFormData(e.value?.id, "CancellationOn")}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          {showErrorMessage("CancellationOn", "Cancel On")}
        </div>
        {/* <div className="form-group col-12 bz-payment-profile-options d-flex">
          <>
              <span className="d-flex align-items-start">
            {" "}
            <Switch
              checked={formData?.CanUtilizeAfterCancellation}
              onChange={(e) =>
                changeFormData(e.value, "CanUtilizeAfterCancellation")
              }
            />
          </span>
            <label className="k-label d-inline-block ml-1">
            Can be redeemed until the expiration of current billing cycle
            </label>
          </>
        </div> */}
        <div className="form-group col-12 bz-payment-profile-options d-flex">
          <>
            <span className="d-flex align-items-start">
              <Switch
                checked={formData?.NotifyCancelationEmailToClient}
                onChange={(e) =>
                  changeFormData(e.value, "NotifyCancelationEmailToClient")
                }
              />
            </span>{" "}
            <label className="k-label d-inline-block ml-1">
              Send cancellation email to client/provider
            </label>
          </>
        </div>
        <div className="form-group col-12 bz-payment-profile-options d-flex mb-0">
          <span className="d-flex align-items-start">
            {" "}
            <Switch
              checked={formData?.CanExcludedFromChurnRate}
              onChange={(e) =>
                changeFormData(e.value, "CanExcludedFromChurnRate")
              }
            />
          </span>{" "}
          <label className="k-label d-inline-block ml-1">
            Consider as upgrade/downgrade the package to exclude from churn rate
          </label>
        </div>
      </>
    );
  };
  const itemRender = (li: any, itemProps: any) => {
    let icon = itemProps.dataItem.IsCreditCard
      ? "fa fa-credit-card"
      : `fa fa-university ${
          itemProps.dataItem?.BankAccountType === 1 && "savingBankAccount"
        }`;
    const ExpiryDate = itemProps.dataItem.IsCreditCard
      ? itemProps.dataItem.ExpiryDate
      : "";
    let value = itemProps.dataItem.MaskedCCNumber;
    if (itemProps?.dataItem?.CardTypeId === -1) {
      icon = "fa fa-dollar-sign";
      value = itemProps?.dataItem?.CardDescriptionWithoutExpiryDate;
    }
    const itemChildren = (
      <span>
        <i className={icon} aria-hidden="true"></i>&nbsp;{value}{" "}
        &nbsp;&nbsp;&nbsp;{ExpiryDate}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };
  const valueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any
  ) => {
    let children: any = [];
    if (value?.MaskedCCNumber) {
      let icon = value?.IsCreditCard
        ? "fa fa-credit-card"
        : `fa fa-university ${
            value?.BankAccountType === 1 && "savingBankAccount"
          }`;
      const ExpiryDate = value?.IsCreditCard ? value?.ExpiryDate : "";
      const val = value?.MaskedCCNumber;
      if (value?.CardTypeId === -1) {
        icon = "fa fa-dollar-sign";
      }
      children = [
        <span key={icon}>
          <i className={icon} aria-hidden="true"></i>&nbsp;{val}{" "}
          &nbsp;&nbsp;&nbsp;{ExpiryDate}
        </span>,
      ];
    } else {
      children = [<span>Please Select</span>];
    }

    return React.cloneElement(element, { ...element.props }, children);
  };
  const showStep2Form = () => {
    return (
      <>
        {formData?.cancellationType?.id === 1 && (
          <>
            <div className="form-group col-12 pr-3 bz-payment-profile-options">
              <div className="bzc-provider-name">
                <label className="mb-2 d-inline-block k-label">
                  Payment Profile
                </label>
                <div className="">
                  <DropDownList
                    id={`paymentProfile`}
                    name={`paymentProfile`}
                    data={paymentProfiles}
                    textField="MaskedCCNumber"
                    // dataItemKey="PaymentProfileID"
                    valueRender={(
                      el: React.ReactElement<HTMLSpanElement>,
                      value: any
                    ) =>
                      PaymentValueRender(el, value, formData?.paymentProfile)
                    }
                    itemRender={PaymentItemRender}
                    onChange={(e) => {
                      changeFormData(e.value, "paymentProfile");
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              {showErrorMessage("paymentProfile", "Payment Profile")}
            </div>
            {checkIfUserAddPaymentGateway() && <div className="addNewBtn">
              <DropDownButton
                text="Add New"
                onItemClick={(e: any) => onCardOrBankAdd(e)}
                items={PaymentMethodItems.filter(
                  (i: any) => i?.visible === true
                )}
                look="flat"
                className="btn btn-link add-new-btn"
              />
            </div>}
          </>
        )}
        <div className="form-group col-12 bz-payment-profile-options">
          <div className="bzc-provider-name">
            <label className="mb-2 d-inline-block k-label">Comments</label>
            <div>
                <TextArea
                  maxLength={1000}
                  style={{width:'250px'}}
                  value={formData?.Notes}
                  onChange={(e:any) => changeFormData(e.value, "Notes")}
                />
                <Hint direction={"end"}>
                  {formData?.Notes?.length || 0} / {1000}
                </Hint>
            </div>
          </div>
        </div>

        {formData?.cancellationType?.id === 1 && (
          <div className="d-flex align-items-center bz-refund-card justify-content-start pb-1">
            <div>
              <div className="bz-badge-circle rounded-circle mr-2">
                <i className="fas fa-dollar-sign"></i>
              </div>
            </div>
            <div className="">
              <div className="m-0 bz-bd-count-card-title">
                {CurrencyFormat(formData?.chargeAmount)}
              </div>
              <span className="mb-0">Total Amount to Charge</span>
            </div>
          </div>
        )}
        {formData?.cancellationType?.id === 2 && (
          <>
            <div
              className={`d-flex align-items-center bz-refund-card justify-content-start pb-1 w-100`}
            >
              <div>
                <div className="bz-badge-circle rounded-circle mr-2">
                  <i className="fas fa-dollar-sign"></i>
                </div>
              </div>
              <div className="">
                <div className="m-0 bz-bd-count-card-title">
                  {CurrencyFormat(formData?.refundAmount)}
                </div>
                <span className="mb-0">Refund Amount</span>
              </div>
            </div>
            {taxAmountToRefund > 0 && (
              <>
                <div className="d-flex align-items-center bz-refund-card justify-content-start pb-1 w-100">
                  <div>
                    <div className="bz-badge-circle rounded-circle mr-2">
                      <i className="fas fa-dollar-sign"></i>
                    </div>
                  </div>
                  <div className="">
                    <div className="m-0 bz-bd-count-card-title">
                      {CurrencyFormat(taxAmountToRefund)}
                    </div>
                    <span className="mb-0">
                      <Tooltip
                        content={(tooltipProps: any) => {
                          return (
                            <TooltipContentRefundTax
                              title={tooltipProps.title}
                              data={taxes}
                              TotalTaxAmount={taxAmountToRefund}
                              Name={cancelDetails.Name}
                            />
                          );
                        }}
                        tooltipClassName="bz-tooltip-table"
                      >
                        <a
                          title="Surcharges"
                          style={{
                            borderBottom: "1px dotted #0d6efd",
                            cursor: "pointer",
                          }}
                        >
                          {"Surcharges to be refunded"}
                        </a>
                      </Tooltip>
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center bz-refund-card justify-content-start pb-1 w-100">
                  <div>
                    <div className="bz-badge-circle rounded-circle mr-2">
                      <i className="fas fa-dollar-sign"></i>
                    </div>
                  </div>
                  <div className="">
                    <div className="m-0 bz-bd-count-card-title">
                      {CurrencyFormat(
                        formData?.refundAmount + taxAmountToRefund
                      )}
                    </div>
                    <span className="mb-0">{"Total Refund Amount"}</span>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  };

  const handleCancel = () => {
    let oldFormData = { ...formData };
    if (oldFormData["step1"]) {
      oldFormData["step1"] = false;
      setFormData(oldFormData);
    } else {
      if (props?.fromDashboard) {
        props.gotoPackageOverview();
      } else {
        props.handleWindow();
      }
    }
  };
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  if (!cancelDetails) {
    return (
      <>
        <div
          className={
            "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
          }
        >
          <div className="bz-norecords-available">
            No Data for Cancellation of Package
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="card card-fluid bz-sub-card mb-2 bz-card bz-cancel-package">
        <div className="card-header bz-no-background">
          <div className="bz-row no-gutters">
            <div className="col-12 col-md-6 p-0 mb-sm-0">
              <span className="h6 bz-border-Inactive-sm bz--left-border pl-2">
                <span className="bz-card-heading text-capitalize">
                  {props?.fromDashboard ? (
                    <>{"Cancel Package"}</>
                  ) : (
                    <>{`${cancelDetails.FirstName} ${cancelDetails.LastName}`}</>
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
        {!cancelDetails?.CancellationRequestedOn ? (
          <div className="card-body p-0">
            <div className="bz-row no-gutters">
              <div className="col">
                <div className="p-4">
                  <h6 className="card-title bz-fw-600 bz-article">
                    {cancelDetails.Name} <small>(Package)</small>
                  </h6>
                  <div className="">
                    <div className="bz-row no-gutters mb-3">
                      <div className="col-12 col-lg-6">
                        <p className="row">
                          <span className="col bz-article">Purchased Date</span>
                          :{" "}
                          <span className="col">
                            {moment(cancelDetails.PurchasedOn).format("L")}
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-lg-6">
                        <p className="row">
                          <span className="col bz-article">
                            <div className="d-flex flex-column justify-content-end">
                              <span>
                                Cost{" "}
                                {cancelDetails?.Taxes > 0 && <span>*</span>}
                              </span>
                              {cancelDetails?.Taxes > 0 && (
                                <small
                                  style={{ fontSize: "10px", color: "#777" }}
                                >
                                  (Exclusion of Surcharges)
                                </small>
                              )}
                            </div>
                          </span>
                          :{" "}
                          <span className="col">
                            {CurrencyFormat(cancelDetails.Cost)}
                          </span>
                        </p>
                      </div>
                      {cancelDetails?.Taxes > 0 && (
                        <div className="col-12 col-lg-6">
                          <p className="row">
                            <span className="col bz-article">
                              Surcharges Collected
                            </span>
                            :{" "}
                            <span className="col">
                              {CurrencyFormat(cancelDetails.Taxes)}
                            </span>
                          </p>
                        </div>
                      )}
                      <div className="col-12 col-lg-6">
                        <p className="row">
                          <span className="col bz-article">
                            Cancellation Fee
                          </span>
                          :{" "}
                          <span className="col">
                            {CurrencyFormat(cancelDetails.CancellationFee)}
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-lg-6">
                        <p className="row">
                          <span className="col bz-article">Schedules</span>:{" "}
                          <span className="col">
                            {cancelDetails?.SessionType === 2
                              ? `${cancelDetails?.SchedulesUsed} of Unlimited`
                              : `${cancelDetails?.SchedulesUsed} of ${cancelDetails?.Schedules}`}
                          </span>
                        </p>
                      </div>
                      <div className="col-12 col-lg-6">
                        <p className="row">
                          <span className="col bz-article">
                            Next Recurrence
                          </span>
                          :{" "}
                          <span className="col">
                            {cancelDetails?.NextRecurrence === null
                              ? "NA"
                              : moment(cancelDetails?.NextRecurrence).format(
                                  "L"
                                )}
                          </span>
                        </p>
                      </div>
                      {formData?.CancellationOn > 0 && (
                        <div className="col-12 col-lg-6">
                          <p className="row">
                            <span className="col bz-article">
                              Cancellation Date
                            </span>
                            :{" "}
                            <span className="col">
                              {formData?.CancellationOn === 1 &&
                                moment().format("L")}
                              {formData?.CancellationOn === 2 &&
                                selectedPeriod?.EffectiveEnd &&
                                moment(selectedPeriod?.EffectiveEnd)
                                  .add(1, "day")
                                  .format("L")}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    {formData?.CancellationOn > 0 && (
                      <Notify key="info" type={{ style: "info" }}>
                        {`After the cancellation date, any remaining appointments cannot be used, and any scheduled future appointments will be automatically cancelled.`}
                      </Notify>
                    )}
                  </div>
                  <div>
                    {props.packageData?.RenewalType ===2 && cancelDetails?.SessionType === SessionTypeEnum.PRESET && (
                      <Notify key="info" type={{ style: "info" }}>
                        {`The renewal will be auto cancelled when the preset package is cancelled.`}
                      </Notify>
                    )}
                  </div>
                  {/* <div
                  className="bz-absolute-position-strip ng-hide"
                  ng-show="that.privateServiceDetail.Notification"
                >
                  <div className="px-3 py-2 bz-badge-circle-light-primary">
                    <span></span>
                  </div>
                </div> */}
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 bzc-border-left">
                <div className="p-4 h-100">
                  <div
                    className={
                      !formData?.step1
                        ? "bz-row no-gutters"
                        : "bz-row no-gutters mb-1 pb-1"
                    }
                  >
                    {!formData?.step1 ? showStep1Form() : showStep2Form()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <CancelledPackage cancelDetails={cancelDetails} />
        )}
      </div>
      {!cancelDetails?.CancellationRequestedOn && (
        <div className="row no-gutters">
          <div className="col-sm-12 mt-2">
            <div className="float-right">
              <BuzopsButton
                loading={btnLoading}
                disabled={submit || btnLoading}
                label={formData?.step1 ? "Complete" : "Next"}
                onClick={() => (formData?.step1 ? submitStep2() : submitForm())}
              />
            </div>

            <div className="float-right">
              <Button
                disabled={btnLoading || submit}
                onClick={() => handleCancel()}
              >
                {formData?.step1 ? "Previous" : "Cancel"}
              </Button>
            </div>
          </div>
        </div>
      )}
      {showPaymentDialog && PaymethodDialog()}
    </>
  );
};

export default withNotification(CancelPackage);
