import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { useSelector } from "react-redux";

const SessionExpired = () => {
  const history = useHistory();
  const redirectionObj = useSelector((state:any)=>state?.redirection)

  const handleRedirection = () => {
    const { firstPathValue, staffuuid, clientMemberId } = redirectionObj
    if (firstPathValue === 'member') {
      history.push(`/${firstPathValue}/${staffuuid}/${clientMemberId}`);
    } else if (firstPathValue === '') {
      history.push(`/`);
    } else {
      history.push(`/${firstPathValue}`);
    }
  }
  return (
    <div className="">
      <div id="bz-notfound">
        <div className="bz-notfound">
          <div className="bz-notfound-404"></div>
          <h1>401</h1>
          <h2>Oops! Session Expired</h2>
          <p>
            <Button primary onClick={() => handleRedirection()}>
              {"Back to Home Page"}
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SessionExpired;
