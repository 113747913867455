import React, { useEffect, useState } from "react";
import { useApiClient } from "services/axios-service-utils";
import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { GetLocalStore } from "utils/storage";

const ViewClientResponse = (props: any) => {
  const [formData, setFormData] = useState<any>([]);
  const [answersData, setAnswerData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { axiosRequest } = useApiClient();
  const errorCallBack = (message: string) => {
    props?.handleNotificationMessage(message, "error");
  };

  useEffect(() => {
    getFormTemplateById();
  }, []);

  function buildOrderedArray(elements: any[]) {
    // Create a map of elements by their id for quick access
    const elementMap = {};
    elements.forEach(element => { 
      elementMap[element.id] = element;
    });
  
    // Create a new array to hold the ordered elements
    const orderedElements: any[] = [];
  
    // Iterate through the elements
    elements.forEach(element => {
      if (element.isContainer) {
        // Push the container itself
        orderedElements.push(element);
  
        // Push its children in the order specified in childItems
        if (element.childItems && element.childItems.length > 0) {
          element.childItems.forEach(childId => {
            if (elementMap[childId]) {
              orderedElements.push(elementMap[childId]);
            }
          });
        }
        
      } else if (!element.parentId) {
        // Push elements without a parentId directly
        orderedElements.push(element);
      }
    });
    return orderedElements;
  }

  const getFormTemplateById = async () => {
    setLoading(true);
    await axiosRequest.get(
      `Forms/FormInstance/${props?.formInstance?.FormInstanceId}`,
      {
        successCallBack: (response: any) => {
          const data = response.data;
          const value = data?.Item?.Values;
          setFormData(buildOrderedArray(value));
          const answers = data?.Item?.Answers;
          setAnswerData(answers);
          setLoading(false);
        },
        errorCallBack: (response: any) => {
          setLoading(false);
          errorCallBack(
            response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] || "Internal server error"
          );
        },
      }
    );
  };
  const renderFile = (file: any) => {
    const tenantDomainName = GetLocalStore("tenantUserSession")?.DomainName;
    const fileName = `${process.env.REACT_APP_DOTNET_APP_URL}/Storage//${tenantDomainName}/Form/${file}`;
    const fileExtension: any = fileName.split(".").pop();
    return (
      <a
        href={fileName}
        download={`${fileName.split("/").pop()}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        View Document
      </a>
    );
    if (["pdf"].includes(fileExtension)) {
      return (
        <embed
          src={fileName}
          type="application/pdf"
          width="100%"
          height="600px"
        />
      );
    } else if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      return (
        <img
          src={fileName}
          alt="File"
          style={{ width: "75px", height: "75px" }}
        />
      );
    } else {
      return (
        <a
          href={fileName}
          download={`${fileName.split("/").pop()}`}
          target="_blank"
        >
          Download File
        </a>
      );
    }
  };

  const getSignImage = (str) => {
    const prefix = 'data:image/png;base64,';
  
    if (str.startsWith(prefix)) {
      return str;
    }
    
    return `${prefix}${str}`;
  }

  const checkVal = (val) =>{
    return val?val:'N/A'
  }
  const renderField = (item: any) => {

    switch (item.element) {
      case "FileUpload":
        return answersData?.[item.field_name]?renderFile(answersData?.[item.field_name]):checkVal(answersData?.[item.field_name]);
      case "Dropdown":
        const val = answersData?.[item.field_name];
        const answer = item?.options?.find((i: any) => i?.value === val)?.text;
        return checkVal(answer);
      case "Signature":
        return (
          (answersData?.[item.field_name])?
          <img
            src={getSignImage(answersData?.[item.field_name])}
            style={{ width: "50px", height: "50px" }}
            alt="Signature"
          />:checkVal(answersData?.[item.field_name])
        );
        case "RadioButtons":
          const radio_selected_option = item.options.filter((option: { key: any; }) => 
            answersData?.[item.field_name].includes(option.key)
        );
          return checkVal(radio_selected_option.map((optionval: { text: any; }) => optionval.text).join(", "));
        case "Checkboxes":
          const checkbox_selected_option = item.options.filter((option: { key: any; }) => 
            answersData?.[item.field_name].includes(option.key)
        );
          return checkVal(checkbox_selected_option.map((optionval: { text: any; }) => optionval.text).join(", "));
          
      default:
        return checkVal(answersData?.[item.field_name]);
    }
  };

  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  console.log(answersData, "answersData");
  console.log(formData, "formData");
  return (
    <div className="bz-plan-overview">
      <div className="left-section">
        <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile mt-2 p-0" style={{border: '1px solid #eee'}}>
          {formData?.map((i: any,index:number) => {
            if (i?.static) {
              return <></>;
            }
            if (i?.isContainer) {
              return (
                <div className="bz-content-text-row" key={i.label}>
                  <h6 className="bz-content-title  pl-2 pt-3  buz-font-600">
                    {i?.label}
                  </h6>
                </div>
              );
            }
            return (
              <div className="bz-form-response-table" key={i.label}>
                <div className="form-description">{i?.label}</div>
                 <div className="form-values">{renderField(i)}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withNotification(ViewClientResponse);
