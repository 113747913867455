import { createSlice } from '@reduxjs/toolkit'

export const genericSlice = createSlice({
  name: 'generic',
  initialState: {
    selectedPaymentMethod: '',
    enteredDownPayment: null,
    showOnsiteAccessConfiguration: false,
    selectedMenuItem: null,
    selectedPlan: null,
    selectedAddon: null,
    navigateToAPageOnButtonClick: null,
  },
  reducers: {
    PaymentMethodSelected: (state, action) => {
      state.selectedPaymentMethod = action.payload
    },
    DownPaymentEntered: (state, action) => {
      state.enteredDownPayment = action.payload
    },
    SetShowOnsiteAccessConfiguration: (state, action) => {
      state.showOnsiteAccessConfiguration = action.payload
    },
    SetSelectedMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload
    },
    SetSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload
    },
    SetSelectedAddon: (state, action) => {
      state.selectedAddon = action.payload
    },
    SetNavigateToAPageOnButtonClick: (state, action) => {
      state.navigateToAPageOnButtonClick = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  PaymentMethodSelected, 
  DownPaymentEntered, 
  SetShowOnsiteAccessConfiguration, 
  SetSelectedMenuItem,
  SetSelectedPlan,
  SetSelectedAddon,
  SetNavigateToAPageOnButtonClick,
} = genericSlice.actions

export default genericSlice.reducer