import Location from "components/Location/Location";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { verbiageMappings } from "utils/form-utils";
import ClientNameWithMemberType from "./ClientNameWithMemberType";

export interface ClientInfoProps {
  clientName: string;
  memberType: number;
  email: string;
  phoneNo: string;
  onEditClick?: any;
  module?: string;
  selectedName?: string;
  selectedSubscription?:any
}
const ClientOverviewInfo = (props: ClientInfoProps) => {
  const Attributes=props?.selectedSubscription?.Attributes
  return (
    <>
      <div className="row align-items-center bz-profile-overview bz-subMember-overview" style={{width: "100%"}}>
        <div className="col-md-9 col-xl-8">
          <div className="bz-summary-profile">
            <div className={"bz-row align-items-sm-center w-100"}>
              
              <div className="d-flex align-items-center mb-1">
                <h5 className="text-capitalize mb-0">{props.selectedName}</h5>
                {Attributes && Attributes !== "" && <Location Attributes={Attributes} showDetails={true} VirtualMeetingLink={props?.selectedSubscription?.VirtualMeetingLink} position={'bottom'}/>}
                <BuzopsTooltip
                  position={"bottom"}
                  html={<span title={verbiageMappings[props.module].toUpperCase() || "None"} className="badge badge-sm badge-info bz-cursor ml-1">
                    {verbiageMappings[props.module][0] || "N"}
                  </span>}
                />
              </div>
              <div className={"user-details pl-0"}>
                <div key={1} className={"d-flex"}>
                  <h6 className="d-inline-flex align-items-center mb-0 mr-2">
                    <ClientNameWithMemberType
                      clientName={props?.clientName}
                      memberType={props?.memberType}
                    />
                  </h6>
                  {
                    props?.email &&
                    <span className="contact mr-2">
                      <i className="fas fa-envelope"></i>
                      {props?.email}
                    </span>
                  }
                  {
                    props?.phoneNo &&
                    <span className="contact">
                      <i className="fas fa-phone-volume"></i>
                      {props?.phoneNo}
                    </span>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          props?.memberType === 0 && props?.onEditClick &&
          <div className="col-md-3 col-xl-4 text-md-right">
            <button
              className="btn btn-link"
              onClick={() => props?.onEditClick()}
            >
              Edit Profile
            </button>
          </div>
        }

      </div>
    </>
  );
};

export default ClientOverviewInfo;
