import React, { useEffect, useState } from "react";
import { FormElement } from "@progress/kendo-react-form";
import { AutoComplete, ListItemProps } from "@progress/kendo-react-dropdowns";
import { TenantService } from "services/tenant/index.service";
import PaymentDropDown from "components/paymentMethod/PaymentDropDown";
import BuzopsButton from "generic-components/BuzopsButton";
import { MdInfo } from "react-icons/md";
import { Hint } from "@progress/kendo-react-labels";
import { MemberTypeEnum } from "utils/form-utils";

const ClientManageForm: React.FC<any> = (props) => {
  const service = new TenantService();
  const [clientModal, setClientModal] = useState(false);
  const [paymentProfile, setPaymentProfile] = useState<any>([]);
  const [selectPayment, setSelectPayment] = useState<any>(null);

  useEffect(() => {
    if (props?.manage?.target?.value && props?.manage?.source?.value) {
      if (
        props?.manage?.source?.value?.MemberType === MemberTypeEnum.PRIMARY &&
        props?.manage?.target?.value?.MemberType === MemberTypeEnum.PRIMARY
      ) {
        fetchPaymentProfiles();
      } else {
        handleManageClients(
          props?.manage?.target?.value,
          props?.manage?.source?.value
        );
      }
    }
  }, [props?.manage]);

  const handleManageClients = (target: any, source: any) => {
    let errorMessage = "";
    if (
      source?.MemberType === MemberTypeEnum.SUBMEMBER &&
      target?.MemberType === MemberTypeEnum.SUBMEMBER
    ) {
      errorMessage =
        "Operation not supported: Merging from Sub member to Sub member.";
    } else if (
      source?.MemberType === MemberTypeEnum.SUBMEMBER &&
      target?.MemberType === MemberTypeEnum.PRIMARY
    ) {
      errorMessage =
        "Operation not supported: Source member must be designated as the primary client.";
    } else if (
      source?.MemberType === MemberTypeEnum.PRIMARY &&
      target?.MemberType === MemberTypeEnum.SUBMEMBER
    ) {
      errorMessage =
        "Operation not supported: Primary client status needed for target client.";
    } else if (
      source?.MemberType === MemberTypeEnum.SUBMEMBER &&
      target?.MemberType === MemberTypeEnum.CHILD
    ) {
      errorMessage = "Operation not supported: Merging family members.";
    } else if (
      source?.MemberType === MemberTypeEnum.CHILD &&
      target?.MemberType === MemberTypeEnum.CHILD
    ) {
      errorMessage = "Operation not supported: Merging family members.";
    } else if (
      source?.MemberType === MemberTypeEnum.CHILD &&
      target?.MemberType === MemberTypeEnum.SUBMEMBER
    ) {
      errorMessage = "Operation not supported: Merging family members.";
    } else if (
      source?.MemberType === MemberTypeEnum.CHILD &&
      target?.MemberType === MemberTypeEnum.PRIMARY
    ) {
      errorMessage =
        "Operation not supported: Source client must have primary client status.";
    } else if (
      source?.MemberType === MemberTypeEnum.PRIMARY &&
      target?.MemberType === MemberTypeEnum.CHILD
    ) {
      errorMessage =
        "Operation not supported: Target client must be designated as the primary client.";
    }
    if (errorMessage) {
      props?.setMessage(errorMessage);
      props?.setNotification((prev: any) => {
        return { ...prev, error: true };
      });
    }
    return errorMessage;
  };
  async function fetchPaymentProfiles() {
    const manaageid = props?.manage["target"]?.value?.UserMemberId;
    const result = await service.paymentProfile(manaageid);
    const filterData = result?.filter(
      (itm: any) =>
        itm?.CardDescription?.trim()?.toLowerCase() !== "cash" &&
        itm.CardTypeId !== 8
    );
    setPaymentProfile(filterData);
    if (filterData?.length !== 0) {
      setSelectPayment({
        ...filterData[0],
        PaymentProfileId: filterData[0]?.PaymentGatewayPaymentProfileID,
      });
    } else {
      props?.setMessage(
        "Target client do not have active payment profile. Please add and continue."
      );
      props?.setNotification((prev: any) => {
        return { ...prev, error: true };
      });
    }
  }
  const changePaymentProfile = (item: any) => {
    setSelectPayment({
      ...item,
      PaymentProfileId: parseInt(item?.PaymentGatewayPaymentProfileID),
    });
  };
  const mergeClient = async () => {
    const check = handleManageClients(
      props?.manage?.target?.value,
      props?.manage?.source?.value
    );
    if (check) {
      return;
    }
    if (paymentProfile?.length <= 0) {
      props?.setMessage(
        "Target client do not have active payment profile. Please add and continue."
      );
      props?.setNotification((prev: any) => {
        return { ...prev, error: true };
      });
      return;
    }
    if (!selectPayment) {
      props?.setClientSubmitBtnLoading(false);
      props?.setMessage("Please select Payment method");
      props?.setNotification((prev: any) => {
        return { ...prev, error: true };
      });
      return;
    }
    if (selectPayment?.CardDescription?.trim()?.toLowerCase() === "cash") {
      props?.setClientSubmitBtnLoading(false);
      props?.setMessage("Please select Payment method");
      props?.setNotification((prev: any) => {
        return { ...prev, error: true };
      });
      return;
    }
    const data = {
      SourceUserMemberId: props?.manage?.source?.value?.UserMemberId,
      TargetUserMemberId: props?.manage?.target?.value?.UserMemberId,
      PaymentGatewayPaymentProfileId:
        selectPayment?.PaymentGatewayPaymentProfileID,
    };
    props?.setClientSubmitBtnLoading(true);
    const res = await service?.mergeClient(data);
    props?.setClientSubmitBtnLoading(false);

    if (res?.ResponseCode === 100) {
      props?.setMessage("Merging is done succesfully");
      setClientModal(true);
      return;
    }
    if (res?.ResponseCode === 200) {
      props?.setMessage(res?.ErrorMessages[0]);
      props?.setNotification((prev: any) => {
        return { ...prev, error: true };
      });
    }
  };
  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = `${itemProps?.dataItem?.FullName} ${itemProps?.dataItem?.Email}`;

    return React.cloneElement(li, li.props, itemChildren);
  };
  if (clientModal) {
    return (
      <>
        <div className="Self-CheckIn">
          <h6>
            {props?.manage?.source?.value?.FullName} is merged to{" "}
            {props?.manage?.target?.value?.FullName}{" "}
          </h6>
          <h6>
            Your new client profile name is{" "}
            {props?.manage?.target?.value?.FullName}.
          </h6>
          <h6>
            Please click okay to {props?.manage?.target?.value?.FullName}{" "}
            dashboard.
          </h6>
        </div>
        <div className="bz-row pt-3 pb-2 float-right k-mt-5">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <BuzopsButton
              label={"cancel"}
              primary={false}
              // loading={props.clientSubmitBtnLoading}
              onClick={(e: any) => {
                props?.setClientSubmitBtnLoading(false);
                setClientModal(false);
                props?.onClose();
              }}
              fillMode="outline"
              className="mr-0"
            />
          </div>
          <BuzopsButton
            primary
            label={"Okay"}
            disable={
              !props?.manage?.source?.value?.UserMemberId ||
              !props?.manage?.target?.value?.UserMemberId
            }
            // loading={props.clientSubmitBtnLoading}
            onClick={(e: any) => {
              props?.setClientSubmitBtnLoading(false);
              props.handleMergeSelectionSubmit(e, props?.manage?.target);
              setClientModal(false);
              props?.onClose();
            }}
            className="mr-0"
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="alert alert-lighter mt-0 mb-2 px-0 pb-0" role="alert">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <div>
            {" "}
            <MdInfo color="red" style={{ fontSize: "25px" }} />
          </div>
          <div style={{ flexGrow: "1" }}>
            {" "}
            Prior to merging any active paid client subscriptions, please ensure
            that the target client has active payment methods.
            <br />
            <b>Note:</b> Primary member merging is supported. However, merging
            sub-members is presently not supported.
          </div>
        </div>
      </div>
      <FormElement style={{ width: "100%" }}>
        <div className="">
          <div className="bz-row">
            <div className="col-12 p-0">
              <label htmlFor="">Merge From</label>
              <AutoComplete
                data={props.clientsList}
                itemRender={itemRender}
                value={
                  props?.manage?.["source"]?.type === "search"
                    ? props?.manage?.["source"]?.value
                    : props?.manage?.["source"]?.value?.FullName
                }
                onChange={(e: any) => props?.handleManageClient(e, "source")}
                placeholder={"Select a Source Client"}
                loading={props?.clientSearchLoading}
                listNoDataRender={() => (
                  <span className={"bzo-noData-found"}>
                    {props?.clientSearchLoading
                      ? "Searching for Client..."
                      : "No Data Found"}
                  </span>
                )}
              />
            </div>
          </div>
        </div>
      </FormElement>
      <FormElement style={{ width: "100%" }}>
        <>
          <FormElement style={{ width: "100%" }}>
            <div className="">
              <div className="bz-row k-mt-3">
                <div className="col-12 p-0">
                  <label htmlFor="">Merge To</label>

                  <AutoComplete
                    data={props.clientsList?.filter(
                      (itm: any) =>
                        itm?.Email !== props?.manage?.source?.value?.Email
                    )}
                    itemRender={itemRender}
                    value={
                      props?.manage?.["target"]?.type === "search"
                        ? props?.manage?.["target"]?.value
                        : props?.manage?.["target"]?.value?.FullName
                    }
                    onChange={(e: any) => props.handleManageClient(e, "target")}
                    placeholder={"Select a Target Client"}
                    loading={props?.clientSearchLoading}
                    listNoDataRender={() => (
                      <span className={"bzo-noData-found"}>
                        {props?.clientSearchLoading
                          ? "Searching for Client..."
                          : "No Data Found"}
                      </span>
                    )}
                  />
                </div>
              </div>
            </div>
          </FormElement>
          {props?.manage?.target?.value?.FullName &&
            paymentProfile.length > 0 && (
              <div className="bz-row">
                <div className="col-12 k-mt-3  p-0">
                  <label htmlFor="">Payment Method</label>

                  <PaymentDropDown
                    merge={true}
                    paymentProfiles={paymentProfile}
                    selectedValue={selectPayment}
                    changePaymentProfile={changePaymentProfile}
                  />
                  <Hint>
                    <small>
                      {`Please note that the payment profile of `}
                      <b>{props?.manage?.target?.value?.FullName}</b>
                      {` will be used for any subscriptions for `}
                      <b>{props?.manage?.source?.value?.FullName}</b>.
                      <b>{props?.manage?.source?.value?.FullName}</b>
                      {` payment profile will not be merged.`}
                    </small>
                  </Hint>
                </div>
              </div>
            )}
        </>
      </FormElement>
      <div className="bz-row pt-3 pb-2 float-right k-mt-5">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <BuzopsButton
            label={"cancel"}
            primary={false}
            onClick={(e: any) => {
              props?.setClientSubmitBtnLoading(false);
              props?.onClose();
            }}
            fillMode="outline"
            className="mr-0 mergebutton"
          />
        </div>
        <BuzopsButton
          primary
          label={"Merge Clients"}
          disable={
            !props?.manage?.source?.value?.UserMemberId ||
            !props?.manage?.target?.value?.UserMemberId
          }
          loading={props.clientSubmitBtnLoading}
          onClick={(e: any) => {
            mergeClient();
          }}
          className="mr-0 mergebutton"
        />
      </div>
    </>
  );
};
export default ClientManageForm;
