import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Notification as Notify } from "@progress/kendo-react-notification";
import { Error } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { filterBy } from "@progress/kendo-data-query";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import {
  trainerItemRender,
  trainerValueRender,
} from "components/Trainers/TrainerRenderItems";
import { getFullDate, getTimeFormat, getUserTimeZoneLong } from "utils";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import withNotification from "components/Hoc/withNotification";
import MemberSelection from "components/submemberseletion/MemberSelection";
import { useHistory } from "react-router-dom";

const ComplimentaryCheckout = (props: any) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [availServices, setAvailServices] = useState<any>([]);
  const [originalServices, setOriginalServices] = useState<any>([]);
  const [providerList, setProviderList] = useState<any>([]);
  const [origProviderList, setOrigProviderList] = useState<any>([]);
  const [selectedItems, setselectedItems] = useState<any>({
    provider: undefined,
    service: undefined,
    notes: undefined,
    signOffDate: new Date(moment().format()),
  });
  const [unAvailableList, setUnAvailableList] = useState<any>([]);
  const [unAvailableListDialog, setUnAvailableListDialog] = useState(false);
  const [validateBtnLoading, setValidateBtnLoading] = useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [showOnlyComplimentary, setShowOnlyComplimentary] = useState(false);
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  let userInfo;
  if(props?.userData){
    userInfo={...props?.userData,PrimaryMemberId:props?.userData?.UserMemberId}
  }
  const [userDetails, setUserDetails] = useState<any>(userInfo || undefined);
  const maximum = new Date(moment().format());

  const service = new TenantService();

  useEffect(() => {
    fetchAllApis();
    getAllSubMembers(props?.userData?.MainUserId || props?.userData?.UserMemberId, props?.userData?.UserMemberId)
  }, []);


  useEffect(() => {
    let timer = 1000;
    let timer1 = setTimeout(() => {
      if (success) {
        handleRedirection();
      }
    }, timer);
    return () => {
      clearTimeout(timer1);
    };
  }, [success]);

  const handleRedirection=()=>{
    props?.handleSelectionPage("complimentaryCheckout", false);
  }

  const getCurrentClientData = (allUsersData: any, userMemberId: any) => {
    return allUsersData?.find((val: any) => val.UserMemberId === userMemberId) || {}
  }

  async function updateSelectedClientData(userData: any) {
    const result = userData;
    if (result) {
      const clientSubmitDetails = {
        Email: result?.Email,
        FirstName: result?.FirstName,
        Photo: result?.Photo,
        LastName: result?.LastName,
        FullName: result?.FullName,
        PrimaryMemberId: result?.ParentId || result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        MemberType: result?.MemberType,
        Status:result?.UserMemberStatus || result?.Status
      };
      setUserDetails(clientSubmitDetails);
    }
  }

  const getAllSubMembers = async (memberId: any, userMemberId: any = null) => {
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId);
    setAllClientsList(res);
    updateSelectedClientData(getCurrentClientData(res, userMemberId || memberId))
  }

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchMemberServices();
    await fetchTrainers();
    setLoading(false);
  };

  async function fetchTrainers() {
    const result = await service.getTrainers();
    if (result) {
      setProviderList(result);
      setOrigProviderList(result);
    }
  }


  const fetchMemberServices = async () => {
    let memberId = props?.userData?.UserMemberId;    
    if (memberId) {
      const req = {
        IsPaidScheduledOnly: false,
        AllowWithOutPurchaseServices: true,
        ShowOnlyComplementaryServices: true,        
        UserMembers: [
          {
            UserMemberId: memberId,
          },
        ],
      };      
      const res = await service.getServiceInstance(req);
      if (res.length > 0) 
      {
         if(props?.EntityId)
         {                    
            const selectedService = res.filter((item: any) => item?.PrivateServiceInstanceId === parseInt(props?.EntityId))?.[0];          
            setselectedItems({                      
                provider: undefined,
                service: selectedService,
                notes: undefined,
                signOffDate: new Date(moment().format()),
            });          
         }
          setAvailServices(res);
          setOriginalServices(res);
        }
      }
    };

  const toggleAvailibilityDialog = () => {
    setUnAvailableListDialog(!unAvailableListDialog);
  };

  const showErrorMessage = (key: any, mainKey: any) => {
    if (!selectedItems[key] || selectedItems[key].length <= 0) {
      if(key==='service'){
        return <Error>{`Please provide Unpaid/Complimentary service`}</Error>
      }
      return <Error>{`${mainKey} is required`}</Error>;
    }
    return <></>;
  };
  const handleCheckoutValidation = () => {
    if (
      !selectedItems?.service ||
      !selectedItems?.provider ||
      !selectedItems?.notes
    ) {
      return false;
    }
    return true;
  };
  const handleSkip=()=>{
    const selectedService=availServices.filter((i:any)=>i?.PrivateServiceType === 3)[0]
    setShowOnlyComplimentary(!showOnlyComplimentary)
    setselectedItems({                      
      provider: undefined,
      service: selectedService,
      notes: undefined,
      signOffDate: new Date(moment().format()),
    });   
  }

  const handleCloseComplimentary=()=>{
    setShowOnlyComplimentary(!showOnlyComplimentary)
    setselectedItems({                      
      provider: undefined,
      service: undefined,
      notes: undefined,
      signOffDate: new Date(moment().format()),
    });   
  }

  const renderUnAvailableListDialog = () => {
    return (
      <Dialog
        className="bz-mybookings-dialog-box bz-custom-content-height"
        title={"Confirm Complimentary Checkout"}
        onClose={toggleAvailibilityDialog}
        width={440}
      >
        {unAvailableList.length === 0 ? (
          <div className={"bz-no-upcoming-appoinments text-muted"}>
            There is no other appointment scheduled with the selected sign off date.
          </div>
        ) : (
          <>
            <div className={""}>
            {`There is another appointment already scheduled with the selected sign off date for ${selectedItems?.provider?.FullName}, do you still want to continue?`}
          </div>
          </>
        )}
        <DialogActionsBar>
          <Button onClick={toggleAvailibilityDialog} disabled={submitBtnLoading}>{"Cancel"}</Button>
          <BuzopsButton
            loading={submitBtnLoading}
            disabled={submitBtnLoading}
            label={
              unAvailableList.length === 0 ? "Checkout" : "Checkout Anyway"
            }
            onClick={() => handleCheckout()}
          />
        </DialogActionsBar>
      </Dialog>
    );
  };

  const handleChange = async (val: any, name: string) => {
    setselectedItems({
      ...selectedItems,
      [name]: val,
    });
  };

  const filterChange = (e: any) => {
    const filteredData = filterData(e.filter);
    setAvailServices(filteredData);
  };

  const filterData = (filter: any) => {
    const data = originalServices.slice();
    return filterBy(data, filter);
  };

  const trainerfilterChange = (e: any) => {
    const filteredData = trainerfilterData(e.filter);
    setProviderList(filteredData);
  };

  const trainerfilterData = (filter: any) => {
    const data = origProviderList.slice();
    return filterBy(data, filter);
  };

  const handleValidation = async () => {
    const check = handleCheckoutValidation();
    setSubmit(true)
    if (!check) {
      return;
    }

    const StartDateTime =
      moment(selectedItems?.signOffDate).format("YYYY-MM-DD HH:mm") + `:00`;
    const StartDate = moment(selectedItems?.signOffDate).format("YYYY-MM-DD");
    const StartTime = moment(selectedItems?.signOffDate).format("YYYY-MM-DD");
    const EndDateTime = moment(StartDateTime)
      .add(selectedItems?.service?.Duration, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
    const tzvalueName=Intl.DateTimeFormat().resolvedOptions().timeZone;
    let tzlabelVal;
    if(!props?.staffPage){
      tzlabelVal = getUserTimeZoneLong(tzvalueName)
    }
    const ScheduleReq = {
      ScheduleId: 0,
      UserAssociateId: selectedItems?.provider?.AssociateId,
      UserId: selectedItems?.provider?.UserId,
      Schedules: [
        {
          StartDateTime,
          EndDateTime,
          StartDate,
          StartTime,
        },
      ],
      UserTimeZoneId: tzlabelVal,
    };
    setValidateBtnLoading(true);
    const result = await service.validateAppointmentsOverlap(ScheduleReq);
    if (result.length > 0) {
      const records = result?.filter((item: any) => item.IsAvailable === false);
      if (records.length > 0) {
        setUnAvailableListDialog(true);
        setUnAvailableList(records);
        setValidateBtnLoading(false);
      } else {
        setUnAvailableList([]);
        await handleCheckout();
        setValidateBtnLoading(false);
      }
    } else {
      setUnAvailableList([]);
      await handleCheckout();
      setValidateBtnLoading(false);
    }
  };
  const handleCheckout = async () => {
    // availability api condition
    const StartDateTime =
      moment(selectedItems?.signOffDate).format("YYYY-MM-DD HH:mm") + `:00`;
    const EndDateTime = moment(StartDateTime)
      .add(selectedItems?.service?.Duration, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
    const maxDate = moment().format("YYYY-MM-DD HH:mm") + `:00`;
    const req = {
      UserMemberId: userDetails?.UserMemberId,
      HasCheckoutSession: true,
      ShowOnlyComplementaryServices: true,
      UserId: userDetails?.UserId,
      MemberName: userDetails?.FullName,
      PurchasedServiceId: selectedItems?.service?.PrivateServiceInstanceId,
      ShowNoCheckSessionAvailableMessage: false,
      EntityId: selectedItems?.service?.PrivateServiceInstanceId,
      Status: 2,
      FromTime: StartDateTime,
      ToTime: EndDateTime,
      ScheduleInformation: `You will register a completed ${
        selectedItems?.service?.PrivateServiceType === 2
          ? "Unpaid"
          : "Complimentary  One-on-One"
      }  once you click "submit" below. Please provide any relevant notes associated with this One-on-One.`,
      Duration: selectedItems?.service?.Duration,
      TrainerComments: selectedItems?.Notes,
      CompletedDate: StartDateTime,
      MaxDate: maxDate,
      HasPrivateServiceInstance: !selectedItems?.service?.IsPrivateService,
      HasPaidSchedules: false,
      TrainerName: selectedItems?.provider?.FullName,
      ScheduledTrainerId: selectedItems?.provider?.AssociateId,
      IsComplimentary:selectedItems?.service?.PrivateServiceType ===1 ? true : false
    };
    // checkout api
    setSubmitBtnLoading(true);
    const result = await service.serviceinstanceSignOff(req);
    setUnAvailableListDialog(false);
    setSubmitBtnLoading(false);

    if (result?.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Complimentary Checkout done successfully`,
        "success"
      );
      setSuccess(true)
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in Complimentary Checkout";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  return (
    <>
      <h5 className="mb-3 bz_fs_1_5">
        <button
          className="btn btn-primary bz-back-btn btn-sm"
          onClick={() =>{
              if(!showOnlyComplimentary){
                props?.handleSelectionPage("complimentaryCheckout", false)
              }else{
                handleCloseComplimentary()
              }
            }
          }
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;Complimentary Checkout
      </h5>
      <hr />
      {loading ? (
        <>
          <BuzopsLoader type="list" />
        </>
      ) : (
        <>
          {!props?.EntityId && !showOnlyComplimentary && (<div className="alert alert-warning fade show" role="alert">
              <span>
                  {`To link the complimentary appointment with any existing One-on-One service, kindly select the service below for audit. However, if you prefer to proceed without tagging the One-on-One,`} <span className="btn btn-link" onClick={handleSkip}>skip and continue</span></span>
            </div>)}
          <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation bz-complementaryCheckout">
            <div className="left-section">
              <div className="">
                <div className="bz-content-text-row align-items-start">
                  <div className="bz-content-title text-right">
                  Service Name<span className="required-asterisk">*</span>
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <DropDownList
                      data={!showOnlyComplimentary ? availServices.filter((serv:any)=>serv?.PrivateServiceType ===1):availServices.filter((serv:any)=>serv?.PrivateServiceType ===3)}
                      textField="Name"
                      dataItemKey="PrivateServiceInstanceId"
                      value={selectedItems?.service || {PrivateServiceInstanceId:0,Name:'Please select service name'}}
                      onChange={(e) => handleChange(e.target.value, "service")}
                      listNoDataRender={() => (
                        <span className={"bzo-noData-found"}>
                          No services available
                        </span>
                      )}
                      disabled={props?.EntityId || showOnlyComplimentary}
                      filterable={true}
                      style={{ width: "100%" }}
                      onFilterChange={(e) => filterChange(e)}
                      className={"customDropdown"}
                    />
                    {submit && showErrorMessage("service", "Please provide Unpaid/Complimentary service")}
                  </div>
                </div>
                {selectedItems?.service && (
                  <>
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right"></div>
                      <span className="bz-colon text-center"></span>
                      <div className="bz-content-body">
                        <div className="bz-plan-note">
                          <p className="mb-0">
                            <Notify key="info" type={{ style: "info" }}>
                              {`You will register a completed ${
                                selectedItems?.service?.PrivateServiceType === 2
                                  ? "Unpaid"
                                  : "Complimentary  One-on-One"
                              }  once you click "submit" below. Please provide any relevant notes associated with this One-on-One.`}
                            </Notify>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bz-content-text-row align-items-start">
                      <div className="bz-content-title text-right">
                        Select Client
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        {userDetails?.UserMemberId && (<>
                          <MemberSelection allClientsList={AllClientsList} selectedUsermemberId={userDetails?.UserMemberId} handleUpdateClient={(val:any)=>updateSelectedClientData(val)}/>
                        </>)}
                      </div>
                    </div>
                    <div className="bz-content-text-row align-items-start">
                      <div className="bz-content-title text-right">
                        Sign Off Date
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        <DateTimePicker
                          defaultValue={selectedItems?.signOffDate}
                          max={maximum}
                          onChange={(e) =>
                            handleChange(e.target.value, "signOffDate")
                          }
                        />
                      </div>
                    </div>
                    <div className="bz-content-text-row align-items-start">
                      <div className="bz-content-title text-right">
                        Provider <span className="required-asterisk">*</span>
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body bz-complementary-provider-dropdown">
                        <DropDownList
                          data={providerList}
                          textField="FullName"
                          dataItemKey="UserId"
                          className={"customDropdown"}
                          valueRender={(
                            el: React.ReactElement<HTMLSpanElement>,
                            value: any
                          ) =>
                            trainerValueRender(
                              el,
                              value,
                              selectedItems?.provider
                            )
                          }
                          itemRender={(
                            li: React.ReactElement<HTMLLIElement>,
                            itemProps: ListItemProps
                          ) =>
                            trainerItemRender(
                              li,
                              itemProps,
                              selectedItems?.Provider
                            )
                          }
                          onChange={(e) =>
                            handleChange(e.target.value, "provider")
                          }
                          filterable={true}
                          style={{ width: "100%" }}
                          onFilterChange={(e) => trainerfilterChange(e)}
                        />
                        {submit && showErrorMessage("provider", "Provider")}
                      </div>
                    </div>
                    <div className="bz-content-text-row align-items-start">
                      <div className="bz-content-title text-right">Notes <span className="required-asterisk">*</span></div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        <textarea
                          className="form-control"
                          onChange={(e) =>
                            handleChange(e.target.value, "notes")
                          }
                          value={selectedItems?.notes}
                        ></textarea>
                        {submit && showErrorMessage("notes", "Notes")}
                      </div>
                    </div>
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right"></div>
                      <span className="bz-colon text-center"></span>
                      <div className="bz-content-body">
                        <Button
                          onClick={() =>{
                              if(!showOnlyComplimentary){
                                props?.handleSelectionPage("complimentaryCheckout", false)
                              }else{
                                handleCloseComplimentary()
                              }
                            }
                          }
                          disabled={validateBtnLoading || success}
                        >
                          Cancel
                        </Button>
                        <BuzopsButton
                          loading={validateBtnLoading}
                          disabled={validateBtnLoading || success}
                          label={"Submit"}
                          onClick={() => handleValidation()}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {unAvailableListDialog && renderUnAvailableListDialog()}
    </>
  );
};

export default withNotification(ComplimentaryCheckout);
