import { DropDownList } from "@progress/kendo-react-dropdowns";
import "./index.scss";
import ReportConstants from "./Utilities/constants";
import CustomCalendar from "atoms/CustomCalendar";

export enum reportFilters {
  TODAY = "Today",
  ThisWeek = "This Week",
  ThisMonth = "This Month",
  ThisQuarter = "This Quarter",
  ThisYear = "This Year",
  Yesterday = "Yesterday",
  PreviousWeek = "Previous Week",
  PreviousMonth = "Previous Month",
  PreviousQuarter = "Previous Quarter",
  PreviousYear = "Previous Year",
  Custom = "Custom",
}

const ReportsCommonFilters = ({
  history,
  location,
  showDate = true,
  showReports = true,
  match,
  onlyCustom = false,
  setFromDateToDate,
}: any) => {
  const uuid = match?.params?.uuid;

  const dropDownData = [
    ...ReportConstants.reports,
    ...ReportConstants.subscribers,
    ...ReportConstants.sales,
    ...ReportConstants.attendance,
    ...ReportConstants.revenue,
    ...ReportConstants.payroll,
    ...ReportConstants.payments,
  ];

  const handleChange = (event: any) => {
    let data = dropDownData.filter((el) => el.name === event.target.value);
    let link;
    if (data) {
      link = `/app/reports/${uuid}` + data[0].url;
    }
    history.push(data && link);
  };

  return (
    <div className="row reports-container">
      <div
        className="col-4 reports-header bz-cursor-pointer"
        onClick={() => history.push(`/app/reports/${uuid}`)}
      >
        <h2>Reports</h2>
      </div>

      <div className="col-4 reports-date-range">
        {showDate && !onlyCustom && (
          <CustomCalendar
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            key={"no change"}
            setFromDateToDate={setFromDateToDate}
            selectedDateItem={"This Year"}
            onlyPastDates
          />
        )}
      </div>

      <div className="col-4 reports-side-header">
        {showReports && (
          <DropDownList
            className="reports-date-dropdown"
            data={dropDownData.map((dropdown) => dropdown.name)}
            value={dropDownData.map((dropdown) =>
              `/app/reports/${uuid}` + dropdown.url === location.pathname
                ? dropdown.name
                : ""
            )}
            onChange={handleChange}
          />
        )}
      </div>
      <div className="reports-horizontal-line">
        {location.pathname === "/reports" && <hr />}
      </div>
    </div>
  );
};

export default ReportsCommonFilters;
