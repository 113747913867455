import React from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { GetLocalStore } from "utils/storage";
import PaymentProfileSelection from "components/checkout/PaymentProfileSelection";
import ShowBankForm from "components/checkout/ShowBankForm";
import ShowCardForm from "components/checkout/ShowCardForm";
import CustomFieldsUpdate from "components/ManageCustomFields/CustomFieldsUpdate.view";
import StripePayments from "components/stripePayment";
import SquarePaymentForm from "components/square-payment-form/paymentsv2";

const PaymentCheckout = (props: any) => {
  const { card, bank } = props.paymentMethod;
  const userDetails = GetLocalStore("userDetails");
  const userConfiguration =
    userDetails?.ChargehubSquarePaymentProfile || undefined;
  const selected = props.selected;
  const selectedPaymentValue = props.selectedPaymentProfile.selectedPayment;
  const ExpiryDate = props.selectedPaymentProfile.selectedPayment?.IsCreditCard
    ? props.selectedPaymentProfile.selectedPayment?.ExpiryDate
    : "";

  const PaymentGatewayForm = () => {
    switch (props.paymentGatewayType) {
      case 0:
        console.log('ChargeHub')

        //PaymentGatewayType->ChargeHub
        return (
          <PaymentProfileSelection
            paymentProfile={props.paymentProfile}
            handleChangeSavedCard={(val: any) =>
              props.handleChangeSavedCard(val)
            }
            selectedPaymentProfile={props.selectedPaymentProfile}
            paymentGatewayType={props.paymentGatewayType}
            paymentMethod={props.paymentMethod}
            handleForm={(val: string) => props.handleForm(val)}
            handleSubmit={props.handleSubmit}
            staffPage={props.staffPage}
          />
        );
        break;
      case 1:
        console.log('AuzthorizeNet')

        //PaymentGatewayType->AuzthorizeNet
        return (
          <>
            <PaymentProfileSelection
              paymentProfile={props.paymentProfile}
              handleChangeSavedCard={(val: any) =>
                props.handleChangeSavedCard(val)
              }
              selectedPaymentProfile={props.selectedPaymentProfile}
              paymentGatewayType={props.paymentGatewayType}
              paymentMethod={props.paymentMethod}
              handleForm={(val: string) => props.handleForm(val)}
              handleSubmit={props.handleSubmit}
              staffPage={props.staffPage}
            />

            {bank && (
              <ShowBankForm
                bankFormValues={props.bankFormValues}
                bankSubmitBtnLoading={props.bankSubmitBtnLoading}
                handleBankSubmit={props.handleBankSubmit}
              />
            )}
            {card && (
              <ShowCardForm
                cardFormValues={props.cardFormValues}
                cardSubmitBtnLoading={props.cardSubmitBtnLoading}
                handleCardSubmit={props.handleCardSubmit}
                cardImageIcon={props.cardImageIcon}
                handleCardImageIcon={(url: any) =>
                  props.handleCardImageIcon(url)
                }
              />
            )}
          </>
        );

      case 3:
        return (
          <>
            <PaymentProfileSelection
              paymentProfile={props.paymentProfile}
              handleChangeSavedCard={(val: any) =>
                props.handleChangeSavedCard(val)
              }
              selectedPaymentProfile={props.selectedPaymentProfile}
              paymentGatewayType={props.paymentGatewayType}
              paymentMethod={props.paymentMethod}
              handleForm={(val: string) => props.handleForm(val)}
              handleSubmit={props.handleSubmit}
              staffPage={props.staffPage}
            />

            {card && (
              <SquarePaymentForm
                handleCardSubmit={props.handleCardSubmit}
                userConfiguration={userConfiguration}
                cardSubmitBtnLoading={props.cardSubmitBtnLoading}
                UserMemberId={props?.UserMemberId}
              />
            )}
          </>
        );
      case 4:
          // console.log('Stripe')
  
          //PaymentGatewayType->AuzthorizeNet
          return (
            <>
              <PaymentProfileSelection
                paymentProfile={props.paymentProfile}
                handleChangeSavedCard={(val: any) =>
                  props.handleChangeSavedCard(val)
                }
                page={props?.page}
                checkPaymentProfileNotRequired={props.checkPaymentProfileNotRequired}
                selectedPaymentProfile={props.selectedPaymentProfile}
                paymentGatewayType={props.paymentGatewayType}
                paymentMethod={props.paymentMethod}
                handleForm={(val: string) => props.handleForm(val)}
                handleSubmit={props.handleSubmit}
                staffPage={props.staffPage}
              />
  
              {bank && (
                <StripePayments
                  bankFormValues={props.bankFormValues}
                  bankSubmitBtnLoading={props.bankSubmitBtnLoading}
                  handleBankSubmit={props.handleBankSubmit}
                  type={"bank"}
                  UserMemberId={props?.UserMemberId}
                />
              )}
              {card && (
                <StripePayments
                  handleCardSubmit={props.handleCardSubmit}
                  userConfiguration={userConfiguration}
                  cardSubmitBtnLoading={props.cardSubmitBtnLoading}
                  type={"card"}
                  UserMemberId={props?.UserMemberId}
                />
              )}
            </>
          );
          
      default:
        //PaymentGatewayType->ChargeHub
        return (
          <PaymentProfileSelection
            paymentProfile={props.paymentProfile}
            handleChangeSavedCard={(val: any) =>
              props.handleChangeSavedCard(val)
            }
            selectedPaymentProfile={props.selectedPaymentProfile}
            paymentGatewayType={props.paymentGatewayType}
            paymentMethod={props.paymentMethod}
            handleForm={(val: string) => props.handleForm(val)}
            handleSubmit={props.handleSubmit}
            staffPage={props.staffPage}
          />
        );
    }
  };

  const PaymentPanel = (props: any, selected: any) => {
    return (
      <PanelBarItem
        title={PaymentPanelTitle()}
        id="1"
        selected={selected == "1"}
        expanded={selected == "1"}
        focused={selected == "1"}
      >
        {PaymentGatewayForm()}
      </PanelBarItem>
    );
  };

  const AdditionalInformationPanel = (props: any, selected: any) => {
    return (
      <PanelBarItem
        title={`Additional Information`}
        id="3"
        selected={selected == "3"}
        expanded={selected == "3"}
        focused={selected == "3"}
      >
        <div className="px-2 py-4">
          <CustomFieldsUpdate {...props?.customFields} />
        </div>
      </PanelBarItem>
    );
  };

  const PaymentPanelTitle = () => {
    let val = selectedPaymentValue?.MaskedCCNumber;
    let expiryDate = ExpiryDate;
    if (expiryDate === null) {
      expiryDate = "";
    }
    if (selectedPaymentValue?.CardTypeId === -1) {
      expiryDate = "";
      val = selectedPaymentValue?.CardDescriptionWithoutExpiryDate;
    }
    return selectedPaymentValue &&
      Object.keys(selectedPaymentValue).length !== 0
      ? `Payment: ${val} ${expiryDate}`
      : "Payment: Please Select / Add";
  };

  return (
    <div className="checkout-panelbar-wrapper payement-panel-header">
      <PanelBar
        expandMode={"single"}
        onSelect={(e) => {
          props.onSelect(e);
        }}
        key={selected?.toString()}
      >
      {(props?.submitPlanData?.MembershipPlanDetail?.MonthlyRate > 0 || props?.submitPlanData?.SelectedAddons?.length > 0 || props?.submitPlanData?.DownPayment > 0)  && PaymentPanel(props, selected)}
      {props?.customFields?.fieldsData?.length > 0 &&  AdditionalInformationPanel(props,selected)}
      </PanelBar>
    </div>
  );
};

export default PaymentCheckout;
