import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const SuccessForm = (props: any) => {
  const history = useHistory();
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    if (!props?.clientDashboard) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            return prevSeconds;
          }
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, []);

  const handleNavigation = () => {
    const ReturnUrl = props?.location?.state?.ReturnUrl || props?.ReturnUrl;
    const FormCategory = props?.location?.state?.FormCategory || props?.FormCategory;
    const redirection =
      props?.location?.state?.redirection || props?.redirection;
    const shortcode = props?.location?.state?.shortcode || props?.shortcode;
    if (redirection == "false") {
      if(FormCategory==="Referral"){
        history.push(`referral/lead/${shortcode}?redirection=${redirection}`);
      }else{
        history.push(`${shortcode}?redirection=${redirection}`);
      }
    } else if (ReturnUrl && ReturnUrl !=="null") {
      window.location.href = ReturnUrl;
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    if (seconds === 0 && !props?.clientDashboard) {
      handleNavigation();
    }
  }, [seconds]);
  const redirection =
  props?.location?.state?.redirection || props?.redirection;
  return (
    <div className="online-form-generator m-2 p-2">
      <div className="alert alert-success" role="alert">
        Form Submitted Successfully.<br/>
        Thank you! Your response has been recorded, and a copy has been sent to your email.
      </div>
      {/* <h2>{props?.location?.state?.Title || props?.Title}</h2> */}
      {(!props?.clientDashboard && (redirection == "true" || !redirection)) &&(
        <p>
          You will be redirected in {seconds} seconds, or you can{` `}
          <span
            className="btn btn-link text-primary"
            onClick={() => handleNavigation()}
          >
            <u>click here</u>
          </span>
          {` `}to navigate now
        </p>
      )}
    </div>
  );
};

export default SuccessForm;
