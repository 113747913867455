import { DropDownList } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Hint, Error } from "@progress/kendo-react-labels";
import { NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import { TenantService } from "services/tenant/index.service";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import BuzopsButton from "generic-components/BuzopsButton";
import { CheckUserMemberStatus, CurrencyFormat, CustomToFixed, checkHasTaxEnable, getLongDate } from "utils";
import { addDates } from "components/checkout/Checkout";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
  customDateCell,
  InstallmentAmtCell,
} from "components/checkout/ShowDownPayment";
import PaymentMethods from "./PaymentMethods";
import Transactions from "./Transactions";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import BuzopsLoader from "generic-components/BuzopsLoader";
import withNotification from "components/Hoc/withNotification";
import { PermissionEnum } from "utils/PermissionEnum";
import CheckPermissions from "utils/permissions";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentPackageService } from "components/checkout/OrderSummary";
import AdditionalPayment from "components/AdditionalPayment/AdditionalPayment";
import { recurrenceUnits } from "utils/form-utils";
import { WaivePackagePaymentPermission } from "utils/permission-utils";
const initialAdditionalPayment={
  dialog:false,
  selectedPaymentProfile:null
}
export const RecurrenceUnits = [
  { id: 0, text: "Please Select Frequency" },
  { id: 4, text: "Months"},
  { id: 2, text: "Days"},
];

const Payments = (props: any) => {
  const [amount, setAmount] = useState(0);
  const [updateRecurrencetable, setUpdateRecurrencetable] = useState<any>([]);
  const [payDueRecurrencetable, setPayDueRecurrencetable] = useState<any>([]);
  const [showAdditionalPayment, setShowAdditionalPayment] = useState<any>(initialAdditionalPayment);
  const [serviceData, setserviceData] = useState<any>(null);

  const [remDueRecurrence, setRemDueRecurrence] = useState(false);
  const [IsWaivePayment, setIsWaivePayment] = useState(false);
  const [IsTaxSkipped, setIsTaxSkipped] = useState(true);
  const [makePayment, setMakePayment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitPayRemDue, setSubmitPayRemDue] = useState(false);
  const [submistatus, setSubmitStatus] = useState({
    payRemDue: true,
    updateRecurrence: false,
  })
  const [updateRecurrenceSubmitLoading, setUpdateRecurrenceSubmitLoading] =
    useState(false);
  const [payRemDueSubmitLoading, setpayRemDueSubmitLoading] = useState(false);
  const [payRemainingDue, setPayRemainingDue] = useState(0);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    useState<any>(undefined);
  const [AlternatePaymentProfileId, setAlternatePaymentProfileId] = useState<any>(null);
  const [paymentProfile, setPaymentProfile] = useState<any>([]);
  const {
    UserMemberId,
    UserId,
    FullName,
    FirstName,
    LastName,
    Email,
    MemberNumber,
    MainUserId
  } = props?.userData;

  const clientDetails = {
    ClientId: UserMemberId,
    PaymentCards: [],
    UserMemberId: UserMemberId,
    ClientName: FullName,
    UserId: UserId,
    MemberNumber: MemberNumber,
    FirstName: FirstName,
    LastName: LastName,
    PrimaryMemberId: MainUserId || UserMemberId,
    Email: Email,
  };
  const [Taxes, setTaxes] = useState<any>([]);
  const [TotalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const tommorrow = moment().add(1, "days").format("L");
  const remainingDueRecDetails = {
    recurrenceUnit: { id: 0, text: "Please Select Frequency" },
    NextRecurrence: undefined,
    OccursEvery: "",
    NoOfInstallments: "",
  };
  const clientData = clientDetails;
  useEffect(() => {
    if (props.selectedService) {
      fetchApis();
    }
  }, []);

  const fetchApis = async () => {
    setLoading(true);
    props.fetchServiceInstance(
      props.selectedService
    )
    await fetchRecurringDetails(props.selectedService);
    await fetchPaymentProfiles(MainUserId);
    await fetchTaxes()
    
    setLoading(false);
  };
  const fetchTaxes = async () => {
    const req = {};
    const taxService= new ManageTaxesService()
    const res = await taxService.getTaxes(req);
    setTaxes(res);
  };

  const handleMakePayment=()=>{
    setMakePayment(true)
    // if(serviceData?.FailedTransactionAmount === 0){
      handleTaxCalculation(payRemainingDue)
    // }
  }

  const handleTaxCalculation=(purchaseAmt:number,TaxItems=Taxes)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=purchaseAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
          return acc + currentValue?.Amount
    }, 0);
    setTaxes(TaxesArray)
    setTotalTaxAmount(taxAmt)
  }

  const showErrorMessage = (key: any, mainKey: any) => {
    if (!recurrenceFormDetails[key] || recurrenceFormDetails[key] <= 0) {
      return <Error>{`Please provide atleast one ${mainKey}`}</Error>;
    }
    return <></>;
  };
  const showPayRemaingDueErrorMessage = (key: any, mainKey: any) => {
    if (!remDueRecurDetails[key] || remDueRecurDetails[key] <= 0) {
      return <Error>{`Please provide atleast one ${mainKey}`}</Error>;
    }
    return <></>;
  };

  useEffect(() => {
    updateServiceData()
  }, [props?.serviceInstance])

  const updateServiceData = async() => {
    const {
      PackageCost,
      AmountPaid,
      FailedTransactionAmount,
      Name,
      PrivateServiceId,
      OcurrsEvery,
      NoOfInstallments,
      Rate,
      RepeatFrequency,
      Duration,
      NumberOfSessions,
      CanCollectTaxes
    } = props?.serviceInstance || {};
    let diffamount = PackageCost - AmountPaid;
    diffamount= parseFloat(diffamount.toFixed(2))
    // if (FailedTransactionAmount > 0) {
    //   diffamount = FailedTransactionAmount;
    //   const req={
    //     FilterFields: [],
    //     PageNumber: 0,
    //     PageSize: 100,
    //     SortBy: "TransactionDate",
    //     SortDirection: "desc",
    //     Where: {
    //         PaymentTransactionHistoryId: FailedTransactionId,
    //         UserMemberId: UserMemberId
    //     }
    //   }
    //   const service = new TenantService();
    //   const res = await service.getTransactions(req);
    //   const result=res?.Items[0]
    //   diffamount=result?.AmountPaid - result?.TotalTaxAmount
    //   // if((PackageCost - AmountPaid) > diffamount){
    //   //   setRemDueRecurrence(true)
    //   //   let recurrenceUnit = { id: 2, text: "Days",value:'Day(s)' };
    //   //   if (recurrenceDetailsRef?.current?.recurrenceUnit?.id === 4) {
    //   //     recurrenceUnit = { id: 4, text: "Months",value:'Month(s)' };
    //   //   }
    //   //   setRemDueRecurDetails({
    //   //     recurrenceUnit: recurrenceUnit,
    //   //     NextRecurrence: new Date(recurrenceDetailsRef?.current?.NextRecurrence),
    //   //     OccursEvery: recurrenceDetailsRef?.current?.OccursEvery,
    //   //     NoOfInstallments: recurrenceDetailsRef?.current?.NoOfInstallments-1,
    //   //   });
    //   // }
    // }
    const Servicedetails = {
      NumberOfSessions: NumberOfSessions,
      Duration: Duration,
      NewService: false,
      PackageCost: PackageCost,
      PackageCostAfterDiscount: PackageCost,
      RepeatFrequency: RepeatFrequency,
      Name: Name,
      RateAfterDiscount: Rate,
      Rate: Rate,
      PrivateServiceId: PrivateServiceId,
      OcurrsEvery: OcurrsEvery,
      NoOfInstallments: NoOfInstallments,
      CanCollectTaxes: CanCollectTaxes,
      FailedTransactionAmount: FailedTransactionAmount,
    };
    setIsTaxSkipped(!CanCollectTaxes)
    setserviceData(Servicedetails);
    setAmount(diffamount);
    setPayRemainingDue(diffamount);
  };

  const handleReset = () => {
    setRecurrenceDetails(null);
    setSubmitPayRemDue(false)
    setRemDueRecurrence(false);
    fetchApis();
    setMakePayment(false)
    updateServiceData()
  };

  async function fetchPaymentProfiles(userMemberId: any,clearAdditionPP=true) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      if (result.length > 0) {
        const res = result.filter((item: any) => item.CardTypeId !== -1);
        setPaymentProfile(result);
        const test = result.filter(
          (item: any) =>
            item?.PaymentGatewayPaymentProfileID ===
            props?.serviceInstance?.PaymentGatewayProfileId
        );
        if(clearAdditionPP){
          if(test[0]?.CardTypeId !== 8 || (test[0]?.CardTypeId ===8 && test[0]?.Credit  > 0)){
            setSelectedPaymentProfile(test[0]);
          }
          setAlternatePaymentProfileId(null)
          return res
        }
      }
    }
  }
  const handleChangeSavedCard = (val: any) => {
    setSelectedPaymentProfile(val);
    setAlternatePaymentProfileId(null)
  };

  const showRecurrenceDetails = (tableData: any) => {
    if (tableData?.length === 0) {
      return (<>
        {"No Installments found"}
      </>)
    }
    return (
      <div
        className={"installemt-grid bz-installment-details"}
        style={{ width: "268px", margin: "0px" }}
      >
        <Grid data={tableData} style={{ maxHeight: "300px", border: 0 }}>
          <Column field="DisplayDate" title={"Dates"} cell={customDateCell} />
          <Column
            field="InstallmentAmount"
            title={"Amount"}
            cell={InstallmentAmtCell}
          />
        </Grid>
      </div>
    );
  };
  const CalculateRecurrence = (
    item: any,
    totalCost: any,
    paidToday: any,
    check: boolean
  ) => {
    const data = [];
    if (
      paidToday >= 0 &&
      item.recurrenceUnit != undefined &&
      item.NoOfInstallments > 0 &&
      item.NextRecurrence != undefined
    ) {
      if (totalCost >= paidToday) {
        let BalanceAmount = totalCost - paidToday;
        let NoOfInstallments =
          item.NoOfInstallments > 999 ? 999 : item.NoOfInstallments;
        if (BalanceAmount > 0) {
          let InstallmentAmount = BalanceAmount / NoOfInstallments;
          InstallmentAmount = parseFloat(InstallmentAmount.toFixed(2));
          let unit = item.recurrenceUnit.text;
          let InstallmentDate = new Date(item.NextRecurrence); 
          let totalAmt = 0;
          for (let i = 0; i < NoOfInstallments; i++) {
            totalAmt = totalAmt + InstallmentAmount;
            if (BalanceAmount !== totalAmt && i === NoOfInstallments - 1) {
              InstallmentAmount =
                InstallmentAmount + (BalanceAmount - totalAmt);
            }

            data.push({
              Date: InstallmentDate,
              DisplayDate: getLongDate(InstallmentDate),
              InstallmentAmount: InstallmentAmount,
            });

            InstallmentDate = addDates(
              new Date(InstallmentDate),
              parseInt(item.OccursEvery),
              unit
            );
          }
        }
      }
    }
    if (check) {
      setUpdateRecurrencetable(data);
    } else {
      setPayDueRecurrencetable(data);
    }
  };

  const submitPayRemainigDue = async () => {
    setSubmitPayRemDue(true)
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage('', "error", true, 'client');
      return
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage('', "error", false, 'staff');
      return
    }
    if (!selectedPaymentProfile && !IsWaivePayment) {
      props?.handleNotificationMessage('Please select Payment Profile', "error");
      return
    }
    let purchaseAmt=!IsTaxSkipped ? payRemainingDue + TotalTaxAmount : payRemainingDue
    purchaseAmt=CustomToFixed(purchaseAmt,2)
    if(selectedPaymentProfile?.CardTypeId === 8 && purchaseAmt > selectedPaymentProfile?.Credit && !AlternatePaymentProfileId){
      let defaultPaymentProfile=null
      const existedPP=paymentProfile?.filter((i:any)=> i?.CardTypeId !== -1 && i.CardTypeId !==8)
      if(existedPP?.length > 0){
        defaultPaymentProfile=existedPP[0]
      }
      setShowAdditionalPayment({
        dialog:true,
        selectedPaymentProfile:defaultPaymentProfile
      })
      return
    }

    if (remDueRecurrence && (!remDueRecurDetails?.OccursEvery || remDueRecurDetails?.OccursEvery <= 0 || !remDueRecurDetails?.NoOfInstallments || remDueRecurDetails?.NoOfInstallments <= 0 || !remDueRecurDetails?.NextRecurrence || moment(remDueRecurDetails?.NextRecurrence).format("L") === "Invalid date"  || remDueRecurDetails?.recurrenceUnit?.id===0)) {
      return
    }

    const recurrences = payDueRecurrencetable.map((item: any) => {
      return {
        ...item,
        Date: moment(item.Date).format('L'),
        InstallmentAmount: item.InstallmentAmount.toFixed(2),
      };
    });
    if(selectedPaymentProfile?.CardTypeId === -1){
      // if(serviceData?.FailedTransactionAmount > 0){
      //   const errormsg = "Can't use cash payments with failed transaction amount";
      //   props?.handleNotificationMessage(errormsg, "error");
      //   return true;
      // }else
       if(recurrences?.length > 0){
        const errormsg = "Can't use cash payments with recurrence service";
        props?.handleNotificationMessage(errormsg, "error");
        return true;
      }
    }
    const serviceDet = {
      ...serviceData,
      OcurrsEvery: remDueRecurDetails?.OccursEvery ? remDueRecurDetails?.OccursEvery : 0,
      RepeatFrequency: remDueRecurDetails?.recurrenceUnit?.id,
      NoOfInstallments: remDueRecurDetails?.NoOfInstallments ? remDueRecurDetails?.NoOfInstallments : 0,
    };

    const req:any = {
      RecurrenceText: "",
      Amount: 0,
      IsWaivePayment:IsWaivePayment,
      Service: serviceDet,
      Client: clientData,
      PaymentProfile: !IsWaivePayment  ? {
        PaymentGatewayPaymentProfileID:
          selectedPaymentProfile?.PaymentGatewayPaymentProfileID,
        CustomerProfileID: selectedPaymentProfile?.CustomerProfileID,
        PaymentProfileID: selectedPaymentProfile?.PaymentProfileID,
        MaskedCCNumber: selectedPaymentProfile?.MaskedCCNumber,
      }:{},
      DownPayment: payRemainingDue,
      PrivateServiceInstanceId:
        props?.serviceInstance?.PrivateServiceInstanceId,
      MemberRecurringDetailId: props.serviceInstance.MemberRecurringDetailId,
      Status: 1,
      MaskedCCNumber: !IsWaivePayment ? selectedPaymentProfile?.MaskedCCNumber : undefined,
      Recurrences: recurrences,
      NextRecurringDate: moment(remDueRecurDetails?.NextRecurrence).format('L'),
      Taxes:!IsTaxSkipped   ? Taxes : [],
      CanCollectTaxes :!IsTaxSkipped ? true : false,
      TotalTaxAmount:!IsTaxSkipped ? TotalTaxAmount : 0
    };
    if(selectedPaymentProfile?.CardTypeId === 8 && purchaseAmt > selectedPaymentProfile?.Credit){
      const AmountChargeByAlternatePaymentProfile=CustomToFixed(purchaseAmt-selectedPaymentProfile?.Credit,2)
      console.log(purchaseAmt,selectedPaymentProfile?.Credit,AmountChargeByAlternatePaymentProfile,purchaseAmt-selectedPaymentProfile?.Credit)
      req.AlternatePaymentProfileId=AlternatePaymentProfileId
      req.AmountChargeByAlternatePaymentProfile=AmountChargeByAlternatePaymentProfile
   }

    const checkoutservice = new TenantService();
    setpayRemDueSubmitLoading(true);
    const result = await checkoutservice.purchaseService(req);
    setpayRemDueSubmitLoading(false);
    if (result.ResponseCode === 100) {
      const successMsg = "Remaining Due paid successfully";
      props?.handleNotificationMessage(successMsg, "success");
      setRemDueRecurDetails(remainingDueRecDetails)
      setSubmitPayRemDue(false)
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
    setRemDueRecurrence(false)
    await props.fetchServiceInstance(
      props?.serviceInstance?.PrivateServiceInstanceId
    );
    await fetchApis()
    setMakePayment(false)
  };
  const handleUpdateRecurrence = (val: any, name: string) => {

    const amounttoUpdate =
      props?.serviceInstance?.PackageCost - props?.serviceInstance?.AmountPaid;
    let value = val;
    if (name !== "NextRecurrence" && val < 0) {
      value = Math.abs(val);
    }
    let data = {
      ...recurrenceFormDetails,
      [name]: value,
    };
    if (name === 'OccursEvery' && parseInt(value) === 0) {
      data = {
        ...recurrenceFormDetails,
        [name]: value, NoOfInstallments: 1
      };
    }
    if (name === "NoOfInstallments" && val > 999) {
      data = {
        ...recurrenceFormDetails,
        [name]: 999
      };
    }
    if(name==="NextRecurrence"){
      if(moment(val).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')){
        data = {
          ...recurrenceFormDetails,
          [name]: val
        };
      }else{
        data = {
          ...recurrenceFormDetails,
          [name]: recurrenceFormDetails?.NextRecurrence
        };
      }
    }
    setRecurrenceFormDetails(data);
    setSubmitStatus({
      ...submistatus, updateRecurrence: true
    })
    CalculateRecurrence(data, amounttoUpdate, 0, true);
  };
  const handlePayRemainingDue = (val: any, name: string) => {
    const amounttoUpdate =
      props?.serviceInstance?.PackageCost - props?.serviceInstance?.AmountPaid;
    let value = val;
    if (name !== "NextRecurrence" && val < 0) {
      value = Math.abs(val);
    }
    let data = {
      ...remDueRecurDetails,
      [name]: value,
    };
    if (name === 'OccursEvery' && parseInt(value) === 0) {
      data = {
        ...remDueRecurDetails,
        [name]: value, NoOfInstallments: 1
      };
    }
    if (name === "NoOfInstallments" && val > 999) {
      data = {
        ...remDueRecurDetails,
        [name]: 999
      };
    }
    if(name==="NextRecurrence"){
      if(moment(val).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')){
        data = {
          ...remDueRecurDetails,
          [name]: val
        };
      }else{
        data = {
          ...remDueRecurDetails,
          [name]: remDueRecurDetails?.NextRecurrence
        };
      }
    }
    setRemDueRecurDetails(data);
    setSubmitStatus({
      ...submistatus, payRemDue: true
    })
    CalculateRecurrence(data, amounttoUpdate, payRemainingDue, false);
  };

  useEffect(() => {
    if (payRemainingDue >= 0) {
      const amounttoUpdate =
        props?.serviceInstance?.PackageCost -
        props?.serviceInstance?.AmountPaid;
      CalculateRecurrence(
        remDueRecurDetails,
        amounttoUpdate,
        payRemainingDue,
        false
      );
    }
  }, [payRemainingDue]);

  const [remDueRecurDetails, setRemDueRecurDetails] = useState<any>(
    remainingDueRecDetails
  );
  const [recurrenceDetails, setRecurrenceDetails] = useState<any>(null);
  const [recurrenceFormDetails, setRecurrenceFormDetails] = useState<any>(null);

  const checkPayRemainingDue = (val: any, serviceInstance: any) => {
    let value = val;
    if(!val || val ===""){
      value = 0
    }
    if (val < 0) {
      value = Math.abs(val);
    }
    const { PackageCost, AmountPaid } = serviceInstance;
    const totalAmtToPay = PackageCost - AmountPaid;
    if (value === totalAmtToPay) {
      setPayRemainingDue(value);
      setRemDueRecurrence(false);
      setSubmitStatus({
        ...submistatus, payRemDue: true
      })
      handleTaxCalculation(value)
    }
    else if (value < totalAmtToPay) {
      setPayRemainingDue(value);
      setRemDueRecurrence(true);
      setSubmitStatus({
        ...submistatus, payRemDue: true
      })
      handleTaxCalculation(value)

    } else {
      setPayRemainingDue(totalAmtToPay);
      setRemDueRecurrence(false);
      // setSubmitStatus({
      //   ...submistatus, payRemDue: false
      // })
      handleTaxCalculation(totalAmtToPay)
    }
  };
  const fetchRecurringDetails = async (val: any) => {
    const fetchService = new TenantService();
    const result = await fetchService.getRecurringDetails(val);
    if (result) {
      let recurrenceUnit = { id: 2, text: "Days",value:'Day(s)' };
      if (result.PSRepeatFrequency === 4) {
        recurrenceUnit = { id: 4, text: "Months",value:'Month(s)' };
      }
      const data = {
        recurrenceUnit: recurrenceUnit,
        AmountPending: result?.AmountPending,
        NextRecurrence: result?.NextRecurrence,
        NoOfInstallments: result?.NoOfInstallments,
        OccursEvery: Math.abs(result?.OccursEvery),
        PreviousOccurence: result?.PreviousOccurence,
        PrivateServiceInstanceId: result?.PrivateServiceInstanceId,
        RecurringCost: result?.RecurringCost,
        UserMemberId: result?.UserMemberId,
        Status: result?.Status,
      };
      setRecurrenceDetails(data);
      setRecurrenceFormDetails(data);
      CalculateRecurrence(data, result?.AmountPending, 0, true);
    }
  };

  const submitRecurrence = async () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage('', "error", true, 'client');
      return
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage('', "error", false, 'staff');
      return
    }
    if (!recurrenceFormDetails?.OccursEvery || recurrenceFormDetails?.OccursEvery <= 0 || !recurrenceFormDetails?.NoOfInstallments || recurrenceFormDetails?.NoOfInstallments <= 0 || moment(recurrenceFormDetails?.NextRecurrence).format("L") === "Invalid date") {
      return
    }
    const recurAmt = (
      recurrenceFormDetails?.AmountPending /
      recurrenceFormDetails?.NoOfInstallments
    ).toFixed(2);
    const occurence = `${recurrenceFormDetails?.OccursEvery} ${recurrenceFormDetails?.recurrenceUnit?.text}`;
    const req = {
      Status: recurrenceFormDetails?.Status,
      AmountPending: recurrenceFormDetails?.AmountPending,
      NextRecurrence: moment(recurrenceFormDetails?.NextRecurrence).format("L"),
      NoOfInstallments: parseInt(recurrenceFormDetails?.NoOfInstallments),
      OccursEvery: parseInt(recurrenceFormDetails?.OccursEvery),
      PreviousOccurence: occurence,
      PrivateServiceInstanceId: recurrenceFormDetails?.PrivateServiceInstanceId,
      PSRepeatFrequency: recurrenceFormDetails?.recurrenceUnit?.id,
      RecurringCost: parseFloat(recurAmt),
      UserMemberId: recurrenceFormDetails?.UserMemberId,
      FullName: props?.userData?.FullName
    };
    setUpdateRecurrenceSubmitLoading(true);
    const updateRecurrenceService = new TenantService();
    const result = await updateRecurrenceService.updateRecurrence(req);
    setUpdateRecurrenceSubmitLoading(false);
    if (result.ResponseCode === 100) {
      const successMsg = "Recurrence Updated Successfully";
      props?.handleNotificationMessage(successMsg, "success");
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
    await props.fetchServiceInstance(recurrenceFormDetails?.PrivateServiceInstanceId);
    await fetchApis()
  };

  const renderUpdateRecurrence = () => {
    const billingFreq=`For Every ${recurrenceDetails?.OccursEvery} ${recurrenceDetails?.recurrenceUnit?.value}`
    if (recurrenceDetails?.AmountPending <= 0) {
      return (
        <>
          <div className="bz-mt-2rem">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Update Recurrence</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {"There is no pending amount to update recurrence"}
              </div>
            </div>
          </div>
        </>
      );
    }
  
    return (
      <>
     
        <h5 className="mb-3 pt-3">Update Recurrence</h5>
        <div className="bz-mt-2rem bz-mb-2_5rem update-recurrence">
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Amount Pending</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(recurrenceDetails?.AmountPending)}
            </div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">
              Billing Frequency{" "}
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {billingFreq}
            </div>
          </div>

          <div className="bz-content-text-row mb-0">
            <div className="bz-content-title text-right align-self-baseline">Recurring Cost</div>
            <span className="bz-colon text-center  align-self-baseline">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(recurrenceDetails?.RecurringCost)}

            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right align-self-baseline"></div>
            <span className="bz-colon text-center  align-self-baseline"></span>
            <div className="bz-content-body">
              <Hint>
                <span className="form-text text-muted col-md-6 pl-0">
                  Amount to be charged on every billing cycle.
                </span>
              </Hint>
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right">
              Recurrence Start Date
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <DatePicker
                name="NextRecurrence"
                min={new Date(tommorrow)}
                
                value={new Date(recurrenceFormDetails?.NextRecurrence)}
                 onChange={(e) =>
                  handleUpdateRecurrence(e.target.value, "NextRecurrence")
                }
                className={"col-md-6"}
              />
              {moment(recurrenceFormDetails?.NextRecurrence).format('L') === "Invalid date" && (<Error>{'Please select valid Recurrence Start Date'}</Error>)}
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right  align-self-baseline">Frequency</div>
            <span className="bz-colon text-center  align-self-baseline">:</span>
            <div className="bz-content-body">
              <DropDownList
                data={recurrenceUnits}
                textField="value"
                className={"form-control col-md-6"}
                dataItemKey="id"
                name="recurrenceUnit"
                value={recurrenceFormDetails?.recurrenceUnit}
                onChange={(e) =>
                  handleUpdateRecurrence(e.value, "recurrenceUnit")
                }
              />
              <Hint>
                <span className="form-text text-muted col-md-6 pl-0">
                  The billing frequency for the Service.
                </span>
              </Hint>
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row mb-0">
            <div className="bz-content-title text-right  align-self-baseline">Recurring Every</div>
            <span className="bz-colon text-center align-self-baseline">:</span>
            <div className="bz-content-body">
              <input
                type="number"
                className={"form-control col-md-6"}
                value={recurrenceFormDetails?.OccursEvery}
                onChange={(e) =>
                  handleUpdateRecurrence(e.target.value, "OccursEvery")
                }
              />
              {showErrorMessage("OccursEvery", "Recurring Every")}

            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right  align-self-baseline"></div>
            <span className="bz-colon text-center align-self-baseline"></span>
            <div className="bz-content-body">
              <Hint>
                <span className="form-text text-muted col-md-6 pl-0">
                  Select how frequently the client would want to repeat the Service payment based on frequency.
                </span>
              </Hint>
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right align-self-baseline">
              Number Of Installments
            </div>
            <span className="bz-colon text-center  align-self-baseline">:</span>
            <div className="bz-content-body">
              <input
                type="number"
                min="0"
                max="999"
                className={"form-control col-md-6"}
                value={recurrenceFormDetails?.NoOfInstallments}
                onChange={(e) =>
                  handleUpdateRecurrence(e.target.value, "NoOfInstallments")
                }
              />
              {showErrorMessage("NoOfInstallments", "Installment")}
              <Hint>
                <span className="form-text text-muted col-md-6 pl-0">
                  Select the number of times the client would want to repeat the Service payment based on frequency.
                </span>
              </Hint>
            </div>
          </div>
          <div className="bz-content-text-row align-items-start bz-notify-row">
            <div className="bz-content-title text-right">
              Installment Details
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {showRecurrenceDetails(updateRecurrencetable)}
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row mt-4 pt-2 mble-mt-0">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <Button className="ml-0" onClick={() => handleReset()} disabled={updateRecurrenceSubmitLoading || !submistatus?.updateRecurrence}>
                Reset
              </Button>
              <BuzopsButton
                disabled={updateRecurrenceSubmitLoading || !submistatus?.updateRecurrence}
                type={"submit"}
                loading={updateRecurrenceSubmitLoading}
                label={"Submit"}
                onClick={() => submitRecurrence()}
              />
            </div>
          </div>
        </div>

        <hr />
      </>
    );
  };

  const renderRenewalOptions = () => {
    return (
      <>
        <h5 className="mb-3 pt-3 bz_fs_1_5">Renewal</h5>
        <div className="bz-mt-2rem bz-mb-2_5rem update-recurrence">
          <div className="bz-content-text-row bz-notify-row">
            <div className="bz-content-title text-right">Renewal Option</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{props?.serviceInstance?.RenewalDescription}</div>
          </div>
        </div>
        <hr />
      </>
    );
  };

  const handleWaivePayment=(val:boolean)=>{
    setIsWaivePayment(val)
    if(val){
      setRemDueRecurrence(false)
    }
    updateServiceData()
  }

  const handleChangeAdditionalPaymentProfile = async (value: any) => {
    setShowAdditionalPayment({
      ...showAdditionalPayment,selectedPaymentProfile:value
    })
  }

  const handleAddAdditionalPayment=()=>{
    if(!showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID){
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return
    }
    setAlternatePaymentProfileId(showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID)
    setShowAdditionalPayment(initialAdditionalPayment)
  }

  const renderPayRemainingDue = () => {
    return (
      <>
        <h5 className="mb-3 pt-3 bz_fs_1_5">Pay Remaining Due</h5>
        {!makePayment && (<>
          <div className="bz-content-text-row-new">
            <div className="bz-content-title text-right">Remaining Balance</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{CurrencyFormat(amount)}</div>
          </div>
          <div className="bz-content-text-row-new">
            <div className="bz-content-title text-right">{""}</div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <span
                className="btn btn-link"
                onClick={() => {
                  handleMakePayment();
                }}
              >
                Make Payment
              </span>
            </div>
          </div>
        </>)}
        {makePayment && (<div className="bz-mt-2rem bz-mb-2_5rem update-recurrence">
         {props?.staffPage && WaivePackagePaymentPermission() && <div className="bz-content-text-row-new">
            <div className="bz-content-title text-right"> Do you want to waive this payment ?</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
            <Switch
                checked={IsWaivePayment}
                disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}
                onChange={(e) => handleWaivePayment(e.value)}
              />
            </div>
          </div>}
         {props?.staffPage && !IsWaivePayment && checkHasTaxEnable() && <div className="bz-content-text-row-new">
            <div className="bz-content-title text-right">Surcharges</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
            <Switch
                  checked={!IsTaxSkipped}
                  defaultChecked={false}
                  name={`IsTaxSkipped`}
                  disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}
                  onChange={(e: any) =>
                    setIsTaxSkipped(!IsTaxSkipped)
                  }
                  className="mr-1 d-inline-flex"
                />
            </div>
          </div>}
          {!IsWaivePayment && ( <div className="bz-content-text-row-new bz-notify-row">
            <div className="bz-content-title text-right">Payment Method</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <DropDownList
                id={"selectedPaymentProfile"}
                name={"selectedPaymentProfile"}
                data={paymentProfile}
                listNoDataRender={() => "No Payment Methods"}
                textField="MaskedCCNumber"
                dataItemKey="PaymentProfileID"
                className={"form-control col-md-6"}
                valueRender={(
                  el: React.ReactElement<HTMLSpanElement>,
                  value: any
                ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                itemRender={PaymentItemRender}
                onChange={(e) => {
                  handleChangeSavedCard(e.value);
                }}
                style={{ width: '100%' }}
              />
            </div>
          </div>)}
         
          <div className="bz-content-text-row-new">
            <div className="bz-content-title text-right">Amount</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <NumericTextBox
                className={"form-control col-md-6"}
                value={payRemainingDue}
                format="c2"
                min={0}
                disabled={IsWaivePayment}
                onChange={(e) => {
                  checkPayRemainingDue(e.target.value, props.serviceInstance);
                }}
              />
              {(amount - payRemainingDue) > 0 && <Hint>
                <span className="form-text text-muted pl-0">
                  Remaining Amount: {CurrencyFormat(amount - payRemainingDue)}
                </span>
              </Hint>}
              {/* {serviceData?.FailedTransactionAmount>0 &&  <Hint>
                <span className="form-text text-muted col-md-6 pl-0">
                  Failed Transaction Amount
                </span>
              </Hint>} */}
            </div>
          </div>
          {!IsWaivePayment && remDueRecurrence && (
            <>
              <div className="bz-content-text-row-new bz-notify-row">
                <div className="bz-content-title text-right">
                  Recurrence Start Date
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {" "}
                  <DatePicker
                    value={remDueRecurDetails?.NextRecurrence ? new Date(remDueRecurDetails?.NextRecurrence): remDueRecurDetails?.NextRecurrence}
                    min={new Date(tommorrow)}
                    format="MM/dd/yyyy" 
                    formatPlaceholder={{ year: "yyyy", month: "mm", day: "dd" }}
                    onChange={(e) =>
                      handlePayRemainingDue(e.target.value, "NextRecurrence")
                    }
                    className={"form-control col-md-6"}
                  />
                  {submitPayRemDue &&  (!remDueRecurDetails?.NextRecurrence || moment(remDueRecurDetails?.NextRecurrence).format('L') === "Invalid date") && (<Error>{'Please select valid Recurrence Start Date'}</Error>)}
                </div>
              </div>

              <div className="bz-content-text-row-new bz-notify-row">
                <div className="bz-content-title text-right">Frequency</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <DropDownList
                    data={RecurrenceUnits}
                    textField="text"
                    className={"form-control col-md-6"}
                    dataItemKey="id"
                    value={remDueRecurDetails?.recurrenceUnit || { id: 0, text: "Please Select Frequency" }}
                    onChange={(e) =>
                      handlePayRemainingDue(e.value, "recurrenceUnit")
                    }
                  />
                  {submitPayRemDue && remDueRecurDetails?.recurrenceUnit?.id === 0 && <Error>{`Please Select Frequency`}</Error>}
                </div>
              </div>
              <div className="bz-content-text-row-new bz-notify-row">
                <div className="bz-content-title text-right">
                  Recurring Every
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {" "}
                  <input
                    type="number"
                    min="0"
                    className={"form-control col-md-6"}
                    value={remDueRecurDetails?.OccursEvery}
                    onChange={(e) =>
                      handlePayRemainingDue(e.target.value, "OccursEvery")
                    }
                  />
                  {submitPayRemDue &&  showPayRemaingDueErrorMessage("OccursEvery", "Recurring Every")}
                </div>
              </div>
              <div className="bz-content-text-row-new">
                <div className="bz-content-title text-right">
                  Number Of Installments
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {" "}
                  <input
                    type="number"
                    min="0"
                    max="999"
                    className={"form-control col-md-6"}
                    value={remDueRecurDetails?.NoOfInstallments}
                    onChange={(e) =>
                      handlePayRemainingDue(e.target.value, "NoOfInstallments")
                    }
                  />
                  {submitPayRemDue &&  showPayRemaingDueErrorMessage("NoOfInstallments", "Installment")}
                </div>
              </div>
              <div className="bz-content-text-row align-items-start  bz-notify-row">
                <div className="bz-content-title text-right">
                  Installment Details
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {showRecurrenceDetails(payDueRecurrencetable)}
                </div>
              </div>
            </>
          )}
          <div className="bz-content-text-row-new" style={{ fontWeight: 600 }}>
            <div className="bz-content-title text-right">{IsWaivePayment ? "Total Waived Amount" : "Pay Today"}</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(payRemainingDue)}
            </div>
          </div>
          {!IsTaxSkipped && !IsWaivePayment && TotalTaxAmount > 0  && (<>
            <div className="bz-content-text-row-new" style={{ fontWeight: 600 }}>
            <div className="bz-content-title text-right"><Tooltip
            content={(tooltipProps: any) => {
              return (
                <TooltipContentPackageService
                title={tooltipProps.title}
                  data={Taxes}
                  TotalTaxAmount={TotalTaxAmount}
                />
              );
            }}
            tooltipClassName="bz-tooltip-table"
          >
           <a
              title="Surcharges"
              style={{
                borderBottom: "1px dotted #0d6efd",
                cursor: "pointer",
              }}
            >
             {"Surcharges to be Collected"}
            </a>
          </Tooltip></div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(TotalTaxAmount)}
            </div>
          </div>
          <div className="bz-content-text-row-new" style={{ fontWeight: 600 }}>
            <div className="bz-content-title text-right">
            {'Total'}
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(TotalTaxAmount+payRemainingDue)}
            </div>
          </div>
          </>)}
          <div className="bz-content-text-row-new mt-2 mble-mt-0">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <Button className="ml-0" onClick={() => handleReset()} disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}>
                Cancel
              </Button>
              <BuzopsButton
                disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}
                type={"submit"}
                loading={payRemDueSubmitLoading}
                key={payRemDueSubmitLoading?.toString()}
                label={IsWaivePayment ? "Waive":"Pay Now"}
                onClick={() => submitPayRemainigDue()}
              />
            </div>
          </div>
        </div>)}
       </>
    );
  };
  const renderTransactions = () => {
    return (
      <Transactions
        allItems={false}
        EntityId={props?.serviceInstance?.PrivateServiceInstanceId}
        FilterType={0}
        userData={props?.userData}
        staffPage={props?.staffPage}
        onSuccessfullPayment={() => props?.onSuccessfullPayment()}

      />
    );
  };

  const renderPaymentMethods = () => {
    const entityDetails = {
      PrivateServiceInstanceId: props?.serviceInstance?.PrivateServiceInstanceId,
      MemberRecurringDetailId: props?.serviceInstance?.MemberRecurringDetailId,
      PaymentGatewayProfileId: props?.serviceInstance?.PaymentGatewayProfileId,
      FilterType: "service",
      UserMemberId,
    };
    return (
      <PaymentMethods
        fetchServiceInstance={(id: any) => props.fetchServiceInstance(id)}
        entityDetails={entityDetails}
        AuditType={16}
        userData={props?.userData}
        staffPage={props?.staffPage}
      />
    );
  };

  if (loading) {
    return <BuzopsLoader type={"list"} />;
  }
  return (
    <div className={"bz-service-payments-page payments-grid"}>
      <div className="bz-subsection-row bz-plan-overview subscription-blk">
        <div className="left-section">
        <h5 className="mb-3 pt-3">Payments</h5>
        <div className="bz-mt-2rem bz-mb-2_5rem update-recurrence">
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Available Appointments</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
            {`${props?.serviceInstance?.SessionAvailableToSchedule} (${props?.serviceInstance?.Duration}min)`}
            </div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">
            Client Name{" "}
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
            {FullName}
            </div>
          </div>
        </div>
        <hr />
        </div>
      </div>
      
      <div className="bz-subsection-row bz-plan-overview subscription-blk">
        <div className="left-section">
          {recurrenceDetails?.AmountPending > 0 && recurrenceDetails?.RecurringCost > 0 &&
            renderUpdateRecurrence()}
        </div>
      </div>
      <div className="bz-subsection-row bz-plan-overview">
        <div className="left-section">
          {renderRenewalOptions()}
        </div>
      </div>
      <div className="bz-subsection-row bz-plan-overview">
        <div className="left-section">
          {recurrenceDetails?.AmountPending > 0 &&
            renderPayRemainingDue()}
        </div>
      </div>
      <div className="bz-subsection-row bz-plan-overview render-payment-methods">
        <div className="left-section">{renderPaymentMethods()}</div>
      </div>
      <hr />
      {renderTransactions()}
      {showAdditionalPayment?.dialog && 
        <AdditionalPayment 
            fetchPaymentProfiles={fetchPaymentProfiles}
            userMemberId={UserMemberId}
            setShowAdditionalPayment={setShowAdditionalPayment}
            paymentProfile={paymentProfile}
            PurchaseAmount={!IsTaxSkipped ? payRemainingDue + TotalTaxAmount : payRemainingDue}
            CreditAmount={selectedPaymentProfile?.Credit}
            showAdditionalPayment={showAdditionalPayment}
            handleChangeAdditionalPaymentProfile={handleChangeAdditionalPaymentProfile}
            handleAddAdditionalPayment={handleAddAdditionalPayment}
        />}
    </div>
  );
};

export default withNotification(Payments)
