import { DropDownList } from "@progress/kendo-react-dropdowns";
import BuzopsReportGrid from "generic-components/BuzopsReportGrid";
import ReportsCommonFilters from "../../reportsCommonFilters";
import useOnsiteLog from "../Container/OnsiteLog.hook";

const OnSiteLog = (props: any) => {
  const uuid = props?.match?.params?.uuid;
  const onsiteLog = useOnsiteLog();
  return (
    <>
      <ReportsCommonFilters
        {...props}
        history={props.history}
        location={props.location}
        setFromDateToDate={onsiteLog.setFromDateToDate}
      />
      <div className="mt-4 subscribers-main-container">
        <div className="d-flex justify-content-end subscribers-close-icon">
          <li
            className="k-icon k-i-close"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
        <div className="subscribers-main-header">
          <h2 className="subscriber-header">On Site Log</h2>
          <DropDownList
            data={["Select AccessPoint", "test", "test"]}
            defaultValue={"Select AccessPoint"}
          />
          &nbsp;
          <DropDownList
            data={["Select User", "test", "test"]}
            defaultValue={"Select User"}
          />
          <BuzopsReportGrid
            fileName="OnSiteLog.xlsx"
            result={onsiteLog.pageInfo?.data}
            dataState={onsiteLog.pageInfo}
            stateGridColumns={onsiteLog.stateGridColumns}
            pageSize={5}
            setStateGridColumns={(data: any) =>
              onsiteLog.setStateGridColumns(data)
            }
            onColumnsSubmit={onsiteLog.onColumnsSubmit}
            onCellClick={onsiteLog.onCellClick}
            excelData={onsiteLog.excelData}
            setExcelData={() => onsiteLog.setExcelData([])}
          />
        </div>
      </div>
    </>
  );
};

export default OnSiteLog;
