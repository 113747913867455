import React, { useEffect, useState } from "react";
import { ManageCustomFieldsService } from "services/managecustomfields/index.service";
import { CustomFieldsTypeEnum } from "utils/form-utils";
import { mobileValidator } from "validators/validator";

const useCustomFieldsUpdate = (props: any) => {
  const service = new ManageCustomFieldsService();
  const [fieldsData, setFieldsData] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState<any>(false);
  const [reload, setReload] = useState<any>(false);
  const [modified, setModified] = useState<any>(false);
  const handleChange = (val: any, name: string) => {
    const newData = [...fieldsData];
    const rec = newData?.map((i: any) => {
      if (i?.FieldName === name) {
        let value =val
        if(i?.FieldType === CustomFieldsTypeEnum['Numeric'] && val <=0){
          value = Math.abs(val)
        }
        if(i?.FieldType === CustomFieldsTypeEnum['PhoneNumber'] && val ==="(___) ___-____"){
          value=""
        }
        return { ...i, Value: value };
      }
      return { ...i };
    });
    setFieldsData(rec);
    setModified(true)
  };

  useEffect(() => {
    if (props?.CustomFields?.length > 0) {
      fetchFieldsData(props?.CustomFields);
    }
  }, [props?.CustomFields]);

  const checkFieldName = (element: any, index: number, array: any) => {
    return element?.IsRequired && element?.CanShow && (element?.Value === "" || !element?.Value);
  };

  const handleFieldValidation = (showNotification = true) => {
    const findInvalid = fieldsData.some(checkFieldName);
    const findInvalidPhone = fieldsData.findIndex((i:any)=>
    {
      if(i?.FieldType=== CustomFieldsTypeEnum['PhoneNumber'] && i?.CanShow && i?.IsRequired && mobileValidator(i?.Value) !==""){
        return true
      }else if(i?.FieldType=== CustomFieldsTypeEnum['PhoneNumber'] && i?.CanShow && i?.Value && mobileValidator(i?.Value) !==""){
        return true
      }
      return false
    })
    console.log(findInvalidPhone)
    if (findInvalid || findInvalidPhone !==-1) {
      if (showNotification) {
        props?.handleNotificationMessage(
          `Please fill out the required additional information fields`,
          "error"
        );
      }
      return false;
    } else {
      return true;
    }
  };

  const handleUpdate = async () => {
    const check = handleFieldValidation();
    if (check) {
      const req = {
        EntityId: props?.EntityId,
        CustomFields: fieldsData,
      };
      setBtnLoading(true)
      const savePlanCustomFields = await service.saveMemberPlanCustomFields(
        req
      );
      setBtnLoading(false)

      if (savePlanCustomFields?.ResponseCode === 100) {
        const successMsg = "Updated Successfully";
        props?.handleNotificationMessage(successMsg, "success");
        setReload(true);
      } else {
        const errorMsg = savePlanCustomFields.ErrorMessages[0];
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
    setModified(false)
  };

  const handleCancel = () => {
    if (props?.CustomFields?.length > 0) {
      fetchFieldsData(props?.CustomFields);
    }
    setModified(false)
  };

  const fetchFieldsData = async (res: any) => {
    const result = res?.sort((a: any, b: any) => {
      return a.Order - b.Order;
    });
    setFieldsData(result || []);
  };

  return {
    handleChange,
    fieldsData,
    handleFieldValidation,
    handleUpdate,
    handleCancel,
    reload,
    btnLoading,
    modified,
    setReload
  };
};

export default useCustomFieldsUpdate;
