import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { EventEmitter } from "fbemitter";
import { Button } from "@progress/kendo-react-buttons";
import { useApiClient } from "services/axios-service-utils";
import withNotification from "components/Hoc/withNotification";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsFormValidator from "../FormGenerator/form-validator";
import { Address, Prospect } from "../Preview/multi-column";
import BaseFormELements from "../Preview/BaseFormElements";
import { GetLocalStore, LocalStore } from "utils/storage";
import { useHistory } from "react-router-dom";
import SuccessForm from "./SuccessForm";
import { AffiliateZipCodeRegex, NameRegex } from "validators/validator";
import moment from "moment";
import { TenantService } from "services/tenant/index.service";
import { useDispatch, useSelector } from "react-redux";
import { setUserConfiguration } from "redux-slices/userSlice";
import { getFullImageUrl } from "utils";

const { Checkboxes, Signature, FileUpload } = BaseFormELements;

const intialFormParentValue = {
  FormName: "",
  Description: "",
  ReturnUrl: "",
  Value: "",
  ShortCode: "",
  OldShortCode: "",
  CanShowFormName: true,
};

const BuzopsOnlineFormGenerator = (props: any) => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const redirection = params.get('redirection');
  const displaylogo = params.get('displaylogo');
  const dispatch = useDispatch()
  const userInfo = useSelector(
    (state: any) => state?.userConfiguration?.userDetails
  );
  const [FormParentValue, setFormParentValue] = useState(intialFormParentValue);
  const [formTemplateData, setFormTemplateData] = useState<any>(null);
  const [answerData, setAnswerData] = useState<any>(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<any>({});
  const [tenantDetails, setTenantDetails] = useState<any>(null);
  const [logo, setLogo] = useState("null");
  const inputs = useRef<any>({});
  // const answerData = handleConvert(props?.answer_data);
  const emitterRef = new EventEmitter();
  const { axiosRequest } = useApiClient();
  useEffect(() => {
    fetchTenants();
  }, [GetLocalStore("tenant")]);


  useEffect(() => {
    if (tenantDetails) {
      if (tenantDetails?.Logo && tenantDetails?.Logo !== "") {
        setLogo(tenantDetails?.Logo);
      }
    }
  }, [tenantDetails]);

  async function fetchTenants() {
    setTenantDetails(GetLocalStore("tenantDetails"));
  }
  const tenantName = GetLocalStore("tenantDetails")?.Name;

  useEffect(() => {
    if (props?.FormParentValue?.FormName) {
      setFormParentValue(props?.FormParentValue);
    }
  }, []);
  useEffect(() => {
    if (props?.data) {
      console.log("props ",props?.FormParentValue)
      setFormTemplateData(props?.data)
    }
    setAnswerData(handleConvert(props?.answer_data))
  }, [props?.data, props?.answer_data]);

  function handleConvert(answers: any) {
    if (Array.isArray(answers)) {
      const result: any = {};
      answers.forEach((x) => {
        if (x.name.indexOf("tags_") > -1) {
          result[x.name] = x.value.map((y: any) => y.value);
        } else {
          result[x.name] = x.value;
        }
      });
      return result;
    }
    return answers || {};
  }

  const _getDefaultValue = (item: any) => {
    return answerData[item.field_name];
  };

  const _optionsDefaultValue = (item: any) => {
    const defaultValue = _getDefaultValue(item);
    if (defaultValue) {
      return defaultValue;
    }

    const defaultChecked: any = [];
    item.options.forEach((option: any) => {
      if (answerData[`option_${option.key}`]) {
        defaultChecked.push(option.key);
      }
    });
    return defaultChecked;
  };

  const handleFileChange = (value: string, item: any) => {
    setUploadedFile({ ...uploadedFile, [item?.field_name]: value })
  }

  const _getItemValue = (item: any, ref: any) => {
    let $item: any = {
      element: item.element,
      value: "",
    };
    if (item.element === "Rating") {
      $item.value = ref.inputField.current.state.rating;
    } else if (item.element === "Tags") {
      $item.value = ref.inputField.current.state.value;
    } else if (item.element === "DatePicker") {
      $item.value = ref.state.value;
    } else if (item.element === "Camera") {
      $item.value = ref.state.img;
    } else if (item.element === "FileUpload") {
      $item.value = ref?.state?.fileUpload;
    } else if (ref && ref.inputField && ref.inputField.current) {
      $item = ReactDOM.findDOMNode(ref.inputField.current);
      if ($item && typeof $item.value === "string") {
        $item.value = $item.value.trim();
      }
    }
    return $item;
  };

  const _isIncorrect = (item: any) => {
    let incorrect = false;
    if (item.canHaveAnswer) {
      const ref = inputs?.current[item.field_name];
      if (item.element === "Checkboxes" || item.element === "RadioButtons") {
        item.options.forEach((option: any) => {
          const $option: any = ReactDOM.findDOMNode(
            ref.options[`child_ref_${option.key}`]
          );
          if (
            (option.hasOwnProperty("correct") && !$option.checked) ||
            (!option.hasOwnProperty("correct") && $option.checked)
          ) {
            incorrect = true;
          }
        });
      } else {
        const $item = _getItemValue(item, ref);
        if (item.element === "Rating") {
          if ($item.value.toString() !== item.correct) {
            incorrect = true;
          }
        } else if (
          $item.value.toLowerCase() !== item.correct.trim().toLowerCase()
        ) {
          incorrect = true;
        }
      }
    }
    return incorrect;
  };

  const _isInvalid = (item: any,bypassRequried=false) => {
    let invalid = false;
    if (item.required === true || bypassRequried) {
      const ref = inputs.current[item.field_name];
      if (item.element === "Checkboxes" || item.element === "RadioButtons") {
        let checked_options = 0;
        item.options.forEach((option: any) => {
          const $option: any = ReactDOM.findDOMNode(
            ref.options[`child_ref_${option.key}`]
          );
          if ($option.checked) {
            checked_options += 1;
          }
        });
        if (checked_options < 1) {
          invalid = true;
        }
      } else {
        const $item = _getItemValue(item, ref);
        console.log("item ", $item)
        if (item.element === "Rating") {
          if ($item.value === 0) {
            invalid = true;
          }
        } else if (item.element === "FileUpload") {
          if (!uploadedFile[item?.field_name]) {
            invalid = true;
          }
        }  else if (item.element === "NumberInput") {          
          if(item?.maxLength){
            if ($item.value.length > item?.maxLength) {
              invalid = true;
            }
            if(item?.text==='ZipCode'){
              if ($item.value.length !== item?.maxLength) {
                invalid = true;
              }
            }
          }else if ($item.value === undefined || $item.value.length < 1) {
            invalid = true;
          }
        }         
        else if ($item.value === undefined || $item.value.length < 1) {
          invalid = true;
        }
      }
    }
    return invalid;
  };

  const _collect = (item: any) => {
    const itemData: any = {
      id: item.id,
      name: item.field_name,
      custom_name: item.custom_name || item.field_name,
    };
    if (!itemData.name) return null;
    const ref = inputs.current[item.field_name];
    if (item.element === "Checkboxes" || item.element === "RadioButtons") {
      const checked_options: any = [];
      item.options.forEach((option: any) => {
        const $option: any = ReactDOM.findDOMNode(
          ref.options[`child_ref_${option.key}`]
        );
        if ($option.checked) {
          checked_options.push(option.key);
        }
      });
      itemData.value = checked_options;
    } else if (item.element === "FileUpload") {
      itemData.value = uploadedFile[item?.field_name];
    } else {
      if (!ref) return null;
      itemData.value = _getItemValue(item, ref).value;
    }
    return itemData;
  };

  const _collectFormData = (data: any) => {
    const formData: any = [];
    data.forEach((item: any) => {
      const item_data = _collect(item);
      if (item_data) {
        formData.push(item_data);
      }
    });
    return formData;
  };

  const _getSignatureImg = (item: any) => {
    const ref = inputs.current[item.field_name];
    const $canvas_sig = ref.canvas.current;
    if ($canvas_sig) {
      const base64 = $canvas_sig
      // .toDataURL()
      // .replace("data:image/png;base64,", "");
      // const isEmpty = $canvas_sig.isEmpty();
      const $input_sig: any = ReactDOM.findDOMNode(ref.inputField.current);
      // if (isEmpty) {
      //   $input_sig.value = "";
      // } else {
      $input_sig.value = base64;
      // }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let errors = [];
    if (!props.skip_validations) {
      errors = validateForm();
      //   emitter.emit("formValidation", errors);
      emitterRef?.emit("formValidation", errors);
    }

    if (errors.length < 1) {
      const data = _collectFormData(formTemplateData);
      handleSubmitFormResponses(data, FormParentValue);
    }
  };

  const handleBlur = (event: any) => {
    if (props.onBlur) {
      const data = _collectFormData(formTemplateData);
      props.onBlur(data);
    }
  };

  const handleChange = (event: any) => {
    if (props.onChange) {
      const data = _collectFormData(formTemplateData);
      props.onChange(data);
    }
  };
  const errorCallBack = (message: any) => {
    props?.handleNotificationMessage(message, "error");
  };

  const fetchUserDetails = async (userMemberId: any) => {
    const service = new TenantService();
    const res = await service?.getUserInfo(userMemberId);
    const Status = res?.Status
    console.log(Status, "Statsissiiss")
    const userData = {
      ...userInfo,
      MemberStatus: Status,
    };
    dispatch(
      setUserConfiguration({
        loggedIn: true,
        userDetails: userData,
      })
    );
    LocalStore("userDetails", userData);
  };


  const handleSubmitFormResponses = async (dataItem: any, parentData: any) => {
    const tenantUserSession = GetLocalStore("tenantUserSession");
    const keyValueObject: any = {};

    dataItem.forEach((obj: any) => {

      keyValueObject[obj.custom_name] = obj.value;
    });
    const req = {
      FormTemplateId: props?.FormParentValue?.FormTemplateId,
      AffiliateAssociateId: props?.FormParentValue?.AffiliateAssociateId || undefined,
      OwnerAssociateId: props?.FormParentValue?.OwnerAssociateId || undefined,
      ReferralPromotionId: props?.FormParentValue?.ReferralPromotionId || undefined,
      Values: formTemplateData,
      Answers: keyValueObject,
      UserId: props?.userDetails?.UserId ? props?.userDetails?.UserId : undefined,
      // UserId: 10010,
      // UserId: "tQ==",
      ClubId: tenantUserSession?.ClubId,
    };
    setBtnLoading(true);
    await axiosRequest.post("Forms/FormInstance", req, {
      successCallBack: async (response: any) => {
        if (props?.clientDashboard) {
          await fetchUserDetails(props?.userDetails?.UserMemberId)
          setShowSuccessPage(true)
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
          history.push(`/form/success`, {
            Title: `${FormParentValue?.FormName}`,
            ReturnUrl: `${FormParentValue?.ReturnUrl}`,
            shortcode : FormParentValue?.ShortCode,
            redirection : redirection,
            FormCategory:`${props?.FormParentValue?.FormCategory}`
          })
        }
      },
      errorCallBack: (response: any) => {
        setBtnLoading(false);
        errorCallBack(
          response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] || "Internal server error"
        );
      },
    });
  };

  const validateFunction = (item: any) => {
    console.log(item, "Item")
    const errors: any = [];
    if (item.element === "Signature") {
      _getSignatureImg(item);
    }

    const ref = inputs.current[item.field_name];
    const itemValue = ref?.inputField?.current?.value;

    if (_isInvalid(item)) {
      if(itemValue){
         if (item?.text === "ZipCode") {
           errors.push(`${item.label} length should be ${item.maxLength}`);
         } else if (item?.maxLength) {
           errors.push(`${item.label} max length should be ${item.maxLength}`);
         } else {
           errors.push(`${item.label} must be valid value`);
         }
      }else{
        errors.push(`${item.label} is required!`);
      }
    }
    // not requried field validation
    if(!item.required && itemValue){
      if (_isInvalid(item,true) ) {
        if (item?.text === "ZipCode") {
          errors.push(`${item.label} length should be ${item.maxLength}`);
        } else if (item?.maxLength) {
          errors.push(`${item.label} max length should be ${item.maxLength}`);
        } else {
          errors.push(`${item.label} must be valid value`);
        }
      }
    }

    if (item.element === "EmailInput") {
      const ref = inputs.current[item.field_name];
      const emailValue = _getItemValue(item, ref).value;
      if (emailValue) {
        const validateEmail = (email: any) =>
          email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        const checkEmail = validateEmail(emailValue);
        if (!checkEmail) {
          errors.push(`${item.label} field requires valid email address`);
        }
      }
    }

    if (item.element === "PhoneNumber") {
      const ref = inputs.current[item.field_name];
      const phoneValue = _getItemValue(item, ref).value;
      if (phoneValue) {
        const validatePhone = (phone: any) =>
          phone.match(
            /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g
          );
        const checkPhone = validatePhone(phoneValue);
        if (!checkPhone) {
          errors.push(`${item.label} field requires a valid phone number`);
        }
      }
    }

    if (item.element === "TextInput") {
      const ref = inputs.current[item.field_name];
      const name = _getItemValue(item, ref).value;
      if (item.text === "First Name" || item.text === "Last Name") {
        //text only accept
        if (name && !NameRegex.test(name)) {
          errors.push(`${item.label} field requires valid Name`);
        }
      } else if (item.text === "ZipCode") {
        if (name && !AffiliateZipCodeRegex.test(name)) {
          errors.push(`${item.label} field requires valid value`);
        }
      }
    }

    if (item.element === "DatePicker") {
      let dobErrorFlag = false;
      const ref = inputs.current[item.field_name];
      const dateValue = _getItemValue(item, ref).value;
      const date = moment(dateValue, item.dateFormat.toUpperCase(), true);

      if (item?.required && !date.isValid()) {
        dobErrorFlag = true;
        errors.push(`${item.label} field not in proper format`);
      } else if (dateValue && !date.isValid()) {
        dobErrorFlag = true;
        errors.push(`${item.label} field not in proper format`);
      }
      const currentDate = moment();
      const givenDate = moment(dateValue);
      if (item.text === "Date of Birth" && givenDate.isSameOrAfter(currentDate, 'day') && !dobErrorFlag) {
        dobErrorFlag = true;
        errors.push(`${item.label} field should not be greater or equal to current date`);
      }
    }

    if (props.validateForCorrectness && _isIncorrect(item)) {
      errors.push(`${item.label} was answered incorrectly!`);
    }

    return errors;
  }

  const validateForm = () => {
    const errors: any = [];
    let data_items = formTemplateData;

    if (props.display_short) {
      data_items = formTemplateData?.filter((i: any) => i.alternateForm === true);
    }

    data_items?.forEach((item: any) => {
      if (!item?.parentId) {
        if (item?.childItems) {
          data_items?.forEach((itm: any) => {
            if (item?.childItems.includes(itm.id)) {
              const err = validateFunction(itm)
              if (err.length > 0) {
                errors.push(err);
              }
            }
          })
        }
        const err = validateFunction(item)
        if (err.length > 0) {
          errors.push(err);
        }
      }
    });

    return errors;
  };

  const getDataById = (id: any) => {
    const { data } = props;
    return data.find((x: any) => x.id === id);
  };

  const getInputElement = (item: any) => {
    // if (item.custom) {
    //   return getCustomElement(item);
    // }
    if (item?.element) {
      const Input = BaseFormELements[item.element];
      return (
        <Input
          handleChange={handleChange}
          ref={(c: any) => (inputs.current[item.field_name] = c)}
          mutable={true}
          key={`form_${item.id}`}
          data={item}
          read_only={props.read_only}
          defaultValue={_getDefaultValue(item)}
        />
      );
    }
  };

  const getContainerElement = (item: any, Element: any) => {
    const controls = item.childItems.map((x: any) =>
      x ? getInputElement(getDataById(x)) : <div>&nbsp;</div>
    );
    return (
      <Element
        mutable={true}
        key={`form_${item.id}`}
        data={item}
        controls={controls}
      />
    );
  };

  const getSimpleElement = (item: any) => {
    const Element = BaseFormELements[item.element];
    return <Element mutable={true} key={`form_${item.id}`} data={item} />;
  };

  const handleRenderSubmit = () => {
    const name = props.action_name || props.actionName;
    const actionName = name || "Submit";
    const { submitButton = false } = props;

    return (
      submitButton || (
        <BuzopsButton
          loading={btnLoading}
          disabled={props?.read_only || btnLoading}
          type="submit"
          label={actionName}
        />
      )
    );
  };
  const handleReset = (e: any) => {
    e.preventDefault()
    Object.keys(inputs.current).forEach((key) => {
      if (inputs.current[key] && inputs.current[key].inputField) {
        inputs.current[key].inputField.current.value = ""
        if (inputs.current[key].inputField.current?.clear) {
          inputs.current[key].inputField.current?.clear()
        }
        if (inputs.current[key]?.clear) {
          inputs.current[key]?.clear()
        }
      }

      if (inputs.current[key] && inputs.current[key].options) {
        const rec = inputs.current[key].options
        Object.keys(rec).forEach((subkey) => {
          inputs.current[key].options[subkey].checked = false
        })
      }
    });
    //clear image
    const clearDivElements = document.getElementsByClassName('btn-file-upload-clear');
    // Loop through all elements and trigger click on each
    for (let i = 0; i < clearDivElements.length; i++) {
      clearDivElements[i]?.click();
    }

  }

  const handleRenderBack = () => {
    const name = props.back_name || props.backName;
    const backName = name || "Cancel";
    const { backButton = false } = props;

    return (
      backButton || (
        <Button
          type="reset"
          disabled={props?.read_only || btnLoading}
          onClick={(e) => handleReset(e)}
        >
          {backName}
        </Button>
      )
    );
  };

  let data_items = formTemplateData;
  if (props.display_short) {
    data_items = formTemplateData?.filter((i: any) => i.alternateForm === true);
  }

  // data_items?.forEach((item: any) => {
  //   if (
  //     item &&
  //     item.readOnly &&
  //     item.variableKey &&
  //     props.variables[item.variableKey]
  //   ) {
  //     answerData[item.field_name] = props.variables[item.variableKey];
  //   }
  // });

  const items = data_items?.filter((x: any) => !x.parentId)?.map((item: any) => {
    if (!item) return null;
    switch (item.element) {
      case "TextInput":
      case "EmailInput":
      case "PhoneNumber":
      case "NumberInput":
      case "TextArea":
      case "Dropdown":
      case "DatePicker":
      case "RadioButtons":
      case "Rating":
      case "Tags":
      case "Range":
        return getInputElement(item);
      // case "CustomElement":
      //   return getCustomElement(item);
      case "Address":
        return getContainerElement(item, Address);
      case "Prospect":
        return getContainerElement(item, Prospect);
      case "Signature":
        return (
          <Signature
            ref={(c) => (inputs.current[item.field_name] = c)}
            read_only={props.read_only}
            mutable={true}
            key={`form_${item.id}`}
            data={item}
            defaultValue={_getDefaultValue(item)}
          />
        );
      case "Checkboxes":
        return (
          <Checkboxes
            ref={(c) => (inputs.current[item.field_name] = c)}
            handleChange={handleChange}
            mutable={true}
            key={`form_${item.id}`}
            data={item}
            defaultValue={_optionsDefaultValue(item)}
          />
        );
      case "FileUpload":
        return (
          <FileUpload
            ref={(c) => (inputs.current[item.field_name] = c)}
            handleFileChange={(e: any) => { handleFileChange(e, item) }}
            mutable={true}
            FormParentValue={FormParentValue}
            key={`form_${item.id}`}
            data={item}
            defaultValue={_getDefaultValue(item)}
          />
        );
      default:
        return getSimpleElement(item);
    }
  });
  if (showSuccessPage) {
    return <SuccessForm ReturnUrl={FormParentValue?.ReturnUrl} Title={FormParentValue?.FormName} clientDashboard={props?.clientDashboard} />
  }
  console.log(displaylogo, "displaylogo")
  console.log(redirection, "redirection")
  return (
    <div className="online-form-generator">
      <BuzopsFormValidator emitterRef={emitterRef} />
      {((displaylogo ==="true" && redirection==="false") || !redirection || !displaylogo || redirection==="true") &&   <div className="d-flex w-100 justify-content-between borderheadingfroms">
        <div> <div className={`bz-navBar-logo-sec d-flex align-items-center`}>
          {logo !== "null" &&
            logo !== "/Assets/Images/logo.png" &&
            tenantDetails?.IsLogoVisible && (
              <span className="title tn  buz-font-600 bz-cursor-pointer logo-container py-0 pr-2 mr-1">
                <img src={getFullImageUrl(logo)} alt="Logo" />
              </span>
            )}
          {tenantDetails?.IsLogoLabelVisible && (
            <h6 className="bz-tenet-name bz-cursor-pointer mb-0 pl-0  borderlogoforms">
              {tenantName}
            </h6>
          )}
        </div></div>
        {FormParentValue?.CanShowFormName ? <h5 className="ingtakeformonline buz-font-600 flex-grow-1 text-center" >{FormParentValue?.FormName}</h5> : null}
      </div>}
      <div className="react-form-builder-form">
        <form
          // encType="multipart/form-data"
          action={props.form_action}
          onBlur={handleBlur}
          onChange={handleChange}
          onSubmit={handleSubmit}
          method={props.form_method}
          style={{ padding: "0px" }}
        >
          {/* {props.authenticity_token && (
    <div style={formTokenStyle}>
    <input name="utf8" type="hidden" value="&#x2713;" />
    <input
    name="authenticity_token"
    type="hidden"
    value={props.authenticity_token}
    />
    <input name="task_id" type="hidden" value={props.task_id} />
    </div>
  )} */}

          {items}
          <div className="btn-toolbar d-flex w-100 my-3 justify-content-center">
            {!props.hide_actions && handleRenderSubmit()}
            {!props.hide_actions && props.back_action && handleRenderBack()}
          </div>
        </form>
      </div>
    </div>
  );
};

export default withNotification(BuzopsOnlineFormGenerator);
