import React, { createContext, useState } from "react";

export const TimeZoneContextApi = createContext<any>(null);
const TimeZoneContext = ({ children }: any) => {
  const tzvalueName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [tz, setTz] = useState<any>(tzvalueName);

  const updateTimeZone = (val: any) => {
    setTz(val);
  };
  return (
    <TimeZoneContextApi.Provider
      value={{
        tz,
        updateTimeZone,
      }}
    >
      {children}
    </TimeZoneContextApi.Provider>
  );
};

export default TimeZoneContext;
