import { process } from "@progress/kendo-data-query";
import { useState } from "react";
import { AccountUpdaterColumns } from "../Utilities/constants";

const useAccountUpdater = () => {
  const [dataType, setDataType] = useState("");
  const [originalTableData, setOriginalTableData] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [dataState, setDataState] = useState({
    take: 5,
    skip: 0,
  });
  const [stateGridColumns, setStateGridColumns] = useState(
    AccountUpdaterColumns
  );
  const handleChange = (type: any) => {
    setDataType(type);
  };
  const createDataState = (data: any, dataState: any) => {
    return {
      result: process(data.slice(0), dataState),
      dataState: dataState,
    };
  };
  const dataStateChange = (event: any) => {
    let updatedState = createDataState(
      originalTableData?.data,
      event.dataState
    );
    setTableData(updatedState.result);
    setDataState(updatedState?.dataState);
  };

  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };
  return {
    dataType,
    setOriginalTableData,
    handleChange,
    tableData,
    totalItems,
    setTotalItems,
    dataState,
    dataStateChange,
    onColumnsSubmit,
    stateGridColumns,
    setStateGridColumns,
  };
};

export default useAccountUpdater;
