import ContentLoader from "react-content-loader";
export type loaderType = "list" | "text" | "content" | "singlecontent";
export interface loaderProps {
  type?: loaderType;
  loading?: boolean;
}
const BuzopsLoader = (props: loaderProps) => {
  const renderLoader = () => {
    switch (props.type) {
      case "list": {
        return (
          <ContentLoader
            speed={2}
            viewBox="0 0 400 150"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect y="10" width="100%" height="20" />
            <rect y="35" width="100%" height="20" />
            <rect y="60" width="100%" height="20" />
            <rect y="85" width="100%" height="20" />
            <rect y="110" width="100%" height="20" />
          </ContentLoader>
        );
      }
      case "content": {
        return (
          <ContentLoader
            speed={2}
            viewBox="0 0 400 60"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect y="3" width="100%" height="50" />
          </ContentLoader>
        );
      }
      case "singlecontent": {
        return (
          <ContentLoader
            speed={2}
            viewBox="0 0 200 15"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect y="3" width="100%" height="10" />
          </ContentLoader>
        );
      }
      default: {
        return (
          <ContentLoader
            speed={2}
            viewBox="0 0 400 80"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10px" y="10px" width="calc(100% - 20px)" height="10" />
            <rect x="10px" y="25px" width="calc(100% - 20px)" height="10" />
            <rect x="10px" y="40px" width="calc(100% - 20px)" height="10" />
            <rect x="10px" y="55px" width="calc(100% - 20px)" height="10" />
            <rect x="10px" y="70px" width="calc(100% - 20px)" height="10" />
          </ContentLoader>
        );
      }
    }
  };
  return renderLoader();
};
export default BuzopsLoader;
