import axios from "axios";
import {
  GetLocalStore,
  LocalStore,
  RemoveAllLocalStore,
  RemoveLocalStore,
} from "utils/storage";
import TimeZoneSetup from "utils/time-zone-setup";

export class CommonServiceUtils {
  async getTenantDetails(tenant: string): Promise<any> {
    const getToken: any = await this.postData(
      `${this.apiUrlCreator(`authentication/issuetoken/${tenant}`)}`
    );
    if (tenant !== GetLocalStore("tenant")) {
      RemoveAllLocalStore();
    }
    RemoveLocalStore("tenant");
    if (getToken?.ResponseCode === 100) {
      LocalStore("tenant", tenant);
      LocalStore("token", getToken.Item.Token);
      TimeZoneSetup(
        getToken.Item.UserSession?.TenantTime,
        getToken.Item.UserSession
      );
      LocalStore("tenantUserSession", {...getToken?.Item?.UserSession, DomainName: getToken.Item.DomainName, StripePaymentConfiguration: getToken.Item.StripePaymentConfiguration, ChargehubSquarePaymentProfile: getToken.Item.ChargehubSquarePaymentProfile, 
      Configuration: getToken.Item.Configuration});
      LocalStore("Configuration", {...getToken.Item.Configuration,IsAffiliateFeatureEnabled:getToken.Item.IsAffiliateFeatureEnabled});
      return getToken;
    }
  }

  postData = (url: string, data?: any, config?: any) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, data, config)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.message);
        });
    });

  getData = (url: string, req?: any) =>
    new Promise((resolve, reject) => {
      axios
        .get(url, req)
        .then((res) => {
          resolve(res?.data);
        })
        .then((err) => {
          reject(err);
        });
    });

  patchData = (url: string, data?: any) =>
    new Promise((resolve, reject) => {
      axios
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .then((err) => {
          reject(err);
        });
    });

  deleteData = (url: string) =>
    new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then((res) => {
          resolve(res);
        })
        .then((err) => {
          reject(err);
        });
    });

  apiUrlCreator = (endPoint: string) => {
    return `${process.env.REACT_APP_API_URL}/api/${endPoint}`;
  };

  apiAccessControlBackendCreator = (endPoint: string) => {
    return `${process.env.REACT_APP_ACCESS_CONTROL_BACKEND + endPoint}`;
  };

  prepareResponseFromServer = (response: any) => {
    let res = {success: false, data: null, msg: ""};
    if(!response){
      return res;
    }
    const {Item, Items, ErrorMessages, ResponseCode} = response;
    if(ErrorMessages?.length > 0){
      res.success = false,
      res.data = null,
      res.msg = ErrorMessages[0];
      return res;
    }
    if(Item){
      res.success = true,
      res.data = Item,
      res.msg = "Success"
      return res;

    }
    if(Items?.length > 0){
      res.success = true,
      res.data = Items,
      res.msg = "Success"
      return res;

    }
    console.log(ResponseCode);
    if(ResponseCode === 100){
      res.success = true,
      res.data = [],
      res.msg = "Success"
      return res;
    }
    return res;
  }
}
