import React from "react";
import myxss from "./myxss";

const ComponentLabel = (props: any) => {
  const hasRequiredLabel =
    props.data.hasOwnProperty("required") &&
    props.data.required === true; 
    // && !props.read_only;
  const labelText = myxss.process(props.data.label);
  if (!labelText) {
    return null;
  }
  return (
    <label className={props.className || "form-label"}>
      <span dangerouslySetInnerHTML={{ __html: labelText }} />
      {hasRequiredLabel && !props?.mutable && (
        <span className="label-required required-asterisk ">*</span>
      )}
      {hasRequiredLabel && props?.mutable && (
        <span className="label-required required-asterisk">*</span>
      )}
    </label>
  );
};

export default ComponentLabel;
