import { AutoComplete, ListItemProps } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useState } from 'react';
import { ClassService } from 'services/class/index.service';

interface IClientSelect {
    setSelectedClient: any,
    reset?: any;
    setResetForm?: any
}
const ClientSelect = (props: IClientSelect) => {
    const [clientsList, setClientsList] = useState<any>([]);
    const [selectedClient, setSelectedClient] = useState<any>({
        type: "search",
        value: "",
    });
    useEffect(() => {
      if(selectedClient.value){
        setSelectedClient({
          type: "search",
          value: "",
      })
      props?.setResetForm?.();
      }
    }, [props.reset])
    const [clientSearchLoading, setClientSearchLoading] = useState(false);

    const itemRender = (
        li: React.ReactElement<HTMLLIElement>,
        itemProps: ListItemProps
      ) => {
        const itemChildren = `${itemProps?.dataItem?.FullName} ${itemProps?.dataItem?.Email}`;
    
        return React.cloneElement(li, li.props, itemChildren);
    };

    async function autoCompleteClients(str: string) {
        const req = {
          Where: {
            SearchKeyword: str,
            IsGloablSearch: true,
          },
        };
        setClientSearchLoading(true);
        const clientservice = new ClassService();
        const result = await clientservice.autoCompleteClients(req);
        setClientSearchLoading(false);
        setClientsList(result);
    }
    const handleClientSelecton = (e: any) => {
        if (e.syntheticEvent?.key === "Enter") {
          if (clientsList?.length > 0) {
            setSelectedClient({
              ...selectedClient,
              type: "select",
              value: clientsList[0],
            });
            props.setSelectedClient(clientsList[0]);
          }
          return true;
        }
        setSelectedClient({
          ...selectedClient,
          type: "search",
          value: e.value,
        });
        if (e.syntheticEvent?.type === "change" && e.value.length >= 3) {
          autoCompleteClients(e.value);
        }
        if (e.syntheticEvent?.type === "click") {
          setSelectedClient({
            ...selectedClient,
            type: "select",
            value: e.value,
          });
          props.setSelectedClient(e.value);
        }
    };
    return (
        <>
            <AutoComplete
                data={clientsList}
                style={{ width: "280px" }}
                className="select_clientdrop"
                itemRender={itemRender}
                value={
                selectedClient?.type === "search"
                    ? selectedClient?.value
                    : selectedClient?.value?.FullName
                }
                onChange={(e: any) => handleClientSelecton(e)}
                placeholder={"Select Client"}
                loading={clientSearchLoading}
                listNoDataRender={() => (
                <span className={"bzo-noData-found"}>
                    {clientSearchLoading
                    ? "Searching for Client..."
                    : "Client is not available please add new client"}
                </span>
                )}
            />
        </>
    )
}

export default ClientSelect;