import { useLocation } from "react-router-dom";
import CompareAddonsGridOne from "./Addons/Compare/CompareAddonsGridOne";
import CompareAddonsGridTwo from "./Addons/Compare/CompareAddonsGridTwo";
import CompareGroupServicesGridOne from "./GroupServices/Compare/CompareGroupServicesGridOne";
import CompareGroupServicesGridTwo from "./GroupServices/Compare/CompareGroupServicesGridTwo";
import CompareOneonOneGridOne from "./One-on-One/Compare/Compare-One-on-One-GridOne";
import CompareOneonOneGridTwo from "./One-on-One/Compare/Compare-One-on-One-GridTwo";
import CompareOneTimeChargeGridOne from "./OneTimeCharge/Compare/CompareOneTimeChargeGridOne";
import CompareOneTimeChargeGridTwo from "./OneTimeCharge/Compare/CompareOneTimeChargeGridTwo";
import ComparePackagesGridOne from "./Packages/Compare/ComparePackagesGridOne";
import ComparePackagesGridTwo from "./Packages/Compare/ComparePackagesGridTwo";
import ComparePlanGridOne from "./Plan/Compare/comparePlanGridOne";
import ComparePlanGridTwo from "./Plan/Compare/comparePlanGridTwo";

const CommonCompare = ({ history, location, ...props }: any) => {
  const locationCompare = useLocation<any>();
  const uuid = props?.match?.params?.uuid;
  console.log(locationCompare);
  const compareLocation = () => {
    if (locationCompare.state.path === `/app/reports/${uuid}/sales/plan`) {
      return (
        <>
          <div className="sales-compare-heading">
            <h2>Sales by Plan Compare</h2>
            <li
              className="k-icon k-i-close"
              onClick={() => {
                history.push(locationCompare.state.path);
              }}
            ></li>
          </div>
          <div className="sample" style={{ display: "flex" }}>
            <ComparePlanGridOne />
            <ComparePlanGridTwo />
          </div>
        </>
      );
    } else if (
      locationCompare.state.path === `/app/reports/${uuid}/sales/addons`
    ) {
      return (
        <>
          <div className="sales-compare-heading">
            <h2>Sales by Add-Ons Compare</h2>
            <li
              className="k-icon k-i-close"
              onClick={() => {
                history.push(locationCompare.state.path);
              }}
            ></li>
          </div>
          <div className="sample" style={{ display: "flex" }}>
            <CompareAddonsGridOne />
            <CompareAddonsGridTwo />
          </div>
        </>
      );
    } else if (
      locationCompare.state.path === `/app/reports/${uuid}/sales/oneTimeCharge`
    ) {
      return (
        <>
          <div className="sales-compare-heading">
            <h2>Sales by One Time Charge Compare</h2>
            <li
              className="k-icon k-i-close"
              onClick={() => {
                history.push(locationCompare.state.path);
              }}
            ></li>
          </div>
          <div className="sample" style={{ display: "flex" }}>
            <CompareOneTimeChargeGridOne />
            <CompareOneTimeChargeGridTwo />
          </div>
        </>
      );
    } else if (
      locationCompare.state.path === `/app/reports/${uuid}/sales/groupServices`
    ) {
      return (
        <>
          <div className="sales-compare-heading">
            <h2>Sales by Group Classes Compare</h2>
            <li
              className="k-icon k-i-close"
              onClick={() => {
                history.push(locationCompare.state.path);
              }}
            ></li>
          </div>
          <div className="sample" style={{ display: "flex" }}>
            <CompareGroupServicesGridOne />
            <CompareGroupServicesGridTwo />
          </div>
        </>
      );
    } else if (
      locationCompare.state.path ===
      `/app/reports/${uuid}/sales/individualServices`
    ) {
      return (
        <>
          <div className="sales-compare-heading">
            <h2>Sales by One-on-One Compare</h2>
            <li
              className="k-icon k-i-close"
              onClick={() => {
                history.push(locationCompare.state.path);
              }}
            ></li>
          </div>
          <div className="sample" style={{ display: "flex" }}>
            <CompareOneonOneGridOne />
            <CompareOneonOneGridTwo />
          </div>
        </>
      );
    } else if (
      locationCompare.state.path === `/app/reports/${uuid}/sales/packages`
    ) {
      return (
        <>
          <div className="sales-compare-heading">
            <h2>Sales by Packages Compare</h2>
            <li
              className="k-icon k-i-close"
              onClick={() => {
                history.push(locationCompare.state.path);
              }}
            ></li>
          </div>
          <div className="sample" style={{ display: "flex" }}>
            <ComparePackagesGridOne />
            <ComparePackagesGridTwo />
          </div>
        </>
      );
    }
  };

  return <>{compareLocation()}</>;
};
export default CommonCompare;
