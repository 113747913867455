import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import React, { useEffect, useRef, useState } from "react";
import {
  CheckUserMemberStatus,
  getDayFormat,
  getMonthFormat,
  getTimeFormat,
  getWeekdayFormat,
  getYearFormat,
} from "utils";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { ClassService } from "services/class/index.service";
import moment from "moment";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsLoader from "generic-components/BuzopsLoader";
import Cancellation from "components/session/Cancellation";
import withNotification from "components/Hoc/withNotification";
import { GetLocalStore } from "utils/storage";
import { PermissionEnum } from "utils/PermissionEnum";
import CheckPermissions from "utils/permissions";
import PayNowSession from "components/session/PayNowSession";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import ClientNameWithMemberType from "../ClientNameWithMemberType";
import { Loader } from "@progress/kendo-react-indicators";
import Location from "components/Location/Location";
import { CheckInService } from "services/checkin/index.service";
import { ThirdPartyConfigService } from "services/thirdparty-configuration/index.service";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import { MdAccessTimeFilled } from "react-icons/md";
import { TbChecks } from "react-icons/tb";
import PaymentModeIconDisplay from "./PaymentModeIconDisplay";
import BuzopsLongNameHandler from "generic-components/BuzopsLongNameHandler";
import ChangePaymentSession from "components/session/ChangePaymentSession";
const initialSelectedWaitlist={
  ScheduleId:0,loading:false
}
const GroupServices = (props: any) => {
  const textAreaRef = useRef<any>();
  useEffect(() => {
    getAppointments(0);
  }, []);
  const [selected, setSelected] = React.useState(0);
  const [cancelClients, setCancelClients] = useState<any>([]);
  const [visibility, setVisibility] = useState({
    cancel: false,
    paynow: false,
    changePayment:false
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedWaitListClient,setSelectedWaitlistClient] = useState<any>(initialSelectedWaitlist);
  const [visible, setvisible] = React.useState(false);
  const [clientNotes, setClientNotes] = useState("");
  const sessionObj = {
    pageNumber: 0,
    totalRecords: 0,
    maxPageNumber: 0,
    sessionData: [],
  };
  const [session, setSession] = useState<any>(null);
  const [payNowItem, setPayNowItem] = useState<any>(null);
  const [classSessionlist, setClassSessionlist] = useState<any>(sessionObj);
  const [loading, setLoading] = useState(false);
  const [securitySystemConfigs, setSecuritySystemConfig] = useState<any>({});
  const cancelledItem = '';
  const getSecuritySystemConfigs = async () => {
    const thirdPartyService = new ThirdPartyConfigService();
    const getResult = await thirdPartyService.getSecuritySystemConfigs();
    if (getResult?.success) {
      setSecuritySystemConfig(getResult?.data);
      return getResult?.data;
    } else {
      return {};
    }
  };
  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    await getSecuritySystemConfigs();
  };
  const checkForFutureRecords = (dataItem: any) => {
    let doCheckin = false;
    const timeStamp = dataItem.ClassSessionStartDate;
    let minDuration = 240;
    minDuration =
      securitySystemConfigs?.GroupClassCheckinPreference
        ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin;

    let diff = moment(moment(timeStamp).format("YYYY-MM-DD HH:mm:ss")).diff(
      moment().format("YYYY-MM-DD HH:mm:ss"),
      "minutes"
    );
    if (diff < minDuration && diff > -30) {
      doCheckin = true;
    } else {
      doCheckin = false;
    }
    if (doCheckin) {
      if (dataItem?.CheckinTimestamp !== null || !dataItem?.IsPaid) {
        doCheckin = false;
      } else {
        doCheckin = true;
      }
    }

    return doCheckin;
  };
  const userSession = JSON.parse(
    JSON.stringify(GetLocalStore("tenantUserSession"))
  );
  const UserAssociateId = userSession.UserAssociateId;
  const userDetails = props?.userData;

  const GroupServiceLevel3Permission = () => {
    return CheckPermissions(PermissionEnum["GroupServiceLevel3"]);
  };
  const GroupServiceCancellationPermission = (dataItem: any) => {
    return (
      CheckPermissions(PermissionEnum["GroupServiceLevel3"]) ||
      dataItem?.TrainerId === UserAssociateId
    );
  };

  const getAppointments = async (pageNumber: number, tab = 0) => {
    if (pageNumber === 0) {
      setLoading(true);
    }
    let UserMemberId = userDetails?.UserMemberId;
    if (props?.specificPackage) {
      UserMemberId = props?.data?.UserMemberId;
    }
    let req: any;
    const fetchAppointments = new ClassService();
    const pageSize = 10;
    if (tab === 0) {
      req = {
        FromDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        PageNumber: pageNumber,
        PageSize: pageSize,
        UserMemberId: UserMemberId,
        IncludeInActive: false,
        IsHistory: false,
      };
    } else if (tab === 2) {
      req = {
        PageNumber: pageNumber,
        PageSize: pageSize,
        ToDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        UserMemberId: UserMemberId,
        IncludeInActive: false,
        IsHistory: false,
        SortDirection: "descending",
        SortBy: "ClassSessionStartDate",
      };
    } else if (tab === 1) {
      req = {
        PageNumber: pageNumber,
        PageSize: pageSize,
        UserMemberId: UserMemberId,
        IncludeInActive: false,
        IsHistory: true,
        SortDirection: "descending",
        SortBy: "ClassSessionStartDate",
      };
    }
    if (props?.specificPackage) {
      req.ClassId = props?.data?.ClassId;
    }
    const result = await fetchAppointments.getSessionsForClient(req);
    if (pageNumber === 0) {
      setLoading(false);
    }
    if (result) {
      const maxPageNumber = result.TotalItems / pageSize;
      if (pageNumber === 0) {
        setClassSessionlist({
          ...classSessionlist,
          pageNumber: 0,
          maxPageNumber: maxPageNumber,
          sessionData: result?.Items || [],
        });
      }
    }
    return result?.Items || [];
  };
  const handleSelect = (e: any) => {
    setClassSessionlist(sessionObj);
    const val = e.selected;
    if (val !== 3) {
      getAppointments(0, val);
    }
    setSelected(val);
  };
  const handleGotoPurchasePage = (name: string) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    props?.handlePurchase(name);
  };


  const fetchClientsForSession = async (sessionData: any) => {
    const req = {
      ClassSessionId: sessionData?.ClassSessionId,
      UserId: sessionData?.UserId,
    };

    const service = new ClassService();
    const result = await service.getClientsForSession(req);
    if (result?.ResponseCode === 100) {
      setCancelClients(result?.Items || []);
    }
  };


  const requestIfNeeded = () => {
    const nextPage = classSessionlist.pageNumber + 1;
    const maxPageNum = classSessionlist.maxPageNumber;
    if (nextPage < maxPageNum) {
      return {
        status: true,
        page: nextPage,
      };
    } else {
      return {
        status: false,
        page: nextPage,
      };
    }
  };
  const scrollHandler = async (event: any) => {
    const e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      const check = await requestIfNeeded();
      if (check.status) {
        const moreData = await getAppointments(check.page, selected);
        if (moreData.length > 0) {
          const test = classSessionlist.sessionData.concat(moreData);
          setClassSessionlist({
            ...classSessionlist,
            sessionData: test,
            pageNumber: check.page,
          });
        }
      }
    }
  };
  let tabs = [
    { title: "Upcoming" },
    { title: "Cancelled" },
    { title: "Past" },
  ];

  if (props?.specificPackage) {
    tabs = [{ title: "Upcoming" }, { title: "Cancelled" }, { title: "Past" }];
  }

  const noRecordsMsg = (indx: number) => {
    switch (selected) {
      case 0:
        return "No Upcoming Appointments Available";
      case 1:
        return "No Cancelled Appointments Available";
      case 2:
        return "No Past Appointments Available";
      case 3:
        return "No Packages Available";
      default:
        return "No Appointments Available";
    }
  };

  const handlePageChange = async (status = "more") => {
    if (status === "more") {
      const check = await requestIfNeeded();
      if (check.status) {
        setPageLoading(true);
        const moreData = await getAppointments(check.page, selected);
        setPageLoading(false);
        if (moreData.length > 0) {
          const test = classSessionlist.sessionData.concat(moreData);
          setClassSessionlist({
            ...classSessionlist,
            sessionData: test,
            pageNumber: check.page,
          });
        }
      }
    }
  };

  const checkIfUserCanCheckin = (accessCardDetails: any) => {
    let doCheckin = false;
    if (accessCardDetails.CardNumber) {
      doCheckin = true;
      if (accessCardDetails.IsSuspended) {
        doCheckin = false;
        props?.handleNotificationMessage(
          "User's access is temporarily suspended",
          "error"
        );
      }
    } else {
      props?.handleNotificationMessage(
        "Access card is not assigned to the selected user",
        "error"
      );
      doCheckin = false;
    }
    return doCheckin;
  };
  // To handle Check-In from Appointments
  const handleCheckIn = async (item: any) => {
    const req = {
      UserMemberId: props?.userData?.UserMemberId || props?.data?.UserMemberId,
      PrimaryUserMemberId:
        props?.userData?.MainUserId || props?.data?.UserMemberId,
      IncludeSubMember: false,
    };
    const simpleAccessService = new SimpleAccessService();
    let response = await simpleAccessService.getMembersWithAccess(req);
    let doCheckin = true;

    if (!response.length) {
      doCheckin = false;
      props?.handleNotificationMessage(
        "Access card is not assigned to the selected user",
        "error"
      );
    } else {
      doCheckin = checkIfUserCanCheckin(response[0].AccessCard);
    }
    if (!doCheckin) {
      return true;
    }

    const checkinService = new CheckInService();
    const obj = {
      Ocurred: moment().format("YYYY-MM-DDTHH:mm:ss"),
      Type: 2,
      CheckedInUserId: props?.userData?.UserId || props?.data?.UserId,
      CheckinMode: 1,
      EntityName: item?.ClassName,
      ScheduledStartTime: moment(item?.ClassSessionStartDate).format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      EntityId: item?.ClassSessionId,
      EntityType: 2,
      ScheduleId: item?.ScheduleId,
    };
    const result = await checkinService?.saveEventsCheckins({
      CheckinEvents: [obj],
    });
    if (result?.ResponseCode === 300) {
      props?.handleNotificationMessage(
        "Check-In successfully completed",
        "success"
      );
      getAppointments(0);
    } else {
      props?.handleNotificationMessage(result.ErrorMessages[0], "error");
    }
  };
  const itemClick = async (e: any, value: any) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    setSession(value);
    const service = new ClassService();
    const item: any = e.item;
    let photo = props?.userData?.Photo;
    let userId = value?.UserId;
    let userMemberId = props?.userData?.UserMemberId;
    if (props?.specificPackage) {
      photo = props?.data?.Photo;
      userId = value?.UserId;
      userMemberId = props?.data?.UserMemberId;
    }
    if (props?.staffPage) {
      if (item?.id === 9) {
        handleCheckIn(value);
        return true;
      }
      if (item.id > 0 && item.id < 5) {
        const scheduleNameText=item.text?.replace(/\s/g, "")
        const ChangeScheduleStatusList = [
          {
            ScheduleId: value.ScheduleId,
            ClientId: userId,
            Status: scheduleNameText,
          },
        ];
        const req = {
          ClassSessionId: value?.ClassSessionId,
          ChangeScheduleStatusList,
        };
        const res = await service.updateClassSessionStatus(req);
        if (res?.ResponseCode === 100) {
          props?.handleNotificationMessage(
            `Status updated successfully`,
            "success"
          );

          getAppointments(0, selected);
        } else {
          const errorMsg =
            res?.ErrorMessages.length > 0
              ? res?.ErrorMessages?.[0]
              : "Error in Updating Status";
          props?.handleNotificationMessage(errorMsg, "error");
        }
      } else if (item.id === 0) {
        const clientDataTemp = JSON.parse(JSON.stringify(classSessionlist));
        const findIndx = clientDataTemp.sessionData.findIndex(
          (val: any) =>
            val.ClassSessionId === value?.ClassSessionId &&
            val?.UserId === value?.UserId
        );
        clientDataTemp.sessionData.forEach((element: any) => {
          element.notes = false;
        });
        if (findIndx !== -1) {
          clientDataTemp.sessionData[findIndx].notes = true;
          setClientNotes(clientDataTemp.sessionData[findIndx].Notes);
        }
        setClassSessionlist(clientDataTemp);
      } else if (item.id < 0) {
        if (validateBeforeCancel(value)) {
          return true;
        }
        handleView("cancel", value);
      } else if (item.id === 5) {
        const obj = {
          ClassSessionId: value?.ClassSessionId,
          ClassId: value?.ClassId,
          ScheduleId: value.ScheduleId,
          UserId: userId,
          UserMemberId: userMemberId,
          DownPayment: value.Cost,
          Name: value.ClassName,
          ProfileImage: photo,
          CanCollectTaxes:value?.CanCollectTaxes || false
        };
        setPayNowItem(obj);
        handleView("paynow", value);
      }else if (item.id === 10) {
        const obj = {
          ClassSessionId: value?.ClassSessionId,
          ClassId: value?.ClassId,
          ScheduleId: value.ScheduleId,
          UserId: userId,
          UserMemberId: userMemberId,
          PaymentMode: value?.PaymentMode,
          PaymentGatewayTransactionHistoryId: value?.PaymentGatewayTransactionHistoryId,
          DownPayment: value.Cost,
          Name: value.ClassName,
          ProfileImage: photo,
          CanCollectTaxes:value?.CanCollectTaxes || false
        };
        setPayNowItem(obj);
        handleView("changePayment", value);
      }else if(item.id ===6){
        const req = {
          classSessionId: value?.ClassSessionId,
          scheduleId: value.ScheduleId,
        };
        setSelectedWaitlistClient({
          ScheduleId:value.ScheduleId,
          loading:true
        })
        const res = await service.ApproveWaitListForClient(req);
        setSelectedWaitlistClient(initialSelectedWaitlist)
        if (res?.ResponseCode === 100) {
          props?.handleNotificationMessage(
            "Waitlist approved successfully, and payment or package credit was applied successfully.",
            "success"
          );
          getAppointments(0, selected);

        }else {
          const errorMsg =
            res?.ErrorMessages.length > 0
              ? res?.ErrorMessages?.[0]
              : "Error in Approving Waitlist";
          props?.handleNotificationMessage(errorMsg, "error");
          getAppointments(0, selected);

        }
      }else if(item.id === 7){
        const userSession = GetLocalStore("tenantUserSession");
        const reqObj: any = {
          ClubDateTime: new Date(),
          ApplicationType: userSession?.CurrentApplicationType,
          CurrentUserId: userSession?.UserId,
          classSessionUserMappings: [
            {
              ClassSessionId: value?.ClassSessionId,
              ScheduleId: value?.ScheduleId,
              UserId: value?.UserId,
              UserMemberId: userMemberId,
              ClassInstanceId: value?.ClassInstanceId,
              Refund: 0,
              Charge: 0,
              ChargeType: "NOCHARGE",
              CanCollectTaxes: false,
            },
          ],
          CanEmailToClient: true,
          IsDenyWaitlistEnrollment:true
        };
        setSelectedWaitlistClient({
          ScheduleId:value.ScheduleId,
          loading:true
        })
        const res = await service.cancelClassSession(reqObj);
        setSelectedWaitlistClient(initialSelectedWaitlist)
        if (res?.ResponseCode === 100) {
          props?.handleNotificationMessage(
            "Waitlist Denied",
            "success"
          );
          getAppointments(0, selected);
        }else {
          const errorMsg =
            res?.ErrorMessages.length > 0
              ? res?.ErrorMessages?.[0]
              : "Error in denying waitlist";
          props?.handleNotificationMessage(errorMsg, "error");
        }
      }

    } else {
      if (item.id < 0) {
        if (validateBeforeCancel(value)) {
          return true;
        }
        handleView("cancel", value);
      } else if (item.id === 0) {
        const clientDataTemp = JSON.parse(JSON.stringify(classSessionlist));
        const findIndx = clientDataTemp.sessionData.findIndex(
          (val: any) =>
            val.ClassSessionId === value?.ClassSessionId &&
            val?.UserId === value?.UserId
        );
        clientDataTemp.sessionData.forEach((element: any) => {
          element.notes = false;
        });
        if (findIndx !== -1) {
          clientDataTemp.sessionData[findIndx].notes = true;
          setClientNotes(clientDataTemp.sessionData[findIndx].Notes);
        }
        setClassSessionlist(clientDataTemp);
      } else if (item.id === 5) {
        const obj = {
          ClassSessionId: value?.ClassSessionId,
          ClassId: value?.ClassId,
          ScheduleId: value.ScheduleId,
          UserId: userId,
          UserMemberId: userMemberId,
          DownPayment: value.Cost,
          Name: value.ClassName,
          ProfileImage: photo,
          CanCollectTaxes:value?.CanCollectTaxes || false
        };
        setPayNowItem(obj);
        handleView("paynow", value);
      }
    }
  };

  const handleView = async (screen: string, value: any) => {
    if (screen === "cancel") {
      await fetchClientsForSession(value);
    }
    setVisibility({ ...visibility, [screen]: true });
  };

  const validateBeforeCancel = (item: any) => {
    const currentTime = moment().format();
    const cancelDuration = item?.MinimumCancellationDuration;
    let MinimumCancellationDurationType = "minutes";
    if (item?.MinimumCancellationDurationType === 1) {
      MinimumCancellationDurationType = "hours";
    } else if (item?.MinimumCancellationDurationType === 2) {
      MinimumCancellationDurationType = "minutes";
    } else if (item?.MinimumCancellationDurationType === 3) {
      MinimumCancellationDurationType = "days";
    }
    const MimimumBookingBeforTime = moment(item?.ClassSessionStartDate)
      .subtract(cancelDuration, MinimumCancellationDurationType)
      .format();

    if (!props?.staffPage && currentTime > MimimumBookingBeforTime) {
      const errorMsg = `Group can not be cancelled as it crossed Minimum Cancellation Duration.`;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    } else {
      return false;
    }
  };
  const renderItems = (selectedItem: any) => {
    console.log(props?.staffPage)
    if (props?.staffPage) {
      switch (selected) {
        case 0:
          let items = [
            {
              text: "Notes",
              id: 0,
              disabled: false,
              visible: true,
            },
            {
              text: "Cancel",
              id: -1,
              disabled: !GroupServiceCancellationPermission(selectedItem),
              visible: selectedItem?.IsWaitList ? false :true,
            },
            {
              text: "Pay",
              id: 5,
              disabled: selectedItem?.PaymentMode === 2 ? false : true,
              visible: !selectedItem?.IsWaitList,
            },
            {
              text: "Approve",
              id: 6,
              disabled: false,
              visible: selectedItem?.IsWaitList,
            },
            {
              text: "Deny",
              id: 7,
              disabled: false,
              visible: selectedItem?.IsWaitList,
            },
            {
              text: "Change payment",
              id: 10,
              disabled: false,
              visible: selectedItem?.IsWaitList && (selectedItem?.PaymentMode === 0 || selectedItem?.PaymentMode === 2),
            },
          ];

          if (checkForFutureRecords(selectedItem)) {
            items.push({
              text: "Check-In",
              id: 9,
              disabled: false,
              visible: true,
            });
          }
          return items;
        case 1:
          return [
            {
              text: "Notes",
              id: 0,
              disabled: false,
              visible: true,
            },
          ];
        case 2:
          return [
            {
              text: "Show",
              id: 2,
              disabled:
                !GroupServiceCancellationPermission(selectedItem) ||
                !selectedItem?.IsPaid || selectedItem?.IsWaitList,
              visible: true,
            },
            {
              text: "No Show",
              id: 3,
              disabled:
                !GroupServiceCancellationPermission(selectedItem) ||
                !selectedItem?.IsPaid || selectedItem?.IsWaitList,
              visible: true,
            },
            {
              text: "Dispute",
              id: 4,
              disabled:
                !GroupServiceLevel3Permission() || !selectedItem?.IsPaid  || selectedItem?.IsWaitList,
              visible: true,
            },
            {
              text: "Notes",
              id: 0,
              disabled: false,
              visible: true,
            },
            {
              text: "Cancel",
              id: -1,
              disabled: !GroupServiceCancellationPermission(selectedItem),
              visible: true,
            },
            {
              text: "Pay",
              id: 5,
              disabled: selectedItem?.PaymentMode === 2 ? false : true,
              visible: !selectedItem?.IsWaitList && selectedItem?.PaymentMode === 2 ? true : false
            },
          ];
        default:
          return [];
      }
    } else {
      switch (selected) {
        case 0:
          return [
            {
              text: "Notes",
              id: 0,
              disabled: false,
              visible: true,
            },
            { text: "Cancel", id: -1, disabled: false, visible: true },
            {
              text: "Pay",
              id: 5,
              disabled: selectedItem?.PaymentMode === 2 ? false : true,
              visible: selectedItem?.PaymentMode === 2 ? true : false,
            },
          ];
        case 1:
          return [
            {
              text: "Notes",
              id: 0,
              disabled: false,
              visible: true,
            },
          ];
        case 2:
          return [
            {
              text: "Notes",
              id: 0,
              disabled: false,
              visible: true,
            },
            {
              text: "Pay",
              id: 5,
              disabled: selectedItem?.PaymentMode === 2 ? false : true,
              visible: selectedItem?.PaymentMode === 2 ? true : false,
            },
          ];
        default:
          return [];
      }
    }
  };
  const allItems = () => {
    return [
      { text: "Show", id: 2, disabled: false },
      { text: "No Show", id: 3, disabled: false },
      { text: "Dispute", id: 4, disabled: false },
      { text: "Notes", id: 0, disabled: false },
      { text: "Cancel", id: -1, disabled: false },
    ];
  };
  const changeNotes = (val: any) => {
    setClientNotes(val.target.value);
  };
  const focusNotes = (e: any) => {
    var val = e.target.value;
    e.target.value = "";
    e.target.value = val;
  };
  const removeNotesForItem = (item: any) => {
    const clientDataTemp = JSON.parse(JSON.stringify(classSessionlist));
    const findIndx = clientDataTemp.sessionData.findIndex(
      (val: any) =>
        val.ClassSessionId === item?.ClassSessionId &&
        val?.UserId === item?.UserId
    );
    if (findIndx !== -1) {
      clientDataTemp.sessionData[findIndx].notes = false;
    }

    setClassSessionlist(clientDataTemp);
    setClientNotes("");
  };
  const saveNotesForItem = async (item: any) => {
    const service = new ClassService();
    const ChangeScheduleStatusList = {
      ScheduleId: item.ScheduleId,
      ClientId: item?.UserId,
      notes: clientNotes?.trim(),
    };
    const req = {
      ClassSessionId: item?.ClassSessionId,
      ChangeScheduleStatusList: [ChangeScheduleStatusList],
    };
    const res = await service.updateClassSessionStatus(req);
    if (res?.ResponseCode === 100) {
      props?.handleNotificationMessage(`Notes updated successfully`, "success");
      removeNotesForItem(item);
      getAppointments(0, selected);
    } else {
      const errorMsg =
        res?.ErrorMessages.length > 0
          ? res?.ErrorMessages?.[0]
          : "Error in Updating Notes";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleShowNotes = (item: any) => {
    const clientDataTemp = JSON.parse(JSON.stringify(classSessionlist));
    const findIndx = clientDataTemp.sessionData.findIndex(
      (val: any) =>
        val.ClassSessionId === item?.ClassSessionId &&
        val?.UserId === item?.UserId
    );
    if (findIndx !== -1) {
      clientDataTemp.sessionData[findIndx].notes = true;
      setClientNotes(clientDataTemp.sessionData[findIndx].Notes);
    }

    setClassSessionlist(clientDataTemp);
  };
  const MyItemRender = (props: any, indx: number) => {
    let item = props.dataItem;
    const scheduleName = allItems().filter(
      (val) => val.id === item.ScheduleStatus
    );
    const scheduleNameText=scheduleName?.[0]?.text?.replace(/\s/g, "")
    let showLocation = true;
    if (indx === 1) {
      showLocation = false;
    }
    const Attributes =item?.Attributes && typeof item?.Attributes === "string"
      ? JSON.parse(item?.Attributes)
      : item?.Attributes;

    
    return (
      <div className="">
        <div className="card bzc-sessions-list-card mb-3 test">
          <div className="card-body">
            <div className="bzc-sessions-list-row">
              <div className="bzc-col-1">
                <div>
                  <div className="bzc-date-sec d-flex align-items-center">
                    <i className="far fa-calendar-alt"></i>
                    {getWeekdayFormat(item?.ClassSessionStartDate)},&nbsp;
                    {getDayFormat(item?.ClassSessionStartDate)}&nbsp;
                    {getMonthFormat(item?.ClassSessionStartDate)}&nbsp;
                    {getYearFormat(item?.ClassSessionStartDate)}
                  </div>
                  <div className="bzc-time-sec">
                    <span className={""}>
                      <i className="far fa-clock"></i>
                      {getTimeFormat(item?.ClassSessionStartDate)} -{" "}
                      {getTimeFormat(item?.ClassSessionEndTime)}
                    </span>
                  </div>
                  {item?.CheckinTimestamp && (
                    <div className="d-flex align-items-center justify-content-start bz-member-info bzc-time-sec text-capitalize checked-in-success">
                      <span className="pr-1">
                        <MdAccessTimeFilled />
                      </span>
                      <BuzopsTooltip
                        html={
                          <span title="Checked-in">
                            {moment(item?.CheckinTimestamp).format("hh:mm A")}
                          </span>
                        }
                      />
                      <span className="checkedin-success">
                        <TbChecks />
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="bzc-col-1 member-name">
                <div>
                  <div className="bzc-session-price">
                    <small>Member Name</small>
                    <h5 className="mb-0">
                      {" "}
                      <ClientNameWithMemberType
                        memberType={item?.MemberType}
                        clientName={`${item?.FirstName} ${item?.LastName}`}
                      />
                    </h5>
                  </div>
                </div>
              </div>
              <div className="bzc-col-1 group-name">
                <div>
                  <div className="bzc-session-price">
                    <small>Group Name</small>
                    <h5 className={"mb-0 d-flex align-items-center"}>
                      <BuzopsLongNameHandler name={item?.ClassName} maxLength={18} className="taxName-tooltip"/>
                      {/* <span>{item?.ClassName}</span> */}
                      {item?.Attributes !== "" && showLocation && (
                        <span className="bz-location-content">
                          <Location
                            Attributes={item?.Attributes}
                            showDetails={true}
                            VirtualMeetingLink={item?.VirtualMeetingLink}
                            clientPhnNum={item?.Phone}
                          />
                        </span>
                      )}
                    </h5>
                    <small>
                      {`Enrolled on `}
                      <span className="enrol-cancel-span">
                        {moment(item?.EnrolledOn).format("L")}
                      </span>
                    </small>
                    {item?.CancelledOn && (
                      <small>
                        {`Cancelled on `}
                        <span className="enrol-cancel-span">
                          {moment(item?.CancelledOn).format("L")}
                        </span>
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className="bzc-col-1">
                <div>
                  <div className="bzc-provider-name">
                    <small>Provider</small>
                    <h5 className="mb-0">{item?.TrainerName}</h5>
                    {item?.IsWaitList ? <span className="badge bz-badge-warning ml-0 mt-1">{`Waitlisted`}</span>:null}
                  </div>
                </div>
              </div>
              
              <div className={"bzc-close-icon bz-past-statuses"}>
                <div className="bz-groupService-statuses bz-groups-actions">
                  {item?.Notes?.length > 0 && (
                    <BuzopsTooltip
                      html={
                        <span
                          title="Notes"
                          className={"bz-statuses Notes"}
                          onClick={() => handleShowNotes(item)}
                        >
                          <i
                            className="fa fa-sticky-note"
                            aria-hidden="true"
                          ></i>
                        </span>
                      }
                    />
                  )}
                  <PaymentModeIconDisplay
                    dataItem={item}
                    Attributes={Attributes}
                  />
                  {scheduleName[0]?.id > 0 && (
                    <span className={"bz-statuses " + scheduleNameText}>
                      {scheduleName.length > 0 && scheduleName[0]?.text}
                    </span>
                  )}
                </div>
                {!props?.hideTitle && (
                  <>
                  {item?.ScheduleId===selectedWaitListClient?.ScheduleId && selectedWaitListClient?.loading  ? <Loader size="small" type={"pulsing"} />:                    <DropDownButton
                      className={
                        "my-bookings_upcoming-appointments_body_tag_menu bz-elipsis-icon"
                      }
                      buttonClass={
                        "my-bookings_upcoming-appointments_body_tag_menu"
                      }
                      items={renderItems(item)?.filter(
                        (i: any) => i?.visible === true
                      )}
                      iconClass="fas fa-ellipsis-v"
                      look="flat"
                      onItemClick={(e) => {
                        itemClick(e, item);
                      }}
                      popupSettings={{
                        anchorAlign:{
                          horizontal:"right",
                          vertical:"bottom"
                        },
                        popupAlign:{
                          horizontal:"right",
                          vertical:"top"
                        }
                      }}
                    />}
                  </>
                )}
              </div>
            </div>
            {item.notes && (
              <>
                <div className={"row py-3"}>
                  <div className={"col-12"}>
                    <textarea
                      value={clientNotes}
                      className={"k-state-focused form-control"}
                      onChange={changeNotes}
                      onFocus={focusNotes}
                      ref={textAreaRef}
                      style={{ width: "100%" }}
                      autoFocus
                    />
                  </div>
                </div>
                <div className="k-form-buttons justify-content-end pb-2 mt-0">
                  <Button
                    onClick={() => saveNotesForItem(item)}
                    disabled={!clientNotes || clientNotes?.trim()?.length === 0}
                    primary={true}
                  >
                    Save
                  </Button>
                  <Button onClick={() => removeNotesForItem(item)}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderAppointments = (indx: number) => {
    const scrollPadding =
      classSessionlist.sessionData?.length > 2
        ? "scroll-padding"
        : "no-scroll-padding";
    return (
      <>
        <div className={"mb-2 purchase-btn-appts"}>
          <Button
            onClick={() => handleGotoPurchasePage("groupevent")}
            className=""
            primary
          >
            {"Purchase"}
          </Button>
        </div>
        <>
          {classSessionlist.sessionData?.length === 0 ? (
            <>
              <div className="bz-norecords-available">{noRecordsMsg(indx)}</div>
            </>
          ) : (
            <>
              <ListView
                className={`bz-group-services-list bz-past-GroupServices statuses-alignment ${scrollPadding}`}
                data={classSessionlist.sessionData || []}
                item={(props: any) => MyItemRender(props, indx)}
                style={{
                  width: "100%",
                }}
              />
              <div className="d-flex justify-content-center align-items-center bz-services-showMore">
                <div>
                  {pageLoading ? (
                    <>
                      <Loader type="converging-spinner" />
                    </>
                  ) : (
                    <>
                      {classSessionlist.pageNumber + 1 <
                        classSessionlist.maxPageNumber && (
                        <span
                          className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                          onClick={() => handlePageChange()}
                        >
                          View More <i className="fas fa-caret-down"></i>
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      </>
    );
  };

  const toggleDialog = () => {
    setvisible(!visible);
  };
  const handleCancelSession = async () => {
    if (cancelledItem) {
      const req = [
        {
          ClassSessionId: cancelledItem?.ClassSessionId,
          UserId: cancelledItem?.UserId,
          ScheduleId: cancelledItem?.ScheduleId,
          // return obj;
        },
      ];
      const service = new ClassService();
      const reqObj: any = {
        ClubDateTime: new Date(),
        CurrentUserId: cancelledItem?.UserId,
        classSessionUserMappings: req,
      };
      const result = await service.cancelClassSession(reqObj);
      if (result?.ResponseCode === 100) {
        if (result?.Items?.length > 0) {
          if (result?.Items[0]?.ErrorReason) {
            setvisible(false);
            props?.handleNotificationMessage(
              result?.Items[0]?.ErrorReason,
              "error"
            );
            return true;
          }
        }
        getAppointments(0, selected);
        setvisible(false);
        props?.handleNotificationMessage(
          `appointment successfully cancelled`,
          "success"
        );
      } else {
        setvisible(false);
        props?.handleNotificationMessage(
          `Issue with selected appointment.`,
          "error"
        );
      }
    } else {
      setvisible(false);
      props?.handleNotificationMessage(
        `Issue with selected appointment.`,
        "error"
      );
    }
  };
  const handleRemove = (val: boolean) => {
    if (val) {
      handleCancelSession();
    }
    toggleDialog();
  };


  const handlePopUpWindow = (handleEvent: any) => {
    setSelected(0);
    setVisibility(handleEvent);
    getAppointments(0);
  };

  if (visibility?.cancel) {
    return (
      <Cancellation
        classSession={session}
        fromDashboard={true}
        isInhouse={props?.staffPage}
        cancelClients={cancelClients}
        handleWindow={() => handlePopUpWindow({ ...visibility, cancel: false })}
      />
    );
  } else if (visibility?.paynow) {
    console.log(payNowItem,'payNowItem')
    return (
      <PayNowSession
        isInhouse={false}
        fromDashboard={true}
        isClient={!props?.staffPage}
        payNowItem={payNowItem}
        classSession={session}
        handleWindow={() => handlePopUpWindow({ ...visibility, paynow: false })}
      />
    );
  }else if (visibility?.changePayment) {
    return (
      <ChangePaymentSession
        isInhouse={false}
        fromDashboard={true}
        isClient={!props?.staffPage}
        payNowItem={payNowItem}
        classSession={session}
        handleWindow={() => handlePopUpWindow({ ...visibility, changePayment: false })}
      />
    );
  }

  return (
    <>
      
      {loading ? (
        <BuzopsLoader type={"list"} />
      ) : (
        <>
          <TabStrip selected={selected} onSelect={handleSelect}>
            {tabs.map((val, indx) => {
              return (
                <TabStripTab
                  title={val.title}
                  key={indx}
                  contentClassName={"bz-group-services-list"}
                >
                  <>
                    {selected === indx && loading ? (
                      <BuzopsLoader type={"list"} />
                    ) : (
                      renderAppointments(indx)
                    )}
                  </>
                </TabStripTab>
              );
            })}
          </TabStrip>
        </>
      )}
      {visible && (
        <Dialog onClose={() => toggleDialog()}>
          <p className={"dialogContainer"}>
            Are you sure you want to cancel appointment ?
          </p>
          <DialogActionsBar>
            <Button onClick={() => handleRemove(false)}>{"No,keep it."}</Button>
            <Button primary={true} onClick={() => handleRemove(true)}>
              Yes
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default withNotification(GroupServices);
