import React from "react";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { SalesCustomColumnMenu } from "./salesCustomColumnMenu";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import ReportsCommonFilters from "../reportsCommonFilters";
import { Popup } from "@progress/kendo-react-popup";

const FullScreenView = ({ viewData }: any) => {
  return <div className="subscribers-fullview">{viewData}</div>;
};

const GridTableFullScreen = ({
  excelExport,
  handleSearch,
  toggleFullscreen,
  result,
  dataState,
  dataStateChange,
  stateGridColumns,
  onColumnsSubmit,
  setStateGridColumns,
}: any) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const onReset = (event: any) => {
    event.preventDefault();
    const newColumns = stateGridColumns.map((col: any) => {
      return { ...col, show: true };
    });
    setStateGridColumns(newColumns);
    onColumnsSubmit(newColumns);

    setShow(!show);
  };

  const onSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    onColumnsSubmit(stateGridColumns);

    setShow(!show);
  };
  const onToggleColumn = (id: any) => {
    const newColumns = stateGridColumns.map((column: any, idx: any) => {
      return idx === id ? { ...column, show: !column.show } : column;
    });
    setStateGridColumns(newColumns);
  };
  const oneVisibleColumn =
    stateGridColumns.filter((c: any) => c.show).length === 1;
  return (
    <div className="mt-4 sales-signup-grid-border">
      <div className="d-flex pt-3 pb-4 sales-signup-search-header">
        <div className="d-flex mr-3 sales-signup-search">
          <i className="ml-2 fas fa-search"></i>
          <input
            type={"search"}
            className="form-control"
            placeholder="Search"
            onChange={(event) => handleSearch(event.target.value)}
          />
        </div>
        <button
          title="Settings"
          className="mr-3 sales-signup-settings"
          onClick={() => setShow(!show)}
          ref={anchor}
        >
          <li className="k-icon k-i-gears"></li>
        </button>
        <Popup anchor={anchor.current} show={show} popupClass={"popup-content"}>
          <div>Columns</div>

          <form onSubmit={onSubmit} onReset={onReset}>
            <div className={"k-column-list"}>
              {stateGridColumns.map((column: any, idx: any) => (
                <div key={idx} className={"k-column-list-item"}>
                  <span>
                    <input
                      id={`column-visiblity-show-${idx}`}
                      className="k-checkbox k-checkbox-md k-rounded-md"
                      type="checkbox"
                      readOnly={true}
                      disabled={column.show && oneVisibleColumn}
                      checked={column.show}
                      onClick={() => {
                        onToggleColumn(idx);
                      }}
                    />
                    <label
                      htmlFor={`column-visiblity-show-${idx}`}
                      className="k-checkbox-label"
                      style={{
                        userSelect: "none",
                      }}
                    >
                      {column.title}
                    </label>
                  </span>
                </div>
              ))}
            </div>
            <div className={"k-actions k-hstack k-justify-content-stretch"}>
              <button
                type={"reset"}
                className={
                  "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                }
              >
                Reset
              </button>
              <button
                className={
                  "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                }
              >
                Save
              </button>
            </div>
          </form>
        </Popup>
        <div className="sales-signup-grid-button-header">
          <button
            title="Download"
            className="mr-3 sales-signup-download"
            onClick={excelExport}
          >
            <li className="k-icon k-i-download"></li>
          </button>
          <button
            title="Fullscreen"
            className="sales-signup-fullscreen"
            onClick={toggleFullscreen}
          >
            <li className="k-icon k-i-full-screen"></li>
          </button>
        </div>
      </div>

      <Grid
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable={true}
        pageable={true}
        pageSize={5}
        resizable={true}
      >
        {stateGridColumns.map(
          (column: any, idx: any) =>
            column.show && (
              <Column
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                width={column.width}
                columnMenu={(props) => (
                  <SalesCustomColumnMenu
                    {...props}
                    columns={stateGridColumns}
                    onColumnsSubmit={onColumnsSubmit}
                  />
                )}
              />
            )
        )}
      </Grid>
    </div>
  );
};
const SalesByAllSignups = (props: any) => {
  const _exporter = React.createRef<any>();

  const excelExport = () => {
    save(_exporter);
  };
  const save = (component: any) => {
    const options = component.current.workbookOptions();
    const rows = options.sheets[0].rows;
    let altIdx = 0;
    rows.forEach((row: any) => {
      if (row.type === "data") {
        if (altIdx % 2 !== 0) {
          row.cells.forEach((cell: any) => {
            cell.background = "#aabbcc";
          });
        }

        altIdx++;
      }
    });
    component.current.save(options);
  };

  const products = [
    {
      id: 1,
      name: "Ravi",
      lastName: "Babu",
      email: "ravi@gmail.com",
      phone: 9876578908,
      status: "Active",
      serviceName: "Plan A",
      startDate: "11/05/2022",
      signedUpDate: "11/04/2022",
      signedUpBy: "Alina",
      amount: "$20.00",
      nextEftDate: "05/07/2022",
      nextEftAmount: "$05.00",
      contractValue: "$100.00",
      lastAudit: "10/05/2022",
    },
    {
      id: 2,
      name: "Kiran",
      lastName: "Reddy",
      email: "kiran@gmail.com",
      phone: 8907656789,
      status: "In-Active",
      serviceName: "Plan B",
      startDate: "10/05/2022",
      signedUpDate: "10/04/2022",
      signedUpBy: "Varun",
      amount: "$10.00",
      nextEftDate: "06/07/2022",
      nextEftAmount: "$06.00",
      contractValue: "$110.00",
      lastAudit: "11-05-2022",
    },
    {
      id: 3,
      name: "Sai",
      lastName: "Ram",
      email: "sai@gmail.com",
      phone: 9876578332,
      status: "Frozen",
      serviceName: "Plan C",
      startDate: "9/05/2022",
      signedUpDate: "9/04/2022",
      signedUpBy: "Raghu",
      amount: "$30.00",
      nextEftDate: "07/07/2022",
      nextEftAmount: "$07.00",
      contractValue: "$120.00",
      lastAudit: "12/05/2022",
    },
    {
      id: 4,
      name: "Manu",
      lastName: "Babu",
      email: "manu@gmail.com",
      phone: 7686578908,
      status: "Expired",
      serviceName: "Plan D",
      startDate: "8/05/2022",
      signedUpDate: "8/04/2022",
      signedUpBy: "Raj",
      amount: "$40.00",
      nextEftDate: "08/07/2022",
      nextEftAmount: "$08.00",
      contractValue: "$140.00",
      lastAudit: "14/05/2022",
    },
    {
      id: 5,
      name: "Ashok",
      lastName: "Reddy",
      email: "ashok@gmail.com",
      phone: 9886578908,
      status: "Cancelled",
      serviceName: "Plan E",
      startDate: "7/05/2022",
      signedUpDate: "7/04/2022",
      signedUpBy: "Nikil",
      amount: "$60.00",
      nextEftDate: "05/07/2022",
      nextEftAmount: "$05.00",
      contractValue: "$100.00",
      lastAudit: "10/05/2022",
    },
  ];

  const createDataState = (dataState: any) => {
    return {
      result: process(products.slice(0), dataState),
      dataState: dataState,
    };
  };

  const gridColumns = [
    {
      title: "Name",
      field: "name",
      show: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Email",
      field: "email",
      show: true,
      filter: "text",
      width: "250px",
    },
    {
      title: "Phone",
      field: "phone",
      show: true,
      filter: "numeric",
      width: "150px",
    },

    {
      title: "Service Name",
      field: "serviceName",
      show: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Start Date",
      field: "startDate",
      show: true,
      filter: "numeric",
      width: "150px",
    },
    {
      title: "Signed Up Date",
      field: "signedUpDate",
      show: true,
      filter: "numeric",
      width: "170px",
    },
    {
      title: "Signed Up By",
      field: "signedUpBy",
      show: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Amount",
      field: "amount",
      show: true,
      filter: "numeric",
      width: "150px",
    },

    {
      title: "Next EFT Amount",
      field: "nextEftAmount",
      show: false,
      filter: "numeric",
      width: "200px",
    },
    {
      title: "Next EFT Date",
      field: "nextEftDate",
      show: false,
      filter: "text",
      width: "200px",
    },
    {
      title: "Contract Value",
      field: "contractValue",
      show: false,
      filter: "text",
      width: "200px",
    },
    {
      title: "Last Audit",
      field: "lastAudit",
      show: false,
      filter: "text",
      width: "150px",
    },
  ];
  let initialState = createDataState({
    take: 5,
    skip: 0,
  });
  const [result, setResult] = React.useState(initialState.result);
  const [dataType, setDataType] = React.useState("All");

  const getStatusCount = (data: any, status: any) => {
    let statusCount = data?.filter((el: any) => el.status === status).length;
    return statusCount;
  };
  const [subscriberFullscreen, setSubscriberFullscreen] = React.useState(false);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateGridColumns, setStateGridColumns] = React.useState(gridColumns);
  const dataStateChange = (event: any) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };
  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };

  const handleSearch = (value: any) => {
    if (value) {
      let data = initialState?.result?.data?.filter((el) =>
        (
          el.name +
          el.lastName +
          el.email +
          el.phone +
          el.status +
          el.serviceName +
          el.startDate +
          el.signedUpDate +
          el.signedUpBy +
          el.amount
        )
          .toLowerCase()
          .includes(value.toLowerCase())
      );
      let total = initialState?.result?.total;
      setResult({ data, total });
    } else {
      setResult({
        data: initialState?.result?.data,
        total: initialState?.result?.total,
      });
    }
  };
  const uuid = props?.match?.params?.uuid;

  return (
    <div>
      <ReportsCommonFilters
        {...props}
        history={props.history}
        location={props.location}
      />
      <div className="mt-4 sales-signup-main-container">
        <div className="d-flex justify-content-end sales-signup-close-icon">
          <li
            className="k-icon k-i-close"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
        <div className="d-flex sales-signup-sub-header">
          <h2 className="sales-signup-header">Sales by All Signups</h2>
        </div>

        {subscriberFullscreen && (
          <FullScreenView
            viewData={
              <GridTableFullScreen
                excelExport={excelExport}
                handleSearch={handleSearch}
                toggleFullscreen={() =>
                  setSubscriberFullscreen(!subscriberFullscreen)
                }
                result={result}
                dataState={dataState}
                dataStateChange={dataStateChange}
                stateGridColumns={stateGridColumns}
                setStateGridColumns={(data: any) => setStateGridColumns(data)}
                onColumnsSubmit={onColumnsSubmit}
              />
            }
          />
        )}
        <GridTableFullScreen
          excelExport={excelExport}
          handleSearch={handleSearch}
          toggleFullscreen={() =>
            setSubscriberFullscreen(!subscriberFullscreen)
          }
          result={result}
          dataState={dataState}
          dataStateChange={dataStateChange}
          stateGridColumns={stateGridColumns}
          setStateGridColumns={(data: any) => setStateGridColumns(data)}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
      <ExcelExport
        data={result?.data}
        fileName="salesbyAllSignups.xlsx"
        ref={_exporter}
      >
        {stateGridColumns
          .filter((ele) => ele.show)
          .map((el, id) => (
            <ExcelExportColumn key={id} field={el.field} title={el.title} />
          ))}
      </ExcelExport>
    </div>
  );
};
export default SalesByAllSignups;
