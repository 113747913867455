import React, { useState } from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartAxisDefaults,
} from "@progress/kendo-react-charts";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "hammerjs";
import MultiSelectDropDownForChart from "../multiSelectDropDownForChart";

const TransactionChart = ({ chartOriginalData, chartLoading, title, removeColumns, dropDownValues }: any) => {
  const [chartView, setChartView] = useState<any>("Bar chart");
  const [dataType, setDataType] = React.useState("Bar");
  const [value, setValue] = React.useState<string[]>(dropDownValues || []);
  const handleLineChartClick = () => {
    setChartView("Line Chart");
    setDataType("Line");
  };
  const handleBarChartClick = () => {
    setChartView("Bar chart");
    setDataType("Bar");
  };
  const chartCategories = chartOriginalData?.Items?.map(
    (item: any) => item.Year
  );
  const seriesData = (val: any) => {
    return chartOriginalData?.Items?.map(
      (item: any) => item[val]
    );
  }
  // DeclinedPayments
  // Refund
  let max = 0;

  const chartSeriesName = chartOriginalData?.Items?.map(
    (el: any) => el.OverallMemberships
  );
  const handle = useFullScreenHandle();
  const handleChange = (value: any) => {
    if(removeColumns){
      const dropDownData = JSON.parse(JSON.stringify(dropDownValues));
      let intersection = dropDownData.filter((x: any) => !value.includes(x) && x !== "Select All"); 
      removeColumns(intersection);
    }
    setValue(value);
  }
  const showChartAxes = (dropDownValue: string) => {
    if(value.length === 0){
      return true;
    }
    if(value.includes(dropDownValue)){
      return true;
    }
    return false;
  }
  return (
    <>
    
      <div className="d-flex mb-1 sales-sub-header">
        <div className="me-auto sales-header">{title}</div>
        {
          dropDownValues.length > 0 &&
          <div className="" style={{width: '20%'}}>
            <MultiSelectDropDownForChart ddData={dropDownValues} value={value} onChange={handleChange} />
          </div>
        }
        <div
          className={
            dataType === "Line"
              ? "p-2 sales-line-header clicked"
              : "p-2 sales-line-header"
          }
        >
          <span className="sales-line-button" onClick={handleLineChartClick}>
            <i className="k-icon k-i-chart-line mr-1"></i>
            Line
          </span>
        </div>
        <div
          className={
            dataType === "Bar"
              ? "p-2 sales-bar-header clicked"
              : "p-2 sales-bar-header"
          }
        >
          <span className="sales-bar-button" onClick={handleBarChartClick}>
            Bar
          </span>
        </div>
        <div
          className={
            "p-2 sales-bar-header"
          }
        >
          <span className="sales-bar-button" onClick={handle.enter}>
            Full Screen
          </span>
        </div>
      </div>
      <div className="sales-graphs-header">
        {chartLoading ? (
          <>
            <BuzopsLoader type="list" />
          </>
        ) : (
          <>
            <FullScreen handle={handle}>
            <div className="mt-6 mb-5">
                <div className="k-card">
                  <Chart className={chartView === "Line Chart" ? "slaes-line-graphs" : "slaes-bar-graphs"}  /*pannable={true} zoomable={true} */ >
                    <ChartLegend />
                    <ChartAxisDefaults
                      labels={{
                        format: "c0",
                      }}
                      majorGridLines={{ visible: false }}
                    />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={chartCategories}
                        color={"black"}
                      />
                    </ChartCategoryAxis>
                    <ChartSeries>
                      {
                        dropDownValues.map((val: any) => {
                          return showChartAxes(val) &&
                          <ChartSeriesItem
                            type={chartView === "Line Chart" ? "line" : "column"}
                            labels={{
                              visible: false,
                              format: "c",
                            }}
                            tooltip={{
                              visible: true,
                              format:
                                `${val}`+
                                ":" +
                                " " +
                                " ${0}",
                            }}
                            data={seriesData(val)}
                          />
                        })
                        
                      }

                    </ChartSeries>
                  </Chart>
                </div>
              </div>
            </FullScreen>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(TransactionChart);
