import React, { useState } from "react";
import {
  Form,
  FormElement
} from "@progress/kendo-react-form";
import creditCardType from "credit-card-type";
import BuzopsButton from "generic-components/BuzopsButton";
import payment from "payment";
import { cardTypes } from "utils/form-utils";
import {
  formatCardNumber,
  formatExpiry,
  hasCardNumberReachedMaxLength,
  isHighlighted
} from "../../utils/formatter";
import isExpiryInvalid from "../../utils/is-expiry-invalid";
import isZipValid from "../../utils/is-zip-valid";
import PaymentCardInput from "./PaymentCardInput";

const ShowCardForm = (props: any) => {
  const [cardFormValues, setCardFormValues] = useState<any>(
    props.cardFormValues
  );
  const [error, setError] = useState("");
  const [isValid, setisValid] = useState(false);
  const CARD_TYPES: any = {
    mastercard: "MASTERCARD",
    visa: "VISA",
    amex: "AMERICAN_EXPRESS",
    jcb:"JCB",
    dinersclub:"DINERS",
    discover:"DISCOVER"
  };



  const handleCardNumberChange = (e: any) => {
    let cardNumber = e.target.value;
    cardNumber = formatCardNumber(cardNumber);
    let cardId=undefined;
    if (cardNumber !== "") {
      const cardNumberLength = cardNumber.split(" ").join("").length;
      const cardTypeName = payment.fns.cardType(cardNumber);
      if(cardTypeName !=='' || cardTypeName !== undefined){
         const res=cardTypes.filter((item:any)=>item.text===cardTypeName)
         cardId=res?.[0]?.id
      }
      const cardTypeInfo =
        creditCardType.getTypeInfo(
          creditCardType.types[CARD_TYPES[cardTypeName]]
        ) || {};
      const cardTypeLengths = cardTypeInfo.lengths || [16];
     

      if (cardTypeLengths) {
          if (
            cardTypeLengths?.includes(cardNumberLength) &&
            payment.fns.validateCardNumber(cardNumber)
          ) {
            setisValid(true);
          } else {
            setisValid(false);
            setError("Card Number is Invalid");
          }
        }
      // }
    } else {
      setisValid(false);
      setError("Card Number is required");
    }
    setCardFormValues({ ...cardFormValues, MaskedCCNumber: cardNumber,CardTypeId:cardId });
  };

  const handleCardNumberKeyPress = (e: any) => {
    const value = e.target.value;
    checkIsNumeric(e);
    if (value && !isHighlighted()) {
      const valueLength = value.split(" ").join("").length;
      if (hasCardNumberReachedMaxLength(value, valueLength)) {
        e.preventDefault();
      }
    }
  };

    const handlezipCodeBlur = (value: any) => {
    if (value !== "") {
      const zipLength = value.length;
        // If the zip is numeric, it must be exactly 5 digits
        if (/^\d+$/.test(value) && zipLength !== 5) {
          setError("Zip code must be exactly 5 digits or 6 alphanumeric characters");
          setisValid(false);
          return false;
        }
        // If alphanumeric, allow up to 6 characters
        else if (!/^\d+$/.test(value) && zipLength > 6) {
          setError("Zip code must be up to 6 alphanumeric characters or 5 digits");
          setisValid(false);
          return false;
        } 
        // Validate using the isZipValid function
        else if (isZipValid(value)) {
          setisValid(true);
          setError("");
          return true;
        } else {
          setError("Zip code is invalid");
          setisValid(false);
          return false;
        }

    } else {
      setisValid(false);
      setError("Zip code is required");
      return false;
    }
  };

  const handlezipCodeChange = (e: any) => {
    const zip = e.target.value;
    if (zip !== "") {
        // If the input is all digits and longer than 5 characters, it's invalid
        if (/^\d+$/.test(zip) && zip.length > 5) {
          setError("Zip code must be exactly 5 digits or 6 alphanumeric characters");
          setisValid(false);
        } 
        // If the input is alphanumeric and longer than 6 characters, it's invalid
        else if (!/^\d+$/.test(zip) && zip.length > 6) {
          setError("Zip code must be up to 6 alphanumeric characters or 5 digits");
          setisValid(false);
        } 
        // Validate the input using the isZipValid function
        else if (zip !== "" && isZipValid(zip)) {
          setError("");
          setisValid(true);
        } else if (zip !== "") {
          setError("Zip code is invalid");
          setisValid(false);
        } else {
          setError("Zip code is required");
          setisValid(false);
        }
    } else {
      setError("Zip code is required");
      setisValid(false);
    }
    setCardFormValues({ ...cardFormValues, ZipCode: zip });
  };

  const handlezipCodeKeyPress = (e: any) => {
    checkIsNumeric(e);
  };

  const handleCardExpiryBlur = (value: any) => {
    const cardExpiry = value?.split(" / ").join("/");
    if (cardExpiry) {
      const message = isExpiryInvalid(cardExpiry);
      if (message === false) {
        setisValid(true);
        return true;
      } else {
        setError(message);
        setisValid(false);
        return false;
      }
    } else {
      setError("Card Expiry Date is Required");
      setisValid(false);
      return false;
    }
  };

  const handleCardExpiryBlurNew = (value: any) => {
    const cardExpiry = value?.split(" / ").join("/");
    if (cardExpiry) {
      const message = isExpiryInvalid(cardExpiry);
      if (message === false) {
        setisValid(true);
        const expiryValue = cardExpiry.split("/");
        setCardFormValues({
          ...cardFormValues,
          ExpiryMonthYear: cardExpiry,
          ExpiryMonth: expiryValue[0],
          ExpiryYear: expiryValue[1],
        });
        return true;
      } else {
        setError(message);
        setisValid(false);
        return false;
      }
    } else {
      setError("Card Expiry Date is Required");
      setisValid(false);
      return false;
    }
  };
  const handleCardExpiryChange = (e: any) => {
    let cardExpiryField = formatExpiry(e);
    if (cardExpiryField) {
      cardExpiryField = cardExpiryField.split(" / ").join("/");
      const message = isExpiryInvalid(cardExpiryField);
      if (message === false) {
        setisValid(true);
      } else {
        setError(message);
        setisValid(false);
      }
    } else {
      setError("Card Expiry Date is Required");
      setisValid(false);
    }
    if(cardExpiryField){
      const expiryValue=cardExpiryField.split('/');
      setCardFormValues({ ...cardFormValues, ExpiryMonthYear: cardExpiryField,ExpiryMonth:expiryValue[0],ExpiryYear:expiryValue[1] });
    }else{
      setCardFormValues({
        ...cardFormValues,
        ExpiryMonthYear:"",
        ExpiryMonth:"",
        ExpiryYear:"",
      });
    }
  };
  const isMonthDashKey = ({ key, target: { value } }: any = {}) => {
    return !value.match(/[/-]/) && /^[/-]$/.test(key);
  };

  const handleCardExpiryKeyPress = (e: any) => {
    const value = e.target.value;

    if (value && !isMonthDashKey(e)) {
      checkIsNumeric(e);
    }

    if (value && !isHighlighted()) {
      const valueLength = value.split(" / ").join("").length;
      if (valueLength >= 5) {
        e.preventDefault();
      }
    }
  };

  const checkIsNumeric = (e: any) => {
    if (!/^\d*$/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleCardNumberBlur = (value: any) => {
    if (value) {
      if (!payment.fns.validateCardNumber(value)) {
        setisValid(false);
        setError("Card Number is Invalid");
        return false;
      } else {
        setisValid(true);
        return true;
      }
    } else {
      setisValid(false);
      setError("Card Number is required");
      return false;
    }
  };

  const handleFormValidation = () => {
    const { MaskedCCNumber, ExpiryMonthYear, ZipCode } = cardFormValues;

    if (!props?.type) {
      if (
        handleCardNumberBlur(MaskedCCNumber) &&
        handleCardExpiryBlur(ExpiryMonthYear) &&
        handlezipCodeBlur(ZipCode)
      ) {
        props?.handleCardSubmit(cardFormValues, "edit");
      }
    } else {
      if (handleCardExpiryBlur(ExpiryMonthYear)) {
        props?.handleCardSubmit(cardFormValues, "add");
      }
    }

  };

  return (
    <>
     <div className="pl-24">
     <Form
        render={(cardRendorForm: any) => (
          <>
            <FormElement>
                <div className="payment-card-input-new">
                  <PaymentCardInput
                    cardFormValues={cardFormValues}
                    cardImageIcon={props.cardImageIcon}
                    handleCardNumberKeyPress={handleCardNumberKeyPress}
                    handleCardNumberBlur={(val: any) =>
                      handleCardNumberBlur(val)
                    }
                    handleCardNumberChange={(e: any) =>
                      handleCardNumberChange(e)
                    }
                    handleCardExpiryKeyPress={handleCardExpiryKeyPress}
                    handleCardExpiryBlur={(val: any) =>
                      handleCardExpiryBlurNew(val)
                    }
                    handleCardExpiryChange={(e: any) =>
                      handleCardExpiryChange(e)
                    }
                    handlezipCodeKeyPress={handlezipCodeKeyPress}
                    handlezipCodeBlur={(val: any) => handlezipCodeBlur(val)}
                    handlezipCodeChange={(e: any) => handlezipCodeChange(e)}
                    error={error}
                    type={props?.type}
                    isValid={isValid}
                  />
                </div>
                <div className="d-flex pt-3 pb-2">
                  <BuzopsButton
                    primary
                    label={"Continue"}
                    className={"ml-0"}
                    disable={!isValid}
                    loading={props.cardSubmitBtnLoading}
                    onClick={() => {
                      handleFormValidation();
                    }}
                  />
                </div>
            </FormElement>
          </>
        )}
      />
     </div>
    </>
  );
};

export default ShowCardForm;
