import React, { useState } from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartAxisDefaults,
} from "@progress/kendo-react-charts";
import BuzopsLoader from "generic-components/BuzopsLoader";
import "hammerjs";
import MultiSelectDropDownForChart from "../../Generic/multiSelectDropDownForChart";

const StatisticChart = ({
  chartOriginalData,
  chartLoading,
  title,
  removeColumns,
  dropDownValues,
}: any) => {
  console.log(chartOriginalData);
  const [chartView, setChartView] = useState<any>("Bar chart");
  const [dataType, setDataType] = React.useState("Bar");
  const [value, setValue] = React.useState<string[]>(dropDownValues || []);
  const [fullScreen, setFullscreen] = React.useState(false);

  const handleLineChartClick = () => {
    setChartView("Line Chart");
    setDataType("Line");
  };
  const handleBarChartClick = () => {
    setChartView("Bar chart");
    setDataType("Bar");
  };
  const chartCategories = chartOriginalData?.Items?.map(
    (item: any) => item.AuditDate.split(" ")[0]
  );

  const seriesData = (val: any) => {
    return chartOriginalData?.Items?.map((item: any) => item[val]);
  };

  const handleChange = (value: any) => {
    if (removeColumns) {
      const dropDownData = JSON.parse(JSON.stringify(dropDownValues));
      let intersection = dropDownData.filter(
        (x: any) => !value.includes(x) && x !== "Select All"
      );
      removeColumns(intersection);
    }
    setValue(value);
  };
  const showChartAxes = (dropDownValue: string) => {
    if (value.length === 0) {
      return true;
    }
    if (value.includes(dropDownValue)) {
      return true;
    }
    return false;
  };

  const toggleScreen = () => {
    setFullscreen(!fullScreen);
    var style = document.createElement("style");
    style.innerHTML = `body::-webkit-scrollbar {display: ${
      fullScreen ? "block" : "none"
    };}`;
    document.head.appendChild(style);
  };
  return (
    <>
      <div className={fullScreen ? "subscribers-fullview" : ""}>
        <div className="d-flex mb-1 sales-sub-header">
          <div className="me-auto sales-header">{title}</div>
          {dropDownValues.length > 0 && (
            <div className="" style={{ width: "20%" }}>
              <MultiSelectDropDownForChart
                ddData={dropDownValues}
                value={value}
                onChange={handleChange}
              />
            </div>
          )}
          <div
            className={
              dataType === "Line"
                ? "p-2 sales-line-header clicked"
                : "p-2 sales-line-header"
            }
          >
            <span
              className="sales-line-button bz-cursor-pointer"
              onClick={handleLineChartClick}
            >
              <i className="k-icon k-i-chart-line mr-1"></i>
              Line
            </span>
          </div>
          <div
            className={
              dataType === "Bar"
                ? "p-2 sales-bar-header clicked"
                : "p-2 sales-bar-header"
            }
          >
            <span
              className="sales-bar-button bz-cursor-pointer"
              onClick={handleBarChartClick}
            >
              Bar
            </span>
          </div>
          <div className={"p-2 sales-bar-header"}>
            <button
              title={fullScreen ? "Exit Fullscreen" : "Fullscreen"}
              className="subscribers-fullscreen"
              onClick={toggleScreen}
            >
              <li className="k-icon k-i-full-screen"></li>
            </button>
          </div>
        </div>
        <div className="sales-graphs-header">
          {chartLoading ? (
            <>
              <BuzopsLoader type="list" />
            </>
          ) : (
            <>
              <div className="mt-6 mb-5">
                <div className="k-card">
                  <Chart
                    className={
                      chartView === "Line Chart"
                        ? "slaes-line-graphs"
                        : "slaes-bar-graphs"
                    } /*pannable={true} zoomable={true} */
                  >
                    <ChartLegend />
                    <ChartAxisDefaults
                      labels={{
                        format: "c0",
                      }}
                      majorGridLines={{ visible: false }}
                    />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={chartCategories}
                        color={"black"}
                      />
                    </ChartCategoryAxis>
                    <ChartSeries>
                      {dropDownValues.map((val: any) => {
                        return (
                          showChartAxes(val) && (
                            <ChartSeriesItem
                              type={
                                chartView === "Line Chart" ? "line" : "column"
                              }
                              labels={{
                                visible: false,
                                format: "c",
                              }}
                              tooltip={{
                                visible: true,
                                format: `${val}` + ":" + " " + " ${0}",
                              }}
                              data={seriesData(val)}
                            />
                          )
                        );
                      })}
                    </ChartSeries>
                  </Chart>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(StatisticChart);
