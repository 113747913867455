export const AccountUpdaterColumns = [
  {
    title: "First Name",
    field: "name",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Last Name",
    field: "name",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Email",
    field: "email",
    show: true,
    filter: "text",
    width: "250px",
  },
  {
    title: "Subscription",
    field: "phone",
    show: true,
    filter: "numeric",
    width: "150px",
  },

  {
    title: "Client Profile Id",
    field: "serviceName",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Client Payment Profile Id",
    field: "startDate",
    show: true,
    filter: "numeric",
    width: "150px",
  },
  {
    title: "Reason Code",
    field: "signedUpDate",
    show: true,
    filter: "numeric",
    width: "170px",
  },
  {
    title: "Notes",
    field: "signedUpBy",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Processing Status",
    field: "amount",
    show: true,
    filter: "text",
    width: "150px",
  },
];
