import {
  GridDetailRowProps,
  GridFooterCellProps,
} from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import moment from "moment";
import { ReportsService } from "services/reports/index.service";
import {
  AddOnColumns,
  MembershipsColumns,
  OneonOneColumns,
  PackagesColumns,
} from "../Utilities/constants";

const useStatisticDetail = (props: GridDetailRowProps, dateRange: any) => {
  const total = (props: GridFooterCellProps) => {
    const field = props.field || "";
    const total = pageInfo.data.reduce(
      (acc: any, current: { [x: string]: any }) => acc + current[field],
      0
    );
    return <td>{total}</td>;
  };

  const getDetailColumn = (Type: number) => {
    switch (Type) {
      case 2:
        return MembershipsColumns(total);
      case 8:
        return OneonOneColumns(total);
      case 16:
        return AddOnColumns(total);
      case 512:
        return PackagesColumns(total);
    }
  };
  const dataItem = props.dataItem;
  const [show, setShow] = useState(false);
  const [pageInfo, setPageInfo] = useState<any>({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });
  const [page1Info, setPage1Info] = useState<any>({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });
  const [excelData, setExcelData] = useState([]);
  const [excel1Data, setExcel1Data] = useState([]);

  console.log(props);

  const [stateGridColumns, setStateGridColumns] = useState(
    getDetailColumn(props?.dataItem?.Type)
  );
  const [stateGrid1Columns, setStateGrid1Columns] = useState(
    getDetailColumn(props?.dataItem?.Type)
  );

  useEffect(() => {
    fetchAuditData();
  }, [dataItem]);

  const service = new ReportsService();
  const fetchAuditData = async (pageData?: any, forExcel = false) => {
    let req = {
      pageSize: forExcel ? pageInfo.total : pageData?.dataState?.take || 100,
      PageNumber: forExcel
        ? 0
        : pageData?.dataState?.skip
        ? pageData?.dataState.skip / pageData?.dataState.take
        : 0,
      ShowAll: false,
      AuditType: dataItem.Type,
      FromDate: pageData?.fromDate || dateRange?.from, //need to change
      ToDate: pageData?.toDate || dateRange?.to,
      SortBy: pageData?.sort?.[0]?.field || "AuditDate",
      SortDirection: pageData?.sort?.[0]?.dir || "desc",
    };
    const getData = await service.getSubscriberStatistics(req);

    if (forExcel) {
      setExcelData(getData.Item.Result);
    } else {
      setPageInfo((prev: any) => {
        prev.data = getData.Item.Result;
        prev.pageNumber = req.PageNumber;
        prev.total = getData.Item.TotalItems;
        if (pageData?.take) {
          prev.skip = pageData.skip;
          prev.take = pageData.take;
        }
        return { ...prev };
      });
    }
  };
  const fetchAuditDetails = async (pageData?: any, forExcel = false) => {
    console.log(pageData);
    let req = {
      PageSize: forExcel ? pageInfo.total : pageData?.dataState?.take || 10,
      PageNumber: forExcel
        ? 0
        : pageData?.dataState?.skip
        ? pageData?.dataState.skip / pageData?.dataState.take
        : 0,
      AuditDate: moment(pageData?.AuditDate).format("YYYY-MM-DD") + "T00:00:00",
      AuditType: "PrivateService",
      GridHirecharyLevel: 1,
      IncludeChrunRateExcluded: false,
      SortBy: "AuditDate",
      SortDirection: "desc",
      StatusDescription: "test",
    };
    const getData = await service.getAuditDetails(req);

    if (forExcel) {
      setExcel1Data(getData.Item.Result);
    } else {
      setPage1Info((prev: any) => {
        prev.data = getData.Item.Result;
        prev.pageNumber = req.PageNumber;
        prev.total = getData.Item.TotalItems;
        if (pageData?.take) {
          prev.skip = pageData.skip;
          prev.take = pageData.take;
        }
        return { ...prev };
      });
      setShow(!show);
    }
  };
  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };
  const onColumns1Submit = (columnsState: any) => {
    setStateGrid1Columns(columnsState);
  };
  const onCellClick = async (cellData: any) => {
    await fetchAuditDetails(cellData);
  };
  const onCell1Click = async (cellData: any) => {};

  return {
    page1Info,
    pageInfo,
    fetchAuditData,
    excel1Data,
    excelData,
    setExcel1Data,
    setExcelData,
    show,
    setShow,
    fetchAuditDetails,
    stateGrid1Columns,
    stateGridColumns,
    setStateGrid1Columns,
    setStateGridColumns,
    onCell1Click,
    onCellClick,
    onColumns1Submit,
    onColumnsSubmit,
  };
};

export default useStatisticDetail;
