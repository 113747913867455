import { useEffect, useState } from "react";

import { reportFilters } from "../../reportsCommonFilters";
import { ReportsService } from "services/reports/index.service";
import {
  SubscriberTypes,
  SubscriberStatus,
  SubscriberStatusTypes,
} from "../Utilities/constants";

const useSubsciberReport = (props: any) => {
  const { reportType } = props;
  const filterText = reportFilters.ThisMonth;
  const pageSize = 10;
  const reportService = new ReportsService();
  const [tableData, setTableData] = useState<any>({
    take: pageSize,
    skip: 0,
    pageNumber: 0,
    data: [],
    total: 0,
  });
  const [count, setCount] = useState({
    total: 0,
    active: 0,
    inActive: 0,
    frozen: 0,
    expired: 0,
    cancelled: 0,
    aboutToExpire: 0,
    suspended: 0,
    future: 0,
    completed: 0,
  });
  const [excelData, setExcelData] = useState([]);
  const [loading, setLoading] = useState<any>({
    count: false,
    dataLoad: false,
  });
  const [dataType, setDataType] = useState<SubscriberStatusTypes>(
    SubscriberStatusTypes.total
  );
  const [stateGridColumns, setStateGridColumns] = useState(
    props.SubsriberReportColumns
  );
  const [isDateRange, setIsDateRange] = useState<any>({});
  const [filterData, setFilterData] = useState<any>([]);

  const fetchSubscriberCount = async () => {
    setLoading((prev: any) => {
      prev.count = true;
      return { ...prev };
    });
    let response = await reportService.getSubscribersCountReport({
      SubscriptionType: reportType,
    });
    console.log(response);
    setCount((prev: any) => {
      Object.keys(prev).map((val) => {
        prev[val] = response[SubscriberTypes[reportType][val]];
      });
      return { ...prev };
    });
    setLoading((prev: any) => {
      prev.count = false;
      return { ...prev };
    });
  };
  const fetchSubscriberData = async (
    state: any,
    planStatus = 0,
    props?: any,
    forExcel: boolean = false
  ) => {
    setLoading((prev: any) => {
      prev.dataLoad = true;
      return { ...prev };
    });

    let response = await reportService.getSubscribersReport({
      SubscriptionType: reportType,
      PageSize: forExcel ? tableData.total : pageSize,
      PageNumber: state ? state.skip / state.take : 0,
      SortDirection: "asc",
      SortBy: "MemberName",
      PlanStatus: planStatus,
      ...props,
    });

    if (forExcel) {
      setExcelData(response?.Result);
    } else {
      setTableData((prev: any) => {
        return {
          ...prev,
          data: response?.Result || [],
          total: response?.TotalItems || 0,
          ...state,
          ...props,
        };
      });
      setExcelData([]);
    }
    setLoading((prev: any) => {
      prev.dataLoad = false;
      return { ...prev };
    });
  };

  const dataStateChange = async (event: any, forExcel = false) => {
    console.log(event);
    var filterArrayData: any = [];
    var eventFilterData = event?.dataState?.filter
      ? event?.dataState?.filter?.filters.map((item: any) =>
          item?.filters.map(
            (fil: { field: any; operator: any; value: any }) => {
              const dataTypeValue =
                typeof fil.value === "string" ? "string" : "number";
              const numberValue = dataTypeValue === "string" ? 0 : fil.value;
              const filterOperators =
                (fil.operator === "eq" && "=") ||
                (fil.operator === "neq" && "<>") ||
                (fil.operator === "gte" && ">=") ||
                (fil.operator === "gt" && ">") ||
                (fil.operator === "lte" && "<=") ||
                (fil.operator === "lt" && "<") ||
                fil.operator;

              var data = {
                Field: fil.field,
                Operator: fil.operator,
                Logic: item.logic,
                FieldDataType: dataTypeValue,
                Condition: item.logic,
                Filter: filterOperators,
                Column: fil.field,
                DataType: dataTypeValue,
                NumberValue: numberValue,
                Value: fil.value,
                DropDownValue: "",
                DateValue: "",
                DisplayName: "",
              };

              props.SubsriberReportColumns.map((el: any) => {
                stateGridColumns.map((item: any) => {
                  if (fil.field === el.field) {
                    data.DisplayName = el.title;
                  } else if (fil.field === item.field) {
                    data.DisplayName = item.title;
                  }
                });
              });
              filterArrayData.push(data);
              return data;
            }
          )
        )
      : filterData;

    for (let i = 0; i < filterArrayData.length - 1; i++) {
      if (
        filterArrayData[i].DisplayName === filterArrayData[i + 1].DisplayName
      ) {
        filterArrayData[i].Condition = "and";
        filterArrayData[i].Logic = "and";
      }
    }
    setFilterData(filterArrayData);
    await fetchSubscriberData(
      event.dataState,
      SubscriberStatus[dataType],
      event.sort?.length > 0
        ? {
            SortDirection: event.sort[0].dir,
            SortBy: event.sort[0].field,
            Filters: filterArrayData,
            skip: 0,
            take: pageSize,
          }
        : { Filters: filterArrayData },
      forExcel
    );
  };

  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };

  const handleChange = async (value: any) => {
    await fetchSubscriberData(
      { skip: 0, take: pageSize },
      SubscriberStatus[value]
    );
    setDataType(value);
  };

  const dateValues = async (props: any) => {
    setIsDateRange(props);
    await fetchSubscriberData(
      { skip: 0, take: pageSize },
      SubscriberStatus[dataType],
      props
    );
  };

  useEffect(() => {
    fetchSubscriberCount();
    fetchSubscriberData({
      skip: 0,
      take: pageSize,
    });
  }, []);

  return {
    filterData,
    filterText,
    dateValues,
    isDateRange,
    dataType,
    handleChange,
    count,
    tableData,
    dataStateChange,
    stateGridColumns,
    pageSize,
    loading,
    excelData,
    setExcelData,
    setStateGridColumns,
    onColumnsSubmit,
  };
};

export default useSubsciberReport;
