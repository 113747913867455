import { createSlice } from "@reduxjs/toolkit";

const initialState= {
  loggedInStaffDetails: null,
  isLoggedIn: false,
  CurrentApplicationType:0,
  ClubTimeZone:null
}
export const staffCheckinsSlice = createSlice({
  name: "staffCheckIn",
  initialState,
  reducers: {
    resetStaffLoggedDetails: () => initialState,
    setStaffLoggedDetails: (state, action) => {
      const {
        loggedInStaffDetails=null,
        CurrentApplicationType = 0,
        isLoggedIn = false,
        ClubTimeZone=null
      } = action.payload;
      state.loggedInStaffDetails = loggedInStaffDetails;
      state.CurrentApplicationType = CurrentApplicationType;
      state.isLoggedIn = isLoggedIn;
      state.ClubTimeZone = ClubTimeZone;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStaffLoggedDetails,resetStaffLoggedDetails } = staffCheckinsSlice.actions;

export default staffCheckinsSlice.reducer;
