import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { CurrencyFormat } from "utils";

const AmountCell = (props: any, name: string) => {
  return <td>{CurrencyFormat(props?.dataItem?.[name])}</td>;
};
const Summary = (props: any) => {
  const chargeback = props?.ChargebackTransactionsSummary?.filter(
    (i: any) => i?.TransactionStatus === 13
  );
  const returns = props?.ChargebackTransactionsSummary?.filter(
    (i: any) => i?.TransactionStatus === 12
  );
  return (
    <>
      {props?.selectServiceType?.id !== 12 && (
        <div className="my-4">
          <h6>{`Charge Backs (CC) Summary`}</h6>
          <div className="my-4 charge-back-table">
            <Grid data={chargeback} className="bz-chargeback-summary-grid">
              <GridColumn field="PaymentMadeForDescription" title="Services" />
              <GridColumn field="MembersCount" title="Clients" />
              <GridColumn field="TransactionsCount" title="Transactions" />
              <GridColumn
                field="TotalAmount"
                title="Amount"
                cell={(props: any) => AmountCell(props, "TotalAmount")}
              />
            </Grid>
          </div>
        </div>
      )}
      {props?.selectServiceType?.id !== 13 && (
        <div className="my-4">
          <h6>{`Returns (ACH) Summary`}</h6>
          <div className="my-4 charge-back-table">
            <Grid data={returns} className="bz-chargeback-summary-grid">
              <GridColumn field="PaymentMadeForDescription" title="Services" />
              <GridColumn field="MembersCount" title="Clients" />
              <GridColumn field="TransactionsCount" title="Transactions" />
              <GridColumn
                field="TotalAmount"
                title="Amount"
                cell={(props: any) => AmountCell(props, "TotalAmount")}
              />
            </Grid>
          </div>
        </div>
      )}
      <div className="my-4 text-right">
        <span className="btn-sm k-button k-primary mb-3 mr-0"> Total: {CurrencyFormat(props?.TotalAmount || 0)}</span>

      </div>
    </>
  );
};

export default Summary;
