import { SubscriptionTypes } from "components/reports/Generic/Utilities/constants";
import SubsciberReport from "../../Generic/Components/SubscriberReport";

const Packages = (props: any) => {
  const SubsriberReportColumns = [
    {
      title: "Name",
      field: "MemberName",
      show: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Email",
      field: "Email",
      show: true,
      filter: "text",
      width: "250px",
    },
    {
      title: "Phone",
      field: "phone",
      show: true,
      filter: "text",
      width: "150px",
    },

    {
      title: "Package Name",
      field: "SubscriptionName",
      show: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Start Date",
      field: "StartDate",
      show: true,
      filter: "date",
      width: "150px",
    },
    {
      title: "Signed Up Date",
      field: "signedUpDate",
      show: true,
      filter: "date",
      width: "170px",
    },
    {
      title: "Signed Up By",
      field: "signedUpBy",
      show: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Amount",
      field: "amount",
      show: true,
      filter: "numeric",
      width: "150px",
    },
    {
      title: "Status",
      field: "FormattedStatus",
      show: true,
      enableHtml: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Last Payment Date",
      field: "LastTransactionDate",
      show: false,
      filter: "date",
      width: "200px",
    },
    {
      title: "Last Payment Amount",
      field: "LastTransactionAmount",
      show: false,
      filter: "numeric",
      width: "250px",
    },
    {
      title: "Next EFT Amount",
      field: "RecurringAmount",
      show: false,
      filter: "numeric",
      width: "200px",
    },
    {
      title: "Next EFT Date",
      field: "NextRecurrence",
      show: false,
      filter: "date",
      width: "200px",
    },
    {
      title: "Amount collected",
      field: "amountCollected",
      show: false,
      filter: "numeric",
      width: "200px",
    },
    {
      title: "No of Renewals",
      field: "RenewalCount",
      show: false,
      filter: "numeric",
      width: "200px",
    },
    {
      title: "Last Audit",
      field: "lastAudit",
      show: false,
      filter: "date",
      width: "150px",
    },
  ];
  return (
    <SubsciberReport
      reportName={"Packages"}
      reportDescription={""}
      reportType={SubscriptionTypes.Packages}
      {...props}
      SubsriberReportColumns={SubsriberReportColumns}
    />
  );
};
export default Packages;
