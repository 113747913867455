import { MultiSelect } from '@progress/kendo-react-dropdowns';
import React, { useState } from 'react';

const MultiSelectDropDownForChart = ({
    ddData,
    value,
    onChange
}: any) => {
    const [ddValue, setValue] = useState(value || []);

    const handleChange = (event: any) => {
        const currentSelectAll = ddValue.some((i: any) => i === "Select All");
        const nextSelectAll = event.value.some((i: any) => i === "Select All");
        let value = event.value;
        const currentCount = ddValue.length;
        const nextCount = value.length;
    
        if (
          nextCount > currentCount &&
          !currentSelectAll &&
          !nextSelectAll &&
          ddData.length - 1 === nextCount
        ) {
          value = ddData;
        } else if (
          nextCount < currentCount &&
          currentCount === ddData.length &&
          currentSelectAll &&
          nextSelectAll
        ) {
          value = value.filter((v: any) => v !== "Select All");
        } else if (!currentSelectAll && nextSelectAll) {
          value = ddData;
        } else if (currentSelectAll && !nextSelectAll) {
          value = [];
        }
        setValue(value);
        onChange(value);
      };
    const itemRender = (li: any, itemProps: any) => {
        console.log(itemProps);
        const itemChildren = (
          <>
            <input
              type="checkbox"
              name={itemProps.dataItem}
              checked={itemProps.selected}
              className={'chartDD'}
              onChange={(e) => itemProps.onClick(itemProps.index, e)}
            />{li.props.children}
          </>
        );
        return React.cloneElement(li, li.props, itemChildren);
      };
    return (
        <div>
          <MultiSelect
            data={ddData}
            itemRender={itemRender}
            autoClose={false}
            value={ddValue}
            // opened={true}
            onChange={handleChange}
            tags={
              ddValue.length > 0
                ? [{ text: `${ddValue.length} items selected`, data: [...ddValue] }]
                : []
            }
          />
        </div>
      );
}

export default MultiSelectDropDownForChart;