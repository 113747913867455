import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./index.scss";
import StripeCard from "./cardSection";
import { GetLocalStore } from "utils/storage";
import { useEffect, useState } from "react";

// const userDetails = GetLocalStore("tenantUserSession");
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const StripePayments = (props: any) => {
  const [stripePromise, setStripePromise] = useState<any>(null);
  useEffect(() => {

    const fetchStripeObject = async () => {
      // If there is no accountId, do not run the loadStripe function.
      if (GetLocalStore("tenantUserSession")?.StripePaymentConfiguration && !stripePromise) {
        const res = await loadStripe( GetLocalStore("tenantUserSession")?.StripePaymentConfiguration?.PublishableKey || 
          "pk_test_51N58LpFxbmjiHpLWOR1Gu9cjlhxpd19Xa6tK1GfdtUx01xgy4F7i9vlE2fPNF1bsxDq0mNcBiqYYjWsIvvC0x5tV006Xx4Khyj"
        );
        // When we have got the Stripe object, pass it into our useState.
        setStripePromise(res);
      }
    };
    fetchStripeObject();
  }, []);

  if(!stripePromise){
    return <p>Loading...</p>
  }
  return (
    <Elements stripe={stripePromise} >
      {
        props?.type === "card" &&
        <StripeCard {...props} />
      }
    </Elements>
  );
};

export default StripePayments;
