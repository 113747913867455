import {
  Notification,
  NotificationGroup
} from "@progress/kendo-react-notification";
import React from 'react';
import { notificationPosition } from 'utils/form-components';
export interface NotificationProps {
  notification: any;
  message: any;
  handleNotification?: any
}


const BuzopsNotification = (props: NotificationProps) => {
  const { notification, message } = props
  return (
    <NotificationGroup className="bzo-notification" style={{ ...notificationPosition.topCenter, zIndex: 9999999, top: "4rem" }}>
      {notification.error && (
        <Notification
          type={{ style: "error", icon: true }}
          closable={true}
          onClose={() => props.handleNotification('error')}
        >
          {message}{" "}
        </Notification>
      )}
      {notification.success && (
        <Notification
          type={{ style: "success", icon: true }}
          closable={true}
          onClose={() => props.handleNotification('success')}
        >
          {message}
        </Notification>
      )}
      {notification.info && (
        <Notification
          type={{ style: "info", icon: true }}
          closable={true}
          onClose={() => props.handleNotification('info')}
        >
          {message}
        </Notification>
      )}
    </NotificationGroup>
  )
}
export default BuzopsNotification;