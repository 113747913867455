import { GridDetailRowProps } from "@progress/kendo-react-grid";

import BuzopsReportGrid from "generic-components/BuzopsReportGrid";
import BuzopsWindow from "generic-components/BuzopsWindow";
import useStatisticDetail from "../Container/StatisticsDetail.hook";

const StatisticDetail = (props: GridDetailRowProps, dateRange: any) => {
  const statisticDetail = useStatisticDetail(props, dateRange);
  return (
    <>
      <BuzopsReportGrid
        fileName="SubscriberStatisticsAudit.xlsx"
        result={statisticDetail.pageInfo.data}
        dataState={{ ...statisticDetail.pageInfo }}
        dataStateChange={(e: any, forExcel: boolean) =>
          statisticDetail.fetchAuditData(e, forExcel)
        }
        stateGridColumns={statisticDetail.stateGridColumns}
        pageSize={100}
        setStateGridColumns={(data: any) =>
          statisticDetail.setStateGridColumns(data)
        }
        onColumnsSubmit={statisticDetail.onColumnsSubmit}
        excelData={statisticDetail.excelData}
        setExcelData={() => statisticDetail.setExcelData([])}
        onCellClick={statisticDetail.onCellClick}
      />
      <BuzopsWindow
        title={
          <>
            <div className={"buzops-window-title"}>
              <span
                className={"k-icon k-i-undo buzopsWindowBack"}
                onClick={() => statisticDetail.setShow(!statisticDetail.show)}
              ></span>
              &nbsp;{"MRR Data"}
            </div>
          </>
        }
        reduceWidth={10}
        className={"bzo-class-window"}
        visibility={statisticDetail.show}
        key={statisticDetail.show.toString()}
        onWindowClose={() => statisticDetail.setShow(!statisticDetail.show)}
      >
        <BuzopsReportGrid
          fileName="DetailedMRR.xlsx"
          result={statisticDetail.page1Info.data}
          dataState={statisticDetail.page1Info}
          dataStateChange={statisticDetail.fetchAuditDetails}
          stateGridColumns={statisticDetail.stateGrid1Columns}
          pageSize={100}
          setStateGridColumns={(data: any) =>
            statisticDetail.setStateGrid1Columns(data)
          }
          onColumnsSubmit={statisticDetail.onColumns1Submit}
          onCellClick={statisticDetail.onCell1Click}
          excelData={statisticDetail.excel1Data}
          setExcelData={() => statisticDetail.setExcel1Data([])}
        />
      </BuzopsWindow>
    </>
  );
};

export default StatisticDetail;
