import {
  Card,
  CardBody,
} from "@progress/kendo-react-layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TenantService } from "services/tenant/index.service";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { CheckUserMemberStatus, CurrencyFormat, CustomToFixed, checkHasTaxEnable } from "utils";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import AppointmentsBox from "../AppointmentsBox";
import BuzopsLoader from "generic-components/BuzopsLoader";
import QuickCheckout from "./QuickCheckout";
import ComplimentaryCheckout from "./ComplimentaryCheckouts";
import { CreditsErrorMessage,  recurrenceUnits } from "utils/form-utils";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import withNotification from "components/Hoc/withNotification";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import BuzopsButton from "generic-components/BuzopsButton";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { Hint } from "@progress/kendo-react-labels";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useSelector } from "react-redux";
import AppointmentTabs from "../AppointmentTabs";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentPackageService } from "components/checkout/OrderSummary";

const ServiceOverview = (props: any) => {
  const history = useHistory();
  const service = new TenantService();
  const redirectionObj = useSelector((state: any) => state?.redirection);
  const [PredictedTaxes, setPredictedTaxes] = useState<any>([]);
  const [PredictedTotalTaxAmount, setPredictedTotalTaxAmount] = useState<number>(0);
  const [loading, setLoading] = useState<any>(true);
  const [dropdownLoading, setDropdownLoading] = useState<any>(false);
  const [selectionPage, setSelectionPage] = useState({
    quickCheckout: props?.selectedButton === "quick" ? true : false,
    complimentaryCheckout:
      props?.selectedButton === "complimentary" ? true : false,
  });
  const [availableServices, setAvailableServices] = useState<any>([]);
  const [origAvailServices, setOrigAvailServices] = useState<any>([]);
  const [selectedService, setSelectedService] = useState<any>(null);
  const [formModified, setFormModified] = useState<any>(false);
  const userData = props?.userData;
  const renewOptionsFull = [
    {
      id: 0,
      text: "None",
    },
    {
      id: 2,
      text: "Renew after Last Appointment Completion",
    },
  ];
  const scheduleItems = [
    {
      text: "Paid",
      key: "Paid",
    },
    {
      text: "Complimentary",
      key: "Complimentary",
    },
  ];
  const [renewChecked, setRenewChecked] = useState<boolean>(false);
  const [PayInFull, setPayInFull] = useState<boolean>(false);
  const [visibleAC, setVisibleAC] = useState<boolean>(false);
  const [paymentProfile, setPaymentProfile] = useState<any>([]);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    useState<any>(undefined);
  const toggleACDialog = () => {
    if (renewalDetailsCurrent) resetRenewalForm();
    setVisibleAC(!visibleAC);
  };
  const initialRenewalData = {
    PackageCost: 0,
    PrivateServiceInstanceId: 0,
    CanRenew: false,
    RenewalOcurrsEvery: 0,
    RenewalRepeatFrequency: 0,
    RenewalNoOfInstallments: 0,
    RenewalDownPayment: 0,
    RenewalType: 0,
    PayInFull: false,
    RenewalPaymentGatewayPaymentProfileId: 0,
    RecurringAmount: 0,
    PredictedRenewalDateString: null,
  };
  const [renewalDetailsCurrent, setRenewalDetailsCurrent] =
    React.useState(initialRenewalData);
  const [renewalFormDetails, setRenewalFormDetails] =
    React.useState<any>(initialRenewalData);
  const renewOptions = renewOptionsFull;
  const [selectedRenewOption, setSelectedRenewOption] = useState(
    renewOptions.find((e) => e.id === 0)
  );
  const [updateRecurrenceSubmitLoading, setUpdateRecurrenceSubmitLoading] =
    useState(false);

  useEffect(() => {
    if (
      renewalDetailsCurrent.PackageCost ===
      renewalDetailsCurrent.RenewalDownPayment
    ) {
      setPayInFull(true);
    } else {
      setPayInFull(false);
    }
  }, [renewalDetailsCurrent]);

  useEffect(() => {
    if (
      PayInFull ||
      renewalDetailsCurrent.PackageCost ===
        renewalDetailsCurrent.RenewalDownPayment
    ) {
      renewalDetailsCurrent.RenewalType
        ? setSelectedRenewOption(renewOptions.find((e) => e.id === 2))
        : setSelectedRenewOption(renewOptions.find((e) => e.id === 0));
      handleUpdateRenewal(
        renewalDetailsCurrent.RenewalType ? 2 : 0,
        "RenewalType"
      );
    } else {
      setSelectedRenewOption(
        renewOptions.find((e) => e.id === renewalDetailsCurrent.RenewalType)
      );
      handleUpdateRenewal(renewalDetailsCurrent.RenewalType, "RenewalType");
    }
  }, [renewOptions]);

  useEffect(() => {
    if (renewChecked) {
      if (
        PayInFull ||
        renewalDetailsCurrent.PackageCost ===
          renewalDetailsCurrent.RenewalDownPayment
      ) {
        renewalDetailsCurrent.RenewalType
          ? setSelectedRenewOption(renewOptions.find((e) => e.id === 2))
          : setSelectedRenewOption(renewOptions.find((e) => e.id === 0));
        handleUpdateRenewal(
          renewalDetailsCurrent.RenewalType ? 2 : 0,
          "RenewalType"
        );
      } else {
        setSelectedRenewOption(
          renewOptions.find((e) => e.id === renewalDetailsCurrent.RenewalType)
        );
        handleUpdateRenewal(renewalDetailsCurrent.RenewalType, "RenewalType");
      }
    } else {
      setSelectedRenewOption(renewOptions.find((e) => e.id === 0));
      handleUpdateRenewal(0, "RenewalType");
    }
  }, [renewChecked]);

  const [selected, setSelected] = React.useState(0);
  useEffect(() => {
    let memberId = props?.userData?.UserMemberId;
    if (memberId) {
      fetchServiceInstance();
      fetchTaxes()
    }
  }, []);
  const fetchTaxes = async () => {
    const req = {};
    const taxService= new ManageTaxesService()
    const res = await taxService.getTaxes(req);
    setPredictedTaxes(res);
  };

  const handleTaxCalculation=(purchaseAmt:number,TaxItems=PredictedTaxes)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=purchaseAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
          return acc + currentValue?.Amount
    }, 0);
      setPredictedTaxes(TaxesArray)
      setPredictedTotalTaxAmount(taxAmt)
  }


  const handleCheckout=(e:any)=>{
    console.log(e.item.key)
    if(e.item.key==="Complimentary"){
      handleSelectionPage("complimentaryCheckout", true)
    }else if(e.item.key==="Paid"){
      handleSelectionPage("quickCheckout", true)
    }
  }

  const handleStaffSchedule=(e:any)=>{
    console.log(e.item.key)
    let complimentary=false
    if(e.item.key==="Complimentary"){
      complimentary=true
    }
    handleRedirectionForStaff(selectedService,complimentary)
  }
  async function fetchServiceInstance(status = false) {
    setLoading(true);
    setDropdownLoading(true);
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
    };
    const res = await service.getServiceFilter(req);
    if (res) {
      setAvailableServices(res);
      setOrigAvailServices(res);
    }
    setLoading(false);
  }
  useEffect(() => {
    if (props.selectedService && origAvailServices.length > 0) {
      onServiceSelected(props.selectedService);
    }
  }, [origAvailServices]);

  const getSelectedService = async (val: any) => {
    const result = await service.getIndividualService(val);
    setDropdownLoading(false);
    if (result) {
      setSelectedService(result);
      const d = {
        PackageCost: result?.PackageCost,
        PrivateServiceInstanceId: result?.PrivateServiceInstanceId,
        CanRenew: result?.RenewalType ? true : false,
        RenewalOcurrsEvery: result.RenewalOcurrsEvery,
        RenewalRepeatFrequency: result.RenewalRepeatFrequency,
        RenewalNoOfInstallments: result?.RenewalNoOfInstallments,
        RenewalDownPayment: result?.RenewalDownPayment,
        RenewalType: result?.RenewalType,
        PayInFull:
          result.PackageCost === result.RenewalDownPayment ? true : false,
        RenewalPaymentGatewayPaymentProfileId:
          result?.RenewalPaymentGatewayPaymentProfileId,
        RecurringAmount: result?.RecurringAmount,
        PredictedRenewalDateString: result?.PredictedRenewalDateString,
      };
      setRenewalFormDetails(d);
      setRenewalDetailsCurrent(d);
      setRenewChecked(d.RenewalType > 0 ? true : false);
      setSelectedRenewOption(
        renewOptions.find((e) => e.id === result?.RenewalType)
      );
      handleTaxCalculation(result?.RecurringAmount,PredictedTaxes)
      if (props?.selectedButton === "Paid") {
        history.push(
          `/member/${props?.uuid}/${props?.userData?.UserMemberId}/mybooking`,
          {
            selectedService: result,
            staffPage: true,
            userData: props?.userData,
            uuid: props?.uuid,
          }
        );
      }
    }
  };

  async function fetchPaymentProfiles(userMemberId: any) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      if (result.length > 0) {
        const res = result.filter((item: any) => item.CardTypeId !== -1);
        setPaymentProfile(res);
        setSelectedPaymentProfile(
          res.find(
            (r: any) =>
              r.PaymentGatewayPaymentProfileID ===
              renewalDetailsCurrent.RenewalPaymentGatewayPaymentProfileId
          )
        );
      }
    }
  }

  
  const handleEditRenewal = async () => {
    fetchPaymentProfiles(props?.userData?.MainUserId);
    setVisibleAC(!visibleAC);
  };

  const handleEditRequency = () => {
    props?.handleSubMenuNav(13, false);
  };

  const handleUpdateRenewal = (val: any, key: string, touched = false) => {
    let CanRenew = false;
    if (key === "RenewalType") {
      CanRenew = val ? true : false;
    } else {
      CanRenew = renewalFormDetails.RenewalType ? true : false;
    }
    let value = val;
    if (key === "RenewalDownPayment" || key === "RenewalNoOfInstallments") {
      if (val >= 0) {
        value = val;
      } else {
        value = 0;
      }
      if (key === "RenewalNoOfInstallments" && val > 999) {
        value = 999;
      }
      let recurringAmt = renewalFormDetails?.RecurringAmount;
      if (key === "RenewalDownPayment") {
        recurringAmt =
          (selectedService?.PackageCost - value) /
          renewalFormDetails?.RenewalNoOfInstallments;
      } else {
        if (value > 0) {
          recurringAmt =
            (selectedService?.PackageCost -
              renewalFormDetails?.RenewalDownPayment) /
            value;
        }
      }
      setRenewalFormDetails({
        ...renewalFormDetails,
        [key]: value,
        RecurringAmount: recurringAmt,
        CanRenew,
      });
    } else if (key === "PayInFull") {
      setRenewalFormDetails({
        ...renewalFormDetails,
        [key]: value,
        RenewalDownPayment: value
          ? selectedService?.PackageCost
          : selectedService?.RenewalDownPayment,
      });
    } else {
      setRenewalFormDetails({
        ...renewalFormDetails,
        [key]: value,
        CanRenew,
      });
    }
    if (touched) {
      setFormModified(true);
    }
  };

  const showErrorMessage = (key: any, mainKey: any) => {
    if (!renewalFormDetails?.[key] || renewalFormDetails?.[key] <= 0) {
      return <Error>{`Please provide atleast one ${mainKey}`}</Error>;
    }
    return <></>;
  };

  const renderUpdateRenewal = () => {
    const { PackageCost, Rate, RenewalDownPayment } = selectedService;
    return (
      <>
        <div className="bz-mt-2rem bz-mb-2_5rem">
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Cost</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{CurrencyFormat(PackageCost)}</div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Rate per session</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{CurrencyFormat(Rate)}</div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Down Payment</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(RenewalDownPayment)}
            </div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">
              <Checkbox
                label={"Renew"}
                checked={renewChecked}
                defaultChecked={true}
                onChange={(e: any) => {
                  setRenewChecked(!renewChecked);
                  if (renewChecked) {
                    setSelectedRenewOption(
                      renewOptions.find((e) => e.id === 0)
                    );
                    handleUpdateRenewal(0, "RenewalType", true);
                  } else {
                    setSelectedRenewOption(
                      renewOptions.find(
                        (e) => e.id === renewalDetailsCurrent.RenewalType
                      )
                    );
                    handleUpdateRenewal(
                      renewalDetailsCurrent.RenewalType,
                      "RenewalType",
                      true
                    );
                  }
                }}
                name="renew"
              />
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <div style={{ display: "flex" }}>
                <DropDownList
                  id={"renewOptionsDD"}
                  data={renewOptions}
                  onChange={(e) => {
                    setSelectedRenewOption(e.value);
                    handleUpdateRenewal(e.value.id, "RenewalType");
                    setFormModified(true);
                  }}
                  textField="text"
                  value={selectedRenewOption}
                  defaultValue={renewOptions.find(
                    (e) => e.id === renewalDetailsCurrent.RenewalType
                  )}
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  disabled={
                    !renewChecked || !CheckPermissions(PermissionEnum["Level3"])
                  }
                />
              </div>
            </div>
          </div>
          {renewalFormDetails.RenewalType !== 0 && renewChecked && (
            <div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Pay In Full</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <Checkbox
                    label={""}
                    checked={PayInFull}
                    defaultChecked={false}
                    onChange={(e: any) => {
                      setPayInFull(!PayInFull);
                      handleUpdateRenewal(!PayInFull, "PayInFull", true);
                    }}
                    name="PayInFull"
                    disabled={
                      renewalDetailsCurrent.PackageCost ===
                      renewalDetailsCurrent.RenewalDownPayment
                    }
                  />
                </div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  Payment Method
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <DropDownList
                    id={"selectedPaymentProfile"}
                    name={"selectedPaymentProfile"}
                    data={paymentProfile}
                    listNoDataRender={() => "No Payment Methods"}
                    textField="MaskedCCNumber"
                    dataItemKey="PaymentGatewayPaymentProfileID"
                    valueRender={(
                      el: React.ReactElement<HTMLSpanElement>,
                      value: any
                    ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                    itemRender={PaymentItemRender}
                    onChange={(e) => {
                      setSelectedPaymentProfile(e.value);
                      handleUpdateRenewal(
                        e.value?.PaymentGatewayPaymentProfileID,
                        "RenewalPaymentGatewayPaymentProfileId",
                        true
                      );
                    }}
                    style={{ width: "100%" }}
                    defaultValue={paymentProfile.find(
                      (r: any) =>
                        r.PaymentGatewayPaymentProfileID ===
                        renewalDetailsCurrent.RenewalPaymentGatewayPaymentProfileId
                    )}
                  />
                  {(!renewalFormDetails?.RenewalPaymentGatewayPaymentProfileId ||
                    renewalFormDetails?.RenewalPaymentGatewayPaymentProfileId ===
                      0) && <Error>{"Please select payment profile"}</Error>}
                </div>
              </div>
              {!PayInFull ? (
                <>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">
                      Down Payment
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      <div>
                        <NumericTextBox
                          width={"100%"}
                          value={renewalFormDetails?.RenewalDownPayment}
                          format="c2"
                          min={0}
                          onChange={(e) => {
                            handleUpdateRenewal(
                              e.target.value,
                              "RenewalDownPayment",
                              true
                            );
                          }}
                        />
                        {(renewalFormDetails?.RenewalDownPayment ===
                          undefined ||
                          renewalFormDetails?.RenewalDownPayment === "" ||
                          renewalFormDetails?.RenewalDownPayment < 0) && (
                          <Error>{"Please provide Renewal Down Payment"}</Error>
                        )}
                      </div>
                    </div>
                  </div>
                  {renewalFormDetails?.RenewalDownPayment === PackageCost ? (
                    <>
                      <div className="bz-content-text-row">
                        <div className="bz-content-title text-right">
                          Grand Total
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          {CurrencyFormat(
                            renewalFormDetails?.RenewalDownPayment
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bz-content-text-row">
                        <div className="bz-content-title text-right">
                          Frequency
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          <div style={{ display: "flex" }}>
                            <DropDownList
                              data={recurrenceUnits}
                              textField="text"
                              dataItemKey="id"
                              name="recurrenceUnit"
                              value={recurrenceUnits.find(
                                (r) =>
                                  r.id ===
                                  renewalFormDetails?.RenewalRepeatFrequency
                              )}
                              defaultValue={recurrenceUnits.find(
                                (r) => r.id === 4
                              )}
                              style={{ width: "100%" }}
                              onChange={(e) =>
                                handleUpdateRenewal(
                                  e.value?.id,
                                  "RenewalRepeatFrequency",
                                  true
                                )
                              }
                            />
                          </div>
                          <Hint>The billing frequency for the Service.</Hint>
                        </div>
                      </div>
                      <div className="bz-content-text-row">
                        <div className="bz-content-title text-right">
                          Recurring Every
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          <NumericTextBox
                            width={"100%"}
                            value={renewalFormDetails?.RenewalOcurrsEvery}
                            min={1}
                            onChange={(e) => {
                              handleUpdateRenewal(
                                e.target.value,
                                "RenewalOcurrsEvery",
                                true
                              );
                            }}
                          />
                          {showErrorMessage(
                            "RenewalOcurrsEvery",
                            "Recurring Every"
                          )}
                          <Hint>
                            Select how frequently the client would want to
                            repeat the Service payment based on frequency.
                          </Hint>
                        </div>
                      </div>
                      <div className="bz-content-text-row">
                        <div className="bz-content-title text-right">
                          Number Of Installments
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          <NumericTextBox
                            width={"100%"}
                            value={renewalFormDetails?.RenewalNoOfInstallments}
                            min={1}
                            max={999}
                            onChange={(e) => {
                              handleUpdateRenewal(
                                e.target.value,
                                "RenewalNoOfInstallments",
                                true
                              );
                            }}
                          />
                          {showErrorMessage(
                            "RenewalNoOfInstallments",
                            "Installment"
                          )}
                          <Hint>
                            Select the number of times the client would want to
                            repeat the Service payment based on frequency.
                          </Hint>
                        </div>
                      </div>
                      {renewalDetailsCurrent?.PredictedRenewalDateString &&
                        renewalFormDetails?.RenewalNoOfInstallments > 0 && (
                          <div className="bz-content-text-row">
                            <div className="bz-content-title text-right">
                              Installment Details
                            </div>
                            <span className="bz-colon text-center">:</span>
                            <div className="bz-content-body">
                              {showRecurrenceDetails()}
                            </div>
                          </div>
                        )}
                      {renewalDetailsCurrent?.PredictedRenewalDateString &&
                        renewalFormDetails?.RenewalNoOfInstallments > 0 && (
                          <div className="bz-content-text-row">
                            <div className="bz-content-title text-right">
                              Recurring Cost
                            </div>
                            <span className="bz-colon text-center">:</span>
                            <div className="bz-content-body">
                              {CurrencyFormat(
                                renewalFormDetails?.RecurringAmount
                              )}
                            </div>
                          </div>
                        )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">
                      Grand Total
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {CurrencyFormat(renewalFormDetails?.RenewalDownPayment)}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  const showRecurrenceDetails = () => {
    const {
      PredictedRenewalDateString,
      PackageCost,
    } = selectedService;
    let data = [];
    const every = renewalFormDetails?.RenewalOcurrsEvery;
    const unit = renewalFormDetails?.RenewalRepeatFrequency;
    for (
      let index = 0;
      index < renewalFormDetails?.RenewalNoOfInstallments;
      index++
    ) {
      const item = {
        DisplayDate:
          unit === 4
            ? moment(PredictedRenewalDateString)
                .add("months", every * index)
                .format("L")
            : moment(PredictedRenewalDateString)
                .add("days", every * index)
                .format("L"),
        InstallmentAmount:
          (PackageCost - renewalFormDetails?.RenewalDownPayment) /
          renewalFormDetails?.RenewalNoOfInstallments,
      };
      data.push(item);
    }

    if (data?.length === 0) {
      return <>{"No Installments found"}</>;
    }
    return (
      <div
        className={"installemt-grid bz-installment-details"}
        style={{ width: "268px", margin: "0px" }}
      >
        <Grid data={data} style={{ maxHeight: "300px", border: 0 }}>
          <Column field="DisplayDate" title={"Dates"} cell={customDateCell} />
          <Column
            field="InstallmentAmount"
            title={"Amount"}
            cell={InstallmentAmtCell}
          />
        </Grid>
      </div>
    );
  };

  const customDateCell = (props: any) => {
    return <td className={"capitalize "}>{props.dataItem.DisplayDate}</td>;
  };
  const InstallmentAmtCell = (props: any) => {
    return (
      <td className={"capitalize"}>
        {CurrencyFormat(props.dataItem.InstallmentAmount.toFixed(2))}
      </td>
    );
  };

  const resetRenewalForm = () => {
    setRenewalFormDetails(renewalDetailsCurrent);
    setPayInFull(renewalDetailsCurrent.PayInFull);
    setSelectedRenewOption(
      renewOptions.find((e) => e.id === renewalDetailsCurrent?.RenewalType)
    );
    setSelectedPaymentProfile(
      paymentProfile.find(
        (r: any) =>
          r.PaymentGatewayPaymentProfileID ===
          renewalDetailsCurrent.RenewalPaymentGatewayPaymentProfileId
      )
    );
    setFormModified(false);
    getSelectedService(props.selectedService);
  };

  const saveRenewalOtions = async () => {
    const {
      PredictedRenewalDateString = null,
      PackageCost = 0,
      ...req
    } = { ...renewalFormDetails };
    if (
      !req.PayInFull &&
      req.RenewalType !== 0 &&
      (!renewalFormDetails?.RenewalOcurrsEvery ||
        renewalFormDetails?.RenewalOcurrsEvery <= 0 ||
        !renewalFormDetails?.RenewalNoOfInstallments ||
        renewalFormDetails?.RenewalNoOfInstallments <= 0)
    ) {
      return;
    }
    if (
      (!req?.RenewalPaymentGatewayPaymentProfileId ||
        req?.RenewalPaymentGatewayPaymentProfileId === 0) &&
      req.RenewalType !== 0
    ) {
      return;
    }
    if (selectedPaymentProfile?.CardTypeId === 8 && req.RenewalDownPayment > selectedPaymentProfile?.Credit) {
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    if (
      req.PayInFull ||
      req.RenewalType === 0 ||
      req.RenewalDownPayment === selectedService?.PackageCost
    ) {
      req.RecurringAmount = 0;
      req.RenewalNoOfInstallments = 0;
      req.RenewalOcurrsEvery = 0;
      req.RenewalRepeatFrequency = 0;
    }
    setUpdateRecurrenceSubmitLoading(true);
    const updateRecurrenceService = new TenantService();
    const result = await updateRecurrenceService.updateRenewal(req);
    setUpdateRecurrenceSubmitLoading(false);
    if (result.ResponseCode === 100) {
      const successMsg = "Renewal Updated Successfully";
      props?.handleNotificationMessage(successMsg, "success");
      toggleACDialog();
      getSelectedService(props.selectedService);
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const renderSubscriptionDetails = () => {
    if (selectedService === null) {
      return "No Subscription Data Found";
    }
    const {
      PurchaseDate,
      AmountPaid,
      OcurrsEvery,
      NextRecurrence,
      RepeatFrequencyDuration,
      RecurringAmount,
      Duration,
      PackageCost,
      NumberOfSessions,
      Rate,
      NoOfInstallments,
      SalesPersonName,
      RenewalDescription,
      RenewalStatus,
      PrivateServiceType,
      Status,
      StatusDescription,
      CanCollectTaxes=checkHasTaxEnable()
    } = selectedService;
    return (
      <div className="bz-mt-2rem bz-mb-2_5rem">
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Purchased Date</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {moment(PurchaseDate).format("MM/DD/YYYY")}
          </div>
        </div>
        <div className="bz-content-text-row show-tax-content-row">
          <div className="bz-content-title text-right">
            <div className="d-flex flex-column justify-content-end">
              <span>Total Cost {CanCollectTaxes && <span>*</span>}</span>
              {CanCollectTaxes && <small style={{fontSize:'10px',color:'#777'}}>(Exclusion of Surcharges)</small>}
            </div>
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            <b>{CurrencyFormat(PackageCost)}</b>{" "}
            {`(Paid ${CurrencyFormat(AmountPaid)}, Remaining ${CurrencyFormat(
              PackageCost - AmountPaid
            )})`}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Total Appointments</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {`${NumberOfSessions} (${Duration}min) (Per Appointment Cost ${CurrencyFormat(
              Rate
            )})`}{" "}
          </div>
        </div>
        <div className="bz-content-text-row  show-tax-content-row">
          <div className="bz-content-title text-right">
            <div className="d-flex flex-column justify-content-end">
              <span>Recurring {checkHasTaxEnable() && CanCollectTaxes && NextRecurrence !== "N/A" && <span>*</span>}</span>
              {checkHasTaxEnable() && CanCollectTaxes && NextRecurrence !== "N/A" && <small style={{fontSize:'10px',color:'#777'}}> <Tooltip
                content={(tooltipProps: any) => {
                  return (
                    <TooltipContentPackageService
                      title={tooltipProps.title}
                      data={PredictedTaxes}
                      TotalTaxAmount={PredictedTotalTaxAmount}
                      Name={selectedService?.Name}
                    />
                  );
                }}
                tooltipClassName="bz-tooltip-table"
              >
                <a
                  title="Surcharges"
                  style={{
                    borderBottom: "1px dotted #0d6efd",
                    cursor: "pointer",
                  }}
                >
                  {"(Surcharges)"}
                </a>
              </Tooltip></small>}
            </div>
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {RecurringAmount !== 0 && NextRecurrence !== "N/A"
              ? `${CurrencyFormat(RecurringAmount)} (${moment(
                  NextRecurrence
                ).format("MM/DD/YYYY")})`
              : PackageCost - AmountPaid > 0
              ? "N/A"
              : "N/A (PIF)"}
                {CanCollectTaxes && NextRecurrence !== "N/A" && <small className="pl-1">{`(Exclusion of Surcharges)`}</small>}
          </div>
        </div>
        {RecurringAmount !== 0 &&
          OcurrsEvery !== 0 &&
          RepeatFrequencyDuration && (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Frequency</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {`For Every ${OcurrsEvery} ${RepeatFrequencyDuration}`}
                {props?.staffPage &&
                  CheckPermissions(PermissionEnum["ServicePause"]) &&
                  RenewalStatus !== 3 &&
                  ![3, 4].includes(Status) &&
                  PrivateServiceType === 1 && (
                    <BuzopsTooltip
                      html={
                        <span style={{ cursor: "pointer" }}>
                          <a
                            title="Update Recurrence Options"
                            onClick={() => handleEditRequency()}
                          >
                            <i
                              title="Update Recurrence Options"
                              style={{ fontSize: "16px" }}
                              className="fa fa-edit"
                            ></i>
                          </a>
                        </span>
                      }
                    />
                  )}
              </div>
            </div>
          )}
        {RecurringAmount !== 0 &&
          OcurrsEvery !== 0 &&
          RepeatFrequencyDuration && (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                No. of Installments
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{NoOfInstallments}</div>
            </div>
          )}
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Signed up by</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{SalesPersonName}</div>
        </div>
        {StatusDescription !=="Completed" ? <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Renewal Option</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {RenewalDescription === "Off" || RenewalDescription === "off"
              ? "None"
              : RenewalDescription}
            {props?.staffPage &&
              CheckPermissions(PermissionEnum["ServicePause"]) &&
              RenewalStatus !== 3 &&
              ![3, 4].includes(Status) &&
              PrivateServiceType === 1 && (
                <BuzopsTooltip
                  html={
                    <span style={{ cursor: "pointer" }}>
                      <a
                        title="Update Renewal Options"
                        onClick={() => handleEditRenewal()}
                      >
                        <i
                          title="Update Renewal Options"
                          style={{ fontSize: "16px" }}
                          className="fa fa-edit"
                        ></i>
                      </a>
                    </span>
                  }
                />
              )}
         
          </div>
        </div>:null}
        
      </div>
    );
  };
  const onServiceSelected = (val: any, update: boolean = false) => {
    const test = origAvailServices.filter(
      (item: any) => item.PrivateServiceInstanceId === parseInt(val)
    );
    if (test.length === 0) {
      props?.handleNotificationMessage(
        `Issue while fetching Services`,
        "error"
      );
    } else {
      getSelectedService(val);
    }
  };
  const valueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any
  ) => {
    const children = [
      <span key={1} className={"p-2"}>
        {selectedService?.Name}
        <br />
        <span>
          Available Appointments: {selectedService?.SessionAvailableToSchedule}
        </span>
      </span>,
    ];

    return React.cloneElement(element, { ...element.props }, children);
  };

  const filterData = (filter: any) => {
    const data = origAvailServices.slice();
    return filterBy(data, filter);
  };
  const filterChange = (e: any) => {
    const filteredData = filterData(e.filter);
    setAvailableServices(filteredData);
  };

  const handleSelectionPage = (name: string, val: boolean) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    setSelectionPage({
      ...selectionPage,
      [name]: val,
    });
    if(!val){
      fetchServiceInstance()
    }
  };

  const handleRedirectionForClient = (dataItem: any,complimentary=false) => {
    if (!CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    }
    if (redirectionObj?.firstPathValue !== "") {
      history.push(`/${redirectionObj?.firstPathValue}/mybooking`, {
        selectedService: dataItem,
      });
    } else {
      history.push(`/mybooking`, {
        selectedService: dataItem,
        IsComplimentary:dataItem?.PrivateServiceType ===1 ? complimentary : false
      });
    }
  };
  const handleRedirectionForStaff = (dataItem: any,complimentary=false) => {
    if (!CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    history.push(
      `/member/${props?.uuid}/${props?.userData?.UserMemberId}/mybooking`,
      {
        selectedService: dataItem,
        staffPage: true,
        userData: props?.userData,
        uuid: props?.uuid,
        IsComplimentary:dataItem?.PrivateServiceType ===1 ? complimentary : false
      }
    );
  };

  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span
        style={
          selectedService?.PrivateServiceInstanceId ===
          itemProps.dataItem.PrivateServiceInstanceId
            ? { color: "#008db9" }
            : {}
        }
      >
        <span style={{ fontSize: "1.25rem", fontWeight: 400 }}>
          {itemProps.dataItem.Name}
        </span>
        <br />
        <span>
          {`Available Appointments`}:{" "}
          {itemProps.dataItem?.IsScheduleWithoutPurchasePrivateService
            ? 1
            : itemProps.dataItem.SessionAvailableToSchedule}
        </span>
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <>
      {selectionPage?.quickCheckout && (
        <QuickCheckout
          {...props}
          EntityId={props.selectedService}
          userData={userData}
          staffPage={props?.staffPage}
          handleSelectionPage={(name: string, val: boolean) =>
            handleSelectionPage(name, val)
          }
        />
      )}
      {selectionPage?.complimentaryCheckout && (
        <ComplimentaryCheckout
          {...props}
          EntityId={props.selectedService}
          userData={userData}
          staffPage={props?.staffPage}
          handleSelectionPage={(name: string, val: boolean) =>
            handleSelectionPage(name, val)
          }
        />
      )}
      {!selectionPage?.complimentaryCheckout &&
        !selectionPage?.quickCheckout && (
          <div className="bz-service-overview-page">
            <h5 className="mb-3 bz_fs_1_5">One-on-One Overview</h5>

            {loading || dropdownLoading ? (
              <BuzopsLoader type={"list"} />
            ) : (
              <>
                <div className="bz-row justify-content-between">
                  <div className="bz-service-selection service-overview">
                    <div className="bz-service-dropdown">
                      <div className="form-group mb-0">
                        <label className="d-block">Service Name</label>
                        <DropDownList
                          key={selectedService?.PrivateServiceInstanceId}
                          data={availableServices}
                          textField="Name"
                          dataItemKey="PrivateServiceInstanceId"
                          onChange={(e) =>
                            onServiceSelected(
                              e.target.value.PrivateServiceInstanceId
                            )
                          }
                          valueRender={valueRender}
                          filterable={true}
                          style={{ width: "100%" }}
                          onFilterChange={(e) => filterChange(e)}
                          className={"customDropdown bz-oneonone-dropdown"}
                          itemRender={itemRender}
                        />
                      </div>
                    </div>
                  </div>
                  {!props?.staffPage && selectedService?.SessionAvailableToSchedule > 0 &&
                                selectedService?.CanScheduleAppointment && selectedService?.StatusDescription !=="Completed" && (
                    <div className="bz-schedule-btn-containet bz-schedule-btn-containet-oneonone">
                      <Button
                        className={"bz-schedule-btn float-right"}
                        primary={true}
                        onClick={() =>
                          handleRedirectionForClient(selectedService)
                        }
                      >
                        <i className="fas fa-calendar-alt"></i>Schedule
                      </Button>
                    </div>
                  )}
                  {props?.staffPage && selectedService?.StatusDescription !=="Completed" && (
                    <div className="bz-schedule-btn-containet bz-schedule-btn-containet-oneonone">
                        <div>
                        <DropDownButton
                          look="flat"
                          text={"Checkout"}
                          onItemClick={(e: any) => handleCheckout(e)}
                          items={scheduleItems.filter((i: any) => {
                            if (i?.key === "Paid") {
                              if (((props?.staffPage &&
                                CheckPermissions(PermissionEnum["QuickCheckout"])) ||
                                !props?.staffPage) &&
                                selectedService?.PaidSchedules -
                                  selectedService?.Completed >
                                  0 &&
                                selectedService?.CanScheduleAppointment) {
                                return true
                              }
                              return false
                            }else if(i?.key ==="Complimentary"){
                              if((props?.staffPage &&
                                CheckPermissions(
                                  PermissionEnum["ComplementaryCheckout"]
                                )) ||
                                !props?.staffPage) {
                                  return true
                                }
                                return false
                            }
                            return true
                          })}
                          className="btn btn-link add-new-btn bz-schedule-btn pr-1"
                        />
                        <DropDownButton
                          iconClass="fas fa-calendar-alt"
                          look="flat"
                          text={"Schedule"}
                          onItemClick={(e: any) => handleStaffSchedule(e)}
                          items={scheduleItems.filter((i: any) => {
                            if (i?.key === "Paid") {
                              if (selectedService?.SessionAvailableToSchedule > 0 &&
                                selectedService?.CanScheduleAppointment) {
                                return true
                              }
                              return false
                            }
                            return true
                          })}
                          className="btn btn-link add-new-btn bz-schedule-btn"
                        />
                        </div>
                    </div>
                  )}
                </div>
                <hr />
                <div className="bz-subsection-row subscription-blk">
                  <div className="left-section">
                    <h5 className="my-3 bz_fs_1_5">Subscription Info  <span
                              className={`badge badge-status-${selectedService?.StatusDescription}`}
                            >
                              {selectedService?.StatusDescription}
                            </span></h5>
                    {renderSubscriptionDetails()}
                    <hr />
                    {
                      selectedService?.PrivateServiceInstanceId &&
                      <AppointmentTabs
                        {...props}
                        userData={{
                          ...props?.userData,
                          EntityUserMemberId: selectedService?.UserMemberId,
                        }}
                        key={selectedService?.PrivateServiceInstanceId}
                        serviceId={selectedService?.PrivateServiceInstanceId}
                      />
                    }
                  </div>
                  <div className="right-section mt-3">
                    {selectedService && (
                      <Card
                        className={
                          "bz-card bz-overview-cards bz-appoinments-overview-card pt-0"
                        }
                        style={{ backgroundColor: "#eff4f7" }}
                      >
                        <CardBody>{AppointmentsBox(selectedService)}</CardBody>
                      </Card>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
           {visibleAC && (
              <Dialog
                title={"Update Renewal options"}
                onClose={toggleACDialog}
                height={"calc(100vh - 2rem)"}
                width={"calc(100vw/2)"}
                style={{ alignItems: "end", paddingRight: "1rem" }}
              >
                <div>{renderUpdateRenewal()}</div>
                <DialogActionsBar>
                  <BuzopsButton
                    onClick={() => saveRenewalOtions()}
                    primary
                    type={"button"}
                    label={"Save"}
                    disabled={updateRecurrenceSubmitLoading || !formModified}
                    loading={updateRecurrenceSubmitLoading}
                  />
                  <Button
                    className="ml-0"
                    onClick={() => resetRenewalForm()}
                    disabled={updateRecurrenceSubmitLoading}
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={toggleACDialog}
                    disabled={updateRecurrenceSubmitLoading}
                  >
                    Cancel
                  </Button>
                </DialogActionsBar>
              </Dialog>
            )}
    </>
  );
};

export default withNotification(ServiceOverview);
