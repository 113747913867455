import { Button } from "@progress/kendo-react-buttons";
import { Card, CardBody } from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import { isTabletConnected, onClear, onDone, onSign } from "./SigWebTablet";

const TopazSignPad = (props: any) => {
  const [invalidForm, setInvalidForm] = useState(false);
  useEffect(() => {
    onSign();
    // }
  });

  useEffect(() => {
    if (!isTabletConnected()) {
      setInvalidForm(true);
    }
  }, []);
  if (invalidForm) {
    return (
      <div style={{minHeight:'20rem'}}>
        <div className="alert alert-danger bz-connect-msg">
        <p className="mb-0">
          <i className="fas fa-exclamation-triangle mr-2"></i>Please Connect your
          Signature pad.
        </p>
      </div>
      </div>
    );
  }
  return (
    <div className={"row bzo-signature-options"}>
      <div className={"col-lg-12 col-md-12 col-sm-12 mb-3 text-center"}>
        <div>
          <Card className={"buzops-clickable border-1"}>
            <CardBody>
              <canvas id="topazCnv" width="500" height="100" />
              {/* <img src="/images/sign_write.svg" className="card-img-top" alt="Signature" /> */}
              {/* <span >Draw</span> */}
            </CardBody>
          </Card>
        </div>
      </div>
      <div>
        <Button onClick={() => onDone(props?.getImage)}>Done</Button>
        <Button onClick={() => onClear()}>Clear</Button>
      </div>
    </div>
  );
};

export default TopazSignPad;
