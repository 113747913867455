import { DropDownList } from "@progress/kendo-react-dropdowns";
import BuzopsReportGrid from "generic-components/BuzopsReportGrid";
import BuzopsWindow from "generic-components/BuzopsWindow";
import ReportsCommonFilters from "../../reportsCommonFilters";
import DetailedGroupServices from "./DetailedGroupServices";
import useGroupServices from "../Container/GroupServices.hook";

const GroupServices = (props: any) => {
  const groupService = useGroupServices();
  const uuid = props?.match?.params?.uuid;

  return (
    <>
      <ReportsCommonFilters
        {...props}
        history={props.history}
        location={props.location}
        setFromDateToDate={groupService.setFromDateToDate}
      />

      <div className="subscribers-main-header">
        <h2 className="subscriber-header">{`Subscribers By Group Classes`}</h2>
        <div className="d-flex justify-content-end subscribers-close-icon">
          <li
            className="k-icon k-i-close"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
      </div>

      <>
        <DropDownList
          data={["All", "Active", "Cancelled", "Completed"]}
          defaultValue={"All"}
          value={groupService.selectedStatus}
          onChange={(e) => {
            groupService.fetchGroupClassesData({ status: e.target.value });
            groupService.setSelectedStatus(e.target.value);
          }}
        />{" "}
        &nbsp;
      </>
      <div className="customer-grid-border">
        <BuzopsReportGrid
          fileName="GroupClasses.xlsx"
          result={groupService.pageInfo.data}
          dataState={{ ...groupService.pageInfo }}
          dataStateChange={(e: any) =>
            groupService.fetchGroupClassesData(e.dataState)
          }
          stateGridColumns={groupService.stateGridColumns}
          pageSize={10}
          setStateGridColumns={(data: any) =>
            groupService.setStateGridColumns(data)
          }
          onColumnsSubmit={groupService.onColumnsSubmit}
          onCellClick={(item: any) => groupService.onCellClick(item)}
        />
      </div>
      {
        <BuzopsWindow
          key={groupService.showDetailedView.toString()}
          visibility={groupService.showDetailedView}
          onWindowClose={() => groupService.setShowDetailedView(false)}
          children={<DetailedGroupServices data={groupService.selectedItem} />}
        />
      }
    </>
  );
};

export default GroupServices;
