import React, { useEffect, useState } from "react";
import { useApiClient } from "services/axios-service-utils";
import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import BuzopsOnlineFormGenerator from "./BuzopsOnlineFormGenerator";
import ErrorForm from "./ErrorForm";

const BuzopsIntakeForm = (props: any) => {
  const [formTemplate, setFormTemplate] = useState<any>(null);
  const [formData, setFormData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const { axiosRequest } = useApiClient();
  const formTemplateShortCode = props?.match?.params?.formTemplateShortCode;
  const affiliateShortCode = props?.match?.params?.affiliateShortCode || null;
  const referralPromotionalShortcode = props?.match?.params?.referralPromotionalShortcode || null;
  const formMappingInternalId =
    props?.match?.params?.formMappingInternalId || null;
  const errorCallBack = (message: string) => {
    props?.handleNotificationMessage(message, "error");
  };
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (props?.FormTemplate) {
      getFormTemplateById();
    } else if (formMappingInternalId) {
      getFormTemplateByformMappingInternalId();
    } else {
      getFormTemplateByShortCode();
    }
  }, [props?.FormTemplate]);

  const getFormTemplateById = async () => {
    setLoading(true);
    const FormTemplateId = props?.FormTemplate?.FormTemplateId;
    await axiosRequest.get(`Forms/FormTemplate/${FormTemplateId}`, {
      successCallBack: (response: any) => {
        const data = response.data;
        setFormTemplate(data?.Item);
        const value = data?.Item?.Values;
        setFormData(value);
        setLoading(false);
      },
      errorCallBack: (response: any) => {
        setLoading(false);
        errorCallBack(
          response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] || "Internal server error"
        );
      },
    });
  };
  const getFormTemplateByformMappingInternalId = async () => {
    setLoading(true);
    const userMappingInternalDataId = formMappingInternalId;
    await axiosRequest.post(
      `Forms/FormTemplate/userMappingInternalDataId?userMappingInternalDataId=${userMappingInternalDataId}`,{},
      {
        successCallBack: (response: any) => {
          const data = response.data;
          setFormTemplate(data?.Item);
          const value = data?.Item?.Values;
          setFormData(value);
          setLoading(false);
        },
        errorCallBack: (response: any) => {
          setLoading(false);
          errorCallBack(
            response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] || "Internal server error"
          );
        },
      }
    );
  };
  const getFormTemplateByShortCode = async () => {
    setLoading(true);
    await axiosRequest.post(
      `Forms/FormTemplate/shortcode`,
      {
        ShortCode: formTemplateShortCode,
        AffiliateShortCode: affiliateShortCode,
        PromotionShortCode:referralPromotionalShortcode ==="lead" ? null : referralPromotionalShortcode, 
        HasUseOwnerShortCode: referralPromotionalShortcode ==="lead" ? true : false
      },
      {
        successCallBack: (response: any) => {
          const data = response.data;
          setFormTemplate(data?.Item);
          const value = data?.Item?.Values;
          setFormData(value);
          setLoading(false);
        },
        errorCallBack: (response: any) => {
          setLoading(false);
          setShowErrorPage(true);
          setErrorMsg(
            response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] || "Internal server error"
          );
          
        },
      }
    );
  };
  const handleReset = () => {};
  const handleSuccess = () => {};

  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  let answer_data: any = null;
  if (props?.userDetails && formData?.length > 0) {
    answer_data = {};
    const { Phone, FirstName, Email, LastName } = props?.userDetails;
    const userData: any = {
      "Phone Number": Phone,
      Email: Email,
      "Last Name": LastName,
      "First Name": FirstName,
    };
    formData?.forEach((i: any) => {
      if (i?.canHaveAnswer) {
        answer_data[i?.field_name] = userData[i?.text] || "";
      }
    });
  } else if (formTemplate?.FormTemplateMappedUser) {
    if (formTemplate?.FormTemplateMappedUser?.IsFormSubmitted) {
      return (
        <div className="online-form-generator m-2 p-2">
          <div className="alert alert-success" role="alert">
            You already Submitted the form.
          </div>
          <h2>{formTemplate?.FormName}</h2>
          <p>Your response has been recorded.</p>
        </div>
      );
    } else {
      answer_data = {};
      const { PhoneNumber, FirstName, Email, LastName } =
        formTemplate?.FormTemplateMappedUser;
      const userData: any = {
        "Phone Number": PhoneNumber,
        Email: Email,
        "Last Name": LastName,
        "First Name": FirstName,
      };
      formData?.forEach((i: any) => {
        if (i?.canHaveAnswer) {
          answer_data[i?.field_name] = userData[i?.text] || "";
        }
      });
    }
  }
  if (showErrorPage) {
    return <ErrorForm errorMessage={errorMsg} />;
  }

  return (
    <div>
      <BuzopsOnlineFormGenerator
        download_path=""
        back_action="/"
        handleBack={handleReset}
        back_name="Reset"
        answer_data={answer_data}
        action_name="Submit"
        form_action="/api/form"
        form_method="POST"
        skip_validations={false}
        read_only={false}
        variables={null}
        modifyTemplate={true}
        data={formData}
        FormParentValue={formTemplate}
        locale="en"
        handleSuccess={handleSuccess}
        userDetails={props?.userDetails}
        clientDashboard={props?.userDetails ? true : false}
      />
    </div>
  );
};

export default withNotification(BuzopsIntakeForm);
