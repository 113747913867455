import React, { useEffect, useState } from "react";
import { ReportsService } from "services/reports/index.service";
import moment from "moment";
import { GridExpandChangeEvent } from "@progress/kendo-react-grid";
import { AuditType } from "utils/form-utils";
import { SubscriberStatisticsColumns } from "../Utilities/constants";

const useStatistics = () => {
  const [pageInfo, setPageInfo] = useState<any>({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });
  const [excelData, setExcelData] = useState([]);

  const [serviceTypeValue, setServiceTypeValue] = useState<any>({
    label: "All",
    value: 0,
  });
  const filterText = "This Year";
  const service = new ReportsService();

  const [stateGridColumns, setStateGridColumns] = React.useState(
    SubscriberStatisticsColumns
  );

  const [fromDateToDate, setFromDateToDate] = useState({
    from: moment().startOf("year").startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });

  const fetchStatistics = async (pageData?: any, forExcel = false) => {
    let req = {
      pageSize: 1000000,
      ShowAll: true,
      AuditType: pageData?.AuditType || 0,
      pageNumber: forExcel
        ? 0
        : pageData?.dataState?.skip
        ? pageData?.dataState.skip / pageData?.dataState.take
        : 0,
      FromDate: fromDateToDate?.from,
      ToDate: fromDateToDate?.to,
      HirecharyLevel: 2,
      IncludeChrunRateExcluded: false,
      frequencyText: "Custom",
      StatusDescription: "All",
      AuditTypeValue: pageData?.AuditType || 0,
      IsChartRendering: 1,
    };

    const getData = await service.getSubscriberStatistics(req);

    if (forExcel) {
      setExcelData(
        getData.Item.Result?.[0]?.OverAllByAuditType.map((d: any) => ({
          ...d,
          Type: AuditType.find((val) => val.id === d.Type)?.text || "",
          Period: `${fromDateToDate.from.split("T")[0]}-${
            fromDateToDate.to.split("T")[0]
          }`,
        }))
      );
    } else {
      setPageInfo((prev: any) => {
        prev.data = getData.Item.Result;
        prev.pageNumber = req.pageNumber;
        prev.total = getData.Item.TotalItems;
        if (pageData?.take) {
          prev.skip = pageData.skip;
          prev.take = pageData.take;
        }
        return { ...prev };
      });
    }
  };

  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    let oldExpanded = false;
    let newData = pageInfo.data[0].OverAllByAuditType.map((item: any) => {
      if (item.Type === event.dataItem.Type) {
        if (!event.dataItem.expanded) {
          oldExpanded = true;
        }
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });

    if (oldExpanded) {
    }
    setPageInfo((prev: any) => {
      prev.data[0].OverAllByAuditType = newData;
      return { ...prev };
    });
  };

  const redesignGrid = (columnsToRemove: any) => {
    const overallColumns = JSON.parse(
      JSON.stringify(SubscriberStatisticsColumns)
    );
    let removableColumns: any = [];
    removableColumns = columnsToRemove;
    const finalColumns = overallColumns.filter(
      (val: any) => !removableColumns?.includes(val.field)
    );
    setStateGridColumns(finalColumns);
  };

  const renderCustomCell = (dataItem: any, column: any) => {
    switch (column.field) {
      case "Type":
        return AuditType.find((val) => val.id === dataItem.Type)?.text || "";
      case "Period":
        return `${fromDateToDate.from.split("T")[0]}-${
          fromDateToDate.to.split("T")[0]
        }`;

      default:
        break;
    }
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  useEffect(() => {
    fetchStatistics({
      fromDate: fromDateToDate.from,
      toDate: fromDateToDate.to,
    });
  }, [fromDateToDate]);

  return {
    fetchStatistics,
    onColumnsSubmit,
    excelData,
    expandChange,
    setStateGridColumns,
    setExcelData,
    pageInfo,
    stateGridColumns,
    redesignGrid,
    serviceTypeValue,
    setServiceTypeValue,
    filterText,
    renderCustomCell,
    setFromDateToDate,
    fromDateToDate,
  };
};

export default useStatistics;
