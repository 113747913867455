import { CommonServiceUtils } from "services/common-service-utils";
import { GetLocalStore } from "utils/storage";

export class OnsiteAccessService extends CommonServiceUtils {
    async getAccessPoints(): Promise<any> {
        let response = null;
        const accessData: any = await this.postData(
          `${this.apiUrlCreator('accessCard/accesspoints')}`
        );
        if (accessData?.ResponseCode === 100) {
          response = accessData.Items;
        }
        return response;
    }

    async getAccessConfiguration(req: any): Promise<any> {
      let response = null;
      const accessData: any = await this.postData(
        `${this.apiUrlCreator('accessCard/configuration')}`,
        req
      );
      if (accessData?.ResponseCode === 100) {
        response = accessData.Items;
      }
      return response;
    }

    async saveAccessConfiguration(req: any): Promise<any> {
      let response = null;
      const accessData: any = await this.postData(
        `${this.apiUrlCreator('accessCard/save')}`,
        req
      );
      // console.log(accessData)
      return accessData;
    }

    async updateAccessConfiguration(req: any): Promise<any> {
      let response = null;
      const accessData: any = await this.postData(
        `${this.apiUrlCreator('accessCard/updateStatus')}`,
        req
      );
      // console.log(accessData)
      return accessData;
    }

    async saveAccessConfigurationFetch(req: any): Promise<any> {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${GetLocalStore("token")}`
      }
      const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(req),          
      };
      const result: any = await fetch(this.apiUrlCreator('accessCard/save'), requestOptions)
                                .catch(e => {
                                  // console.log(e)
                                  return {
                                    ErrorMessages: [
                                        "Something happened. Server doesn't seem to be responding"
                                    ],
                                    ResponseCode: 200
                                  }
                                })
      const data = await result.json();
      // console.log(data)
      return data
    }

    async updateAccessConfigurationFetch(req: any): Promise<any> {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${GetLocalStore("token")}`
      }
      const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(req),          
      };
      const result: any = await fetch(this.apiUrlCreator('accessCard/updateStatus'), requestOptions)
                                .catch(e => {
                                  // console.log(e)
                                  return {
                                    ErrorMessages: [
                                        "Something happened. Server doesn't seem to be responding"
                                    ],
                                    ResponseCode: 200
                                  }
                                })
      const data = await result.json();
      // console.log(data)
      return data
    }
}