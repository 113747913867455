import React, { useState, useEffect, useRef } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import "hammerjs";
import { useHistory } from "react-router-dom";
import { RadioButton } from "@progress/kendo-react-inputs";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import ReportsCommonFilters from "../../reportsCommonFilters";
import { SalesCustomColumnMenu } from "../salesCustomColumnMenu";
import { ReportsService } from "../../../../services/reports/index.service";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { CurrencyFormat } from "utils";
import moment from "moment";
import { orderBy } from "@progress/kendo-data-query";
import OneTimeChargeChart from "./oneTimeChargeChart";

const dataCell = (props: any, column: any) => {
  if (column.type === "amount") {
    return <td>{CurrencyFormat(props.dataItem[props.field])}</td>;
  } else if (column.type === "date") {
    return <td>{moment(props.dataItem[props.field]).format("YYYY-MM-DD")}</td>;
  } else if (column.type === "dateMonth") {
    return <td>{moment(props.dataItem[props.field]).format("YYYY-MM")}</td>;
  } else if (column.type === "dateTime") {
    return (
      <td>{moment(props.dataItem[props.field]).format("YYYY-MM-DD HH:MM")}</td>
    );
  }
  return <td>{props.dataItem[props.field]}</td>;
};

const FullScreenView = ({ viewData }: any) => {
  return <div className="sales-fullview">{viewData}</div>;
};

const SalesGridTableFullScreen = ({
  excelExport,
  result,
  stateGridColumns,
  dataState,
  dataStateChange,
  onColumnsSubmit,
  toggleFullscreen,
  loading,
  total,
  pageable,
  sort,
  handleChangeSort,
  filterData,
  clearFilterOne,
  clearAllClientFilter,
  actualFilterData,
}: any) => {
  const gridTotalAmount = (props: any) => {
    let grandTotal = "0.00";
    if (result && result.length > 0) {
      grandTotal = result
        .map((o: any) => o.OneTimeCharge)
        .reduce((a: number, c: number) => {
          return a + c;
        });
    }
    return (
      <>
        <td style={{ fontSize: 13 }}>
          <tr>
            {" "}
            {props.field === "OneTimeCharge" &&
              "Total:" +
                " " +
                "$" +
                (result && result[0]?.OverallOneTimeCharge
                  ? result[0]?.OverallOneTimeCharge
                  : "0.00")}{" "}
          </tr>

          <tr>
            {props.field === "OneTimeCharge" &&
              "Grand Total:" + " " + "$" + parseFloat(grandTotal).toFixed(2)}
          </tr>
        </td>
      </>
    );
  };
  return (
    <>
      {loading ? (
        <>
          <BuzopsLoader type="list" />
        </>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              {filterData.map((el: any, id: any, nextEle: any) => (
                <>
                  <h2
                    style={{
                      fontSize: "17px",
                      fontWeight: "bold",

                      borderRadius: "25px",
                      float: "left",
                      paddingTop: "7px",
                      color: "#626469",
                    }}
                  >
                    {el.DisplayName}{" "}
                    <span style={{ fontSize: "17px", fontWeight: "lighter" }}>
                      {(el.Operator === "eq" && "=") ||
                        (el.Operator === "neq" && "<>") ||
                        (el.Operator === "gte" && ">=") ||
                        (el.Operator === "gt" && ">") ||
                        (el.Operator === "lte" && "<=") ||
                        (el.Operator === "lt" && "<") ||
                        (el.Operator === "doesnotcontain" &&
                          "Does NOT Contains") ||
                        (el.Operator === "startswith" && "Starts With") ||
                        (el.Operator === "contains" && "Contains") ||
                        (el.Operator === "endswith" && "Ends With") ||
                        el.Operator}{" "}
                    </span>
                    {el.Value}{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#888888",
                        paddingRight: "0.6rem",
                        paddingLeft: "0.5rem",
                      }}
                      onClick={() => clearFilterOne(el, id)}
                    >
                      X{" "}
                    </span>
                  </h2>
                  {filterData.length > 1 && filterData.length !== id + 1 ? (
                    <span style={{ margin: "6px" }}>
                      {" "}
                      {el.DisplayName === nextEle[id + 1].DisplayName
                        ? nextEle[id + 1].Logic
                        : "and"}{" "}
                    </span>
                  ) : null}
                </>
              ))}

              {filterData.length > 0 ? (
                <div style={{ display: "inline-block" }}>
                  <button
                    style={{
                      backgroundColor: "#008db9",
                      borderRadius: "20px",
                      width: "90px",
                      color: "#fff",
                      border: "none",
                      fontSize: "17px",
                      padding: "6px 7px",
                    }}
                    onClick={clearAllClientFilter}
                  >
                    Clear all
                  </button>
                </div>
              ) : null}
            </div>
            <div className="pb-4 sales-header-search">
              <div className="sales-download-header">
                <button
                  title="Download"
                  className="mr-3 sales-download-button"
                  onClick={excelExport}
                >
                  <li className="k-icon k-i-download"></li>
                </button>
              </div>
              <div className="sales-fullscreen-header">
                <button
                  title="Fullscreen"
                  className="sales-fullscreen-button"
                  onClick={toggleFullscreen}
                >
                  <li className="k-icon k-i-full-screen"></li>
                </button>
              </div>
            </div>
          </div>
          <div>
            <Grid
              data={orderBy(result, sort)}
              onDataStateChange={dataStateChange}
              sortable={true}
              sort={sort}
              onSortChange={handleChangeSort}
              pageSize={dataState.take}
              resizable={true}
              total={total}
              pageable={pageable}
              skip={dataState.skip}
            >
              {stateGridColumns.map((column: any, idx: any) => {
                if (column.show) {
                  if (column.field == "Year") {
                    return (
                      <Column
                        width={column.width}
                        key={idx}
                        field={column.field}
                        title={column.title}
                        cell={(props) => dataCell(props, column)}
                      />
                    );
                  } else {
                    return (
                      <Column
                        width={column.width}
                        key={idx}
                        field={column.field}
                        title={column.title}
                        filter={column.filter}
                        cell={(props) => dataCell(props, column)}
                        footerCell={gridTotalAmount}
                        columnMenu={(props) => {
                          var modifiedFilters =
                            actualFilterData?.filters?.length > 0
                              ? actualFilterData
                              : undefined;

                          props = { ...props, filter: modifiedFilters };

                          return (
                            <SalesCustomColumnMenu
                              {...props}
                              columns={stateGridColumns}
                              onColumnsSubmit={onColumnsSubmit}
                            />
                          );
                        }}
                      />
                    );
                  }
                }
              })}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

const GridClientWiseFullScreenView = ({ viewDataClientWise }: any) => {
  return <div className="sales-fullview">{viewDataClientWise}</div>;
};

const SalesClientWiseGridTableFullScreen = ({
  excelExport,
  result,
  stateGridColumnsClientWise,
  dataState,
  dataStateChange,
  onColumnsSubmitClientWise,
  toggleClientWiseFullscreen,
  loading,
  total,
  pageable,
  sort,
  handleChangeSort,
  filterData,
  clearFilterOne,
  clearAllClientFilter,
  actualFilterData,
}: any) => {
  return (
    <>
      {loading ? (
        <>
          <BuzopsLoader type="list" />
        </>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              {filterData.map((el: any, id: any, nextEle: any) => (
                <>
                  <h2
                    style={{
                      fontSize: "17px",
                      fontWeight: "bold",

                      borderRadius: "25px",
                      float: "left",
                      paddingTop: "7px",
                      color: "#626469",
                    }}
                  >
                    {el.DisplayName}{" "}
                    <span style={{ fontSize: "17px", fontWeight: "lighter" }}>
                      {(el.Operator === "eq" && "=") ||
                        (el.Operator === "neq" && "<>") ||
                        (el.Operator === "gte" && ">=") ||
                        (el.Operator === "gt" && ">") ||
                        (el.Operator === "lte" && "<=") ||
                        (el.Operator === "lt" && "<") ||
                        (el.Operator === "doesnotcontain" &&
                          "Does NOT Contains") ||
                        (el.Operator === "startswith" && "Starts With") ||
                        (el.Operator === "contains" && "Contains") ||
                        (el.Operator === "endswith" && "Ends With") ||
                        el.Operator}{" "}
                    </span>
                    {el.Value}{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#888888",
                        paddingRight: "0.6rem",
                        paddingLeft: "0.5rem",
                      }}
                      onClick={() => clearFilterOne(el, id)}
                    >
                      X{" "}
                    </span>
                  </h2>
                  {filterData.length > 1 && filterData.length !== id + 1 ? (
                    <span style={{ margin: "6px" }}>
                      {" "}
                      {el.DisplayName === nextEle[id + 1].DisplayName
                        ? nextEle[id + 1].Logic
                        : "and"}{" "}
                    </span>
                  ) : null}
                </>
              ))}

              {filterData.length > 0 ? (
                <div style={{ display: "inline-block" }}>
                  <button
                    style={{
                      backgroundColor: "#008db9",
                      borderRadius: "20px",
                      width: "90px",
                      color: "#fff",
                      border: "none",
                      fontSize: "17px",
                      padding: "6px 7px",
                    }}
                    onClick={clearAllClientFilter}
                  >
                    Clear all
                  </button>
                </div>
              ) : null}
            </div>
            <div className="pb-4 sales-header-search">
              <div className="sales-download-header">
                <button
                  title="Download"
                  className="mr-3 sales-download-button"
                  onClick={excelExport}
                >
                  <li className="k-icon k-i-download"></li>
                </button>
              </div>
              <div className="sales-fullscreen-header">
                <button
                  title="Fullscreen"
                  className="sales-fullscreen-button"
                  onClick={toggleClientWiseFullscreen}
                >
                  <li className="k-icon k-i-full-screen"></li>
                </button>
              </div>
            </div>
          </div>
          <div>
            <Grid
              data={orderBy(result, sort)}
              onDataStateChange={dataStateChange}
              sortable={true}
              sort={sort}
              onSortChange={handleChangeSort}
              pageSize={dataState.take}
              resizable={true}
              total={total}
              skip={dataState.skip}
              pageable={pageable}
            >
              {stateGridColumnsClientWise.map((column: any, idx: any) => {
                if (column.show) {
                  if (column.field == "SignedUpDate") {
                    return (
                      <Column
                        width={column.width}
                        key={idx}
                        field={column.field}
                        title={column.title}
                        cell={(props) => dataCell(props, column)}
                      />
                    );
                  } else {
                    return (
                      <Column
                        width={column.width}
                        key={idx}
                        field={column.field}
                        title={column.title}
                        filter={column.filter}
                        cell={(props) => dataCell(props, column)}
                        columnMenu={(props) => {
                          var modifiedFilters =
                            actualFilterData?.filters?.length > 0
                              ? actualFilterData
                              : undefined;

                          props = { ...props, filter: modifiedFilters };

                          return (
                            <SalesCustomColumnMenu
                              {...props}
                              columns={stateGridColumnsClientWise}
                              onColumnsSubmitClientWise={
                                onColumnsSubmitClientWise
                              }
                            />
                          );
                        }}
                      />
                    );
                  }
                }
              })}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

const overAllGridColumns = [
  {
    title: "Date",
    field: "Year",
    show: true,
    filter: "text",
    width: "560px",
    type: "dateMonth",
  },
  {
    title: "Amount ($)",
    field: "OneTimeCharge",
    show: true,
    filter: "numeric",
    format: "{0:c}",
    width: "560px",
    type: "amount",
  },
];

const gridColumns = [
  {
    title: "Client Name",
    field: "MemberName",
    show: true,
    filter: "text",
    width: "200px",
    type: "text",
  },
  {
    title: "Membership Name",
    field: "Name",
    show: true,
    filter: "text",
    width: "200px",
    type: "text",
  },
  {
    title: "Sales Person",
    field: "SalesPerson",
    show: true,
    filter: "text",
    width: "200px",
    type: "text",
  },
  {
    title: "Amount ($)",
    field: "OneTimeCharge",
    show: true,
    width: "200px",
    type: "amount",
    filter: "numeric",
    format: "{0:c}",
  },
  {
    title: "Signed Up Date",
    field: "SignedUpDate",
    show: true,
    filter: "text",
    width: "200px",
    type: "date",
    format: "{0:d}",
  },
];
const initialSort = [
  {
    field: "Year",
  },
];

const SalesByOneTimeCharge = (props: any) => {
  const { push } = useHistory();
  const [sort, setSort] = React.useState(initialSort);

  const [selectedValue, setSelectedValue] = React.useState("Over All");

  const [salesFullscreen, setSalesFullscreen] = React.useState(false);
  const [salesClientWiseFullScreen, setSalesClientWiseFullScreen] =
    React.useState(false);

  const [chartOriginalData, setChartOriginalData] = useState<any>({});

  const [loading, setLoading] = React.useState(true);
  const [chartLoading, setChartLoading] = React.useState(true);
  const pageSize = 10;
  const [originalTableData, setOriginalTableData] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [dataState, setDataState] = React.useState<any>({
    take: pageSize,
    skip: 0,
  });
  const [isPageable, setIsPageable] = useState({});
  const [excelData, setExcelData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [actualFilterData, setActualFilterData] = useState<any>([]);

  const createDataState = (data: any, dataState: any) => {
    let pagerSettings = {
      buttonCount: 5,
      info: true,
      type: "numeric",
      pageSizes: true,
      previousNext: true,
    };
    return {
      result: data,
      dataState: dataState,
      total: totalItems,
      pageable: pagerSettings,
    };
  };

  const [fromDateToDate, setFromDateToDate] = useState({
    from: moment().startOf("year").startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const fetchSalesByOneTimeCharge = async (
    selectedValue: any,
    IsChartRendering: any,
    dataState: any,
    isPageNumber: boolean,
    SortDirection: string,
    SortBy: string,
    FromDate: string,
    ToDate: string,
    forExcel: boolean,
    filterArrayData: any
  ) => {
    if (!forExcel) {
      setLoading(true);
    }
    const service = new ReportsService();
    if (!isPageNumber) {
      dataState.skip = 0;
    }
    const oneTimeChargePayload = {
      IncludeChrunRateExcluded: true,
      StatusDescription: "",
      ReportType: "All",
      HirecharyLevel: selectedValue === "Over All" ? 0 : 0,
      GridHirecharyLevel: selectedValue === "Client Wise" ? 1 : 0,
      IsChartRendering,
      AuditType: "All",
      EntityId: 0,
      AccountUpdaterDuration: "",
      TotalAmount: 0,
      RecurringType: "None",
      MemberPlanStatus: "None",
      EFTPaymentType: 0,
      RecurrenceTypeDescription: "",
      IncludeFirstOccurenceOnly: true,
      IncludeNextEFT: true,
      IncludeDateRanges: true,
      AuditDate: "2022-06-16T11:32:30.430Z",
      IncludePaymentType: 0,
      EntityType: 5,
      SortDirection: SortDirection,
      SortBy: SortBy,
      PageSize: forExcel ? totalItems : dataState.take,
      PageNumber: dataState.skip === 0 ? 0 : dataState.skip / dataState.take,
      TotalItems: 0,

      IsExportRequest: false,
      DataModel: "",
      Logic: "",
      Field: "",
      CanEmailToClient: false,
      SearchKeyword: "",
      FromDate: FromDate,
      Filters: filterArrayData ? filterArrayData : "",

      ToDate: ToDate,
      IncludeInActive: true,
      ClubId: 0,
      ClubInternalId: "00000000-0000-0000-0000-000000000000",
      UserAssociateId: 0,
      UserMemberId: 0,
      UserId: 0,
      Subscription: "None",
      IsCreateDateFilter: true,
      AsMembershipEnabled: true,
      AsTrainingEnabled: true,
      SerializedRequested: "",
      EditorTicket: "00000000-0000-0000-0000-000000000000",
      IsDashboard: true,
      IsHistory: true,
      ClubMode: "None",
      IsAutoSearch: true,
      ExcludeDefault: true,
    };

    const oneTimeChargeResponceResult = await service.getSalesReport(
      {
        ...oneTimeChargePayload,
      },
      "sales"
    );
    if (IsChartRendering && !forExcel) {
      setChartOriginalData(oneTimeChargeResponceResult);
      setChartLoading(false);
    } else if (!IsChartRendering && !forExcel) {
      // api
      const data = { data: oneTimeChargeResponceResult?.Items }; //response.Items
      const totalrecords = oneTimeChargeResponceResult?.TotalItems; //response.TotalItems
      let initialState = createDataState(data.data, {
        take: dataState.take,
        skip: dataState.skip,
      });
      setOriginalTableData(data);
      setTableData(initialState.result);
      setDataState(initialState?.dataState);
      setIsPageable(initialState?.pageable);
      setTotalItems(totalrecords);
      setLoading(false);
    }
    if (forExcel) {
      setExcelData(oneTimeChargeResponceResult.Items);
    }
  };

  const [stateGridColumns, setStateGridColumns] =
    React.useState<any>(overAllGridColumns);

  const [stateGridColumnsClientWise, setStateGridColumnsClientWise] =
    React.useState<any>(gridColumns);

  const dataStateChange = (event: any) => {
    let updatedState = createDataState(
      originalTableData?.data,
      event.dataState
    );

    setTableData(updatedState.result);
    setDataState(updatedState?.dataState);
    let actualFilterValue = event?.dataState?.filter;

    setActualFilterData(actualFilterValue);

    let filterArrayData: any = [];
    var eventFilterData = event?.dataState?.filter
      ? event?.dataState?.filter?.filters.map((item: any) =>
          item?.filters.map(
            (fil: { field: any; operator: any; value: any }) => {
              const dataTypeValue =
                typeof fil.value === "string" ? "string" : "number";
              const numberValue = dataTypeValue === "string" ? 0 : fil.value;
              const filterOperators =
                (fil.operator === "eq" && "=") ||
                (fil.operator === "neq" && "<>") ||
                (fil.operator === "gte" && ">=") ||
                (fil.operator === "gt" && ">") ||
                (fil.operator === "lte" && "<=") ||
                (fil.operator === "lt" && "<") ||
                fil.operator;

              var data = {
                Field: fil.field,
                Operator: fil.operator,
                Logic: item.logic,
                FieldDataType: dataTypeValue,
                Condition: item.logic,
                Filter: filterOperators,
                Column: fil.field,
                DataType: dataTypeValue,
                NumberValue: numberValue,
                Value: fil.value,
                DropDownValue: "",
                DateValue: "",
                DisplayName: "",
              };

              stateGridColumnsClientWise.map((el: any) => {
                stateGridColumns.map((item: any) => {
                  if (fil.field === el.field) {
                    data.DisplayName = el.title;
                  } else if (fil.field === item.field) {
                    data.DisplayName = item.title;
                  }
                });
              });
              filterArrayData.push(data);
              return data;
            }
          )
        )
      : filterData;
    for (let i = 0; i < filterArrayData.length - 1; i++) {
      if (
        filterArrayData[i].DisplayName === filterArrayData[i + 1].DisplayName
      ) {
        filterArrayData[i].Condition = "and";
        filterArrayData[i].Logic = "and";
      }
    }
    setFilterData(filterArrayData);
    fetchSalesByOneTimeCharge(
      selectedValue,
      false,
      updatedState?.dataState,
      true,
      "desc",
      "Year",
      fromDateToDate.from,
      fromDateToDate.to,
      false,
      filterArrayData
    );
  };

  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };

  const onColumnsSubmitClientWise = (columnsState: any) => {
    setStateGridColumnsClientWise(columnsState);
  };

  const _exporter = useRef(null);

  const excelExport = async () => {
    const IsChartRendering = selectedValue === "Over All" ? true : false;

    await fetchSalesByOneTimeCharge(
      selectedValue,
      IsChartRendering,
      dataState,
      false,
      "desc",
      "Year",
      fromDateToDate.from,
      fromDateToDate.to,
      true,
      null
    );

    save(_exporter);
    setExcelData([]);
  };
  function save(component: any) {
    const options = component.current.workbookOptions();
    const rows = options.sheets[0].rows;
    let altIdx = 0;
    let format = "YYYY-MM-DD";
    if (selectedValue === "Over All") {
      format = "YYYY-MM";
    }
    rows.forEach((row: any) => {
      if (row.type === "header") {
        row.cells.forEach((cell: any) => {
          cell.background = "#FFFFFF";
          cell.color = "#000000";
          cell.borderRight = "1";
        });
      }
      if (row.type === "data") {
        row.cells.forEach((cell: any) => {
          if (cell.format === undefined) {
            const value = moment(cell.value).format(format);
            cell.wrap = true;
            cell.value = value;
          }
        });

        altIdx++;
      }
    });
    component.current.save(options);
  }

  const onCompareClick = () => {
    push({
      pathname: `/app/reports/${uuid}/sales/compare`,
      state: { path: props?.location?.pathname },
    });
  };

  const handleOverAllClick = (props: any) => {
    setSelectedValue("Over All");
    fetchSalesByOneTimeCharge(
      "Over All",
      false,
      dataState,
      false,
      "desc",
      "Year",
      fromDateToDate.from,
      fromDateToDate.to,
      false,
      null
    );
    setFilterData([]);
  };

  const handleClientwiseClick = () => {
    setSelectedValue("Client Wise");
    fetchSalesByOneTimeCharge(
      "Client Wise",
      false,
      dataState,
      false,
      "desc",
      "Year",
      fromDateToDate.from,
      fromDateToDate.to,
      false,
      null
    );
    setFilterData([]);
  };
  const handleChangeSort = (e: any) => {
    setSort(e.sort);
    fetchSalesByOneTimeCharge(
      selectedValue,
      false,
      dataState,
      false,
      e.sort.length !== 0 ? e.sort[0].dir : "desc",
      e.sort.length !== 0 ? e.sort[0].field : "Year",
      fromDateToDate.from,
      fromDateToDate.to,
      false,
      null
    );
  };

  useEffect(() => {
    fetchSalesByOneTimeCharge(
      selectedValue,
      false,
      dataState,
      false,
      "desc",
      "Year",
      fromDateToDate.from,
      fromDateToDate.to,
      false,
      null
    );
    fetchSalesByOneTimeCharge(
      selectedValue,
      true,
      dataState,
      false,
      "desc",
      "Year",
      fromDateToDate.from,
      fromDateToDate.to,
      false,
      null
    );
  }, [fromDateToDate]);
  const clearFilterOne = (el: any, id: any) => {
    const updates = filterData.filter((item: any, idx: any) => idx !== id);
    setFilterData(updates);
    fetchSalesByOneTimeCharge(
      selectedValue,
      false,
      dataState,
      true,
      "desc",
      "Year",
      fromDateToDate.from,
      fromDateToDate.to,
      false,
      updates
    );
    updates.length === 0 && setActualFilterData(undefined);
  };
  const clearAllClientFilter = () => {
    setFilterData([]);
    fetchSalesByOneTimeCharge(
      selectedValue,
      false,
      dataState,
      true,
      "desc",
      "Year",
      fromDateToDate.from,
      fromDateToDate.to,
      false,
      null
    );
    setActualFilterData(undefined);
  };
  const uuid = props?.match?.params?.uuid;

  return (
    <div>
      <ReportsCommonFilters
        {...props}
        history={props.history}
        location={props.location}
        setFromDateToDate={setFromDateToDate}
      />
      <div className="mt-4 sales-main-container">
        <div className="d-flex justify-content-end sales-close-icon">
          <li
            className="k-icon k-i-close"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
        <OneTimeChargeChart
          chartOriginalData={chartOriginalData}
          chartLoading={chartLoading}
        />
        <div className="sales-radio-button-headers">
          <RadioButton
            name="group1"
            value="Over All"
            checked={selectedValue === "Over All"}
            label="Over All"
            onClick={handleOverAllClick}
          />
          <RadioButton
            name="group2"
            value="Client Wise"
            checked={selectedValue === "Client Wise"}
            label="Client Wise"
            onClick={handleClientwiseClick}
          />
          <button className="sales-compare" onClick={onCompareClick}>
            Compare
          </button>
        </div>
        <div className="sales-border-header">
          {selectedValue === "Over All" ? (
            <>
              {salesFullscreen && (
                <FullScreenView
                  viewData={
                    <SalesGridTableFullScreen
                      toggleFullscreen={() => {
                        setSalesFullscreen(!salesFullscreen);
                        var style = document.createElement("style");
                        style.innerHTML = `body::-webkit-scrollbar {display: block;}`;
                        document.head.appendChild(style);
                      }}
                      excelExport={excelExport}
                      onColumnsSubmit={onColumnsSubmit}
                      result={tableData}
                      stateGridColumns={stateGridColumns}
                      dataState={dataState}
                      loading={loading}
                      dataStateChange={dataStateChange}
                      total={totalItems}
                      pageable={isPageable}
                      sort={sort}
                      handleChangeSort={handleChangeSort}
                      filterData={filterData}
                      clearFilterOne={clearFilterOne}
                      clearAllClientFilter={clearAllClientFilter}
                      actualFilterData={actualFilterData}
                    />
                  }
                />
              )}
              <SalesGridTableFullScreen
                toggleFullscreen={() => {
                  setSalesFullscreen(!salesFullscreen);
                  var style = document.createElement("style");
                  style.innerHTML = `body::-webkit-scrollbar {display: none;}`;
                  document.head.appendChild(style);
                }}
                excelExport={excelExport}
                result={tableData}
                stateGridColumns={stateGridColumns}
                onColumnsSubmit={onColumnsSubmit}
                dataState={dataState}
                loading={loading}
                dataStateChange={dataStateChange}
                total={totalItems}
                pageable={isPageable}
                sort={sort}
                handleChangeSort={handleChangeSort}
                filterData={filterData}
                clearFilterOne={clearFilterOne}
                clearAllClientFilter={clearAllClientFilter}
                actualFilterData={actualFilterData}
              />

              <ExcelExport
                data={chartOriginalData?.Items}
                fileName="salesbyOneTimeChargeOverAll.xlsx"
                ref={_exporter}
              >
                <ExcelExportColumn
                  field="Year"
                  title="Date"
                  width={80}
                  cellOptions={{ wrap: true }}
                />
                <ExcelExportColumn
                  field="OneTimeCharge"
                  title="Amount"
                  cellOptions={{
                    format: "$#,##0.00",
                    wrap: true,
                  }}
                  width={80}
                />
              </ExcelExport>
            </>
          ) : (
            <>
              <div>
                {salesClientWiseFullScreen && (
                  <GridClientWiseFullScreenView
                    viewDataClientWise={
                      <SalesClientWiseGridTableFullScreen
                        toggleClientWiseFullscreen={() => {
                          setSalesClientWiseFullScreen(
                            !salesClientWiseFullScreen
                          );
                          var style = document.createElement("style");
                          style.innerHTML = `body::-webkit-scrollbar {display: block;}`;
                          document.head.appendChild(style);
                        }}
                        excelExport={excelExport}
                        result={tableData}
                        stateGridColumnsClientWise={stateGridColumnsClientWise}
                        onColumnsSubmitClientWise={onColumnsSubmitClientWise}
                        dataState={dataState}
                        loading={loading}
                        dataStateChange={dataStateChange}
                        total={totalItems}
                        pageable={isPageable}
                        sort={sort}
                        handleChangeSort={handleChangeSort}
                        filterData={filterData}
                        clearFilterOne={clearFilterOne}
                        clearAllClientFilter={clearAllClientFilter}
                        actualFilterData={actualFilterData}
                      />
                    }
                  />
                )}
                <SalesClientWiseGridTableFullScreen
                  toggleClientWiseFullscreen={() => {
                    setSalesClientWiseFullScreen(!salesClientWiseFullScreen);
                    var style = document.createElement("style");
                    style.innerHTML = `body::-webkit-scrollbar {display: none;}`;
                    document.head.appendChild(style);
                  }}
                  excelExport={excelExport}
                  result={tableData}
                  dataState={dataState}
                  loading={loading}
                  dataStateChange={dataStateChange}
                  stateGridColumnsClientWise={stateGridColumnsClientWise}
                  onColumnsSubmitClientWise={onColumnsSubmitClientWise}
                  total={totalItems}
                  pageable={isPageable}
                  sort={sort}
                  handleChangeSort={handleChangeSort}
                  filterData={filterData}
                  clearFilterOne={clearFilterOne}
                  clearAllClientFilter={clearAllClientFilter}
                  actualFilterData={actualFilterData}
                />
              </div>
              <ExcelExport
                data={excelData}
                fileName="salesbyOneTimeChargeClientWise.xlsx"
                ref={_exporter}
              >
                <ExcelExportColumn
                  field="MemberName"
                  title="Client Name"
                  cellOptions={{ format: "text", wrap: true }}
                />
                <ExcelExportColumn
                  field="Name"
                  title="Membership Name "
                  cellOptions={{ format: "text", wrap: true }}
                />
                <ExcelExportColumn
                  field="SalesPerson"
                  title="Sales Person"
                  cellOptions={{ format: "text", wrap: true }}
                />
                <ExcelExportColumn
                  field="OneTimeCharge"
                  title="Amount"
                  width={60}
                  cellOptions={{
                    format: "$#,##0.00",
                    wrap: true,
                  }}
                />
                <ExcelExportColumn
                  field="SignedUpDate"
                  title="Signed Up Date"
                />
              </ExcelExport>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesByOneTimeCharge;
