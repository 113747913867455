import { TokenResult } from "@square/web-payments-sdk-types";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { GetLocalStore } from "utils/storage";
import "./SquarePayments.scss";

const SquarePaymentForm = (props: any) => {
  const userDetails = GetLocalStore("tenantUserSession");
  const appId = userDetails?.ChargehubSquarePaymentProfile?.MerchantId;
  const locationId = userDetails?.ChargehubSquarePaymentProfile?.Attributes == '' ? '' : JSON.parse(userDetails?.ChargehubSquarePaymentProfile?.Attributes)?.SelectedLocationId;

  async function handlePaymentMethodSubmission(token: TokenResult, verifiedBuyer: any) {

    try {
      const cardTypeName = token?.details?.card?.brand;
      const userDetails = GetLocalStore("userDetails");
      const usermemberId =
        userDetails?.UserSession?.UserMemberId || props?.UserMemberId;
      let CardtypeId;
      switch (cardTypeName) {
        case "VISA":
          CardtypeId = 6;
          break;
        case "MASTERCARD":
          CardtypeId = 5;
          break;
        case "JCB":
          CardtypeId = 4;
          break;
        case "DISCOVER":
          CardtypeId = 3;
          break;
        case "DINERSCLUB":
          CardtypeId = 2;
          break;
        case "AMERICANEXPRESS":
          CardtypeId = 1;
          break;
        case "AMERICAN_EXPRESS":
          CardtypeId = 1;
          break;
      }
      const expiry_year = token?.details?.card?.expYear.toString().substr(-2);
      let expiry_month = token?.details?.card?.expMonth.toString();
      expiry_month = "0" + expiry_month?.substr(-2);
      const expiryMonthYear = `${expiry_month}${expiry_year}`;
      const cardIntialValues = {
        MaskedCCNumber: token.token,
        CardTypeId: CardtypeId,
        ExpiryMonthYear: expiryMonthYear,
        ExpiryMonth: token?.details?.card?.expMonth,
        ExpiryYear: token?.details?.card?.expYear,
        ZipCode: GetLocalStore("postalCodeValue"),
        PaymentType: "Card",
        UserMemberId: usermemberId,
      };
      props.handleCardSubmit(cardIntialValues);
    } catch (e: any) {
      console.error(e.message);
    }
  }
  return (
    <>
      <PaymentForm
        applicationId={appId}
        cardTokenizeResponseReceived={(token, verifiedBuyer) => {
          console.log("token:", token);
          console.log("verifiedBuyer:", verifiedBuyer);
          handlePaymentMethodSubmission(token, verifiedBuyer);
        }}
        locationId={locationId}
        formProps={{
          className: "square-payments-form-v2"
        }}
      >
        <CreditCard  buttonProps={{
          isLoading: props?.cardSubmitBtnLoading,
          css:{
            background: '#008db9',
            padding:'0.5rem'
          }
        }}>Continue</CreditCard>
      </PaymentForm>
    </>
  );
};

export default SquarePaymentForm;
