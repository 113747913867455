import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import React from "react";
import zerodollar from "../../../Images/zerodollar.png";
import bzImages from "../../../Images";
import { FaRegClock } from "react-icons/fa";

const PaymentModeIconDisplay = (props: any) => {
    console.log(props,"PROPSPSPS")
    const nonPaidLabel = props?.Attributes?.NonPaidLabel?.replace(/ /g, "");
   const iconSrc = nonPaidLabel === 'NonPaidGroup' ? bzImages.notPaid : nonPaidLabel === 'consultation' ? bzImages.consultation : zerodollar;
   console.log(props)
    if (props?.dataItem?.IsPaid) {
        switch (props?.dataItem?.PaymentMode) {
            case 1:
                return (  <BuzopsTooltip
                    html={
                      <span
                        className="bz-badge"
                        title={`Redeemed via package (${props?.dataItem?.PackageName})`}
                      >
                        <i className="fas fa-box-open" aria-hidden="true"></i>
                      </span>
                    }
                  />)
            case 5:
                return (<BuzopsTooltip
                    html={
                      <span className="bz-badge" title={"Redeemed via Complimentary"}>
                        <i className="fas fa-gift"></i>
                      </span>
                    }
                  />)
            case 3:
                return ( <BuzopsTooltip
                    html={
                      <span
                        title={props?.Attributes?.NonPaidLabel || "Non Paid"}
                        className="bz-badge bz-zerodollar-icon"
                      >
                        <img src={iconSrc} alt="" />
                      </span>
                    }
                  />)
            default:
                return (<BuzopsTooltip
                    html={
                      <span title={"Paid"} className="bz-badge">
                        <i className="fas fa-dollar-sign"></i>
                      </span>
                    }
                  />)
        }

    } else {
        if(props?.dataItem?.DropInPrice===0){
            return (<BuzopsTooltip
                html={
                    <span title={props?.Attributes?.NonPaidLabel || "Non Paid"} className="bz-badge bz-zerodollar-icon mr-1">
                        <img src={iconSrc} alt="" />
                    </span>
                }
            />)
        }
        switch (props?.dataItem?.PaymentMode) {
            case 1:
                return (<BuzopsTooltip
                    html={
                        <span
                            className="bz-badge"
                            title={`Redeemed via package (${props?.dataItem?.PackageName})`}
                        >
                            <i
                                className="fas fa-box-open"
                                aria-hidden="true"
                            ></i>
                        </span>
                    }
                />)
            case 5:
                return (<BuzopsTooltip
                    html={
                        <span
                            className="bz-badge"
                            title={"Redeemed via Complimentary"}
                        >
                            <i className="fas fa-gift"></i>
                        </span>
                    }
                />)
            default:
                return (
                  <BuzopsTooltip
                    position={"top"}
                    toolTipClass={"phone-tooltip"}
                    html={
                      <span
                        className="payment-required-warning bz-cursor-pointer mx-2"
                        title={
                          props?.dataItem?.IsWaitList
                            ? "Your spot is reserved, and payment will be collected once your booking is confirmed."
                            : "Payment Pending"
                        }
                      >
                        {props?.dataItem?.IsWaitList ? (
                          <FaRegClock
                            style={{ marginRight: "0.4rem", color: "#008db9" }}
                          />
                        ) : (
                          <i className="fas fa-exclamation-triangle"></i>
                        )}
                      </span>
                    }
                  />
                );
        }
      
    }
};

export default PaymentModeIconDisplay;
