import React, { useState } from "react";
import moment from "moment";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { orderBy } from "@progress/kendo-data-query";
import { ReportsService } from "../../../../../services/reports/index.service";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Popup } from "@progress/kendo-react-popup";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { SalesCustomColumnMenu } from "../../salesCustomColumnMenu";
import { CurrencyFormat } from "utils";

const dataCell = (props: any, column: any) => {
  if (column.type === "amount") {
    return <td>{CurrencyFormat(props.dataItem[props.field])}</td>;
  } else if (column.type === "date") {
    return <td>{moment(props.dataItem[props.field]).format("YYYY-MM-DD")}</td>;
  } else if (column.type === "dateMonth") {
    return <td>{moment(props.dataItem[props.field]).format("YYYY-MM")}</td>;
  } else if (column.type === "dateTime") {
    return (
      <td>{moment(props.dataItem[props.field]).format("YYYY-MM-DD HH:MM")}</td>
    );
  }
  return <td>{props.dataItem[props.field]}</td>;
};

const gridColumns = [
  {
    title: "Date",
    field: "Year",
    show: true,
    filter: "text",
    type: "dateMonth",
  },
  {
    title: "Amount ($)",
    field: "Class",
    show: true,
    filter: "numeric",
    type: "amount",
  },
];
const initialSort = [
  {
    field: "Year",
  },
];
const CompareGroupServicesGridTwo = ({ ...props }: any) => {
  const rangeSizes = [
    "Today",
    "This Week",
    "This Month",
    "This Year",
    "This Quarter",
    "Yesterday",
    "Previous Week",
    "Previous Month",
    "Previous Year",
    "Previous Quarter",
    "Custom",
  ];

  const anchorData = React.useRef(null);
  const [showData, setShowData] = React.useState(false);
  const [stateGridColumns, setStateGridColumns] = React.useState(gridColumns);
  //Sort
  const [sort, setSort] = React.useState(initialSort);
  const pageSize = 10;
  const [originalTableData, setOriginalTableData] = useState<any>([]);
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [dataState, setDataState] = React.useState({
    take: pageSize,
    skip: 0,
  });
  const [isPageable, setIsPageable] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [actualFilterData, setActualFilterData] = useState<any>([]);
  const [isDateRange, setIsDateRange] = useState<any>({});

  let fromDate = isDateRange?.from;
  let toDate = isDateRange?.to;

  const createDataState = (data: any, dataState: any) => {
    let pagerSettings = {
      buttonCount: 5,
      info: true,
      type: "numeric",
      pageSizes: true,
      previousNext: true,
    };
    return {
      result: data,
      dataState: dataState,
      total: totalItems,
      pageable: pagerSettings,
    };
  };

  const fetchSalesByGroupServices = async (
    dataState: any,
    isPageNumber: any,
    SortDirection: any,
    SortBy: any,
    FromDate: any,
    ToDate: any,
    filterArrayData: any
  ) => {
    const service = new ReportsService();
    if (!isPageNumber) {
      dataState.skip = 0;
    }
    const groupServicesPayload = {
      IncludeChrunRateExcluded: true,
      StatusDescription: "",
      ReportType: "All",
      HirecharyLevel: 0,
      GridHirecharyLevel: 0,
      IsChartRendering: false,
      AuditType: "All",
      EntityId: 0,
      AccountUpdaterDuration: "",
      TotalAmount: 0,
      RecurringType: "None",
      MemberPlanStatus: "None",
      EFTPaymentType: 0,
      RecurrenceTypeDescription: "",
      IncludeFirstOccurenceOnly: true,
      IncludeNextEFT: true,
      IncludeDateRanges: true,
      AuditDate: "2022-06-16T11:32:30.430Z",
      IncludePaymentType: 0,
      EntityType: 6,
      SortDirection: SortDirection,
      SortBy: SortBy,
      PageSize: dataState.take,
      PageNumber: dataState.skip === 0 ? 0 : dataState.skip / dataState.take,
      TotalItems: 0,

      IsExportRequest: false,
      DataModel: "",
      Logic: "",
      Field: "",
      CanEmailToClient: false,
      SearchKeyword: "",
      FromDate: FromDate,
      Filters: filterArrayData ? filterArrayData : "",

      ToDate: ToDate,
      IncludeInActive: true,
      ClubId: 0,
      ClubInternalId: "00000000-0000-0000-0000-000000000000",
      UserAssociateId: 0,
      UserMemberId: 0,
      UserId: 0,
      Subscription: "None",
      IsCreateDateFilter: true,
      AsMembershipEnabled: true,
      AsTrainingEnabled: true,
      SerializedRequested: "",
      EditorTicket: "00000000-0000-0000-0000-000000000000",
      IsDashboard: true,
      IsHistory: true,
      ClubMode: "None",
      IsAutoSearch: true,
      ExcludeDefault: true,
    };

    const compareGroupServicesResponce = await service.getSalesReport(
      groupServicesPayload,
      "sales"
    );

    // api
    const data = { data: compareGroupServicesResponce?.Items }; //response.Items
    const totalrecords = compareGroupServicesResponce?.TotalItems; //response.TotalItems
    let initialState = createDataState(data.data, {
      take: dataState.take,
      skip: dataState.skip,
    });
    setOriginalTableData(data);
    setTableData(initialState.result);
    setDataState(initialState?.dataState);
    setIsPageable(initialState?.pageable);
    setTotalItems(totalrecords);
  };

  const dataStateChange = (event: any) => {
    let updatedState = createDataState(
      originalTableData?.data,
      event.dataState
    );

    setTableData(updatedState.result);
    setDataState(updatedState?.dataState);
    let actualFilterValue = event?.dataState?.filter;

    setActualFilterData(actualFilterValue);

    let filterArrayData: any = [];
    var eventFilterData = event?.dataState?.filter
      ? event?.dataState?.filter?.filters.map((item: any) =>
          item?.filters.map(
            (fil: { field: any; operator: any; value: any }) => {
              const dataTypeValue =
                typeof fil.value === "string" ? "string" : "number";
              const numberValue = dataTypeValue === "string" ? 0 : fil.value;
              const filterOperators =
                (fil.operator === "eq" && "=") ||
                (fil.operator === "neq" && "<>") ||
                (fil.operator === "gte" && ">=") ||
                (fil.operator === "gt" && ">") ||
                (fil.operator === "lte" && "<=") ||
                (fil.operator === "lt" && "<") ||
                fil.operator;
              let logicData;
              event?.dataState?.filter?.filters.forEach((item: any) => {
                logicData = item.logic;
              });

              var data = {
                Field: fil.field,
                Operator: fil.operator,
                Logic: logicData,
                FieldDataType: dataTypeValue,
                Condition: logicData,
                Filter: filterOperators,
                Column: fil.field,
                DataType: dataTypeValue,
                NumberValue: numberValue,
                Value: fil.value,
                DropDownValue: "",
                DateValue: "",
                DisplayName: "",
              };

              stateGridColumns.map((item: any) => {
                if (fil.field === item.field) {
                  data.DisplayName = item.title;
                }
              });
              filterArrayData.push(data);
              return data;
            }
          )
        )
      : filterData;
    for (let i = 0; i < filterArrayData.length - 1; i++) {
      if (
        filterArrayData[i].DisplayName === filterArrayData[i + 1].DisplayName
      ) {
        filterArrayData[i].Condition = "and";
        filterArrayData[i].Logic = "and";
      }
    }
    setFilterData(filterArrayData);
    fetchSalesByGroupServices(
      updatedState?.dataState,
      false,
      "desc",
      "Year",
      fromDate,
      toDate,
      filterArrayData
    );
  };

  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };

  const handleDateRangeTwo = (event: any) => {
    if (event.target.value === "Today") {
      const now = moment();
      const date = now.format("YYYY-MM-DD");

      dateValuesTwo({ from: date, to: date });
    } else if (event.target.value === "This Week") {
      const now = moment();

      const date = now.clone().startOf("isoWeek").format("YYYY-MM-DD");
      const date1 = now.format("YYYY-MM-DD");

      dateValuesTwo({ from: date, to: date1 });
    } else if (event.target.value === "This Month") {
      const now = moment();
      const date = now.format("YYYY-MM-01");
      const date1 = now.format("YYYY-MM-DD");
      dateValuesTwo({ from: date, to: date1 });
    } else if (event.target.value === "This Quarter") {
      const now = moment().subtract(2, "month");
      const date = now.format("YYYY-MM-01");
      const now1 = moment();
      const date1 = now1.format("YYYY-MM-DD");
      dateValuesTwo({ from: date, to: date1 });
    } else if (event.target.value === "This Year") {
      const now = moment();
      const date = now.format("YYYY-01-01");
      const date1 = now.format("YYYY-12-31");
      dateValuesTwo({ from: date, to: date1 });
    } else if (event.target.value === "Yesterday") {
      const now = moment().subtract(1, "day");
      const date = now.format("YYYY-MM-DD");

      const now1 = moment();
      const date1 = now1.format("YYYY-MM-DD");
      dateValuesTwo({ from: date, to: date1 });
    } else if (event.target.value === "Previous Week") {
      const now = moment().subtract(6, "day");
      const date = now.clone().startOf("isoWeek").format("YYYY-MM-DD");
      const now1 = moment();
      const date1 = now1.format("YYYY-MM-DD");
      dateValuesTwo({ from: date, to: date1 });
    } else if (event.target.value === "Previous Month") {
      const moment = require("moment");
      const now = moment().subtract(1, "month");
      const date = now.format("YYYY-MM-01");
      const now1 = moment();
      const date1 = now1.format("YYYY-MM-DD");
      dateValuesTwo({ from: date, to: date1 });
    } else if (event.target.value === "Previous Quarter") {
      const moment = require("moment");
      const now = moment().subtract(5, "month");
      const date = now.format("YYYY-MM-01");
      const now1 = moment();
      const date1 = now1.format("YYYY-MM-DD");
      dateValuesTwo({ from: date, to: date1 });
    } else if (event.target.value === "Previous Year") {
      const moment = require("moment");
      const now = moment().subtract(1, "year");
      const date = now.format("YYYY-01-01");
      const now1 = moment().subtract(1, "year");
      const date1 = now1.format("YYYY-12-31");
      dateValuesTwo({ from: date, to: date1 });
    } else if (event?.target?.value === "Custom") {
      setShowData(!showData);
    }
  };
  const handleDateChangeTwo = (event: any) => {
    const now = moment(event.value.start);
    const date = now.format("YYYY-MM-DD");

    const now1 = moment(event.value.end);
    const date1 = now1.format("YYYY-MM-DD");

    if (date1 !== "Invalid date") {
      dateValuesTwo({ from: date, to: date1 });
    }
  };

  const handleChangeSort = (e: any) => {
    setSort(e.sort);
    fetchSalesByGroupServices(
      dataState,
      false,
      e.sort.length !== 0 ? e.sort[0].dir : "desc",
      e.sort.length !== 0 ? e.sort[0].field : "Year",
      fromDate,
      toDate,
      null
    );
  };

  const dateValuesTwo = (props: any) => {
    setIsDateRange(props);
    fetchSalesByGroupServices(
      dataState,
      false,
      "desc",
      "Year",
      props.from,
      props.to,
      null
    );
  };
  //Filter
  const clearFilterOne = (el: any, id: any) => {
    const updates = filterData.filter((item: any, idx: any) => idx !== id);
    setFilterData(updates);

    fetchSalesByGroupServices(
      dataState,
      false,
      "desc",
      "Year",
      fromDate,
      toDate,
      updates
    );
    updates.length === 0 && setActualFilterData(undefined);
  };
  const clearAllClientFilter = () => {
    setFilterData([]);
    fetchSalesByGroupServices(
      dataState,
      false,
      "desc",
      "Year",
      fromDate,
      toDate,
      null
    );
    setActualFilterData(undefined);
  };

  return (
    <span style={{ display: "inline-block" }}>
      <div className="sales-compare-main-dropdown">
        <div>
          <DropDownList
            data={rangeSizes}
            onChange={handleDateRangeTwo}
            defaultItem="Select..."
          />
          <span ref={anchorData}></span>
          <Popup
            anchor={anchorData.current}
            show={showData}
            popupClass={"popup-content"}
          >
            <div className="example-wrapper">
              <DateRangePicker onChange={handleDateChangeTwo} />
              <li
                className="k-icon k-i-close ml-3"
                onClick={() => {
                  setShowData(!showData);
                }}
              ></li>
            </div>
          </Popup>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        {filterData.map((el: any, id: any, nextEle: any) => (
          <>
            <h2
              style={{
                fontSize: "17px",
                fontWeight: "bold",

                borderRadius: "25px",
                float: "left",
                paddingTop: "7px",
                color: "#626469",
              }}
            >
              {el.DisplayName}{" "}
              <span style={{ fontSize: "17px", fontWeight: "lighter" }}>
                {(el.Operator === "eq" && "=") ||
                  (el.Operator === "neq" && "<>") ||
                  (el.Operator === "gte" && ">=") ||
                  (el.Operator === "gt" && ">") ||
                  (el.Operator === "lte" && "<=") ||
                  (el.Operator === "lt" && "<") ||
                  (el.Operator === "doesnotcontain" && "Does NOT Contains") ||
                  (el.Operator === "startswith" && "Starts With") ||
                  (el.Operator === "contains" && "Contains") ||
                  (el.Operator === "endswith" && "Ends With") ||
                  el.Operator}{" "}
              </span>
              {el.Value}{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "#888888",
                  paddingRight: "0.6rem",
                  paddingLeft: "0.5rem",
                }}
                onClick={() => clearFilterOne(el, id)}
              >
                X{" "}
              </span>
            </h2>
            {filterData.length > 1 && filterData.length !== id + 1 ? (
              <span style={{ margin: "6px" }}>
                {" "}
                {el.DisplayName === nextEle[id + 1].DisplayName
                  ? nextEle[id + 1].Logic
                  : "and"}{" "}
              </span>
            ) : null}
          </>
        ))}
        {filterData.length > 0 ? (
          <div style={{ display: "inline-block" }}>
            <button
              style={{
                backgroundColor: "#008db9",
                borderRadius: "20px",
                width: "90px",
                color: "#fff",
                border: "none",
                fontSize: "17px",
                padding: "6px 7px",
              }}
              onClick={clearAllClientFilter}
            >
              Clear all
            </button>
          </div>
        ) : null}
      </div>
      <div className="row sales-compare-grid">
        <div className="col-11">
          <Grid
            data={orderBy(tableData, sort)}
            onDataStateChange={dataStateChange}
            sortable={true}
            sort={sort}
            onSortChange={handleChangeSort}
            pageSize={dataState.take}
            resizable={true}
            total={totalItems}
            pageable={isPageable}
            skip={dataState.skip}
          >
            {stateGridColumns.map((column: any, idx: any) => {
              if (column.show) {
                if (column.field == "Year") {
                  return (
                    <Column
                      width={column.width}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      cell={(props) => dataCell(props, column)}
                    />
                  );
                } else {
                  return (
                    <Column
                      width={column.width}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      cell={(props) => dataCell(props, column)}
                      columnMenu={(props) => {
                        var modifiedFilters =
                          actualFilterData?.filters?.length > 0
                            ? actualFilterData
                            : undefined;

                        props = { ...props, filter: modifiedFilters };

                        return (
                          <SalesCustomColumnMenu
                            {...props}
                            columns={stateGridColumns}
                            onColumnsSubmit={onColumnsSubmit}
                          />
                        );
                      }}
                    />
                  );
                }
              }
            })}
          </Grid>
        </div>
      </div>
    </span>
  );
};
export default CompareGroupServicesGridTwo;
