import BuzopsReportGrid from "generic-components/BuzopsReportGrid";
import React, { memo, useEffect, useState } from "react";
import { ClassService } from "services/class/index.service";
import { ClassStatus } from "utils/form-utils";

const DetailedGroupServices = (props: any) => {
  const [pageInfo, setPageInfo] = useState({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });

  const gridColumns = [
    {
      title: "Name",
      field: "Name",
      show: true,
      filter: "text",
    },
    {
      title: "Status",
      field: "ScheduleStatus",
      show: true,
      filter: "text",
      customValCell: (val: any) =>
        ClassStatus.find((val1) => val1.id === val)?.text,
    },
  ];
  const [stateGridColumns, setStateGridColumns] = React.useState(gridColumns);
  const service = new ClassService();
  useEffect(() => {
    fetchGroupClassesData();
  }, []);

  const fetchGroupClassesData = async (pageData?: any) => {
    let req = {
      ClassSessionId: props?.data?.ClassSessionId,
      SortDirection: "ascending",
      SortBy: "Name",
      PageSize: pageData?.take || 10,
      PageNumber: pageData?.skip / pageData?.take || 0,
    };

    let getData = await service.getClientsForSession({
      ...req,
    });

    setPageInfo((prev: any) => {
      prev.data = getData.Items;
      prev.pageNumber = req.PageNumber;
      prev.total = getData.TotalItems;
      if (pageData?.take) {
        prev.skip = pageData.skip;
        prev.take = pageData.take;
      }
      return { ...prev };
    });
  };
  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };
  return (
    <>
      <BuzopsReportGrid
        fileName="DetailedGroupClasses.xlsx"
        result={pageInfo.data}
        dataState={{ ...pageInfo }}
        dataStateChange={(e: any) => fetchGroupClassesData(e.dataState)}
        stateGridColumns={stateGridColumns}
        pageSize={10}
        setStateGridColumns={(data: any) => setStateGridColumns(data)}
        onColumnsSubmit={onColumnsSubmit}
      />
    </>
  );
};

export default memo(DetailedGroupServices);
