import React, { useState, useEffect } from "react";
import { ReportsService } from "services/reports/index.service";
import moment from "moment";
import { OnsiteLogColumns } from "../Utilities/constants";

const useOnsiteLog = () => {
  const service = new ReportsService();
  const [pageInfo, setPageInfo] = useState({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });

  const [stateGridColumns, setStateGridColumns] =
    React.useState(OnsiteLogColumns);

  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    initializeData();
    fetchAccessLog();
  }, []);

  const [fromDateToDate, setFromDateToDate] = useState({
    from: moment().startOf("year").startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const initializeData = async () => {
    const users = await service.getUsersForAccessLog();
    const clubs = await service.getClubsForAccessLog();
    console.log(users, clubs);
  };

  const fetchAccessLog = async (pageData?: any, forExcel = false) => {
    const req = {
      pageSize: forExcel ? pageInfo.total : pageData?.dataState?.take || 10,
      pageNumber: forExcel
        ? 0
        : pageData?.dataState?.skip
        ? pageData?.dataState.skip / pageData?.dataState.take
        : 0,
      SortBy: pageData?.sort?.length > 0 ? pageData.sort[0].field : "Occured",
      SortDirection: pageData?.sort?.length > 0 ? pageData.sort[0].dir : "asc",
      FromDate: pageData?.fromDate || fromDateToDate.from,
      ToDate: pageData?.toDate || fromDateToDate.to,
    };
    const getData = await service.getAccessLog(req);
    console.log(getData);
    if (forExcel) {
      setExcelData(getData.Item.Result);
    } else {
      setPageInfo((prev) => {
        prev.data = getData.Item.Result;
        prev.pageNumber = req.pageNumber;
        prev.total = getData.Item.TotalItems;
        if (pageData?.dataState?.take) {
          prev.skip = pageData?.dataState?.skip;
          prev.take = pageData?.dataState?.take;
        }
        return { ...prev };
      });
    }
  };
  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };

  const onCellClick = (cellData: any) => {
    console.log(cellData);
  };

  useEffect(() => {
    fetchAccessLog({
      fromDate: fromDateToDate.from,
      toDate: fromDateToDate.to,
    });
  }, [fromDateToDate]);

  return {
    onColumnsSubmit,
    onCellClick,
    pageInfo,
    stateGridColumns,
    excelData,
    setExcelData,
    setStateGridColumns,
    setFromDateToDate,
  };
};

export default useOnsiteLog;
