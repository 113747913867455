import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { getTenantAddress } from "utils";
import { ScheduleModeOptionEnum } from "utils/form-utils";
import {
  MdPhone,
  MdOutlineVideocam,
  MdOutlineLocationOn,
} from "react-icons/md";
import { urlValidator } from "validators/validator";

const findOtherMeetingLink = (otherMeetingLink: string) => {
  const splittedOtherMeetingLink = otherMeetingLink?.split(" ");
  let meetingLink = otherMeetingLink;
  if(splittedOtherMeetingLink.length > 1){
    for (const val of splittedOtherMeetingLink) {
      if(!urlValidator(val)){
        meetingLink = val;
        break;
      }
    }
    if(!meetingLink.includes('http')){
      return `//${meetingLink}`;
    }
    return meetingLink;
  }else {
    return meetingLink;
  }
}
export const LocationForBooking = (props: any) => {
  const Attributes =
    props?.Attributes &&
    props?.Attributes !== "" &&
    typeof props?.Attributes === "string"
      ? JSON.parse(props?.Attributes)
      : props?.Attributes;
  const { linkEnable = false, clientPhnNum = "" } = props;
  const show = props?.showDetails;
  const showToolTip = props?.showToolTip;
  const description = "Details will be shared once the appointment is booked";

  const meetingLink = () => {
    return Attributes?.ScheduleModeOption ===
    ScheduleModeOptionEnum["VirtualMeetingOtherMeeting"]
      ? findOtherMeetingLink(Attributes?.OtherMeeting)
      : `${props?.VirtualMeetingLink}`
  }
  switch (Attributes?.ScheduleMode) {
    case 2:
      return (
        <>
          <span className="location-icon-blk">
            <BuzopsTooltip
            position={"right"}
              html={
                <span
                  title={
                    showToolTip
                      ? Attributes?.ScheduleModeOption ===
                        ScheduleModeOptionEnum["VirtualMeetingOtherMeeting"]
                        ? `${Attributes?.OtherMeeting}`
                        : `${props?.VirtualMeetingLink || "Buzops Meet"}`
                      : description
                  }
                  style={{ marginLeft: "0.35rem", marginRight: "0.25rem" }}
                  className={"align-baseline"}
                >
                  {show &&
                  linkEnable &&
                  !urlValidator(
                    Attributes?.OtherMeeting || props?.VirtualMeetingLink
                  ) ? (
                    <a
                      style={{ color: "#008db9" }}
                      className={"justify-content"}
                      rel="noreferrer"
                      href={
                        meetingLink()
                      }
                      target="_blank"
                    >
                      <MdOutlineVideocam
                        aria-hidden="true"
                        style={{ color: props?.confirmation ? "" : "#008db9" }}
                        className={"bz-md-video"}
                      />
                    </a>
                  ) : (
                    <a>
                      <MdOutlineVideocam
                        aria-hidden="true"
                        style={{ color: props?.confirmation ? "" : "#008db9" }}
                        className={"bz-md-video"}
                      />
                    </a>
                  )}
                </span>
              }
            />
          </span>
          <span className="pl-1">
            {show ? (
              <>
                {props?.VirtualMeetingLink || Attributes?.OtherMeeting ? (
                  linkEnable &&
                  !urlValidator(
                    Attributes?.OtherMeeting || props?.VirtualMeetingLink
                  ) ? (
                    <a
                      style={{ color: "#008db9" }}
                      className={"justify-content"}
                      rel="noreferrer"
                      href={
                        meetingLink()
                      }
                      target="_blank"
                    >
                      {Attributes?.ScheduleModeOption ===
                      ScheduleModeOptionEnum["VirtualMeetingOtherMeeting"]
                        ? `${Attributes?.OtherMeeting}`
                        : `${props?.VirtualMeetingLink}`}
                    </a>
                  ) : Attributes?.ScheduleModeOption ===
                    ScheduleModeOptionEnum["VirtualMeetingOtherMeeting"] ? (
                    `${Attributes?.OtherMeeting}`
                  ) : (
                    `${props?.VirtualMeetingLink}`
                  )
                ) : (
                  "Virtual Meeting"
                )}
              </>
            ) : (
              <>{"Virtual Meet"}</>
            )}
          </span>
        </>
      );
    case 4:
      return (
        <>
         <div className="d-flex align-items-start">
         <span className="location-icon-blk" style={{marginTop:'0rem'}}>
            <BuzopsTooltip
            position={"right"}
              html={
                <MdPhone
                  title={
                    showToolTip
                      ? Attributes?.ScheduleModeOption ===
                        ScheduleModeOptionEnum["PhoneCallMyInviteeShouldCall"]
                        ? `My Invitee should call me`
                        : `I will call my Invitee`
                      : description
                  }
                  style={{ color: props?.confirmation ? "" : "#008db9" }}
                  className={"bz-md-phone"}
                />
              }
            />
          </span>
          <span className="pl-1">
            Phone Call
            <br />
              {show && (
                <small>
                  {Attributes?.ScheduleModeOption === ScheduleModeOptionEnum["PhoneCallMyInviteeShouldCall"]
                    ? `My Invitee should call me at ${Attributes?.TraineePhoneNumber}`
                    : (Attributes?.InviteePhoneNumber || clientPhnNum)
                      ? `I will call my Invitee at ${Attributes?.InviteePhoneNumber ? Attributes?.InviteePhoneNumber : clientPhnNum
                      }`
                      : ``}
                </small>
              )}
          </span>
         </div>
        </>
      );

    case 8:
      return (
        <>
          <span className="location-icon-blk">
            {" "}
            <BuzopsTooltip
            position={"right"}
              html={
                <MdOutlineLocationOn
                  title={
                    showToolTip
                      ? Attributes?.ScheduleModeOption ===
                        ScheduleModeOptionEnum["InPersonMeetingOtherAddres"]
                        ? `${Attributes?.OtherAddress}`
                        : `${getTenantAddress()}`
                      : description
                  }
                  style={{ color: props?.confirmation ? "" : "#008db9" }}
                  className={"bz-md-location"}
                />
              }
            />
          </span>
          {show ? (
            <span className="pl-1">
              {Attributes?.ScheduleModeOption === ScheduleModeOptionEnum["InPersonMeetingOtherAddres"]
                ? `${Attributes?.OtherAddress}`
                : `${getTenantAddress()}`}
            </span>
          ) : (
            'In-person Meeting'
          )}
        </>
      );

    default:
      break;
  }
  return <></>;
};
const Location = (props: any) => {
  const Attributes =
    props?.Attributes &&
    props?.Attributes !== "" &&
    typeof props?.Attributes === "string"
      ? JSON.parse(props?.Attributes)
      : props?.Attributes;
  const show = props?.showDetails;
  const { position = "right" } = props;
  const description = "Details will be shared once the appointment is booked";

  const titleForMeetings = () => {
    if(show) {
      if(Attributes?.ScheduleModeOption === ScheduleModeOptionEnum["VirtualMeetingOtherMeeting"]){
        return Attributes?.OtherMeeting
      }else {
        return props?.VirtualMeetingLink || "Buzops Meet"
      }
    }else {
      return description
    }
  }

  
  const meetingLink = () => {
    return Attributes?.ScheduleModeOption ===
    ScheduleModeOptionEnum["VirtualMeetingOtherMeeting"]
      ? findOtherMeetingLink(Attributes?.OtherMeeting)
      : `${props?.VirtualMeetingLink}`
  }
  const meetingContent = () => {
    if(show && !urlValidator(Attributes?.OtherMeeting || props?.VirtualMeetingLink)) {
      return (<a
        style={{ color: "#008db9" }}
        className={"justify-content"}
        rel="noreferrer"
        href={
          meetingLink()
        }
        target="_blank"
      >
        <MdOutlineVideocam
          aria-hidden="true"
          style={{ color: "#008db9" }}
          className={"bz-md-video"}
        />
      </a>)
    }else {
      return (<a>
        <MdOutlineVideocam
          aria-hidden="true"
          style={{ color: "#008db9" }}
          className={"bz-md-video"}
        />
      </a>)
    }
  }
  switch (Attributes?.ScheduleMode) {
    case 2:
      return (
        <>
          <BuzopsTooltip
            position={position}
            html={
              <span
                title={
                  titleForMeetings()
                }
                style={{ marginLeft: "0.35rem", marginRight: "0.25rem" }}
                className={"align-baseline"}
              >
                {meetingContent()}
              </span>
            }
          />
        </>
      );
    case 4:
      return (
        <>
          <BuzopsTooltip
          toolTipClass="phone-tooltip"
            position={position}
            html={
              <span
                title={
                  show
                    ? Attributes?.ScheduleModeOption ===
                      ScheduleModeOptionEnum["PhoneCallMyInviteeShouldCall"]
                      ? `My Invitee should call me  ${
                          Attributes?.TraineePhoneNumber ? `at ${Attributes?.TraineePhoneNumber}` :""
                        }`
                      : `I will call my Invitee ${
                          Attributes?.InviteePhoneNumber ? `at ${Attributes?.InviteePhoneNumber}` : props?.clientPhnNum ? `at ${props?.clientPhnNum}` : ""
                        }`
                    : description
                }
                style={{ marginLeft: "0.35rem", marginRight: "0.25rem" }}
                className={"align-baseline"}
              >
                <a>
                  <MdPhone
                    aria-hidden="true"
                    style={{ color: "#008db9" }}
                    className={"bz-md-phone"}
                  />
                </a>
              </span>
            }
          />
        </>
      );

    case 8:
      return (
        <>
          <BuzopsTooltip
            position={position}
            toolTipClass="phone-tooltip"
            html={
              <span
                title={
                  show
                    ? Attributes?.ScheduleModeOption ===
                      ScheduleModeOptionEnum["InPersonMeetingOtherAddres"]
                      ? `${Attributes?.OtherAddress}`
                      : `${getTenantAddress()}`
                    : description
                }
                style={{ marginLeft: "0.35rem" }}
                className={"align-baseline"}
              >
                <a>
                  <MdOutlineLocationOn
                    aria-hidden="true"
                    style={{ color: "#008db9" }}
                    className={"bz-md-location"}
                  />
                </a>
              </span>
            }
          />
        </>
      );

    default:
      break;
  }
  return <></>;
};

export default Location;
