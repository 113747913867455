import ChartAndGrid from "components/reports/Generic/ChartAndGrid";

const SalesByPlanGeneric = (props: any) => {
  const overAllGridColumns = [
    {
      title: "Date",
      field: "Year",
      show: true,
      filter: "text",
      width: "560px",
      type: "dateMonth",
    },
    {
      title: "Amount ($)",
      field: "Memberships",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      width: "560px",
      type: "amount",
    },
  ];

  const gridColumns = [
    {
      title: "Client Name",
      field: "MemberName",
      show: true,
      width: "200px",
      filter: "text",
      type: "text",
    },
    {
      title: "Membership Name",
      field: "Name",
      show: true,
      width: "200px",
      filter: "text",
      type: "text",
    },
    {
      title: "Sales Person",
      field: "SalesPerson",
      show: true,
      filter: "text",
      width: "200px",
      type: "text",
    },
    {
      title: "Amount ($)",
      field: "Memberships",
      show: true,
      width: "200px",
      type: "amount",
      filter: "numeric",
      // format: "{0:c}",
    },
    {
      title: "Signed Up Date",
      field: "SignedUpDate",
      show: true,
      filter: "text",
      width: "200px",
      type: "date",
      // format: "{0:d}",
    },
  ];
  const initialSort = [
    {
      field: "Year",
    },
  ];
  return (
    <ChartAndGrid
      title={"Sales By Membership"}
      initialSort={initialSort}
      gridColumns={gridColumns}
      overAllGridColumns={overAllGridColumns}
      {...props}
      type={"sales"}
    />
  );
};

export default SalesByPlanGeneric;
