import React, { useEffect, useState } from "react";
import mammoth from "mammoth";

interface AgreementPdfViewerProps {
  base64String: string;
}

const AgreementPdfViewer: React.FC<AgreementPdfViewerProps> = ({
  base64String,
}) => {
  const [fileUrl, setFileUrl] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");
  const [docxHtml, setDocxHtml] = useState<string>("");

  useEffect(() => {
    // Function to convert base64 to blob
    const base64ToBlob = (base64: string, mime: string): Blob => {
      const cleanedBase64 = base64.replace(/[^A-Za-z0-9+/=]/g, "");
      const byteCharacters = atob(cleanedBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mime });
    };

    // Function to detect the MIME type from base64 string
    const detectMimeType = (base64: string): string => {
      const mimeMatch = base64.match(/^data:(.*?);base64,/);
      if (mimeMatch) {
        return mimeMatch[1];
      }

      const signatures: { [key: string]: string } = {
        JVBERi0: "application/pdf",
        UEsFBg:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        iVBORw0KGgo: "image/png",
        "/9j/": "image/jpeg",
      };

      for (const signature in signatures) {
        if (base64.startsWith(signature)) {
          return signatures[signature];
        }
      }

      return "application/octet-stream"; // default binary type if unknown
    };

    // Clean the base64 string
    const cleanBase64 = (base64: string): string => {
      if (base64.includes(",")) {
        return base64.split(",")[1];
      }
      return base64;
    };

    try {
      const cleanBase64String = cleanBase64(base64String);
      const mimeType = detectMimeType(base64String);
      setFileType(mimeType);

      // Create blob URL
      const blob = base64ToBlob(cleanBase64String, mimeType);
      const url = URL.createObjectURL(blob);
      setFileUrl(url);

      // Clean up the URL object when the component is unmounted
      return () => URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to process base64 string:", error);
    }
  }, [base64String]);

  useEffect(() => {
    if (
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
      fileUrl
    ) {
      fetch(fileUrl)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          return mammoth.convertToHtml({ arrayBuffer });
        })
        .then((result) => {
          setDocxHtml(result.value);
        })
        .catch((error) => console.error("Failed to render DOCX:", error));
    }
  }, [fileType, fileUrl]);

  return (
    <div>
      {fileUrl ? (
        fileType === "application/pdf" ? (
          <iframe
            src={fileUrl}
            title="PDF Viewer"
            width="100%"
            height="600px"
          />
        ) : fileType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
          <>
            <a
              href={fileUrl}
              download="document.docx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Document
            </a>
            <div
              style={{
                width: "100%",
                height: "600px",
                overflow: "auto",
                border: "1px solid #ccc",
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: docxHtml }} />
            </div>
          </>
        ) : fileType === "image/png" || fileType === "image/jpeg" ? (
          <>
            <a
              href={fileUrl}
              download={`image.${fileType.split('/')[1]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Image
            </a>
            <img src={fileUrl} alt="Document" width="100%" />
          </>
        ) : (
          <p>Unsupported file type</p>
        )
      ) : (
        <div
          className="agreement-view"
          id={"agreement-view"}
          dangerouslySetInnerHTML={{
            __html: base64String,
          }}
        />
      )}
    </div>
  );
};

export default AgreementPdfViewer;
