import ChartAndGrid from "components/reports/Generic/ChartAndGrid";

const RevenueGeneric = (props: any) => {
  const overAllGridColumns = [
    {
      title: "Date",
      field: "Year",
      show: true,
      filter: "text",
      // width: "560px",
      type: "dateMonth",
    },
    {
      title: "Gross",
      field: "Gross",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Net",
      field: "Net",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Refunds",
      field: "Refund",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Returns",
      field: "Return",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Charge Backs",
      field: "ChargeBack",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Void",
      field: "Void",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Waive",
      field: "Waive",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Complimentary",
      field: "Complimentary",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
  ];

  const gridColumns = [
    {
      title: "Client Name",
      field: "MemberName",
      show: true,
      // width: "200px",
      filter: "text",
      type: "text",
    },
    {
      title: "Type Of Service",
      field: "ServiceDetails",
      show: true,
      // width: "200px",
      filter: "text",
      type: "text",
    },
    {
      title: "Service Name",
      field: "ServiceName",
      show: true,
      filter: "text",
      // width: "200px",
      type: "text",
    },
    {
      title: "Date",
      field: "Year",
      show: true,
      // filter: "text",
      // width: "200px",
      type: "dateMonth",
    },
    {
      title: "Gross",
      field: "gross",
      show: true,
      filter: "numeric",
      // width: "200px",
      type: "amount",
      // format: "{0:d}",
    },
    {
      title: "Net",
      field: "net",
      show: true,
      filter: "numeric",
      // width: "200px",
      type: "amount",
      // format: "{0:d}",
    },
    {
      title: "Refunds",
      field: "Refund",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Returns",
      field: "Return",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Charge Backs",
      field: "ChargeBack",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Void",
      field: "Void",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Waive",
      field: "Waive",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
    {
      title: "Complimentary",
      field: "Complimentary",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      // width: "560px",
      type: "amount",
    },
  ];
  const initialSort = [
    {
      field: "Year",
    },
  ];
  return (
    <ChartAndGrid
      title={"Revenue"}
      type={"revenue"}
      initialSort={initialSort}
      gridColumns={gridColumns}
      overAllGridColumns={overAllGridColumns}
      {...props}
      dropDownValues={[
        "Select All",
        "Gross",
        "Net",
        "Refund",
        "Return (ACH)",
        "Charge Back (Credit card)",
        "Void",
        "Waive",
        "Complimentary",
      ]}
    />
  );
};

export default RevenueGeneric;
