import React from "react";
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { IMemberAccessProps } from "../Utils/interfaces";
import useMemberCredentials from "../Container/MemberCredentials.hook";
import { Button } from "@progress/kendo-react-buttons";
import {
  OpenPathCredentialStatusType,
  OpenpathCrendentialOptions,
  OpenpathCrendentialType,
} from "../Utils/constants";
import { ListView } from "@progress/kendo-react-listview";
import History from "./History";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import { GetLocalStore } from "utils/storage";

const MemberCredential: React.FunctionComponent<IMemberAccessProps> = (
  props
) => {
  const isAdminOrStaff = [1, 8].includes(
    GetLocalStore("CurrentApplicationType")
  );

  const memberCredential = useMemberCredentials({ ...props });
  const getStatusText = () => {
    let currentDate = new Date().getTime();

    if (
      memberCredential.getAccessCredentialStatus() ===
      OpenPathCredentialStatusType.Pending
    ) {
      const emailSentOnDate = new Date(
        props.OpenpathCredentialSetupEmailSentOn
      ).getTime();
      let dateCount = Math.round((currentDate - emailSentOnDate) / 86400000);

      return dateCount <= 0
        ? ": Sent earlier today"
        : `: Sent '${dateCount}' days ago`;
    } else if (
      memberCredential.getAccessCredentialStatus() ===
        OpenPathCredentialStatusType.NoAccess &&
      props.IsSuspended === false &&
      moment(moment(props.OpenpathCredentialStartDate).format("HH:mm:ss")) >
        moment(new Date())
    ) {
      return `: Access starts ${props.OpenpathCredentialStartDate}`;
    }
  };

  return (
    <>
      <div className={`bz-inhouse-group-events align-middle buz-online-list-view bz-groupServices-list-new group-serv-list bz-openPath-credintials ${!props.Email && "cursor-not-allowed"}`} title={!props.Email ? "User must have a valid email to assign access card" : ""}>
        <div className=" row justify-content-between mobile-onsite-checkins">
          <div className="col-md-4 col-sm-6">
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <div className="bz-provider-name">
                    <span className="k-label">Name</span>
                    <h6 className="mb-0 mb-sm-2">
                      <ClientNameWithMemberType
                        memberType={props.MemberType}
                        clientName={props.FullName}
                      />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-6">
            <div className="row mb-2 openpathflex">
              <div className="col-md-6 col-sm-12 credentialopenpath mt-1 mt-md-0">
                <div>
                  <DropDownList
                    defaultValue={memberCredential.selectedCredentialType}
                    textField="text"
                    dataItemKey="id"
                    label="Credential Type"
                    data={[
                      {
                        id: -1,
                        text: "Select",
                      },
                      ...OpenpathCrendentialOptions,
                    ]}
                    value={memberCredential.selectedCredentialType}
                    onChange={(e) => {
                      memberCredential.setSelectedCredentialType(
                        e.target.value
                      );
                      memberCredential.setError("");
                      memberCredential.setCardNumber("");
                    }}
                    disabled={
                      props.OpenpathCredentialType ||
                      props.CanDisableEditAndActivate
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-2 mt-sm-4-openpath">
                  <div className="bz-provider-name">
                    <span className="bz-provider text-muted">
                      Access Credential Status
                    </span>
                    <div>
                      <span
                        className={`bz-access-credintials-status ${memberCredential
                          .getAccessCredentialStatus()
                          .replace(/\s/g, "")
                          .toLowerCase()}`}
                      >
                        <img
                          src={memberCredential.getStatusICon(
                            memberCredential.getAccessCredentialStatus()
                          )}
                          alt="status"
                        />
                        {memberCredential.getAccessCredentialStatus()}
                        {getStatusText()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" row justify-content-between mobile-onsite-checkins openpath-credintials">
          <div className="col-md-4 col-sm-6 order-2 order-sm-1 mt-2 mt-sm-0">
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <MultiSelect
                    textField="text"
                    dataItemKey="id"
                    label="Select Group"
                    data={props.ConfiguredGroups?.map((group: any) => ({
                      id: group.Id,
                      text: group.Name,
                    }))}
                    value={memberCredential.groups}
                    onChange={(e) =>
                      memberCredential.onChangeGroupHandler(e.target.value)
                    }
                    disabled={
                      memberCredential?.getAccessCredentialStatus() ===
                        OpenPathCredentialStatusType.Suspended ||
                      props?.CanDisableEditAndActivate ||
                      memberCredential?.selectedCredentialType?.id === -1
                    }
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </div>
                {memberCredential.groupsError && (
                  <p className="k-form-error">{memberCredential.groupsError}</p>
                )}
                {isAdminOrStaff &&
                  memberCredential.getAccessCredentialStatus() !==
                    OpenPathCredentialStatusType.NoAccess && (
                    <Button
                      primary={true}
                      className="k-button-sm mt-2 mt-md-3 ml-0"
                      onClick={memberCredential.updateGroup}
                      disabled={!memberCredential.isGroupChanged}
                    >
                      Update Groups
                    </Button>
                  )}
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-6 order-1 order-sm-2">
            <div className="row">
              {memberCredential?.selectedCredentialType?.id ===
                OpenpathCrendentialType.CardWeigand && (
                <div className="col-md-6 bz-provider-name">
                  <div
                    className="d-flex align-items-center mb-2"
                    style={{ lineHeight: 1 }}
                  >
                    <span className="k-label bz-label-mb-0">Facility Code</span>
                    <BuzopsTooltip
                      position={"top"}
                      html={
                        <span
                          title={`A number between 0 to 255`}
                          className="bz-cursor-pointer"
                        >
                          <i
                            style={{ fontSize: "16px", marginLeft: "5px" }}
                            className="fa fa-info-circle"
                          ></i>
                        </span>
                      }
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      className="form-control k-textbox"
                      value={
                        memberCredential.facilityCode === -1
                          ? ""
                          : memberCredential.facilityCode
                      }
                      min={0}
                      max={255}
                      onChange={(e: any) => {
                        memberCredential.setFacilityCodeError("");
                        memberCredential.setFacilityCode(
                          Math.min(e.target.value || 0, 255)
                        );
                      }}
                      disabled={props.FacilityCode !== null}
                      style={{ maxWidth: "250px", width: "100%" }}
                    />
                    {memberCredential.facilityCodeError && (
                      <p className="k-form-error">
                        {memberCredential.facilityCodeError}
                      </p>
                    )}
                  </div>
                </div>
              )}
              {memberCredential.selectedCredentialType?.id !==
                OpenpathCrendentialType.Mobile &&
                memberCredential.selectedCredentialType?.id !== -1 && (
                  <div className="col-md-6 bz-provider-name mt-2 mt-md-0">
                    {memberCredential.selectedCredentialType?.id ===
                    OpenpathCrendentialType.CardWeigand ? (
                      <div
                        className="d-flex align-items-center mb-2"
                        style={{ lineHeight: 1 }}
                      >
                        <span className="k-label bz-label-mb-0">
                          Card Number
                        </span>
                        <BuzopsTooltip
                          position={"top"}
                          html={
                            <span
                              title={`A number between 0 to 65535`}
                              className="bz-cursor-pointer"
                            >
                              {memberCredential.selectedCredentialType?.id ===
                                OpenpathCrendentialType.CardWeigand && (
                                <i
                                  style={{
                                    fontSize: "16px",
                                    marginLeft: "5px",
                                  }}
                                  className="fa fa-info-circle"
                                ></i>
                              )}
                            </span>
                          }
                        />
                      </div>
                    ) : (
                      <span className="k-label">Card Number</span>
                    )}

                    <input
                      type={
                        memberCredential.selectedCredentialType?.id ===
                        OpenpathCrendentialType.CardWeigand
                          ? "number"
                          : "text"
                      }
                      className="form-control k-textbox"
                      value={memberCredential.cardNumber}
                      maxLength={
                        memberCredential.selectedCredentialType?.id ===
                        OpenpathCrendentialType.CardDESFire
                          ? 14
                          : 30
                      }
                      onChange={(e: any) => {
                        memberCredential.setError("");
                        memberCredential.selectedCredentialType?.id ===
                        OpenpathCrendentialType.CardWeigand
                          ? memberCredential.setCardNumber(
                              Math.min(e.target.value || 0, 65535).toString()
                            )
                          : memberCredential.setCardNumber(e.target.value);
                      }}
                      disabled={
                        props.CardNumber !== null || props.CardId !== null
                      }
                      style={{ maxWidth: "250px", width: "100%" }}
                    />
                    {memberCredential.error && (
                      <p className="k-form-error">{memberCredential.error}</p>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="row">
          {isAdminOrStaff && (
            <div className="col-12">
              <hr style={{ marginBottom: "0.75rem" }} />
            </div>
          )}

          <div
            className="col-12 bz-openPath-actions"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {
              !props?.Email &&
              <div className="mr-auto text-danger mb-3 mb-sm-0">
                User must have a valid email to assign access card.
              </div>
            }
            {isAdminOrStaff && (
              <div className="bz-checkins-actions-container">
                {memberCredential.getAccessCredentialStatus() ===
                  OpenPathCredentialStatusType.NoAccess && (
                  <Button
                    primary={true}
                    iconClass="fa fa-plus"
                    className="k-button-sm"
                    onClick={() => {
                      memberCredential.selectedCredentialType !== -1 &&
                        memberCredential.createOpenPathMobileCredential();
                    }}
                    disabled={
                      props.CanDisableEditAndActivate ||
                      memberCredential.selectedCredentialType?.id === -1
                    }
                  >
                    Assign
                  </Button>
                )}
                {memberCredential.getAccessCredentialStatus() ===
                  OpenPathCredentialStatusType.Pending && (
                  <Button
                    primary={true}
                    iconClass="fa fa-envelope"
                    className="k-button-sm"
                    onClick={memberCredential.resendSetupEmail}
                    disabled={props.CanDisableEditAndActivate}
                  >
                    Resend
                  </Button>
                )}

                {props.IsSuspended && (
                  <BuzopsTooltip
                    html={
                      <span
                        style={{
                          margin: "0rem 0.5rem",
                          color: "skyblue",
                          cursor:
                            memberCredential.getAccessCredentialStatus() ===
                              OpenPathCredentialStatusType.Suspended &&
                            props.CanDisableEditAndActivate === false
                              ? "pointer"
                              : "not-allowed",
                        }}
                        title="Activate Access"
                        className={`bz-cursor-pointer ${
                          memberCredential.getAccessCredentialStatus() ===
                            OpenPathCredentialStatusType.Suspended &&
                          props.CanDisableEditAndActivate === false
                            ? ""
                            : "disabled"
                        }`}
                        onClick={() =>
                          memberCredential.getAccessCredentialStatus() ===
                            OpenPathCredentialStatusType.Suspended &&
                          props.CanDisableEditAndActivate === false &&
                          memberCredential.updateMemberHardwareAccess()
                        }
                      >
                        <i
                          style={{ fontSize: "16px" }}
                          className="fa fa-check"
                        ></i>
                      </span>
                    }
                  />
                )}

                {!props.IsSuspended && (
                  <BuzopsTooltip
                    html={
                      <span
                        style={{
                          margin: "0rem 0.5rem",
                          color: "green",
                          cursor:
                            (memberCredential.getAccessCredentialStatus() ===
                              OpenPathCredentialStatusType.Active ||
                              memberCredential.getAccessCredentialStatus() ===
                                OpenPathCredentialStatusType.Pending) &&
                            props.CanDisableEditAndActivate === false
                              ? "pointer"
                              : "not-allowed",
                        }}
                        title="Access will be suspended temporarily"
                        className={`bz-cursor-pointer ${
                          (memberCredential.getAccessCredentialStatus() ===
                            OpenPathCredentialStatusType.Active ||
                            memberCredential.getAccessCredentialStatus() ===
                              OpenPathCredentialStatusType.Pending) &&
                          props.CanDisableEditAndActivate === false
                            ? ""
                            : "disabled"
                        }`}
                        onClick={() =>
                          (memberCredential.getAccessCredentialStatus() ===
                            OpenPathCredentialStatusType.Active ||
                            memberCredential.getAccessCredentialStatus() ===
                              OpenPathCredentialStatusType.Pending) &&
                          props.CanDisableEditAndActivate === false &&
                          memberCredential.updateMemberHardwareAccess()
                        }
                      >
                        <i
                          style={{ fontSize: "16px" }}
                          className="fa fa-stop-circle"
                        ></i>
                      </span>
                    }
                  />
                )}

                <BuzopsTooltip
                  html={
                    <span
                      style={{
                        margin: "0rem 0.5rem",
                        color: "crimson",
                        cursor:
                          memberCredential.getAccessCredentialStatus() !==
                            OpenPathCredentialStatusType.NoAccess &&
                          props.CanDisableRemoveAccess === false
                            ? "pointer"
                            : "not-allowed",
                      }}
                      title="Remove Access"
                      className={`bz-cursor-pointer ${
                        memberCredential.getAccessCredentialStatus() !==
                          OpenPathCredentialStatusType.NoAccess &&
                        props.CanDisableRemoveAccess === false
                          ? ""
                          : "disabled"
                      }`}
                      onClick={() =>
                        memberCredential.getAccessCredentialStatus() !==
                          OpenPathCredentialStatusType.NoAccess &&
                        props.CanDisableRemoveAccess === false &&
                        memberCredential.removeOpenPathMobileCredential()
                      }
                    >
                      <i className="fa fa-trash"></i>
                    </span>
                  }
                />
              </div>
            )}

            <div className="ml-3 mr-3 section-3 d-flex align-items-center">
              <span
                className="bz-cursor-pointer p-0 d-flex align-items-center"
                onClick={() =>
                  memberCredential.getAuditLogs(!memberCredential.showHistory)
                }
              >
                Audit &nbsp;
                <i
                  className={
                    memberCredential.showHistory
                      ? "fas fa-angle-up"
                      : "fas fa-angle-down"
                  }
                ></i>
              </span>
            </div>
          </div>
        </div>

        {memberCredential?.showHistory && (
          <>
            <div className="row">
              <div className="col-12">
                {memberCredential.auditLogs.length > 0 ? (
                  <ListView
                    item={History}
                    data={memberCredential.auditLogs || []}
                  />
                ) : (
                  <div>
                    <span>No Audit History</span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MemberCredential;
