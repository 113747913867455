import withNotification from "components/Hoc/withNotification";
import AccountDialog from "./AccountDialog";
import PaymentDropDown from "./PaymentDropDown";
import BuzopsLoader from "generic-components/BuzopsLoader";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { CurrencyFormat, checkIfUserCanAddBankAccount } from "utils";
import { Switch } from "@progress/kendo-react-inputs";
import { BiInfoCircle } from "react-icons/bi";
import bzImages from "Images";
import usePaymentMethod from "./PaymentMethod.hook";
import AddCreditsTopup from "./AddCreditsTopup";
import CreditActivity from "./CreditActivity";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import { FaExclamationTriangle } from "react-icons/fa";
import { useSelector } from "react-redux";

const PaymentMethod = (props: any) => {
  const {
    IsEnforced=false,IsCardEnforced=false,IsFormsEnforced=false
  } = useSelector((state: any) => state?.userConfiguration);
  const {
    enforcePPValue,
    handleChangeEnforce,
    showBankModal,
    cardSubmitBtnLoading,
    handleCardSubmit,
    bankFormValues,
    handleBankSubmit,
    paymentType,
    cardFormValues,
    isAdmin,
    paymentGatewayType,
    userConfiguration,
    cardIntialValues,
    bankIntialValues,
    setBankFormValues,
    setCardFormValues,
    setShowBankModal,
    intialValues,
    changePaymentProfile,
    paymentProfiles,
    confirmationShwon,
    confirmChangePayment,
    setConfirmationShown,
    btnLoading,
    paymentsLoading,
    setPaymentType,
    editBank,
    deleteBank,
    handleShowDialog,
    showDialog,
    handleCloseDialog,
    enforceBtnLoading,
    toggleDeleteDialog,
    showDeleteConfirm,
    handleDeletePaymentMethod,
    deleteBtnLoading,
  } = usePaymentMethod(props);

  const renderDeleteDialog = () => {
    return (
      <Dialog onClose={() => toggleDeleteDialog()}>
        <p className={"dialogContainer"}>
          Are you sure you want to delete this payment method?
        </p>
        <DialogActionsBar>
          <Button onClick={() => toggleDeleteDialog()} disabled={deleteBtnLoading}>
            {"No,keep it."}
          </Button>
          <BuzopsButton
            label={"Yes"}
            loading={deleteBtnLoading}
            disabled={deleteBtnLoading}
            onClick={() => deleteBank()}
          />
        </DialogActionsBar>
      </Dialog>
    );
  };
  if (showDialog?.activity) {
    return (
      <CreditActivity
        handleCloseDialog={handleCloseDialog}
        UserMemberId={props?.userData?.UserMemberId}
      />
    );
  }
  let walletAmt = 0;
  const findWallet = paymentProfiles?.find((i: any) => i?.CardTypeId === 8);
  if (findWallet) {
    walletAmt = findWallet?.Credit || 0;
  }
  if (enforceBtnLoading) {
    return <BuzopsLoader type="list" />;
  }
  return (
    <>
      <div className="bz-payment-method-grid">
        {!props?.staffPage && IsCardEnforced && IsEnforced ? (
          <div className="alert alert-warning fade show" role="alert">
            <span>
              {`Action Required: Payment Profile Creation No further actions can be performed until the Payment Profile is created.`}
            </span>
          </div>
        ) : null}
        <div className="d-flex justify-content-between">
          <h5>Available Payment Methods</h5>
          {props?.staffPage ? (
            <div className="d-flex">
              <div className="d-flex">
                {" "}
                Payment Profile Enforcement
                <BuzopsTooltip
                  position={"bottom"}
                  html={
                    <button
                      className="btn btn-icon"
                      title={
                        props?.userData?.Status === 8 ||
                          props?.userData?.Status === 4
                          ? "This feature is not available for chargeback or return customers."
                          : "When enabled, clients will be required to add a new payment profile, and no further actions can be performed until a new payment profile is added"
                      }
                    >
                      <BiInfoCircle color="#008db9" />
                    </button>
                  }
                />
              </div>
              <span className="pl-1 pr-2">:</span>
              <span className="">
                <Switch
                  checked={enforcePPValue}
                  disabled={
                    props?.userData?.Status === 8 ||
                    props?.userData?.Status === 4
                  }
                  onChange={(e: any) => handleChangeEnforce(e.value)}
                />
              </span>
            </div>
          ) : null}
        </div>
        <hr />
        <h5 className="my-3 bz_fs_1_5">Credits</h5>
        <div className="d-flex justify-content-between bz-mb-2_5rem">
          <div className="d-flex">
            <div className="d-flex align-items-center pr-2">
              <img
                src={bzImages.walleticon}
                style={{ width: "2rem" }}
                alt="Wallet"
              />
            </div>
            <div className="d-flex flex-column">
              <span>Credit Balance</span>
              <span className="buz-font-600">{CurrencyFormat(walletAmt)}</span>
            </div>
          </div>
          <div>
            <span className={`edit-btn-container`}>
              <button
                className="btn btn-link bz-overview-add-member-btn"
                onClick={() => handleShowDialog("addTopUp")}
              >
                <img
                  src={bzImages.addwalleticon}
                  style={{ width: "1rem" }}
                  alt="Wallet"
                />{" "}
                Top Up
              </button>
              <span className="bz-vertical-line"></span>
              <button
                className="btn btn-link"
                onClick={() => handleShowDialog("activity")}
              >
                <img
                  src={bzImages.activityicon}
                  style={{ width: "1rem" }}
                  alt="Wallet"
                />{" "}
                Activity
              </button>
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-sm-center">
          <div>
            <h5 className="my-3 bz_fs_1_5">Cards and Bank Payment Methods</h5>
          </div>
          <div className="d-flex arrow-toggle ">
            {(paymentGatewayType === 1 ||
              paymentGatewayType === 3 ||
              paymentGatewayType === 4) && (
                <span
                  onClick={() => {
                    setPaymentType("addCard");
                    setShowBankModal(true);
                  }}
                  className="bz-cursor-pointer mr-2"
                >
                  <i
                    className={"fa fa-credit-card"}
                    aria-hidden="true"
                    style={{ width: "1.125em" }}
                  ></i>{" "}
                  <span style={{ paddingLeft: "3px" }}> {"Add Card"}</span>
                </span>
              )}
            {checkIfUserCanAddBankAccount() && (
              <span
                onClick={() => {
                  setPaymentType("Bank");
                  setShowBankModal(true);
                }}
                className="bz-cursor-pointer"
              >
                <i
                  className={"fa fa-university fa-w-18"}
                  aria-hidden="true"
                  style={{ width: "1.125em" }}
                ></i>{" "}
                <span style={{ paddingLeft: "3px" }}>{"Add Bank"}</span>
              </span>
            )}
          </div>
        </div>
        <div className="availble-payment-methods bz-available-payment-methods bz-mb-2_5rem">
          {paymentsLoading ? (
            <>
              <BuzopsLoader type={"list"} />
            </>
          ) : paymentProfiles?.filter((i: any) => i.CardTypeId !== 8)
            ?.length === 0 ? (
            <div className="bz-norecords-available">
              {"No Saved Payment Profiles."}
            </div>
          ) : (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Card Number</th>
                  <th scope="col">Card Type</th>
                  <th scope="col">Client Name</th>
                  <th scope="col">Expiry Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {paymentProfiles
                  ?.filter((i: any) => i.CardTypeId !== 8)
                  ?.map((itm: any) => {
                    return (
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="pr-1">{itm?.CardDescriptionWithoutExpiryDate}</div>
                            {itm?.Status === "ACL" ? <BuzopsTooltip
                              html={
                                <span title="The account has been closed, contact card holder for new card">
                                  <FaExclamationTriangle style={{ color: 'red', marginTop: '-4px' }}/>
                                </span>
                              }
                            />:null}
                          </div>
                        </td>
                        <td>
                          {itm?.CardTypeId === -1
                            ? "Buzops"
                            : itm?.CardTypeId === 0
                            ? itm?.BankAccountType === 1
                              ? "Savings Bank"
                              : "Checking Bank"
                            : "Card"}
                        </td>
                        <td>{itm?.MemberName}</td>
                        <td>{itm?.ExpiryDate ? itm?.ExpiryDate : "N/A"}</td>
                        <td>
                          <div className="d-flex justify-content-start align-items-center">
                            {itm?.IsCreditCard && paymentGatewayType === 1 && (
                              <div
                                className="bz-cursor-pointer mr-2"
                                onClick={() => {
                                  editBank(itm);
                                }}
                              >
                                <BuzopsTooltip
                                  html={
                                    <span title="Edit">
                                      <i
                                        className="fa fa-pen"
                                        aria-hidden="true"
                                        style={{ color: "#656565" }}
                                      ></i>
                                    </span>
                                  }
                                />
                              </div>
                            )}
                            {!itm?.IsActive && itm?.CardTypeId !== -1 && (
                              <div
                                className="bz-cursor-pointer"
                                onClick={() => {
                                  handleDeletePaymentMethod(itm);
                                }}
                              >
                                <BuzopsTooltip
                                  html={
                                    <span title="Delete">
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                        style={{ color: "#656565" }}
                                      ></i>
                                    </span>
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {((!props?.staffPage && !IsCardEnforced && !IsEnforced) || props?.staffPage) ? (
        <div>
          <h5 className="mt-3 mb-1 bz_fs_1_5">Linked Subscription</h5>
          <p>
            Below are the linked payment methods to your subscriptions, you can
            add or update the payment method at any time.
          </p>
          <div className="availble-payment-methods linked-subscription-grid">
            {intialValues?.length === 0 ? (
              <div className="bz-norecords-available">
                {"No linked subscriptions available."}
              </div>
            ) : (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Client Name</th>
                    <th scope="col">Subscription Type </th>
                    <th scope="col">Payment Profile</th>
                  </tr>
                </thead>
                <tbody>
                  {intialValues?.map((itm: any) => {
                    return (
                      <tr>
                        <td scope="row">{itm?.UserName}</td>
                        <td>{itm?.RecurrenceTypeDescription}</td>
                        <td>
                          <PaymentDropDown
                            paymentProfiles={paymentProfiles}
                            selectedValue={itm}
                            changePaymentProfile={changePaymentProfile}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {confirmationShwon && (
              <ConfirmationDialog
                msg=" Do you want to change the payment method for this subscription?"
                confirmChangePayment={confirmChangePayment}
                btnLoading={btnLoading}
                cancelConfirmation={() => {
                  setConfirmationShown(false);
                }}
              />
            )}
          </div>
        </div>
      ) : null}
      {showBankModal && (
        <AccountDialog
          cardSubmitBtnLoading={cardSubmitBtnLoading}
          handleCardSubmit={handleCardSubmit}
          handleBankSubmit={handleBankSubmit}
          bankFormValues={bankFormValues}
          cardFormValues={cardFormValues}
          paymentType={paymentType}
          ignoreZipCode={isAdmin}
          paymentGatewayType={paymentGatewayType}
          userConfiguration={userConfiguration}
          setModal={() => {
            setBankFormValues(bankIntialValues);
            setCardFormValues(cardIntialValues);
            setShowBankModal(false);
          }}
          className="bz-addCard-details"
        />
      )}
      {showDialog?.addTopUp ? (
        <AddCreditsTopup
          handleCloseDialog={handleCloseDialog}
          UserMemberId={props?.userData?.UserMemberId}
        />
      ) : null}
      {showDeleteConfirm && renderDeleteDialog()}
    </>
  );
};

export default withNotification(PaymentMethod);
