import Location from "components/Location/Location";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import React from "react";
import {
  CurrencyFormat,
  getFullImageUrl,
  getPaymentOptionById,
  getTimeFormat,
} from "utils";

const ClassSessionItem = (props: any) => {
  let collpaseStatus = false;
  const item1 = props?.dataItem;
  if (props?.expandedItem === item1.ClassSessionId) {
    collpaseStatus = true;
  }
  const check = props?.check;
  const redeem = props?.redeem;
  const Attributes =
    item1?.Attributes && typeof item1?.Attributes === "string"
      ? JSON.parse(item1?.Attributes)
      : item1?.Attributes;

  let IsFilled = false
  if (item1?.BookedSlot === item1?.Slot && !item1?.HasWaitList) {
    IsFilled = true
  } else if (item1?.HasWaitList && item1?.WaitList === item1?.WaitingSlot) {
    IsFilled = true
  }
  const classFree = Attributes?.NonPaidLabel?.toUpperCase() === 'FREE' ? 'free-class' : '';
  return (
    <div
      className="bzc-sessions-list-card-new bz-group-events-new bz-default-cursor bz-noPaid-group mb-3"
      key={`${item1?.ClassSessionId}${check}`}
    >
      <div className="card-body">
        <div className="bzc-sessions-list-row-new">
          <div className="section-1">
            <div className="bzc-service-name">
              <div className="bz-name">
                <h6 className="service-name d-flex align-items-start">
                  {" "}
                  <span className="group-name">{item1?.ClassName}</span>{" "}
                  {item1?.Attributes !== "" && (
                    <Location
                      Attributes={item1?.Attributes}
                      showDetails={props?.staffPage}
                      VirtualMeetingLink={item1?.VirtualMeetingLink}
                      clientPhnNum={item1?.Phone}
                    />
                  )}
                </h6>
                <span className="bz-availability-appointments">
                  <i className="far fa-clock"></i>
                  <span>
                    {getTimeFormat(item1?.ClassSessionStartDate)} -{" "}
                    {getTimeFormat(item1?.ClassSessionEndTime)}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="section-3">
            <div className="bz-main-provider">
              <div className="bz-provider-img">
                <img
                  src={
                    item1?.ProviderPhoto
                      ? getFullImageUrl(item1?.ProviderPhoto)
                      : "/images/user.png"
                  }
                  alt=""
                />
              </div>
              <div className="bz-provider-name">
                <h6>{item1?.TrainerName}</h6>
                <span className="bz-provider">Provider</span>
              </div>
            </div>
          </div>

          <div className="section-4">
            <div className="bz-time-section">
              <div>
                <h6 className="pl-0">
                  {/* Attendees: {item1?.BookedSlot} of {item1?.Slot} */}
                  Seats Avail: {item1?.Slot - item1?.BookedSlot}
                </h6>
                {!IsFilled && !item1?.HasPurchased && item1?.PackagesList?.length > 0 && props?.usePackageFilter && <div className='packageavailable'>Package Available</div>}
                {item1?.CanBookAsWaitlist && props?.staffPage && <span
                  className={`bz-badge2 bz-badge-warning bz-min-w-7rem mt-2`}
                >{`${item1?.WaitingSlot} on Waitlisted`}</span>}
              </div>
            </div>
          </div>

          <div className={`section-5 rate ${classFree} ${redeem ? 'redeem' : ''}`}>
            <div className="bzc-session-price">
              <div className="status-container">
                <span
                  className={`mb-0 ${item1?.ClassType === 1 ? "" : "bzo-status-success"
                    }`}
                >
                  <span className="ratelabel"></span>
                  {item1?.ClassType === 1
                    ? redeem || (item1?.PackagesList?.length > 0 && props?.usePackageFilter) ? <span className="cross-word">{CurrencyFormat(item1?.Cost)}</span> : CurrencyFormat(item1?.Cost)
                    : Attributes?.NonPaidLabel || "Non-Paid"}
                </span>
                {redeem && item1?.ClassType === 1 ? <small className="text-left" style={{ fontSize: '10px', color: "#008DB9" }}>Redeem via Package</small> : null}
              </div>
              <div className="w-35">
                <BuzopsTooltip
                  html={
                    <span
                      title={getPaymentOptionById(item1?.PaymentOption)}
                      style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                    >
                      <a>
                        <i
                          style={{ fontSize: "16px" }}
                          className="fa fa-credit-card"
                        ></i>
                      </a>
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className={item1?.CanBookAsWaitlist ? 'section-6 bz-waitlist-btn' : 'section-6'} style={{ justifySelf: "end" }}>
            {item1?.Status === 1 ? (
              <>
                <button
                  className={
                    (IsFilled || item1?.HasPurchased)
                      ? `select-button bz-service-select-btn bz-service-select-btn-new ${item1?.HasPurchased ? "bz-enrolled-btn" : "bz-filled-btn"}`
                      : check !== -1
                        ? "buz-selected-button k-button select-button bz-service-select-btn bz-service-select-btn-new"
                        : "buz-select-button select-button bz-service-select-btn bz-service-select-btn-new"
                  }
                  disabled={IsFilled || item1?.HasPurchased}
                  onClick={() => {

                    props?.handleSelectClass(item1, check !== -1 ? "deleted" : "add");
                  }}
                >
                  {IsFilled ? (
                    "Full"
                  ) :
                    (<>{item1?.HasPurchased ? "Enrolled" : (<>
                      {check !== -1 ? (
                        <>
                          <i
                            className="fa fa-check"
                            style={{ color: "#008DB9" }}
                            aria-hidden="true"
                          ></i>{" "}
                          {item1?.CanBookAsWaitlist ? "Waitlisted" : "Selected"}
                        </>
                      ) : (
                        <>  {item1?.CanBookAsWaitlist ? "Waitlist" : "Select"}</>
                      )}
                    </>)}
                    </>)}
                </button>
                {/* <small className="text-center" style={{fontSize:'10px',color: "#008DB9",}}>Redeem via Package</small> */}
              </>
            ) : (
              <span>Cancelled</span>
            )}
          </div>
        </div>
        <div>
          <div className="">
            <div className="bz-name">
              <span className="bz-availability-appointments">
                {item1.Description !== "" && (
                  <>
                    {collpaseStatus ? (
                      <span
                        className="align-baseline bz-cursor-pointer p-0"
                        onClick={(e) => props?.handleCollapse()}
                      >
                        view details <i className="fas fa-angle-up"></i>
                      </span>
                    ) : (
                      <span
                        className="bz-cursor-pointer  p-0"
                        onClick={(e) => props?.handleExpand(item1)}
                      >
                        view details <i className="fas fa-angle-down"></i>
                      </span>
                    )}
                  </>
                )}
              </span>
            </div>
          </div>
          {collpaseStatus && (
            <>
              <div className="service-desc bz-ol-muted">
                {item1.Description}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClassSessionItem
