import BuzopsNotification from "generic-components/BuzopsNotification";
import { useEffect, useState } from "react";

function withNotification(WrappedComponent: any) {
  return ({ ...props }: any) => {
    const [notification, setNotification] = useState({
      error: false,
      success: false,
      info: false,
    });
    const [message, setMessage] = useState("");
    const [submit, setSubmit] = useState(false);
    const [timer, setTimer] = useState(true);

    useEffect(() => {
      if (timer) {
        let timer = 7000;
        if (notification.error || notification.info) {
          timer = 10000;
        }
        let timer1 = setTimeout(() => {
          if (notification?.success) {
            setSubmit(true);
          }
          setNotification({
            ...notification,
            error: false,
            success: false,
            info: false,
          });
        }, timer);
        return () => {
          clearTimeout(timer1);
        };
      }
    }, [notification.error, notification.success, notification.info, timer]);

    const handleNotification = (type: string) => {
      setNotification({ ...notification, [type]: false });
    };

    const handleNotificationMessage = (
      message: any,
      type: string,
      timer = true,
      status = ""
    ) => {
      let obj = { ...notification };
      if (type === "error") {
        obj = { ...notification, error: true, success: false, info: false };
      } else if (type === "success") {
        obj = { ...notification, success: true, error: false, info: false };
      } else {
        obj = { ...notification, success: false, error: false, info: true };
      }
      if (status === "staff") {
        setTimer(timer);
        const mes: any = (
          <>
            {`Cannot Perform this action until Charge Back (Credit card)/Return (ACH) dispute is resolved. Please contact administrator.`}
          </>
        );
        setMessage(mes);
      } else if (status === "client") {
        setTimer(timer);
        const mes: any =
          "Cannot Perform this action until Charge Back (Credit card)/Return (ACH) transaction is paid, Please contact administrator.";
        setMessage(mes);
      } else {
        setTimer(timer);
        setMessage(message);
      }
      setNotification(obj);
    };

    return (
      <>
        <WrappedComponent
          {...props}
          handleNotificationMessage={handleNotificationMessage}
          submit={submit}
          handleNotification={(type: any) => handleNotification(type)}
        />
        <BuzopsNotification
          notification={notification}
          message={message}
          handleNotification={(type: any) => handleNotification(type)}
        />
      </>
    );
  };
}

export default withNotification;
