import BuzopsLoader from 'generic-components/BuzopsLoader'
import { useEffect, useState } from 'react'
import { Card } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { ListView } from "@progress/kendo-react-listview";
import { Loader } from "@progress/kendo-react-indicators";
import { PackageService } from "services/package/index.service";
import Modal from 'components/dialog';
import withNotification from 'components/Hoc/withNotification';

const Notes = (props: any) => {
  const noteData = new PackageService();
  const [noteList, setNoteList] = useState<[]>([])
  const [loading, setloading] = useState<boolean>(true);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [note, setNote] = useState<string>('')
  const [noteMaxLength, setNoteMaxLength] = useState<number>(0)
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {
    fetchNotes()

  }, [showModal])

  const fetchNotes = async () => {
    const req: any = {
      PageNumber: 0,
      PageSize: 10,
      UserMemberId: props?.userData?.UserMemberId,
    };
    const result = await noteData.getNotes(req);
    setNoteMaxLength(result?.TotalItems)
    setloading(false)
    setNoteList(result?.Items)

  }

  const handlePageChange = async () => {
    const req: any = {
      PageNumber: 0,
      PageSize: noteList.length + 10,
      UserMemberId: props?.userData?.UserMemberId,
    };
    const result = await noteData.getNotes(req);
    setNoteList(result?.Items)
  }

  const saveNote = async () => {
    const req = {
      "Description": note,
      "UserMemberId": props?.userData?.UserMemberId
    }
    setBtnLoading(true)
    const result = await noteData.createNote(req);
    setBtnLoading(false)
    if (result?.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Notes Successfully Saved`,
        "success"
        );
      setShowModal(true)
      setNote('')
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in Saving Notes";
      props?.handleNotificationMessage(errorMsg, "error");
    }

  }
  const RenderItem = ({ dataItem }: any) => {
    return (
      <div
        style={{ backgroundColor: "#fff" }}
        className="py-2 bz-package-audit-history-list"
      >
        {" "}
        <span className="text-muted d-block mb-0 audit-flex">
          <span>
            <i className="far fa-calendar-alt"></i>
          </span>
          <div>
            <span className="bz-activity-time-date text-capitalize">
              {" "}
              {dataItem?.CreateDateTime}
            </span>
            <span className="bz-activity-dis-div">
              {dataItem?.Description}
            </span>
            <label className="bz-requestedBy font-italic bz-fw-600">
              {dataItem?.AssociateName}
            </label>{" "}

          </div>
        </span>
      </div>
    );
  }

  return (
    <>
      {" "}
      <div className={"bz-auditHistory-blk bz-notes-blk"}>
        <h5 className="mb-0 bz_fs_1_5">
          Notes
          <Button onClick={() => { setShowModal(false) }} className="mx-0" primary={true}>
            Add Note
          </Button>
        </h5>
        {!showModal && <Modal
          setShowModal={(e: boolean) => { setShowModal(e) }}
          title="Add Note"
          onChange={(text: string) => { setNote(text) }}
          submit={saveNote}
          disabled={note.trim().length === 0 ? true : false}
          btnLoading={btnLoading}
          value={note}
        />}
        {loading ? (
          <BuzopsLoader type={"list"} />
        ) :
          noteList.length === 0 ? (
            <>
              <div className={"bz-norecords-available text-muted"}>
                <span>
                  {"No Notes is available"}
                </span>
              </div>
            </>
          ) :

            <>
              <Card
                className={
                  "bzo-services-container bz-package-audit-history border-0"
                }
              >
                <ListView data={noteList} item={RenderItem} />
                <div className="d-flex justify-content-center align-items-center bz-services-showMore">
                  <div >
                    {false ? (
                      <>
                        <Loader type="converging-spinner" />
                      </>
                    ) : noteList.length < noteMaxLength ? (


                      <span
                        className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                        onClick={() => handlePageChange()}
                      >
                        View More <i className="fas "></i>
                      </span>
                    ) : null}
                  </div>
                </div>
              </Card>
            </>

        }

      </div>

    </>
  )

}

export default withNotification(Notes)