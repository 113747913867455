import moment from "moment";
import { useState } from "react";
import { CheckInService } from "services/checkin/index.service";
import { CheckinType } from "../Utils/constants";


const useCheckinUser = (props: any) => {
    const [restrictUser, setRestrictUser] = useState("");
    const checkinService = new CheckInService();
    const checkIfOpenPathSetupcompleted = (accessCardDetails: any) => {
        if(accessCardDetails?.OpenpathCredentialStartDate && moment(accessCardDetails?.OpenpathCredentialStartDate).isBefore(moment()) && (moment(accessCardDetails?.OpenpathCredentialEndDate).isAfter(moment()) || !accessCardDetails?.OpenpathCredentialEndDate)){
            return true
        }
        return false;
    }
    const checkIfUserCanCheckinForOpenPath = (accessCardDetails: any, check = false, ignoreSuspended = false) => {
        let doCheckin = false;
        
        if (checkIfOpenPathSetupcompleted(accessCardDetails)) {
          doCheckin = true;
          if (accessCardDetails.IsSuspended && !ignoreSuspended) {
            doCheckin = false;
            if(!check){
              props?.handleNotificationMessage(
                "User's access is temporarily suspended",
                "error"
              );
            }
          }
        } else {
          if(!check){
            setRestrictUser("Access is not assigned to the selected user");
            props?.handleNotificationMessage(
              "Access is not assigned to the selected user",
              "error"
            );
          }
          doCheckin = false;
        }
        return doCheckin;
    };

    const checkIfUserCanCheckinForBarcode = (accessCardDetails: any, check = false, ignoreSuspended = false) => {
        let doCheckin = false;
        if (accessCardDetails.CardNumber) {
          doCheckin = true;
          if (accessCardDetails.IsSuspended && !ignoreSuspended) {
            doCheckin = false;
            if(!check){
              props?.handleNotificationMessage(
                "User's access is temporarily suspended",
                "error"
              );
            }
          }
        } else {
          if(!check){
            setRestrictUser("Access card is not assigned to the selected user");
            props?.handleNotificationMessage(
              "Access card is not assigned to the selected user",
              "error"
            );
          }
          doCheckin = false;
        }
        return doCheckin;
      };
    const handleLocationCheckin = async () => {
        const payload: any = {
            Ocurred: `${moment().format("YYYY-MM-DDTHH:mm:ss")}`,
            Type: CheckinType.Location, //0-Location, 2-GroupClassAppointment, 3-OneOnOneAppointment
            CheckedInUserId: props?.userDetails?.UserId, //<UserId>
            CheckinMode: props?.CheckinMode, //0-AccessControlBrivo, 1-ByStaff, 2-UserFromKiosk, 3-OnlineByUser(future)
        };
        const result = await checkinService?.saveCheckins(payload);
        console.log(result);
        if (result?.ResponseCode === 300) {
            props?.handleNotificationMessage(
            "Check-In successfully completed",
            "success"
            );
        } else {
            props?.handleNotificationMessage("Check-In unsuccessfull", "success");
        }
    };
    return {
        setRestrictUser,
        restrictUser,
        checkIfUserCanCheckinForOpenPath,
        checkIfUserCanCheckinForBarcode,
        handleLocationCheckin
    }

}

export default useCheckinUser;