import ChartAndGrid from "components/reports/Generic/ChartAndGrid";
import { useEffect } from "react";

const TransactionsGeneric = (props: any) => {

  useEffect(() => {}, []);

  const groupFields = {
    Success: [
      "Success",
      "RecurringPaymentSuccess",
      "DownPaymentSuccess",
      "ManualPaymentSuccess",
    ],
    DeclinedPayments: [
      "DeclinedPayments",
      "DownPaymentFailure",
      "RecurringPaymentFailure",
      "ManualPaymentFailure",
    ],
    Refund: [
      "Refund",
      "RefundFailure",
      "ChargeBack",
      "Return",
      "Voided",
      "Waived",
    ],
  };
  const overAllGridColumns = [
    {
      title: "Transaction Date",
      field: "Year",
      show: true,
      filter: "text",
      width: "300px",
      type: "dateMonth",
    },
    {
      title: "Payment By",
      field: "CardType",
      show: true,
      filter: "text",
      width: "300px",
      type: "text",
    },
    {
      title: "Success",
      field: "Success",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Recurring Payment Success",
      field: "RecurringPaymentSuccess",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Down Payment Success",
      field: "DownPaymentSuccess",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Manual Payment Success",
      field: "ManualPaymentSuccess",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Declined Payments",
      field: "DeclinedPayments",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Down Payment Failed",
      field: "DownPaymentFailure",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Recurring Failed",
      field: "RecurringPaymentFailure",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Manual Failed",
      field: "ManualPaymentFailure",
      show: true,
      filter: "numeric",
      // format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Refunds",
      field: "Refund",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Refund Failure",
      field: "RefundFailure",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Charge Backs",
      field: "ChargeBack",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Returns",
      field: "Return",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Voided",
      field: "Voided",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Waived",
      field: "Waived",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
    {
      title: "Complimentary",
      field: "Complimentary",
      show: true,
      filter: "numeric",
      format: "{0:c}",
      width: "300px",
      type: "amount",
    },
  ];

  const gridColumns = [
    {
      title: "Transaction Id",
      field: "TransactionId",
      show: true,
      width: "200px",
      filter: "text",
      type: "text",
    },
    {
      title: "Client Name",
      field: "MemberName",
      show: true,
      width: "200px",
      filter: "text",
      type: "text",
    },
    {
      title: "Email",
      field: "Email",
      show: true,
      filter: "text",
      width: "200px",
      type: "text",
    },
    {
      title: "Transaction Date",
      field: "TransactionDate",
      show: true,
      // filter: "text",
      width: "300px",
      type: "dateMonth",
    },
    {
      title: "Payment Type",
      field: "PaymentMode",
      show: true,
      filter: "text",
      width: "200px",
      type: "text",
      // format: "{0:d}",
    },
    {
      title: "Payment By",
      field: "net",
      show: true,
      filter: "numeric",
      width: "200px",
      type: "amount",
      // format: "{0:d}",
    },
    {
      title: "Subscription Type",
      field: "refunds",
      show: true,
      filter: "numeric",
      width: "200px",
      type: "amount",
      // format: "{0:d}",
    },
    {
      title: "Payment Mode",
      field: "PaymentMode",
      show: true,
      filter: "text",
      width: "200px",
      type: "text",
      // format: "{0:d}",
    },
    {
      title: "Payment Status",
      field: "PaymentTransactionStatus",
      show: true,
      filter: "text",
      width: "200px",
      type: "text",
      // format: "{0:d}",
    },
    {
      title: "Amount Paid",
      field: "AmountPaid",
      show: true,
      filter: "numeric",
      width: "200px",
      type: "amount",
      // format: "{0:d}",
    },
    {
      title: "Description",
      field: "CardDescription",
      show: true,
      filter: "text",
      width: "200px",
      type: "text",
      // format: "{0:d}",
    },
    {
      title: "Internal Processing Status",
      field: "InternalProcessingStatusDescription",
      show: true,
      filter: "text",
      width: "200px",
      type: "text",
      // format: "{0:d}",
    },
  ];
  const initialSort = [
    {
      // field: "Year",
    },
  ];
  return (
    <ChartAndGrid
      title={"Transactions"}
      groupFields={groupFields}
      initialSort={initialSort}
      gridColumns={gridColumns}
      overAllGridColumns={overAllGridColumns}
      {...props}
      type={"transaction"}
      dropDownValues={["Select All", "Success", "DeclinedPayments", "Refund"]}
    />
  );
};

export default TransactionsGeneric;
