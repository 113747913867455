import React, { useEffect, useState } from "react";
import { ReportsService } from "services/reports/index.service";
import moment from "moment";
import { MRRColumns } from "../Utilities/constants";

const useMRR = () => {
  const [selectedType, setSelectedType] = useState(1);
  const [updaterFullscreen, setUpdaterFullscreen] = React.useState(false);
  const [showDetailedMRR, setShowDetailedMRR] = React.useState(false);
  const [pageInfo, setPageInfo] = useState({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });
  const [stateGridColumns, setStateGridColumns] = React.useState(MRRColumns);
  const [selectedRow, setSelectedRow] = React.useState<any>({});
  const [excelData, setExcelData] = useState([]);

  const [fromDateToDate, setFromDateToDate] = useState({
    from: moment().startOf("year").startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });

  const service = new ReportsService();

  const fetchMRR = async (pageData?: any, forExcel = false) => {
    const req = {
      FilterFields: [],
      PageNumber: forExcel
        ? 0
        : pageData?.dataState?.skip
        ? pageData?.dataState.skip / pageData?.dataState.take
        : 0,
      showAddittionFilter: false,
      Hidefilter: "Y",
      EFTHistory: {},
      FromDate: pageData?.fromDate || fromDateToDate.from,
      ToDate: pageData?.toDate || fromDateToDate.to,
      GridHirecharyLevel: 0,
      IncludeNextEFT: true,
      calendarStartOption: "year",
      SortBy:
        pageData?.sort?.length > 0 ? pageData.sort[0].field : "RecurrenceDate",
      SortDirection: pageData?.sort?.length > 0 ? pageData.sort[0].dir : "desc",
    };
    const getData = await service.getMRRData(req);

    if (forExcel) {
      setExcelData(getData.Item.Result);
    } else {
      setPageInfo((prev) => {
        prev.data = getData.Item.Result;
        prev.pageNumber = req.PageNumber;
        prev.total = getData.Item.TotalItems;
        if (pageData?.dataState?.take) {
          prev.skip = pageData?.dataState?.skip;
          prev.take = pageData?.dataState?.take;
        }
        return { ...prev };
      });
    }
  };

  useEffect(() => {
    fetchMRR({
      fromDate: fromDateToDate.from,
      toDate: fromDateToDate.to,
    });
  }, [fromDateToDate]);

  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };

  const onCellClick = (cellData: any) => {
    setShowDetailedMRR(true);
    setSelectedRow(cellData);
  };
  return {
    setUpdaterFullscreen,
    updaterFullscreen,
    pageInfo,
    fetchMRR,
    stateGridColumns,
    setStateGridColumns,
    onCellClick,
    onColumnsSubmit,
    excelData,
    setExcelData,
    showDetailedMRR,
    setSelectedType,
    selectedType,
    selectedRow,
    setFromDateToDate,
  };
};

export default useMRR;
