import { Switch } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { CurrencyFormat, checkHasTaxEnable } from "utils";
import { TooltipContentPackageService } from "./OrderSummary";

const OneonOneOrderSummary = (props: any) => {
  const {
    packageCostAfterDiscount,
    handleIsTaxSkipped,
    IsTaxSkipped,
    staffPage,
    Taxes,
    serviceData,
    downpaymentForm,
    PredictedTaxes,
    PredictedTotalTaxAmount,
    TotalTaxAmount,
  } = props;
  const { paidToday } = downpaymentForm;
  return (
    <>
      {checkHasTaxEnable() && staffPage && Taxes?.length > 0 && (
        <div
          className={
            "d-flex align-items-center text-left mb-3 buz-text-small buz-font-500 mt-2"
          }
        >
          <Switch
            checked={!IsTaxSkipped}
            defaultChecked={false}
            onChange={() => handleIsTaxSkipped()}
            className="mr-1 d-inline-flex"
          />
          {`Surcharges`}
        </div>
      )}

      {checkHasTaxEnable() && !IsTaxSkipped && Taxes?.length > 0 && (
        <>
          <p className={"text-left mb-1 buz-font-500 service-para  mt-3"}>
            <span className="service-titlename2">Total before Surcharges</span>
            <span className={"service-value float-right"}>
              {CurrencyFormat(packageCostAfterDiscount)}
            </span>
          </p>
          <p className={"text-left mb-1 buz-font-500 service-para"}>
            <span className="service-titlename2 buz-font-600">
              <Tooltip
                content={(tooltipProps: any) => {
                  return (
                    <TooltipContentPackageService
                      title={tooltipProps.title}
                      data={PredictedTaxes}
                      TotalTaxAmount={PredictedTotalTaxAmount}
                      Name={serviceData.Name}
                    />
                  );
                }}
                tooltipClassName="bz-tooltip-table"
              >
                <a
                  title="Surcharges"
                  style={{
                    borderBottom: "1px dotted #0d6efd",
                    cursor: "pointer",
                  }}
                >
                  {"Surcharges to be collected"}
                </a>
              </Tooltip>
            </span>
            <span className={"service-value buz-font-600 float-right"}>
              {CurrencyFormat(PredictedTotalTaxAmount)}
            </span>
          </p>
          <hr />
        </>
      )}
      <p className={"text-left mb-1 buz-font-500 service-para mt-2"}>
        <span className="service-titlename2 buz-font-600">{"Order Total"}</span>
        <span className={"service-value buz-font-600 float-right"}>
          {CurrencyFormat(
            !IsTaxSkipped
              ? packageCostAfterDiscount + PredictedTotalTaxAmount
              : packageCostAfterDiscount
          )}
        </span>
      </p>
      <p className={"text-left mb-1 buz-font-500 service-para"}>
        <span className="service-titlename2 buz-font-600">
          {"Down Payment"}
        </span>
        <span className={"service-value buz-font-600 float-right"}>
          {CurrencyFormat(
            !IsTaxSkipped ? paidToday + TotalTaxAmount : paidToday
          )}
        </span>
      </p>
    </>
  );
};

export default OneonOneOrderSummary;
