import { allTimeZonesArray } from "./form-utils";
import moment from "moment-timezone";
import timemachine from "timemachine";
import timeZoneConverter from "time-zone-converter";
import { GetLocalStore } from "./storage";

function timeToDecimal(time: string) {
  // console.log(time)
  // Split the time into hours and minutes
  const [hours, minutes] = time?.split(":").map(Number);

  // Convert the time to decimal hours
  const decimalHours = hours + minutes / 60;

  return decimalHours;
}

function TimeZoneSetup(TenantTime: any, TenantData: any) {
  const TimeZoneObj=TenantData?.TimeZone
  const TimeZoneOffSet=TenantData?.TimeZoneOffSet || TimeZoneObj?.BaseUtcOffset
  const TimeZone = TimeZoneObj?.Id;
  const baseUTCOffset = timeToDecimal(TimeZoneOffSet);
  const tzvalueName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log(tzvalueName, "tzvalueName");
  const CurrentApplicationType = GetLocalStore("CurrentApplicationType");
  let newDateTime = new Date(TenantTime);
  let timezoneFull = TimeZone;
  if (CurrentApplicationType === 1 || CurrentApplicationType === 8) {
    newDateTime = new Date(TenantTime);
    timezoneFull = TimeZone;
  } else {
    const timezoneShorthand = allTimeZonesArray.filter(
      (i: any) => i?.key === tzvalueName
    )?.[0]?.UTC;
    // timezoneFull = allTimeZonesArray.filter((i: any) => i?.key === tzvalueName)?.[0]?.name
    timezoneFull = tzvalueName;
    const offsetMinutes = moment.tz(tzvalueName).utcOffset(); // Get the offset in minutes
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetHoursFormated = String(offsetHours).padStart(2, "0");
    const offsetMinutesFormatted = String(
      Math.abs(offsetMinutes) % 60
    ).padStart(2, "0");
    const offsetSign = offsetMinutes < 0 ? "-" : "+";
    const formattedOffset = `${offsetSign}${offsetHoursFormated}:${offsetMinutesFormatted}:00`;

    if (formattedOffset !== TimeZoneOffSet) {
      newDateTime = timeZoneConverter(
        new Date(TenantTime),
        baseUTCOffset,
        timezoneShorthand,
        "YYYY/MM/DD HH:mm:ss"
      );
    }
  }
  timemachine.reset();
  timemachine.config({
    dateString: moment(newDateTime).tz(timezoneFull)?.format(),
    tick: true,
  });
}

export default TimeZoneSetup;
