import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import ShowBankForm from "components/checkout/ShowBankForm";
import images from "utils/images";
import ShowCardForm from "components/checkout/ShowCardForm";
import StripePayments from "components/stripePayment";
import SquarePaymentForm from "components/square-payment-form/paymentsv2";
const AccountDialog = ({
  setModal,
  paymentType,
  bankFormValues,
  handleBankSubmit,
  cardFormValues,
  handleCardSubmit,
  cardSubmitBtnLoading,
  paymentGatewayType,
  userConfiguration,
  ignoreZipCode=false,
  className=''
}: any) => {
  const getCardImage = (id: number) => {
    if (id === 1) {
      return "amex";
    } else if (id === 2) {
      return "dinersclub";
    } else if (id === 3) {
      return "discover";
    } else if (id === 4) {
      return "jcb";
    } else if (id === 5) {
      return "mastercard";
    } else if (id === 6) {
      return "visa";
    } else {
      return "placeholder";
    }
  };

  const showAccountBasedOnType = () => {
    if (paymentGatewayType === 4) {
      return (
        <StripePayments
          bankFormValues={bankFormValues}
          bankSubmitBtnLoading={cardSubmitBtnLoading}
          handleBankSubmit={handleBankSubmit}
          type={"bank"}
        />
      );
    } else {
      return (
        <ShowBankForm
          bankSubmitBtnLoading={cardSubmitBtnLoading}
          bankFormValues={bankFormValues}
          handleBankSubmit={handleBankSubmit}
        />
      );
    }
  };

  const showCardBasedOnType = () => {
    if (paymentGatewayType === 3) {
      return (
        <SquarePaymentForm
          handleCardSubmit={handleCardSubmit}
          userConfiguration={userConfiguration}
          cardSubmitBtnLoading={cardSubmitBtnLoading}
        />
      );
    } else if (paymentGatewayType === 4) {
      return (
        <StripePayments
          handleCardSubmit={handleCardSubmit}
          userConfiguration={userConfiguration}
          type={"card"}
          cardSubmitBtnLoading={cardSubmitBtnLoading}
        />
      );
    } else {
      return (
        <ShowCardForm
          cardFormValues={cardFormValues}
          ignoreZipCode={ignoreZipCode}
          cardSubmitBtnLoading={cardSubmitBtnLoading}
          cardImageIcon={
            paymentType === "editcard"
              ? images[getCardImage(cardFormValues?.CardTypeId)]
              : images?.placeholder
          }
          handleCardSubmit={handleCardSubmit}
          type={paymentType === "editcard" ? "edit" : ""}
        />
      );
    }
  };
  return (
    <Dialog
      title={
        paymentType === "Bank"
          ? "Add Bank"
          : paymentType === "editcard"
          ? "Edit Card"
          : "Add Card"
      }
      onClose={() => {
        setModal();
      }}
      className={`bz-addCard-dialog ${className}`}
    >
      {paymentType === "Bank" ? (
        showAccountBasedOnType()
      ) : (
        <>
          {showCardBasedOnType()}
        </>
      )}
    </Dialog>
  );
};

export default AccountDialog;
