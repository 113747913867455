import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsNotification from "generic-components/BuzopsNotification";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TenantService } from "services/tenant/index.service";
import { FormInput } from "utils/form-components";
import { GetLocalStore } from "utils/storage";
import { emailValidator } from "validators/validator";

function AffiliateLoginPage(props: any) {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const history = useHistory();

  const [message, setMessage] = useState("");

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
    }, 3000);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification, notification.error, notification.success]);

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

  const tenantService = new TenantService();

  useEffect(() => {
    const AffiliateDetails = GetLocalStore("AffiliateDetails");
    if (AffiliateDetails) {
      history.push("/affiliate/summary");
    }
  }, []);
  const handleSubmit = async (dataItem: any) => {
    setLoading(true);
    const req = { EmailId: dataItem.email };
    const verifyEmailExistence = await tenantService.verifyIfEmailExist(req);
    if (
      verifyEmailExistence.isEmailExists &&
      verifyEmailExistence.IsActivated
    ) {
      props.setAuthRoute("affiliatewelcome");
    } else {
      setMessage("Affiliate is not Activated.");
      setNotification({ ...notification, success: false, error: true });
    }
    setLoading(false);
  };

  const LoginBody = () => {
    return (
      <>
        <div className="bzo-login-body">
          <p className="text-left my-3"> Enter your email address</p>
          <div className="form-group">
            <Field
              id={"email"}
              name={"email"}
              component={FormInput}
              validator={(val:any) => emailValidator(val,"Email")}
              autoFocus={true}
              hidelabel={true}
            />
          </div>
          <div className="">
            <ButtonGroup>
              <Button type={"reset"} onClick={(e) => handleRedirect(e)}>
                Cancel
              </Button>

              <BuzopsButton
                type={"submit"}
                label={"Next"}
                loading={loading}
                disable={loading}
              />
            </ButtonGroup>
          </div>
        </div>
      </>
    );
  };
  const handleRedirect = (e: any) => {
    e.stopPropagation();
    props.handleCloseLogin();
  };

  return (
    <>
      <BuzopsNotification
        notification={notification}
        message={message}
        handleNotification={(type: any) => handleNotification(type)}
      />
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <div className="">
              <div className="bzo-login-card bz-emailLogin-card border-0 bz-border-radius-1">
                <div className="bzo-login-header">
                  <h4 className="h4 mb-0 text-left">Sign in as an Affiliate</h4>
                </div>
                {props?.info?.Message && (<div className="bzo-login-body mb-1">
                  <h6>{props.info.Message}</h6>
                </div>)}
                <LoginBody />
              </div>
            </div>
          </FormElement>
        )}
      />
    </>
  );
}

export default AffiliateLoginPage;
