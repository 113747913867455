export enum MemberType {
  PrimaryMember = 0,
  SubMember = 1,
}

export enum OpenPathCredentialStatusType {
  Suspended = "Suspended",
  Pending = "Pending",
  Active = "Active",
  NoAccess = "No Access",
}
export enum PassportCredentialStatusType {
  Suspended = "Suspended",
  Pending = "Pending",
  Active = "Active",
  NoAccess = "No Access",
}

export enum OpenpathCrendentialType {
  Mobile = 1,
  CardWeigand = 2,
  CardMIFARE = 4,
  CardDESFire = 5,
}
export enum PassPortCrendentialType {
  Card_LF_Weigand_H10301 = 2,
  Card_HF_PassportTech = 4,
}

export const OpenpathCrendentialOptions = [
  { id: OpenpathCrendentialType.Mobile, text: "Mobile" },
  {
    id: OpenpathCrendentialType.CardMIFARE,
    text: "Card-MIFARE",
  },
  {
    id: OpenpathCrendentialType.CardDESFire,
    text: "Card-DESFire",
  },
  {
    id: OpenpathCrendentialType.CardWeigand,
    text: "Card-Wiegand",
  },
];

export const PassportCrendentialOptions = [
  {
    id: PassPortCrendentialType.Card_LF_Weigand_H10301,
    text: "Buzops Credential",
  },
  {
    id: PassPortCrendentialType.Card_HF_PassportTech,
    text: "Passport Tech Credential",
  },
];
