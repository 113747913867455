import React, { useEffect, useState } from "react";
import { GetLocalStore, LocalStore } from "utils/storage";
import "./SquarePayments.scss";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { Helmet } from "react-helmet";

const SquarePayments = (props) => {
  const [cardLoading, setCardLoading] = useState(true);
  const userDetails = GetLocalStore("tenantUserSession");
  const appId = userDetails?.ChargehubSquarePaymentProfile?.MerchantId;

  async function initializeCard(payments) {
    setCardLoading(true);
    const card = await payments.card();

    await card.attach("#card-container");
    setCardLoading(false);
    return card;
  }

  // This function tokenizes a payment method.
  // The ‘error’ thrown from this async function denotes a failed tokenization,
  // which is due to buyer error (such as an expired card). It is up to the
  // developer to handle the error and provide the buyer the chance to fix
  // their mistakes.
  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }
      throw new Error(errorMessage);
    }
  }

  useEffect(() => {
    loadFunction();
  }, [window?.Square]);

  const postalCodeEvent = async (cardInputEvent) => {
    const postalCodeValue = cardInputEvent.detail.postalCodeValue;
    LocalStore("postalCodeValue", postalCodeValue);
  };

  const loadFunction = async () => {
    if (!window?.Square) {
      throw new Error("Square.js failed to load properly");
    }
    const payments = window?.Square?.payments(appId);
    let card;
    try {
      card = await initializeCard(payments);
      card.addEventListener("postalCodeChanged", postalCodeEvent);
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }

    async function handlePaymentMethodSubmission(event, paymentMethod) {
      event.preventDefault();

      try {
        // disable the submit button as we await tokenization and make a
        // payment request.
        cardButton.disabled = true;
        const cardData = await tokenize(paymentMethod);
        const cardTypeName = cardData.details.card.brand;
        const userDetails = GetLocalStore("userDetails");
        const usermemberId =
          userDetails?.UserSession?.UserMemberId || props?.UserMemberId;
        let CardtypeId;
        switch (cardTypeName) {
          case "VISA":
            CardtypeId = 6;
            break;
          case "MASTERCARD":
            CardtypeId = 5;
            break;
          case "JCB":
            CardtypeId = 4;
            break;
          case "DISCOVER":
            CardtypeId = 3;
            break;
          case "DINERSCLUB":
            CardtypeId = 2;
            break;
          case "AMERICANEXPRESS":
            CardtypeId = 1;
            break;
          case "AMERICAN_EXPRESS":
            CardtypeId = 1;
            break;
        }
        const expiry_year = cardData.details.card.expYear.toString().substr(-2);
        let expiry_month = cardData.details.card.expMonth.toString();
        expiry_month = "0" + expiry_month.substr(-2);
        const expiryMonthYear = `${expiry_month}${expiry_year}`;
        const cardIntialValues = {
          MaskedCCNumber: cardData.token,
          CardTypeId: CardtypeId,
          ExpiryMonthYear: expiryMonthYear,
          ExpiryMonth: cardData.details.card.expMonth,
          ExpiryYear: cardData.details.card.expYear,
          ZipCode: GetLocalStore("postalCodeValue"),
          PaymentType: "Card",
          UserMemberId: usermemberId,
        };
        props.handleCardSubmit(cardIntialValues);
      } catch (e) {
        cardButton.disabled = false;
        console.error(e.message);
      }
    }

    const cardButton = document.getElementById("card-button");
    cardButton.addEventListener("click", async function (event) {
      await handlePaymentMethodSubmission(event, card);
    });
  };

  return (
    <>
      {" "}
      <form id="payment-form">
        <div id="card-container">{
          cardLoading && <span>
              <BuzopsLoader type="content" style={{}} />
            </span>
          }
        </div>
        {cardLoading ? (
          <></>
        ) : (
          <div className="bz-square-btn-wrapper">
            {props?.showCancel && (
              <button
                id="cancel-button"
                type="button"
                onClick={() => props?.handleCancel()}
                className="k-button ml-0"
              >
                Cancel
              </button>
            )}
            <button
              id="card-button"
              type="button"
              className="k-button k-primary mr-0"
            >
              Continue
            </button>
          </div>
        )}
      </form>
      <Helmet>
        <script src={userDetails?.ChargehubSquarePaymentProfile?.Mode === "SANDBOX" ? "https://sandbox.web.squarecdn.com/v1/square.js" : "https://web.squarecdn.com/v1/square.js"} type="text/javascript" />
      </Helmet>
    </>
  );
};
export default SquarePayments;
