import { CommonServiceUtils } from "services/common-service-utils";

export class ThirdPartyConfigService extends CommonServiceUtils {
  async getSecuritySystemConfigs(): Promise<any> {
    let res = { success: false, data: null, msg: null };
    const getConfigs: any = await this.getData(
      `${this.apiUrlCreator(`SecuritySystem/Get`)}`
    );
    return this.prepareResponseFromServer(getConfigs);
  }

  async saveSecuritySystemConfigs(req: any): Promise<any> {
    const saveConfigs: any = await this.postData(
      `${this.apiUrlCreator(`SecuritySystem/Save`)}`,
      req
    );
    return this.prepareResponseFromServer(saveConfigs);
  }

  async getSecuritySystemActivityLog(): Promise<any> {
    const getActivityLog: any = await this.getData(
      `${this.apiUrlCreator(`SecuritySystem/ActivityLog`)}`
    );

    return this.prepareResponseFromServer(getActivityLog);
  }

  async getSecuritySystemActivityLogPagination(
    pageNumber: number,
    pageSize: number
  ): Promise<any> {
    const getActivityLog: any = await this.getData(
      `${this.apiUrlCreator(
        `SecuritySystem/ActivityLog?pageNumber=${pageNumber}&pageSize=${pageSize}`
      )}`
    );

    return this.prepareResponseFromServer(getActivityLog);
  }

  async saveHardwareType(type: number, req: any): Promise<any> {
    const response: any = await this.postData(
      `${this.apiUrlCreator(`SecuritySystem/SetHardwareProvider/${type}`)}`,
      req
    );
    return this.prepareResponseFromServer(response);
  }

  async checkLivestreamStatus(): Promise<any> {
    const response: any = await this.getData(
      `${this.apiUrlCreator(`HardwareAccess/CheckLivestreamConnection`)}`
    );
    return this.prepareResponseFromServer(response);
  }

  async startLiveStream(): Promise<any> {
    const response: any = await this.postData(
      `${this.apiUrlCreator(`HardwareAccess/StartLivestreamConnection`)}`,
      {}
    );
    return this.prepareResponseFromServer(response);
  }

  async stopLiveStream(): Promise<any> {
    const response: any = await this.postData(
      `${this.apiUrlCreator(`HardwareAccess/StopLivestreamConnection`)}`,
      {}
    );
    return this.prepareResponseFromServer(response);
  }
}
