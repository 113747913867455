import { OfferTypeEnum } from "./form-utils";
import { GetLocalStore, LocalStore } from "./storage";
import jwt_decode from "jwt-decode";

export const checkIsvalidTenantToken = (token: string) => {
  const decoded: any = jwt_decode(token);
  if (Date.now() >= decoded.exp * 1000) {
    return false;
  }
  return true;
};
export enum appointmentTab {
  UPCOMING = "Upcoming",
  COMPLETED = "Past"
}
const checkTenantSession = async (service: any) => {
  if (
    !GetLocalStore("tenantUserSession") ||
    !checkIsvalidTenantToken(GetLocalStore("token"))
  ) {
    const hostValues = window.location.host.split(".");
    if (
      hostValues.length === 3 &&
      hostValues[0] !== "www" &&
      hostValues[0] !== "ui"
    ) {
      const tenantName = hostValues[0];
      LocalStore("tenant", tenantName);
    }
    await service.getTenantDetails(GetLocalStore("tenant"));
  }
};

const checkValidEmail = (email: string) => {
  const emailRegex = new RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  return emailRegex.test(email);
};

const hasWhiteSpace = (str: string) => {
  return /\s/g.test(str);
};
export default { checkTenantSession, checkValidEmail, hasWhiteSpace };



export const randomString=(length:number) =>{
  const chars='0123456789abcdefghijklmnopqrstuvwxyz'
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

export const CalculateOffer=(originalPrice:any,OfferDetails:any)=>{
  let cost=originalPrice
  if(OfferTypeEnum[OfferDetails?.Type] === "Fixed"){
    cost = cost <= OfferDetails?.Amount ? 0 : cost - OfferDetails?.Amount;
  }else{
    const coupvalue=cost * (OfferDetails?.Amount / 100)
    const coupAmt=parseFloat(coupvalue.toFixed(2))
    cost = OfferDetails?.Amount === 100 ? 0 : cost - coupAmt;
  }
  return cost
}

export function debounce<T extends (...args: any[]) => void>(func: T, wait: number): T {
  let timeout: NodeJS.Timeout;
  return function (this: any, ...args: Parameters<T>): void {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  } as T;
}

export const getCroppedImg = (imageSrc: string, croppedAreaPixels: any): Promise<string> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (ctx) { // Check if ctx is not null
        const scaleX = image.width / image.naturalWidth;
        const scaleY = image.height / image.naturalHeight;

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          image,
          croppedAreaPixels.x * scaleX,
          croppedAreaPixels.y * scaleY,
          croppedAreaPixels.width * scaleX,
          croppedAreaPixels.height * scaleY,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'croppedImage.jpg', { type: 'image/jpeg' });
            resolve(URL.createObjectURL(file)); // Return the URL for the uploaded image
          }
        }, 'image/jpeg');
      } else {
        console.error("Failed to get canvas context");
        resolve(""); // or handle error as needed
      }
    };
  });
};

