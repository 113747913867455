import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import ClientNameWithMemberType from "../ClientNameWithMemberType";
import { Button } from "@progress/kendo-react-buttons";
import { useSelector } from 'react-redux'
import { CheckUserMemberStatus } from "utils";
import withNotification from "components/Hoc/withNotification";
import BuzopsLongNameHandler from "generic-components/BuzopsLongNameHandler";

const Addons = (props: any) => {
  const [availAddons, setAvailAddons] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [selectedPlanInfo, setSelectedPlanInfo] = useState<any>(null);
  const service = new TenantService();
  const selectedPlan = useSelector((state: any) => state?.generic?.selectedPlan)
  useEffect(() => {
    fetchAddons();
  }, []);


  const CheckSubscribeAddon = (SubscriptionStatus: any) => {
    return SubscriptionStatus === "Suspended" || SubscriptionStatus === "In-Active" || SubscriptionStatus === "Expired" || SubscriptionStatus === "Cancelled"
  }

  const handleSubscribeAddons = (val: any) => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    props.handleSelectionItem(val?.MemberPlanId, val?.planStatusDescription, "purchaseaddon", val);
  }

  const fetchAddons = async () => {
    let memberId = props?.userData?.UserMemberId;
    let memberPlanId
    if (selectedPlan) {
      memberPlanId = selectedPlan?.id
    }
    const req = {
      PageNumber: 0,
      PageSize: 32767,
      UserMemberId: memberId,
      MemberPlanId: memberPlanId
    };
    if (memberId) {
      setLoading(true);
      const res = await service?.getAllAddOnSummary(req);
      if (selectedPlan) {
        const planres = await service?.getMemberPlanSummary(req);
        setSelectedPlanInfo(planres);
      }
      setLoading(false);
      setAvailAddons(res);
    }
  };
  return (
    <>
      {loading ? (
        <>
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <>
          <div className="dFlexBtn bz-planAddon">
            <h5>{(selectedPlanInfo && selectedPlanInfo?.PlanName) ? `Addons for membership - ${selectedPlanInfo?.PlanName}` : ''}</h5>
            {(selectedPlanInfo && selectedPlanInfo?.MemberPlanId) && (<span className="float-right">
              {props?.staffPage && !CheckSubscribeAddon(selectedPlanInfo?.planStatusDescription) && (
                <div className="bz-schedule-btn-containet mb-2">
                  <Button
                    className={"bz-schedule-btn"}
                    primary={true}
                    onClick={() => {
                      handleSubscribeAddons(selectedPlanInfo)
                    }}
                  >
                    Purchase
                  </Button>
                </div>
              )}
            </span>)}
          </div>
          {availAddons.length === 0 ? (
            <div className={"bz-norecords-available text-muted"}>
              No Subscribed Addons{" "}
            </div>
          ) : (<>
            <div className={"row"}>
              {availAddons.map((val: any, ind: any) => {
                return (
                  <div className={"col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4"}>
                    <div
                      className={`card bz-service-card bz-plans-card bz-client-icon bz-shadow bz-arrow-right ${val?.Status} mb-3`}
                    >
                      <div
                        className={"card-body p-3"}
                        onClick={() => {
                          props.handleSelectionItem(val.EntityId, val?.Status, "addon", val) 
                        }}
                      >
                        <div className={"bz-service-card-inner pl-0"}>
                          <h5
                            className={
                              "card-title d-flex align-items-center"
                            }
                          >
                           
                            <span className="bz-plan-title">
                              <BuzopsLongNameHandler
                                name={val.ServiceName}
                                maxLength={24}
                                className="taxName-tooltip"
                              />
                            </span>
                            <span
                              className={`badge badge-status-${val?.Status}`}
                            >
                              {val?.Status}
                            </span>
                          </h5>

                          <p className={"card-subtitle mb-2 text-muted flex-txt"}>
                            <span className="txt-title">Next Recurrence</span>
                            <span className="title-column">:</span>
                            <span>
                              {val.NextRecurrence !== "N/A"
                                ? moment(val.NextRecurrence).format("L")
                                : "N/A"}
                            </span>
                          </p>
                          <p className={"card-subtitle mb-2 text-muted flex-txt"}>
                            <span className="txt-title">Subscribed On</span>{" "}
                            <span className="title-column">:</span>
                            <span>{val.PurchasedDateTime !== "N/A"
                              ? moment(val.PurchasedDateTime).format("L")
                              : "N/A"}</span>
                          </p>
                          <p className={"card-subtitle mb-2 text-muted flex-txt"}>
                            <span className="txt-title">Membership</span>
                            <span className="title-column">:</span>
                            <span>{val?.PlanName}</span>
                          </p>
                          <p
                            className={
                              "card-subtitle d-flex align-items-center mb-2 text-muted bz-member-type"
                            }
                          >
                            <ClientNameWithMemberType memberType={val?.MemberType} clientName={val?.MemberName} />
                          </p>
                        </div>
                        <span className={"bz-arrow-icon"}>
                          <i className={"fas fa-caret-right"}></i>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div></>
          )}
        </>
      )}
    </>
  );
};

export default withNotification(Addons)
