import { CommonServiceUtils } from "services/common-service-utils";

export class SimpleAccessService extends CommonServiceUtils {
  async getMembersWithAccess(req: any): Promise<any> {
    let response = [];
    const accessData: any = await this.postData(
      `${this.apiUrlCreator("SimpleAccess/CheckMemberAccess")}`,
      req
    );
    if (accessData?.ResponseCode === 100) {
      response = accessData.Items;
    }
    return response;
  }

  async getSatffsWithAccess(req: any): Promise<any> {
    let response = [];
    const accessData: any = await this.postData(
      `${this.apiUrlCreator("HardwareAccess/CheckAssociateAccess")}`,
      req
    );
    if (accessData?.ResponseCode === 100) {
      response = accessData.Items;
    }
    return response;
  }

  async getSimpleAccessAuditInfo(req: any): Promise<any> {
    let response = [];
    const accessData: any = await this.postData(
      `${this.apiUrlCreator("SimpleAccess/Audit")}`,
      req
    );
    if (accessData?.ResponseCode === 100) {
      response = accessData.Items;
    }
    return response;
  }

  async saveSimpleAccessCard(req: any): Promise<any> {
    try {
      const accessData: any = await this.postData(
        `${this.apiUrlCreator("SimpleAccess/Save")}`,
        req
      );
      console.log(accessData);
      return this.prepareResponseFromServer(accessData);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async getSimpleAccessUserLookup(
    searchVal: any,
    paramType: number
  ): Promise<any> {
    try {
      const getSimpleAccessUserLookup: any = await this.getData(
        `${this.apiUrlCreator(
          `SimpleAccess/userLookup?searchVal=${searchVal}&paramType=${paramType}`
        )}`
      );
      if (getSimpleAccessUserLookup?.ResponseCode === 100) {
        return this.prepareResponseFromServer(getSimpleAccessUserLookup);
      }
      return {};
    } catch (err) {
      return this.prepareResponseFromServer(err);
    }
  }

  async updateSimpleAccessCard(req: any): Promise<any> {
    try {
      const accessData: any = await this.postData(
        `${this.apiUrlCreator("SimpleAccess/UpdateStatus")}`,
        req
      );
      console.log(accessData);
      return this.prepareResponseFromServer(accessData);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async sendEmail(req: any): Promise<any> {
    try {
      const accessData: any = await this.postData(
        `${this.apiUrlCreator("SimpleAccess/EmailAccessCard")}`,
        req
      );
      console.log(accessData);
      return this.prepareResponseFromServer(accessData);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async getSimpleAccessQuickID(): Promise<any> {
    try {
      const response: any = await this.getData(
        `${this.apiUrlCreator(`SimpleAccess/generateQuickId`)}`
      );
      if (response?.ResponseCode === 100) {
        return this.prepareResponseFromServer(response);
      }
      return {};
    } catch (err) {
      return this.prepareResponseFromServer(err);
    }
  }

  async createOpenPathMobileCredential(req: any): Promise<any> {
    try {
      const response: any = await this.postData(
        `${this.apiUrlCreator("HardwareAccess/Save")}`,
        req
      );
      return this.prepareResponseFromServer(response);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async removeOpenPathMobileCredential(req: any): Promise<any> {
    try {
      const response: any = await this.postData(
        `${this.apiUrlCreator("HardwareAccess/Save")}`,
        req
      );
      return this.prepareResponseFromServer(response);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async checkMemberHardwareAccess(req: any): Promise<any> {
    try {
      const response: any = await this.postData(
        `${this.apiUrlCreator("HardwareAccess/CheckMemberAccess")}`,
        req
      );
      return this.prepareResponseFromServer(response);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }
  async updateMemberHardwareAccess(req: any): Promise<any> {
    try {
      const response: any = await this.postData(
        `${this.apiUrlCreator("HardwareAccess/UpdateStatus")}`,
        req
      );

      return this.prepareResponseFromServer(response);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async getHardwareAccessAuditInfo(req: any): Promise<any> {
    try {
      const response: any = await this.postData(
        `${this.apiUrlCreator("HardwareAccess/Audit")}`,
        req
      );
      return this.prepareResponseFromServer(response);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async resendSetupEmail(req: any): Promise<any> {
    try {
      const response: any = await this.postData(
        `${this.apiUrlCreator(
          "HardwareAccess/SendMobileCredentialSetupEmail"
        )}`,
        req
      );
      return this.prepareResponseFromServer(response);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async getMembersWithAccessForHardware(req: any): Promise<any> {
    let response = [];
    const accessData: any = await this.postData(
      `${this.apiUrlCreator("HardwareAccess/CheckMemberAccess")}`,
      req
    );
    if (accessData?.ResponseCode === 100) {
      response = accessData.Items;
    }
    return response;
  }

  async getHardwareAccessLookup(
    searchVal: any,
    paramType: number
  ): Promise<any> {
    try {
      const getHardwareAccessLookup: any = await this.getData(
        `${this.apiUrlCreator(
          `HardwareAccess/userLookup?emailAddress=${searchVal}`
        )}`
      );
      if (getHardwareAccessLookup?.ResponseCode === 100) {
        return this.prepareResponseFromServer(getHardwareAccessLookup);
      }
      return {};
    } catch (err) {
      return this.prepareResponseFromServer(err);
    }
  }

  async checkIfHardwareIsOnline(): Promise<any> {
    const checkHardware: any = await this.getData(
      `${this.apiUrlCreator(
        `HardwareAccess/IsOnline`
      )}`
    );
    if (checkHardware?.ResponseCode === 100) {
      return checkHardware?.Item;
    }
    return true;
  }

  async checkMemberPassportAccess(req: any): Promise<any> {
    try {
      const response: any = await this.postData(
        `${this.apiUrlCreator("PassportAccess/CheckMemberAccess")}`,
        req
      );
      return this.prepareResponseFromServer(response);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async getPassportAccessAuditInfo(req: any): Promise<any> {
    let response = [];
    const accessData: any = await this.postData(
      `${this.apiUrlCreator("PassportAccess/Audit")}`,
      req
    );
    if (accessData?.ResponseCode === 100) {
      response = accessData.Items;
    }
    return response;
  }

  async removePassportAccessCredential(req: any): Promise<any> {
    try {
      const response: any = await this.postData(
        `${this.apiUrlCreator("PassportAccess/Save")}`,
        req
      );
      return this.prepareResponseFromServer(response);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async updateMemberPassportAccess(req: any): Promise<any> {
    try {
      const response: any = await this.postData(
        `${this.apiUrlCreator("PassportAccess/UpdateStatus")}`,
        req
      );

      return this.prepareResponseFromServer(response);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async createPassportCredential(req: any): Promise<any> {
    try {
      const response: any = await this.postData(
        `${this.apiUrlCreator("PassportAccess/Save")}`,
        req
      );
      return this.prepareResponseFromServer(response);
    } catch (err) {
      console.log(err);
      return this.prepareResponseFromServer(err);
    }
  }

  async getStaffwithPassportAccess(req: any): Promise<any> {
    let response = [];
    const accessData: any = await this.postData(
      `${this.apiUrlCreator("PassportAccess/CheckAssociateAccess")}`,
      req
    );
    if (accessData?.ResponseCode === 100) {
      response = accessData.Items;
    }
    return response;
  }

}
