import {
  TabStrip,
  TabStripTab
} from "@progress/kendo-react-layout";
import React from "react";
import Addons from "./../Addons/Addons";
import Plans from "./Plans";
import { Button } from "@progress/kendo-react-buttons";
import AssignPlan from "../PlanSubscribe/AssignPlan";
import withNotification from "components/Hoc/withNotification";
import { CheckUserMemberStatus } from "utils";

const PlansAndAddons = (props: any) => {
  const [showAddPlan, setshowAddPlan] = React.useState(props?.showAddPlan ? true : false);
  const tabs = [{ title: "Memberships" }, { title: "Addons" }];
  const handleSelect = (e: any) => {
    const val = e.selected;
    props?.handleSelectTab(val)
  };

  const handleAddPlan = () => {
    if(!showAddPlan){
      if(!props?.staffPage && !CheckUserMemberStatus(props?.userData)){
        props?.handleNotificationMessage('', "error",true,'client');
        return
      }else if(props?.staffPage && !CheckUserMemberStatus(props?.userData) ){
        props?.handleNotificationMessage('', "error",false,'staff');
        return
      }
    }
    if(props?.staffPage){
      setshowAddPlan(!showAddPlan);
    }else{
      props?.goToPurchasePlanPage()
    }
  };

  if (showAddPlan) {
    return (
      <AssignPlan
        {...props}
        handleAddPlan={handleAddPlan}
        UserMemberId={props?.userData?.UserMemberId}
        staffPage={props.staffPage}
      />
    );
  }

  return (
    <>
    <div className="dFlexBtn bz-planAddon">
      <h5 className="mb-3 bz_fs_1_5">{"Memberships & Addons"}</h5>
      <span className="float-right">
          {true && (
            <div className="bz-schedule-btn-containet">
              <Button
                className={"bz-schedule-btn purchase-btn"}
                primary={true}
                onClick={() =>
                  handleAddPlan()
                }
              >
                Purchase
              </Button>
            </div>
          )}
        </span>
      </div>
      <TabStrip selected={props?.selectedTab} onSelect={handleSelect}>
        {tabs.map((val, indx) => {
          return (
            <TabStripTab title={val.title} key={indx}>
              {indx === 0 && (
                <Plans
                  {...props}
                  userData={props?.userData}
                  handleSelectionItem={(
                    selectedId: number,
                    status: string,
                    type: string,
                    dataItem: any
                  ) => props?.handleSelectionItem(selectedId, status, type,dataItem)}
                />
              )}
              {indx === 1 && (
                <Addons
                  {...props}
                  userData={props?.userData}
                  handleSelectionItem={(
                    selectedId: number,
                    status: string,
                    type: string,
                    dataItem: any
                  ) => props?.handleSelectionItem(selectedId, status, type,dataItem)}
                />
              )}
            </TabStripTab>
          );
        })}
      </TabStrip>
    </>
  );
};

export default withNotification(PlansAndAddons);
