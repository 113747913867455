import BuzopsWindow from 'generic-components/BuzopsWindow';
import React, { ReactNode } from 'react';
import ChargeBack from '.';


type ChargeBackGlobalProps = {
    clientId: string;
    paymentTitle?: ReactNode | string;
    onSuccessfullPayment: () => void;
}

const ChargeBackGlobal = ({clientId, paymentTitle, onSuccessfullPayment}: ChargeBackGlobalProps) => {
    console.log(clientId);
    const [visibility, setVisibility] = React.useState<boolean>(false);
    const [paymentStatus, setPaymentStatus] = React.useState<boolean>(false);

    const onSuccessfulPayment = () => {
        setPaymentStatus(true);
    }

    const renderSuccessMessage = () => {
        return <div className="bz-text-center">
            <span className="checkins-success-message">Payment successfully collected. <span className="btn-link checkins-success-message" onClick={() => {onSuccessfullPayment(); setVisibility(false) }}><u>Please Continue</u></span></span>
          </div>
    }
    const renderChargeBack = () => { 
        return (
            <BuzopsWindow
                key={visibility.toString()}
                reduceWidth={10}
                onWindowClose={() => {
                    if(paymentStatus){
                        onSuccessfullPayment?.();
                    }
                    setVisibility(false)
                }}
                title={"Manage Chargeback and Returns"}
                className={"chargeback-global-window"}
                visibility={visibility}
            >
                {
                    !paymentStatus ? 
                    <ChargeBack clientId={clientId} onWindowClose={() => {
                        onSuccessfullPayment?.();
                        setVisibility(false)
                    }} onSuccessOfPayment={() => {
                        onSuccessfulPayment()
                    }} /> : renderSuccessMessage()
                }
            </BuzopsWindow>
        )
    }
    return (
        <>
            <span onClick={() => setVisibility(true)}>
                {paymentTitle || "Please click here to make a payment."}
            </span>
            {renderChargeBack()}
        </>
    )
}

export default ChargeBackGlobal;