import { useApiClient } from "services/axios-service-utils";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { TenantService } from "services/tenant/index.service";
import { GetLocalStore } from "utils/storage";

const schema = Yup.object({
  CreditAmount: Yup.number()
    .typeError("Please enter a valid amount for the credit top-up")
    .min(0.01, "Amount should be greater than 0")
    .max(15000, "Amount should not be greater than 15k")
    .required(),
  PaymentGatewayPaymentProfileID: Yup.string().required(
    "Payment Profile is required"
  ),
});
const useAddCreditsTopUp = (props: any) => {

  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
  React.useState<any>(null);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const [paymentProfile, setPaymentProfile] = useState<any>([]);
  const paymentProfileData = new TenantService();
  const { axiosRequest } = useApiClient();
  const formik = useFormik({
    initialValues: {
      CreditAmount: 0,
      PaymentGatewayPaymentProfileID: "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const  fetchPaymentProfiles= async(userMemberId: any)=> {
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      setPaymentProfile(
        result?.map((i: any) => {
          if (i?.CardTypeId !== 8) {
            return { ...i };
          } else {
            return { ...i, MaskedCCNumber: "Credits" };
          }
        })
      );
    }
  }

  useEffect(() => {
    fetchAllApis();
  }, []);
  const fetchAllApis = async () => {
    if (props?.UserMemberId) {
      setLoading(true);
      await fetchPaymentProfiles(props?.UserMemberId);
      setLoading(false);
    }
  };
  const showError = (message: string) => {
    props?.handleNotificationMessage(message, "error");
  };

  const handleSubmit = async(values: any)=>{
    setBtnLoading(true);
    const payload = {
      UserMemberId: props?.UserMemberId,
      CreditAmount: values?.CreditAmount,
      PaymentGatewayPaymentProfileID: values?.PaymentGatewayPaymentProfileID,
      PaymentGatewayType: paymentGatewaytype,
    };

    await axiosRequest.post("PaymentProfile/addcredit", payload, {
      successCallBack: (response: any) => {
        console.log(response);
        handleReset();
        props?.handleCloseDialog(true);
        setBtnLoading(false);
      },
      errorCallBack: (response: any) => {
        console.log(response?.response, "response");
        showError(
          response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] ||
            "Error occured while adding credits to wallet"
        );
        setBtnLoading(false);
      },
    });
  };

  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };
  const handleChangeSavedCard = (val: any) => {
    setSelectedPaymentProfile(val);
  };
  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async (msg = null) => {
    if (msg) {
      props?.handleNotificationMessage(msg, "success");
    }
    setShowPaymentDialog(!showPaymentDialog);
    fetchPaymentProfiles(props?.UserMemberId);
  };

  const handleReset = () => {
    formik.resetForm();
    setSelectedPaymentProfile(undefined);
  };

  return {
    handleReset,
    handleSuccessClosePaymethodDialog,
    handleClosePaymethodDialog,
    handleChangeSavedCard,
    onCardOrBankAdd,
    addNewItem,
    loading,
    formik,
    paymentProfile,
    selectedPaymentProfile,
    showPaymentDialog,
    btnLoading,
  };
};

export default useAddCreditsTopUp;
