import { CommonServiceUtils } from "services/common-service-utils";
export class ManageTaxesService extends CommonServiceUtils {
  async getTaxes(req:any): Promise<any> {
    let response=[]
    const getTaxes: any = await this.postData(
      this.apiUrlCreator(`Tax/Filter`),req
    );
      response= getTaxes?.Items || [];
   return response
  }

  async saveTax(req: any): Promise<any> {
    const saveTax: any = await this.postData(
      this.apiUrlCreator(`Tax/Create`),
      req
    );
    return saveTax;
  }
  async updateTax(req: any): Promise<any> {
    const updateTax: any = await this.postData(
      this.apiUrlCreator(`Tax/Update`),
      req
    );
    return updateTax;
  }
  async enableTax(req: any): Promise<any> {
    const enableTax: any = await this.postData(
      this.apiUrlCreator(`Tax?hasTaxEnabled=${req?.hasTaxEnabled}`)
    );
    return enableTax;
  }
  async getTaxReport(req: any): Promise<any> {
    const getTaxReport: any = await this.postData(
      this.apiUrlCreator(`Tax/TaxReport`),req
    );
    return getTaxReport;
  }
  async getTaxHistory(req: any): Promise<any> {
    // const getCustomFields: any = await this.getData(
    //   this.apiUrlCreator(`site/GetCustomFields`)
    // );
    // if (getCustomFields?.ResponseCode === 100) {
    //   return getCustomFields?.Items;
    // } else {
    //   return [];
    // }
  }
}
