import BuzopsReportGrid from "generic-components/BuzopsReportGrid";
import BuzopsWindow from "generic-components/BuzopsWindow";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ClientDashboardWindow from "components/reports/Generic/clientDashboardWindow";
import useDetailedMRR from "../Container/DetailedMRR.hook";

const DetailedMRR = (props: any) => {
  const detailedMRR = useDetailedMRR(props);
  return (
    <>
      <div className="mt-4 subscribers-main-container">
        <div>
          <DropDownList
            data={
              detailedMRR.pageInfo?.data?.length > 0
                ? detailedMRR.pageInfo?.data[0].RecurrenceDates?.map(
                    (val: any) => val.Key
                  )
                : []
            }
            onChange={({ value }) =>
              detailedMRR.fetchData({
                fromDate: value.split("-")[0],
                toDate:
                  value.split("-").length > 1
                    ? value.split("-")[1]
                    : value.split("-")[0],
              })
            }
          />
        </div>
        <BuzopsReportGrid
          fileName="DetailedMRR.xlsx"
          //   handleSearch={handleSearch}
          toggleFullscreen={() => {
            detailedMRR.setUpdaterFullscreen(!detailedMRR.updaterFullscreen);
            var style = document.createElement("style");
            style.innerHTML = `body::-webkit-scrollbar {display: ${
              detailedMRR.updaterFullscreen ? "block" : "none"
            };}`;
            document.head.appendChild(style);
          }}
          result={detailedMRR.pageInfo.data}
          dataState={detailedMRR.pageInfo}
          dataStateChange={detailedMRR.fetchData}
          stateGridColumns={detailedMRR.stateGridColumns}
          FullScreen={detailedMRR.updaterFullscreen}
          pageSize={5}
          setStateGridColumns={(data: any) =>
            detailedMRR.setStateGridColumns(data)
          }
          onColumnsSubmit={detailedMRR.onColumnsSubmit}
          onCellClick={detailedMRR.onCellClick}
          excelData={detailedMRR.excelData}
          setExcelData={() => detailedMRR.setExcelData([])}
        />
        {/* </div> */}
      </div>
      <BuzopsWindow
        title={
          <>
            <div className={"buzops-window-title"}>
              <span
                className={"k-icon k-i-undo buzopsWindowBack"}
                onClick={() => detailedMRR.setShow(!detailedMRR.show)}
              ></span>
              &nbsp;{"MRR Data"}
            </div>
          </>
        }
        reduceWidth={10}
        className={"bzo-class-window"}
        visibility={detailedMRR.show}
        key={detailedMRR.show.toString()}
        onWindowClose={() => detailedMRR.setShow(!detailedMRR.show)}
      >
        <BuzopsReportGrid
          fileName="DetailedMRR.xlsx"
          //   handleSearch={handleSearch}
          toggleFullscreen={() => {
            detailedMRR.setUpdaterFullscreen(!detailedMRR.updaterFullscreen);
            var style = document.createElement("style");
            style.innerHTML = `body::-webkit-scrollbar {display: ${
              detailedMRR.updaterFullscreen ? "block" : "none"
            };}`;
            document.head.appendChild(style);
          }}
          result={detailedMRR.page1Info.data}
          dataState={detailedMRR.page1Info}
          dataStateChange={detailedMRR.fetchData1}
          stateGridColumns={detailedMRR.MRRDataColumns}
          FullScreen={detailedMRR.updaterFullscreen}
          pageSize={5}
          setStateGridColumns={(data: any) =>
            detailedMRR.setStateGrid1Columns(data)
          }
          onColumnsSubmit={detailedMRR.onColumns1Submit}
          onCellClick={detailedMRR.onCell1Click}
          excelData={detailedMRR.excel1Data}
          setExcelData={() => detailedMRR.setExcel1Data([])}
        />
      </BuzopsWindow>
      <ClientDashboardWindow
        {...props}
        clientID={detailedMRR.clientData?.clientId}
      />
    </>
  );
};

export default DetailedMRR;
