import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import withNotification from "components/Hoc/withNotification";
import { GetLocalStore } from "utils/storage";
import {useState} from 'react'
import BuzopsButton from "generic-components/BuzopsButton";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const StripeCard = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
    const stripe = useStripe();
  const elements = useElements();
  const handleCardAdd = () => {

    if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
    }

    const cardDetails: any = elements.getElement(CardElement);
    console.log(cardDetails);
    setLoading(true);
    stripe.createToken(cardDetails).then(function(result) {
        // Handle result.error or result.token
        if(result?.error){
          setLoading(false);
          props?.handleNotificationMessage(result?.error?.message, "error");
          return;
        }
        const cardData: any = result.token?.card;
      
        const cardTypeName = cardData.brand;
        const userDetails = GetLocalStore("userDetails");
        const usermemberId =
          userDetails?.UserSession?.UserMemberId || props?.UserMemberId;
        let CardtypeId;
        switch (cardTypeName) {
          case "Visa":
            CardtypeId = 6;
            break;
          case "MasterCard":
            CardtypeId = 5;
            break;
          case "JCB":
            CardtypeId = 4;
            break;
          case "Discover":
            CardtypeId = 3;
            break;
          case "Diners Club":
            CardtypeId = 2;
            break;
          case "American Express":
            CardtypeId = 1;
            break;
          default:
            CardtypeId = 7;
            break;
        }
        if(!CardtypeId){
          CardtypeId = 7;
        }
        const expiry_year = cardData.exp_year.toString().substr(-2);
        let expiry_month = cardData.exp_month.toString();
        expiry_month = "0" + expiry_month.substr(-2);
        const expiryMonthYear = `${expiry_month}${expiry_year}`;
        const cardIntialValues = {
          Token: result?.token?.id,
          MaskedCCNumber: result.token?.card?.last4,
          CardTypeId: CardtypeId,
          ExpiryMonthYear: expiryMonthYear,
          ExpiryMonth: cardData.exp_month,
          ExpiryYear: cardData.exp_year,
          ZipCode: cardData.address_zip,
          PaymentType: "Card",
          PaymentMethod: 1,
          UserMemberId: usermemberId,
        };
        props.handleCardSubmit(cardIntialValues);
        setLoading(false);
        console.log(result)
      }).catch(function(err: any){
        setLoading(false);
        console.log(err)
      });
  };
  return (
    <>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <div className="bz-square-btn-wrapper m-2">
        {props?.showCancel && (
          <button
            id="cancel-button"
            type="button"
            onClick={() => props?.handleCancel()}
            className="k-button ml-0"
            disabled={loading || props?.cardSubmitBtnLoading}
          >
            Cancel
          </button>
        )}
        <BuzopsButton
            primary
            label={"Continue"}
            className={"ml-0"}
            disable={loading || props?.cardSubmitBtnLoading}
            loading={loading || props?.cardSubmitBtnLoading}
            onClick={() => {
              handleCardAdd();
            }}
          />
        {/* <button
          id="card-button"
          type="button"
          className="k-button k-primary mr-0"
          onClick={() => handleCardAdd()}
          disabled={loading || props?.cardSubmitBtnLoading}
        >
          Continue
        </button> */}
      </div>
    </>
  );
};

export default withNotification(StripeCard);
