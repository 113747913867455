import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEnforceStatus } from "redux-slices/userSlice";
import { useApiClient } from "services/axios-service-utils";
import { ClassService } from "services/class/index.service";
import { TenantService } from "services/tenant/index.service";
import { GetLocalStore } from "utils/storage";
const initialShowDialogValues={
    addTopUp: false,
    activity: false
  }
const usePaymentMethod = (props: any) => {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState<any>(initialShowDialogValues);
  const [enforcePPValue, setEnforcePPValue] = useState<any>(false);
  const {
    IsEnforced=false,IsCardEnforced=false,UserMemberStatus=0,IsFormsEnforced=false
  } = useSelector((state: any) => state?.userConfiguration);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<any>(false);
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState<any>(false);
  
  const Payment = new TenantService();
  const bankIntialValues = {
    AccountName: undefined,
    BankAccountType: undefined,
    BankName: undefined,
    RoutingNumber: undefined,
    AccountNumber: undefined,
    UserMemberId: props?.userData?.UserMemberId,
    PaymentType: "Bank",
    isUsed: "",
  };
  const cardIntialValues = {
    MaskedCCNumber: undefined,
    CardTypeId: undefined,
    ExpiryMonthYear: undefined,
    ExpiryMonth: undefined,
    ExpiryYear: undefined,
    ZipCode: undefined,
    PaymentType: "Card",
    UserMemberId: props?.userData?.UserMemberId,
  };
  const { axiosRequest } = useApiClient();

  const [bankFormValues, setBankFormValues] = useState<any>(bankIntialValues);
  const [showBankModal, setShowBankModal] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>("Bank");
  const [intialValues, setIntialValues] = useState<any>([]);
  const [cardSubmitBtnLoading, setCardSubmitBtnLoading] =
    useState<boolean>(false);
  const [cardFormValues, setCardFormValues] = useState<any>(cardIntialValues);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [rescurringDetails, setRecurringDetails] = useState<any>([]);
  const [confirmationShwon, setConfirmationShown] = useState<boolean>(false);
  const [enforceBtnLoading, setEnforceBtnLoading] = useState<boolean>(false);
  const [paymentProfiles, setPaymentProfiles] = useState<any>([]);
  const [paymentsLoading, setPaymentsLoading] = useState<boolean>(false);
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [paymentGatewayType, setPaymentGatewayType] =
    useState<any>(paymentGatewaytype);
  const userDetails = GetLocalStore("userDetails");
  const userConfiguration =
    userDetails?.ChargehubSquarePaymentProfile || undefined;
  const isAdmin = GetLocalStore("CurrentApplicationType") === 1;

  useEffect(() => {
    fetchEnforceDetails(props?.userData?.UserMemberId);
    fetchPaymentProfiles(props?.userData?.UserMemberId);
    fetchSubscription(props?.userData?.UserMemberId);
  }, [props?.userData]);

  const toggleDeleteDialog=()=>{
    setShowDeleteConfirm(!showDeleteConfirm)
  }
  const handleDeletePaymentMethod=(dataItem:any)=>{
    setDeleteItem(dataItem)
    setShowDeleteConfirm(!showDeleteConfirm)
  }
  const fetchSubscription = async (UserMemberId: any) => {
    if (IsEnforced && IsCardEnforced) {
      return;
    }
    const result = await Payment.LinkedSubscription(UserMemberId);
    setIntialValues(
      result.sort(function (a: any, b: any) {
        return a?.EntityId - b?.EntityId;
      })
    );
  };

  const handleCloseDialog = async(refresh=false)=>{
    setShowDialog(initialShowDialogValues)
    if(refresh){
      props?.handleNotificationMessage(
        `Credits Added Successfully`,
        "success"
      );
      await fetchPaymentProfiles(props?.userData?.UserMemberId);
    }
  }

  const handleShowDialog=(name: string) => {
    setShowDialog({...showDialog, [name]:!showDialog[name]});
  }
  const fetchPaymentProfiles = async (UserMemberId: any) => {
    setPaymentsLoading(true);
    const result = await Payment.paymentProfile(UserMemberId);

    const filteredPayments = result?.filter(
      (itm: any) => itm?.CardTypeId !== -1
    );
    setPaymentsLoading(false);
    setPaymentProfiles(filteredPayments);
  };
  const fetchEnforceDetails  = async (userMemberId:any) => {
    await axiosRequest.get(`authentication/GetMemberStatus/${userMemberId}`, {
      successCallBack: (response: any) => {
        const data = response.data
        dispatch(setEnforceStatus({
          IsEnforced: data?.IsEnforced,
          IsCardEnforced: data?.IsCardEnforced,
          IsFormsEnforced: data?.IsFormsEnforced,
          UserMemberStatus: data?.UserMemberStatus,
        }))
        setEnforcePPValue(data?.IsCardEnforced && data?.IsEnforced ? true :false)
      },
      errorCallBack: (response: any) => {
        props?.handleNotificationMessage(response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] || "Internal server error", "error");
      },
    });
  }

  const handleCardSubmit = async (dataItem: any) => {
    if (paymentType === "addCard") {
      setCardSubmitBtnLoading(true);
      if (paymentGatewayType === 1) {
        dataItem.MaskedCCNumber = dataItem.MaskedCCNumber.split(" ").join("");
      }
      if (IsCardEnforced && IsEnforced) {
        dataItem.UpdateMemberStatus = true;
      }
      const result = await Payment.createPaymentProfile(dataItem);
      setCardSubmitBtnLoading(false);
      if (result?.ResponseCode === 100) {
        const successMsg = result?.SuccessMessage;
        fetchPaymentProfiles(props?.userData?.UserMemberId);
        setShowBankModal(false);
        props?.handleNotificationMessage(successMsg, "success");
        setCardFormValues(cardIntialValues);
        if (!props?.staffPage && IsCardEnforced && IsEnforced) {
          await fetchEnforceDetails(props?.userData?.UserMemberId)
        }
      } else {
        const errorMsg = result?.ErrorMessages?.[0] || "Error In Creating";
        setShowBankModal(false);
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } else {
      const maskedNum = `xxxxxxxx${dataItem.MaskedCCNumber}`;
      const expiryValue = dataItem?.ExpiryMonthYear?.split("/");
      const req = {
        ExpiryMonthYear: dataItem?.ExpiryMonthYear,
        ExpiryMonth: expiryValue?.[0],
        ExpiryYear: expiryValue?.[1],
        ZipCode: dataItem?.ZipCode,
        UserMemberId: dataItem.UserMemberId,
        PaymentGatewayPaymentProfileID: dataItem.PaymentGatewayPaymentProfileID,
        CardTypeId: dataItem.CardTypeId,
        MaskedCCNumber: maskedNum?.toUpperCase(),
        PaymentMethod: "CreditCard",
      };
      setCardSubmitBtnLoading(true);
      const result = await Payment.updatePaymentProfile(req);
      setCardSubmitBtnLoading(false);
      if (result?.ResponseCode === 100) {
        const successMsg = "Payment Method Successfully";
        props?.handleNotificationMessage(successMsg, "success");

        fetchPaymentProfiles(props?.userData?.UserMemberId);
        setShowBankModal(false);
        setCardFormValues(cardIntialValues);
      } else {
        const errorMsg = result?.ErrorMessages?.[0] || "Error In Updating";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
  };

  const handleBankSubmit = async (dataItem: any) => {
    setCardSubmitBtnLoading(true);
    let req = { ...dataItem, BankAccountType: dataItem?.BankAccountType?.id };
    if (IsCardEnforced && IsEnforced) {
      req = { ...req, UpdateMemberStatus: true };
    }
    const result = await Payment.createPaymentProfile(req);
    setCardSubmitBtnLoading(false);
    if (result.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      fetchPaymentProfiles(props?.userData?.UserMemberId);
      setShowBankModal(false);
      setBankFormValues(bankIntialValues);
      if (!props?.staffPage && IsCardEnforced && IsEnforced) {
        await fetchEnforceDetails(props?.userData?.UserMemberId)
      }
    } else {
      setShowBankModal(false);
      setBankFormValues(dataItem);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const editBank = async (item: any) => {
    if (item?.CardTypeId === 0) {
      setPaymentType("bank");
      setShowBankModal(true);
    } else {
      const Result = await Payment.GetPaymentProfile(item);

      setPaymentType("editcard");
      const cardIntialValues = {
        MaskedCCNumber: item.MaskedCCNumber,
        CardTypeId: item.CardTypeId,
        ExpiryMonthYear: item.ExpiryDate,
        ExpiryMonth: undefined,
        ExpiryYear: undefined,
        ZipCode: Result?.ZipCode,
        PaymentType: "Card",
        UserMemberId: props?.userData?.MainUserId,
        PaymentGatewayPaymentProfileID: item.PaymentGatewayPaymentProfileID,
      };
      setCardFormValues(cardIntialValues);
      setShowBankModal(true);
    }
  };

  const deleteBank = async (item=deleteItem) => {
    const reqId = item?.PaymentGatewayPaymentProfileID;
    setDeleteBtnLoading(true)
    const result = await Payment.deletePaymentProfile(reqId);
    setDeleteBtnLoading(false)
    if (result.ResponseCode === 300) {
      toggleDeleteDialog()
      const successMsg = result?.SuccessMessage;
      fetchPaymentProfiles(props?.userData?.UserMemberId);
      fetchSubscription(props?.userData?.UserMemberId);
      props?.handleNotificationMessage(successMsg, "success");
    }else{
      props?.handleNotificationMessage(result?.ErrorMessages[0], "success");
    }
  };

  const changePaymentProfile = async (d: any, data: any) => {
    const changeData = {
      ...data,
      PaymentProfileId: parseInt(d?.PaymentGatewayPaymentProfileID),
    };
    setConfirmationShown(true);
    setRecurringDetails(changeData);
  };
  const confirmChangePayment = async () => {
    setBtnLoading(true);
    const result = await Payment?.ChangedSubscription(rescurringDetails);
    if (result.ResponseCode === 100) {
      fetchSubscription(props?.userData?.UserMemberId);
      setConfirmationShown(false);
      props?.handleNotificationMessage(result?.SuccessMessage, "success");
      fetchPaymentProfiles(props?.userData?.UserMemberId);
      setBtnLoading(false);
    } else {
      setConfirmationShown(false);
      setBtnLoading(false);
      props?.handleNotificationMessage(result?.ErrorMessages[0], "error");
    }
  };
  const handleChangeEnforce = async (val: boolean) => {
    let status = IsFormsEnforced ? 32 : 0;
    if (val) {
      console.log(UserMemberStatus)
      status = IsFormsEnforced ? 48 : 16;
    }
    setEnforcePPValue(val)
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      Status: status,
    };
    setEnforceBtnLoading(true);
    const service = new ClassService();
    const res = await service.AllowServicesForClient(req);
    if (res?.ResponseCode === 100) {
      const successMsg = res?.SuccessMessage || "Successfully Modified";
      props?.handleNotificationMessage(successMsg, "success");
      await  props?.onSuccessfullPayment();
      setEnforceBtnLoading(false);
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in Updating Status";
      props?.handleNotificationMessage(errorMsg, "error");
      setEnforceBtnLoading(false);
    }
  };
  return {
    enforcePPValue,
    handleChangeEnforce,
    showBankModal,
    cardSubmitBtnLoading,
    handleCardSubmit,
    bankFormValues,
    handleBankSubmit,
    paymentType,
    cardFormValues,
    isAdmin,
    paymentGatewayType,
    userConfiguration,
    cardIntialValues,
    bankIntialValues,
    setBankFormValues,
    setCardFormValues,
    setShowBankModal,
    intialValues,
    changePaymentProfile,
    paymentProfiles,
    confirmationShwon,
    confirmChangePayment,
    setConfirmationShown,
    btnLoading,
    paymentsLoading,
    setPaymentType,
    editBank,
    deleteBank,
    handleShowDialog,
    showDialog,
    handleCloseDialog,
    enforceBtnLoading,
    toggleDeleteDialog,
    showDeleteConfirm,
    handleDeletePaymentMethod,
    deleteBtnLoading
  };
};

export default usePaymentMethod;
