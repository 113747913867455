import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect } from "react";
import useState from "react-usestateref";
import { CapitalizeStr, CurrencyFormat, getFullImageUrl } from "utils";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import PurchasePackageList from "components/package/PurchasePackageList";
import PurchasePlanList from "components/plans/PurchasePlanList";
import Service from "components/service/Service";
import { TenantService } from "services/tenant/index.service";
import {
  OfferTypeEnum,
} from "utils/form-utils";
import { useHistory, useParams } from "react-router-dom";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import { GetLocalStore } from "utils/storage";
import { useApiClient } from "services/axios-service-utils";

export const GenerateOfferLabel = ({ offerObj }: any) => {
  return (
    <span className="badge badge-offer" style={{ margin: '0px', display: 'block', textTransform: 'lowercase', fontSize: '12px' }}>
      {`${OfferTypeEnum[offerObj?.Type] === "Fixed"
        ? CurrencyFormat(offerObj?.Amount)
        : `${offerObj?.Amount} %`
        } discount `}
      {offerObj?.DurationType === 2 && "for every purchases"}
      {offerObj?.DurationType === 3 &&
        `For first ${offerObj?.MinimumCycle} purchases`}
    </span>
  );
};

const AffiliateSubscriptions = () => {
  const history = useHistory();
  const { axiosRequest } = useApiClient();
  const [providerLoading, setProviderLoading] = useState(true);
  const [promoLinkDetails, setPromoLinkDetails, promoLinkDetailsref] =
    useState<any>(null);
  const paramsData: any = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const affiliateService = new AffiliateProgramService();
  const tenantDetails = GetLocalStore("tenantDetails");
  const ClubId = GetLocalStore("tenantUserSession")?.ClubId;
  const [isServiceLoading, setIsServiceLoading] = useState(true);
  const [servicesData, setServicesData] = useState<any>([]);

  const handleTabSelect = (tabValue: number) => {
    setSelectedTab(tabValue);
  };
  const tabs = [
    {
      title: "Memberships",
      visible: tenantDetails?.IsMemberplanTabVisible,
      id: 3,
    },
    { title: "Packages", visible: tenantDetails?.IsPackageTabVisible, id: 2 },
    {
      title: "Appointments",
      visible: tenantDetails?.IsIndividualServicesTabVisible,
      id: 1,
    },
  ];

  useEffect(() => {
    fetchPromoLinkDetails();
  }, [paramsData]);

  const fetchPromoLinkDetails = async () => {
    const { promotionCode, affiliateCode, subscriptionCode } = paramsData;
    let req: any = {
      promotionCode,
      affiliateCode,
      clubId: ClubId,
    };
    let res = null;
    setProviderLoading(true);
    if (subscriptionCode) {
      req = { ...req, subscriptionCode };
      res = await affiliateService.GetPromotionWithAffiliateSubscriptionDetails(
        req
      );
    } else {
      res = await affiliateService.GetPromotionWithAffiliateDetails(req);
    }
    setPromoLinkDetails(res);
    setProviderLoading(false);
    // await fetchServiceInstance();
  };
  const handleRedirection = () => {
    history.push('/summary', { purchaseoneonone: true })
  };
  async function fetchServiceInstance() {
    let req: any = {
      IncludeHostOnlineOnly: true,
      PromotionId: promoLinkDetailsref?.current?.PromotionDetails?.PromotionId,
    };
    if (paramsData?.subscriptionCode) {
      req = { ...req, ShortCode: paramsData?.subscriptionCode };
    }
    req = { ...req, IsActive: true }
    setIsServiceLoading(true);
    const servicedet = await axiosRequest.post('Appointments/Inventory', req);
    const result = servicedet.data?.Items
    if (result) {
      setServicesData(result);
    }
    setIsServiceLoading(false);
  }

  const renderProvider = () => {
    if (providerLoading) {
      return <BuzopsLoader />;
    }
    return (
      <div>
        <div className="bzo-provider-sec pb-sm-2">
          <div className="d-flex align-items-center">
            <div className="">
              <div className="provider-img-new">
                <img
                  className="bz-user-img align-self-center mr-md-2"
                  src={
                    promoLinkDetails?.AffiliateDetails?.Photo
                      ? getFullImageUrl(
                        promoLinkDetails?.AffiliateDetails?.Photo
                      )
                      : "/images/user.png"
                  }
                  alt="AffiliateImage"
                />
              </div>
            </div>
            <div className="provider-text mb-0">
              <div className="row">
                <h4 className="bzo-provider-name">
                  <span className="">
                    {promoLinkDetails?.AffiliateDetails?.FullName}
                  </span>
                </h4>
              </div>
              <div className="bzo-provider-contact-details-sec">
                <div className="">
                  <span className="bzo-provider-contact-details mt-2 mb-0 ">
                    <i className="fas fa-envelope"></i>
                    <strong>
                      <span className="">
                        <a
                          href={`mailTo:${promoLinkDetails?.AffiliateDetails?.Email}`}
                        >
                          {promoLinkDetails?.AffiliateDetails?.Email}
                        </a>
                      </span>
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row affiliatesubscriptions">
          <div className="col-md-12">
            <p>
              {GetLocalStore("tenantDetails")?.AffiliatePromoDescription || "Discover exceptional discounts and exclusive offers through this specially crafted promotion link tailored to your needs. Simply click to explore and maximize your benefits today!"}

            </p>
          </div>
        </div>
      </div>
    );
  };
  let offerObj: any = null;
  const Coupon = promoLinkDetails?.PromotionDetails?.Offer;
  if (Coupon) {
    offerObj = {
      Type: Coupon?.Type,
      CouponId: Coupon?.CouponId,
      CouponCode: Coupon?.CouponCode,
      CouponDescription: Coupon?.Description,
      DurationType: Coupon?.DurationType,
      Amount: Coupon?.Amount,
      MinimumCycle: Coupon?.MinimumCycle,
    };
  }

  return (
    <div className="row justify-content-center  bz-classes-in-online bz-provider-section">
      <div className="col-12 col-xl-10">
        {!providerLoading && !promoLinkDetails?.AffiliateDetails ? (
          <div className="row affiliatesubscriptions InActiveaffiliatesubscriptions">
            <div className="col-md-12">

              <div style={{ display: 'flex', justifyContent: 'center', gap: '15px', alignItems: 'center' }} className="mobilestatusopps">
                <div  >
                  <p style={{ textAlign: 'left', width: '100%' }} >Oops! Looks like the promotion link is no longer valid.  If you would like to view the product offerings for this
                    location {" "}</p>

                </div>
                <div className="buttonmobilesttusopps">
                  <button className="btn btn-primary" onClick={() => handleRedirection()} style={{ color: '#443b38', borderColor: '#443b38', cursor: 'pointer', background: 'transparent', fontSize: '14px', width: '100px' }}>Click Here </button>
                </div>

              </div>

            </div>
          </div>
        ) : (
          <>
            {renderProvider()}
            <hr />
          </>
        )}

        {providerLoading ? (
          <BuzopsLoader type="list" />
        ) : (
          <>
            {promoLinkDetails?.AffiliateDetails ? (
              <>
                {!paramsData?.subscriptionCode ? (
                  <>
                    <TabStrip
                      selected={selectedTab}
                      onSelect={(e) => handleTabSelect(e.selected)}
                    >
                      {tabs
                        .filter((i) => i?.visible === true)
                        .map((item, index) => {
                          return (
                            <TabStripTab
                              title={`${CapitalizeStr(item.title)}`}
                              key={index}
                            >
                              {item?.id === 1 && (
                                <>
                                  <Service
                                    servicesData={servicesData}
                                    isServiceLoading={isServiceLoading}
                                    AffiliateDetails={
                                      promoLinkDetailsref?.current
                                        ?.AffiliateDetails
                                    }
                                    PromotionId={
                                      promoLinkDetailsref?.current
                                        ?.PromotionDetails?.PromotionId
                                    }
                                    offerObj={offerObj}
                                  />
                                </>
                              )}
                              {item?.id === 2 && (
                                <>
                                  <PurchasePackageList
                                    page={"online"}
                                    AffiliateDetails={
                                      promoLinkDetailsref?.current
                                        ?.AffiliateDetails
                                    }
                                    PromotionId={
                                      promoLinkDetailsref?.current
                                        ?.PromotionDetails?.PromotionId
                                    }
                                    offerObj={offerObj}
                                  />
                                </>
                              )}
                              {item?.id === 3 && (
                                <>
                                  <PurchasePlanList
                                    AffiliateDetails={
                                      promoLinkDetailsref?.current
                                        ?.AffiliateDetails
                                    }
                                    PromotionId={
                                      promoLinkDetailsref?.current
                                        ?.PromotionDetails?.PromotionId
                                    }
                                    offerObj={offerObj}
                                  />
                                </>
                              )}
                            </TabStripTab>
                          );
                        })}
                    </TabStrip>
                  </>
                ) : (
                  <>
                    {promoLinkDetailsref?.current?.SubscriptionDetails?.Type ===
                      1 && (
                        <Service
                          servicesData={servicesData}
                          isServiceLoading={isServiceLoading}
                          AffiliateDetails={
                            promoLinkDetailsref?.current?.AffiliateDetails
                          }
                          PromotionId={
                            promoLinkDetailsref?.current?.PromotionDetails
                              ?.PromotionId
                          }
                          offerObj={offerObj}
                          serviceShortName={
                            promoLinkDetailsref?.current?.SubscriptionDetails
                              ?.ShortCode
                          }
                        />
                      )}
                    {promoLinkDetailsref?.current?.SubscriptionDetails?.Type ===
                      4 && (
                        <PurchasePlanList
                          AffiliateDetails={
                            promoLinkDetailsref?.current?.AffiliateDetails
                          }
                          PromotionId={
                            promoLinkDetailsref?.current?.PromotionDetails
                              ?.PromotionId
                          }
                          offerObj={offerObj}
                          planShortName={
                            promoLinkDetailsref?.current?.SubscriptionDetails
                              ?.ShortCode
                          }
                        />
                      )}
                    {promoLinkDetailsref?.current?.SubscriptionDetails?.Type ===
                      8 && (
                        <PurchasePackageList
                          page={"online"}
                          AffiliateDetails={
                            promoLinkDetailsref?.current?.AffiliateDetails
                          }
                          PromotionId={
                            promoLinkDetailsref?.current?.PromotionDetails
                              ?.PromotionId
                          }
                          offerObj={offerObj}
                          packageShortName={
                            promoLinkDetailsref?.current?.SubscriptionDetails
                              ?.ShortCode
                          }
                        />
                      )}
                  </>
                )}
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default AffiliateSubscriptions;
