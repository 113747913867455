import { Field, Form, FormElement } from "@progress/kendo-react-form";
import BuzopsButton from "generic-components/BuzopsButton";
import { FormDropDownList, FormInput } from "utils/form-components";
import { BankAccountTypes } from "utils/form-utils";
import { BankAccountValidator, requiredValidator, routingNumberValidator } from "validators/validator";

const ShowBankForm = (props: any) => {
  return (
    <>
      <Form
        initialValues={props.bankFormValues}
        onSubmit={props.handleBankSubmit}
        render={(bankRenderForm: any) => (
          <>
            <FormElement style={{ width: "100%" }}>
            <div className="m-0 p-2">
            <div className="col-xl-12">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Field
                      id={"BankAccountType"}
                      name={"BankAccountType"}
                      component={FormDropDownList}
                      label={"Account Type"}
                      required={true}
                      validator={requiredValidator}
                      data={BankAccountTypes}
                      textField="text"
                      dataItemKey="id"
                      customvalidation={true}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Field
                      id={"AccountName"}
                      name={"AccountName"}
                      component={FormInput}
                      label={"Account Name"}
                      required={true}
                      validator={requiredValidator}
                      customvalidation={true}
                    />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Field
                      id={"BankName"}
                      name={"BankName"}
                      component={FormInput}
                      label={"Bank Name"}
                      required={true}
                      validator={requiredValidator}
                      customvalidation={true}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Field
                      id={"RoutingNumber"}
                      name={"RoutingNumber"}
                      component={FormInput}
                      required={true}
                      label={"Routing Number"}
                      validator={routingNumberValidator}
                      customvalidation={true}
                    />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Field
                      id={"AccountNumber"}
                      name={"AccountNumber"}
                      component={FormInput}
                      required={true}
                      label={"Account Number"}
                      validator={BankAccountValidator}
                      customvalidation={true}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex col-12 pt-4 pb-2">
                <BuzopsButton
                    primary
                    label={"Continue"}
                    type={"submit"}
                    className="ml-0"
                    disable={!bankRenderForm.allowSubmit}
                    loading={props.bankSubmitBtnLoading}
                  />
              </div>
            </div>
            </FormElement>
          </>
        )}
      />
    </>
  );
};

export default ShowBankForm;
