import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
const ConfirmationDialog = ({
  msg,
  cancelConfirmation,
  confirmChangePayment,
  btnLoading,
}: any) => {
  return (
    <Dialog
      title={"Confirmation"}
      onClose={() => {
        cancelConfirmation();
      }}
      className="bz-notes-dialog"
    >
      <div>
        <p className="text-left">{msg}</p>
        <div className="d-flex justify-content-end">
          {/* <BuzopsButton
            type={"reset"}
            onClick={() => cancelConfirmation()}
            className="ml-0"
            label={"Cancel"}
          /> */}
          <Button onClick={() => cancelConfirmation()}>Cancel</Button>
          <BuzopsButton
            type={"reset"}
            onClick={() => confirmChangePayment()}
            className="ml-0"
            loading={btnLoading}
            label={"Confirm"}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
