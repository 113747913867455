import BuzopsLoader from "generic-components/BuzopsLoader";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ListView } from "@progress/kendo-react-listview";
import { CurrencyFormat, checkIfUserCanAddBankAccount } from "utils";
import Summary from "./Summary";
import BuzopsButton from "generic-components/BuzopsButton";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import moment from "moment";
import { DropDownButton } from "@progress/kendo-react-buttons";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import withNotification from "components/Hoc/withNotification";
import ChargeBackListView from "./ChargeBackListView";
import useChargeBack from "./ChargeBack.hook";
import { Pager } from "@progress/kendo-react-data-tools";
export const AmountCell = (props: any, name: string) => {
  return <td>{CurrencyFormat(props?.dataItem?.[name])}</td>;
};

export const NameCell = (MemberName: string, MemberType: any) => {
  return (
    <td>
      <ClientNameWithMemberType
        clientName={MemberName}
        memberType={MemberType}
      />
    </td>
  );
};
export const DateCell = (props: any) => {
  return <td>{moment(props?.dataItem?.TransactionDate).format("L")}</td>;
};
export const StatusDescriptionCell=(propdata:any)=>{
  return <td>{propdata?.dataItem?.StatusDescription ? propdata?.dataItem?.StatusDescription: 'N/A'}</td>;
}
const insertItems = [
  {
    text: "Charge Back (CC)",
    key: "chargeback",
  },
  {
    text: "Return (ACH)",
    key: "return",
  },
];
const itemsList = [
  {
    id: 1,
    text: "All",
  },
  {
    id: 13,
    text: "Charge Backs (CC)",
  },
  {
    id: 12,
    text: "Returns (ACH)",
  },
];
const ChargeBack = (props: any) => {
  const {
    loading,
    showTransactionItems,
    setSelectedItem,
    handleAllowService,
    handleCollect,
    handleWaive,
    getClientId,
    insertItem,
    handleCloseModal,
    handleCloseConfirmModal,
    showConfirmDialog,
    handleCancel,
    transactionId,
    handleChargeBack,
    selectedItem,
    transactionItems,
    handleChangeTransactionId,
    handleContinue,
    addNewItem,
    handleClosePaymethodDialog,
    searchText,
    usermemberId,
    handleSearchText,
    handleSuccessClosePaymethodDialog,
    data,
    handleInsert,
    selectServiceType,
    handleSelectServiceType,
    handleChange,
    handleConfirmPopup,
    showPaymentDialog,
    onCardOrBankAdd,
    TotalAmount,
    showDialog,
    handleChangeSavedCard,
    ChargebackTransactionsSummary,
    page,
    handlePageChange,
    totalItems
  } = useChargeBack(props);
  const { skip, take } = page;

  const ConfirmModal = () => {
    let title = "";
    if (showConfirmDialog?.Collect) {
      title = "Confirm to Collect";
    } else if (showConfirmDialog?.Waive) {
      title = "Confirm to Waive";
    } else if (showConfirmDialog?.MemberStatus) {
      if (!selectedItem?.MemberStatus) {
        title = "Modify Allow Appointment/Membership use";
      } else {
        title = "Modify Allow Appointment/Membership use";
      }
    }
    return (
      <>
        <Dialog
          className="bz-book-Appoinment-dialog bz-chargeback-dialog"
          title={title}
          onClose={() => handleCloseConfirmModal()}
          style={{ zIndex: 10007 }}
        >
          <>
            {showConfirmDialog?.Collect || showConfirmDialog?.Waive ? (
              <>
                <div className="ready-to-book-appt-dialog">
                  <div className="column-1-section">
                    Client Name<span className="purchased-dots">:</span>
                  </div>
                  <div className="column-2-section">
                    {selectedItem?.ParentName}
                  </div>
                  <div className="column-1-section">
                    {`Total Amount to ${
                      showConfirmDialog?.Collect ? "Collect" : "Waive"
                    }`}
                    <span className="purchased-dots">:</span>
                  </div>
                  <div className="column-2-section">
                    {CurrencyFormat(selectedItem?.TotalAmount)}
                  </div>
                </div>
                {showConfirmDialog?.Waive && (
                  <div className="mt-2">
                    <Input
                      required
                      className="form-control pt-2"
                      style={{ width: "100%" }}
                      placeholder="Comments"
                      name="Comments"
                      id="Comments"
                      value={selectedItem?.Comments}
                      onChange={(e: any) =>
                        setSelectedItem({ ...selectedItem, Comments: e.value })
                      }
                    />
                  </div>
                )}
                <div className="column-button-section book-appt-btns">
                  <Button
                    primary={false}
                    className="ml-0"
                    disabled={loading?.collect || loading?.waive}
                    onClick={() => handleCloseConfirmModal()}
                  >
                    Cancel
                  </Button>
                  {showConfirmDialog?.Collect && (
                    <BuzopsButton
                      label={"Confirm"}
                      className="mr-0"
                      key={loading?.collect?.toString()}
                      loading={loading?.collect}
                      disabled={loading?.collect}
                      onClick={() => handleCollect(selectedItem)}
                    />
                  )}
                  {showConfirmDialog?.Waive && (
                    <BuzopsButton
                      label={"Confirm"}
                      className="mr-0"
                      loading={loading?.waive}
                      key={loading?.waive?.toString()}
                      disabled={!selectedItem?.Comments || loading?.waive}
                      onClick={() => handleWaive(selectedItem)}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="ready-to-book-appt-dialog">
                  <div className="column-1-section">
                    Client Name<span className="purchased-dots">:</span>
                  </div>
                  <div className="column-2-section">
                    {selectedItem?.ParentName}
                  </div>
                  <div className="column-1-section">
                    {`Total Amount`}
                    <span className="purchased-dots">:</span>
                  </div>
                  <div className="column-2-section">
                    {CurrencyFormat(selectedItem?.TotalAmount)}
                  </div>
                </div>
                <div className="column-button-section book-appt-btns">
                  <Button
                    primary={false}
                    className="ml-0"
                    disabled={loading?.MemberStatus}
                    onClick={() => handleCloseConfirmModal()}
                  >
                    Cancel
                  </Button>
                  <BuzopsButton
                    label={"Confirm"}
                    className="mr-0"
                    loading={loading?.MemberStatus}
                    disabled={loading?.MemberStatus}
                    onClick={() => handleAllowService(selectedItem)}
                  />
                </div>
              </>
            )}
          </>
        </Dialog>
      </>
    );
  };
  const TransactionUI = () => {
    return (
      <>
        {showTransactionItems ? (
          <>
            <Grid
              data={transactionItems?.Result}
              style={{ maxHeight: "300px", width: "auto" }}
              className="insert-charge-back-table"
            >
              <GridColumn
                field="MemberName"
                title="Client Name"
                width={150}
                cell={(props) =>
                  NameCell(
                    props?.dataItem?.MemberName,
                    props?.dataItem?.MemberType
                  )
                }
              />
              <GridColumn
                field="ServiceName"
                title="Service Name"
                width={150}
              />
              <GridColumn
                field="TransactionId"
                title="Transaction Id"
                width={150}
              />
              <GridColumn
                field="TransactionDate"
                title="Transaction Date"
                cell={DateCell}
                width={135}
              />
              <GridColumn
                field="AmountPaid"
                title="Amount"
                width={"100px"}
                cell={(props: any) => AmountCell(props, "AmountPaid")}
              />
            </Grid>
            <div className="pt-2 float-right">
              <Button
                primary={false}
                onClick={() => handleCancel()}
                disabled={loading?.updateTransaction}
              >
                Cancel
              </Button>
              <BuzopsButton
                label={"Confirm"}
                disabled={
                  transactionItems?.length === 0 || loading?.updateTransaction
                }
                onClick={() => handleChargeBack()}
                loading={loading?.updateTransaction}
                className="mr-0"
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <FieldWrapper>
                <Label editorId={"TransactionId"}>
                  {"Transaction Id"}{" "}
                  {<span className="required-asterisk">*</span>}
                </Label>
                <Input
                  autoComplete={"off"}
                  style={{ width: "100%" }}
                  name={"TransactionId"}
                  value={transactionId}
                  placeholder={"Please enter Transaction Id"}
                  onChange={(e: any) =>
                    handleChangeTransactionId(e.target.value)
                  }
                />
              </FieldWrapper>
              <div className="pt-2 float-right">
                <BuzopsButton
                  label={"Continue"}
                  onClick={() => handleContinue()}
                  loading={loading?.getTransaction}
                  disabled={loading?.getTransaction}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  };
  const ChargeBackModal = () => {
    return (
      <>
        <Dialog
          className="bz-insertChargeback-dialog"
          title={`Insert ${
            insertItem === "chargeback" ? "Charge Back (CC)" : "Return (ACH)"
          }`}
          onClose={() => handleCloseModal()}
          style={{ zIndex: 999 }}
        >
          {TransactionUI()}
        </Dialog>
      </>
    );
  };

  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${
            checkIfUserCanAddBankAccount() ? "Add Bank or Card" : "Add Card"
          }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={usermemberId}
          />
        </Dialog>
      </>
    );
  };

  if (loading?.screen) {
    return <BuzopsLoader type={"list"} />;
  }

  return (
    <div className="chargeback-card">
      {getClientId() && (
        <>
          <h6 className="mb-1 bz_fs_1_5">
            <button
              className="btn btn-primary bz-back-btn btn-sm"
              onClick={() =>
                props?.clientId ? props?.onWindowClose() : window.history.back()
              }
            >
              <i className="fas fa-arrow-left"></i>
            </button>
            &nbsp;{"Manage Charge Backs (CC) & Returns (ACH)."}
          </h6>
        </>
      )}
      <small className="bz-ol-muted pb-2">
        {
          "All the transactions related to Charge Backs (CC) & Returns (ACH) of all clients."
        }
      </small>
      <div className="row my-2">
        <div className="col-md-9">
          <div className="dflexBtn group-event-top-blk">
            {!getClientId() && (
              <>
                <div className="bz-classes-search col-md-4 pl-0">
                  <Input
                    type={"search"}
                    style={{ width: "100%" }}
                    className="form-control"
                    placeholder={`Search by Client/Transaction Id`}
                    onChange={(e) => handleSearchText(e.value)}
                    value={searchText}
                  />
                </div>
              </>
            )}
            <div className="pl-2 dropdown-serviceType">
              <DropDownList
                id={`serviceType`}
                name={`serviceType`}
                data={itemsList}
                style={{ width: "100%" }}
                textField="text"
                dataItemKey="id"
                value={selectServiceType}
                onChange={(e) => handleSelectServiceType(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3 insertbtn text-right">
          {/* <Button primary onClick={() => setShowDialog(!showDialog)} className="mr-0">
            Insert ChargeBack
          </Button> */}
          <DropDownButton
            text="Insert"
            onItemClick={(e: any) => handleInsert(e)}
            items={insertItems}
            className="btn btn-link add-new-btn mx-0"
          />
        </div>
      </div>
      {loading?.allTransaction ? (
        <>
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <>
          {data?.length === 0 ? (
            <>
              <div className="bz-norecords-available">No Records Available</div>
            </>
          ) : (
            <>
      
              <ListView
                item={(listViewProps: any) =>
                  ChargeBackListView({
                    ...listViewProps,
                    handleChange,
                    handleConfirmPopup,
                    onCardOrBankAdd,
                    handleChangeSavedCard,
                  })
                }
                data={data || []}
                style={{
                  width: "100%",
                }}
              />
              <Pager
                className="k-listview-pager-chargeback"
                skip={skip}
                responsive={true}
                // size={'small'}
                
                take={take}
                onPageChange={handlePageChange}
                total={totalItems}
                pageSizes={[5,10, 20, 50]}
              
              />
            </>
          )}
          <Summary
            selectServiceType={selectServiceType}
            ChargebackTransactionsSummary={ChargebackTransactionsSummary}
            TotalAmount={TotalAmount}
          />
        </>
      )}

      {showDialog && ChargeBackModal()}
      {showPaymentDialog && PaymethodDialog()}
      {(showConfirmDialog?.Collect ||
        showConfirmDialog?.Waive ||
        showConfirmDialog?.MemberStatus) &&
        ConfirmModal()}
    </div>
  );
};

export default withNotification(ChargeBack);
