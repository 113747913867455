export const SubsriberReportColumns = [
  {
    title: "Name",
    field: "MemberName",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Email",
    field: "Email",
    show: true,
    filter: "text",
    width: "250px",
  },
  {
    title: "Phone",
    field: "phone",
    show: true,
    filter: "text",
    width: "150px",
  },

  {
    title: "Membership Name",
    field: "SubscriptionName",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Start Date",
    field: "StartDate",
    show: true,
    filter: "date",
    width: "150px",
  },
  {
    title: "Signed Up Date",
    field: "signedUpDate",
    show: true,
    filter: "date",
    width: "170px",
  },
  {
    title: "Signed Up By",
    field: "signedUpBy",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Amount",
    field: "amount",
    show: true,
    filter: "numeric",
    width: "150px",
  },
  {
    title: "Status",
    field: "FormattedStatus",
    show: true,
    enableHtml: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Last Payment Date",
    field: "LastTransactionDate",
    show: false,
    filter: "date",
    width: "200px",
  },
  {
    title: "Last Payment Amount",
    field: "LastTransactionAmount",
    show: false,
    filter: "numeric",
    width: "250px",
  },
  {
    title: "Next EFT Amount",
    field: "RecurringAmount",
    show: false,
    filter: "numeric",
    width: "200px",
  },
  {
    title: "Next EFT Date",
    field: "NextRecurrence",
    show: false,
    filter: "date",
    width: "200px",
  },
  {
    title: "Amount collected",
    field: "amountCollected",
    show: false,
    filter: "numeric",
    width: "200px",
  },
  {
    title: "No of Renewals",
    field: "RenewalCount",
    show: false,
    filter: "numeric",
    width: "200px",
  },
  {
    title: "Last Audit",
    field: "lastAudit",
    show: false,
    filter: "date",
    width: "150px",
  },
];

export const SubscriberTypes: any = {
  1: {
    total: "TotalMembers",
    inActive: "InactiveMembers",
    active: "RegisteredMembers",
    frozen: "FreezeMembers",
    expired: "ExpiredMembers",
    cancelled: "CancelledMembers",
    aboutToExpire: "AboutExpiryMembers",
    suspended: "SuspendedMembers",
    future: "FutureMembers",
  },

  2: {
    total: "Total",
    inActive: "InActive",
    active: "Active",
    frozen: "Freezed",
    cancelled: "Cancelled",
    completed: "Completed",
    future: "Future",
  },
  3: {
    total: "Total",
    inActive: "InActive",
    active: "Active",
    frozen: "Freezed",
    cancelled: "Cancelled",
    completed: "Completed",
    future: "Future",
  },
  5: {
    total: "Total",
    inActive: "InActive",
    active: "Active",
    frozen: "Freezed",
    cancelled: "Cancelled",
    completed: "Completed",
    future: "Future",
  },
};

export const SubscriberStatus: any = {
  total: 0,
  inActive: 15,
  active: 1,
  frozen: 13,
  expired: 6,
  cancelled: 5,
  aboutToExpire: 14,
  suspended: 16,
  future: 17,
};

export enum SubscriberStatusTypes {
  total = "total",
  inActive = "inActive",
  active = "active",
  frozen = "frozen",
  expired = "expired",
  cancelled = "cancelled",
  aboutToExpire = "aboutToExpire",
  suspended = "suspended",
  future = "future",
  completed = "completed",
}

export enum SubscriptionTypes {
  MembershipPlan = 1,
  Addon = 2,
  IndividualService = 3,
  GroupService = 4,
  Packages = 5,
}
