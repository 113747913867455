import moment from "moment";
import { useEffect, useState } from "react";
import { ClassService } from "services/class/index.service";
import { GroupServiceColumns } from "../Utilities/constants";

const useGroupServices = () => {
  const [showDetailedView, setShowDetailedView] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [pageInfo, setPageInfo] = useState({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [stateGridColumns, setStateGridColumns] = useState(GroupServiceColumns);
  const service = new ClassService();

  const [fromDateToDate, setFromDateToDate] = useState({
    from: moment().startOf("year").startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });

  const fetchGroupClassesData = async (pageData?: any) => {
    let req = {
      ClassId: 0,
      FromDate: pageData?.fromDate || fromDateToDate.from,
      ToDate: pageData?.toDate || fromDateToDate.to,
      PageSize: pageData?.take || 10,
      PageNumber: pageData?.skip / pageData?.take || 0,
      SortDirection: "asc",
      SortBy: "ClassSessionStartDate",
    };
    const tempSelectedStatus = pageData?.status || selectedStatus;
    let status = {};
    if (tempSelectedStatus === "Active") {
      status = { ClassSessionStatus: "Active" };
    } else if (tempSelectedStatus === "Cancelled") {
      status = { IncludeInActive: true, ClassSessionStatus: "Cancelled" };
    } else {
      status = { IncludeInActive: true };
    }
    let getData = await service.filterClassSchedule({
      ...req,
      ...status,
    });

    setPageInfo((prev: any) => {
      prev.data = getData.Items;
      prev.pageNumber = req.PageNumber;
      prev.total = getData.TotalItems;
      if (pageData?.take) {
        prev.skip = pageData.skip;
        prev.take = pageData.take;
      }
      return { ...prev };
    });
  };

  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };
  const onCellClick = (item: any) => {
    setShowDetailedView(true);
    setSelectedItem(item);
  };

  useEffect(() => {
    fetchGroupClassesData({
      fromDate: fromDateToDate.from,
      toDate: fromDateToDate.to,
    });
  }, [fromDateToDate]);

  return {
    onCellClick,
    onColumnsSubmit,
    fetchGroupClassesData,
    setStateGridColumns,
    selectedStatus,
    stateGridColumns,
    pageInfo,
    setSelectedStatus,
    showDetailedView,
    setShowDetailedView,
    selectedItem,
    fromDateToDate,
    setFromDateToDate,
  };
};

export default useGroupServices;
