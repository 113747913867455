import React from 'react';
import PrimaryMember from "../../PrimaryMember.png";
import SubMember from "../../SubMember.png";

export interface ClientWithMemberTypeProps {
    memberType: number,
    clientName: string
}
const ClientNameWithMemberType = (props: ClientWithMemberTypeProps) => {

    const showImage = () => {

        if (!props.memberType || props.memberType === 0) {

            return <img title='Primary Client' src={PrimaryMember} className="bz-member-icon" />;
        } else {
            return <img title='Sub Client' src={SubMember} className="bz-member-icon" />;
        }

    }
    return (
        <>
            {props.clientName && showImage()}
            {props.clientName}
        </>
    )
}

export default ClientNameWithMemberType;