import React from "react";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { IPassportTechCredentialProps } from "../Utils/interfaces";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import usePassportTechCredentials from "../Container/PassportTechCredentials.hook";
import PassportMemberCredential from "./PassportMemberCredential";

const PassportTechCredentials: React.FunctionComponent<
  IPassportTechCredentialProps
> = (props) => {
  const passportTechCredentials = usePassportTechCredentials(props);

  return (
    <>
      {passportTechCredentials.loading ? (
        <BuzopsLoader type={"list"} />
      ) : (
        <div
          className=""
          style={{
            width: "100%",
            opacity: passportTechCredentials.processing ? 0.5 : 1,
          }}
        >
          <Card className="" style={{ width: "100%" }}>
            <CardHeader className="p-0">
              <CardTitle className="bz-card-title">{"Onsite Access"}</CardTitle>
            </CardHeader>
            <CardBody className="p-0">
              <>
                {passportTechCredentials.processing ? (
                  <Loader
                    type="infinite-spinner"
                    className="laoder-full-screen"
                  />
                ) : null}
                {passportTechCredentials?.memberPassportAccessData?.length >
                  0 &&
                  passportTechCredentials?.memberPassportAccessData?.map(
                    (accessData) => (
                      <PassportMemberCredential
                        {...accessData}
                        handleNotification={props.handleNotification}
                        setProcessing={passportTechCredentials.setProcessing}
                        checkMemberPassportAccess={
                          passportTechCredentials.checkMemberPassportAccess
                        }
                        StaffPage={false}
                      />
                    )
                  )}
                  {passportTechCredentials?.memberPassportAccessData?.length===0 ?   <div className="bz-norecords-available">
                  {"No Records Available"}
                </div>: null}
              </>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
};
export default PassportTechCredentials;
