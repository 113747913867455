import React, { useEffect, useState } from "react";
import { ClassService } from "services/class/index.service";
import { saveAs } from "@progress/kendo-file-saver";
import { AgreementType } from "utils";
import { TenantService } from "services/tenant/index.service";
import { GetLocalStore } from "utils/storage";

export const useAgreement = (props: any) => {
  const { selectedItemId, userData, type } = props;
  const userDetails = GetLocalStore("userDetails");
  const apiService = new ClassService();
  const [agreementDetails, setAgreementDetails] = useState([]);
  const [currentagreementDetails, setCurrentAgreementDetails] =
    useState<any>(null);
  const [loading, setLoading] = useState({
    page: false,
    download: false,
  });
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  //client details
  const [clientSubmitDetails, setClientSubmitDetails] = useState<any>({});
  const [toggleView, setToggleView] = useState(false);
  const [showWaiver, setShowWaiver] = useState(false);

  const [isUploadAgreementVisible, setIsUploadAgreementVisible] = useState(false);

  const toggleUploadAgreement = (visible:boolean) => {
    setIsUploadAgreementVisible(visible);
    if(!visible){
      fetchAgreements();
    }
  };

  useEffect(() => {
    fetchAgreements();
  }, []);

  const fetchAgreements = async () => {
    setLoading((prev) => {
      prev.page = true;
      return { ...prev };
    });
    let req = {
      // "SortBy": "AgreementDate",
      // "SortDirection": "asc",
      Where: {
        UserMemberId: userData?.UserMemberId,
        EntityId: selectedItemId ? selectedItemId : 0,
        EntityType: type ? AgreementType[type] : "None",
        IsEntityTypeSearch: selectedItemId ? true : false,
      },
    };
    const res = await apiService.getAllAgreements(req);
    if (res?.Items?.length > 0) {
      setAgreementDetails(
        res.Items.sort(
          (a: any, b: any) =>
            new Date(b.AgreementDate) - new Date(a.AgreementDate)
        )
      );
    }
    setLoading((prev) => {
      prev.page = false;
      return { ...prev };
    });
  };

  useEffect(() => {
    getAllSubMembers(userData?.ParentId || userData?.UserMemberId,userData?.UserMemberId);
  }, []);

  const getAllSubMembers = async (memberId: any,selectedUserMemberId:any) => {
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId);
    const result = res?.map((i: any) => {
      return { ...i, key: i?.UserMemberId, text: i?.FullName }
    })
    setAllClientsList(result);
    let Id = selectedUserMemberId;
    if (!props.staffPage && userDetails) {
      Id = selectedUserMemberId || parseInt(userDetails?.UserSession?.AdditionalAttributes?.LogedInMemberId);
    }

    updateSelectedClientData(getCurrentClientData(res, Id));
  };
  const getCurrentClientData = (allUsersData: any, userMemberId: any) => {
    return (
      allUsersData?.find((val: any) => val.UserMemberId === userMemberId) || {}
    );
  };

  async function updateSelectedClientData(userData: any) {
    const result = userData;
    if (result) {
      const clientSubmitDetails = {
        Email: result?.Email,
        FirstName: result?.FirstName,
        Photo: result?.Photo,
        LastName: result?.LastName,
        FullName: result?.FullName,
        PrimaryMemberId: result?.ParentId || result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        MemberType: result?.MemberType,
        Status: result?.UserMemberStatus || result?.Status,
      };
      setClientSubmitDetails(clientSubmitDetails);
    }
  }
  const togglePdfView = () => {
    setToggleView((prev) => !prev);
  };
  const fetchAgreementDetails = async (dataItem: any, type = "view") => {
    const data = { ...dataItem };
    data["type"] = type;
    setCurrentAgreementDetails(data);
    if (type === "view") {
      togglePdfView();
      return true;
    }
    setLoading((prev) => {
      prev.download = true;
      return { ...prev };
    });
    const res = await apiService.getAgreementDetailsForDownload(
      dataItem?.AgreementName,
      dataItem?.MemberAgreementId
    );
    if (type === "download") {
      saveAs(res, `${dataItem?.AgreementName}.pdf`);
    }

    setLoading((prev) => {
      prev.download = false;
      return { ...prev };
    });
  };
  const handleClientSelection = async (e: any) => {
    await updateSelectedClientData(e.item);
    setShowWaiver(true);
  };

  const OpenWaiverAgreement = () => {
    setShowWaiver(true);
  };
  const handleBack = (callApi = false) => {
    setShowWaiver(false);
    if (callApi) {
      fetchAgreements();
    }
  };

  return {
    fetchAgreementDetails,
    agreementDetails,
    loading,
    AllClientsList,
    clientSubmitDetails,
    OpenWaiverAgreement,
    handleClientSelection,
    showWaiver,
    handleBack,
    currentagreementDetails,
    toggleView,
    togglePdfView,
    isUploadAgreementVisible,
    toggleUploadAgreement,
  };
};
