import { Input, MaskedTextBox, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { mobileValidator, requiredValidator } from "validators/validator";
import { Error } from "@progress/kendo-react-labels";
import BuzopsButton from "generic-components/BuzopsButton";
import { Button } from "@progress/kendo-react-buttons";
import { CustomFieldsTypeEnum } from "utils/form-utils";

const CustomFieldsUpdate = (props: any) => {
  return (
    <div>
      {props?.fieldsData?.map((dataItem: any, index: number) => {
        return (
          <>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                {`${dataItem?.FieldName}`} {dataItem?.IsRequired ? `*` : ``}
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body bz-myprofile-container">
                {dataItem?.FieldType === CustomFieldsTypeEnum['String'] && (
                  <FieldWrapper>
                    <Input
                      autoComplete={"off"}
                      name={`${dataItem?.FieldName}`}
                      id={`${dataItem?.FieldName}`}
                      value={dataItem?.Value}
                      className={props?.className ? props?.className : ""}
                      style={{ width: "100%" }}
                      onChange={(e: any) =>
                        props?.handleChange(
                          e.target.value,
                          `${dataItem?.FieldName}`
                        )
                      }
                    />
                    {dataItem?.IsRequired &&
                      requiredValidator(dataItem?.Value) && (
                        <Error>{`${dataItem?.FieldName} is ${requiredValidator(
                          dataItem?.Value
                        )}`}</Error>
                      )}
                  </FieldWrapper>
                )}
                {dataItem?.FieldType === CustomFieldsTypeEnum['MultilineText'] && (
                  <FieldWrapper>
                    <TextArea
                      autoComplete={"off"}
                      name={`${dataItem?.FieldName}`}
                      id={`${dataItem?.FieldName}`}
                      value={dataItem?.Value}
                      className={props?.className ? props?.className : ""}
                      style={{ width: "100%" }}
                      onChange={(e: any) =>
                        props?.handleChange(
                          e.value,
                          `${dataItem?.FieldName}`
                        )
                      }
                    />
                    {dataItem?.IsRequired &&
                      requiredValidator(dataItem?.Value) && (
                        <Error>{`${dataItem?.FieldName} is ${requiredValidator(
                          dataItem?.Value
                        )}`}</Error>
                      )}
                  </FieldWrapper>
                )}
                {dataItem?.FieldType === CustomFieldsTypeEnum['Numeric'] && (
                  <FieldWrapper>
                    <NumericTextBox
                      className={props?.className ? props?.className : ""}
                      name={`${dataItem?.FieldName}`}
                      id={`${dataItem?.FieldName}`}
                      width={"100%"}
                      value={dataItem?.Value}
                      onChange={(e: any) =>
                        props?.handleChange(e.value, `${dataItem?.FieldName}`)
                      }
                    />
                    {dataItem?.IsRequired &&
                      requiredValidator(dataItem?.Value) && (
                        <Error>{`${dataItem?.FieldName} is ${requiredValidator(
                          dataItem?.Value
                        )}`}</Error>
                      )}
                  </FieldWrapper>
                )}
                {dataItem?.FieldType === CustomFieldsTypeEnum['PhoneNumber'] && (
                  <FieldWrapper>
                    <MaskedTextBox
                      // className={props?.className ? props?.className : ""}
                      name={`${dataItem?.FieldName}`}
                      id={`${dataItem?.FieldName}`}
                      // width={"100%"}
                      // style={{width:'100%'}}
                      value={dataItem?.Value}
                      mask="(999) 000-0000"
                      onChange={(e: any) =>
                        props?.handleChange(e.value, `${dataItem?.FieldName}`)
                      }
                    />
                    {dataItem?.IsRequired ? <Error>{mobileValidator(
                          dataItem?.Value
                        )}</Error> : dataItem?.Value && (
                        <Error>{mobileValidator(
                          dataItem?.Value
                        )}</Error>
                      )}
                  </FieldWrapper>
                )}
              </div>
            </div>
          </>
        );
      })}
      {props?.update && (
        <div className="float-right">
          {props?.modified && <Button
            onClick={() => props?.handleCancel()}
            disabled={props?.btnLoading}
          >
            Cancel
          </Button>}
          <BuzopsButton
            label={`Update`}
            onClick={() => props?.handleUpdate()}
            disabled={props?.btnLoading}
            loading={props?.btnLoading}
          />
        </div>
      )}
    </div>
  );
};

export default CustomFieldsUpdate;
