import { DropDownList } from "@progress/kendo-react-dropdowns";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React, { useState } from "react";
import { CurrencyFormat } from "utils";
import { Button } from "@progress/kendo-react-buttons";


const Coupons = (props: any) => {
  const [expandedItem, setExpandItem] = useState(null);

  if (props?.loading) {
    return <BuzopsLoader type={"list"} />;
  }

  const renderCouponInfo = (dataItem: any) => {
    const {
      PurchasedOn,
      Amount,
      ProcessedBillingCycle,
      PendingBillingCycle,
      Percentage,
      CouponTypeDescription,
      Status,
      DeactivatedOn,
      DurationType,
      RenewType
    } = dataItem;
    return (
      <div className="bz-content-text-row bz-coupon-details">
        <div className="bz-content-title text-right"></div>
        <span className="bz-colon text-center"></span>
        <div className="bz-content-body bz-content-text-row-mobile">
          <div className="bz-applied-coupon-details">
          <div className="bz-content-child-text-row">
            <div className="bz-content-title text-right">
              {"Coupon Applied On"}
            </div>
            <span className="bz-colon text-center">{":"}</span>
            <div className="bz-content-body">
              {moment(PurchasedOn).format("L")}
            </div>
          </div>
          <div className="bz-content-child-text-row">
            <div className="bz-content-title text-right">{`Coupon ${CouponTypeDescription === "Fixed" ? "Amount" : "Percentage"}`}</div>
            <span className="bz-colon text-center">{":"}</span>
            <div className="bz-content-body">
              {CouponTypeDescription === "Fixed"
                ? CurrencyFormat(Amount)
                : (Status ===2 || Status === 3) ?`${Amount}%`:`${Percentage}% (${CurrencyFormat(Amount)})`}
            </div>
          </div>
          {props?.selectedPlan?.DurationType===1 && DurationType===3 && <div className="bz-content-child-text-row">
            <div className="bz-content-title text-right">
              {"Auto Renews"}
            </div>
            <span className="bz-colon text-center">{":"}</span>
            <div className="bz-content-body">
              {RenewType === 0
                ? "No"
                : "Yes"}
            </div>
          </div>}
          
          <div className="bz-content-child-text-row">
            <div className="bz-content-title text-right">
              {"Total billing cycles to be applied"}
            </div>
            <span className="bz-colon text-center">{":"}</span>
            <div className="bz-content-body">
              {PendingBillingCycle === "N/A" || PendingBillingCycle==="Never Ending"
                ? PendingBillingCycle
                : parseInt(PendingBillingCycle) + parseInt(ProcessedBillingCycle)}
            </div>
          </div>
          <div className="bz-content-child-text-row">
            <div className="bz-content-title text-right">
              {"Completed billing cycles"}
            </div>
            <span className="bz-colon text-center">{":"}</span>
            <div className="bz-content-body">{ProcessedBillingCycle}</div>
          </div>
          {(Status !==2 &&  Status !== 3) && (<div className="bz-content-child-text-row">
            <div className="bz-content-title text-right">
              {"Remaining billing cycles"}
            </div>
            <span className="bz-colon text-center">{":"}</span>
            <div className="bz-content-body">{PendingBillingCycle}</div>
          </div>)}
          {Status === 3 && (<>
            <div className="bz-content-child-text-row">
            <div className="bz-content-title text-right">
              {"Coupon de-activated On"}
            </div>
            <span className="bz-colon text-center">{":"}</span>
            <div className="bz-content-body">
              {moment(DeactivatedOn).format("L")}
            </div>
          </div>
          </>)}
          </div>
        </div>
      </div>
    );
  };
  const handleExpand = (event: any, item: any) => {
    event?.stopPropagation();
    setExpandItem(item.MemberCouponId);
  };
  const handleCollapse = (event: any) => {
    event?.stopPropagation();
    setExpandItem(null);
  };

  return (
    <div>
      {props?.couponsList.length > 0 ? (
        <div className={"bz-subscription-card"}>
          {props?.couponsList?.map((item: any, index: number) => {
            let collpaseStatus = false;
            if (expandedItem === item.MemberCouponId) {
              collpaseStatus = true;
            }
            return (
              <>
                <div className="bz-content-text-row" key={item.MemberCouponId}>
                  <div className="bz-content-title text-right">
                    {index === 0 && "Coupons"}
                  </div>
                  <span className="bz-colon text-center">
                    {index === 0 && ":"}
                  </span>
                  <div className="bz-content-body">
                    <div className="bz-payment-options">
                      <span className="subscription-name">
                        {item?.CouponName}
                      </span>
                      <span
                        className={`badge badge-status-${item?.StatusDescription} ml-0`}
                      >
                        {item?.StatusDescription}
                      </span>
                      {item?.Status !== 2 && item?.Status !== 3 ? (
                        <span className="d-flex text-center">
                          {props?.selectedPlan?.RenewMemberPlanId > 0 && (
                            <span
                              className="bz-close-icon2"
                              onClick={() => {
                                props?.handleEditCouponItem(item);
                              }}
                            >
                              <i
                                className="fas fa-edit"
                                style={{ color: "#008DB9" }}
                              ></i>
                            </span>
                          )}
                          <span
                            className="bz-close-icon2 pl-1"
                            onClick={() => {
                              props?.handleRemoveCouponItem(item);
                            }}
                          >
                            <i
                              className="far fa-trash-alt"
                              style={{ color: "red" }}
                            ></i>
                          </span>
                        </span>
                      ) : (
                        <span></span>
                      )}
                      {collpaseStatus && (
                        <span
                          className="align-baseline bz-cursor-pointer p-0"
                          onClick={(e) => handleCollapse(e)}
                          style={{
                            color: "#6f7477",
                            fontSize: "0.8125rem",
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          Hide Details <i className="fas fa-angle-up ml-1"></i>
                        </span>
                      )}
                      {!collpaseStatus && (
                        <span
                          className="bz-cursor-pointer p-0"
                          onClick={(e) => handleExpand(e, item)}
                          style={{
                            color: "#6f7477",
                            fontSize: "0.8125rem",
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          View Details{" "}
                          <i className="fas fa-angle-down ml-1"></i>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {collpaseStatus && renderCouponInfo(item)}
              </>
            );
          })}
        </div>
      ) : (
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Coupons</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{"No Coupons Available"}</div>
        </div>
      )}
      {!props?.showCoupon ? (
        <>
          {props?.status !== "Suspended" &&
            props?.status !== "Completed" &&
            props?.status !== "Cancelled" &&
            props?.status !== "Expired" && (
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">{""}</div>
                <span className="bz-colon text-center"></span>
                <div className="bz-content-body">
                  <span
                    className="btn btn-link"
                    onClick={() => {
                      props?.setShowCoupon(true);
                    }}
                  >
                    Apply Coupon
                  </span>
                </div>
              </div>
            )}
        </>
      ) : (
        <>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">{""}</div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <DropDownList
                data={props?.CouponsToAdd}
                textField="Description"
                dataItemKey="CouponId"
                id={"CouponId"}
                style={{ width: "55%" }}
                name={"CouponId"}
                onChange={(e) => props?.handleCoupon(e.value)}
                value={
                  props?.couponValue || {
                    CouponId: 0,
                    Description: "Please Select Coupon",
                  }
                }
                filterable={true}
                onFilterChange={(e) => props?.CouponfilterChange(e)}
              />
            </div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <Button
                onClick={() => {
                  props?.setShowCoupon(false);
                  props?.handleCoupon("");
                }}
                className="ml-0"
              >
                {"Cancel"}
              </Button>
              <BuzopsButton
                label="Apply"
                disabled={!props?.couponValue}
                onClick={() => props?.toggleAddCouponDialog()}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Coupons;
