export enum SecuritySystemType {
  None = 0,
  ProximityReaderSystem = 1,
  ScannerSystem = 2,
}

export enum HardwareProviderType {
  Brivo = 1,
  Openpath = 2,
  Passport = 3,
}

export enum AccessCardType {
  OpenPath = 3,
  Passport = 4,
}

export enum SelfCheckInType {
  BARCODE = "barcode",
  EMAIL = "email",
}

export enum StatusCode {
  Ok = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,
  MultipleChoices = 300,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  Gone = 410,
  TooManyRequests = 429,
  ClientClosedRequest = 499,
  InternalServerError = 500,
}

export enum RequestType {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}
