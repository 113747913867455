import { ListView } from "@progress/kendo-react-listview";
import BuzopsButton from "generic-components/BuzopsButton";
import moment from "moment";
import React from "react";
import { CurrencyFormat } from "utils";

const DeclinedPayments = (props: any) => {
  const handleDeclinedPayments = (item: any) => {
    props?.handleDeclinedPayments(item);
  };
  const renderDeclinedPayments = (props: any) => {
    return (
        <div className={`card bz-decliened-payment-card mb-3`}>
          <div className="card-body">
            <div className="media-body p-2">
              <div>
                <p className={"text-center"}>
                  {`Your subscription (${
                    props.dataItem.EntityName
                  }) is inactive due to unpaid balance of
                          ${CurrencyFormat(props.dataItem.Amount)} on ${moment(
                    props.dataItem.TransactionDate
                  ).format("MM/DD/YYYY")}.`}
                  <br />
                  <BuzopsButton
                    label={"Click here"}
                    onClick={() => handleDeclinedPayments(props?.dataItem)}
                  />
                  <br />
                  to pay now and avoid service disruption
                </p>
              </div>
            </div>
          </div>
        </div>
    );
  };

  if (props?.declinedPayments.length === 0) {
    return (
      <div className={"text-center"}>
        <span style={{ fontSize: "30px", fontWeight: 700, color: "#fff" }}>
          No Declined Payments
        </span>
      </div>
    );
  }
  return (
    <ListView
      // onScroll={(e) => scrollHandler(e)}
      data={props?.declinedPayments || []}
      item={(props) => renderDeclinedPayments(props)}
      style={{
        width: "100%",
      }}
    />
  );
};

export default DeclinedPayments;
