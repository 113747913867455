import { useEffect, useState } from "react";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import {
  PassportCredentialStatusType,
  PassportCrendentialOptions,
  PassPortCrendentialType,
} from "../Utils/constants";
import { IMemberPassportAccessProps } from "../Utils/interfaces";
import bzImages from "../../../Images";
import { AccessCardType } from "utils/constants";

function padNumberWithZeros(numberString: string) {
  // Convert the number to a string
  // Calculate the number of zeros to add
  let zerosToAdd = 5 - numberString.length;

  // Add leading zeros
  for (let i = 0; i < zerosToAdd; i++) {
    numberString = "0" + numberString;
  }

  return numberString;
}
const usePassportMemberCredentials = (props: IMemberPassportAccessProps) => {
  const simpleAccessService = new SimpleAccessService();
  const [showHistory, setShowHistory] = useState(false);
  const [auditLogs, setAuditLogs] = useState([]);
  const [selectedCredentialType, setSelectedCredentialType] = useState<any>({
    id: -1,
    text: "Select credential type",
  });
  const [cardNumber, setCardNumber] = useState<string>("");
  const [cardId, setCardId] = useState<string>("");
  const [facilityCode, setFacilityCode] = useState<number>(-1);
  const [error, setError] = useState<string>("");
  const [facilityCodeError, setFacilityCodeError] = useState<string>("");

  const createPassportCredential = async () => {
    if (
      selectedCredentialType?.id ===
        PassPortCrendentialType.Card_LF_Weigand_H10301 &&
      (facilityCode < 0 || facilityCode > 255 || facilityCode === -1)
    ) {
      setFacilityCodeError("Please enter valid Facility Code");
      return;
    }
    if (
      selectedCredentialType?.id ===
        PassPortCrendentialType.Card_LF_Weigand_H10301 &&
      (parseInt(cardId) < 0 || parseInt(cardId) > 65535 || cardId === "")
    ) {
      setError("Please enter valid Card Number");
      return;
    }
    if (
      selectedCredentialType?.id ===
        PassPortCrendentialType.Card_HF_PassportTech &&
      (cardNumber === "")
    ) {
      setError("Please enter valid Card Number");
      return;
    }
    setError("");
    const paddedCardId = padNumberWithZeros(cardId);
    props.setProcessing(true);
    const payload = {
      UserId: props.UserId,
      EntityId: !props?.StaffPage ? props?.EntityId : undefined,
      AccessCardType: AccessCardType.Passport,
      PassportTechCardType: selectedCredentialType.id,
      ...(selectedCredentialType?.id ===
        PassPortCrendentialType.Card_HF_PassportTech && {
        CardNumber: cardNumber,
      }),
      ...(selectedCredentialType?.id ===
        PassPortCrendentialType.Card_LF_Weigand_H10301 && {
        CardId: parseInt(cardId),
        FacilityCode: facilityCode,
        CardNumber: `${facilityCode}:${paddedCardId}`,
      }),
    };

    const response = await simpleAccessService.createPassportCredential(
      payload
    );
    if (response.success) {
      props.handleNotification("Access Granted", "success");
      await props.checkMemberPassportAccess();
    } else {
      props.handleNotification(response.msg || "Error", "error");
    }
    props.setProcessing(false);
  };

  const removePassportAccessCredential = async () => {
    props.setProcessing(true);
    const payload = {
      UserId: props.UserId,
      EntityId: !props?.StaffPage ? props?.EntityId : undefined,
      CardNumber: "",
      FacilityCode: "",
      CardId: "",
    };
    const response = await simpleAccessService.removePassportAccessCredential(
      payload
    );

    if (response.success) {
      props.handleNotification("Access Removed", "success");
      await props.checkMemberPassportAccess();
    } else {
      props.handleNotification(response.msg || "Error", "error");
    }
    props.setProcessing(false);
  };

  const updateMemberHardwareAccess = async () => {
    props.setProcessing(true);
    const payload = {
      UserId: props.UserId,
      EntityId: !props?.StaffPage ? props?.EntityId : undefined,
      IsSuspended: !props.IsSuspended,
      IsForStaff: props?.StaffPage ? true : undefined,
    };
    const response = await simpleAccessService.updateMemberPassportAccess(
      payload
    );
    if (response.success) {
      props.handleNotification(
        `Access ${payload.IsSuspended ? "Suspended" : "Activated"} `,
        "success"
      );
      await props.checkMemberPassportAccess();
    } else {
      props.handleNotification(response.msg || "Error", "error");
    }
    props.setProcessing(false);
  };

  const getAccessCredentialStatus = () => {
    if (props.PassportTechCardType && props?.CardNumber) {
      if (props.IsSuspended === true) {
        return PassportCredentialStatusType.Suspended;
      } else {
        return PassportCredentialStatusType.Active;
      }
    } else {
      return PassportCredentialStatusType.NoAccess;
    }
  };

  const getStatusICon = (status: PassportCredentialStatusType) => {
    switch (status) {
      case PassportCredentialStatusType.Active:
        return bzImages.activeIcon;
      case PassportCredentialStatusType.NoAccess:
        return bzImages.noAccessIcon;
      case PassportCredentialStatusType.Suspended:
        return bzImages.suspendedIcon;
      default:
        return bzImages.noAccessIcon;
    }
  };

  const getAuditLogs = async (showHistory: boolean) => {
    if (showHistory === true) {
      props.setProcessing(true);
      const req = {
        UserMemberId: props.UserMemberId,
        PageSize: 20,
        PageNumber: 0,
      };
      const response = await simpleAccessService.getPassportAccessAuditInfo(
        req
      );
      setAuditLogs(response);
      setShowHistory(true);
      props.setProcessing(false);
    } else {
      setAuditLogs([]);
      setShowHistory(false);
    }
  };

  useEffect(() => {
    props.PassportTechCardType !== null
      ? setSelectedCredentialType(
          PassportCrendentialOptions.find(
            (option) => option?.id === props.PassportTechCardType
          )
        )
      : setSelectedCredentialType({ id: -1, text: "Select credential type" });
    if (
      props.PassportTechCardType ===
      PassPortCrendentialType.Card_LF_Weigand_H10301
    ) {
      props?.CardId !== null &&
        props?.CardId !== undefined &&
        setCardId(props.CardId);
      props?.FacilityCode !== null &&
        props?.FacilityCode !== undefined &&
        setFacilityCode(props.FacilityCode);
    } else {
      props.CardNumber && setCardNumber(props.CardNumber);
    }
  }, [
    props.PassportTechCardType,
    props.CardNumber,
    props.CardId,
    props.FacilityCode,
  ]);

  return {
    createPassportCredential,
    removePassportAccessCredential,
    getAccessCredentialStatus,
    updateMemberHardwareAccess,
    showHistory,
    getAuditLogs,
    auditLogs,
    getStatusICon,
    selectedCredentialType,
    setSelectedCredentialType,
    setCardNumber,
    cardNumber,
    cardId,
    setCardId,
    setError,
    error,
    facilityCode,
    setFacilityCode,
    facilityCodeError,
    setFacilityCodeError,
  };
};
export default usePassportMemberCredentials;
