import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  Checkbox,
  NumericTextBox,
  RadioButton,
} from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import withNotification from "components/Hoc/withNotification";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { CheckUserMemberStatus, CurrencyFormat, CustomToFixed, checkIfUserAddPaymentGateway, checkIfUserCanAddBankAccount } from "utils";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import FailedTransactionsGrid from "../FailedTransactionsGrid";
import { useDispatch, useSelector } from "react-redux";
import { SetNavigateToAPageOnButtonClick } from "redux-slices/genericSlice";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentRefundTax } from "components/Transactions/Refund";
import { CreditsErrorMessage } from "utils/form-utils";

const AddonCancel = (props: any) => {
  const dispatch = useDispatch()
  const [cancelDetails, setCancelDetails] = useState<any>({});
  const [totalRefundAmount, setTotalRefundAmount] = useState<any>(0);
  const [paymentProfiles, setPaymentProfiles] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const service = new TenantService();
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const [selectedAddon, setSelectedAddon] = useState<any>({});  
  const PaymentMethodItems = [
    {
      iconClass: "fa fa-credit-card pr-1",
      text: "Add credit card",
      key: "addCard",
      visible:true,
    },
    {
      iconClass: "fa fa-university pr-1",
      text: "Add bank account",
      key: "addBank",
      visible: checkIfUserCanAddBankAccount()
    },
  ];
  const genericReducersubscription = useSelector((state : any) => {
    if(selectedAddon !== state.generic.selectedAddon){
      setSelectedAddon(state.generic.selectedAddon)
    }
  })
  
  const onCardOrBankAdd = (e: any) => {
      setShowPaymentDialog(true);
      setAddNewItem(e.item.key);
    };
  
    const handleClosePaymethodDialog = async () => {
      setShowPaymentDialog(!showPaymentDialog)
    }
    const handleSuccessClosePaymethodDialog = async () => {
      setShowPaymentDialog(!showPaymentDialog)
      await fetchPaymentProfiles(props?.userData?.MainUserId)
    }
  
    const PaymethodDialog = () => {
      return (
        <>
          <Dialog
            className="bz-addCard-dialog"
            title={`${checkIfUserCanAddBankAccount() ? "Add Bank or Card": "Add Card"}`}
            onClose={() => handleClosePaymethodDialog()}
            style={{ zIndex: 9999 }}
          >
            <PaymentModalCreation staffPage={props?.staffPage} addNewItem={addNewItem} handleClosePaymethodDialog={handleClosePaymethodDialog} handleSuccessClosePaymethodDialog={handleSuccessClosePaymethodDialog} UserMemberId={props?.userData?.UserMemberId} />
          </Dialog>
        </>
      );
    };

  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchCancellationDetails();
    await fetchPaymentProfiles(props?.userData?.MainUserId);
    setLoading(false);
  };

  const fetchCancellationDetails = async () => {
    const entityId = selectedAddon? selectedAddon.id : 0
    const req = {
      Where: {
        UserMemberId: props?.userData?.UserMemberId,
        EntityId: entityId,
      },
    };
    const result = await service.GetAddonCancellationDetails(req);
    setCancelDetails(result);
    setTotalRefundAmount(result?.RefundAmount);
    if (result?.CancellationStatus === 1) {
      handleCalculation(result, result?.RefundAmount);
    }
  };

  async function fetchPaymentProfiles(userMemberId: any) {
    const result = await service.paymentProfile(userMemberId);
    setPaymentProfiles(result || []);
  }

  const showErrorMessage = (key: any, mainKey: any) => {
    if (
      key === "ChargingPaymentProfileId" &&
      cancelDetails?.AmountToCharge > 0 &&
      (!cancelDetails[key] || cancelDetails[key] === 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    } else if (
      key !== "ChargingPaymentProfileId" &&
      (!cancelDetails[key] || cancelDetails[key].length <= 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    }
    return <></>;
  };

  const selectionChange = (event: any, EntityId: number = 0) => {
    const dataItem = JSON.parse(JSON.stringify(cancelDetails));
    const originalDup = dataItem?.FailedTransactions;

    const original = originalDup.map((item: any) => {
      if (item.TransactionHistoryId === event.dataItem.TransactionHistoryId) {
        item.CanWaive = !event.dataItem.CanWaive;
      }
      return item;
    });
    const updatedCancellationDetails = {
      ...dataItem,
      FailedTransactions: original,
    };
    handleCalculation(updatedCancellationDetails, totalRefundAmount);
  };

  const dateCell = (props: any) => {
    const TransactionDate = moment(props?.dataItem?.TransactionDate).format(
      "L"
    );

    return <td>{TransactionDate}</td>;
  };

  const handleChange = async (value: any, name: string) => {
    let updatedCancellationDetails = cancelDetails;
    if (name === "paymentProfile") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      updatedCancellationDetails = {
        ...cancelDetails,
        ChargingPaymentProfileId: PaymentGatewayPaymentProfileID,
        CardTypeId: value?.CardTypeId,
        Credit: value?.Credit,
      };
    } else {
      updatedCancellationDetails = {
        ...cancelDetails,
        [name]: value,
      };
    }
    await handleCalculation(updatedCancellationDetails, totalRefundAmount);
  };

  const handleCalculation = async (dataItem: any, refAmount: number) => {
    let {
      IsImmediateCancellation,
      CancelItToday,
      CancellationDate,
      NextRecurrence,
      IsNormalCancellationAvailable,
      AfterNextRecurrence,
      CancellationFee,
      ProcessType,
      AmountToCharge,
      AmountToRefund,
      ChargedTransactionMetaData=""
    } = dataItem;

    if (IsImmediateCancellation && CancelItToday) {
      CancellationDate = moment().format("L");
    } else if (IsImmediateCancellation) {
      CancellationDate = NextRecurrence;
    } else if (IsNormalCancellationAvailable && !IsImmediateCancellation) {
      CancellationDate = AfterNextRecurrence;
    } else {
      CancellationDate = NextRecurrence;
    }
    const refundAmount =
      IsImmediateCancellation && CancelItToday ? refAmount : 0;
    let totalChargeAmount = CancellationFee;

    dataItem?.FailedTransactions &&
      dataItem?.FailedTransactions?.forEach((i: any) => {
        if (i?.CanWaive === false) {
          totalChargeAmount =
            parseFloat(totalChargeAmount) + parseFloat(i?.Amount);
        }
      });

    if (refundAmount === totalChargeAmount) {
      ProcessType = 3;
      AmountToCharge = 0;
      AmountToRefund = 0;
    } else if (refundAmount > totalChargeAmount) {
      AmountToCharge = 0;
      AmountToRefund = refundAmount - totalChargeAmount;
      ProcessType = 2;
    } else if (totalChargeAmount > refundAmount) {
      AmountToCharge = totalChargeAmount - refundAmount;
      AmountToRefund = 0;
      ProcessType = 1;
    }
    const AddonTaxItem =handleCalculateSurcharges(AmountToRefund,ChargedTransactionMetaData)

    const result = {
      ...dataItem,
      CancellationDate,
      AmountToCharge,
      AmountToRefund,
      ProcessType,
      TaxesArray:AddonTaxItem?.TaxesArray || [],
      taxAmt:AddonTaxItem?.taxAmt || 0
    };
    setCancelDetails(result);
  };
  const handleCalculateSurcharges = (
    refundAmt: any,
    TaxMetaData:any
  ) => {
    if(TaxMetaData){
    const mainTaxItems = TaxMetaData?.split(",");
    const TaxesArray = mainTaxItems.map((i: any) => {
      const dataItem=i?.split(":")
      const percentage = parseFloat(dataItem[1]);
      const description = `${dataItem[0]} - (${percentage}%)`
      const amount = (refundAmt * percentage) / 100;
      const calcAmt=CustomToFixed(amount,2)
      return {
        Description: description,
        Percentage: percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount
    }, 0);
    return {
      TaxesArray,taxAmt
    }
  }else{
    return {
      TaxesArray:[],taxAmt:0
    }
  }
 
  };

  const handleValidation = () => {
    if (!cancelDetails?.Comments || cancelDetails?.Comments === "") {
      return false;
    }
    if (
      cancelDetails?.AmountToCharge > 0 &&
      (!cancelDetails?.ChargingPaymentProfileId ||
        cancelDetails?.ChargingPaymentProfileId === 0)
    ) {
      return false;
    }
    if (
      cancelDetails?.AmountToCharge > 0 &&
      cancelDetails?.ChargingPaymentProfileId && cancelDetails?.CardTypeId === 8 && cancelDetails?.AmountToCharge > cancelDetails?.Credit
    ) {
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return false;
    }
    return true;
  };
  const handleApproveCancellation = async () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage('', "error", true, 'client');
      return
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage('', "error", false, 'staff');
      return
    }
    const check = handleValidation();
    if (check) {
      setBtnLoading(true);
      const result = await service.ApproveAddonCancellation(cancelDetails);
      setBtnLoading(false);
      if (result?.ResponseCode === 100) {
        const successMsg = result?.SuccessMessage || `Addon Successfully Cancelled`;
        props?.handleNotificationMessage(successMsg, "success");
        await fetchAllApis();
      } else {
        const errorMsg =
          result?.ErrorMessages.length > 0
            ? result?.ErrorMessages[0]
            : "Error in Cancelling Package";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
  };
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  return (
    <>
      <h5 className="mb-3 bz_fs_1_5"><button
          className="btn btn-primary bz-back-btn btn-sm"
          onClick={() => dispatch(SetNavigateToAPageOnButtonClick({ id: 136, parent: false }))}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;Add-On Cancellation</h5>
      <hr />
      <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation">
        <div className="left-section">
          <h5 className="mb-3 pt-3 bz_fs_1_5">Add-on Details</h5>
          <h6 className="card-title bz-fw-600 bz-article">
            {cancelDetails.Name}
          </h6>
          <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Add-on Name</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{`${cancelDetails.SubscribedTo}`}</div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Client Name</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{cancelDetails.MemberName}</div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Cancellation Status
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {cancelDetails.CancellationStatusDescription}
              </div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Cancellation Date
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {cancelDetails?.CancellationDate === null
                  ? "NA"
                  : moment(cancelDetails?.CancellationDate).format("L")}
              </div>
            </div>
            {(cancelDetails?.CancellationStatus === 2 ||
              cancelDetails?.CancellationStatus === 4) && (
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Approved Date</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {cancelDetails?.ApprovedDate === null
                    ? "NA"
                    : moment(cancelDetails?.ApprovedDate).format("L")}
                </div>
              </div>
            )}
          </div>
          <hr />

          {cancelDetails?.FailedTransactions?.length > 0 && (
            <FailedTransactionsGrid
              details={cancelDetails?.FailedTransactions}
              selectionChange={(e: any, id: number) => selectionChange(e, id)}
              EntityId={0}
            />
          )}
          <div className="bz-mt-2rem bz-mb-2_5rem">
            {cancelDetails?.CancellationStatus === 1 && (
              <>
                <div className="bz-content-text-row align-items-start">
                  <div className="bz-content-title text-right">
                    Cancel without 1 billing notice
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <Checkbox
                      value={cancelDetails?.IsImmediateCancellation}
                      onChange={(e) =>
                        handleChange(e.value, "IsImmediateCancellation")
                      }
                      disabled={
                        cancelDetails?.CancellationStatus !== 1 ||
                        !cancelDetails?.IsNormalCancellationAvailable ||
                        cancelDetails?.DisableImmediateAndCancelItToday
                      }
                    />
                    {cancelDetails?.IsImmediateCancellation && (
                      <p
                        style={{ color: "blue" }}
                        className="notificxation-blue mb-0"
                      >
                        {
                          "Addon will cancel on the next recurring billing date and the user will not get charged."
                        }
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}
            {cancelDetails?.IsImmediateCancellation &&
              cancelDetails?.CancellationStatus === 1 && (
                <>
                  <div className="bz-content-text-row align-items-start">
                    <div className="bz-content-title text-right">
                      Cancel Immediately with Refund
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      <Checkbox
                        value={cancelDetails?.CancelItToday}
                        onChange={(e) => handleChange(e.value, "CancelItToday")}
                        disabled={
                          cancelDetails?.CancellationStatus !== 1 ||
                          cancelDetails?.Frequency === 1 ||
                          cancelDetails?.DisableImmediateAndCancelItToday
                        }
                      />
                      {cancelDetails?.CancelItToday && (
                        <p
                          style={{ color: "blue" }}
                          className="notificxation-blue mb-0"
                        >
                          {
                            "Addon will cancel now and allow for refund option for pro-rated Service."
                          }
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
            <div className="bz-content-text-row align-items-start bz-notify-row">
              <div className="bz-content-title text-right">
                Cancellation Type
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <RadioButton
                  name="CancellationType"
                  value={1}
                  disabled={true}
                  checked={cancelDetails?.ProcessType === 1}
                  label="Charge the Amount"
                />
                <br />
                <RadioButton
                  name="CancellationType"
                  value={2}
                  disabled={true}
                  checked={cancelDetails?.ProcessType === 2}
                  label="Refund the Amount"
                />
                <br />
                <RadioButton
                  name="CancellationType"
                  value={3}
                  disabled={true}
                  checked={cancelDetails?.ProcessType === 3}
                  label="No Refund and No Charge"
                />
              </div>
            </div>
            {(cancelDetails?.ProcessType === 2 ||
              (cancelDetails?.IsImmediateCancellation &&
                cancelDetails?.CancelItToday)) && (
              <>
                <div className="bz-content-text-row bz-notify-row">
                  <div className="bz-content-title text-right">
                    Refund Amount
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {" "}
                    <NumericTextBox
                      id={`RefundAmount`}
                      name={`RefundAmount`}
                      min={0}
                      format={"c2"}
                      className="k-dropdown-width-100"
                      disabled={true}
                      value={cancelDetails?.RefundAmount}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="bz-content-text-row bz-notify-row">
              <div className="bz-content-title text-right">
                Cancellation Fee
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <NumericTextBox
                  id={`CancellationFee`}
                  name={`CancellationFee`}
                  min={0}
                  disabled={cancelDetails?.CancellationStatus !== 1}
                  format={"c2"}
                  className="k-dropdown-width-100"
                  value={cancelDetails?.CancellationFee}
                  onChange={(e) =>
                    handleChange(e.target.value, "CancellationFee")
                  }
                />
              </div>
            </div>

            {cancelDetails?.AmountToCharge > 0 && (
              <>
                <div className="bz-content-text-row bz-notify-row">
                  <div className="bz-content-title text-right">
                    Chargable Amount
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <NumericTextBox
                      id={`AmountToCharge`}
                      name={`AmountToCharge`}
                      min={0}
                      format={"c2"}
                      disabled={true}
                      className="k-dropdown-width-100"
                      value={cancelDetails?.AmountToCharge}
                    />
                  </div>
                </div>
              </>
            )}

            {cancelDetails?.AmountToRefund > 0 && (
              <>
                <div className="bz-content-text-row bz-notify-row">
                  <div className="bz-content-title text-right">
                    Amount to Refund
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <NumericTextBox
                      id={`AmountToRefund`}
                      name={`AmountToRefund`}
                      min={0}
                      format={"c2"}
                      disabled={true}
                      className="k-dropdown-width-100"
                      value={cancelDetails?.AmountToRefund}
                    />
                  </div>
                </div>
                {cancelDetails?.ChargedTransactionMetaData &&  <div className="bz-content-text-row bz-notify-row">
                  <div className="bz-content-title text-right">
                  <Tooltip
                    content={(tooltipProps: any) => {
                      return (
                        <TooltipContentRefundTax
                          title={tooltipProps.title}
                          data={cancelDetails?.TaxesArray}
                          TotalTaxAmount={cancelDetails?.taxAmt}
                          Name={cancelDetails.SubscribedTo}
                        />
                      );
                    }}
                    tooltipClassName="bz-tooltip-table"
                  >
                    <a
                      title="Surcharges"
                      style={{
                        borderBottom: "1px dotted #0d6efd",
                        cursor: "pointer",
                      }}
                    >
                      {"Surcharges to be refunded"}
                    </a>
                  </Tooltip>
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                  {CurrencyFormat(cancelDetails?.taxAmt)}
                  </div>
                </div>}
              </>
            )}
            {cancelDetails?.CancellationStatus === 1 && (
              <>
                <div className="bz-content-text-row bz-notify-row">
                  <div className="bz-content-title text-right">
                    Payment Profile
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body payment-profile">
                    <div>
                    <DropDownList
                      id={`ChargingPaymentProfileId`}
                      name={`ChargingPaymentProfileId`}
                      data={paymentProfiles}
                      textField="MaskedCCNumber"
                      // dataItemKey="PaymentProfileID"
                      valueRender={(
                        el: React.ReactElement<HTMLSpanElement>,
                        value: any
                      ) => PaymentValueRender(el, value, value)}
                      itemRender={PaymentItemRender}
                      onChange={(e) => {
                        handleChange(e.value, "paymentProfile");
                      }}
                      style={{ width: "100%" }}
                    />
                    {showErrorMessage(
                      "ChargingPaymentProfileId",
                      "Payment Profile"
                    )}
                    </div>
                    {checkIfUserAddPaymentGateway() && <div className="addNewBtn">
                      <DropDownButton
                        text="Add New"
                        onItemClick={(e: any) => onCardOrBankAdd(e)}
                        items={PaymentMethodItems.filter((i:any)=>i?.visible===true)}
                        look="flat"
                        className="btn btn-link add-new-btn"
                      />
                    </div>}
                  </div>
                </div>
              </>
            )}
            {cancelDetails?.CancellationStatus === 1 && (
              <>
                <div className="bz-content-text-row bz-notify-row align-items-start">
                  <div className="bz-content-title text-right">Comments</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <textarea
                      className="form-control"
                      onChange={(e) => handleChange(e.target.value, "Comments")}
                    ></textarea>
                    {showErrorMessage("Comments", "Comments")}
                  </div>
                </div>
              </>
            )}
          </div>
          {cancelDetails?.CancellationStatus === 1 && (
            <>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right"></div>
                <span className="bz-colon text-center"></span>
                <div className="bz-content-body">
                  <BuzopsButton
                    loading={btnLoading}
                    disabled={btnLoading}
                    label={"Complete"}
                    onClick={() => handleApproveCancellation()}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showPaymentDialog && PaymethodDialog()}
    </>
  );
};

export default withNotification(AddonCancel);
