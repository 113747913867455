import React, { useEffect, useState } from 'react';
import BuzopsWindow from "generic-components/BuzopsWindow";
import Summary from 'components/summary/summary';


const ClientDashboardWindow = (props: any) => {
    const {
        clientId,
        uuid
    } = props;
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if(clientId){
            setVisible(true)
        }else {
            setVisible(false)
        }
    }, [clientId])
    return (
        <BuzopsWindow
        key={visible.toString()}
        reduceWidth={20}
        onWindowClose={() => setVisible(false)}
        title={"Agreement"}
        className={'bzo-window bzo-agreement-window'}
        visibility={visible}
      >
        <Summary uuid={uuid} clientId={clientId} />
      </BuzopsWindow>
    )
}

export default ClientDashboardWindow;