import React, { useEffect, useState } from "react";
import { PackageService } from "services/package/index.service";

export const usePackageUtilization = (props: any) => {
    const packageService = new PackageService();
    const [loading, setLoading] = useState(true);
    const [billingPeriods, setBillingPeriods] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState<any>(null);

    const fetchBillingAPI = async () => {
        setLoading(true);
        await fetchBillingPeriodDetails();
        setLoading(false);
    };

    const fetchBillingPeriodDetails = async () => {
        const payload = {
            PackageInstanceId: props?.packageData?.PackageInstanceId || props?.selectedPackageId,
            UserMemberId: props?.userData?.UserMemberId,
        };
        const result = await packageService.fetchPackageBillingPeriods(payload);
        setBillingPeriods(result);
        if (result?.length > 0) {
            const defaultSelectedPeriod = result?.filter(
                (i: any) => i?.IsCurrentBillingPeriod === true
            )?.[0];
            setSelectedPeriod(defaultSelectedPeriod);
        }
    };
    useEffect(() => {
        fetchBillingAPI();
    }, []);

    const handleChange = (val: any) => {
        setSelectedPeriod(val);
    };
    return { loading, billingPeriods, handleChange, selectedPeriod,fetchBillingAPI };
};
