import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React from "react";

const TermsAndConditions = ({
  handleTermsConditionDialog,
  handleTermsConditions,
}) => {
  return (
    <Dialog
      className={
        "bz-mybookings-dialog-box bz-privacyPolicy-dialog bz-classes-dialog"
      }
      title={"Terms and Conditions"}
      onClose={handleTermsConditionDialog}
      width={"45%"}
      height={'630px'}
    >
      <div className="row">
        <div className={"col-12"}>
          <article>
            <p>
              Terms, Conditions, Waiver of Liability, and Consent to Purchase
              for Services Scheduled or Purchased via Buzops Software
            </p>
            <h6 className="buz-font-600">Introduction</h6>
            <p>
              These Terms, Conditions, Waiver of Liability, and Consent to
              Purchase ("Agreement") are applicable to any class, program,
              appointment, service, membership, or subscription ("Services")
              offered by any business ("Provider") using the Buzops Management
              Software ("Software"). This Agreement governs the scheduling,
              purchase, and participation in Services provided by the Provider
              and is between the Provider and its clients or customers
              ("Participants").
            </p>
            <h6 className="buz-font-600">Acceptance of Agreement</h6>
            <p>
              By scheduling, purchasing, or participating in any Service through
              the Software, Participants agree to be bound by this Agreement. If
              a Participant does not agree to this Agreement, they must not
              schedule, purchase, or engage in the Provider's Services.
            </p>
            <h6 className="buz-font-600">Changes to the Agreement</h6>
            <p>
              The Provider reserves the right to modify this Agreement at any
              time. Such modifications will be effective immediately upon
              posting through the Software. It is the Participant's
              responsibility to review this Agreement periodically to ensure
              they are aware of any changes
            </p>
            <h6 className="buz-font-600">Health and Safety Acknowledgment (If Applicable)</h6>
            <p>
              Participants acknowledge that certain Services may involve
              physical activity or other activities that carry risks of injury
              or harm. Participants assert they are in suitable condition to
              engage in such Services and agree to adhere to all safety
              instructions and guidelines provided by the Provider.
            </p>
            <h6 className="buz-font-600">Waiver of Liability</h6>
            <p>
              To the maximum extent permitted by applicable law, Participants
              waive any and all claims or causes of action, known or unknown,
              arising out of or connected with their participation in any
              Service or use of the Provider's facilities or equipment, against
              the Provider, its affiliates, employees, agents, and officers.
            </p>
            <h6 className="buz-font-600">Consent to Purchase</h6>
            <p>
              Participants consent to purchase Services offered by the Provider
              through the Software. Participants agree to pay all charges at the
              prices then in effect for their purchases, along with any
              applicable taxes. The Provider reserves the right to change
              service prices or other conditions of purchase at its discretion.
            </p>
            <h6 className="buz-font-600">Cancellation and Refund Policy</h6>
            <p>
              The Provider's cancellation and refund policies for Services
              scheduled or purchased through the Software will be provided at
              the time of purchase. Participants agree to abide by these
              policies
            </p>
            <h6 className="buz-font-600">Indemnification</h6>
            <p>
              Participants agree to indemnify, defend, and hold harmless the
              Provider, its affiliates, licensors, service providers, employees,
              agents, officers, and directors from any claims, liabilities,
              damages, and costs (including reasonable attorneys' fees) arising
              from their breach of this Agreement or use of the Provider's
              Services.
            </p>
            <h6 className="buz-font-600">Governing Law</h6>
            <p>
              This Agreement shall be governed by the laws of the jurisdiction
              in which the Provider operates, without regard to its conflict of
              laws provisions.
            </p>
            <h6 className="buz-font-600">Entire Agreement</h6>
            <p>
              This Agreement constitutes the full agreement between the
              Participants and the Provider regarding the subject matter hereof
              and supersedes all prior understandings or agreements, written or
              oral, regarding such subject matter.
            </p>
            <p>
              By scheduling, purchasing, or participating in a Service,
              Participants acknowledge they have read, understood, and agree to
              be bound by this Agreement.
            </p>
          </article>
        </div>
        <DialogActionsBar>
          <Button onClick={() => handleTermsConditions(false)}>
            {"Decline"}
          </Button>
          <Button primary={true} onClick={() => handleTermsConditions(true)}>
            Accept
          </Button>
        </DialogActionsBar>
      </div>
    </Dialog>
  );
};

export default TermsAndConditions;
