const reducer = (state, action) => {
    switch (action.type) {
        case 'USER_LOGIN':
            return {
                ...state,
                // email: action.payload.email
                ...action.payload
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload
            };
        case 'SET_LOADER':
            return {
                ...state,
                isLoading: action.payload
            };
        default:
            return state;
    }
};

export default reducer;