import React, { useEffect, useRef, useState } from "react";
import { BankAccountTypes, cardTypes } from "utils/form-utils";
import images from "utils/images";
import { DropDownButton } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
  BankAccountValidator,
  requiredValidator,
  routingNumberValidator,
} from "validators/validator";
import { FormDropDownList, FormInput } from "utils/form-components";
import { Button } from "@progress/kendo-react-buttons";
import { TenantService } from "services/tenant/index.service";
import payment from "payment";
import {
  formatCardNumber,
  formatExpiry,
  hasCardNumberReachedMaxLength,
  isHighlighted,
} from "../../utils/formatter";
import creditCardType from "credit-card-type";
import isExpiryInvalid from "../../utils/is-expiry-invalid";
import isZipValid from "../../utils/is-zip-valid";
import PaymentCardInput from "components/checkout/PaymentCardInput";
import { GetLocalStore } from "utils/storage";
import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import SquarePayments from "components/square-payment-form/SquarePayments";
import StripePayments from "components/stripePayment";
import { checkIfUserAddPaymentGateway, checkIfUserCanAddBankAccount } from "utils";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { BsCCircleFill } from "react-icons/bs";
import { FaExclamationTriangle } from "react-icons/fa";

const PaymentMethods = (props: any) => {
  const showMoreRef = useRef<any>();
  const [page, setPage] = React.useState({
    skip: 0,
    take: 2,
  });
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [paymentGatewayType, setPaymentGatewayType] =
    useState<any>(paymentGatewaytype);
  const userDetails = GetLocalStore("userDetails");
  const userConfiguration =
    userDetails?.ChargehubSquarePaymentProfile || undefined;

  const [paymentProfile, setPaymentProfile] = useState<any>([]);
  //for buzops button loading for bank submit
  const [bankSubmitBtnLoading, setBankSubmitBtnLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  //for buzops button loading for card submit
  const [cardSubmitBtnLoading, setCardSubmitBtnLoading] = useState(false);

  useEffect(() => {
    fetchPaymentProfiles(props?.userData?.MainUserId);
  }, []);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  //  payment profile bank initial form values
  const bankIntialValues = {
    AccountName: undefined,
    BankAccountType: undefined,
    BankName: undefined,
    RoutingNumber: undefined,
    AccountNumber: undefined,
    UserMemberId: props?.userData?.MainUserId,
    PaymentType: "Bank",
  };
  const [bankFormValues, setBankFormValues] = useState<any>(bankIntialValues);
  // credit cards image placeholder
  const [cardImageIcon, setcardImageIcon] = useState(images.placeholder);
  //  payment profile card initial form values
  const cardIntialValues = {
    MaskedCCNumber: undefined,
    CardTypeId: undefined,
    ExpiryMonthYear: undefined,
    ExpiryMonth: undefined,
    ExpiryYear: undefined,
    ZipCode: undefined,
    PaymentType: "Card",
    UserMemberId: props?.userData?.MainUserId,
  };
  const [cardFormValues, setCardFormValues] = useState<any>(cardIntialValues);

  // to handle credit card image icon
  const handleCardImageIcon = (imageUrl: any) => {
    setcardImageIcon(imageUrl);
  };
  const handlePageChange = (status = "more") => {
    if (status === "less") {
      setPage({
        skip: 0,
        take: 2,
      });
    } else {
      setPage({
        take: page.take + 2,
        skip: 0,
      });
    }

    if (showMoreRef.current) {
      showMoreRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleCancel = () => {
    setAddNewItem(null);
    setBankFormValues(bankIntialValues);
    setCardFormValues(cardIntialValues);
  };

  async function fetchPaymentProfiles(userMemberId: any) {
    setPaymentLoading(true);
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    setPaymentLoading(false);
    if (result) {
      const test = result.filter((item: any) => item.CardTypeId !== -1);
      setPaymentProfile(test);
    }
  }

  // handle card form submit
  const handleCardSubmit = async (dataItem: any, status: any = addNewItem) => {
    if (status === "addCard") {
      setCardSubmitBtnLoading(true);
      const saveCardDetails = new TenantService();
      if (paymentGatewayType === 1) {
        dataItem.MaskedCCNumber = dataItem.MaskedCCNumber.split(" ").join("");
      }
      const result = await saveCardDetails.createPaymentProfile(dataItem);
      setCardSubmitBtnLoading(false);
      if (result?.ResponseCode === 100) {
        const successMsg = result?.SuccessMessage;
        props?.handleNotificationMessage(successMsg, "success");
        setcardImageIcon(images.placeholder);
        fetchPaymentProfiles(props?.userData?.MainUserId);
        setAddNewItem(null);
        setCardFormValues(cardIntialValues);
      } else {
        const errorMsg = result?.ErrorMessages?.[0] || "Error In Creating";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } else {
      const maskedNum = `xxxxxxxx${dataItem.MaskedCCNumber}`;
      const expiryValue = dataItem?.ExpiryMonthYear?.split("/");
      const req = {
        ExpiryMonthYear: dataItem?.ExpiryMonthYear,
        ExpiryMonth: expiryValue?.[0],
        ExpiryYear: expiryValue?.[1],
        ZipCode: dataItem?.ZipCode,
        UserMemberId: dataItem?.UserMemberId,
        PaymentGatewayPaymentProfileID:
          dataItem?.PaymentGatewayPaymentProfileID,
        CardTypeId: dataItem?.CardTypeId,
        MaskedCCNumber: maskedNum?.toUpperCase(),
        PaymentMethod: "CreditCard",
      };
      setCardSubmitBtnLoading(true);
      const updateCardDetails = new TenantService();
      const result = await updateCardDetails.updatePaymentProfile(req);
      setCardSubmitBtnLoading(false);
      if (result?.ResponseCode === 100) {
        const successMsg = "Payment Method Successfully";
        props?.handleNotificationMessage(successMsg, "success");

        setcardImageIcon(images.placeholder);
        fetchPaymentProfiles(props?.userData?.MainUserId);
        setAddNewItem(null);
        setCardFormValues(cardIntialValues);
      } else {
        const errorMsg = result?.ErrorMessages?.[0] || "Error In Updating";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
  };

  // handle bank form submit
  const handleBankSubmit = async (dataItem: any) => {
    setBankSubmitBtnLoading(true);
    const saveBankDetails = new TenantService();
    const req = { ...dataItem, BankAccountType: dataItem?.BankAccountType?.id }
    const result = await saveBankDetails.createPaymentProfile(req);
    setBankSubmitBtnLoading(false);
    if (result.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      fetchPaymentProfiles(props?.userData?.MainUserId);
      setAddNewItem(null);
      setBankFormValues(bankIntialValues);
    } else {
      setBankFormValues(dataItem);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const onCardOrBankAdd = (e: any) => {
    setAddNewItem(e.item.key);
    setCardFormValues(cardIntialValues);
    setBankFormValues(bankIntialValues);
  };
  const onCardOrBankEdit = async (item: any) => {
    const paymentProfileData = new TenantService();
    const Result = await paymentProfileData.GetPaymentProfile(item);
    if (item.IsCreditCard) {
      const cardIntialValues = {
        MaskedCCNumber: item.MaskedCCNumber,
        CardTypeId: item.CardTypeId,
        ExpiryMonthYear: item.ExpiryDate,
        ExpiryMonth: undefined,
        ExpiryYear: undefined,
        ZipCode: Result?.ZipCode,
        PaymentType: "Card",
        UserMemberId: props?.userData?.MainUserId,
        PaymentGatewayPaymentProfileID: item.PaymentGatewayPaymentProfileID,
      };
      setAddNewItem("editCard");
      setCardFormValues(cardIntialValues);
    }
    // else{
    //   setAddNewItem('addBank');
    // }
    // setAddNewItem(e.item.key);
  };
  const removeCardOrBank = async (item: any) => {
    const reqId = item.PaymentGatewayPaymentProfileID;
    const deletePaymentProfile = new TenantService();
    const result = await deletePaymentProfile.deletePaymentProfile(reqId);
    if (result.ResponseCode === 300) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      fetchPaymentProfiles(props?.userData?.MainUserId);
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handlePreferred = async (item: any) => {
    const req = {
      AuditType: props?.entityDetails?.AuditType,
      MemberRecurringDetailId: props?.entityDetails?.MemberRecurringDetailId,
      PaymentGatewayPaymentProfileId: item?.PaymentGatewayPaymentProfileID,
    };
    const savePreferred = new TenantService();
    const result = await savePreferred.UpdateRecurringPaymentProfile(req);
    if (result.ResponseCode === 300) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      if (props?.entityDetails?.FilterType === "service") {
        props?.fetchServiceInstance(
          props?.entityDetails?.PrivateServiceInstanceId
        );
      } else if (props?.entityDetails?.FilterType === "plan") {
        props?.fetchPlanSummary();
      } else if (props?.entityDetails?.FilterType === "addon") {
        props?.fetchAddonSummary();
      } else if (props?.entityDetails?.FilterType === "package") {
        props?.fetchPackageSummary();
      }
      fetchPaymentProfiles(props?.userData?.MainUserId);
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const items = [
    {
      iconClass: "fa fa-credit-card pr-1",
      text: "Add Credit Card",
      key: "addCard",
      visible: true,
    },
    {
      iconClass: "fa fa-university pr-1",
      text: "Add Bank Account",
      key: "addBank",
      visible: checkIfUserCanAddBankAccount(),
    },
  ];

  const [cardImagee, setcardImagee] = useState(images.placeholder);

  const [error, setError] = useState("");
  const [isValid, setisValid] = useState(false);
  const CARD_TYPES: any = {
    mastercard: "MASTERCARD",
    visa: "VISA",
    amex: "AMERICAN_EXPRESS",
    jcb: "JCB",
    dinersclub: "DINERS",
    discover: "DISCOVER",
  };
  const handleCardNumberChange = (e: any) => {
    let cardNumber = e.target.value;
    cardNumber = formatCardNumber(cardNumber);
    let cardId = undefined;
    if (cardNumber !== "") {
      const cardNumberLength = cardNumber.split(" ").join("").length;
      const cardTypeName = payment.fns.cardType(cardNumber);
      if (cardTypeName !== "" || cardTypeName !== undefined) {
        const res = cardTypes.filter((item: any) => item.text === cardTypeName);
        cardId = res?.[0]?.id;
      }
      const cardTypeInfo =
        creditCardType.getTypeInfo(
          creditCardType.types[CARD_TYPES[cardTypeName]]
        ) || {};

      const cardTypeLengths = cardTypeInfo.lengths || [16];
      const cardImageValue = images[cardTypeName] || images.placeholder;
      handleCardImageIcon(cardImageValue);
      setcardImagee(cardImageValue);

      if (cardTypeLengths) {
        for (let length of cardTypeLengths) {
          if (
            length === cardNumberLength &&
            payment.fns.validateCardNumber(cardNumber)
          ) {
            setisValid(true);
          } else {
            setisValid(false);
            setError("Card Number is Invalid");
          }
        }
      }
    } else {
      setisValid(false);
      setError("Card Number is required");
    }
    setCardFormValues({
      ...cardFormValues,
      MaskedCCNumber: cardNumber,
      CardTypeId: cardId,
    });
  };

  const handleCardNumberKeyPress = (e: any) => {
    const value = e.target.value;
    checkIsNumeric(e);
    if (value && !isHighlighted()) {
      const valueLength = value.split(" ").join("").length;
      if (hasCardNumberReachedMaxLength(value, valueLength)) {
        e.preventDefault();
      }
    }
  };

  const handlezipCodeBlur = (value: any) => {
    if (value !== "") {
      const zipLength = value.length;
        // If the zip is numeric, it must be exactly 5 digits
        if (/^\d+$/.test(value) && zipLength !== 5) {
          setError("Zip code must be exactly 5 digits or 6 alphanumeric characters");
          setisValid(false);
          return false;
        }
        // If alphanumeric, allow up to 6 characters
        else if (!/^\d+$/.test(value) && zipLength > 6) {
          setError("Zip code must be up to 6 alphanumeric characters or 5 digits");
          setisValid(false);
          return false;
        } 
        // Validate using the isZipValid function
        else if (isZipValid(value)) {
          setisValid(true);
          setError("");
          return true;
        } else {
          setError("Zip code is invalid");
          setisValid(false);
          return false;
        }

    } else {
      setisValid(false);
      setError("Zip code is required");
      return false;
    }
  };

  const handlezipCodeChange = (e: any) => {
    const zip = e.target.value;
    if (zip !== "") {
        // If the input is all digits and longer than 5 characters, it's invalid
        if (/^\d+$/.test(zip) && zip.length > 5) {
          setError("Zip code must be exactly 5 digits or 6 alphanumeric characters");
          setisValid(false);
        } 
        // If the input is alphanumeric and longer than 6 characters, it's invalid
        else if (!/^\d+$/.test(zip) && zip.length > 6) {
          setError("Zip code must be up to 6 alphanumeric characters or 5 digits");
          setisValid(false);
        } 
        // Validate the input using the isZipValid function
        else if (zip !== "" && isZipValid(zip)) {
          setError("");
          setisValid(true);
        } else if (zip !== "") {
          setError("Zip code is invalid");
          setisValid(false);
        } else {
          setError("Zip code is required");
          setisValid(false);
        }
    } else {
      setError("Zip code is required");
      setisValid(false);
    }
    setCardFormValues({ ...cardFormValues, ZipCode: zip });
  };


  const handlezipCodeKeyPress = (e: any) => {
    checkIsNumeric(e);
  };

  const handleCardExpiryBlur = (value: any) => {
    const { customTextLabels } = props;
    const cardExpiry = value?.split(" / ").join("/");
    if (cardExpiry) {
      const message = isExpiryInvalid(cardExpiry);
      if (message === false) {
        setisValid(true);
        return true;
      } else {
        setError(message);
        setisValid(false);
        return false;
      }
    } else {
      setError("Card Expiry Date is Required");
      setisValid(false);
      return false;
    }
  };

  const handleCardExpiryBlurNew = (value: any) => {
    const { customTextLabels } = props;
    const cardExpiry = value?.split(" / ").join("/");
    if (cardExpiry) {
      const message = isExpiryInvalid(cardExpiry);
      if (message === false) {
        setisValid(true);
        const expiryValue = cardExpiry.split("/");
        setCardFormValues({
          ...cardFormValues,
          ExpiryMonthYear: cardExpiry,
          ExpiryMonth: expiryValue[0],
          ExpiryYear: expiryValue[1],
        });
        return true;
      } else {
        setError(message);
        setisValid(false);
        return false;
      }
    } else {
      setError("Card Expiry Date is Required");
      setisValid(false);
      return false;
    }
  };

  const handleCardExpiryChange = (e: any) => {
    const { customTextLabels } = props;
    let cardExpiryField = formatExpiry(e);
    if (cardExpiryField) {
      cardExpiryField = cardExpiryField.split(" / ").join("/");
      const message = isExpiryInvalid(cardExpiryField);
      if (message === false) {
        setisValid(true);
      } else {
        setError(message);
        setisValid(false);
      }
    } else {
      setError("Card Expiry Date is Required");
      setisValid(false);
    }
    if (cardExpiryField) {
      const expiryValue = cardExpiryField.split("/");
      setCardFormValues({
        ...cardFormValues,
        ExpiryMonthYear: cardExpiryField,
        ExpiryMonth: expiryValue[0],
        ExpiryYear: expiryValue[1],
      });
    } else {
      setCardFormValues({
        ...cardFormValues,
        ExpiryMonthYear: "",
        ExpiryMonth: "",
        ExpiryYear: "",
      });
    }
  };
  const isMonthDashKey = ({ key, target: { value } }: any = {}) => {
    return !value.match(/[/-]/) && /^[/-]$/.test(key);
  };

  const handleCardExpiryKeyPress = (e: any) => {
    const value = e.target.value;

    if (value && !isMonthDashKey(e)) {
      checkIsNumeric(e);
    }

    if (value && !isHighlighted()) {
      const valueLength = value.split(" / ").join("").length;
      if (valueLength >= 5) {
        e.preventDefault();
      }
    }
  };

  const checkIsNumeric = (e: any) => {
    if (!/^\d*$/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleCardNumberBlur = (value: any) => {
    if (value) {
      if (!payment.fns.validateCardNumber(value)) {
        setisValid(false);
        setError("Card Number is Invalid");
        return false;
      } else {
        setisValid(true);
        return true;
      }
    } else {
      setisValid(false);
      setError("Card Number is required");
      return false;
    }
  };

  const handleFormValidation = () => {
    const { MaskedCCNumber, ExpiryMonthYear, ZipCode } = cardFormValues;
    if (addNewItem === "addCard") {
      if (
        handleCardNumberBlur(MaskedCCNumber) &&
        handleCardExpiryBlur(ExpiryMonthYear) &&
        handlezipCodeBlur(ZipCode)
      ) {
        handleCardSubmit(cardFormValues, addNewItem);
      }
    } else {
      if (handleCardExpiryBlur(ExpiryMonthYear) && handlezipCodeBlur(ZipCode)) {
        handleCardSubmit(cardFormValues, addNewItem);
      }
    }
  };
  const rendercardForm = (page = "add") => {
    const showCardBasedOnType = () => {
      if (paymentGatewayType === 3) {
        return (
          <SquarePayments
            handleCardSubmit={handleCardSubmit}
            userConfiguration={userConfiguration}
            showCancel={true}
            handleCancel={handleCancel}
            cardSubmitBtnLoading={cardSubmitBtnLoading}
            UserMemberId={props?.userData?.MainUserId}
          />
        );
      } else if (paymentGatewayType === 4) {
        return (
          <StripePayments
            handleCardSubmit={handleCardSubmit}
            userConfiguration={userConfiguration}
            showCancel={true}
            handleCancel={handleCancel}
            type={"card"}
            cardSubmitBtnLoading={cardSubmitBtnLoading}
            UserMemberId={props?.userData?.MainUserId}
          />
        );
      } else {
        return (
          <FormElement className="bz-add-card-field">
            <div className="payment-card-input-new">
              <PaymentCardInput
                cardFormValues={cardFormValues}
                cardImageIcon={cardImageIcon}
                handleCardNumberKeyPress={handleCardNumberKeyPress}
                handleCardNumberBlur={(val: any) => handleCardNumberBlur(val)}
                handleCardNumberChange={(e: any) => handleCardNumberChange(e)}
                handleCardExpiryKeyPress={handleCardExpiryKeyPress}
                handleCardExpiryBlur={(val: any) =>
                  handleCardExpiryBlurNew(val)
                }
                handleCardExpiryChange={(e: any) => handleCardExpiryChange(e)}
                type={page}
                handlezipCodeKeyPress={handlezipCodeKeyPress}
                handlezipCodeBlur={(val: any) => handlezipCodeBlur(val)}
                handlezipCodeChange={(e: any) => handlezipCodeChange(e)}
                error={error}
                isValid={isValid}
              />
            </div>
            <div className="d-flex mt-4 pt-2">
              <Button
                type={"reset"}
                onClick={() => handleCancel()}
                className="ml-0"
              >
                Cancel
              </Button>
              <BuzopsButton
                primary
                label={page === "add" ? "Save" : "Update"}
                disable={!isValid}
                loading={cardSubmitBtnLoading}
                onClick={() => {
                  handleFormValidation();
                }}
              />
            </div>
          </FormElement>
        );
      }
    };
    return (
      <>
        <Form
          render={(cardRendorForm: any) => (
            <>
              {page === "add" ? (
                <h6 className="bz-fs-14px bz-fw-6">Add Card</h6>
              ) : (
                <h6 className="bz-fs-14px bz-fw-6">Edit Card</h6>
              )}
              {showCardBasedOnType()}
            </>
          )}
        />
      </>
    );
  };
  const renderBankForm = () => {
    const showAccountBasedOnType = () => {
      if (paymentGatewayType === 4) {
        return (
          <StripePayments
            bankFormValues={bankFormValues}
            bankSubmitBtnLoading={bankSubmitBtnLoading}
            handleBankSubmit={handleBankSubmit}
            type={"bank"}
            UserMemberId={props?.userData?.MainUserId}
          />
        );
      } else {
        return (
          <Form
            initialValues={bankFormValues}
            onSubmit={handleBankSubmit}
            render={(bankRenderForm: any) => (
              <>
                <h6 className="bz-fs-14px bz-fw-6 mb-0">Add Bank</h6>
                <FormElement style={{ width: "100%" }} className="bz-add-bank">
                  <div className="">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Field
                          id={"BankAccountType"}
                          name={"BankAccountType"}
                          component={FormDropDownList}
                          label={"Account Type"}
                          required={true}
                          validator={requiredValidator}
                          data={BankAccountTypes}
                          textField="text"
                          dataItemKey="id"
                          customvalidation={true}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Field
                          id={"AccountName"}
                          name={"AccountName"}
                          component={FormInput}
                          required={true}
                          placeholder={"Account Name"}
                          label={"Account Name"}
                          validator={requiredValidator}
                          customvalidation={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Field
                          id={"BankName"}
                          name={"BankName"}
                          component={FormInput}
                          placeholder={"Bank Name"}
                          required={true}
                          label={"Bank Name"}
                          validator={requiredValidator}
                          customvalidation={true}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Field
                          id={"RoutingNumber"}
                          name={"RoutingNumber"}
                          component={FormInput}
                          placeholder={"Routing Number"}
                          required={true}
                          label={"Routing Number"}
                          validator={routingNumberValidator}
                          customvalidation={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Field
                          id={"AccountNumber"}
                          name={"AccountNumber"}
                          required={true}
                          component={FormInput}
                          placeholder={"Account Number"}
                          label={"Account Number"}
                          validator={BankAccountValidator}
                          customvalidation={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-4 pt-2">
                    <Button
                      type={"reset"}
                      onClick={() => handleCancel()}
                      className="ml-0"
                    >
                      Cancel
                    </Button>
                    <BuzopsButton
                      primary
                      label={"Save"}
                      type={"submit"}
                      disable={!bankRenderForm.allowSubmit}
                      loading={bankSubmitBtnLoading}
                    />
                  </div>
                </FormElement>
              </>
            )}
          />
        );
      }
    };
    return (
      <>
        {showAccountBasedOnType()}
      </>
    );
  };
  return (
    <>
      <h5 className="mb-3 pt-3">Payment Methods</h5>
      {paymentLoading ? (
        <>
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <>
          <div className="bz-mt-2rem bz-mb-2_5rem">
            {paymentProfile?.length === 0 && (
              <>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">
                    {"Payment Profiles"}
                  </div>
                  <span className="bz-colon text-center">{":"}</span>
                  <div className={`bz-content-body`}>
                    {"No Payment Profiles available."}
                  </div>
                </div>
              </>
            )}
            {paymentProfile
              .slice(0, page.take)
              .map((item: any, index: number) => {
                const cardTypeName = cardTypes.filter(
                  (card: any) => card.id === item.CardTypeId
                );
                let cardId = cardTypeName[0]?.text;
                if (!cardId) {
                  cardId = "placeholder";
                }

                let removeLink =
                  !item.IsActive && item?.CardTypeId !== 8
                    ? "remove-link"
                    : "no-remove-link";
                let editLink = item.IsCreditCard && paymentGatewayType === 1 ? "edit-link" : "no-edit-link";
                const ExpiryDate = item?.IsCreditCard ? item?.ExpiryDate : "";
                let squarePayment =
                  item.IsCreditCard && paymentGatewayType === 3
                    ? "squre-payment"
                    : "";

                const changeAsPrefered = (item.PaymentGatewayPaymentProfileID !==
                  props?.entityDetails?.PaymentGatewayProfileId) &&
                  props?.entityDetails?.MemberRecurringDetailId > 0 ? "bz-change-prefered" : '';
                return (
                  <>
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right">
                        {index === 0 ? "Payment Profiles" : ""}
                      </div>
                      <span className="bz-colon text-center">
                        {" "}
                        {index === 0 ? ":" : ""}
                      </span>
                      <div
                        className={`bz-content-body cards-items-grid ${removeLink} ${editLink} ${squarePayment} ${changeAsPrefered}`}
                      >
                        <span className="bz-card-details">
                          <span>
                            {item.IsCreditCard ? (
                              <img
                                width={30}
                                src={images[cardId]}
                                className="mr-1"
                                alt="CardImg"
                              />
                            ) : (
                              <>
                                {item?.CardTypeId === 8 ? (
                                  <BuzopsTooltip
                                    html={
                                      <span
                                        title={"Credits"}
                                        className="ml-0"
                                      >
                                        <BsCCircleFill style={{ fontSize: "1rem", color: "#008db9" }} />
                                      </span>
                                    }
                                  />
                                ) : (
                                  <BuzopsTooltip
                                    html={
                                      <a
                                        title={
                                          item?.BankAccountType === 1
                                            ? "Savings Bank"
                                            : "Checking Bank"
                                        }
                                      >
                                        <i
                                          className={`fa fa-university fa-lg mr-3 ${item?.BankAccountType === 1 &&
                                            "savingBankAccount"
                                            }`}
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    }
                                  />
                                )}
                              </>
                            )}
                          </span>
                          {item?.CardTypeId === 8 ? (
                            <span>
                              {item?.CardDescriptionWithoutExpiryDate}
                            </span>
                          ) : (
                            <span style={{minWidth:'100px'}}>
                              {item.MaskedCCNumber.slice(-4)}  <span>{ExpiryDate}  {item.Status === "ACL" ? <span className="tooltipach"><BuzopsTooltip
                                style={{ display: 'inline-block' }}
                                html={
                                  <span title="The account has been closed, contact card holder for new card">
                                    <FaExclamationTriangle style={{ color: 'red', marginTop: '-5px' }} />
                                  </span>
                                }
                              /></span> : null} </span>
                            </span>
                          )}

                        </span>
                        {item.IsCreditCard && paymentGatewayType === 1 ? (
                          <span
                            className="btn btn-link mr-2"
                            onClick={() => {
                              onCardOrBankEdit(item);
                            }}
                          >
                            Edit
                          </span>
                        ) : null}
                        {!item.IsActive && item?.CardTypeId !== 8 ? (
                          <span
                            className="btn btn-link"
                            onClick={() => {
                              removeCardOrBank(item);
                            }}
                          >
                            Remove
                          </span>
                        ) : null}
                        {(!item.IsActive ||
                          item.PaymentGatewayPaymentProfileID !==
                          props?.entityDetails?.PaymentGatewayProfileId) &&
                          props?.entityDetails?.MemberRecurringDetailId > 0 && (
                            <span
                              className="btn btn-link"
                              onClick={() => {
                                handlePreferred(item);
                              }}
                            >
                              {"Change as Preferred"}
                            </span>
                          )}
                        {item.IsActive &&
                          item.PaymentGatewayPaymentProfileID ===
                          props?.entityDetails?.PaymentGatewayProfileId && (
                            <span className="badge badge-success ml-0">
                              {"Preferred"}
                            </span>
                          )}
                      </div>
                    </div>
                  </>
                );
              })}
            <div className="bz-content-text-row payment-profile">
              <div className="bz-content-title text-right"></div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                <div className="bz-inner-row bz-add_showmore mt-2">
                  {checkIfUserAddPaymentGateway() && <div className="">
                    <DropDownButton
                      text="Add New"
                      onItemClick={(e: any) => onCardOrBankAdd(e)}
                      items={items.filter((i: any) => i?.visible === true)}
                      look="flat"
                      className="btn btn-link add-new-btn"
                    />
                  </div>}
                  
                  <div className="" ref={showMoreRef}>
                    {paymentProfile.length > page.take && (
                      <span
                        className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                        onClick={() => handlePageChange()}
                      >
                        Show More <i className="fas fa-caret-down"></i>
                      </span>
                    )}
                    {paymentProfile.length <= page.take &&
                      paymentProfile.length > 2 && (
                        <span
                          className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                          onClick={() => handlePageChange("less")}
                        >
                          Show less <i className="fas fa-caret-up"></i>
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right"></div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                <div>
                  {addNewItem === "addBank" && renderBankForm()}
                  {addNewItem === "addCard" && rendercardForm()}
                  {addNewItem === "editCard" && rendercardForm("edit")}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withNotification(PaymentMethods);
