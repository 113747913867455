import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ContextApi } from "context-api/AppContext";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsNotification from "generic-components/BuzopsNotification";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TenantService } from "services/tenant/index.service";
import { FormInput } from "utils/form-components";
import { GetLocalStore } from "utils/storage";
import { emailValidator } from "validators/validator";
import { Hint } from "@progress/kendo-react-labels";

function LoginPage(props: any) {
  const history = useHistory();
  const appState = useContext(ContextApi);
  const [loading, setLoading] = useState(false);
  const providerId = props.match?.params?.providerId;
  const [guestLayout, setGuestLayout] = useState<boolean>(
    providerId ? true : false
  );

  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });

  const [message, setMessage] = useState("");

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
    }, 3000);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification, notification.error, notification.success]);

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

  const tenantService = new TenantService();
  const handleSubmit = async (dataItem: any) => {
    setLoading(true);
    const req = { EmailId: dataItem.email };
    const verifyEmailExistence = await tenantService.verifyIfEmailExist(req);
    appState.dispatch({ type: "USER_LOGIN", payload: dataItem });
    if (
      verifyEmailExistence.isEmailExists &&
      verifyEmailExistence.IsActivated
    ) {
      if (guestLayout) {
        props.setAuthRoute("welcome", {
          provider: props.location.state?.provider,
          selectedService: props.location.state?.selectedService,
        });
      } else {
        props.setAuthRoute("welcome");
      }
    } else {
      if (guestLayout) {
        const response = await tenantService.sendOTP({
          EmailId: verifyEmailExistence.EmailId,
          UserId: verifyEmailExistence.UserId,
          UserMemberId: verifyEmailExistence.UserMemberId,
        });

        props.setAuthRoute("emailOTP", {
          EmailId: verifyEmailExistence.EmailId,
          UserId: verifyEmailExistence.UserId,
          UserMemberId: verifyEmailExistence.UserMemberId,
        });


        if (response.ResponseCode === 100) {
          setMessage("OTP sent");
          setNotification({ ...notification, success: false, error: true });
        } else {
          setMessage("Invalid OTP");
          setNotification({ ...notification, success: false, error: true });
        }
      } else {
        const response = await tenantService.sendOTP({
          EmailId: verifyEmailExistence.EmailId,
          UserId: verifyEmailExistence.UserId,
          UserMemberId: verifyEmailExistence.UserMemberId,
        });
        props.setAuthRoute("emailOTP", {
          EmailId: verifyEmailExistence.EmailId,
          UserId: verifyEmailExistence.UserId,
          UserMemberId: verifyEmailExistence.UserMemberId,
        });
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    const userDetails = GetLocalStore("userDetails");
    if (userDetails) {
      history.push('/summary');
    }
  }, []);

  const LoginBody = () => {
    return (
      <>
        <div className="bzo-login-body">
          <p className="text-left my-3"> Enter your email address</p>
          <div className="form-group">
            <Field
              id={"email"}
              name={"email"}
              component={FormInput}
              validator={(val:any) => emailValidator(val,"Email")}
              autoFocus={true}
              hidelabel={true}
            />
            <Hint>
              {
                "To make a purchase please enter your email to log in or provide your email to create a new account"
              }
            </Hint>
          </div>
          <div className="">
            <ButtonGroup>
              <Button type={"reset"} onClick={(e) => handleRedirect(e)}>
                Cancel
              </Button>

              <BuzopsButton
                type={"submit"}
                label={"Next"}
                loading={loading}
                disable={loading}
              />
            </ButtonGroup>
          </div>
        </div>
      </>
    );
  };
  const handleRedirect = (e: any) => {
    e.stopPropagation();
    props.handleCloseLogin();
  };

  return (
    <>
      <BuzopsNotification
        notification={notification}
        message={message}
        handleNotification={(type: any) => handleNotification(type)}
      />
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <div className="">
              <div className="bzo-login-card bz-emailLogin-card border-0 bz-border-radius-1">
                <div className="bzo-login-header">
                  <h4 className="h4 mb-0 text-left">Login or Create Account</h4>
                </div>
                <LoginBody />
              </div>
            </div>
          </FormElement>
        )}
      />
    </>
  );
}

export default LoginPage;
