import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsNotification from "generic-components/BuzopsNotification";
import { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { FormInput } from "utils/form-components";
import { otpValidator } from "validators/validator";

const EmailOTP = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const tenantService = new TenantService();
  const providerId = props.match?.params?.providerId;
  const [guestLayout, setGuestLayout] = useState<boolean>(
    providerId ? true : false
  );

  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });

  const [message, setMessage] = useState("");
  const [seconds, setSeconds] = useState(300);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
    }, 3000);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification, notification.error, notification.success]);

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

  const handleSubmit = async (dataItem: any) => {
    setLoading(true);
    const response = await tenantService.validateOTP({
      EmailId: props.locationData.EmailId,
      UserId: props.locationData.UserId,
      UserMemberId: props.locationData.UserMemberId,
      Code: dataItem.code,
    });

    if (response.ResponseCode === 100) {
      if (guestLayout) {
        props.setAuthRoute("signup", {
          provider: props.location.state?.provider,
          selectedService: props.location.state?.selectedService,
          UserMemberId: props.locationData?.UserMemberId,
        });
      } else {
        props.setAuthRoute("signup", {
          UserMemberId: props.locationData?.UserMemberId,
        });
      }
      setMessage("OTP Successful");
      setNotification({ ...notification, success: false, error: true });
    } else {
      setMessage("Invalid OTP");
      setNotification({ ...notification, success: false, error: true });
    }
    console.log(response);
    setLoading(false);
  };
  const handleRedirect = (e: any) => {
    e.stopPropagation();
    props.handleCloseLogin();
  };

  const resendOTP = async () => {
    setResendLoading(true);
    const response = await tenantService.sendOTP({
      EmailId: props.locationData.EmailId,
      UserId: props.locationData.UserId,
      UserMemberId: props.locationData.UserMemberId,
    });

    if (response.ResponseCode === 100) {
      setMessage("OTP resent");
      setNotification({
        ...notification,
        success: true,
        error: false,
      });
      setSeconds(300);
    } else {
      setMessage("Error occurred while performing the operation");

      setNotification({
        ...notification,
        success: false,
        error: true,
      });
    }

    setResendLoading(false);
  };

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [seconds]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <>
      <BuzopsNotification
        notification={notification}
        message={message}
        handleNotification={(type: any) => handleNotification(type)}
      />
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <div className="">
              <div className="bzo-login-card bz-emailLogin-card border-0 bz-border-radius-1">
                <div className="bzo-login-header">
                  <h4 className="h4 mb-0 text-left">Enter OTP</h4>
                </div>
                <div className="bzo-login-body">
                  <p className="text-left my-3">
                    An email has been sent to you with a one time passcode
                    (OTP). Please check your email and enter this code within 5
                    minutes to verify your identity and complete the process.
                  </p>
                  <p>
                    OTP Expires in: {minutes.toString().padStart(2, "0")}:
                    {remainingSeconds.toString().padStart(2, "0")}
                  </p>

                  <div className="form-group">
                    <Field
                      id={"code"}
                      name={"code"}
                      component={FormInput}
                      validator={otpValidator}
                      autoFocus={true}
                      hidelabel={true}
                    />
                  </div>
                  <div className="">
                    <ButtonGroup>
                      <Button type={"reset"} onClick={(e) => handleRedirect(e)}>
                        Cancel
                      </Button>

                      <BuzopsButton
                        type={"submit"}
                        label={"Next"}
                        loading={loading}
                        disable={loading}
                      />
                      <BuzopsButton
                        label={"resend"}
                        loading={resendLoading}
                        disable={resendLoading || seconds !== 0}
                        onClick={resendOTP}
                      />
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
          </FormElement>
        )}
      />
    </>
  );
};

export default EmailOTP;
