import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TextArea } from "@progress/kendo-react-inputs";
import BuzopsButton from "generic-components/BuzopsButton";
const Modal = ({
  setShowModal,
  title,
  onChange,
  submit,
  disabled,
  value,
  btnLoading,
}: any) => {
  return (
    <Dialog
      title={title}
      onClose={() => {
        setShowModal(true);
      }}
      className="bz-notes-dialog"
    >
      <div className={"k-form-field-wrap"}>
        <TextArea
          disabled={false}
          onChange={(e) => {
            if (e?.value?.trim() == "") {
              onChange("");
              return true;
            } else {
              onChange(e?.value);
            }
          }}
          value={value}
          rows={5}
        />
      </div>
      <div className="dialog-btn-group">
      <Button
          onClick={() => {
            setShowModal(true);
          }}
          type={"submit"}
          disabled={btnLoading}
        >
          Cancel
        </Button>
        <BuzopsButton type="submit" className="mr-0"  onClick={submit} primary={true}  disabled={disabled || btnLoading} loading={btnLoading}  label={"Submit"} />
      </div>
    </Dialog>
  );
};

export default Modal;