export const staffGridColumns = [
  {
    title: "Provider Name",
    field: "TrainerName",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Service Name",
    field: "ServiceName",
    show: true,
    filter: "text",
    width: "250px",
  },
  {
    title: "Notes",
    field: "Notes",
    show: true,
    filter: "text",
    width: "150px",
    enableHtml: true,
  },
  {
    title: "Rate",
    field: "Amount",
    show: true,
    filter: "numeric",
    width: "150px",
  },
  {
    title: "Transaction Date",
    field: "TransactionDate",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Status",
    field: "PayoutScheduleStatusDescription",
    show: true,
    filter: "text",
    width: "150px",
  },
];
