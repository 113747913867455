import { Surface, Path, geometry } from '@progress/kendo-drawing';
import React, { useState } from "react";
import { ColorPicker } from '@progress/kendo-react-inputs';
import SignaturePad from 'react-signature-pad-wrapper'

const { Point } = geometry;
let surface: Surface;
let surfaceElement: any;
let path: Path | undefined;
const DrawSign = (props: any) => {
    const [selectedColor, setSelectedColor] = useState("#181C32");
    const [formValid, setFormValid] = useState<boolean>(false);

    const paletteSettings = {
        palette: [
            "#d21c15", "#27ae60", "#181C32", "#6f7477",
            "#F55B7B", "#A9A543", "black", "#46a4c6",
            "#2980b9", "#27ae60", "#16a085", "#8e44ad",
            "#2c3e50", "#f39c12", "#d35400", "#514d1b",
            "#c6d9f0", "#8db3e2", "#548dd4", "#17365d"
        ],
        columns: 5,
        tileSize: 30
    };

    const handleClear = () => {
        surfaceElement.instance.clear();
        setFormValid(false);
    }
    const handleExport = () => {
        if(!surfaceElement.isEmpty()){
            props.getImage(surfaceElement.toDataURL());
        }
    }

    return (
        <>
          
            <div className={"row justify-content-center bzo-signaturepad"}>
               <div className="col-12 bz-signaturepad-container">
               <React.Fragment>
                    {/* <div
                        ref={(el) => surfaceElement = el}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        className="signature-area"
                        style={{ width: 568, height: 250, backgroundColor: "#F9F9FA", WebkitBoxShadow: "0 0 5px rgba(0, 0, 0, 0.02) inset",
                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.02) inset" }}
                    /> */}
                    <SignaturePad options={{minWidth: 0.1, maxWidth: 4}} className="signature-area" ref={(el: any) => surfaceElement = el} />
                    {/* <button className="k-button" onClick={handleExport}>Export as SVG</button> */}
                    {/* <button className="k-button" onClick={this.handleClear}>Clear drawing</button> */}
                </React.Fragment>
               </div>
            </div>
            <div className="row justify-content-end mt-2 signaturepad-btn-group">
                {/* <div className={"col-md-2 text-right align-self-center"}>
                    <span>clear</span>
                </div> */}
                <div className="col-md-12 text-right d-flex justify-content-end">
                {/* <span onClick={handleClear} style={{cursor: "pointer"}}>clear</span> */}
                {/* <ColorPicker view="gradient" defaultValue={'green'} /> */}
                <ColorPicker
                        defaultValue={selectedColor}
                        paletteSettings={paletteSettings}
                        value={selectedColor}
                        onChange={(color) => { setSelectedColor(color.value); surfaceElement.penColor = color.value; handleClear()} }
                    />
                    <button  className="k-button" onClick={handleClear}>Clear</button>
                    <button  className="k-button k-primary" onClick={handleExport}>Apply</button>
                </div>
            </div >
        </>
    )
}
export default DrawSign;