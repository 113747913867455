import React from 'react'
import DragHandle from './DragHandle'

const HeaderBar = (props:any) => {
  return (
    <div className="toolbar-header">
    {/* <span className="badge badge-secondary">{props?.data.text}</span> */}
    <div className="toolbar-header-buttons">
      {props?.data.element !== 'LineBreak' && props?.data.element !== 'Address' && props?.data.element !== 'Prospect' &&
        <div className="btn is-isolated" onClick={(e:any)=>props?.editModeOn(props?.data,e)} title="Edit"><i className="is-isolated fas fa-edit editformbuzops"></i></div>
      }
      {!props?.data?.parentId &&  <div className="btn is-isolated" onClick={()=>props?.onDestroy(props?.data)} title="Delete"><i className="is-isolated fas fa-trash-alt deleteformbuzops"></i></div>}
      {!props?.data?.parentId && <span title="Drag & Drop"><DragHandle data={props?.data} index={props?.index} onDestroy={props?.onDestroy} setAsChild={props?.setAsChild} /></span>}
      {!props?.data?.parentId && props?.index!==0 &&  <div className="btn is-isolated" onClick={()=>props?.moveCard(props?.index,props?.index-1)} title="Move Up"> <i className="is-isolated fas fa-arrow-up editformbuzops"></i></div>}
      {!props?.data?.parentId  && !props?.isLastElement && <div className="btn is-isolated" onClick={()=>props?.moveCard(props?.index,props?.index+1)} title="Move Down"><i className="is-isolated fas fa-arrow-down editformbuzops"></i></div>}
    </div>
  </div>
  )
}

export default HeaderBar
