/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import {
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import {
  Card,
  CardBody,
  CardHeader,
} from "@progress/kendo-react-layout";
import "./styles.scss";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { OnsiteAccessService } from "services/onsite-access/index.service";
import { GetLocalStore } from "utils/storage";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import CheckIns from "components/summary/CheckIns";
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import withNotification from "components/Hoc/withNotification";

const OnsiteAccess = (props: any) => {
  const [ready, setReady] = React.useState(false);
  const [okLoading, setoKLoading] = React.useState(false);
  const [accessConfigList, setAccessConfigList] = React.useState<any>([]);
  const [dd, setDD] = React.useState<any>([]);
  const [inp, setInp] = React.useState([]);
  const [editMode, setEditMode] = React.useState([]);
  const [enableSAve, setEnableSave] = React.useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [visibleAC, setVisibleAC] = useState<boolean>(false);
  const toggleACDialog = () => {
    setVisibleAC(!visibleAC);
  };

  const userData = GetLocalStore("userDetails");
  const showAccess = userData.ShowOnsiteAccessConfiguration;

  const [clickedItem, setClickedItem] = React.useState<any>();
  useEffect(() => {
    fetchAccessConfiguration();
  }, []);

  const fetchAccessConfiguration = async () => {
    setLoading(true);
    const onsiteService = new OnsiteAccessService();
    const result = await onsiteService.getAccessConfiguration({
      UserMemberId: props?.userData?.UserMemberId,
      IncludeSubMember: true,
    });
    setAccessConfigList(result);
    const d: any = [];
    const n: any = [];
    const e: any = [];
    result.forEach((item: any, i: number) => {
      const f = item.SiteDataSource.filter((s: any) =>
        item.AccessCard.SelectedSiteId.includes(s.SiteId)
      );
      d.push(f);
      n.push(
        item.AccessCard.CardNumber ? item.AccessCard.CardNumber.toString() : ""
      );
      e.push(false);
    });
    setDD(d);
    setInp(n);
    setEditMode(e);
    setEnableSave(e);
    setReady(true);
    setLoading(false);
  };

  const saveOnsiteAccess = async (i: number, item: any) => {
    setoKLoading(true);
    if (!inp[i]) {
      const errorMsg = "Access card number is required";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    if (!dd[i] || dd[i].length === 0) {
      const errorMsg = "Please select at least one site";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    const req = {
      CardNumber: inp[i],
      IsSuspended: item.AccessCard.IsSuspended,
      SelectedSiteId: dd[i].map((e: any) => e.SiteId),
      UniqueId: item.AccessCard.UniqueId,
      EntityId: item.AccessCard.EntityId,
      UserId: item.UserId,
      UserMemberId: item.UserMemberId,
      CredentialId: item.AccessCard.CredentialId,
    };
    const onsiteService = new OnsiteAccessService();
    const result = await onsiteService
      .saveAccessConfigurationFetch(req)
      .catch((e) => {
        showServerError();
        return;
      });
    if (!result) {
      showServerError();
      return;
    }
    setoKLoading(false);
    if (result.ResponseCode === 300) {
      const successMsg = "Access config saved successfully";
      props?.handleNotificationMessage(successMsg, "success");
      const g: any = [...editMode];
      g[i] = false;
      setEditMode(g);
      fetchAccessConfiguration();
    } else {
      let errorMsg = result?.ErrorMessages?.[0];
      if (!errorMsg) {
        showServerError();
        return;
      }
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const showServerError = () => {
    setoKLoading(false);
    const errorMsg = "Something happened. Server doesn't seem to be responding";
    props?.handleNotificationMessage(errorMsg, "error");
  };

  const handleAction = (action: number, i: number, item: any) => {
    if (action === 1) {
      handleCancel(i);
      const g: any = [...editMode];
      g.map((e: boolean, j: number) => {
        if (j === i) g[j] = true;
        else g[j] = false;
      });
      setEditMode(g);
    } else {
      if (action === 2) {
        setConfirmationMsg("Are you sure you want to activate access?");
      }
      if (action === 3) {
        if (item.CanDisableEditAndActivate || !item?.AccessCard?.CardNumber) {
          return true;
        }
        setConfirmationMsg(
          "Are you sure you want to suspend access temporarily?"
        );
      }
      if (action === 4) {
        if (item.CanDisableRemoveAccess || !item?.AccessCard?.CardNumber) {
          return true;
        }
        setConfirmationMsg(
          "Are you sure you want to remove access permanently?"
        );
      }
      setClickedItem(item);
      toggleACDialog();
    }
  };

  const handleCancel = (i: number) => {
    const d: any = [];
    const n: any = [];
    const e: any = [];
    accessConfigList.forEach((item: any, i: number) => {
      const f = item.SiteDataSource.filter((s: any) =>
        item.AccessCard.SelectedSiteId.includes(s.SiteId)
      );
      d.push(f);
      n.push(
        item.AccessCard.CardNumber ? item.AccessCard.CardNumber.toString() : ""
      );
      e.push(false);
    });
    setDD(d);
    setInp(n);
    setEditMode(e);
    setEnableSave(e);
    setReady(true);
    const g: any = [...editMode];
    g[i] = false;
    setEditMode(g);
  };

  const handleDD = (value: any, i: number) => {
    const g: any = [...dd];
    g[i] = value;
    setDD(g);
  };

  const handleIn = (value: any, i: number) => {
    const g: any = [...inp];
    g[i] = value;
    setInp(g);
  };

  const performAction = async () => {
    if (confirmationMsg.includes("remove")) {
      setoKLoading(true);
      const reqRemove = {
        CardNumber: "",
        IsSuspended: clickedItem.AccessCard.IsSuspended,
        SelectedSiteId: [],
        UniqueId: clickedItem.AccessCard.UniqueId,
        EntityId: clickedItem.AccessCard.EntityId,
        UserId: clickedItem.UserId,
        UserMemberId: clickedItem.UserMemberId,
        CredentialId: clickedItem.AccessCard.CredentialId,
      };
      const onsiteService = new OnsiteAccessService();
      const result = await onsiteService
        .saveAccessConfigurationFetch(reqRemove)
        .catch((e) => {
          toggleACDialog();
          showServerError();
          return;
        });
      toggleACDialog();
      setoKLoading(false);
      if (!result) {
        showServerError();
        return;
      }
      if (result.ResponseCode === 300) {
        const successMsg = "Access config removed successfully";
        props?.handleNotificationMessage(successMsg, "success");
        fetchAccessConfiguration();
      } else {
        const errorMsg = result?.ErrorMessages?.[0];
        if (!errorMsg) {
          showServerError();
          return;
        }
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } else {
      setoKLoading(true);
      const req = {
        IsSuspended: clickedItem.AccessCard.IsSuspended,
        EntityId: clickedItem.AccessCard.EntityId,
        UserId: clickedItem.UserId,
      };
      if (confirmationMsg.includes("activate"))
        req.IsSuspended = !clickedItem.AccessCard.IsSuspended;
      if (confirmationMsg.includes("suspend"))
        req.IsSuspended = !clickedItem.AccessCard.IsSuspended;
      const onsiteService = new OnsiteAccessService();
      const result = await onsiteService
        .updateAccessConfigurationFetch(req)
        .catch((e) => {
          toggleACDialog();
          showServerError();
          return;
        });
      toggleACDialog();
      setoKLoading(false);
      if (!result) {
        showServerError();
        return;
      }
      if (result.ResponseCode === 100) {
        let successMsg = "";
        if (confirmationMsg.includes("activate"))
          successMsg = "Access config activated successfully";
        if (confirmationMsg.includes("suspend"))
          successMsg = "Access config suspended successfully";
        props?.handleNotificationMessage(successMsg, "success");
        fetchAccessConfiguration();
      } else {
        const errorMsg = result?.ErrorMessages?.[0];
        if (!errorMsg) {
          showServerError();
          return;
        }
        props?.handleNotificationMessage(errorMsg, "success");
      }
    }
  };

  const AccessConfig = (acprops: any) => {
    return acprops?.items?.map((item: any, i: number) => {
      return (
        <>
          <div
            key={i}
            className={`buz-online-list-view bz-openPath-credintials bz-onsiteAccess ${
              !item?.Email && "cursor-not-allowed"
            }`}
          >
            <div className=" d-flex justify-content-between mobile-onsite-checkins mb-4">
              <div className="section-1" style={{ width: "20%" }}>
                <div className="bz-provider-name">
                  <span className="bz-provider text-muted">Member Name</span>
                  <h6>
                    <ClientNameWithMemberType
                      memberType={item?.MemberType}
                      clientName={item?.FullName}
                    />
                  </h6>
                </div>
              </div>
              <div className="section-2">
                <div className="bz-provider-name">
                  <span className="bz-provider text-muted">Sites</span>
                  <div>
                    <MultiSelect
                      required={editMode[i]}
                      style={{ width: "100%", height: "auto" }}
                      data={item.SiteDataSource}
                      value={dd[i]}
                      onChange={(event) => handleDD(event.target.value, i)}
                      dataItemKey="SiteId"
                      textField="Name"
                      disabled={!editMode[i]}
                      className={"bz-checkins-sites"}
                    />
                  </div>
                </div>
              </div>
              <div className="section-2">
                <div className="bz-provider-name">
                  <span className="bz-provider text-muted">
                    Access Card Number
                  </span>
                  <div>
                    <Input
                      name="cardNumber"
                      autoComplete={"off"}
                      autoFocus={true}
                      style={{ width: "100%" }}
                      value={inp[i]}
                      onChange={(event) => handleIn(event.target.value, i)}
                      disabled={!editMode[i]}
                      required={editMode[i]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr style={{ marginBottom: "0.75rem" }} />
              </div>
              {(props?.match?.staffPage || props?.staffPage) && (
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-sm-8 col-xs-12 col-md-6 col-lg-6">
                    {!item?.Email && (
                      <div className="mr-auto text-danger mb-3 mb-sm-0">
                        User must have a valid email to assign access card.
                      </div>
                    )}
                    {editMode[i] && (
                      <>
                        <BuzopsButton
                          onClick={() => saveOnsiteAccess(i, item)}
                          primary
                          type={"button"}
                          label={"Save"}
                          disabled={
                            !dd[i] ||
                            (dd[i] && dd[i].length === 0) ||
                            !inp[i] ||
                            inp[i] === "0"
                          }
                          loading={okLoading}
                        />
                        <Button
                          className="ml-0"
                          onClick={() => handleCancel(i)}
                          disabled={okLoading}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </div>
                  <div
                    className="col-sm-4 col-xs-12 col-md-6 col-lg-6 bz-openPath-actions"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "fit-content",
                      }}
                      className="bz-checkins-actions-container"
                    >
                      {!editMode[i] && (
                        <>
                          <BuzopsTooltip
                            html={
                              <Button
                                primary={true}
                                iconClass="fa fa-edit"
                                className="k-button-sm"
                                onClick={() => handleAction(1, i, item)}
                                title="Edit"
                                style={{
                                  pointerEvents:
                                    item.CanDisableEditAndActivate ||
                                    !item.Email
                                      ? "none"
                                      : "all",
                                }}
                              >
                                Edit
                              </Button>
                            }
                          />
                          {item.AccessCard?.IsSuspended && (
                            <BuzopsTooltip
                              html={
                                <a
                                  style={{
                                    margin: "0rem 0.5rem",
                                    color: "skyblue",
                                  }}
                                  title="Activate access"
                                  onClick={() => handleAction(2, i, item)}
                                  className="bz-cursor-pointer"
                                >
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="fa fa-check"
                                  ></i>
                                </a>
                              }
                            />
                          )}
                          {!item.AccessCard?.IsSuspended && (
                            <BuzopsTooltip
                              html={
                                <a
                                  style={{
                                    margin: "0rem 0.5rem",
                                    color: "green",
                                    pointerEvents:
                                      item.CanDisableEditAndActivate ||
                                      !item.Email
                                        ? "none"
                                        : "all",
                                  }}
                                  title="Access will be Suspend temporarily"
                                  onClick={() => handleAction(3, i, item)}
                                  className={`bz-cursor-pointer ${
                                    item?.AccessCard?.CardNumber
                                      ? ""
                                      : "disabled"
                                  }`}
                                >
                                  <i
                                    style={{ fontSize: "16px" }}
                                    className="fa fa-stop-circle"
                                  ></i>
                                </a>
                              }
                            />
                          )}
                          <BuzopsTooltip
                            html={
                              <a
                                style={{
                                  margin: "0rem 0.5rem",
                                  color: "crimson",
                                  pointerEvents:
                                    item.CanDisableRemoveAccess || !item.Email
                                      ? "none"
                                      : "all",
                                }}
                                title="Access will be removed permanently"
                                onClick={() => handleAction(4, i, item)}
                                className={`bz-cursor-pointer ${
                                  item?.AccessCard?.CardNumber ? "" : "disabled"
                                }`}
                              >
                                <i
                                  style={{ fontSize: "16px" }}
                                  className="fa fa-trash"
                                ></i>
                              </a>
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      );
    });
  };

  return (
    <div className={"row"}>
      <Card style={{ background: "white", height: "auto" }}>
        {showAccess && (
          <CardHeader className="p-0">
            <h5 className="mb-2 pb-1 bz_fs_1_5">{"Access Configuration"}</h5>
          </CardHeader>
        )}
        {showAccess && (
          <CardBody className="p-0">
            {loading ? (
              <BuzopsLoader type={"list"} />
            ) : (
              <div>
                {accessConfigList.length > 0 && ready && (
                  <AccessConfig items={accessConfigList} />
                )}
              </div>
            )}
          </CardBody>
        )}
        <CardBody className="px-0">
          <div
            style={{
              border: "0px solid gray",
              padding: "1rem",
              borderRadius: "0.5rem",
              textAlign: "justify",
              boxShadow: "0px 3px 8px #00000029",
            }}
          >
            <CheckIns userData={props?.userData} />
          </div>
        </CardBody>
      </Card>
      {visibleAC && (
        <Dialog
          title={"Confirm Action"}
          onClose={toggleACDialog}
          height={200}
          width={300}
        >
          <div>{confirmationMsg}</div>
          <DialogActionsBar layout={"stretched"}>
            <BuzopsButton
              onClick={() => performAction()}
              primary
              type={"button"}
              label={"Ok"}
              loading={okLoading}
              disabled={okLoading}
            />
            <Button onClick={toggleACDialog} disabled={okLoading}>
              Cancel
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default withNotification(OnsiteAccess);
