import { Window } from "@progress/kendo-react-dialogs";
import React, { useEffect, useRef, useState } from "react";
import { BuzopsWindowProps } from "./BuzopsWindow.props";
import "./../index.scss";

const BuzopsWindow = (props: BuzopsWindowProps) => {
  const [visibility, setVisibility] = useState(props.visibility || false);
  const cwindow = useRef(null);
  useEffect(() => {
    document.body.style.overflow = visibility ? "hidden" : "unset";
    if(cwindow)
      console.log(cwindow?.current?.element?.focus());
  }, []);
  const reduceWidth = props.reduceWidth || 100;

  return visibility ? (
    <Window
      onClose={() => props.onWindowClose()}
      className={props.className}
      title={props.title}
      resizable={false}
      // closeButton={undefined}
      style={{
        right: "0",
        height: "100%",
        left: "unset",
        position: "fixed",
        zIndex: 9999,
      }}
      top={15}
      draggable={false}
      width={window.innerWidth - reduceWidth}
      doubleClickStageChange={false}
      minimizeButton={() => <span></span>}
      maximizeButton={() => <span></span>}
      ref={cwindow}
    >
      {props.children}
    </Window>
  ) : (
    <></>
  );
};
export default BuzopsWindow;
