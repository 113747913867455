import { GetLocalStore } from "./storage";

const CheckPermissions = (type: number,CurrentApplicationType=GetLocalStore("CurrentApplicationType"),userData = JSON.parse(
  JSON.stringify(GetLocalStore("tenantUserSession"))
)) => {

  if (CurrentApplicationType === 1) {
    return true;
  } else if (CurrentApplicationType === 8) {
    // eslint-disable-next-line no-new-object
    let userObj: any = new Object();
    for (const key in userData?.Features) {
      if (userData?.Features[key] === 2) {
        userObj[key] = userData?.Features[key];
      }
    }
    if (Object.keys(userObj).includes(type.toString())) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export default CheckPermissions;
