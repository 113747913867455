import React, { useEffect } from "react";
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { Popup } from "@progress/kendo-react-popup";
import { CustomColumnMenu } from "./CustomColumnMenu";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { SortDescriptor } from "@progress/kendo-data-query";
export const FullScreenView = ({ viewData }: any) => {
  return <div className="subscribers-fullview">{viewData}</div>;
};
const initialSort: Array<SortDescriptor> = [
  { field: "MemberName", dir: "asc" },
];
const BuzopsReportGrid = (props: any) => {
  const {
    handleSearch,
    result,
    pageSize,
    dataState,
    fileName,
    dataStateChange,
    stateGridColumns,
    onColumnsSubmit,
    setStateGridColumns,
    onCellClick,
    excelData,
    setExcelData,
    DetailComponent,
    expandChange,
    renderCustomCell,
    hideSearch=false,
  } = props;

  const [sort, setSort] = React.useState(initialSort);
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const [fullScreen, setFullscreen] = React.useState(false);
  const _exporter: any = React.createRef();
  useEffect(() => {
    if (excelData?.length > 0) {
      save(_exporter);
    }
  }, [excelData?.length]);
  const excelExport = () => {
    dataStateChange({}, true);
  };
  const save = (component: any) => {
    const options = component.current.workbookOptions();
    const rows = options.sheets[0].rows;
    let altIdx = 0;
    rows.forEach((row: any) => {
      if (row.type === "data") {
        if (altIdx % 2 !== 0) {
          row.cells.forEach((cell: any) => {
            cell.background = "#aabbcc";
          });
        }

        altIdx++;
      }
    });
    component.current.save(options);
    setExcelData();
  };

  const toggleScreen = () => {
    setFullscreen(!fullScreen);
    var style = document.createElement("style");
    style.innerHTML = `body::-webkit-scrollbar {display: ${
      fullScreen ? "block" : "none"
    };}`;
    document.head.appendChild(style);
  };
  const onReset = (event: any) => {
    event.preventDefault();
    const newColumns = stateGridColumns.map((col: any) => {
      return { ...col, show: true };
    });
    setStateGridColumns(newColumns);
    onColumnsSubmit(newColumns);

    setShow(!show);
  };

  const onSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    onColumnsSubmit(stateGridColumns);

    setShow(!show);
  };
  const onToggleColumn = (id: any) => {
    const newColumns = stateGridColumns.map((column: any, idx: any) => {
      return idx === id ? { ...column, show: !column.show } : column;
    });
    setStateGridColumns(newColumns);
  };
  const oneVisibleColumn =
    stateGridColumns.filter((c: any) => c.show).length === 1;
  return (
    <div className={fullScreen ? "subscribers-fullview" : ""}>
      <div className="m-3 subscribers-grid-border">
        {!hideSearch && <div className="d-flex pt-3 pb-4 subscribers-search-header">
          <div className="d-flex mr-3 subscribers-search">
            <i className="ml-2 fas fa-search"></i>
            <input
              type={"search"}
              className="form-control"
              placeholder="Search"
              onChange={(event) => handleSearch(event.target.value)}
              // value={search}
            />
          </div>
          <button
            title="Settings"
            className="mr-3 subscribers-settings"
            onClick={() => setShow(!show)}
            ref={anchor}
          >
            <li className="k-icon k-i-gears"></li>
          </button>
          <Popup
            anchor={anchor.current}
            show={show}
            popupClass={"popup-content"}
          >
            <div>Columns</div>

            <form onSubmit={onSubmit} onReset={onReset}>
              <div className={"k-column-list"}>
                {stateGridColumns.map((column: any, idx: any) => (
                  <div key={idx} className={"k-column-list-item"}>
                    <span>
                      <input
                        id={`column-visiblity-show-${idx}`}
                        className="k-checkbox k-checkbox-md k-rounded-md"
                        type="checkbox"
                        readOnly={true}
                        disabled={column.show && oneVisibleColumn}
                        checked={column.show}
                        onClick={() => {
                          onToggleColumn(idx);
                        }}
                      />
                      <label
                        htmlFor={`column-visiblity-show-${idx}`}
                        className="k-checkbox-label"
                        style={{
                          userSelect: "none",
                        }}
                      >
                        {column.title}
                      </label>
                    </span>
                  </div>
                ))}
              </div>
              <div className={"k-actions k-hstack k-justify-content-stretch"}>
                <button
                  type={"reset"}
                  className={
                    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  }
                >
                  Reset
                </button>
                <button
                  className={
                    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  }
                >
                  Save
                </button>
              </div>
            </form>
          </Popup>
          <div className="subscribers-grid-button-header">
            <button
              title="Download"
              className="mr-3 subscribers-download"
              onClick={excelExport}
            >
              <li className="k-icon k-i-download"></li>
            </button>
            <button
              title="Fullscreen"
              className="subscribers-fullscreen"
              onClick={toggleScreen}
            >
              <li className="k-icon k-i-full-screen"></li>
            </button>
          </div>
        </div>}

        <Grid
          data={result}
          {...dataState}
          onDataStateChange={dataStateChange}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
            dataStateChange(e);
          }}
          pageable={true}
          pageSize={pageSize}
          resizable={true}
          detail={DetailComponent}
          expandField="expanded"
          onExpandChange={expandChange}
        >
          {stateGridColumns.map(
            (column: any, idx: any) =>
              column.show && (
                <Column
                  key={idx}
                  field={column.field}
                  title={column.title}
                  filter={column.filter}
                  width={column.width}
                  footerCell={column?.footerCell}
                  cell={(props) => (
                    <td>
                      {column.customValCell ? (
                        <p>
                          {column.customValCell(props.dataItem[column.field])}
                        </p>
                      ) : column.customCell ? (
                        <p>{renderCustomCell(props.dataItem, column)}</p>
                      ) : column.clickable ? (
                        <p
                          className={"bz-cursor-pointer "}
                          onClick={() => onCellClick(props.dataItem)}
                        >
                          {" "}
                          {column.icon ? (
                            <i className={"fa fa-eye"}></i>
                          ) : (
                            props.dataItem[column.field]
                          )}
                        </p>
                      ) : column.enableHtml ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: props.dataItem[column.field],
                          }}
                        ></div>
                      ) : (
                        props.dataItem[column.field]
                      )}
                    </td>
                  )}
                  columnMenu={(props) => (
                    <CustomColumnMenu
                      {...props}
                      columns={stateGridColumns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                />
              )
          )}
        </Grid>
        <ExcelExport data={excelData} fileName={fileName} ref={_exporter}>
          {stateGridColumns
            .filter((ele: any) => ele.show)
            .map((el: any, id: any) => (
              <ExcelExportColumn field={el.field} title={el.title} />
            ))}
        </ExcelExport>
      </div>
    </div>
  );
};

export default BuzopsReportGrid;
