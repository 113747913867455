import { useEffect, useState } from "react";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import {
  IMemberPassportAccessProps,
  IPassportTechCredentialProps,
} from "../Utils/interfaces";
import { TenantService } from "services/tenant/index.service";
import { GetLocalStore } from "utils/storage";
import { useParams } from "react-router-dom";

const usePassportTechCredentials = ({
  UserMemberId,
  PrimaryUserMemberId,
}: IPassportTechCredentialProps) => {
  const simpleAccessService = new SimpleAccessService();
  const service = new TenantService();
  const { uuid, clientId } = useParams<any>();

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [memberPassportAccessData, setMemberPassportAccessData] = useState<
    IMemberPassportAccessProps[]
  >([]);

  const fetchUserSession = async () => {
    if(uuid){
      await service.authenticateUserByUuid(GetLocalStore("tenant"), uuid);
    }
  };

  const checkMemberPassportAccess = async () => {
    setLoading(true);
    const req = {
      UserMemberId: UserMemberId,
      PrimaryUserMemberId: PrimaryUserMemberId,
      IncludeSubMember: true,
    };
    const response = await simpleAccessService.checkMemberPassportAccess(req);
    setMemberPassportAccessData(
      response?.data?.map((item: any) => {
        return {
          FullName: item?.FullName,
          MemberType: item?.MemberType,
          CanDisableEditAndActivate: item?.CanDisableEditAndActivate,
          CanDisableRemoveAccess: item?.CanDisableRemoveAccess,
          HasMemberAccessGroup: item?.HasMemberAccessGroup,
          HasStaffAccessGroup: item?.HasStaffAccessGroup,
          Email: item?.Email,
          PassportTechCardType:!item?.AccessCard?.CardNumber ? null: item.AccessCard.PassportTechCardType,
          IsSuspended: item.AccessCard.IsSuspended,
          EntityId: item.AccessCard.EntityId,
          UserMemberId: item.UserMemberId,
          UserId: item.UserId,
          ExternalUserId: item.AccessCard.ExternalUserId,
          CredentialId: item.AccessCard.CredentialId,
          CardNumber: item?.AccessCard?.CardNumber,
          CardId: item?.AccessCard?.CardId,
          FacilityCode: item?.AccessCard?.FacilityCode,
        };
      }) || []
    );
    setLoading(false);
  };

  useEffect(() => {
    checkMemberPassportAccess();
    fetchUserSession();
  }, []);

  return {
    memberPassportAccessData,
    loading,
    processing,
    setProcessing,
    checkMemberPassportAccess,
  };
};
export default usePassportTechCredentials;
