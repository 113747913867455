import ReportsCommonFilters from "../../reportsCommonFilters";
import BuzopsReportGrid from "generic-components/BuzopsReportGrid";
import CloseReport from "../../Generic/CloseReport";
import useStaffPayout from "../Container/StaffPayout.hook";

const StaffPayout = (props: any) => {
  const uuid = props?.match?.params?.uuid;
  const staffPayout = useStaffPayout();

  return (
    <div>
      <ReportsCommonFilters
        {...props}
        history={props.history}
        location={props.location}
        dateValues={staffPayout.dateValues}
        showDate={false}
      />
      <div className="mt-4 customer-main-container">
        <CloseReport history={props.history} uuid={uuid} />
        <div className="d-flex customer-sub-header">
          <h2 className="customer-header">Staff Payouts</h2>
        </div>
        <div className="customer-grid-border">
          <BuzopsReportGrid
            fileName="staffPayouts.xlsx"
            result={staffPayout.pageInfo.data}
            dataState={{ ...staffPayout.pageInfo }}
            dataStateChange={(e: any, forExcel: boolean) =>
              staffPayout.fetchPayoutData(e, forExcel)
            }
            stateGridColumns={staffPayout.stateGridColumns}
            pageSize={10}
            setStateGridColumns={(data: any) =>
              staffPayout.setStateGridColumns(data)
            }
            onColumnsSubmit={staffPayout.onColumnsSubmit}
            excelData={staffPayout.excelData}
            setExcelData={() => staffPayout.setExcelData([])}
          />
        </div>
      </div>
    </div>
  );
};

export default StaffPayout;
