import React, { useEffect, useState } from "react";
import { ReportsService } from "services/reports/index.service";
import { staffGridColumns } from "../Utilities/constants";

const useStaffPayout = () => {
  const service = new ReportsService();
  const [pageInfo, setPageInfo] = useState({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });
  const [excelData, setExcelData] = useState([]);
  const [stateGridColumns, setStateGridColumns] =
    React.useState(staffGridColumns);

  const getSortBy = (field: string) => {
    if (field === "PayoutScheduleStatusDescription")
      return "PayoutScheduleStatus";
    else {
      return field || "TrainerName";
    }
  };

  const fetchPayoutData = async (e?: any, forExcel = false) => {
    const pageData = e?.dataState;
    const sort = e?.sort;

    const req = {
      pageSize: pageData?.take || 10,
      pageNumber: pageData?.skip / pageData?.take || 0,
      SortBy: getSortBy(sort?.[0]?.field),
      SortDirection: sort?.length > 0 ? sort[0]?.dir : "asc",
    };

    const getData = await service.getAllPayoutsHistory(req);
    if (forExcel) {
      req["pageNumber"] = pageInfo.total;
      setExcelData(getData.Item.Result);
    } else {
      setPageInfo((prev) => {
        prev.data = getData.Item.Result;
        prev.pageNumber = req.pageNumber;
        prev.total = getData.Item.TotalItems;
        if (pageData?.dataState?.take) {
          prev.skip = pageData?.dataState?.skip;
          prev.take = pageData?.dataState?.take;
        }
        return { ...prev };
      });
    }
  };

  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };

  const dateValues = (props: any) => {
    console.log(props);
  };

  useEffect(() => {
    fetchPayoutData();
  }, []);

  return {
    dateValues,
    pageInfo,
    fetchPayoutData,
    stateGridColumns,
    setStateGridColumns,
    onColumnsSubmit,
    excelData,
    setExcelData,
  };
};

export default useStaffPayout;
