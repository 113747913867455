import { Tooltip } from "@progress/kendo-react-tooltip";
export const BuzopsTooltip = (props: any) => {
    const filterElements = (element: HTMLElement) => {
      return true
    };
    if(props.content) {
      return (
        <Tooltip
          position={props.position? props.position: 'top'}
          parentTitle={true}
          filter={filterElements}
          anchorElement={props.anchor? props.anchor:"target"}
          className={props.toolTipClass}
          content={(() => {
            switch (typeof props.content) {
              case 'object':
                return (args) => {
                  return (
                    <>{props.content}</>
                  );
                }
              case 'function':
                return props.content
              case 'string':
                return null
              default:
                return null;
            }
          })()}
        >
          {props.html}
        </Tooltip>
      )
    }else{
      return (
        <Tooltip
          position={props.position? props.position: 'top'}
          parentTitle={true}
          filter={filterElements}
          className={props.toolTipClass}
          anchorElement="target"
        >
          {props.html}
        </Tooltip>
      )
    }
  };