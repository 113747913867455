import { RadioButton } from "@progress/kendo-react-inputs";
import ReportsCommonFilters from "components/reports/reportsCommonFilters";
import BuzopsReportGrid from "generic-components/BuzopsReportGrid";
import DetailedMRR from "./DetailedMRR";
import useMRR from "../Container/MRR.hook";

const MRR = (props: any) => {
  const uuid = props?.match?.params?.uuid;
  const mrr = useMRR();
  return (
    <>
      <ReportsCommonFilters
        {...props}
        history={props.history}
        location={props.location}
        setFromDateToDate={mrr.setFromDateToDate}
      />
      <div className="mt-4 subscribers-main-container">
        <div className="d-flex justify-content-end subscribers-close-icon">
          <li
            className="k-icon k-i-close"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
        <div className="subscribers-main-header">
          <h2 className="subscriber-header">MRR</h2>
          <BuzopsReportGrid
            fileName="MRR.xlsx"
            toggleFullscreen={() => {
              mrr.setUpdaterFullscreen(!mrr.updaterFullscreen);
              var style = document.createElement("style");
              style.innerHTML = `body::-webkit- {display: ${
                mrr.updaterFullscreen ? "block" : "none"
              };}`;
              document.head.appendChild(style);
            }}
            result={mrr.pageInfo.data}
            dataState={mrr.pageInfo}
            dataStateChange={mrr.fetchMRR}
            stateGridColumns={mrr.stateGridColumns}
            FullScreen={mrr.updaterFullscreen}
            pageSize={5}
            setStateGridColumns={(data: any) => mrr.setStateGridColumns(data)}
            onColumnsSubmit={mrr.onColumnsSubmit}
            onCellClick={mrr.onCellClick}
            excelData={mrr.excelData}
            setExcelData={() => mrr.setExcelData([])}
          />
        </div>
      </div>
      <br />
      {mrr.showDetailedMRR && (
        <>
          <RadioButton
            name="pickDataFormat"
            value="1"
            label="overall"
            onChange={() => mrr.setSelectedType(1)}
            checked={mrr.selectedType === 1}
          />
          <RadioButton
            name="pickDataFormat"
            value="2"
            label="client"
            onChange={() => mrr.setSelectedType(2)}
            checked={mrr.selectedType === 2}
          />
          <DetailedMRR
            hierarchyType={mrr.selectedType}
            key={mrr.selectedType + mrr.selectedRow?.Month}
            selectedRow={mrr.selectedRow}
          />
        </>
      )}
    </>
  );
};
export default MRR;
