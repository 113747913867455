import React from "react";
import "./index.scss";

const ErrorBoundaryComponent = ({ error, resetErrorBoundary }) => {
  console.log(error.code);
  console.log(error.message);
  console.log(error.name);
  console.log(error);
  console.log(process.env.REACT_APP_DOMAIN);
  console.log(window.location.host.split("."));
  let iframe = false;
  if (window.top !== window.self) {
    console.log("iframe");
    iframe = true;
  } else {
    console.log("Not an iframe");
  }
  const hostValues = window.location.host.split(".");
  const domainName = process.env.REACT_APP_DOMAIN;
  let tenantName = "";
  let url = ""
  if (
    hostValues.length === 3 &&
    hostValues[0] !== "www" &&
    hostValues[0] !== "ui"
  ) {
    tenantName = hostValues[0];
    url = `https://${tenantName}.${domainName}.com`;
  }

  if (error.name === "SecurityError") {
    return (
      <div className="">
        <div id="bz-notfound">
          <div className="bz-notfound">
            <div className="bz-notfound-404"></div>
            <h1>500</h1>
            <h2>Enable Cookies and Reload</h2>
            <p>{`Under Browser's Settings > Privacy > General Settings > Allow all cookies`}</p>
            {/* <p><a onClick={()=>resetErrorBoundary()}>{`Under Browser's Settings > Privacy > General Settings > Allow all cookies`}</a> */}
            {url && iframe && (
              <div>
                <h6>
                  {`Page not displaying? `}
                  <a className="btn btn-link" href={url} target="_blank">
                    Click here
                  </a>
                </h6>
                <h6>{`or `}</h6>
                <h6>{`copy and paste this URL`}</h6>
                <h6>{url}</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <div id="bz-notfound">
          <div className="bz-notfound">
            <div className="bz-notfound-404"></div>
            <h1>500</h1>
            <h2>Something went wrong</h2>
            <p>{`Please contact administrator`}</p>
            {/* <p><a onClick={()=>resetErrorBoundary()}>{`Under Browser's Settings > Privacy > General Settings > Allow all cookies`}</a> */}
            {url && iframe && (
              <div>
                <h6>
                  {`Page not displaying? `}
                  <a className="btn btn-link" href={url} target="_blank">
                    Click here
                  </a>
                </h6>
                <h6>{`or `}</h6>
                <h6>{`copy and paste this URL`}</h6>
                <h6>{url}</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default ErrorBoundaryComponent;
