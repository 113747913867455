import { useEffect, useState } from "react";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import {
  IMemberAccessProps,
  IOpenPathCredentialProps,
} from "../Utils/interfaces";
import { TenantService } from "services/tenant/index.service";
import { GetLocalStore } from "utils/storage";
import { useParams } from "react-router-dom";

const useOpenPathCredentials = ({
  UserMemberId,
  PrimaryUserMemberId,
}: IOpenPathCredentialProps) => {
  const simpleAccessService = new SimpleAccessService();
  const service = new TenantService();
  const { uuid, clientId } = useParams<any>();

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [memberAccessData, setMemberAccessData] = useState<
    IMemberAccessProps[]
  >([]);
  const [isOnline, setIsOnline] = useState(false);

  const fetchUserSession = async () => {
    await service.authenticateUserByUuid(GetLocalStore("tenant"), uuid);
  };

  const checkMemberHardwareAccess = async () => {
    setLoading(true);
    const res = await simpleAccessService.checkIfHardwareIsOnline();
    setIsOnline(res)
    const req = {
      UserMemberId: UserMemberId,
      PrimaryUserMemberId: PrimaryUserMemberId,
      IncludeSubMember: true,
    };
    const response = await simpleAccessService.checkMemberHardwareAccess(req);
    setMemberAccessData(
      response.data.map((item: any) => {
        return {
          FullName: item?.FullName,
          MemberType: item?.MemberType,
          OpenpathCredentialType: item.AccessCard.OpenpathCredentialType,
          IsSuspended: item.AccessCard.IsSuspended,
          OpenpathCredentialEndDate: item.AccessCard.OpenpathCredentialEndDate,
          OpenpathCredentialSetupEmailSentOn:
            item.AccessCard.OpenpathCredentialSetupEmailSentOn,
          OpenpathCredentialStartDate:
            item.AccessCard.OpenpathCredentialStartDate,
          IsOpenpathCredentialSetupComplete:
            item.AccessCard.IsOpenpathCredentialSetupComplete,
          UserMemberId: item.UserMemberId,
          UserId: item.UserId,
          ExternalUserId: item.AccessCard.ExternalUserId,
          CredentialId: item.AccessCard.CredentialId,
          CanDisableEditAndActivate: item?.CanDisableEditAndActivate,
          CanDisableRemoveAccess: item?.CanDisableRemoveAccess,
          CardNumber: item?.AccessCard?.CardNumber,
          CardId: item?.AccessCard?.CardId,
          FacilityCode: item?.AccessCard?.FacilityCode,
          UserGroups: item?.AccessCard?.UserGroups,
          HasMemberAccessGroup: item?.HasMemberAccessGroup,
          HasStaffAccessGroup: item?.HasStaffAccessGroup,
          Email: item?.Email
        };
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    checkMemberHardwareAccess();
    fetchUserSession();
  }, []);

  return {
    memberAccessData,
    loading,
    processing,
    setProcessing,
    checkMemberHardwareAccess,
    isOnline
  };
};
export default useOpenPathCredentials;
