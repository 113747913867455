/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { getFullImageUrl } from "utils";

export const trainerValueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: any,
  selectedValue: any
) => {
  let children = [<></>];
  if (selectedValue) {
    children = [
      <div className={"row align-items-center w-100"}>
        <div className={"col-3 text-center "}>
          {selectedValue?.Photo ? (
            <img
              style={{ borderRadius: "20px" }}
              width={38}
              src={getFullImageUrl(selectedValue?.Photo)}
            />
          ) : (
            <img width={38} src="/images/user.png" />
          )}
        </div>
        <div className={"col-8 pl-2"}>
          <span key={1} className={""}>
            <h6 className="mb-0">{selectedValue?.FullName}</h6>
            <span>{selectedValue?.Email}</span>
          </span>
        </div>
      </div>,
    ];
  }

  return React.cloneElement(element, { ...element.props }, children);
};

export const trainerItemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
  selectedValue: any
) => {
  const itemChildren = (
    <div className={"row align-items-center w-100"}>
      <div className={"col-3 text-center "}>
        {itemProps.dataItem.Photo ? (
          <img
            style={{ borderRadius: "20px" }}
            width={38}
            src={getFullImageUrl(itemProps.dataItem.Photo)}
          />
        ) : (
          <img width={38} src="/images/user.png" />
        )}
      </div>
      <div className={"col-8 pl-2"}>
        <span
          style={
            selectedValue?.AssociateId === itemProps.dataItem.AssociateId
              ? { color: "#008db9" }
              : {}
          }
        >
          <b>{itemProps.dataItem.FullName}</b>
          <br />
          <span>{itemProps.dataItem.Email}</span>
        </span>
      </div>
    </div>
  );

  return React.cloneElement(li, li.props, itemChildren);
};
