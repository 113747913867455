import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Notification as Notify } from "@progress/kendo-react-notification";
import moment from "moment";
import React from "react";
const PlanAddonFreeze = (props: any) => {
  return (
    <>
      <div className="bz-content-text-row">
        <div className="bz-content-title text-right"></div>
        <span className="bz-colon text-center"></span>
        <div className="bz-content-body">
          <div className="bz-plan-note bz-planFreeze-notification">
            <p className="mb-0">
            <Notify key="info" type={{ style: "warning" }}>
            {`Add-on will freeze along with Membership`}
          </Notify>
            </p>
          </div>
        </div>
      </div>

      {props?.freezeDetails?.Result?.filter((ii: any) => ii?.ServiceType !== "Plan").map((dataItem: any) => {
        const {
          EntityType,
          EntityName,
          ImmediateFreeze,
          ImmediateFreezeStartDate,
          NormalFreezeStartDate,
          MaximumBillingCycleOnFreeze,
          Notification,
          EntityId,
          Occurrence,
          FrequencyDescription,
          RepeatFrequency,
          Frequency
        } = dataItem;
        return (
          <>

            <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Add-on Name</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{EntityName}</div>
              </div>
              {EntityType === 4 && (
                <>
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right">Repeat Every</div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        {`${Frequency === 1 ? "NA" : RepeatFrequency} - ${FrequencyDescription}`}
                      </div>
                    </div>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">End</div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {Occurrence > 0
                        ? `After ${Occurrence} Occurrences`
                        : "As Never"}
                    </div>
                  </div>
                </>
              )}
              {Notification && (
                <div className="bz-content-text-row bz-notify-row">
                  <div className="bz-content-title text-right"></div>
                  <span className="bz-colon text-center"></span>
                  <div className="bz-content-body">

                    <div className="bz-plan-note">
                      <p className="mb-0">
                        <Notify key="info" type={{ style: "info" }}>
                          {Notification}
                        </Notify>
                      </p>

                    </div>
                  </div>
                </div>

              )}

              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Freeze Start Date</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {ImmediateFreeze
                    ? moment(ImmediateFreezeStartDate).format("L")
                    : moment(NormalFreezeStartDate).format("L")}
                </div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Maximum Months on Freeze</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <NumericTextBox
                    id={`MaximumBillingCycleOnFreeze`}
                    name={`MaximumBillingCycleOnFreeze`}
                    min={0}
                    disabled={true}
                    className="k-dropdown-width-100"
                    value={MaximumBillingCycleOnFreeze}
                    onChange={(e) =>
                      props?.handleChange(
                        e.target.value,
                        "MaximumBillingCycleOnFreeze",
                        EntityId
                      )
                    }
                  />
                </div>
              </div>




            </div>


            <hr className="bz-addons-hr" />




            {/* <div className="card card-fluid bz-sub-card mb-2 bz-card bz-cancel-package">
              <div className="card-header py-4 bz-no-background">
                <div className="bz-row no-gutters">
                  <div className="col-6 col-md-6 p-0 mb-sm-0">
                    <span className="h6 bz-border-Inactive-sm bz--left-border pl-2">
                      <span className="bz-card-heading text-capitalize">
                        <p
                          className={
                            "card-subtitle d-flex align-items-center mb-2 text-muted"
                          }
                        >
                          {MemberType === 0 ? (
                            <img
                              src={PrimaryMember}
                              className="bz-member-icon"
                            />
                          ) : (
                            <img src={SubMember} className="bz-member-icon" />
                          )}
                          {`${LastName} ${FirstName}`}
                        </p>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="card-body p-0 position-relative">
                <div className="bz-row no-gutters">
                  <div className="col">
                    <div className="p-4">
                      <h6 className="card-title bz-fw-600 bz-article">
                        {EntityName} <small>({ServiceType})</small>
                      </h6>
                      <div className="">
                        <div className="bz-row no-gutters mb-3">
                          {EntityType === 4 && (
                            <>
                              {Frequency !== 1 && (
                                <div className="col-12 col-lg-12">
                                  <p className="row">
                                    <span className="col bz-article">
                                      Repeat Every
                                    </span>
                                    :{" "}
                                    <span className="col">
                                      {`${RepeatFrequency} - ${FrequencyDescription}`}
                                    </span>
                                  </p>
                                </div>
                              )}
                              <div className="col-12 col-lg-12">
                                <p className="row">
                                  <span className="col bz-article">End</span>:{" "}
                                  <span className="col">
                                    {Occurrence > 0
                                      ? `After ${Occurrence} Occurrences`
                                      : "as never"}
                                  </span>
                                </p>
                              </div>
                            </>
                          )}
                          {Notification && (<div className="bz-plan-note">
                            <p className="mb-0">
                              <Notify key="info" type={{ style: "info" }}>
                                {Notification}
                              </Notify>
                            </p>
                          </div>)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-5 bzc-border-left position-relative">
                    <div className="p-4 h-100">
                      <div className={"bz-row no-gutters mb-4 pb-5"}>
                        <div className="form-group col-12 bz-payment-profile-options">
                          <div className="bzc-provider-name">
                            <label className="mb-2 d-inline-block k-label">
                              Freeze Start Date
                            </label>
                            <div className="bzc-time-sec">
                              <span>
                                {ImmediateFreeze
                                  ? moment(ImmediateFreezeStartDate).format("L")
                                  : moment(NormalFreezeStartDate).format("L")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-12 bz-payment-profile-options">
                          <div className="bzc-provider-name">
                            <label className="mb-2 d-inline-block k-label">
                              Maximum Months on Freeze
                            </label>
                            <div className="bzc-time-sec">
                              <NumericTextBox
                                id={`MaximumBillingCycleOnFreeze`}
                                name={`MaximumBillingCycleOnFreeze`}
                                min={0}
                                disabled={true}
                                className="k-dropdown-width-100"
                                value={MaximumBillingCycleOnFreeze}
                                onChange={(e) =>
                                  props?.handleChange(
                                    e.target.value,
                                    "MaximumBillingCycleOnFreeze",
                                    EntityId
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        );
      })}
    </>
  );
};

export default PlanAddonFreeze;
