import { Notification as Notify } from "@progress/kendo-react-notification";
import React from "react";
import { CurrencyFormat } from "utils";

const AddonsInActivationOverview = (props: any) => {
  const {
    ServiceName,
    Charge,
    FrequencyDescription,
    Continue,
    ProratedAmount,
    FailedTransactionAmount,
    WaivedFailedTransactionAmount,
    FailedTransactionAmountToCollect,
    DownPayment,
  } = props?.addonItem;
  return (
    <>
      <div className="bz-mt-2rem bz-mb-2_5rem">
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Add-on Name</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{ServiceName}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Amount</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(Charge)}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Frequency</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{FrequencyDescription}</div>
        </div>
        {Continue && (
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Prorated Amount</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(ProratedAmount)}
            </div>
          </div>
        )}
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Total Failed Transaction Amount
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {CurrencyFormat(FailedTransactionAmount)}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Waived Failed Transaction Amount
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {CurrencyFormat(WaivedFailedTransactionAmount)}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Failed Transaction Amount To Collect
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {CurrencyFormat(FailedTransactionAmountToCollect)}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">
            Amount to Collect for Add-On
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(DownPayment)}</div>
        </div>
        {!Continue && (
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              {" "}
              <div className="bz-plan-note">
                <p className="mb-0">
                  <Notify key="warning" type={{ style: "warning" }}>
                    {"This service will cancel today"}
                  </Notify>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <hr />
    </>
  );
};

export default AddonsInActivationOverview;
