import * as React from "react";
import { GridColumnMenuFilter } from "@progress/kendo-react-grid";
export const SalesCustomColumnMenu = (props: any) => {
  const [columnsExpanded, setColumnsExpanded] = React.useState(false);
  const [filterExpanded, setFilterExpanded] = React.useState(false);

  const onFilterExpandChange = (value: any, event: any) => {
    setFilterExpanded(value);
    setColumnsExpanded(value ? false : columnsExpanded);
  };
  return (
    <div>
      <GridColumnMenuFilter
        {...props}
        onExpandChange={onFilterExpandChange}
        expanded={true}
      />
    </div>
  );
};
