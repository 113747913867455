import { PageChangeEvent } from "@progress/kendo-react-data-tools";
import useDebounce from "components/customHooks/useDebounce";
import { useState, useEffect } from "react";
import { ClassService } from "services/class/index.service";
import { CreditsErrorMessage, UsermemberStatusEnum } from "utils/form-utils";

const useChargeBack = (props: any) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    Collect: false,
    Waive: false,
    MemberStatus: false,
  });
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const [usermemberId, setUsermemberid] = useState<any>(null);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [loading, setLoading] = useState({
    screen: false,
    allTransaction: true,
    updateTransaction: false,
    getTransaction: false,
    waive: false,
    collect: false,
    MemberStatus: false,
  });
  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });
  const { skip, take } = page;
  const [showDialog, setShowDialog] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [transactionItems, setTransactionItems] = useState<any>(null);
  const [showTransactionItems, setShowTransactionItems] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [insertItem, setInsertItem] = useState("");
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [searchText, setSearchText] = useState<any>(null);
  const debouncedValue = useDebounce(searchText, 500);

  const [selectServiceType, setSelectServiceType] = useState({
    id: 1,
    text: "All",
  });
  const [data, setData] = useState<any>([]);
  const [ChargebackTransactionsSummary, setChargebackTransactionsSummary] =
    useState([]);
  const [TotalAmount, setTotalAmount] = useState<any>(0);
  const service = new ClassService();

  useEffect(() => {
    fetchAllApis();
  }, [debouncedValue]);

  const handleSearchText = (val: any) => {
    setSearchText(val);
    setPage({
      skip: 0,
      take: 10,
    });
  };

  const fetchAllApis = async () => {
    setLoading({
      ...loading,
      allTransaction: true,
    });
    if (debouncedValue === null) {
      await fetchUserSession();
    }
    await fetchChargeBackTransactions(debouncedValue);
    setLoading({
      ...loading,
      allTransaction: false,
    });
  };

  const fetchChargeBackApi = async () => {
    setLoading({
      ...loading,
      allTransaction: true,
    });
    await fetchChargeBackTransactions(debouncedValue);
    setLoading({
      ...loading,
      allTransaction: false,
    });
  };

  const handlePageChange = async (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
    setLoading({
      ...loading,
      allTransaction: true,
    });
    await fetchChargeBackTransactions(debouncedValue, e.skip, e.take);
    setLoading({
      ...loading,
      allTransaction: false,
    });
  };
  const handleContinue = async () => {
    if (transactionId === "") {
      const errorMsg = "Please provide transaction id";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    let mode;
    if (insertItem === "chargeback") {
      mode = 10;
    } else if (insertItem === "return") {
      mode = 7;
    }
    const req = {
      TransactionId: transactionId,
      PaymentMode: mode,
    };
    setLoading({
      ...loading,
      getTransaction: true,
    });
    const res = await service.GetByTransactionId(req);
    setLoading({
      ...loading,
      getTransaction: false,
    });
    if (res?.ResponseCode === 100) {
      if (res?.Item?.Result === 0) {
        props?.handleNotificationMessage("No Transactions Available", "error");
      } else {
        setTransactionItems(res?.Item);
        setShowTransactionItems(true);
      }
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in Transaction Id";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleCancel = () => {
    setTransactionItems(null);
    setShowTransactionItems(false);
    setTransactionId("");
  };
  const handleChargeBack = async () => {
    if (transactionItems?.Result?.length === 0) {
      props?.handleNotificationMessage("No Transactions Available", "error");
      return;
    }
    const req = transactionItems;
    setLoading({
      ...loading,
      updateTransaction: true,
    });
    const res = await service.UpdateTransactionAsChargeback(req);
    setLoading({
      ...loading,
      updateTransaction: false,
    });
    if (res?.ResponseCode === 100) {
      const successMsg = res?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      setTransactionItems(null);
      setShowTransactionItems(false);
      setTransactionId("");
      setShowDialog(false);
      setInsertItem("");
      await fetchChargeBackApi();
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in Updating";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const fetchChargeBackTransactions = async (
    debouncedValue = null,
    Skip = skip,
    Take = take,
    paymentMode=selectServiceType
  ) => {
    let req: any = { PageNumber: Skip / Take, PageSize: Take };
    if (debouncedValue) {
      req = {
        ...req,
        SearchKeyword: debouncedValue,
      };
    }
    if(paymentMode?.id > 1){
      req = {
        ...req,
        PaymentMode: paymentMode?.id,
      };
    }
    if (getClientId()) {
      req = { ...req, UserMemberId: getClientId() };
    }
    const response = await service.GetChargeBackTransactions(req);
    const res = response?.Item;
    if (res) {
      let rec = [];
      if (res?.ChargebackTransactions?.length > 0) {
        rec = groupBy(res?.ChargebackTransactions, "ParentUserMemberId");
      }
      setData(rec);
      if (res?.ChargebackTransactionsSummary?.length === 0 && props?.clientId) {
        props?.onSuccessOfPayment();
        return;
      }
      setChargebackTransactionsSummary(
        res?.ChargebackTransactionsSummary || []
      );
      setTotalAmount(res?.TotalAmount || 0);
      setTotalItems(response?.TotalItems);
    }
  };

  const handleSelectServiceType = async (val: any) => {
    setPage({
      skip: 0,
      take: 10,
    });
    setSelectServiceType(val);
    setLoading({
      ...loading,
      allTransaction: true,
    });
    await fetchChargeBackTransactions(debouncedValue, 0, 10,val);
    setLoading({
      ...loading,
      allTransaction: false,
    });
  };

  const fetchUserSession = async () => {
    if (props.match?.params?.uuid) {
      await service.getTenantDetails(props.match?.params?.uuid);
    }
  };
  const groupBy = (array: any, key: string) => {
    let newarr: any = [];
    array.forEach((item: any) => {
      let index = -1;
      if (newarr.length > 0) {
        index = newarr?.findIndex(
          (val1: any) => val1.ParentUserMemberId === item?.ParentUserMemberId
        );
      }
      if (index === -1) {
        const obj = {
          ParentName: item?.ParentName,
          ParentMemberNumber: item?.ParentMemberNumber,
          TotalAmount: item?.TotalAmount,
          ParentUserMemberId: item?.ParentUserMemberId,
          ParentPhoto: item?.ParentPhoto,
          selectedArrow: true,
          MemberStatus: item?.MemberStatus,
          records: [item],
        };
        newarr.push(obj);
      } else {
        const total = item?.TotalAmount + newarr[index]?.TotalAmount;
        newarr[index].TotalAmount = total || 0;
        newarr[index]?.records.push(item);
      }
    });
    return newarr;
  };

  const handleChange = (val: any, dataItem: any, name: string) => {
    let value = val;
    if (name === "MemberStatus") {
      value = val ? 8 : 4;
    }
    const changedItem = { ...dataItem, [name]: value };
    const updatedClients = data?.map((item: any) => {
      if (item?.ParentUserMemberId === dataItem?.ParentUserMemberId) {
        return {
          ...item,
          [name]: value,
        };
      } else {
        return { ...item };
      }
    });
    setData(updatedClients);
    if (name === "MemberStatus") {
      setShowConfirmDialog({
        ...showConfirmDialog,
        MemberStatus: true,
      });
      setSelectedItem(changedItem);
    }
  };
  const handleChangeSavedCard = (val: any, userId: any, index: number) => {
    const dataItem=data?.find((item:any)=> item?.ParentUserMemberId === userId)
    if(val?.CardTypeId === 8 && dataItem?.TotalAmount > val?.Credit){
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    const updatedClients = data?.map((item: any) => {
      if (item?.ParentUserMemberId === userId) {
        const recorddata = item.records?.map((i: any, ind: number) => {
          if (ind === index) {
            return {
              ...i,
              PaymentGatewayPaymentProfileID: val?.PaymentGatewayPaymentProfileId,
              PaymentProfileID: val?.PaymentProfileId,
              CardTypeId: val?.CardTypeId,
              BankAccountType: val?.BankAccountType,
              CardDescriptionWithoutExpiryDate:
                val?.CardDescriptionWithoutExpiryDate,
              IsCreditCard: val?.IsCreditCard,
              MaskedCCNumber: val?.MaskedCCNumber,
              CustomerProfileID: val?.CustomerProfileId,
              Credit: val?.Credit,
            };
          } else {
            return { ...i };
          }
        });
        return { ...item, records: recorddata };
      } else {
        return { ...item };
      }
    });
    setData(updatedClients);
  };

  const handleConfirmPopup = (dataItem: any, name: string) => {
    console.log(dataItem,'dataItem')
    if (
      (!dataItem?.PaymentGatewayPaymentProfileID ||
      dataItem?.PaymentProfiles?.length === 0) && name !=="Waive"
    ) {
      const errorMsg = "Please select Payment Profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    setShowConfirmDialog({
      ...showConfirmDialog,
      [name]: true,
    });
    setSelectedItem(dataItem);
  };

  const handleAllowService = async (dataItem: any) => {
    const req = {
      UserMemberId: dataItem.ParentUserMemberId,
      Status: dataItem?.MemberStatus,
    };
    setLoading({
      ...loading,
      MemberStatus: true,
    });
    const res = await service.AllowServicesForClient(req);
    setLoading({
      ...loading,
      MemberStatus: false,
    });
    if (res?.ResponseCode === 100) {
      setShowConfirmDialog({
        Collect: false,
        Waive: false,
        MemberStatus: false,
      });
      setSelectedItem(null);
      const successMsg = res?.SuccessMessage || "Successfully Modified";
      props?.handleNotificationMessage(successMsg, "success");

      await fetchChargeBackApi();
    } else {
      const errorMsg =
        res?.ErrorMessages[0] || "Error in Updating Allow Services";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleCollect = async (dataItem: any) => {
    const req = dataItem;
    setLoading({
      ...loading,
      collect: true,
    });
    const res = await service.PayChargeBack(req);
    setLoading({
      ...loading,
      collect: false,
    });
    if (res?.ResponseCode === 100) {
      setShowConfirmDialog({
        Collect: false,
        Waive: false,
        MemberStatus: false,
      });
      setSelectedItem(null);
      const successMsg =
        res?.SuccessMessage || "Payment successfully collected.";
      props?.handleNotificationMessage(successMsg, "success");
      await fetchChargeBackApi();
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in Updating ChargeBack";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleWaive = async (dataItem: any) => {
    const req = dataItem;
    setLoading({
      ...loading,
      waive: true,
    });
    const res = await service.WaiveChargeBack(req);
    setLoading({
      ...loading,
      waive: false,
    });
    if (res?.ResponseCode === 100) {
      setShowConfirmDialog({
        Collect: false,
        Waive: false,
        MemberStatus: false,
      });
      setSelectedItem(null);
      const successMsg = res?.SuccessMessage || "Successfully Waived";
      props?.handleNotificationMessage(successMsg, "success");
      await fetchChargeBackApi();
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in Updating ChargeBack";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleInsert = (e: any) => {
    setShowDialog(!showDialog);
    setInsertItem(e.item.key);
  };

  const onCardOrBankAdd = (e: any, memberId: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
    setUsermemberid(memberId);
  };
  const handleChangeTransactionId = (val: any) => {
    setTransactionId(val);
  };

  const handleCloseModal = () => {
    setTransactionItems(null);
    setShowTransactionItems(false);
    setTransactionId("");
    setShowDialog(false);
    setInsertItem("");
  };

  const handleCloseConfirmModal = () => {
    if (showConfirmDialog?.MemberStatus) {
      const updatedClients = data?.map((item: any) => {
        if (item?.ParentUserMemberId === selectedItem?.ParentUserMemberId) {
          return {
            ...item,
            MemberStatus:
              selectedItem?.MemberStatus ===
              UsermemberStatusEnum["AllowService"]
                ? UsermemberStatusEnum["ActionRequired"]
                : UsermemberStatusEnum["AllowService"],
          };
        } else {
          return { ...item };
        }
      });
      setData(updatedClients);
    }
    setShowConfirmDialog({
      Collect: false,
      Waive: false,
      MemberStatus: false,
    });
    setSelectedItem(null);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
    setUsermemberid(null);
  };
  const handleSuccessClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
    setUsermemberid(null);
    await fetchChargeBackApi();
  };
  const getClientId = () => {
    return props?.match?.params?.clientId || props?.clientId;
  };
  return {
    loading,
    showTransactionItems,
    setSelectedItem,
    handleAllowService,
    handleCollect,
    handleWaive,
    getClientId,
    insertItem,
    handleCloseModal,
    handleCloseConfirmModal,
    showConfirmDialog,
    handleCancel,
    transactionId,
    handleChargeBack,
    selectedItem,
    transactionItems,
    handleChangeTransactionId,
    handleContinue,
    addNewItem,
    handleClosePaymethodDialog,
    searchText,
    usermemberId,
    handleSearchText,
    handleSuccessClosePaymethodDialog,
    data,
    handleInsert,
    selectServiceType,
    handleSelectServiceType,
    handleChange,
    handleConfirmPopup,
    showPaymentDialog,
    onCardOrBankAdd,
    TotalAmount,
    showDialog,
    handleChangeSavedCard,
    ChargebackTransactionsSummary,
    page,
    handlePageChange,
    totalItems,
  };
};

export default useChargeBack;
