import {
  Grid,
  GridColumn as Column
} from "@progress/kendo-react-grid";
import { Card } from "@progress/kendo-react-layout";
import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { CurrencyFormat } from "utils";

const Transactions = (props: any) => {
  const [transactions, setTransactions] = React.useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    fetchPaymentTransactions();
  }, []);

  const fetchPaymentTransactions = async () => {
    setloading(true);
    const EntityId = props?.EntityId;
    const req = {
      PageNumber: 0,
      PageSize: 30,
      SortBy: "TransactionDate",
      SortDirection: "desc",
      Where: {
        EntityId: EntityId,
        FilterType: 4,
        UserMemberId: props?.UserMemberId,
      },
    };
    const transactiondata = new TenantService();
    const result = await transactiondata.getTransactions(req);
    setloading(false);

    if (result) {
      setTransactions(result.Items || []);
    }
  };

  const amountCell = (props: any) => {
    return (
      <td className={"capitalize"}>
        {CurrencyFormat(props.dataItem.AmountPaid.toFixed(2))}
      </td>
    );
  };

  return (
    <>
      {loading ? (
        <BuzopsLoader type={"list"} />
      ) : (
        <>
          {transactions.length === 0 ? (
            <>
              <div
                className={
                  "bz-norecords-available"
                }
              >
                <span>No Transactions Available</span>
              </div>
            </>
          ) : (
            <>
              <Card className={"bzo-services-container border-0 px-2 pt-0"}>
                <Grid
                  data={transactions}
                  className="bzc-grid bzc-specificDayGrid bz-payout-grid bz-packages-transaction-grid"
                >
                  <Column field="PaymentMadeForDescription" title="Name" />
                  <Column field="TransactionDate" title="Date" />
                  <Column field="AmountPaid" title="Total" cell={amountCell} />
                  <Column field="PaymentModeDescription" title="Status" />
                  <Column field="CardDescription" title="Payment Method" />
                  {/* <Column cell={CommandCell} width="200px" /> */}
                </Grid>
              </Card>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Transactions;
