export const OnsiteLogColumns = [
  {
    title: "Name",
    field: "FullName",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Home Club",
    field: "ClubName",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Site",
    field: "SiteName",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Access Point",
    field: "AccessPoint",
    show: true,
    filter: "numeric",
    width: "150px",
  },

  {
    title: "Occured",
    field: "Occured",
    show: true,
    filter: "text",
    width: "250px",
  },
];

export const ServiceLogColumns = [
  {
    title: "Name",
    field: "FullName",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Service Type",
    field: "ServiceType",
    show: true,
    filter: "text",
    width: "250px",
  },
  {
    title: "Service Name",
    field: "ServiceName",
    show: true,
    filter: "text",
    width: "150px",
  },

  {
    title: "Occured",
    field: "Occured",
    show: true,
    filter: "text",
    width: "150px",
  },
  {
    title: "Status",
    field: "StatusDescription",
    show: true,
    filter: "numeric",
    width: "150px",
  },
];
