import { SubscriberStatusTypes } from "../Utilities/constants";

interface ISubscriberStatusTypeCardProps {
  dataType: SubscriberStatusTypes;
  count: any;
  statusType: SubscriberStatusTypes;
  handleChange: any;
  description: string;
  statusName: string;
}

const SubscriberStatusTypeCard: React.FunctionComponent<
  ISubscriberStatusTypeCardProps
> = (props) => {
  console.log(props.count);
  return (
    <div
      className={
        props.dataType === props.statusType
          ? "subscribers-box clicked"
          : "subscribers-box"
      }
      onClick={() => {
        props?.handleChange(props.statusType);
      }}
    >
      <div className="subsctibers-box-content">
        <p>{props.description}</p>
        <h2>{props.count[props.statusType.toString()]}</h2>
      </div>
      <span>{props.statusName}</span>
    </div>
  );
};

export default SubscriberStatusTypeCard;
