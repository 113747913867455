import "./index.scss";

const NotFound = () => {
  return (
    <div className="">
      <div id="bz-notfound">
        <div className="bz-notfound">
          <div className="bz-notfound-404"></div>
          <h1>404</h1>
          <h2>Oops! Page Not Found</h2>
          <p>
            Sorry but the page you are looking for does not exist, have been
            removed. name changed or is temporarily unavailable
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
