import {
  Grid,
  GridColumn as Column,
  GridItemChangeEvent,
} from "@progress/kendo-react-grid";
import moment from "moment";
import { Switch,RadioGroup } from "@progress/kendo-react-inputs";
import { CurrencyFormat } from "utils";

const dateCell = (props: any) => {
  const TransactionDate = moment(props?.dataItem?.TransactionDate).format("L");
  return <td>{TransactionDate}</td>;
};
const amountCell = (props: any) => {
  let amt=props?.dataItem?.Amount
  if(props?.dataItem?.AmountPaid){
    amt=props?.dataItem?.AmountPaid
  }
  const AmountPaid = CurrencyFormat(amt);
  return <td>{AmountPaid}</td>;
};

const modeSelect = (props: any) => {
  const handleChange = (e: any) => {
    if (props.onChange) {
      let selectedVal = e.value;
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: selectedVal,
      });
    }
  };

  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  const data = [
    {
      label:"Pay",
      value:false
    },
    {
      label:"Waive",
      value:true
    }
  ]
  return (
    <td>
      <>
      <RadioGroup
        data={data}
        layout="horizontal"
        onChange={handleChange}
        value={dataItem[field] || false}
      />
        {/* <Switch
          name="selectedGroupService"
          onChange={handleChange}
          checked={dataValue}
        />
        &nbsp;
        <label className={"switch-label"}>
          {dataValue ? " Waive" : " Pay"}
        </label> */}
      </>
    </td>
  );
};
const itemChange = (event: GridItemChangeEvent, props: any) => {
  props?.selectionChange(event, props?.EntityId);
};

const FailedTransactionsGrid = (props: any) => {
  const TransactionData = props?.details;
  return (
    <div className="bzc-failedTransaction-container">
      <h5 className="mb-3 pt-3 bz_fs_1_5">Failed Transactions</h5>
      <div className="bz-mt-2rem bz-mb-2_5rem">
        <div className="">
          <Grid
            data={TransactionData}
            className="bzc-grid bzc-failedTransactionGrid"
            onItemChange={(e: GridItemChangeEvent) => itemChange(e, props)}
          >
            <Column field="TransactionId" title="Transaction Id" width={250} />
            <Column
              field="TransactionDate"
              title="Transaction Date"
              cell={dateCell}
            />
            <Column field="AmountPaid" title="Amount" cell={amountCell} />
            <Column
              field="CanWaive"
              cell={modeSelect}
              title="Action"
            />
          </Grid>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default FailedTransactionsGrid;
