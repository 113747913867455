import { CommonServiceUtils } from "services/common-service-utils";
export class ManageCustomFieldsService extends CommonServiceUtils {
  async getCustomFields(): Promise<any> {
    const getCustomFields: any = await this.getData(
      this.apiUrlCreator(`site/GetCustomFields`)
    );
    if (getCustomFields?.ResponseCode === 100) {
      return getCustomFields?.Items;
    } else {
      return [];
    }
  }

  async saveCustomFields(req: any): Promise<any> {
    const saveCustomFields: any = await this.postData(
      this.apiUrlCreator(`site/SaveCustomFields`),
      req
    );
    return saveCustomFields;
  }
  async saveMemberPlanCustomFields(req: any): Promise<any> {
    const saveMemberPlanCustomFields: any = await this.postData(
      this.apiUrlCreator(`MemberPlan/SaveCustomFields`),
      req
    );
    return saveMemberPlanCustomFields;
  }
}
