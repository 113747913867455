import moment from "moment";
import { useEffect, useState } from "react";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import { AccessCardType } from "utils/constants";
import {
  OpenPathCredentialStatusType,
  OpenpathCrendentialOptions,
  OpenpathCrendentialType,
} from "../Utils/constants";
import { IMemberAccessProps } from "../Utils/interfaces";
import bzImages from "../../../Images";

const useMemberCredentials = (props: IMemberAccessProps) => {
  const simpleAccessService = new SimpleAccessService();
  const [showHistory, setShowHistory] = useState(false);
  const [auditLogs, setAuditLogs] = useState([]);
  const [selectedCredentialType, setSelectedCredentialType] = useState<any>({
    id: -1,
    text: "Select credential type",
  });
  const [cardNumber, setCardNumber] = useState<string>("");
  const [facilityCode, setFacilityCode] = useState<number>(-1);
  const [error, setError] = useState<string>("");
  const [groupsError, setGroupsError] = useState<string>("");
  const [facilityCodeError, setFacilityCodeError] = useState<string>("");
  const [groups, setGroups] = useState<any[]>([]);
  const [isGroupChanged, setIsGroupChanged] = useState(false);

  const createOpenPathMobileCredential = async () => {
    const CardMIFAREPattern = /^[0-9A-F]{6,30}$/;
    const CardDESFirePattern = /^[0-9A-F]{14}$/;

    if (groups.length === 0) {
      setGroupsError("Please select groups");
      return;
    }

    if (
      selectedCredentialType?.id === OpenpathCrendentialType.CardWeigand &&
      (facilityCode < 0 || facilityCode > 255 || facilityCode === -1)
    ) {
      setFacilityCodeError("Please enter valid Facility Code");
      return;
    }
    if (
      selectedCredentialType?.id === OpenpathCrendentialType.CardWeigand &&
      (parseInt(cardNumber) < 0 ||
        parseInt(cardNumber) > 65535 ||
        cardNumber === "")
    ) {
      setError("Please enter valid Card Wiegand Id");
      return;
    }

    if (
      selectedCredentialType?.id === OpenpathCrendentialType.CardMIFARE &&
      CardMIFAREPattern.test(cardNumber) === false
    ) {
      setError("Please enter valid card number");
      return;
    }

    if (
      selectedCredentialType?.id === OpenpathCrendentialType.CardDESFire &&
      CardDESFirePattern.test(cardNumber) === false
    ) {
      setError("Please enter valid card number");
      return;
    }
    setError("");

    props.setProcessing(true);
    const payload = {
      UserId: props.UserId,
      EntityId: props.UserMemberId,
      AccessCardType: AccessCardType.OpenPath,
      OpenpathCredentialType: selectedCredentialType.id,

      ...((selectedCredentialType?.id === OpenpathCrendentialType.CardDESFire ||
        selectedCredentialType?.id === OpenpathCrendentialType.CardMIFARE) && {
        CardNumber: cardNumber,
      }),
      ...(selectedCredentialType?.id ===
        OpenpathCrendentialType.CardWeigand && {
        CardId: cardNumber,
        FacilityCode: facilityCode,
      }),

      UserGroups: groups.map((group: any) => ({ GroupId: group.id })),
    };

    const response = await simpleAccessService.createOpenPathMobileCredential(
      payload
    );
    if (response.success) {
      if (selectedCredentialType.id === OpenpathCrendentialType.Mobile) {
        props.handleNotification(
          "Access granted and email sent to set up Mobile credentials",
          "success"
        );
      } else {
        props.handleNotification("Access Granted", "success");
      }
      await props.checkMemberHardwareAccess();
    } else {
      props.handleNotification(response.msg || "Error", "error");
    }
    props.setProcessing(false);
  };

  const removeOpenPathMobileCredential = async () => {
    props.setProcessing(true);
    const payload = {
      UserId: props.UserId,
      EntityId: props.UserMemberId,
      AccessCardType: AccessCardType.OpenPath,
      OpenpathCredentialEndDate: moment()
        .subtract({ hours: 1 })
        .format("YYYY-MM-DDTHH:mm:ss"),
    };
    const response = await simpleAccessService.removeOpenPathMobileCredential(
      payload
    );

    if (response.success) {
      props.handleNotification("Access Removed", "success");
      await props.checkMemberHardwareAccess();
    } else {
      props.handleNotification(response.msg || "Error", "error");
    }
    props.setProcessing(false);
  };

  const updateMemberHardwareAccess = async () => {
    props.setProcessing(true);
    const payload = {
      UserId: props.UserId,
      EntityId: props.UserMemberId,
      IsSuspended: !props.IsSuspended,
    };
    const response = await simpleAccessService.updateMemberHardwareAccess(
      payload
    );
    if (response.success) {
      props.handleNotification(
        `Access ${payload.IsSuspended ? "Suspended" : "Activated"} `,
        "success"
      );
      await props.checkMemberHardwareAccess();
    } else {
      props.handleNotification(response.msg || "Error", "error");
    }
    props.setProcessing(false);
  };

  const updateGroup = async () => {
    props.setProcessing(true);

    const payload = {
      UserId: props.UserId,
      EntityId: props.UserMemberId,
      OnlyPatchUserGroups: true,
      UserGroups: groups.map((group: any) => ({ GroupId: group.id })),
    };
    const response = await simpleAccessService.createOpenPathMobileCredential(
      payload
    );
    if (response.success) {
      props.handleNotification(`Group Updated`, "success");
      await props.checkMemberHardwareAccess();
    } else {
      props.handleNotification(response.msg || "Error", "error");
    }
    props.setProcessing(false);
  };

  const getAccessCredentialStatus = () => {
    // Suspended - (OpenpathCredentialType != null) AND IsSuspended = true
    // Expired - (OpenpathCredentialType != null) AND OpenpathCredentialEndDate < Current Date
    // Pending Setup - (OpenpathCredentialType != null) AND ((OpenpathCredentialEndDate = null OR OpenpathCredentialEndDate > Current Date) AND IsOpenpathCredentialSetupComplete = false)
    // Active - (OpenpathCredentialType != null)  AND ((OpenpathCredentialEndDate = null OR OpenpathCredentialEndDate > Current Date) AND IsOpenpathCredentialSetupComplete = true)
    // No Access - when OpenpathCredentialType is null OR (when none of the above 4 status are set)

    // No Acess
    // 1 step => send invitaion

    if (props.OpenpathCredentialType !== null) {
      // check for No Access
      // new Date() < OpenpathCredentialStartDate => No Accees
      // OpenpathCredentialEndDate !== null &&  new Date() > OpenpathCredentialEndDate => No Accees
      // props.IsSuspended === true => susspended
      // IsOpenpathCredentialSetupComplete= false   => pending
      // else active

      if (
        moment(new Date()) <
        moment(moment(props.OpenpathCredentialStartDate).format("HH:mm:ss"))
      ) {
        return OpenPathCredentialStatusType.NoAccess;
      } else if (
        props.OpenpathCredentialEndDate !== null &&
        moment(new Date()) >
          moment(moment(props.OpenpathCredentialStartDate).format("HH:mm:ss"))
      ) {
        return OpenPathCredentialStatusType.NoAccess;
      } else if (props.IsSuspended === true) {
        return OpenPathCredentialStatusType.Suspended;
      } else if (props.IsOpenpathCredentialSetupComplete === false) {
        return OpenPathCredentialStatusType.Pending;
      } else if (props.IsOpenpathCredentialSetupComplete === true) {
        return OpenPathCredentialStatusType.Active;
      } else {
        return OpenPathCredentialStatusType.NoAccess;
      }
    } else {
      return OpenPathCredentialStatusType.NoAccess;
    }
  };

  const getStatusICon = (status: OpenPathCredentialStatusType) => {
    switch (status) {
      case OpenPathCredentialStatusType.Active:
        return bzImages.activeIcon;
      case OpenPathCredentialStatusType.NoAccess:
        return bzImages.noAccessIcon;
      case OpenPathCredentialStatusType.Pending:
        return bzImages.pendingIcon;
      case OpenPathCredentialStatusType.Suspended:
        return bzImages.suspendedIcon;
      default:
        return bzImages.noAccessIcon;
    }
  };

  const getAuditLogs = async (showHistory: boolean) => {
    if (showHistory === true) {
      props.setProcessing(true);
      const req = {
        UserMemberId: props.UserMemberId,
        PageSize: 20,
        PageNumber: 0,
      };
      const response = await simpleAccessService.getSimpleAccessAuditInfo(req);
      setAuditLogs(response);
      setShowHistory(true);
      props.setProcessing(false);
    } else {
      setAuditLogs([]);
      setShowHistory(false);
    }
  };

  const resendSetupEmail = async () => {
    props.setProcessing(true);
    const payload = {
      UserId: props.UserId,
      EntityId: props.UserMemberId,
      ExternalUserId: props.ExternalUserId,
      CredentialId: props.CredentialId,
    };
    const response = await simpleAccessService.resendSetupEmail(payload);
    if (response.success) {
      props.handleNotification(`Email resent`, "success");
      await props.checkMemberHardwareAccess();
    } else {
      props.handleNotification(response.msg || "Error", "error");
    }
    props.setProcessing(false);
  };

  useEffect(() => {
    props.OpenpathCredentialType !== null
      ? setSelectedCredentialType(
          OpenpathCrendentialOptions.find(
            (option) => option?.id === props.OpenpathCredentialType
          )
        )
      : setSelectedCredentialType({ id: -1, text: "Select credential type" });
    if (props.OpenpathCredentialType === OpenpathCrendentialType.CardWeigand) {
      props?.CardId !== null &&
        props?.CardId !== undefined &&
        setCardNumber(props.CardId);
      props?.FacilityCode !== null &&
        props?.FacilityCode !== undefined &&
        setFacilityCode(props.FacilityCode);
    } else {
      props.CardNumber && setCardNumber(props.CardNumber);
    }
  }, [
    props.OpenpathCredentialType,
    props.CardNumber,
    props.CardId,
    props.FacilityCode,
  ]);

  const isGroupChange = (value: any[]) => {
    if (
      value.length === 0 &&
      !(props.HasMemberAccessGroup === true && props.HasStaffAccessGroup)
    ) {
      setIsGroupChanged(false);
      setGroupsError("Please select groups");
      return;
    }
    if (value.length !== props.UserGroups.length) {
      setIsGroupChanged(true);
      return;
    }
    let isChange = false;
    value.forEach((group: any) => {
      const temp = props.UserGroups.find((ug: any) => ug.GroupId === group.id);
      if (!temp) {
        isChange = true;
      }
    });
    setIsGroupChanged(isChange);
  };

  const onChangeGroupHandler = (value: any) => {
    setGroups(value);
    value.length > 0 && setGroupsError("");
    isGroupChange(value);
  };

  useEffect(() => {
    const temp: any = [];
    props?.UserGroups?.forEach((userGroup: any) => {
      const group = props.ConfiguredGroups.find(
        (cg: any) => cg.Id.toString() === userGroup.GroupId
      );

      if (group) {
        temp.push({
          id: group?.Id,
          text: group?.Name,
        });
      }
    });
    setGroups(temp);
  }, [props.UserGroups]);

  return {
    createOpenPathMobileCredential,
    removeOpenPathMobileCredential,
    getAccessCredentialStatus,
    updateMemberHardwareAccess,
    showHistory,
    getAuditLogs,
    auditLogs,
    resendSetupEmail,
    getStatusICon,
    selectedCredentialType,
    setSelectedCredentialType,
    setCardNumber,
    cardNumber,
    setError,
    error,
    facilityCode,
    setFacilityCode,
    facilityCodeError,
    setFacilityCodeError,
    groups,
    setGroups,
    updateGroup,
    groupsError,
    isGroupChanged,
    onChangeGroupHandler,
  };
};
export default useMemberCredentials;
