import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import { ListView } from "@progress/kendo-react-listview";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { useState } from "react";

const ManageDeclines = (props: any) => {
  const {
    declinedData
  } = props;

  const [loading, setLoading] = useState(false);
  const [expandedItem, setExpandItem] = useState(null);
  const handleExpand = (event: any, item: any) => {
    if (expandedItem) {
      setExpandItem(null);
    } else {
      setExpandItem(item.PaymentProfileID);
    }
  };

  const renderTree = (item: any) => {
    return (
      <>
        <Grid data={item.RelatedDeclinedTransactions}>
          <GridColumn field="FullName" title="Client Name" />
          <GridColumn field="TransactionDate" title="Transaction Date" />
          <GridColumn field="PaymentMadeForDescription" title="Payment Made For" />
          <GridColumn field="ServiceName" title="Service Name" />
          <GridColumn field="Amount" title="Amount" />
          <GridColumn field="StatusDescription" title="Status" />
          <GridColumn field="TransactionId" title="Transaction Id" />
        </Grid>
      </>
    )
  }

  const RenderNewItem = (text: any) => {
    let item = text.dataItem;
    let collpaseStatus = false;
    if (expandedItem === item.PaymentProfileID) {
      collpaseStatus = true;
    }
    return (
      <div className="col-12">
        <div className="row p-3 align-middle manage-declines">
          <div className={'manage-declines-sections'}>
            <div className="section-1">
              <div className="section-1-items">
                {/* <span className="bz-event-type">
                        {"Recurring "}
                    </span> */}
                <h6 className="bz-service-name">{item.ParentName}</h6>
                <span className="bz-total-sessions">(Total Amount : {item.TotalAmount})</span>
              </div>
            </div>
            <div className="section-2">
              <div className="bz-price-section">
                {/* <span className="bz-status">Active</span> */}
                <DropDownList data={[1, 2, 3]} />
              </div>
            </div>
            <div className="section-3">
              {/* <span onClick={() => handleView("edit", item)}>
                        <i style={{ fontSize: "25px" }} className="fa fa-edit"></i>
                    </span> */}
              <span>
                Charge
                <Checkbox checked={true} />
              </span>
            </div>
            <div className="section-4 bz-cursor-pointer">
              <span onClick={(e) => handleExpand(e, item)}>
                <i style={{ fontSize: "25px" }} className={collpaseStatus ? `fa fa-angle-up` : `fa fa-angle-down`}></i>
              </span>
            </div>
          </div>
          {
            collpaseStatus &&
            <div>
              {renderTree(item)}
            </div>
          }
        </div>
      </div>
    );
  };
  return (
    <>
      {loading ? (
        <BuzopsLoader type={"list"} />
      ) : (
        <>
          <p className="ml-5">Select All <Checkbox checked /></p>
          <div className="bz-class-list">
            {declinedData.length === 0 ? (
              <>{"No Declined Data Available"}</>
            ) : (
              <>
                <ListView
                  item={(props) => RenderNewItem(props)}
                  // onScroll={(e) => scrollHandler(e)}
                  data={declinedData || []}
                  style={{
                    width: "100%",
                  }}
                />
                <BuzopsButton className={'m-4'} label="RUN DECLINED PAYMENTS" />
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ManageDeclines;