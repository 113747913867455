export const SubscriberStatisticsColumns = [
  {
    title: "Subscription Type",
    field: "Type",
    show: true,
    filter: "text",
    width: "150px",
    customCell: true,
  },
  {
    title: "Period",
    field: "Period",
    show: true,
    filter: "numeric",
    width: "250px",
    customCell: true,
  },
  {
    title: "New Signups",
    field: "Joined",
    show: true,
    filter: "numeric",
    width: "150px",
  },

  {
    title: "In-Active",
    field: "InActive",
    show: true,
    filter: "text",
    width: "150px",
    clickable: true,
  },
];

export const GroupServiceColumns = [
  {
    title: "Session Start Date",
    field: "ClassSessionStartDate",
    show: true,
    filter: "text",
  },
  {
    title: "Session End Date",
    field: "ClassSessionEndTime",
    show: true,
    filter: "text",
  },
  {
    title: "Provider",
    field: "TrainerName",
    show: true,
  },

  {
    title: "Booked Slots",
    field: "BookedSlot",
    show: true,
    clickable: true,
    filter: "text",
  },
  {
    title: "Total Slots",
    field: "Slot",
    show: true,
    enableHtml: true,
    filter: "text",
  },
  {
    title: "Amount",
    field: "Cost",
    show: true,
    enableHtml: true,
    filter: "text",
  },
  {
    title: "Status",
    field: "Status",
    show: true,
    filter: "text",
    customValCell: (val: any) => (val === 1 ? "Active" : "Cancelled"),
  },
];

export const MembershipsColumns = (total: any) => {
  return [
    {
      title: "Audit Date",
      field: "AuditDate",
      show: true,
      filter: "text",
      width: "150px",
      footerCell: () => "Total/Grand Total",
    },
    {
      title: "New Subscription",
      field: "Joined",
      show: true,
      filter: "text",
      width: "250px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "In-Active",
      field: "InActive",
      show: true,
      filter: "text",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Suspended",
      field: "Suspended",
      show: true,
      filter: "text",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Re-Activated",
      field: "ReActivated",
      show: true,
      filter: "text",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Re-Joined",
      field: "ReJoined",
      show: true,
      filter: "text",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Cancelled",
      field: "Cancelled",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Frozen",
      field: "Freezed",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Expired",
      field: "Expired",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Unfrozen",
      field: "Unfrozen",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
  ];
};

export const OneonOneColumns = (total: any) => {
  return [
    {
      title: "Audit Date",
      field: "AuditDate",
      show: true,
      filter: "text",
      width: "150px",
      footerCell: () => "Total/Grand Total",
    },

    {
      title: "New Subscription",
      field: "Joined",
      show: true,
      filter: "text",
      width: "250px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "In-Active",
      field: "InActive",
      show: true,
      filter: "text",
      width: "150px",
      clickable: true,
      footerCell: total,
    },

    {
      title: "Re-Activated",
      field: "ReActivated",
      show: true,
      filter: "text",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Cancelled",
      field: "Cancelled",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Completed",
      field: "Completed",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
  ];
};

export const AddOnColumns = (total: any) => {
  return [
    {
      title: "Audit Date",
      field: "AuditDate",
      show: true,
      filter: "text",
      width: "150px",
      footerCell: () => "Total/Grand Total",
    },
    {
      title: "New Subscription",
      field: "Joined",
      show: true,
      filter: "text",
      width: "250px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "In-Active",
      field: "InActive",
      show: true,
      filter: "text",
      width: "150px",
      clickable: true,
      footerCell: total,
    },

    {
      title: "Re-Activated",
      field: "ReActivated",
      show: true,
      filter: "text",
      width: "150px",
      clickable: true,
      footerCell: total,
    },

    {
      title: "Cancelled",
      field: "Cancelled",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Frozen",
      field: "Freezed",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Completed",
      field: "Completed",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Unfrozen",
      field: "Unfrozen",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
  ];
};

export const PackagesColumns = (total: any) => {
  return [
    {
      title: "Audit Date",
      field: "AuditDate",
      show: true,
      filter: "text",
      width: "150px",
      footerCell: () => "Total/Grand Total",
    },
    {
      title: "New Subscription",
      field: "Joined",
      show: true,
      filter: "text",
      width: "250px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "In-Active",
      field: "InActive",
      show: true,
      filter: "text",
      width: "150px",
      clickable: true,
      footerCell: total,
    },

    {
      title: "Re-Activated",
      field: "ReActivated",
      show: true,
      filter: "text",
      width: "150px",
      clickable: true,
      footerCell: total,
    },

    {
      title: "Cancelled",
      field: "Cancelled",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
    {
      title: "Expired",
      field: "Expired",
      show: true,
      filter: "numeric",
      width: "150px",
      clickable: true,
      footerCell: total,
    },
  ];
};
