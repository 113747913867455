import { ChipList } from "@progress/kendo-react-buttons";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { ComboBox, DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { FieldWrapper } from "@progress/kendo-react-form";
import {
  Checkbox, ColorPalette, ColorPicker, Input, MaskedTextBox, NumericTextBox, RadioGroup, Switch, TextArea
} from "@progress/kendo-react-inputs";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { ChunkProgressBar } from "@progress/kendo-react-progressbars";
import { ValidatePassword } from "validators/validator";

const checkValidation = (validationMessage: string, label: string, Type = "") => {
  let validation;
  if (validationMessage && validationMessage === "required" && Type === "") {
    validation = `Please enter a ${label}`;
  } else if (validationMessage && validationMessage === "required" && Type === "DropDownList") {
    validation = `Please provide a ${label}`;
  } else {
    validation = validationMessage;
  }
  return validation;
};

export const FormInput = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    required,
    passwordType,
    ...others
  } = fieldRenderProps;
  let validation = validationMessage;
  if (fieldRenderProps.customvalidation) {
    validation = checkValidation(validationMessage, label);
  }

  return (
    <FieldWrapper>
      {!fieldRenderProps.hidelabel && (
        <Label editorId={id} editorValid={valid}>
          {label} {required && <span className="required-asterisk">*</span>}
        </Label>
      )}
      <Input autoComplete={'off'} {...others} type={passwordType ? 'password' : 'text'} />
      {fieldRenderProps.hintMessage && (
        <Hint>{fieldRenderProps.hintMessage}</Hint>
      )}
      {touched && validation && <Error>{validation}</Error>}
    </FieldWrapper>
  );
};

export const FormTextArea = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    required,
    ...others
  } = fieldRenderProps;
  let validation = validationMessage;
  if (fieldRenderProps.customvalidation) {
    validation = checkValidation(validationMessage, label);
  }

  return (
    <FieldWrapper>
      {!fieldRenderProps.hidelabel && (
        <Label editorId={id} editorValid={valid}>
          {label} {required && <span className="required-asterisk">*</span>}
        </Label>
      )}
      <TextArea autoComplete={'off'} {...others} />
      {fieldRenderProps.hintMessage && (
        <Hint>{fieldRenderProps.hintMessage}</Hint>
      )}
      {touched && validation && <Error>{validation}</Error>}
    </FieldWrapper>
  );
};

export const FormComboBox = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    required,
    ...others
  } = fieldRenderProps;
  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {label} {required && <span className="required-asterisk">*</span>}
      </Label>
      <ComboBox {...others} />
      {touched && validationMessage && <Error>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormMaskedTextBox = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    required,
    ...others
  } = fieldRenderProps;
  let validation = validationMessage;
  if (fieldRenderProps.customvalidation) {
    validation = checkValidation(validationMessage, label);
  }
  return (
    <FieldWrapper>
      {!fieldRenderProps.hidelabel && (
        <Label editorId={id} editorValid={valid}>
          {label} {required && <span className="required-asterisk">*</span>}
        </Label>
      )}
      <MaskedTextBox {...others} />
      {touched && validation && <Error>{validation}</Error>}
    </FieldWrapper>
  );
};
export const FormTimePicker = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    required,
    ...others
  } = fieldRenderProps;
  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {label} {required && <span className="required-asterisk">*</span>}
      </Label>
      <TimePicker {...others} format="hh:mm a" />
      {touched && validationMessage && <Error>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormDatePicker = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    required,
    ...others
  } = fieldRenderProps;
  let validation = validationMessage;
  if (fieldRenderProps.customvalidation) {
    validation = checkValidation(validationMessage, label);
  }
  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {label} {required && <span className="required-asterisk">*</span>}
      </Label>
      <DatePicker {...others} className="datapickegroup" />
      {touched && validation && <Error>{validation}</Error>}
    </FieldWrapper>
  );
};
export const FormNumericText = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    required,
    ...others
  } = fieldRenderProps;
  let validation = validationMessage;
  if (fieldRenderProps.customvalidation) {
    validation = checkValidation(validationMessage, label);
  }
  return (
    <FieldWrapper>
      {!fieldRenderProps?.hidelabel && (
        <Label editorId={id} editorValid={valid}>
          {label} {required && <span className="required-asterisk">*</span>}
        </Label>)}
      <NumericTextBox {...others} />
      {touched && validation && <Error>{validation}</Error>}
    </FieldWrapper>
  );
};
export const FormColorPalette = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    label,
    id,
    valid,
    required,
    ...others
  } = fieldRenderProps;
  return (
    <FieldWrapper className="SelectedColor">
      <Label editorId={id} editorValid={valid}>
        {label} {required && <span className="required-asterisk">*</span>}
      </Label>
      <div className="mb-3">
        <ColorPicker tileSize={25} {...others} view={'gradient'} />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>

        <ColorPalette {...others} tileSize={25} />
      </div>

      {touched && validationMessage && <Error>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormChipList = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    label,
    id,
    valid,
    required,
    ...others
  } = fieldRenderProps;
  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {label} {required && <span className="required-asterisk">*</span>}
      </Label>
      <ChipList selection="multiple" valid={valid} id={id} {...others} />
      {touched && validationMessage && <Error>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormCheckbox = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    label,
    id,
    valid,
    value,
    ...others
  } = fieldRenderProps;
  return (
    <div>
      <Checkbox
        label={label}
        checked={value}
        defaultChecked={false}
        {...others}
      />
      {touched && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const FormDropDownList = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    required,
    ...others
  } = fieldRenderProps;
  let validation = validationMessage;
  if (fieldRenderProps.customvalidation) {
    validation = checkValidation(validationMessage, label, "DropDownList");
  }
  return (
    <FieldWrapper>
      {!fieldRenderProps.hidelabel && (
        <Label editorId={id} editorValid={valid}>
          {label} {required && <span className="required-asterisk">*</span>}
        </Label>
      )}
      <DropDownList ariaLabelledBy={label} valid={valid} id={id} {...others} />
      {fieldRenderProps.hintMessage && (
        <Hint>{fieldRenderProps.hintMessage}</Hint>
      )}
      {touched && validation && <Error>{validation}</Error>}
    </FieldWrapper>
  );
};


export const FormRadioGroup = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    required,
    ...others
  } = fieldRenderProps;
  let validation = validationMessage;
  if (fieldRenderProps.customvalidation) {
    validation = checkValidation(validationMessage, label);
  }
  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {label} {required && <span className="required-asterisk">*</span>}
      </Label>
      <RadioGroup ariaLabelledBy={label} valid={valid} id={id} {...others} />
      {fieldRenderProps.hintMessage && (
        <Hint>{fieldRenderProps.hintMessage}</Hint>
      )}
      {touched && validation && <Error>{validation}</Error>}
    </FieldWrapper>
  );
};

export const FormMultiSelect = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    required,
    ...others
  } = fieldRenderProps;
  let validation = validationMessage;
  if (fieldRenderProps.customvalidation) {
    validation = checkValidation(validationMessage, label);
  }
  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {label} {required && <span className="required-asterisk">*</span>}
      </Label>
      <MultiSelect ariaLabelledBy={label} valid={valid} id={id}  {...others} />
      {fieldRenderProps.hintMessage && (
        <Hint>{fieldRenderProps.hintMessage}</Hint>
      )}
      {touched && validation && <Error>{validation}</Error>}
    </FieldWrapper>
  );
};

export const FormEditor = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    label,
    id,
    valid,
    value,
    required,
    ...others
  } = fieldRenderProps;

  const {
    Bold,
    Italic,
    Underline,
    AlignLeft,
    AlignRight,
    AlignCenter,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    Link,
    Unlink,
  } = EditorTools;

  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {label} {required && <span className="required-asterisk">*</span>}
      </Label>
      <Editor
        tools={[
          [Bold, Italic, Underline],
          [Undo, Redo],
          [Link, Unlink],
          [AlignLeft, AlignCenter, AlignRight],
          [OrderedList, UnorderedList, Indent, Outdent],
        ]}
        contentStyle={{ height: 120 }}
        {...others}
      />
      {touched && validationMessage && <Error>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormDurationPicker = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    numericPlaceholder,
    required,
    ...others
  } = fieldRenderProps;
  let validation = validationMessage;
  if (fieldRenderProps.customvalidation) {
    validation = checkValidation(validationMessage, label);
  }
  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {label} {required && <span className="required-asterisk">*</span>}
      </Label>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <NumericTextBox placeholder={numericPlaceholder} />
        <DropDownList ariaLabelledBy={label} valid={valid} id={id} {...others} />
      </div>
      {touched && validation && <Error>{validation}</Error>}
    </FieldWrapper>
  );
};

export const notificationPosition = {
  topCenter: { top: 0, left: "50%", transform: "translateX(-50%)" },
};

export const CustomFormInput = (fieldRenderProps: any) => {
  const {
    id,
    name,
    label,
    value,
    handleChange,
    showErrorMessage,
    // handleOnBlur,
    touched,
    submit,
    required,
    informative,
    ...others
  } = fieldRenderProps;
  return (
    <>
      <div className="k-input-inline-label">
        <div className="label-item">
          <Label>
            {label}
            {required && <span className="requiredClass">*</span>}
          </Label>
        </div>
        <div className="input-item">
          <Input
            autoComplete={"off"}
            id={id}
            name={name}
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            // onBlur={(e: any) => handleOnBlur(e.target.name, e.target.value)}
            value={value}
            {...others}
          />
          {(touched || submit) && showErrorMessage?.length > 0 && (
            <Error>{showErrorMessage}</Error>
          )}
        </div>
      </div>
    </>
  );
};

export const FormToggle = (fieldRenderProps: any) => {
  const {
    validationMessage,
    visited,
    touched,
    id,
    valid,
    label,
    required,
    passwordType,
    value,
    ...others
  } = fieldRenderProps;
  let validation = validationMessage;
  if (fieldRenderProps.customvalidation) {
    validation = checkValidation(validationMessage, label);
  }
  return (
    <FieldWrapper>
      {!fieldRenderProps.hidelabel && (
        <Label editorId={id} editorValid={valid}>
          {label} {required && <span className="required-asterisk">*</span>}
        </Label>
      )}
      <Switch autoComplete={'off'} {...others} checked={value} />
      {fieldRenderProps.hintMessage && (
        <Hint>{fieldRenderProps.hintMessage}</Hint>
      )}
      {touched && validation && <Error>{validation}</Error>}
    </FieldWrapper>
  );
};

export const FormPasswordInput = (fieldRenderProps: any) => {
  const {
    id,
    name,
    label,
    value,
    handleChange,
    showErrorMessage,
    handleOnBlur,
    touched,
    submit,
    required,
    passwordType,
    informative,
    validate,
    handlePasswordDisplay,
    ...others
  } = fieldRenderProps;

  return (
    <>
      {/* <div className="k-input-inline-label">
        <div className="label-item"> */}
      <Label>
        {label}
        {required && <span className="requiredClass">*</span>}
      </Label>
      {/* </div> */}
      {/* <div className="input-item"> */}
      <div className="password_item">
        <Input
          autoComplete={"off"}
          id={id}
          name={name}
          onChange={(e: any) => handleChange(e.target.name, e.target.value)}
          onBlur={(e: any) => handleOnBlur(e.target.name, e.target.value)}
          value={value}
          {...others}
        />
        <span className="password_eye_icon cursor-pointer" onClick={handlePasswordDisplay} key={passwordType.toString()}><i className={passwordType ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
      </div>
      {(touched || submit) && showErrorMessage?.length > 0 && (
        <Error>{showErrorMessage}</Error>
      )}
      {touched && validate &&
        ValidatePassword(value) && (
          <>
            <ChunkProgressBar
            style={{width:"100%"}}
              className={`progress_bar progress_${ValidatePassword(value)}`}
              min={0}
              max={3}
              value={ValidatePassword(value) === 'Strong Password' ? 3 : ValidatePassword(value) === 'Medium Password' ? 2 : 1}
              chunkCount={3}
            />
          </>
        )}
      {/* </div>
      </div> */}
    </>
  );
};

export const CustomFormMaskedTextBox = (fieldRenderProps: any) => {
  const {
    id,
    name,
    label,
    value,
    handleChange,
    showErrorMessage,
    handleOnBlur,
    touched,
    submit,
    required,
    informative,
    tabindex,
    ...others
  } = fieldRenderProps;
  return (
    <>
      <div className="k-input-inline-label">
        <div className="label-item">
          <Label>
            {label}
            {required && <span className="requiredClass">*</span>}
          </Label>
        </div>
        <div className="input-item">
          <MaskedTextBox
            tabIndex={tabindex}
            autoComplete={"off"}
            id={id}
            name={name}
            onChange={(e: any) => handleChange(e.target.name, e.value)}
            onBlur={(e: any) => handleOnBlur(e.target.name, e.value)}
            value={value}
            {...others}
          />
          {(touched || submit) && showErrorMessage?.length > 0 && (
            <Error>{showErrorMessage}</Error>
          )}
        </div>
      </div>
    </>
  );
};

export const CustomFormTextArea = (fieldRenderProps: any) => {
  const {
    id,
    name,
    label,
    value,
    handleChange,
    showErrorMessage,
    handleOnBlur,
    touched,
    submit,
    required,
    informative,
    ...others
  } = fieldRenderProps;

  return (
    <div className="k-input-inline-label">

      <div className="label-item">
        <Label>
          {label}
          {required && <span className="requiredClass">*</span>}
        </Label>
      </div>
      <div className="input-item">

        <TextArea autoComplete={"off"}
          id={id}
          name={name}
          onChange={(e: any) => handleChange(e.target.name, e.value)}
          onBlur={(e: any) => handleOnBlur(e.target.name, e.value)}
          value={value}
          rows={3}
          {...others} />

        {required && (touched || submit) && showErrorMessage?.length > 0 && (
          <Error>{showErrorMessage}</Error>
        )}
      </div>
    </div>
  );
};
export const AffiliateFormInput = (fieldRenderProps: any) => {
  const {
    id,
    name,
    label,
    value,
    handleChange,
    showErrorMessage,
    handleOnBlur,
    touched,
    submit,
    required,
    informative,
    ...others
  } = fieldRenderProps;
  return (
    <>
      <div className="k-input-inline-label">
        <div className="label-item ">
          <Label>
            {label}
            {required && <span className="requiredClass">*</span>}
          </Label>
        </div>
        <div className="input-item">
          <Input
            autoComplete={"off"}
            id={id}
            name={name}
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            onBlur={(e: any) => handleOnBlur(e.target.name, e.target.value)}
            value={value}
            {...others}
           />
          {(touched || submit) && showErrorMessage?.length > 0 && (
            <Error>{showErrorMessage}</Error>
          )}
        </div>
      </div>
    </>
  );
};