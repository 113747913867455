import { useEffect, useState } from "react";
import { ThirdPartyConfigService } from "services/thirdparty-configuration/index.service";
import { HardwareProviderType, SecuritySystemType } from "utils/constants";

const useSecuritySystemConfigs = () => {
  const service = new ThirdPartyConfigService();
  const [securityConfig, setSecuritySystemConfig] = useState<any>();
  const [Loading, setLoading] = useState<boolean>(false);

  const fetchServices = async () => {
    setLoading(true);
    const getResult = await service.getSecuritySystemConfigs();
    if (getResult.success) {
      setSecuritySystemConfig(getResult?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const isOpenPathSystem = () => {
    if (
      securityConfig?.SecuritySystemType ===
        SecuritySystemType?.ProximityReaderSystem &&
      securityConfig?.HardwareSecuritySystemPreferences?.ProviderType ===
        HardwareProviderType.Openpath
    ) {
      return true;
    }
    return false;
  };

  const isBarcodeSystem = () => {
    if (
      securityConfig?.SecuritySystemType === SecuritySystemType?.ScannerSystem
    ) {
      return true;
    }
    return false;
  };
  return {
    Loading,
    securityConfig,
    isOpenPathSystem,
    isBarcodeSystem
  };
};

export default useSecuritySystemConfigs;