import Location from "components/Location/Location";
import React from "react";
import { CurrencyFormat } from "utils";

const ServiceDetailsPanel = (props: any) => {
  const { NumberOfSessions, Rate, Duration, Name} = props.serviceData;
  const purchaseCost = NumberOfSessions * Rate;
  return (
    <>
      <div className="serviceDetailsPanel-grid">
        <div className="section-1 d-flex align-items-center">
          <h5>
            {`One-on-One : ${Name}`} 
            <span className="location-icon">
          {props.serviceData?.Attributes && props.serviceData?.Attributes !== "" && <Location Attributes={props.serviceData?.Attributes} showDetails={props?.staffPage} VirtualMeetingLink={props.serviceData?.VirtualMeetingLink}/>}
          </span>
          </h5> 
        </div>
        {/* <div className="section-2">
          <h3>{CurrencyFormat(purchaseCost)}</h3>
        </div> */}
      </div>
      <div className="serviceDetailsPanel-grid serviceDetailsPanel-flex">
      <div className="section-2">
          <span className={"service-titlename"}>{"Purchase cost"}</span>
          <span>{": "}</span>
          <span>{CurrencyFormat(purchaseCost)}</span>
        </div>
        <div className="section-3">
          <span className={"service-titlename"}>{"Appointments"}</span>
          <span>{": "}</span>
          <span>{NumberOfSessions}</span>
        </div>
        <div className="section-4">
          <span className={"service-titlename"}>{"Duration"}</span>
          <span>{": "}</span>
          <span>{`${parseInt(Duration)} minutes`}</span>
        </div>
        <div className="section-5">
          <span className={"service-titlename"}>{"Appointment Rate"}</span>
          <span>{": "}</span>
          <span>{CurrencyFormat(Rate)}</span>
        </div>
      </div>
    </>
  );
};


export default ServiceDetailsPanel;
