import React from "react";
import BuzopsLoader from "generic-components/BuzopsLoader";
import useOpenPathCredentials from "../Container/OpenPathCredentials.hook";
import { IOpenPathCredentialProps } from "../Utils/interfaces";
import MemberCredential from "./MemberCredential";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import SimpleAccessCheckIns from "components/summary/SimpleAccessCheckIns";
import { SecuritySystemType } from "utils/constants";
import ReactTooltip from 'react-tooltip';

const OpenPathCredentials: React.FunctionComponent<IOpenPathCredentialProps> = (
  props
) => {
  const openPathCredentials = useOpenPathCredentials(props);

  return (
    <>
      {openPathCredentials.loading ? (
        <BuzopsLoader type={"list"} />
      ) : (
        <div
          className=""
          style={{
            width: "100%",
            opacity: openPathCredentials.processing ? 0.5 : 1,
          }}
        >
          <Card className="" style={{ width: "100%" }}>
            <CardHeader className="p-0">
              <CardTitle className="bz-card-title">{"Onsite Access"}</CardTitle>
            </CardHeader>
            {
              !openPathCredentials.isOnline &&
              <div className="alert alert-warning fade show" role="alert">
                <span className="d-inline-block">
                  Your Door Access Control Hardware appears to be offline/ disconnected.{" "}
                  <span className="bz-cursor-pointer" data-for={"access-control-tooltip"} data-tip={"Please check if your Door Access Controller hardware is plugged in and connected to the internet. <br /> Please contact Buzops support if you need further assistance.  <br /> Note: The system will allow assigning access credentials to your users.   <br /> However, the users may not be able to use the credentials until  the Door Access Controller hardware is back online."} >
                    <i className="fa fa-info-circle"></i>
                  </span>
                  <ReactTooltip id={`access-control-tooltip`} place="bottom" type="dark" effect="float" html={true} />
                </span>
              </div>
            }
            <CardBody className="p-0">
              <>
                {openPathCredentials.processing && (
                  <Loader
                    type="infinite-spinner"
                    className="laoder-full-screen"
                  />
                )}
                {openPathCredentials?.memberAccessData?.map((accessData) => (
                  <MemberCredential
                    {...accessData}
                    handleNotification={props.handleNotification}
                    setProcessing={openPathCredentials.setProcessing}
                    checkMemberHardwareAccess={
                      openPathCredentials.checkMemberHardwareAccess
                    }
                    ConfiguredGroups={props.ConfiguredGroups}
                  />
                ))}
              </>
            </CardBody>
            <CardBody className="p-0">
              <div className="mb-3 pt-3">
                {/* <h5>Check Ins</h5> */}
                <SimpleAccessCheckIns
                  {...props}
                  userData={props.userData}
                  members={openPathCredentials.memberAccessData}
                  handleNotificationMessage={props.handleNotification}
                  securitySystemType={SecuritySystemType.ProximityReaderSystem}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
};
export default OpenPathCredentials;
