import React, { useEffect, useState } from "react";
import ReportsCommonFilters from "../reportsCommonFilters";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ReportsService } from "services/reports/index.service";
import BuzopsReportGrid from "generic-components/BuzopsReportGrid";

const DetailComponent = (props: any, categories: any) => {
  const data = [props.dataItem];

  if (categories.length && categories[0]?.customerId === data[0].customerId) {
    const columnExpandShowData =
      props?.dataItem?.numberOfSubscription?.childData?.map((item: any) => {
        return {
          serviceName: item.serviceName,
          startDate: item.startDate,
          signedUpDate: item.signedUpDate,
          signedUpBy: item.signedUpBy,
          amount: item.amount,
          lastPaymentDate: item.lastPaymentDate,
          lastPaymentAmount: item.lastPaymentAmount,
          nextEftDate: item.nextEftDate,
          nextEftAmount: item.nextEftAmount,
          amountCollected: item.amountCollected,
          noOfRenewals: item.noOfRenewals,
          lastAudit: item.lastAudit,
        };
      });

    return (
      <div>
        <Grid data={columnExpandShowData}>
          <Column title="Service Name" field="serviceName" width="150px" />
          <Column title="Start Date" field="startDate" width="150px" />
          <Column title="Signed Up Date" field="signedUpDate" width="170px" />
          <Column title="Signed Up By" field="signedUpBy" width="150px" />
          <Column title="Amount" field="amount" width="150px" />
          <Column
            title="Last Payment Date"
            field="lastPaymentDate"
            width="200px"
          />
          <Column
            title="Last Payment Amount"
            field="lastPaymentAmount"
            width="220px"
          />

          <Column title="Next Eft Date" field="nextEftDate" width="170px" />
          <Column title="Next Eft Amount" field="nextEftAmount" width="170px" />
          <Column
            title="Amount Collected"
            field="amountCollected"
            width="200px"
          />
          <Column title="No Of Renewals" field="noOfRenewals" width="170px" />
          <Column title="Last Audit" field="lastAudit" width="170px" />
        </Grid>
      </div>
    );
  }
  return null;
};

const AllCustomers = (props: any) => {
  const [categories, setCategories] = React.useState<any>([]);
  const [pageInfo, setPageInfo] = useState({
    take: 10,
    pageNumber: 1,
    skip: 0,
    total: 0,
    data: [],
  });
  const gridColumns = [
    {
      title: "Name",
      field: "FirstName",
      show: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "LastName",
      field: "LastName",
      show: true,
      filter: "text",
      width: "250px",
    },
    {
      title: "Email",
      field: "Email",
      show: true,
      filter: "numeric",
      width: "150px",
    },

    {
      title: "Phone",
      field: "Phone",
      show: true,
      filter: "text",
      width: "150px",
    },
    {
      title: "Subscription Count",
      field: "SubscriptionCount",
      show: true,
      clickable: true,
      filter: "numeric",
      width: "150px",
    },
  ];
  const [stateGridColumns, setStateGridColumns] = React.useState(gridColumns);
  const service = new ReportsService();
  useEffect(() => {
    fetchCustomerData();
  }, []);

  const fetchCustomerData = async (pageData?: any) => {
    let req = {
      pageSize: 10,
      pageNumber: 0,
    };
    console.log(pageData);
    if (pageData) {
      req = {
        pageSize: pageData.take,
        pageNumber: pageData.skip / pageData.take,
      };
    }
    const getData = await service.getAllCustomers(req);
    console.log(getData);
    setPageInfo((prev) => {
      prev.data = getData.Items;
      prev.pageNumber = req.pageNumber;
      prev.total = getData.TotalItems;
      if (pageData?.dataState?.take) {
        prev.skip = pageData?.dataState?.skip;
        prev.take = pageData?.dataState?.take;
      }
      return { ...prev };
    });
  };

  const CommandCell = (props: any) => {
    const expandChange = (event: any) => {
      if (event.dataItem.customerId === categories[0]?.customerId) {
        setCategories([]);
      } else {
        setCategories([event.dataItem]);
      }
    };

    return (
      <td>
        <span className="customer-link" onClick={() => expandChange(props)}>
          {props.dataItem.numberOfSubscription.count}
        </span>
      </td>
    );
  };
  const uuid = props?.match?.params?.uuid;
  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };
  const onCellClick = (item: any) => {
    console.log(item);
  };
  return (
    <div>
      <ReportsCommonFilters
        {...props}
        history={props.history}
        location={props.location}
      />
      <div className="mt-4 customer-main-container">
        <div className="d-flex justify-content-end customer-close-icon">
          <li
            className="k-icon k-i-close"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
        <div className="d-flex customer-sub-header">
          <h2 className="customer-header">All Customers</h2>
        </div>
        <div className="customer-grid-border">

          <BuzopsReportGrid
            fileName="allCustomers.xlsx"
            result={pageInfo.data}
            dataState={{ ...pageInfo }}
            dataStateChange={(e: any) => fetchCustomerData(e.dataState)}
            stateGridColumns={stateGridColumns}
            pageSize={10}
            setStateGridColumns={(data: any) => setStateGridColumns(data)}
            onColumnsSubmit={onColumnsSubmit}
            onCellClick={(item: any) => onCellClick(item)}
          />
        </div>
      </div>
    </div>
  );
};

export default AllCustomers;
